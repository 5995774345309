import { Form, FormItemProps, FormRule } from 'antd';
import Formatter from '../../../classes/Formatter';
import { parseLocaleNumber } from '../../../utils';

export const PriceFormItem = ({ children, ...props }: FormItemProps) => {
  const rules: FormRule[] = [
    { type: 'number', min: 0.01, transform: parseLocaleNumber },
    {
      type: 'number',
      max: 100000000,
      transform: parseLocaleNumber,
    },
  ];

  if (props.required) rules.unshift({ required: true });

  return (
    <Form.Item
      normalize={Formatter.formatMoney}
      rules={rules}
      validateFirst
      {...props}
    >
      {children}
    </Form.Item>
  );
};
