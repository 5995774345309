import { Avatar, Descriptions, Progress, Skeleton, Space, theme } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FreightVolumeBarFragment } from '../../../graphql/generated/graphql';
import { ResponsiveDescriptions } from '../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { Volume } from '../../../core/Freight/Volume';

interface IFreightVolumeBarProps {
  data?: FreightVolumeBarFragment;
  loading?: boolean;
  type?: 'freight' | 'quotation';
}

export const FreightVolumeBar = ({
  data = {} as FreightVolumeBarFragment,
  loading,
  type = 'freight',
}: IFreightVolumeBarProps): ReactElement => {
  const TRANSLATION_PATH = `components.freight.${type}VolumeBar`;
  const { t } = useTranslation();

  const {
    token: { colorSuccess, colorInfo },
  } = theme.useToken();

  const {
    allocated_volume = 0,
    loaded_volume = 0,
    total_volume = 1,
    unallocated_volume = 0,
    unit_type,
  } = data;

  const allocatedPercentage = (allocated_volume * 100) / total_volume;
  const loadedPercentage = (loaded_volume * 100) / total_volume;

  const column = {
    xxl: 3,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <>
      <ResponsiveDescriptions column={column} layout={layout}>
        <Descriptions.Item
          label={
            <Space size="small">
              <Avatar size={24} style={{ backgroundColor: colorSuccess }} />
              {t(`${TRANSLATION_PATH}.descriptions.loadedVolume`)}
            </Space>
          }
        >
          <Skeleton active loading={loading} paragraph={false}>
            <Volume.Display volume={loaded_volume} unitType={unit_type} />
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size="small">
              <Avatar size={24} style={{ backgroundColor: colorInfo }} />
              {t(`${TRANSLATION_PATH}.descriptions.allocatedVolume`)}
            </Space>
          }
        >
          <Skeleton active loading={loading} paragraph={false}>
            <Volume.Display volume={allocated_volume} unitType={unit_type} />
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <Space size="small">
              <Avatar size={24} style={{ backgroundColor: '#e0e0e0' }} />
              {t(`${TRANSLATION_PATH}.descriptions.unallocatedVolume`)}
            </Space>
          }
        >
          <Skeleton active loading={loading} paragraph={false}>
            <Volume.Display volume={unallocated_volume} unitType={unit_type} />
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
      <Skeleton active loading={loading} paragraph={false}>
        <Progress
          percent={allocatedPercentage}
          success={{ percent: loadedPercentage }}
          showInfo={false}
          type="line"
          strokeWidth={16}
          trailColor="#e0e0e0"
        />
      </Skeleton>
    </>
  );
};
