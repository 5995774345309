import { SVGProps } from 'react';

export default function TruckIconComponent(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
      <g data-name="Grupo 16972" fill="currentColor">
        <path
          data-name="Ret\xE2ngulo 6726"
          d="M1.474.737a.738.738 0 0 0-.737.737v11.789h12.526V1.474a.738.738 0 0 0-.737-.737H1.474m0-.737h11.052A1.474 1.474 0 0 1 14 1.474V14H0V1.474A1.474 1.474 0 0 1 1.474 0Z"
        />
        <path
          data-name="Ret\xE2ngulo 6727"
          d="M7.239 2.211A2.627 2.627 0 0 0 4.572 4.79a2.627 2.627 0 0 0 2.667 2.579A2.627 2.627 0 0 0 9.91 4.79a2.627 2.627 0 0 0-2.671-2.579m0-.737a3.374 3.374 0 0 1 3.429 3.316 3.374 3.374 0 0 1-3.429 3.316A3.374 3.374 0 0 1 3.81 4.79a3.374 3.374 0 0 1 3.429-3.316Z"
        />
        <path
          data-name="Ret\xE2ngulo 6728"
          d="M7.239 9.579a4.145 4.145 0 0 0-4.174 3.684h8.348a4.145 4.145 0 0 0-4.174-3.684m0-.737a4.873 4.873 0 0 1 4.954 4.789v.368H2.286v-.368a4.873 4.873 0 0 1 4.953-4.789Z"
        />
      </g>
    </svg>
  );
}
