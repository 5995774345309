import { useState, useEffect } from 'react';

type TimeoutId = ReturnType<typeof setTimeout>;

/**
 * A custom hook that debounces changes to a value.
 *
 * @template T
 * @param {T} value - The value to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @return {T} - The debounced value.
 */
const useDebounce = <T,>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId: TimeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounceState = <T,>(
  initialValue?: T | (() => T),
  delay = 300
) => {
  const [state, setState] = useState<T | undefined>(initialValue);

  const debounceState = useDebounce(state, delay);

  return [debounceState, setState] as const;
};

export default useDebounce;
