import dayjs from 'dayjs';

export interface IFormattedDate {
  dateTime: string | dayjs.Dayjs;
}

export const FormattedDate = (props: IFormattedDate) => {
  const dateTime = dayjs(props.dateTime);

  return <>{dateTime.format('DD/MM/YYYY')}</>;
};
