/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Form, Popconfirm, Row, notification } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import errorHandler from '../../../api/errorHandler';
import Button from '../../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import HeroContainer from '../../../components/container/heroContainer';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import Footer from '../../../components/container/heroContainer/heroCardContainer/footer';
import InputText from '../../../components/inputs/inputText';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import { useActivateUserPageMutation } from '../../../graphql/generated/graphql';
import { useJwtDecode } from '../../../hooks/useJwtDecode/useJwtDecode';
import { IAPIError } from '../../../utils/interfaces';
import LoadingPage from '../../LoadingPage/LoadingPage';

import './ActivateUserPage.scss';

export const ActivateUserPage = (): ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const decodedJwt = useJwtDecode({ encodedJwt: token });

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [activateUserMutationResult, executeActivateUserMutation] =
    useActivateUserPageMutation();

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = ({ password }: { password: string }) => {
    if (token) {
      executeActivateUserMutation({
        input: { token, user_password: password },
      }).then(({ data, error }) => {
        if (data?.ActivateUser.has_success) {
          notification.success({
            message: t('pages.activateUser.notifications.success.message'),
            description: t(
              'pages.activateUser.notifications.success.description'
            ),
          });

          navigate('/', { replace: true });
        } else {
          if (!error) {
            notification.error({
              message: t('errors.default.message'),
              description: t('errors.default.description'),
            });
          } else {
            const { message, description } = errorHandler(
              error.graphQLErrors as unknown as IAPIError[]
            );

            notification.error({
              message: t(message),
              description: t(description),
            });
          }
        }
      });
    }
  };

  const passwordRules = [
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}`.length < 6) {
          return Promise.reject();
        }

        return Promise.resolve();
      },
      message: 'pages.createPassword.fields.rules.passwordMinLength',
      validateTrigger: 'onBlur',
    },
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}`.length > 128) {
          return Promise.reject();
        }

        return Promise.resolve();
      },
      message: 'pages.createPassword.fields.rules.passwordMaxLength',
    },
  ];

  const confirmPasswordRules = [
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}` === form.getFieldsValue(['password'])['password']) {
          return Promise.resolve();
        }

        return Promise.reject();
      },
      message: 'pages.createPassword.fields.rules.passwordNotMatch',
    },
  ];

  const createPasswordForm = (
    <Form
      form={form}
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
    >
      <Row className="form-group">
        <InputText
          formItem
          validation
          size="large"
          name="password"
          type={'password'}
          label={t('pages.createPassword.fields.labels.password')}
          placeholder={t('pages.createPassword.fields.placeholders.password')}
          otherRules={passwordRules}
          otherPropsFormItem={{
            validateTrigger: ['onChange', 'onBlur'],
          }}
        />
        <InputText
          formItem
          validation
          size="large"
          name="confirmPassword"
          type={'password'}
          label={t('pages.createPassword.fields.labels.confirmPassword')}
          placeholder={t(
            'pages.createPassword.fields.placeholders.confirmPassword'
          )}
          otherRules={confirmPasswordRules}
          otherPropsFormItem={{
            hasFeedback: true,
            dependencies: ['password'],
          }}
        />
      </Row>
    </Form>
  );

  const cancelButton = (
    <Popconfirm
      title={t('pages.createPassword.confirm.cancel')}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={handleCancel}
    >
      <Button
        danger
        size="large"
        type="primary"
        disabled={activateUserMutationResult.fetching}
      >
        {t('pages.createPassword.action.cancel')}
      </Button>
    </Popconfirm>
  );

  const submitButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      onClick={handleSubmit}
      loading={activateUserMutationResult.fetching}
    >
      {t('pages.createPassword.action.submit')}
    </Button>
  );

  const formHeader = (
    <TitleHeader
      centered
      titleText={t('pages.createPassword.title')}
      subtitleText={t('pages.createPassword.description')}
    />
  );

  const formFooter = (
    <Footer>
      {cancelButton}
      {submitButton}
    </Footer>
  );

  useEffect(() => {
    if (decodedJwt.error) {
      notification.error({
        message: t(decodedJwt.error.message),
        description: t(decodedJwt.error.description),
      });

      navigate('/', { replace: true });
    }
  }, [decodedJwt]);

  if (!decodedJwt.data) return <LoadingPage asOverlay opaque />;

  return (
    <HeroContainer>
      <HeroCardContainer
        logoColumnSize="small"
        className="create-password_container"
      >
        <Card className="create-password_card" bordered={false}>
          <SupportHelpContact />
          {formHeader}
          {createPasswordForm}
        </Card>
        {formFooter}
      </HeroCardContainer>
    </HeroContainer>
  );
};

export default ActivateUserPage;
