/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Spin, Typography } from 'antd';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, {
  MapLayerMouseEvent,
  MapRef,
  Marker,
  MarkerDragEvent,
  ViewStateChangeEvent,
} from 'react-map-gl';
import Formatter from '../../../../classes/Formatter';
import {
  PlaceTableItemFragment,
  UpdatePlaceInput,
  useFindPlaceQuery,
  useUpdatePlaceMutation,
} from '../../../../graphql/generated/graphql';
import MapStyle from '../../../../structures/interfaces/Mapbox/MapStyles';
import { UF } from '../../../../structures/interfaces/UF';
import { parseLocaleNumber } from '../../../../utils';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import '../CreatePlaceModal/CreatePlaceModal.scss';
import {
  IUpdatePlaceForm,
  IUpdatePlaceFormInternalStates,
  UpdatePlaceForm,
} from '../UpdatePlaceForm/UpdatePlaceForm';
import { formatFindPlaceToPlaceForm } from '../UpdatePlaceForm/UpdatePlaceFormUtils';

interface ICoordinates {
  longitude: number;
  latitude: number;
  zoom: number;
}

interface IUpdatePlaceModalProps {
  /**
   * Is modal visible?
   */
  isVisible?: boolean;
  /**
   * Function to execute when a place is successfully updated
   */
  onUpdateSuccess?: (place: PlaceTableItemFragment) => void;
  /**
   * Function to close modal
   */
  onClose?: () => void;
  /**
   * Id of a place to update
   */
  placeId?: string;
}

/**
 * Modal to update a place
 */
export const UpdatePlaceModal = ({
  isVisible = false,
  onUpdateSuccess,
  onClose,
  placeId,
}: IUpdatePlaceModalProps): ReactElement => {
  const UPDATE_PLACE_MODAL_TRANSLATION_PATH =
    'components.freight.place.updatePlaceModal';

  const { t } = useTranslation();

  const [form] = Form.useForm<IUpdatePlaceForm>();

  const [updatePlaceModalMutationResult, executeUpdatePlaceMutation] =
    useUpdatePlaceMutation();

  const findPlaceVariables = placeId ? { input: { id: placeId } } : undefined;

  const [updatePlaceModalQueryResult, executeUpdatePlaceQuery] =
    useFindPlaceQuery({
      pause: true,
      variables: findPlaceVariables,
    });

  const { fetching, error } = updatePlaceModalMutationResult;

  const mapRef = useRef<MapRef | null>(null);

  const defaultMapCoordinates = {
    longitude: -54,
    latitude: -12,
    zoom: 3,
  };

  const [marker, setMarker] = useState<Omit<ICoordinates, 'zoom'>>();
  const [coordinates, setCoordinates] = useState<ICoordinates>(
    defaultMapCoordinates
  );

  const [updateInternalStates, setUpdateInternalStates] =
    useState<IUpdatePlaceFormInternalStates>();

  const handleCitySelect = () => {
    const formValues = form.getFieldsValue(['latitude', 'longitude']);

    const latitude = formValues.latitude
      ? Number(formValues.latitude)
      : undefined;

    const longitude = formValues.longitude
      ? Number(formValues.longitude)
      : undefined;

    if (latitude && longitude) {
      setMarker({ latitude, longitude });
      mapRef.current?.flyTo({
        animate: true,
        zoom: 12,
        center: {
          lat: latitude,
          lon: longitude,
        },
      });
    }
  };

  const Title = () =>
    useMemo(() => <>{t(`${UPDATE_PLACE_MODAL_TRANSLATION_PATH}.title`)}</>, []);

  const Footer = () =>
    useMemo(
      () => (
        <>
          <Button danger size="small" onClick={onClose} disabled={fetching}>
            {t(`${UPDATE_PLACE_MODAL_TRANSLATION_PATH}.actions.cancel`)}
          </Button>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            loading={fetching}
            onClick={() => {
              form.submit();
            }}
          >
            {t(`${UPDATE_PLACE_MODAL_TRANSLATION_PATH}.actions.register`)}
          </Button>
        </>
      ),
      [fetching]
    );

  const updatePlaceModalMutationSerializer = (
    formValues: IUpdatePlaceForm
  ): UpdatePlaceInput => {
    const [opening_hour, closing_hour] = formValues.opening_hour;

    const balance_capacity = Formatter.convertTonBalanceCapacityToKilograms(
      parseLocaleNumber(formValues.balance_capacity),
      formValues.has_balance
    );

    const dirt_road_distance =
      Formatter.convertKilometersDirtRoadDistanceToMeters(
        parseLocaleNumber(formValues.dirt_road_distance),
        formValues.has_dirt_road
      );

    return {
      ...formValues,
      opening_hour: opening_hour.toISOString(),
      closing_hour: closing_hour.toISOString(),
      latitude: marker?.latitude ? String(marker.latitude) : '',
      longitude: marker?.longitude ? String(marker.longitude) : '',
      balance_capacity,
      dirt_road_distance,
    };
  };

  const handleFormSubmit = (formValues: IUpdatePlaceForm) => {
    executeUpdatePlaceMutation({
      input: updatePlaceModalMutationSerializer(formValues),
    }).then(({ data }) => {
      if (data && onUpdateSuccess) onUpdateSuccess(data.UpdatePlace);
    });
  };

  const handleMapMove = (event: ViewStateChangeEvent) => {
    setCoordinates({
      latitude: event.viewState.latitude,
      longitude: event.viewState.longitude,
      zoom: event.viewState.zoom,
    });
  };

  const handleMarkerMove = (event: MapLayerMouseEvent | MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  };

  useEffect(() => {
    if (marker) {
      form.setFieldsValue({
        latitude: String(marker?.latitude),
        longitude: String(marker?.longitude),
      });
    }
  }, [marker]);

  useEffect(() => {
    if (placeId) executeUpdatePlaceQuery();
  }, [placeId]);

  const { city_id, ...formValues } = useMemo(() => {
    if (updatePlaceModalQueryResult.data) {
      return formatFindPlaceToPlaceForm(
        updatePlaceModalQueryResult.data.FindPlace
      );
    }

    return {} as IUpdatePlaceForm;
  }, [updatePlaceModalQueryResult.data]);

  useEffect(() => {
    const { data, fetching } = updatePlaceModalQueryResult;
    if (data && !fetching && formValues) {
      form.setFieldsValue(formValues);

      setUpdateInternalStates({
        state_id: data.FindPlace.state_id as UF,
        has_balance: data.FindPlace.has_balance,
        has_dirt_road: data.FindPlace.has_dirt_road,
      });

      const latitude = Number(data.FindPlace.latitude);
      const longitude = Number(data.FindPlace.longitude);

      setMarker({ latitude, longitude });

      mapRef.current?.flyTo({
        animate: true,
        zoom: 14,
        center: {
          lat: latitude,
          lon: longitude,
        },
      });
    }
  }, [updatePlaceModalQueryResult]);

  return (
    <Modal
      destroyOnClose
      key={placeId}
      width={'992px'}
      title={<Title />}
      open={isVisible}
      onCancel={onClose}
      footer={<Footer />}
    >
      <>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <ErrorAlert
          error={updatePlaceModalQueryResult.error}
          style={{ marginBottom: 24 }}
        />
        <Row gutter={24}>
          <Col lg={12}>
            <Spin spinning={updatePlaceModalQueryResult.fetching}>
              <UpdatePlaceForm
                form={form}
                formProps={{
                  disabled: fetching,
                  onFinish: handleFormSubmit,
                }}
                handleCitySelect={handleCitySelect}
                updateInternalStates={updateInternalStates}
                updateInternalStatesCallback={() =>
                  setUpdateInternalStates(undefined)
                }
                onLoadCityOptions={() => form.setFieldValue('city_id', city_id)}
              />
            </Spin>
          </Col>
          <Col lg={12}>
            <Typography.Paragraph type="secondary">
              {t(`${UPDATE_PLACE_MODAL_TRANSLATION_PATH}.help.map`)}
            </Typography.Paragraph>
            <div className="create-place-modal__map-container">
              <Map
                {...coordinates}
                reuseMaps
                ref={mapRef}
                mapStyle={MapStyle.streets}
                style={{ height: '100%', width: '100%' }}
                onMove={handleMapMove}
                onClick={handleMarkerMove}
              >
                {marker && (
                  <Marker {...marker} onDrag={handleMarkerMove} draggable />
                )}
              </Map>
            </div>
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default UpdatePlaceModal;
