import { Button, Popconfirm, TableColumnsType, TableProps } from 'antd';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultTable } from '../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import { CompanyName } from '../../../../../components/Company/CompanyName/CompanyName';
import { FormattedDateTime } from '../../../../../components/Formatters/FormattedDateTime/FormattedDateTime';
import { UsersPageQuery } from '../../../../../graphql/generated/graphql';
import { PickPartial } from '../../../../../utils/interfaces';

export type IUsersPageTableItem = PickPartial<
  Omit<UsersPageQuery['FindManyUsers'][0], '__typename'>,
  'companies'
>;

export interface IUsersPageTableProps extends TableProps<IUsersPageTableItem> {
  hideCompaniesColumn?: boolean;
  handleUnlinkUser?: ({ userId }: { userId: string }) => void;
}

export const UsersPageTable = ({
  hideCompaniesColumn,
  handleUnlinkUser,
  ...props
}: IUsersPageTableProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.support.account.user.usersPageTable',
  });

  const navigate = useNavigate();

  const handleViewUser = (userId: string) => {
    navigate(`/support/accounts/user/${userId}`);
  };

  const columns: TableColumnsType<IUsersPageTableItem> = [
    {
      dataIndex: 'email',
      title: t('columns.email'),
      sorter: true,
    },
    {
      dataIndex: 'name',
      title: t('columns.name'),
      render: (name: string) => <div style={{ minWidth: 200 }}>{name}</div>,
      sorter: true,
    },
    {
      dataIndex: 'companies',
      title: t('columns.companies'),
      render: (companies: IUsersPageTableItem['companies']) => (
        <div style={{ minWidth: 300 }}>
          {companies?.map(({ company }) => (
            <React.Fragment key={company.id}>
              <CompanyName company={company} />
              <br />
            </React.Fragment>
          ))}
        </div>
      ),
    },
    {
      dataIndex: 'created_at',
      title: t('columns.createdAt'),
      render: (created_at: string) => (
        <div style={{ minWidth: 100 }}>
          <FormattedDateTime dateTime={created_at} />
        </div>
      ),
      defaultSortOrder: 'descend',
      sorter: true,
    },
    {
      dataIndex: 'updated_at',
      title: t('columns.updatedAt'),
      render: (updated_at: string) => (
        <div style={{ minWidth: 100 }}>
          <FormattedDateTime dateTime={updated_at} />
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: 'actions',
      title: t('columns.actions'),
      render: (_: unknown, record: IUsersPageTableItem) => (
        <>
          {record.active && (
            <>
              <Button onClick={() => handleViewUser(record.id)} type="link">
                {t('actions.view')}
              </Button>
              {handleUnlinkUser && (
                <Popconfirm
                  title={t('actions.delete.popconfirm.title')}
                  okText={t('actions.delete.popconfirm.actions.confirm')}
                  okButtonProps={{ danger: true, type: 'default' }}
                  cancelButtonProps={{ type: 'primary' }}
                  onConfirm={() => handleUnlinkUser({ userId: record.id })}
                >
                  <Button danger type="link">
                    {t('actions.delete.title')}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </>
      ),
    },
  ].filter(
    column => column.dataIndex !== 'companies' || !hideCompaniesColumn
  ) as TableColumnsType<IUsersPageTableItem>;

  const activeUsersId = props.dataSource
    ?.filter(user => user.active)
    ?.map(user => user.id);

  return (
    <DefaultTable
      columns={columns}
      rowKey={({ id }) => id}
      rowSelection={{
        columnTitle: t('columns.active'),
        selectedRowKeys: activeUsersId,
        getCheckboxProps: () => ({ disabled: true }),
        hideSelectAll: true,
        type: 'checkbox',
      }}
      {...props}
    />
  );
};
