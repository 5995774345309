import { TableColumnsType, TableProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '../../../../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import { FormattedDateTime } from '../../../../../../../../components/Formatters/FormattedDateTime/FormattedDateTime';
import { CompanyDetailsPageQuery } from '../../../../../../../../graphql/generated/graphql';

export type ICarrierCompanyOperationAreasTableItem = NonNullable<
  CompanyDetailsPageQuery['FindCompany']['operationArea']
>[0];

export type ICarrierCompanyOperationAreasTableProps =
  TableProps<ICarrierCompanyOperationAreasTableItem>;

export const CarrierCompanyOperationAreasTable = (
  props: ICarrierCompanyOperationAreasTableProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.carrier.carrierCompanyOperationAreasTable',
  });

  const columns: TableColumnsType<ICarrierCompanyOperationAreasTableItem> = [
    { dataIndex: ['state', 'initials'], title: t('columns.stateInitials') },
    { dataIndex: ['state', 'state_name'], title: t('columns.stateName') },
    {
      dataIndex: 'created_at',
      render: createdAt => <FormattedDateTime dateTime={createdAt} />,
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.createdAt'),
    },
    {
      dataIndex: 'updated_at',
      render: updatedAt => <FormattedDateTime dateTime={updatedAt} />,
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.updatedAt'),
    },
  ];

  return (
    <DefaultTable
      columns={columns}
      rowKey={({ state }) => state.initials}
      {...props}
    />
  );
};
