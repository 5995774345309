import { Select, SelectProps } from 'antd';
import { ReactElement } from 'react';
import Formatter from '../../../classes/Formatter';
import {
  CompanySelectFragment,
  FreightCompanySelectFragment,
} from '../../../graphql/generated/graphql';
import { CompanyName } from '../CompanyName/CompanyName';

export interface ICompanySelectProps
  extends Omit<SelectProps, 'options' | 'children'> {
  options?: (CompanySelectFragment | FreightCompanySelectFragment)[];
}

export const CompanySelect = ({
  options,
  ...props
}: ICompanySelectProps): ReactElement => {
  const companiesOptions = options?.map(company => ({
    label: `${Formatter.formatCPF_CNPJ(
      company.document_number
    )} - ${CompanyName.getCompanyName(company)}`,
    value: company.id,
  }));

  return (
    <Select optionFilterProp="label" {...props} options={companiesOptions} />
  );
};
