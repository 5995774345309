import { Col, Form, Modal, ModalProps, Row } from 'antd';
import { ReactElement } from 'react';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import {
  ILinkUserToCompanyFormValues,
  LinkUserToCompanyForm,
} from '../LinkUserToCompanyForm/LinkUserToCompanyForm';
import {
  Exact,
  LinkUserToCompanyForm_FindCompaniesQuery,
  LinkUserToCompanyForm_FindManyUsersQuery,
  LinkUserToCompanyInput,
  LinkUserToCompanyMutation,
  useLinkUserToCompanyMutation,
} from '../../../../../graphql/generated/graphql';
import { useTranslation } from 'react-i18next';
import { OperationResult } from 'urql';

export type IMutationSuccess = (
  value: OperationResult<
    LinkUserToCompanyMutation,
    Exact<{ input: LinkUserToCompanyInput }>
  >
) => void;

export interface ILinkUserToCompanyModalProps extends ModalProps {
  user?: LinkUserToCompanyForm_FindManyUsersQuery['FindManyUsers'][number];
  company?: LinkUserToCompanyForm_FindCompaniesQuery['FindCompanies'][number];
  onMutationSuccess?: IMutationSuccess;
}

export const LinkUserToCompanyModal = ({
  user,
  company,
  onMutationSuccess,
  ...props
}: ILinkUserToCompanyModalProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.components.linkUserToCompanyModal',
  });

  const [form] = Form.useForm<ILinkUserToCompanyFormValues>();

  const [linkUserToCompanyMutationResult, executeMutation] =
    useLinkUserToCompanyMutation();

  const handleFinish = (values: ILinkUserToCompanyFormValues) => {
    executeMutation({
      input: {
        user_id: values.userId,
        company_id: values.companyId,
        role_name: values.roleName,
      },
    }).then(result => {
      form.resetFields();

      onMutationSuccess && onMutationSuccess(result);
    });
  };

  return (
    <Modal
      okButtonProps={{ loading: linkUserToCompanyMutationResult.fetching }}
      okText={t('actions.create')}
      onOk={() => form.submit()}
      title={t('title')}
      {...props}
    >
      <Row gutter={[0, 24]}>
        <ErrorAlert error={linkUserToCompanyMutationResult.error} />
        <Col span={24}>
          <LinkUserToCompanyForm
            key={linkUserToCompanyMutationResult.data?.LinkUserToCompany.id}
            company={company}
            disabled={linkUserToCompanyMutationResult.fetching}
            form={form}
            onFinish={handleFinish}
            user={user}
          />
        </Col>
      </Row>
    </Modal>
  );
};
