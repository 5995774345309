/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Form, Popconfirm, Row, notification } from 'antd';
import jwtDecode from 'jwt-decode';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import Footer from '../../../components/container/heroContainer/heroCardContainer/footer';
import InputText from '../../../components/inputs/inputText';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import { useRequest } from '../../../hooks/useRequest';
import UserController from '../../../structures/controllers/User';
import { CompanyFunctionReverseEnum } from '../../../structures/interfaces/Company/CompanyFunction';
import { IDecodedTokenUser } from '../../../structures/interfaces/User';

import './CreatePasswordPage.scss';

export const CreatePasswordPage = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const locationState = location.state as { token?: string } | undefined;

  const [createUserRequest, isCreateUserRequesting] = useRequest(
    UserController.createUser
  );

  const [token, setToken] = useState<string>();
  const [decodedToken, setDecodedToken] = useState<IDecodedTokenUser>();

  useEffect(() => {
    if (!locationState?.token) {
      navigate('/', { replace: true });
    } else {
      if (locationState.token) {
        try {
          const decodedTokenUser = jwtDecode<IDecodedTokenUser>(
            locationState.token
          );

          if (!decodedTokenUser.company_function) {
            throw new Error('Invalid Token');
          }

          setToken(locationState.token);
          setDecodedToken(decodedTokenUser);
        } catch {
          notification.error({
            key: locationState.token,
            message: t(
              'pages.createPassword.notification.invalidToken.message'
            ),
            description: t(
              'pages.createPassword.notification.invalidToken.description'
            ),
            duration: 15,
          });

          navigate('/', { replace: true });
        }
      }
    }
  }, []);

  const [form] = Form.useForm();

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = ({ password }: { password: string }) => {
    if (token && decodedToken) {
      createUserRequest({
        token,
        password,
      })
        .then(({ token }) => {
          navigate('/termsOfUse', {
            replace: true,
            state: {
              token,
              email: decodedToken.email,
              password: password,
              companyFunction:
                CompanyFunctionReverseEnum[decodedToken.company_function],
            },
          });
        })
        .catch(err =>
          notification.error({
            message: t(err.message),
            description: t(err.description),
          })
        );
    }
  };

  const passwordRules = [
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}`.length < 6) {
          return Promise.reject();
        }

        return Promise.resolve();
      },
      message: 'pages.createPassword.fields.rules.passwordMinLength',
      validateTrigger: 'onBlur',
    },
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}`.length > 128) {
          return Promise.reject();
        }

        return Promise.resolve();
      },
      message: 'pages.createPassword.fields.rules.passwordMaxLength',
    },
  ];

  const confirmPasswordRules = [
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}` === form.getFieldsValue(['password'])['password']) {
          return Promise.resolve();
        }

        return Promise.reject();
      },
      message: 'pages.createPassword.fields.rules.passwordNotMatch',
    },
  ];

  const createPasswordForm = (
    <Form
      form={form}
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
    >
      <Row className="form-group">
        <InputText
          formItem
          validation
          size="large"
          name="password"
          type={'password'}
          label={t('pages.createPassword.fields.labels.password')}
          placeholder={t('pages.createPassword.fields.placeholders.password')}
          otherRules={passwordRules}
          otherPropsFormItem={{
            validateTrigger: ['onChange', 'onBlur'],
          }}
        />
        <InputText
          formItem
          validation
          size="large"
          name="confirmPassword"
          type={'password'}
          label={t('pages.createPassword.fields.labels.confirmPassword')}
          placeholder={t(
            'pages.createPassword.fields.placeholders.confirmPassword'
          )}
          otherRules={confirmPasswordRules}
          otherPropsFormItem={{
            hasFeedback: true,
            dependencies: ['password'],
          }}
        />
      </Row>
    </Form>
  );

  const cancelButton = (
    <Popconfirm
      title={t('pages.createPassword.confirm.cancel')}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={handleCancel}
    >
      <Button
        danger
        size="large"
        type="primary"
        disabled={isCreateUserRequesting}
      >
        {t('pages.createPassword.action.cancel')}
      </Button>
    </Popconfirm>
  );

  const submitButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      onClick={handleSubmit}
      loading={isCreateUserRequesting}
    >
      {t('pages.createPassword.action.submit')}
    </Button>
  );

  const formHeader = (
    <TitleHeader
      centered
      titleText={t('pages.createPassword.title')}
      subtitleText={t('pages.createPassword.description')}
    />
  );

  const formFooter = (
    <Footer>
      {cancelButton}
      {submitButton}
    </Footer>
  );

  return (
    <HeroCardContainer
      logoColumnSize="small"
      className="create-password_container"
    >
      <Card className="create-password_card" bordered={false}>
        <SupportHelpContact />
        {formHeader}
        {createPasswordForm}
      </Card>
      {formFooter}
    </HeroCardContainer>
  );
};

export default CreatePasswordPage;
