import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../components/Access/Access';
import { useMenuItem } from '../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../hooks/useTitle/useTitle';
import { QuotationGroups } from './components/QuotationGroups/QuotationGroups';

export const QuotationGroupsPage = (): ReactElement => {
  useTitle({ title: 'tab.shipper.quotationGroups.index' });
  useMenuItem({
    selectedMenu: ['/shipper/quotationGroups'],
  });

  return <QuotationGroups />;
};

const QuotationGroupsPageAccess = Access(QuotationGroupsPage, {
  acceptedPermissions: [{ module: 'GROUP', actions: ['CREATE'] }],
  fallback: <Navigate to="/" replace />,
});

export default QuotationGroupsPageAccess;
