import { ReactElement } from 'react';

import { Menu } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext/useAuthContext';
import { useLayoutContext } from '../../../../contexts/LayoutContext/useLayoutContext';
import { getAllowedMenuItems, getMenuItems } from './MenuItems';

export const SidebarMenu = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = useAuthContext();
  const {
    selectedMenu,
    setSelectedMenu,
    openMenu,
    setOpenMenu,
    setIsMobileMenuOpen,
  } = useLayoutContext();

  const handleSelect = ({ selectedKeys }: { selectedKeys: string[] }) => {
    setSelectedMenu(selectedKeys);
  };

  const handleClick = ({ key }: { key: string }) => {
    if (key !== location.pathname) {
      setIsMobileMenuOpen(false);
      navigate(key);
    }
  };

  const handleOpenChange = (openKeys: string[]) => {
    if (openKeys.length <= 1) {
      setOpenMenu(openKeys);
    } else {
      const openKey = openKeys.find(
        key => !openMenu.some(openMenuKey => openMenuKey === key)
      );
      if (openKey) {
        setOpenMenu([openKey]);
      } else {
        setOpenMenu([]);
      }
    }
  };

  const menuItems = getMenuItems(t);

  const allowedMenuItems = getAllowedMenuItems(menuItems, permissions);

  return (
    <Menu
      defaultSelectedKeys={['/home']}
      items={allowedMenuItems}
      mode="inline"
      onClick={handleClick}
      onOpenChange={handleOpenChange}
      onSelect={handleSelect}
      openKeys={openMenu}
      selectedKeys={selectedMenu}
    />
  );
};

const ScrollableSidebarContainer = ({
  children,
}: {
  children: ReactElement;
}) => {
  return (
    <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 64px)' }}>
      {children}
    </div>
  );
};

SidebarMenu.ScrollableSidebarContainer = ScrollableSidebarContainer;

export default SidebarMenu;
