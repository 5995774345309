/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Form, FormProps, Input, Row, Space } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import { Volume } from '../../../../core/Freight/Volume';
import {
  ConfirmQuotationFormFragment,
  CreateQuotationConfirmation,
  FindDriverQueryVariables,
  UnitTypeEnum,
  useFindDriverQuery,
} from '../../../../graphql/generated/graphql';
import { parseLocaleNumber } from '../../../../utils';
import { VehicleTypeSelect } from '../../../Vehicle/VehicleTypeSelect/VehicleTypeSelect';
import { IConfirmQuotationStatus } from '../ConfirmQuotation/ConfirmQuotation';
import { IQuotationConfirmationTableItem } from '../QuotationConfirmationTable/QuotationConfirmationTable';

export interface IConfirmQuotationFormValues
  extends Omit<
    CreateQuotationConfirmation,
    'vehicle_type_id' | 'assigned_volume'
  > {
  id?: string;
  driver_id?: string;
  vehicle_type: {
    label: string;
    value: string;
  };
  assigned_volume: string;
}

interface IConfirmQuotationFormProps
  extends FormProps<IConfirmQuotationFormValues> {
  quotation?: ConfirmQuotationFormFragment;
  editingConfirmation?: IConfirmQuotationFormValues;
  status?: IConfirmQuotationStatus;
  quotationConfirmations?: IQuotationConfirmationTableItem[];
}

const TRANSLATION_PATH =
  'components.quotation.confirmQuotation.confirmQuotationForm';

export const ConfirmQuotationForm = ({
  quotation,
  editingConfirmation,
  disabled,
  status,
  quotationConfirmations,
  ...formProps
}: IConfirmQuotationFormProps): ReactElement => {
  const { t } = useTranslation();

  const { userData } = useUserContext();

  const [driverName, setDriverName] = useState<string>();

  const cnh = Form.useWatch('cnh', formProps.form);

  const variables: FindDriverQueryVariables | undefined =
    cnh && userData?.company
      ? {
          cnh,
          carrier_id: userData?.company?.id,
        }
      : undefined;

  const [findDriverResult, executeFindDriverQuery] = useFindDriverQuery({
    variables,
    pause: true,
  });

  const hasUnallocatedVolume =
    quotation?.unallocated_volume && quotation.unallocated_volume > 0;

  const assignedVolumeValidator = (
    _: unknown,
    assignedVolume: IConfirmQuotationFormValues['assigned_volume']
  ) => {
    if (!hasUnallocatedVolume) {
      return Promise.reject(
        t(`${TRANSLATION_PATH}.validations.hasNoUnallocatedVolume`)
      );
    }

    const unitType = quotation?.freight.unit_type;

    const assignedVolumeValue = Volume.Utils.formatVolumeInputToBaseUnitType(
      assignedVolume,
      unitType
    );

    if (
      assignedVolumeValue &&
      quotation?.unallocated_volume &&
      assignedVolumeValue > quotation.unallocated_volume
    ) {
      return Promise.reject(
        t(`${TRANSLATION_PATH}.validations.assignedVolume`)
      );
    }

    return Promise.resolve();
  };

  const placesBalanceValidator = (
    _: unknown,
    assignedVolume: IConfirmQuotationFormValues['assigned_volume']
  ) => {
    const places = quotation?.freight.route.places?.filter(
      ({ has_balance, balance_capacity }) =>
        has_balance && balance_capacity && Number(balance_capacity)
    );

    const unitType = quotation?.freight.unit_type;

    const assignedVolumeValue = Volume.Utils.formatVolumeInputToBaseUnitType(
      assignedVolume,
      unitType
    );

    if (unitType === UnitTypeEnum.Unit) {
      return Promise.resolve();
    }

    if (assignedVolumeValue && places) {
      const overloadedBalancePlace = places.find(
        place => assignedVolumeValue > Number(place.balance_capacity)
      );

      if (overloadedBalancePlace) {
        return Promise.reject(
          t(`${TRANSLATION_PATH}.validations.placesBalance.message`, {
            place: t(
              `${TRANSLATION_PATH}.validations.placesBalance.${overloadedBalancePlace.move_type}`
            ),
          })
        );
      }
    }

    return Promise.resolve();
  };

  const repeatedNumbersValidator = (
    _: unknown,
    cnh: IConfirmQuotationFormValues['cnh']
  ) => {
    if (/(\d)\1{10}/.test(cnh)) {
      return Promise.reject(
        t(`${TRANSLATION_PATH}.validations.repeatedNumbers`)
      );
    }

    return Promise.resolve();
  };

  const alreadyConfirmedDriverValidator = (
    _: unknown,
    cnh: IConfirmQuotationFormValues['cnh']
  ) => {
    if (
      quotationConfirmations?.find(
        ({ driver }) =>
          driver.cnh === cnh && driver.cnh !== editingConfirmation?.cnh
      )
    ) {
      return Promise.reject(
        t(`${TRANSLATION_PATH}.validations.alreadyConfirmedDriverValidator`)
      );
    }

    return Promise.resolve();
  };

  const handleCnhChange = () => {
    if (driverName) {
      setDriverName(undefined);
    }
  };

  const handleCnhBlur = () => {
    formProps.form
      ?.validateFields(['cnh'])
      .then(() => {
        executeFindDriverQuery();
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (editingConfirmation) {
      formProps.form?.setFieldsValue({
        ...editingConfirmation,
      });
    }
  }, [editingConfirmation]);

  useEffect(() => {
    if (findDriverResult.data) {
      setDriverName(findDriverResult.data.FindDriver.name);
    } else {
      const driver = quotationConfirmations?.find(
        confirmation => confirmation.driver.cnh === cnh
      )?.driver;

      if (driver) {
        setDriverName(driver.name);
      } else {
        setDriverName(undefined);
      }
    }
  }, [findDriverResult.data, findDriverResult.error]);

  useEffect(() => {
    formProps.form?.setFieldValue('driver_name', driverName);
  }, [driverName]);

  const colSpan = {
    xxl: 4,
    xl: 6,
    lg: 8,
    md: 12,
    sm: 24,
    xs: 24,
  };

  return (
    <Form layout="vertical" {...formProps} disabled={disabled}>
      <Row gutter={[24, 24]}>
        {status && status !== 'EXPIRED' && (
          <Col span={24}>
            <Alert
              type="info"
              closable
              description={t(`${TRANSLATION_PATH}.alert`, {
                addButton: t(`${TRANSLATION_PATH}.actions.add`),
                confirmButton: t('general.confirm'),
              })}
            />
          </Col>
        )}
        <Form.Item name={'id'} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={'driver_id'} hidden>
          <Input />
        </Form.Item>
        <Col {...colSpan}>
          <Form.Item
            label={t(`${TRANSLATION_PATH}.fields.cnh`)}
            name={'cnh'}
            normalize={(value: string) => value.replace(/[^0-9]/g, '')}
            required
            rules={[
              { required: true },
              {
                len: 11,
                type: 'string',
                validateTrigger: 'onBlur',
              },
              { validator: repeatedNumbersValidator },
              { validator: alreadyConfirmedDriverValidator },
            ]}
            validateTrigger={['onChange', 'onBlur']}
            validateFirst
          >
            <Input
              disabled={!!editingConfirmation || disabled}
              maxLength={11}
              onChange={handleCnhChange}
              onBlur={handleCnhBlur}
            />
          </Form.Item>
        </Col>
        <Col {...colSpan}>
          <Form.Item
            label={t(`${TRANSLATION_PATH}.fields.driver_name`)}
            name={'driver_name'}
            required
            rules={[{ required: true }]}
            validateFirst
            hasFeedback={findDriverResult.fetching}
            validateStatus="validating"
          >
            <Input
              disabled={
                !!editingConfirmation ||
                disabled ||
                findDriverResult.fetching ||
                !!driverName
              }
            />
          </Form.Item>
        </Col>
        <Col {...colSpan}>
          <Form.Item
            label={t(`${TRANSLATION_PATH}.fields.vehicle_type`)}
            name={'vehicle_type'}
            required
            rules={[{ required: true }]}
            validateFirst
          >
            <VehicleTypeSelect
              placeholder={t('general.select')}
              labelInValue
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={6} xxl={0} />
        <Col {...colSpan}>
          <Volume.FormItem
            label={t(`${TRANSLATION_PATH}.fields.assigned_volume`)}
            name={'assigned_volume'}
            required
            rules={[
              { required: true },
              { type: 'number', min: 1, transform: parseLocaleNumber },
              { type: 'number', max: 1000000, transform: parseLocaleNumber },
              { validator: assignedVolumeValidator },
              { validator: placesBalanceValidator, warningOnly: true },
            ]}
            unitType={quotation?.freight.unit_type}
          >
            <Volume.Input
              max={quotation?.unallocated_volume || 1000000}
              min={1}
              unitType={quotation?.freight.unit_type}
            />
          </Volume.FormItem>
        </Col>
        <Col {...colSpan}>
          <Form.Item
            label={t(`${TRANSLATION_PATH}.fields.vehicle_plate`)}
            name={'vehicle_plate'}
            required
            normalize={Formatter.formatMercosulPlate}
            rules={[
              {
                required: true,
              },
              {
                len: 8,
                validateTrigger: 'onBlur',
              },
            ]}
            validateTrigger={['onChange', 'onBlur']}
            validateFirst
          >
            <Input placeholder="ABC-1234" />
          </Form.Item>
        </Col>
        <Col {...colSpan}>
          <Form.Item label={' '}>
            {editingConfirmation ? (
              <Space size="large">
                <Button type="primary" htmlType="submit">
                  {t(`general.save`)}
                </Button>
                <Button htmlType="reset">{t(`general.cancel`)}</Button>
              </Space>
            ) : (
              <Button type="primary" htmlType="submit">
                {t(`${TRANSLATION_PATH}.actions.add`)}
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
