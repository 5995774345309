import { Checkbox, TableColumnsType, TableProps } from 'antd';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '../../../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import {
  UserDetailsPage_FindUserPermissionTemplateQuery,
  UserPermissionsTableFragment,
} from '../../../../../../../graphql/generated/graphql';

export interface IAction {
  id: string;
  enabled: boolean;
}

export interface IModule {
  name: string;
  actions: Record<string, IAction>;
}

export type IPermissionGroupedByModule = Record<string, IModule>;

export type IUserPermissionsTableFormValues = Record<string, boolean>;

export interface IUserPermissionsTableEditModeProps {
  isEditing: boolean;
  permissionsTemplate?: UserDetailsPage_FindUserPermissionTemplateQuery['FindPermissionTemplate'][0][];
  permissions: IUserPermissionsTableFormValues;
  setPermissions: Dispatch<SetStateAction<IUserPermissionsTableFormValues>>;
}

export interface IUserPermissionsTableProps
  extends TableProps<IPermissionGroupedByModule[string]> {
  rawDataSource?: UserPermissionsTableFragment[] | null;
  editMode?: IUserPermissionsTableEditModeProps;
}

export const UserPermissionsTable = ({
  dataSource,
  rawDataSource,
  editMode,
  ...props
}: IUserPermissionsTableProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.user.userDetailsPage.components.userPermissionsTable',
  });

  const { t: t0 } = useTranslation();

  const handleCheckboxChange = (value: IAction) => {
    editMode?.setPermissions(permissions => {
      permissions[value.id] = value.enabled;
      return { ...permissions };
    });
  };

  const isPermissionAvailable = (permissionId: string) => {
    if (!editMode?.permissionsTemplate) return true;

    const permission = editMode.permissionsTemplate.find(
      ({ permission_id }) => permissionId === permission_id
    );

    return permission?.available;
  };

  const permissionsGroupedByModule = rawDataSource
    ? getPermissionsGroupedByModule(rawDataSource)
    : dataSource;

  const columns: TableColumnsType<IPermissionGroupedByModule[string]> = [
    {
      dataIndex: 'name',
      title: t('columns.module'),
      render: (module: string) => (
        <div style={{ minWidth: 150 }}>{t0(`enums.modules.${module}`)}</div>
      ),
    },
    {
      align: 'center',
      dataIndex: ['actions', 'READ'],
      title: t0('enums.permissionActions.READ'),
      render: (action: IAction) => (
        <>
          {isPermissionAvailable(action?.id) && (
            <Checkbox
              onChange={event =>
                handleCheckboxChange({
                  ...action,
                  enabled: event.target.checked,
                })
              }
              checked={editMode?.permissions[action?.id]}
              disabled={!editMode?.isEditing}
            />
          )}
        </>
      ),
    },
    {
      align: 'center',
      dataIndex: ['actions', 'CREATE'],
      title: t0('enums.permissionActions.CREATE'),
      render: (action: IAction) => (
        <>
          {isPermissionAvailable(action?.id) && (
            <Checkbox
              onChange={event =>
                handleCheckboxChange({
                  ...action,
                  enabled: event.target.checked,
                })
              }
              checked={editMode?.permissions[action?.id]}
              disabled={!editMode?.isEditing}
            />
          )}
        </>
      ),
    },
    {
      align: 'center',
      dataIndex: ['actions', 'UPDATE'],
      title: t0('enums.permissionActions.UPDATE'),
      render: (action: IAction) => (
        <>
          {isPermissionAvailable(action?.id) && (
            <Checkbox
              onChange={event =>
                handleCheckboxChange({
                  ...action,
                  enabled: event.target.checked,
                })
              }
              checked={editMode?.permissions[action?.id]}
              disabled={!editMode?.isEditing}
            />
          )}
        </>
      ),
    },
    {
      align: 'center',
      dataIndex: ['actions', 'DELETE'],
      title: t0('enums.permissionActions.DELETE'),
      render: (action: IAction) => (
        <>
          {isPermissionAvailable(action?.id) && (
            <Checkbox
              onChange={event =>
                handleCheckboxChange({
                  ...action,
                  enabled: event.target.checked,
                })
              }
              checked={editMode?.permissions[action?.id]}
              disabled={!editMode?.isEditing}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <DefaultTable
      columns={columns}
      dataSource={Object.values(permissionsGroupedByModule || {})}
      pagination={{ hideOnSinglePage: true, pageSize: 5 }}
      rowKey={({ name }) => name}
      {...props}
    />
  );
};

const getPermissionsGroupedByModule = (
  permissions?: UserPermissionsTableFragment[] | null
) => {
  const permissionsGroupByModule: IPermissionGroupedByModule | undefined =
    permissions?.reduce(
      (acc, { enable, user_permission: { action, module, id } }) => {
        const permission = acc[module.name] || { actions: {} };

        permission['name'] = module.name;
        permission['actions'][action.name] = {
          id,
          enabled: enable,
        };

        return { ...acc, [module.name]: permission };
      },
      {} as IPermissionGroupedByModule
    );

  return permissionsGroupByModule;
};

UserPermissionsTable.getPermissionsGroupedByModule =
  getPermissionsGroupedByModule;
