import { App, Form } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAttachmentForm_AttachFileMutation } from '../../../../../graphql/generated/graphql';
import { Attachments } from '../../../attachments';
import { IAttachmentFormValues } from '../../../attachments/AttachmentForm/AttachmentForm';

export const useAttachmentFormModal = () => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.freight.freightDetails.components.attachmentFormModal.useAttachmentFormModal',
  });

  const [form] = Form.useForm();

  const {
    isOpen,
    handlers: { onClose: handleClose, onOpen: handleOpen },
  } = Attachments.useAttachmentModal();

  const [result, executeMutation] = useAttachmentForm_AttachFileMutation();

  const serializer = Attachments.useAttachmentFormSerializer();

  const { message } = App.useApp();

  const handleFinish = useCallback(
    (formValues: IAttachmentFormValues) => {
      return new Promise<Awaited<ReturnType<typeof executeMutation>>>(
        (resolve, reject) => {
          executeMutation(serializer(formValues))
            .then(response => {
              message.success({
                content: t('submitSuccess.message'),
              });

              form.resetFields();

              resolve(response);
            })
            .catch(reject);
        }
      );
    },
    [executeMutation, form, message, serializer, t]
  );

  return {
    form,
    handlers: {
      onClose: handleClose,
      onFinish: handleFinish,
      onOpen: handleOpen,
    },
    isOpen,
    result,
  };
};
