/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  CheckboxProps,
  Col,
  Form,
  FormInstance,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  SelectProps,
  TimePicker,
} from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import useCityStateSelects from '../../../../hooks/useCityStateSelects/useCityStateSelects';
import { UF } from '../../../../structures/interfaces/UF';
import { filterOptionByLabel, parseLocaleNumber } from '../../../../utils';

export interface IUpdatePlaceForm {
  city_id: number;
  state_id: string;
  opening_hour: [dayjs.Dayjs, dayjs.Dayjs];
  cadence: number;
  name: string;
  note?: string | null;
  latitude: string;
  longitude: string;
  has_balance?: boolean;
  balance_capacity?: string;
  has_dirt_road?: boolean;
  dirt_road_distance?: string;
  id: string;
  city_name: string;
}

export interface IUpdatePlaceFormInternalStates {
  state_id: UF;
  has_balance: boolean;
  has_dirt_road: boolean;
}

interface IUpdatePlaceFormProps {
  /**
   * Form instance for father component be able to retrieve the form data
   */
  form: FormInstance<IUpdatePlaceForm>;
  /**
   * Form component props
   */
  formProps?: Omit<FormProps, 'form'>;
  /**
   * Function to be executed when a city is selected in City field
   */
  handleCitySelect?: () => void;
  /**
   * State with data to update the internal states of this component
   */
  updateInternalStates?: IUpdatePlaceFormInternalStates;
  /**
   * Callback to be executed when internal states is updated
   */
  updateInternalStatesCallback?: () => void;
  /**
   * Select component props to display a Select Input instead a Text Input for Name field
   */
  onLoadCityOptions?: () => void;
  usePlaceNameSelect?: {
    onSelect: (placeId?: string) => void;
    options?: SelectProps['options'];
    fetching?: boolean;
  };
}

/**
 * Form to update place data
 */
export const UpdatePlaceForm = ({
  form,
  formProps,
  handleCitySelect = () => {
    return;
  },
  updateInternalStates,
  updateInternalStatesCallback,
  onLoadCityOptions,
  usePlaceNameSelect,
}: IUpdatePlaceFormProps): ReactElement => {
  const UPDATE_PLACE_FORM_TRANSLATION_PATH =
    'components.freight.place.updatePlaceForm';

  const { t } = useTranslation();

  const [hasDirtRoad, setHasDirtRoad] = useState(false);
  const [hasBalance, setHasBalance] = useState(false);

  const { selectProps: stateSelectProps, ...stateFormItemProps } = {
    name: 'state_id',
    required: true,
    rules: [{ required: true }],
    label: t(`${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.state_id`),
    selectProps: {
      allowClear: true,
      className: 'ant-select-in-form-item',
      disabled: true,
      showSearch: true,
      placeholder: t(
        `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.state_id`
      ),
      size: 'small',
    } as SelectProps,
  };

  const { selectProps: citySelectProps, ...cityFormItemProps } = {
    name: 'city_id',
    required: true,
    rules: [{ required: true }],
    label: t(`${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.city_id`),
    selectProps: {
      allowClear: true,
      className: 'ant-select-in-form-item',
      disabled: true,
      showSearch: true,
      placeholder: t(
        `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.city_id`
      ),
      size: 'small',
      onSelect: handleCitySelect,
    } as SelectProps,
  };

  const [stateFormItem, cityFormItem, setSelectedState] = useCityStateSelects({
    form,
    state: { ...stateFormItemProps, selectProps: stateSelectProps },
    city: {
      ...cityFormItemProps,
      selectProps: citySelectProps,
      onLoadOptions: onLoadCityOptions,
    },
  });

  const handleHasDirtRoad: NonNullable<CheckboxProps['onChange']> = event => {
    if (!event.target.checked) {
      form.setFieldValue('dirt_road_distance', undefined);
    }

    setHasDirtRoad(event.target.checked);
  };

  const handleHasBalance: NonNullable<CheckboxProps['onChange']> = event => {
    if (!event.target.checked) {
      form.setFieldValue('balance_capacity', undefined);
    }

    setHasBalance(event.target.checked);
  };

  useEffect(() => {
    if (updateInternalStates && updateInternalStatesCallback) {
      setSelectedState(updateInternalStates.state_id);
      setHasBalance(updateInternalStates.has_balance);
      setHasDirtRoad(updateInternalStates.has_dirt_road);
      updateInternalStatesCallback();
    }
  }, [updateInternalStates]);

  return (
    <Form form={form} layout="vertical" scrollToFirstError {...formProps}>
      <Row gutter={24}>
        <Form.Item name="id" noStyle hidden>
          <Input />
        </Form.Item>
        <Form.Item name="latitude" noStyle hidden>
          <Input />
        </Form.Item>
        <Form.Item name="longitude" noStyle hidden>
          <Input />
        </Form.Item>
        <Col xs={24} sm={16}>
          <Form.Item
            required
            name="name"
            rules={[{ required: true }]}
            label={t(
              `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.name`
            )}
          >
            {usePlaceNameSelect ? (
              <Select
                allowClear
                className="ant-select-in-form-item"
                disabled={usePlaceNameSelect.fetching}
                filterOption={filterOptionByLabel}
                loading={usePlaceNameSelect.fetching}
                onClear={() => usePlaceNameSelect.onSelect()}
                onSelect={usePlaceNameSelect.onSelect}
                options={usePlaceNameSelect.options}
                placeholder={t(
                  `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.name`
                )}
                showSearch
                size="small"
              />
            ) : (
              <Input
                allowClear
                size="small"
                placeholder={t(
                  `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.name`
                )}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          {usePlaceNameSelect ? (
            <Form.Item {...stateFormItemProps}>
              <Select {...stateSelectProps} />
            </Form.Item>
          ) : (
            stateFormItem
          )}
        </Col>
        <Col xs={24} sm={12}>
          {usePlaceNameSelect ? (
            <>
              <Form.Item {...cityFormItemProps} name="city_name">
                <Select {...citySelectProps} />
              </Form.Item>
              <Form.Item name="city_id" noStyle hidden>
                <Input />
              </Form.Item>
            </>
          ) : (
            cityFormItem
          )}
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item
            required
            name="opening_hour"
            rules={[{ required: true }]}
            label={t(
              `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.opening_hour`
            )}
          >
            <TimePicker.RangePicker
              format="HH:mm"
              size="small"
              style={{ width: '100%' }}
              placeholder={[
                t(
                  `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.opening_hour`
                ),
                t(
                  `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.opening_hour`
                ),
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item
            required
            validateFirst
            name="cadence"
            rules={[
              { required: true },
              { min: 1, type: 'number' },
              { max: 2000000000, type: 'number' },
            ]}
            label={t(
              `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.cadence`
            )}
          >
            <InputNumber size="small" precision={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item name="has_dirt_road" valuePropName="checked">
            <Checkbox onChange={handleHasDirtRoad}>
              {t(
                `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.has_dirt_road`
              )}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item
            validateFirst
            name="dirt_road_distance"
            normalize={Formatter.formatMoney}
            dependencies={['has_dirt_road']}
            rules={[
              { required: hasDirtRoad },
              {
                min: 0.001,
                type: 'number',
                transform: parseLocaleNumber,
              },
              {
                max: 2000000,
                type: 'number',
                transform: parseLocaleNumber,
              },
            ]}
            label={t(
              `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.dirt_road_distance`
            )}
          >
            <Input
              size="small"
              disabled={!hasDirtRoad}
              addonAfter={
                <>{t('enums.measureUnits.length.kilometer.initials')}</>
              }
              placeholder={t(
                `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.dirt_road_distance`
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item name="has_balance" valuePropName="checked">
            <Checkbox onChange={handleHasBalance}>
              {t(
                `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.has_balance`
              )}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item
            validateFirst
            name="balance_capacity"
            normalize={Formatter.formatVolume}
            dependencies={['has_balance']}
            rules={[
              { required: hasBalance },
              {
                min: 0.001,
                type: 'number',
                transform: parseLocaleNumber,
              },
              {
                max: 1000000,
                type: 'number',
                transform: parseLocaleNumber,
              },
            ]}
            label={t(
              `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.balance_capacity`
            )}
          >
            <Input
              size="small"
              disabled={!hasBalance}
              addonAfter={<>{t('enums.measureUnits.mass.tonne.initials')}</>}
              placeholder={t(
                `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.balance_capacity`
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item
            name="note"
            label={t(
              `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.labels.note`
            )}
          >
            <Input.TextArea
              size="small"
              placeholder={t(
                `${UPDATE_PLACE_FORM_TRANSLATION_PATH}.inputs.placeholders.note`
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
