import { PriceDisplay } from './PriceDisplay/PriceDisplay';
import { PriceInput } from './PriceInput/PriceInput';
import { PriceFormItem } from './PriceInput/PriceInput.components';
import { PriceInputUtils } from './PriceInput/PriceInput.utils';

export const Price = {
  Display: PriceDisplay,
  Input: PriceInput,
  FormItem: PriceFormItem,
  Utils: PriceInputUtils,
};
