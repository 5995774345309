/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Form, notification, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Formatter from '../../../classes/Formatter';
import Button from '../../../components/AntdCustom/Button/Button';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import Footer from '../../../components/container/heroContainer/heroCardContainer/footer';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import { IUserData } from '../../../contexts/UserContext/UserContext';
import { useRequest } from '../../../hooks/useRequest';
import CompanyController from '../../../structures/controllers/Company';
import { ICarrierCompany } from '../../../structures/interfaces/Company/Company';
import {
  IComplementCompanyForm,
  ICreateCompanyForm,
  IRectifyCompanyForm,
} from '../../../structures/interfaces/Company/CompanyDto';
import {
  CompanyFunctionReverseEnum,
  ICompanyFunction,
} from '../../../structures/interfaces/Company/CompanyFunction';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { useSignUpContext } from '../SignUpPage/SignUpContext/useSignUpContext/useSignUpContext';
import CarrierCompanyOperationAreas from './formSections/carrierCompanyOperationAreas';
import CarrierPoliciesUpload from './formSections/carrierPoliciesUpload';
import CompanyAddress from './formSections/companyAddress';
import CompanyContact from './formSections/companyContact';
import CompanyData from './formSections/companyData';

import './index.scss';

export interface ICompanyFormCreateProps {
  action: 'create' | 'complete';
  userId: string;
  companyFunction: ICompanyFunction;
}

export interface ICompanyFormUpdateProps {
  action: 'update';
  companyFunction: ICompanyFunction;
  user: Required<IUserData>;
}

export const RegisterCompany = (
  props: ICompanyFormCreateProps | ICompanyFormUpdateProps
): React.ReactElement => {
  const REGISTER_COMPANY_TRANSLATION_PATH = `pages.registerCompany.${
    props.action === 'update' ? 'regularize' : 'register'
  }`;

  const { accessToken, clearTokens } = useSignUpContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const companyController = useMemo(
    () => new CompanyController({ accessToken: accessToken || '' }),
    [accessToken]
  );

  const [company, setCompany] = useState<ICarrierCompany | null>();

  const [userId] = useState(
    props.action === 'update' ? props.user.id : props.userId
  );

  const [companyFunction] = useState(props.companyFunction);

  const [companyRequest, isCompanyRequesting] = useRequest(
    companyController.findCompanyById
  );

  const [complementCompanyRequest, isComplementCompanyRequesting] = useRequest(
    companyController.complementCompany
  );

  const [createCompanyRequest, isCreateCompanyRequesting] = useRequest(
    companyController.createCompany
  );

  const [updateCompanyRequest, isUpdateCompanyRequesting] = useRequest(
    companyController.rectifyCompany
  );

  const [uploadLogoImageRequest, isUploadLogoImageRequesting] = useRequest(
    companyController.uploadCompanyLogo
  );

  const [deleteLogoImageRequest, isDeleteLogoImageRequesting] = useRequest(
    companyController.deleteCompanyLogo
  );

  const [uploadCarrierPolicyRequest, isUploadCarrierPolicyRequesting] =
    useRequest(companyController.uploadCarrierPolicy);

  const [deleteCarrierPolicyRequest, isDeleteCarrierPolicyRequesting] =
    useRequest(companyController.deleteCarrierPolicy);

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.action === 'update' && accessToken && !company) {
      companyRequest(props.user.company.id)
        .then(company => {
          setCompany(company);
        })
        .catch(({ message, description }) => {
          setCompany(null);

          notification.error({
            message: t(message),
            description: t(description),
          });

          navigate(-1);
        });
    }
  }, [accessToken, company]);

  useEffect(() => {
    if (props.action === 'update') {
      const parseCompanyToFormItems = (
        company: ICarrierCompany
      ): Partial<IComplementCompanyForm> => ({
        address: company.address.address,
        addressNumber: company.address.number,
        city: company.address.city.id,
        companyDocument:
          company.documentType === 'cpf'
            ? Formatter.formatCPF(company.documentNumber)
            : Formatter.formatCNPJ(company.documentNumber),
        companyFunction: company.companyFunction,
        companyName: company.companyName,
        companyTradeName: company.tradeName,
        companyType: company.companyType,
        complement: company.address.complement,
        district: company.address.districtName,
        districtId: company.address.districtId,
        email: company.contact.email,
        fleetQuantity: String(company.fleetQuantity || ''),
        hasFleet: company.hasFleet,
        id: company.id,
        motherCompanyDocument:
          company.motherCompany?.documentNumber &&
          company.motherCompany.documentType === 'cpf'
            ? Formatter.formatCPF(company.motherCompany.documentNumber)
            : Formatter.formatCNPJ(company.motherCompany?.documentNumber || ''),
        motherCompanyName: company.motherCompany?.tradeName,
        operationAreas: company.operationAreas,
        phoneNumber: Formatter.formatPhoneWithoutDDI(
          company.contact.phone.replace('+55', '')
        ),
        state: company.address.state.initials,
        userId: userId,
        zipCode: Formatter.formatZipCode(company.address.zipCode || ''),

        motherCompany: company.motherCompany,
        urlLogo: company.urlLogo,
      });

      if (company) {
        form.setFieldsValue(parseCompanyToFormItems(company));
      }
    }
  }, [company]);

  const handleCancel = () => {
    clearTokens();
    localStorage.removeItem('SignUp_refreshToken');
    navigate('/');
  };

  const handleSubmit = () => {
    const onFinish = (
      values: ICreateCompanyForm | IRectifyCompanyForm | IComplementCompanyForm
    ) => {
      let controllerAction;

      const formValues = {
        ...values,
        email: values.email.toLowerCase(),
      };

      if ('id' in formValues && formValues.id) {
        if (props.action === 'update') {
          controllerAction = updateCompanyRequest({
            ...formValues,
            oldOperationAreas: company?.operationAreas || [],
          });
        } else {
          controllerAction = complementCompanyRequest({
            ...formValues,
            userId: userId,
          });
        }
      } else {
        controllerAction = createCompanyRequest({
          ...formValues,
          companyFunction: companyFunction,
          userId: userId,
        });
      }

      controllerAction
        .then(({ id, companyFunction }) => {
          clearTokens();

          if (props.action === 'update') {
            navigate('/accountInApproval', {
              replace: true,
              state: { companyId: id },
            });
          } else {
            navigate('/companyCreated', {
              replace: true,
              state: { companyFunction },
            });
          }
        })
        .catch(({ message, description }) => {
          notification.error({
            message: t(message),
            description: t(description),
          });
        });
    };

    form
      .validateFields()
      .then(formValues => {
        onFinish(formValues);
      })
      .catch(({ errorFields }) => {
        if (errorFields) form.scrollToField(errorFields[0].name);
      });
  };

  const isRequesting =
    isComplementCompanyRequesting ||
    isCreateCompanyRequesting ||
    isUpdateCompanyRequesting;

  const CancelButton = ({ onClick }: { onClick?: () => void }) => (
    <Button
      danger
      size="large"
      type="primary"
      onClick={onClick}
      disabled={isRequesting}
    >
      {t(`${REGISTER_COMPANY_TRANSLATION_PATH}.action.cancel`)}
    </Button>
  );

  const popConfirmCancelButton = (
    <Popconfirm
      title={t(`${REGISTER_COMPANY_TRANSLATION_PATH}.confirm.cancel`)}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={handleCancel}
    >
      <CancelButton />
    </Popconfirm>
  );

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      isUploadLogoImageRequesting ||
      isDeleteLogoImageRequesting ||
      isUploadCarrierPolicyRequesting ||
      isDeleteCarrierPolicyRequesting
    );
  }, [
    isUploadLogoImageRequesting,
    isDeleteLogoImageRequesting,
    isUploadCarrierPolicyRequesting,
    isDeleteCarrierPolicyRequesting,
  ]);

  const submitButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      loading={isRequesting}
      disabled={isSubmitButtonDisabled}
      onClick={handleSubmit}
    >
      {t(`${REGISTER_COMPANY_TRANSLATION_PATH}.action.submit`)}
    </Button>
  );

  const formHeader = (
    <TitleHeader
      decorated
      titleText={t(
        `${REGISTER_COMPANY_TRANSLATION_PATH}.${companyFunction}Title`
      )}
      subtitleText={t(`${REGISTER_COMPANY_TRANSLATION_PATH}.subtitle`)}
    />
  );

  const formFooter = (
    <Footer>
      {popConfirmCancelButton}
      {submitButton}
    </Footer>
  );

  const carrierCompanyFields = useMemo(() => {
    if (props.action === 'create' || company != undefined) {
      return (
        <>
          <CarrierPoliciesUpload
            userId={userId}
            initialValues={{
              companyId: company?.id,
              policyFiles: company?.policies.map(
                ({ expiresIn, ...policyFile }) => ({
                  state: 'success',
                  expiresIn: dayjs(expiresIn),
                  ...policyFile,
                })
              ),
            }}
            uploadPolicyFileRequest={uploadCarrierPolicyRequest}
            deleteCarrierPolicyRequest={deleteCarrierPolicyRequest}
          />
          <CarrierCompanyOperationAreas />
        </>
      );
    }

    return <></>;
  }, [company]);

  const companyForm = (
    <Form
      form={form}
      className="form"
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
    >
      <CompanyData
        form={form}
        userId={userId}
        initialValues={
          company
            ? {
                companyDataAction: props.action,
                companyType: company.companyType,
                motherCompanyDocument: company.motherCompany?.documentNumber,
                uploadDefaultFileUrl: company.urlLogo,
                hasFleet: company.hasFleet,
                companyId: company.id,
              }
            : undefined
        }
        selectedCompanyFunction={companyFunction}
        uploadLogoImageRequest={uploadLogoImageRequest}
        deleteLogoImageRequest={deleteLogoImageRequest}
      />
      <CompanyAddress
        form={form}
        initialValues={{
          city: company?.address.city,
          state: company?.address.state.initials,
          validZipCode: company?.address.zipCode,
        }}
      />
      <CompanyContact />
      {companyFunction === 'carrier' && carrierCompanyFields}
    </Form>
  );

  if (isCompanyRequesting) return <LoadingPage asOverlay opaque />;

  return (
    <HeroCardContainer
      logoColumnSize="small"
      className="company-form_container"
    >
      <SupportHelpContact />
      <Card className="company-form_card" bordered={false}>
        {formHeader}
        {companyForm}
      </Card>
      {formFooter}
    </HeroCardContainer>
  );
};

const RegularizeCompanyGuard = ({
  action,
}: {
  action: 'create' | 'update';
}) => {
  const location = useLocation();

  if (action === 'create') {
    const locationState = location.state as
      | {
          userId?: string;
          companyFunction?: ICompanyFunction;
        }
      | undefined;

    if (!locationState?.userId || !locationState.companyFunction) {
      return <Navigate to={'/'} replace />;
    } else {
      return (
        <RegisterCompany
          action={action}
          userId={locationState.userId}
          companyFunction={locationState.companyFunction}
        />
      );
    }
  } else {
    const locationState = location.state as { user?: IUserData } | undefined;

    if (!locationState?.user || !locationState.user.company) {
      return <Navigate to={'/'} replace />;
    } else {
      return (
        <RegisterCompany
          action={action}
          user={locationState.user as Required<IUserData>}
          companyFunction={
            CompanyFunctionReverseEnum[
              locationState.user.company.company_function
            ]
          }
        />
      );
    }
  }
};

export default RegularizeCompanyGuard;
