import {
  Button,
  Card,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Volume } from '../../../../core/Freight/Volume';
import { Price } from '../../../../core/Price';
import { HiredFreightFragment } from '../../../../graphql/generated/graphql';
import { FormattedDate } from '../../../Formatters/FormattedDate/FormattedDate';
import { ProductWithVariety } from '../../../Product/Product/ProductWithVariety';
import { FreightStatus } from '../../FreightStatus/FreightStatus';

interface IHiredFreightTableProps {
  freights?: HiredFreightFragment[];
  loading?: boolean;
  onView: (id: string) => void;
}

export const HiredFreightTable = ({
  freights,
  loading,
  onView,
}: IHiredFreightTableProps) => {
  const HIRED_FREIGHT_TABLE_TRANSLATION_PATH =
    'components.freight.shipper.hiredFreightTable';

  const { t } = useTranslation();

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.title`)}
      </Typography.Text>
    </Row>
  );

  const columns: TableColumnsType<HiredFreightFragment> = [
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.route`),
      dataIndex: ['route', 'name'],
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.shipment_date`),
      dataIndex: 'shipment_date',
      render: shipmentDate => <FormattedDate dateTime={shipmentDate} />,
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.landing_date`),
      dataIndex: 'landing_date',
      render: landingDate => <FormattedDate dateTime={landingDate} />,
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product_type`),
      dataIndex: ['product_type', 'name'],
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product`),
      dataIndex: ['product', 'name'],
      render: (_, freight) => (
        <>
          {ProductWithVariety.getProductWithVariety({
            product: freight.product,
            product_variety: freight.product_variety,
          })}
        </>
      ),
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.packing`),
      dataIndex: ['packing', 'name'],
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.total_volume`),
      dataIndex: 'total_volume',
      render: (totalVolume, freight) => (
        <div style={{ minWidth: 90 }}>
          <Volume.Display volume={totalVolume} unitType={freight.unit_type} />
        </div>
      ),
    },
    {
      title: t(
        `${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.hired_quotation_price`
      ),
      dataIndex: 'quotations',
      render: (quotations: HiredFreightFragment['quotations']) => {
        const hiredQuotationStatus = [
          'HIRED',
          'CARRYING',
          'LOADED',
          'FINISHED',
          'DROPPED',
          'CANCELED',
          'ABANDONED',
        ];

        const hiredQuotation = quotations?.find(quotation =>
          hiredQuotationStatus.includes(quotation.status.name)
        );

        const hiredQuotationPrice = hiredQuotation?.value_per_unit || 0;

        return (
          <>
            <Price.Display price={hiredQuotationPrice} /> por tonelada
          </>
        );
      },
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.status`),
      dataIndex: ['status', 'name'],
      render: status => <FreightStatus status={status} />,
    },
    {
      title: t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.actions`),
      dataIndex: 'actions',
      render: (_, { id }) => (
        <Space>
          <Button type="link" onClick={() => onView(id)}>
            {t(`${HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.actions.view`)}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card title={title}>
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={freights}
        loading={loading}
        rowKey={freight => freight.id}
      />
    </Card>
  );
};
