import {
  Button,
  Card,
  Popconfirm,
  Row,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import {
  CteTableFragment,
  useUnloadMutation,
} from '../../../graphql/generated/graphql';
import { Access } from '../../Access/Access';
import ErrorAlert from '../../AntdCustom/ErrorAlert/ErrorAlert';
import { FormattedDate } from '../../Formatters/FormattedDate/FormattedDate';
import { Volume } from '../../../core/Freight/Volume';

interface ICteTableProps {
  quotationId: string;
  ctes?: CteTableFragment[];
  onDeleteSuccess?: (cteId: string) => void;
  onUpload?: () => void;
  tableProps?: Partial<TableProps<CteTableFragment>>;
  hideActions?: boolean;
  hideUploadButton?: boolean;
}

const TRANSLATION_PATH = 'components.quotation.cteTable';

export const CteTable = ({
  quotationId,
  ctes,
  onUpload,
  onDeleteSuccess,
  tableProps,
  hideActions = false,
  hideUploadButton = false,
}: ICteTableProps): ReactElement => {
  const { t } = useTranslation();

  const [unloadCteResult, executeUnloadMutation] = useUnloadMutation();

  const { fetching, error } = unloadCteResult;

  const handleUnloadCte = (cteId: string) => {
    executeUnloadMutation({
      input: {
        cte_id: cteId,
        quotation_id: quotationId,
      },
    }).then(({ data, error }) => {
      if (data && !error) {
        onDeleteSuccess && onDeleteSuccess(cteId);
      }
    });
  };

  const DeleteButton = ({ cteId }: { cteId: string }) => (
    <Popconfirm
      cancelButtonProps={{ type: 'primary' }}
      okText={t('general.confirm')}
      okType="danger"
      onConfirm={() => handleUnloadCte(cteId)}
      title={t(
        `${TRANSLATION_PATH}.table.columns.actions.delete.popconfirm.title`
      )}
    >
      <Button type="link" danger>
        {t('general.delete')}
      </Button>
    </Popconfirm>
  );

  const DeleteButtonAccess = Access(DeleteButton, {
    acceptedPermissions: [{ module: 'CTES', actions: ['DELETE'] }],
  });

  const UploadButton = () => (
    <Button type="primary" onClick={onUpload}>
      {t(`${TRANSLATION_PATH}.card.uploadFile`)}
    </Button>
  );

  const UploadButtonAccess = Access(UploadButton, {
    acceptedPermissions: [{ module: 'CTES', actions: ['CREATE'] }],
  });

  const ViewFileButton = ({ cte }: { cte: CteTableFragment }) => (
    <Button type="link" onClick={() => window.open(cte.url, '_blank')}>
      {t('general.view')}
    </Button>
  );

  const ViewButtonAccess = Access(ViewFileButton, {
    acceptedPermissions: [{ module: 'CTES', actions: ['READ'] }],
  });

  const cardTitle = (
    <Row align="middle" justify="space-between">
      <Typography.Text>{t(`${TRANSLATION_PATH}.card.title`)}</Typography.Text>
      {!hideActions && !hideUploadButton && <UploadButtonAccess />}
    </Row>
  );

  const columns: TableColumnsType<CteTableFragment> = [
    {
      title: t(`${TRANSLATION_PATH}.table.columns.fileName`),
      dataIndex: ['filename'],
      key: 'fileName',
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.date`),
      dataIndex: ['created_at'],
      key: 'date',
      render: date => <FormattedDate dateTime={date} />,
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.loadedVolume`),
      dataIndex: ['loaded_volume'],
      render: (loadedVolume, cte) => (
        <Volume.Display volume={loadedVolume} unitType={cte.unit_type} />
      ),
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.driver`),
      dataIndex: ['driver'],
      render: (driver: CteTableFragment['driver'], cte) =>
        `[${Formatter.formatMercosulPlate(cte.confirmation.vehicle_plate)}] ${
          driver.name
        }`,
    },
  ];

  if (!hideActions) {
    columns.push({
      title: t('general.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, cte) => (
        <>
          <ViewButtonAccess cte={cte} />

          <DeleteButtonAccess cteId={cte.id} />
        </>
      ),
    });
  }

  return (
    <Card title={cardTitle}>
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={ctes}
        rowKey={cte => cte.id}
        pagination={{ defaultPageSize: 15, hideOnSinglePage: true }}
        {...tableProps}
        loading={fetching || tableProps?.loading}
      />
    </Card>
  );
};
