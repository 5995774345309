import { Button, Col, Form, Popconfirm, Row, Space, Typography } from 'antd';
import { Key, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import { QuotationGroupTableFragment } from '../../../../graphql/generated/graphql';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { DefaultPageFooter } from '../../../AntdCustom/DefaultPageFooter/DefaultPageFooter';
import { DefaultTable } from '../../../AntdCustom/DefaultTable/DefaultTable';
import { RoutesFilter } from '../../Route/RoutesFilter/RoutesFilter';
import { RoutesTable } from '../../Route/RoutesTable/RoutesTable';
import QuotationGroupsForm from '../QuotationGroupsForm/QuotationGroupsForm';
import {
  CancelFormButton,
  CreateRouteButtonAccess,
  RouteErrorAlert,
} from './CreateFreightForm.components';
import {
  useCreateFreightForm,
  useFreightRouteForm,
} from './CreateFreightForm.hooks';
import {
  FreightDatesForm,
  IFreightDatesFormValues,
} from './FreightDatesForm/FreightDatesForm';
import {
  FreightLoadForm,
  IFreightLoadFormValues,
} from './FreightLoadForm/FreightLoadForm';

export type ICreateFreightFormValues = IFreightLoadFormValues &
  IFreightDatesFormValues & {
    route_id: string;
    related_groups?: Key[];
  };

export const CREATE_FREIGHT_FORM_TRANSLATION_PATH =
  'components.freight.shipper.createFreightForm';

export const CreateFreightForm = (): ReactElement => {
  const { t } = useTranslation();

  const { freightId } = useParams();

  const { userData } = useUserContext();

  const { selectedRowKeys, ...useRowSelectionProps } =
    DefaultTable.useRowSelection<QuotationGroupTableFragment>();

  const [form] = Form.useForm<ICreateFreightFormValues>();

  const {
    dropoffPlaces,
    filteredData,
    handlers: { handleFilterRoutes, handleRouteSelect, handleViewRoute },
    pickupPlaces,
    selectedRouteId,
    setSelectedRouteId,
    timeSpentInRoute,
    findRoutesResult,
  } = useFreightRouteForm();

  const {
    handlers: { handleCreateRoute, handleCancel, handleSubmit },
    packages,
    isFormTouched,
  } = useCreateFreightForm({
    form,
    selectedRouteId,
    setSelectedRouteId,
    relatedGroupKeys: selectedRowKeys,
    freightId,
  });

  const routesTableTitle = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.routesTable.title`)}
      </Typography.Text>
      <CreateRouteButtonAccess onClick={handleCreateRoute} />
    </Row>
  );

  const popConfirmCancelButton = isFormTouched ? (
    <Popconfirm
      cancelButtonProps={{ danger: true }}
      cancelText={t('general.yes')}
      okText={t('general.no')}
      onCancel={handleCancel}
      title={t(
        `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.actions.cancel.popconfirm.title`
      )}
    >
      <CancelFormButton />
    </Popconfirm>
  ) : (
    <CancelFormButton onClick={handleCancel} />
  );

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 24]}>
        <RouteErrorAlert visible={selectedRouteId === null} />
        <Col span={24}>
          <RoutesFilter
            pickupPlaces={pickupPlaces}
            dropoffPlaces={dropoffPlaces}
            onFilter={handleFilterRoutes}
          />
        </Col>
        <Col span={24}>
          <RoutesTable
            loading={findRoutesResult.fetching || !userData}
            bordered={false}
            handleView={handleViewRoute}
            onRouteSelect={handleRouteSelect}
            routes={filteredData}
            selectableRows
            size="small"
            title={routesTableTitle}
            selectedRowKeys={selectedRouteId ? [selectedRouteId] : undefined}
          />
        </Col>
        <Col span={24}>
          <QuotationGroupsForm
            form={form}
            useRowSelectionProps={{ selectedRowKeys, ...useRowSelectionProps }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <FreightDatesForm
                form={form}
                timeSpentInRoute={timeSpentInRoute}
              />
            </Col>
            <Col span={24}>
              <FreightLoadForm form={form} packages={packages} />
            </Col>
          </Row>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            {popConfirmCancelButton}
            <Button
              size="small"
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
            >
              {t(`${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.actions.submit`)}
            </Button>
          </Space>
        }
      />
    </>
  );
};
