import { UnitTypeEnum } from '../../../../graphql/generated/graphql';
import { parseLocaleNumber, roundWithDecimals } from '../../../../utils';

const formatVolumeInputToBaseUnitType = (
  value?: string | number | null,
  unit_type?: UnitTypeEnum
) => {
  if (value === undefined || value === null || !unit_type) return undefined;

  const parsedValue =
    typeof value === 'string' ? parseLocaleNumber(value) : value;

  const toBaseUnitType: Record<UnitTypeEnum, number> = {
    KG: 1,
    TON: 1000,
    UNIT: 1,
  };

  if (parsedValue) {
    return Math.trunc(parsedValue * toBaseUnitType[unit_type]);
  }

  return undefined;
};

const formatVolumeDisplay = (
  volume?: string | number,
  unitType?: UnitTypeEnum
) => {
  if (!unitType) {
    return null;
  }

  const numberFormatOptions: Record<UnitTypeEnum, Intl.NumberFormatOptions> = {
    KG: { style: 'decimal', maximumFractionDigits: 3 },
    TON: { style: 'decimal', maximumFractionDigits: 3 },
    UNIT: { style: 'decimal', maximumFractionDigits: 0 },
  };

  const numberVolume = formatVolumeToUnitType(volume, unitType);

  const formattedVolume = roundWithDecimals(numberVolume, 3).toLocaleString(
    'pt-BR',
    numberFormatOptions[unitType]
  );

  return formattedVolume;
};

const formatVolumeToUnitType = (
  volume?: string | number,
  unitType?: UnitTypeEnum
) => {
  let numberVolume = Number(volume) || 0;

  if (unitType === UnitTypeEnum.Ton) {
    numberVolume = roundWithDecimals(numberVolume / 1000, 3);
  }

  return numberVolume;
};

export const VolumeInputUtils = {
  formatVolumeInputToBaseUnitType,
  formatVolumeToUnitType,
  formatVolumeDisplay,
};
