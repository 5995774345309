import React, { ReactNode, useEffect, useState } from 'react';

import LoadingPage from '../../pages/LoadingPage/LoadingPage';

/**
 * It can be user for layout context data, such as:
 * sidebar selected option,
 * screen width and height for responsiveness
 * and other possible layout data
 */

interface ILayoutProvider {
  screenWidth?: number;
  screenHeight?: number;
  selectedMenu: string[];
  openMenu: string[];
  isLoading: boolean;
  isMobileMenuOpen: boolean;
  useMobileLayout: boolean;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string[]>>;
  setOpenMenu: React.Dispatch<React.SetStateAction<string[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(() => window.screen.width);
  const [screenHeight, setScreenHeight] = useState(() => window.screen.height);
  const [selectedMenu, setSelectedMenu] = useState<string[]>([]);
  const [openMenu, setOpenMenu] = useState<string[]>([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [useMobileLayout, setUseMobileLayout] = useState(false);

  useEffect(() => {
    if (screenWidth < 768) setUseMobileLayout(true);
    else setUseMobileLayout(false);
  }, [screenWidth]);

  useEffect(() => {
    const handleWindowResize = (e: UIEvent) => {
      const { innerWidth } = e.target as Window;
      const { innerHeight } = e.target as Window;
      setScreenWidth(innerWidth);
      setScreenHeight(innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        screenWidth,
        screenHeight,
        selectedMenu,
        openMenu,
        isLoading,
        isMobileMenuOpen,
        useMobileLayout,
        setSelectedMenu,
        setOpenMenu,
        setIsLoading,
        setIsMobileMenuOpen,
      }}
    >
      {isLoading ? <LoadingPage asOverlay /> : children}
    </LayoutContext.Provider>
  );
};

export const LayoutContext = React.createContext<ILayoutProvider>({
  isLoading: true,
  selectedMenu: [],
  openMenu: [],
  isMobileMenuOpen: false,
  useMobileLayout: false,
  setSelectedMenu: () => {
    return;
  },
  setOpenMenu: () => {
    return;
  },
  setIsLoading: () => {
    return;
  },
  setIsMobileMenuOpen: () => {
    return;
  },
});

export default LayoutContext;
