import { Button, Card, Col, Row } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import { QuotationGroupHeader } from '../QuotationGroupHeader/QuotationGroupHeader';
import { QuotationGroupTable } from '../QuotationGroupTable/QuotationGroupTable';
import { useQuotationGroups } from './QuotationGroups.hooks';
import { QuotationGroupFormModal } from '../QuotationGroupFormModal/QuotationGroupFormModal';
import { QuestionMarkTooltip } from '../../../../../components/AntdCustom/QuestionMarkTooltip/QuestionMarkTooltip';

export const QuotationGroups = (): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.shipper.quotationGroupsPage.components.quotationGroups',
  });

  const {
    actionsColumn,
    createButtonProps,
    data,
    error,
    executeQuery,
    groupId,
    handlers: { afterClose, close, openCreate },
    isCreateOpen,
    isEditOpen,
    isViewOpen,
    loading,
    pagination,
  } = useQuotationGroups();

  return (
    <>
      <QuotationGroupHeader />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={error} />
        <Col span={24}>
          <Card
            extra={
              <>
                <Button
                  onClick={() => openCreate()}
                  type="primary"
                  {...createButtonProps}
                >
                  {t('actions.create')}
                </Button>
              </>
            }
            title={
              <>
                {t('title')}
                <QuestionMarkTooltip title={t('tooltip')} />
              </>
            }
          >
            <QuotationGroupTable
              dataSource={data}
              loading={loading}
              pagination={pagination}
              actionColumn={actionsColumn}
            />
          </Card>
        </Col>
      </Row>
      <QuotationGroupFormModal
        action="create"
        afterClose={afterClose}
        onCancel={close}
        onMutationSuccess={executeQuery}
        onOpen={openCreate}
        open={isCreateOpen}
      />
      <QuotationGroupFormModal
        key={`update_${groupId}`}
        afterClose={afterClose}
        action="update"
        groupId={groupId}
        onCancel={close}
        onMutationSuccess={executeQuery}
        open={isEditOpen}
      />
      <QuotationGroupFormModal
        key={`read_${groupId}`}
        afterClose={afterClose}
        action="read"
        groupId={groupId}
        onCancel={close}
        open={isViewOpen}
      />
    </>
  );
};
