/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  FormInstance,
  FormItemProps,
  Input,
  InputNumber,
  Select,
  SelectProps,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement, useEffect, useState } from 'react';
import { useFindCitiesByStateQuery } from '../../graphql/generated/graphql';
import { UF, UFOptions } from '../../structures/interfaces/UF';
import { selectFilterOptionByLabel } from '../../utils';

export interface IUseCityStateSelectsProps {
  form: FormInstance;
  state?: Partial<FormItemProps> & {
    selectProps?: Omit<Partial<SelectProps>, 'options'>;
  };
  city?: Partial<FormItemProps> & {
    selectProps?: Omit<Partial<SelectProps>, 'options' | 'filterOption'>;
    withCoordinates?: {
      latitude: NamePath;
      longitude: NamePath;
    };
    withCityName?: {
      cityName: NamePath;
    };
    onLoadOptions?: () => void;
  };
}

export const useCityStateSelects = ({
  form,
  state = {},
  city = {},
}: IUseCityStateSelectsProps): [
  ReactElement,
  ReactElement,
  React.Dispatch<React.SetStateAction<UF | undefined>>
] => {
  const {
    withCityName,
    withCoordinates,
    onLoadOptions,
    selectProps: citySelectProps,
    ...cityFormItemProps
  } = city;
  const { selectProps: stateSelectProps, ...stateFormItemProps } = state;

  const [selectedState, setSelectedState] = useState<UF>();

  const [result, executeQuery] = useFindCitiesByStateQuery({
    pause: true,
    variables: selectedState
      ? { stateInitials: selectedState, withCoordinates: !!withCoordinates }
      : undefined,
  });

  const { data, fetching } = result;

  if (fetching) {
    Object.assign(cityFormItemProps, {
      hasFeedback: true,
      validateStatus: 'validating',
    });
  }

  const citiesOptions = data?.findCities
    .map(({ id, cityName }) => ({
      value: id,
      label: cityName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (stateFormItemProps.name) {
      setSelectedState(form.getFieldValue(stateFormItemProps.name));
    }
  }, []);

  const handleCitySelect = (
    cityId: number,
    option: NonNullable<SelectProps['options']>[number]
  ) => {
    const selectedCity = data?.findCities.find(city => city.id === cityId);

    if (selectedCity && withCoordinates) {
      const { latitude, longitude } = withCoordinates;

      form.setFieldValue(latitude, selectedCity.latitude);
      form.setFieldValue(longitude, selectedCity.longitude);
    }

    if (selectedCity && withCityName) {
      const { cityName } = withCityName;

      form.setFieldValue(cityName, selectedCity.cityName);
    }

    if (citySelectProps?.onSelect) citySelectProps.onSelect(cityId, option);
  };

  useEffect(() => {
    if (selectedState) {
      executeQuery();

      if (stateFormItemProps.name) {
        form.setFieldValue(stateFormItemProps.name, selectedState);
      }
    }
  }, [selectedState]);

  useEffect(() => {
    onLoadOptions && onLoadOptions();
    if (data && cityFormItemProps.name) {
      const selectedCityId: number | undefined = form.getFieldValue(
        cityFormItemProps.name
      );

      if (!data.findCities.find(city => city.id === selectedCityId)) {
        form.setFieldValue(cityFormItemProps.name, undefined);

        if (withCoordinates) {
          const { latitude, longitude } = withCoordinates;

          form.setFieldValue(latitude, undefined);
          form.setFieldValue(longitude, undefined);
        }

        if (withCityName) {
          const { cityName } = withCityName;

          form.setFieldValue(cityName, undefined);
        }
      }
    }
  }, [data]);

  return [
    <>
      <Form.Item {...stateFormItemProps}>
        <Select
          {...stateSelectProps}
          options={UFOptions}
          onChange={setSelectedState}
        />
      </Form.Item>
    </>,
    <>
      <Form.Item {...cityFormItemProps}>
        <Select
          {...citySelectProps}
          options={citiesOptions}
          disabled={fetching || !selectedState || citySelectProps?.disabled}
          filterOption={selectFilterOptionByLabel}
          onSelect={handleCitySelect}
        />
      </Form.Item>
      {!!withCoordinates && (
        <>
          <Form.Item hidden noStyle name={withCoordinates.latitude}>
            <InputNumber />
          </Form.Item>
          <Form.Item hidden noStyle name={withCoordinates.longitude}>
            <InputNumber />
          </Form.Item>
        </>
      )}
      {!!withCityName && (
        <Form.Item hidden noStyle name={withCityName.cityName}>
          <Input />
        </Form.Item>
      )}
    </>,
    setSelectedState,
  ];
};

export default useCityStateSelects;
