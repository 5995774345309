import { Col, FormInstance, Row } from 'antd';
import { UseMutationState } from 'urql';
import {
  AttachmentForm_AttachFileMutation,
  AttachmentForm_AttachFileMutationVariables,
} from '../../../../../graphql/generated/graphql';
import { Access } from '../../../../Access/Access';
import ErrorAlert from '../../../../AntdCustom/ErrorAlert/ErrorAlert';
import { Attachments } from '../../../attachments';
import { IAttachmentFormValues } from '../../../attachments/AttachmentForm/AttachmentForm';
import { useTranslation } from 'react-i18next';
import { useCommonI18n } from '../../../../../core/Common/Common.hooks';

export interface IAttachmentFormModalProps {
  form: FormInstance<IAttachmentFormValues>;
  handleClose: () => void;
  handleFinish: (formValues: IAttachmentFormValues) => void;
  isOpen: boolean;
  mutationResponse: UseMutationState<
    AttachmentForm_AttachFileMutation,
    AttachmentForm_AttachFileMutationVariables
  >;
}

const AttachmentFormModal = ({
  form,
  handleClose,
  handleFinish,
  isOpen,
  mutationResponse,
}: IAttachmentFormModalProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.freight.freightDetails.components.attachmentFormModal',
  });

  const { tKeys } = useCommonI18n();

  return (
    <Attachments.Modal
      cancelButtonProps={{ disabled: mutationResponse.fetching }}
      okButtonProps={{ loading: mutationResponse.fetching }}
      okText={tKeys.save}
      onCancel={mutationResponse.fetching ? undefined : handleClose}
      onOk={form.submit}
      open={isOpen}
      title={t('title')}
    >
      <Row gutter={[24, 24]}>
        <ErrorAlert error={mutationResponse.error} />
        <Col span={24}>
          <Attachments.Form
            disabled={mutationResponse.fetching}
            form={form}
            onFinish={handleFinish}
          />
        </Col>
      </Row>
    </Attachments.Modal>
  );
};

export const AttachmentFormModalAccess = Access(AttachmentFormModal, {
  acceptedPermissions: [{ module: 'FREIGHT_ATTACHMENTS', actions: ['CREATE'] }],
});
