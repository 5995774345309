import { CompanyFunctionEnum } from '../interfaces/Company/CompanyFunction';
import { ICreateAccount, ICreateAccountAPI } from '../interfaces/User';

export class CreateAccountModel implements ICreateAccountAPI {
  name: string;
  email: string;
  phone: string;
  company_function: CompanyFunctionEnum;

  constructor(data: ICreateAccount) {
    this.name = data.name;
    this.email = data.email;
    this.phone = '+55' + data.phone.replace(/\D/g, '');
    this.company_function = CompanyFunctionEnum[data.companyFunction];
  }
}
