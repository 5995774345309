import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CreateManyProductTypesInput,
  CreateProductTypesMutation,
  useCreateProductTypesMutation,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { FormItemIcon } from '../../../AntdCustom/FormItemIcon/FormItemIcon';

interface ICreateProductTypesModalProps {
  /**
   * Function to be executed to close the modal
   */
  onClose?: () => void;
  /**
   * Function to be executed when CreateProductTypesMutation was well succeeded
   */
  onSuccess?: (
    formValues: CreateProductTypesMutation['CreateManyProductTypes']
  ) => void;
  /**
   * Control whether modal is open or closed
   */
  open?: boolean;
}

/**
 * Modal with form to create many product types at same time
 */
export const CreateProductTypesModal = ({
  onClose,
  onSuccess,
  open,
}: ICreateProductTypesModalProps) => {
  const CREATE_PRODUCT_TYPES_MODAL_TRANSLATION_PATH =
    'components.product.productType.createProductTypesModal';

  const { t } = useTranslation();

  const [form] = Form.useForm<CreateManyProductTypesInput>();

  const [createProductTypesMutationResult, executeCreateProductTypesMutation] =
    useCreateProductTypesMutation();

  const handleFinish = (formValues: CreateManyProductTypesInput) => {
    const cleanValues = {
      names: formValues.names.map(({ name }) => ({ name: name.trim() })),
    };

    executeCreateProductTypesMutation({ input: cleanValues }).then(
      ({ data }) => {
        if (data) {
          onSuccess && onSuccess(data.CreateManyProductTypes);

          form.resetFields();
        }
      }
    );
  };

  const { error, fetching } = createProductTypesMutationResult;

  const footer = (
    <Space size="large" wrap style={{ justifyContent: 'end' }}>
      <Button
        htmlType="reset"
        onClick={() => form.resetFields()}
        disabled={fetching}
      >
        {t('general.clear')}
      </Button>
      <Button onClick={onClose} disabled={fetching}>
        {t('general.cancel')}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => form.submit()}
        loading={fetching}
      >
        {t('general.confirm')}
      </Button>
    </Space>
  );

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<>{t(`${CREATE_PRODUCT_TYPES_MODAL_TRANSLATION_PATH}.title`)}</>}
      footer={footer}
    >
      <>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          disabled={fetching}
        >
          <Form.List name="names" initialValue={['']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Row
                    align="middle"
                    justify="space-between"
                    key={field.key}
                    wrap={false}
                  >
                    <Row gutter={[24, 0]} style={{ padding: 12, flex: 1 }}>
                      <Col span={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          label={t(
                            `${CREATE_PRODUCT_TYPES_MODAL_TRANSLATION_PATH}.fields.labels.name`
                          )}
                          required
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: t(
                                `${CREATE_PRODUCT_TYPES_MODAL_TRANSLATION_PATH}.fields.rules.required`
                              ),
                            },
                          ]}
                          style={{ flex: 1 }}
                        >
                          <Input
                            placeholder={t(
                              `${CREATE_PRODUCT_TYPES_MODAL_TRANSLATION_PATH}.fields.placeholders.name`
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {fields.length > 1 && (
                      <FormItemIcon
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Row>
                ))}
                <Button block onClick={() => add()}>
                  {t(
                    `${CREATE_PRODUCT_TYPES_MODAL_TRANSLATION_PATH}.actions.add`
                  )}
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </>
    </Modal>
  );
};
