import { SelectProps } from 'antd';

export const UFs = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
] as const;

export type UF = (typeof UFs)[number];

export enum UFNameEnum {
  'AC' = 'Acre',
  'AL' = 'Alagoas',
  'AM' = 'Amazonas',
  'AP' = 'Amapá',
  'BA' = 'Bahia',
  'CE' = 'Ceará',
  'DF' = 'Distrito Federal',
  'ES' = 'Espírito Santo',
  'GO' = 'Goiás',
  'MA' = 'Maranhão',
  'MG' = 'Minas Gerais',
  'MS' = 'Mato Grosso do Sul',
  'MT' = 'Mato Grosso',
  'PA' = 'Pará',
  'PB' = 'Paraíba',
  'PE' = 'Pernambuco',
  'PI' = 'Piauí',
  'PR' = 'Paraná',
  'RJ' = 'Rio de Janeiro',
  'RN' = 'Rio Grande do Norte',
  'RO' = 'Rondônia',
  'RR' = 'Roraima',
  'RS' = 'Rio Grande do Sul',
  'SC' = 'Santa Catarina',
  'SE' = 'Sergipe',
  'SP' = 'São Paulo',
  'TO' = 'Tocantins',
}

export const UFOptions: NonNullable<SelectProps['options']> = UFs.map(uf => ({
  label: uf,
  value: uf,
}));

export const UFNameOptions: NonNullable<SelectProps['options']> = UFs.map(
  uf => ({
    label: UFNameEnum[uf],
    value: uf,
  })
);
