import { ReactElement, useContext, useEffect, useState } from 'react';

import { Row, Spin } from 'antd';
import LayoutContext from '../../contexts/LayoutContext/LayoutContext';

import { useTranslation } from 'react-i18next';

export const LoadingPage = ({
  asOverlay,
  opaque,
}: {
  asOverlay?: boolean;
  opaque?: boolean;
}): ReactElement => {
  const { t } = useTranslation();

  const { screenWidth = 0 } = useContext(LayoutContext);

  const [textSize, setTextSize] = useState(18);
  const [spinSize, setSpinSize] = useState<'small' | 'default' | 'large'>(
    'large'
  );

  const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    zIndex: 9999,

    height: '100vh',
    width: '100vw',

    background: opaque ? 'white' : 'rgba(255, 255, 255, 0.8)',
  };

  useEffect(() => {
    if (screenWidth > 1092) {
      setTextSize(18);
      setSpinSize('large');
    } else if (screenWidth > 520) {
      setTextSize(14);
      setSpinSize('default');
    } else if (screenWidth < 520) setSpinSize('small');
  }, [screenWidth]);

  return (
    <Row
      justify="center"
      align="middle"
      style={asOverlay ? overlayStyle : { height: '100vh' }}
    >
      <Row>
        <Row justify="center" style={{ width: '100%' }}>
          <Spin size={spinSize} />
        </Row>
        <Row
          justify="center"
          style={{
            width: '100%',
            marginTop: 4,
            fontWeight: 500,
            fontSize: textSize,
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {t('pages.loading.text')}
        </Row>
      </Row>
    </Row>
  );
};

export default LoadingPage;
