import dayjs from 'dayjs';

export interface IFormattedDateTime {
  dateTime: string | dayjs.Dayjs;
}

export const FormattedDateTime = (props: IFormattedDateTime) => {
  const dateTime = dayjs(props.dateTime);

  return <>{dateTime.format('DD/MM/YY HH:mm')}</>;
};
