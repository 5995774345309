import { LeftOutlined } from '@ant-design/icons';
import { Card, Form } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import Footer from '../../../components/container/heroContainer/heroCardContainer/footer';
import InputText from '../../../components/inputs/inputText';
import Select from '../../../components/inputs/select';
import { useAuthContext } from '../../../contexts/AuthContext/useAuthContext';
import {
  CompanyFunctionEnum,
  CompanyFunctionOptions,
} from '../../../structures/interfaces/Company/CompanyFunction';
import { translateOptions } from '../../../utils';

import './ResumeSignUpCard.scss';

interface ILocationState {
  userId?: string;
  name?: string;
  email?: string;
}

interface IFormValues {
  companyFunction: CompanyFunctionEnum;
}

export const ResumeSignUpCard = (): ReactElement<unknown> => {
  const { isUserLoggedIn, logout } = useAuthContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as ILocationState | undefined;

  const [form] = Form.useForm<IFormValues>();

  const companyFunctionOptions = translateOptions(t, CompanyFunctionOptions);

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleFormFinish = ({ companyFunction }: IFormValues) => {
    if (locationState?.userId) {
      navigate('/termsOfUse', {
        state: { userId: locationState.userId, companyFunction },
      });
    }
  };

  const initialValues = {
    name: locationState?.name,
    email: locationState?.email,
  };

  const loginForm = (
    <Form
      form={form}
      className="form"
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
      onFinish={handleFormFinish}
    >
      <div className="form-group">
        <InputText
          formItem
          validation
          size="large"
          name="name"
          disabled={true}
          label={t('pages.resumeSignUpCard.fields.labels.name')}
        />
        <InputText
          formItem
          validation
          size="large"
          name="email"
          disabled={true}
          label={t('pages.resumeSignUpCard.fields.labels.email')}
        />
        <Select
          formItem
          validation
          size="large"
          name="companyFunction"
          options={companyFunctionOptions}
          label={t('pages.resumeSignUpCard.fields.labels.companyFunction')}
          placeholder={t(
            'pages.resumeSignUpCard.fields.placeholders.companyFunction'
          )}
        />
      </div>
    </Form>
  );

  const submitButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      onClick={handleSubmit}
    >
      {t('pages.resumeSignUpCard.action.submit')}
    </Button>
  );

  const backButton = (
    <div className="suspended-button">
      <Button
        size="large"
        type="text"
        icon={<LeftOutlined />}
        onClick={handleCancel}
      >
        {t('pages.resumeSignUpCard.action.return')}
      </Button>
    </div>
  );

  const formHeader = (
    <TitleHeader
      centered
      titleText={t('pages.resumeSignUpCard.title')}
      subtitleText={t('pages.resumeSignUpCard.subtitle')}
    />
  );

  const formBody = <>{loginForm}</>;

  const formFooter = (
    <Footer>
      <Button danger size="large" type="primary" onClick={handleCancel}>
        {t('pages.resumeSignUpCard.action.cancel')}
      </Button>
      {submitButton}
    </Footer>
  );

  useEffect(() => {
    if (isUserLoggedIn) {
      logout();
    }
  }, [isUserLoggedIn, logout]);

  if (!locationState?.userId) {
    return <Navigate to="/" replace />;
  }

  return (
    <HeroCardContainer
      logoColumnSize="small"
      className="sign-up-card_container"
    >
      {backButton}
      <Card className="card_container" bordered={false}>
        <SupportHelpContact />
        {formHeader}
        {formBody}
      </Card>
      {formFooter}
    </HeroCardContainer>
  );
};

export default ResumeSignUpCard;
