import { useCallback, useState } from 'react';
import { ICompanySettings } from './UserContext';

export const useCompanySettings = () => {
  const [companySettings, setCompanySettings] =
    useState<ICompanySettings | null>();

  const [initialCompanySettings, setInitialCompanySettings] =
    useState<ICompanySettings | null>();

  const initialize = useCallback(
    (companySettings?: ICompanySettings | null) => {
      setCompanySettings(companySettings);
      setInitialCompanySettings(companySettings);
    },
    []
  );

  const update = useCallback((theme: ICompanySettings['theme']) => {
    setCompanySettings(companySettings => ({
      ...companySettings,
      theme,
    }));
  }, []);

  const rollback = useCallback(() => {
    setCompanySettings(initialCompanySettings);
  }, [initialCompanySettings]);

  const commit = useCallback(() => {
    setInitialCompanySettings(companySettings);
  }, [companySettings]);

  return {
    companySettings,
    initialCompanySettings,
    handlers: { initialize, theme: { update, rollback, commit } },
  };
};

export type IUseCompanySettings = ReturnType<typeof useCompanySettings>;
