import { ProductWithVarietyFragment } from '../../../../graphql/generated/graphql';

export const getProductWithVariety = ({
  product,
  product_variety,
}: Partial<ProductWithVarietyFragment> = {}) => {
  if (product?.name) {
    if (product_variety?.name)
      return `${product.name} (${product_variety.name})`;
    return product.name;
  }

  return '-';
};
