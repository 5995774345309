import { Layout } from 'antd';

const { Sider } = Layout;

type ISiderProps = Parameters<typeof Sider>[number];

export const StickySider = (props: ISiderProps) => {
  const { children, style, ...headerProps } = props;

  const stickyStyle = {
    ...style,
    bottom: 0,
    height: '100vh',
    left: 0,
    overflow: 'auto',
    position: 'fixed',
    top: 0,
  } as const;

  return (
    <Sider style={stickyStyle} {...headerProps}>
      {children}
    </Sider>
  );
};
