import { Select, SelectProps } from 'antd';
import { ReactElement } from 'react';
import { CompanyTypeEnum } from '../../../../graphql/generated/graphql';
import { CompanyType } from '../CompanyType';
import { useTranslation } from 'react-i18next';

export type ICompanyTypeSelectProps = Omit<SelectProps, 'options' | 'children'>;

export const CompanyTypeSelect = (
  props: ICompanyTypeSelectProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'general',
  });

  const options: SelectProps['options'] = Object.values(CompanyTypeEnum).map(
    key => ({ label: <CompanyType companyType={key} />, value: key })
  );

  return <Select options={options} placeholder={t('select')} {...props} />;
};
