import { ConfigProvider, Divider, Drawer, Image, theme } from 'antd';
import { ReactElement } from 'react';
import menuLogo from '../../../../assets/images/trucker-horizontal-branca-400.webp';
import { useLayoutContext } from '../../../../contexts/LayoutContext/useLayoutContext';
import AvatarMenu from '../AvatarMenu/AvatarMenu';
import { UserData } from '../Header/Header.components';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import { StickySider } from './Sidebar.components';

const DesktopSidebar = (): ReactElement => {
  return (
    <StickySider>
      <Image alt="Trucker do Agro's Logo" preview={false} src={menuLogo} />
      <SidebarMenu.ScrollableSidebarContainer>
        <SidebarMenu />
      </SidebarMenu.ScrollableSidebarContainer>
    </StickySider>
  );
};

const MobileSidebar = (): ReactElement => {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useLayoutContext();
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            colorBgElevated: colorPrimary,
          },
        },
      }}
    >
      <Drawer
        bodyStyle={{ padding: 0 }}
        onClose={() => setIsMobileMenuOpen(false)}
        open={isMobileMenuOpen}
        placement="left"
        width={300}
      >
        <UserData.Mobile />
        <Divider />
        <SidebarMenu />
        <Divider />
        <AvatarMenu.Mobile />
      </Drawer>
    </ConfigProvider>
  );
};

export const Sidebar = {
  Desktop: DesktopSidebar,
  Mobile: MobileSidebar,
};
