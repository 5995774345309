import { ReactElement } from 'react';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { useTranslation } from 'react-i18next';

interface IQuotationGroupHeaderProps {
  children?: JSX.Element;
}

export const QuotationGroupHeader = ({
  children,
}: IQuotationGroupHeaderProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.shipper.quotationGroupsPage.components.quotationGroupHeader',
  });

  return (
    <DefaultPageHeader title={t('title')} subTitle={t('subTitle')}>
      {children}
    </DefaultPageHeader>
  );
};
