import { Navigate, Route } from 'react-router-dom';
import CarrierFreightPage from '../pages/Carrier/Freight/CarrierFreightPage/CarrierFreightPage';
import CreateQuotationPage from '../pages/Carrier/Freight/Quotation/CreateQuotationPage/CreateQuotationPage';
import QuotationDetailsPage from '../pages/Carrier/Freight/Quotation/QuotationDetailsPage/QuotationDetailsPage';
import { HomePage } from '../pages/HomePage/HomePage';
import ConfirmFreightPage from '../pages/Shipper/Freight/ConfirmFreightPage/ConfirmFreightPage';
import CreateFreightPage from '../pages/Shipper/Freight/CreateFreightPage/CreateFreightPage';
import CreateRoutePage from '../pages/Shipper/Freight/CreateRoutePage/CreateRoutePage';
import FreightDetailsPage from '../pages/Shipper/Freight/FreightDetailsPage/FreightDetailsPage';
import PlacesPage from '../pages/Shipper/Freight/PlacesPage/PlacesPage';
import RoutesPage from '../pages/Shipper/Freight/RoutesPage/RoutesPage';
import ShipperFreightPage from '../pages/Shipper/Freight/ShipperFreightPage/ShipperFreightPage';
import ViewRoutePage from '../pages/Shipper/Freight/ViewRoutePage/ViewRoutePage';
import CompaniesPage from '../pages/Support/Account/Company/CompaniesPage/CompaniesPage';
import CompanyDetailsPage from '../pages/Support/Account/Company/CompanyDetailsPage/CompanyDetailsPage';
import CompanyValidationPage from '../pages/Support/Account/Company/CompanyValidationPage/CompanyValidationPage';
import UserDetailsPage from '../pages/Support/Account/User/UserDetailsPage/UserDetailsPage';
import UsersPage from '../pages/Support/Account/User/UsersPage/UsersPage';
import ProductTypesPage from '../pages/Support/Product/ProductTypesPage/ProductTypesPage';
import ProductsPage from '../pages/Support/Product/ProductsPage/ProductsPage';
import { UserDetailsPage as MyUserDetailsPage } from '../pages/User/UserDetailsPage/UserDetailsPage';
import QuotationGroupsPage from '../pages/shipper/QuotationGroupsPage/QuotationGroupsPage';
import MainLayout from '../layouts/MainLayout';

export const PrivateRoutes = (
  <>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />

      <Route path="/user">
        <Route index element={<MyUserDetailsPage />} />
      </Route>

      <Route path="/shipper">
        <Route path="/shipper/freights">
          <Route index element={<ShipperFreightPage />} />
          <Route
            path="/shipper/freights/:freightId"
            element={<FreightDetailsPage />}
          />
          <Route path="/shipper/freights/create">
            <Route index element={<CreateFreightPage />} />
            <Route path=":freightId" element={<CreateFreightPage />} />
          </Route>
          <Route
            path="/shipper/freights/confirm"
            element={<ConfirmFreightPage />}
          >
            <Route index element={<ConfirmFreightPage />} />
            <Route path=":freightId" element={<ConfirmFreightPage />} />
          </Route>
        </Route>
        <Route path="/shipper/places" element={<PlacesPage />} />
        <Route path="/shipper/routes">
          <Route index element={<RoutesPage />} />
          <Route path="/shipper/routes/:routeId" element={<ViewRoutePage />} />
          <Route path="/shipper/routes/create" element={<CreateRoutePage />} />
        </Route>
        <Route path="quotationGroups">
          <Route index element={<QuotationGroupsPage />} />
        </Route>
      </Route>

      <Route path="/carrier">
        <Route path="/carrier/freights">
          <Route index element={<CarrierFreightPage />} />
          <Route
            path="/carrier/freights/:freightId/quotations/create"
            element={<CreateQuotationPage />}
          />
          <Route
            path="/carrier/freights/:freightId/quotations/:quotationId"
            element={<QuotationDetailsPage />}
          />
        </Route>
      </Route>

      <Route path="/support">
        <Route path="products" element={<ProductsPage />} />
        <Route path="productTypes" element={<ProductTypesPage />} />

        <Route path="accounts">
          <Route path="companies">
            <Route index element={<CompaniesPage />} />
            <Route
              path="companyValidation"
              element={<CompanyValidationPage />}
            />
          </Route>
          <Route path="company">
            <Route
              index
              element={<Navigate to="/support/accounts/companies" />}
            />
            <Route path=":companyId" element={<CompanyDetailsPage />} />
          </Route>
          <Route path="users">
            <Route index element={<UsersPage />} />
          </Route>
          <Route path="user">
            <Route index element={<Navigate to="/support/accounts/users" />} />
            <Route path=":userId" element={<UserDetailsPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="/admin"></Route>
    </Route>
  </>
);
