import dayjs from 'dayjs';

interface IGetValidQuoteLimitDateInput {
  timeAmount: number;
  timeUnit: 'hours' | 'days';
  shipmentDate: dayjs.Dayjs;
  landingDate: dayjs.Dayjs;
}
const rules = {
  hours: {
    min: 2,
    max: 24 * 7,
  },
  days: {
    min: 1,
    max: 7,
  },
};

export const getValidDate = (
  date: dayjs.Dayjs,
  now = dayjs(),
  { disableWeekends = true, disableRules = false } = {}
) => {
  let validDate = date.clone();

  if (validDate.hour() < 9) {
    const nextQuoteLimitDate = validDate
      .clone()
      .hour(9)
      .minute(0)
      .second(0)
      .millisecond(0);

    if (
      !disableRules &&
      nextQuoteLimitDate.diff(now, 'hours') > rules.hours.max
    ) {
      validDate
        .subtract(1, 'day')
        .hour(16)
        .minute(59)
        .second(59)
        .millisecond(0);
    } else {
      validDate = nextQuoteLimitDate;
    }
  } else if (validDate.hour() >= 17) {
    const nextQuoteLimitDate = validDate
      .clone()
      .add(1, 'day')
      .hour(9)
      .minute(0)
      .second(0)
      .millisecond(0);

    if (
      !disableRules &&
      nextQuoteLimitDate.diff(now, 'hours') > rules.hours.max
    ) {
      validDate.hour(16).minute(59).second(59).millisecond(0);
    } else {
      validDate = nextQuoteLimitDate;
    }
  }

  if (disableWeekends) {
    if (validDate.weekday() === 0) {
      const nextQuoteLimitDate = validDate
        .clone()
        .add(1, 'day')
        .hour(9)
        .minute(0)
        .second(0)
        .millisecond(0);

      if (
        !disableRules &&
        nextQuoteLimitDate.diff(now, 'hours') > rules.hours.max
      ) {
        validDate
          .subtract(2, 'days')
          .hour(16)
          .minute(59)
          .second(59)
          .millisecond(0);
      } else {
        validDate = nextQuoteLimitDate;
      }
    } else if (validDate.weekday() == 6) {
      const nextQuoteLimitDate = validDate
        .clone()
        .add(2, 'days')
        .hour(9)
        .minute(0)
        .second(0)
        .millisecond(0);

      if (
        !disableRules &&
        nextQuoteLimitDate.diff(now, 'hours') > rules.hours.max
      ) {
        validDate
          .subtract(1, 'day')
          .hour(16)
          .minute(59)
          .second(59)
          .millisecond(0);
      } else {
        validDate = nextQuoteLimitDate;
      }
    }
  }

  return validDate;
};
export const getValidDates = (
  { timeAmount, timeUnit, ...dates }: IGetValidQuoteLimitDateInput,
  now = dayjs()
) => {
  let validTimeAmount = timeAmount;

  if (validTimeAmount < rules[timeUnit].min) {
    validTimeAmount = rules[timeUnit].min;
  } else if (validTimeAmount > rules[timeUnit].max) {
    validTimeAmount = rules[timeUnit].max;
  }

  const quoteLimitDate = now
    .clone()
    .add(validTimeAmount, timeUnit)
    .utcOffset(-180);

  const validQuoteLimitDate = getValidDate(quoteLimitDate, now);

  const shipmentDate = (
    dates.shipmentDate.isBefore(validQuoteLimitDate)
      ? validQuoteLimitDate.clone()
      : dates.shipmentDate.isSame(validQuoteLimitDate, 'day')
      ? dates.shipmentDate
          .clone()
          .hour(validQuoteLimitDate.hour())
          .minute(validQuoteLimitDate.minute())
          .second(validQuoteLimitDate.second())
      : dates.shipmentDate.clone().hour(9).minute(0).second(0).millisecond(0)
  ).add(2, 'hours');

  const landingDate = shipmentDate
    .clone()
    .add(dates.landingDate.diff(dates.shipmentDate, 'hours'), 'hours');

  const validShipmentDate = getValidDate(shipmentDate, now, {
    disableWeekends: false,
    disableRules: true,
  });

  const validLandingDate = getValidDate(landingDate, now, {
    disableWeekends: false,
    disableRules: true,
  });

  return {
    quoteLimitDate: validQuoteLimitDate.utcOffset(now.utcOffset()),
    shipmentDate: validShipmentDate,
    landingDate: validLandingDate,
  };
};
