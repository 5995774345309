import { SVGProps } from 'react';

export default function TruckIconComponent(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
      <g data-name="Grupo 16973">
        <g data-name="Grupo 16038" fill="currentColor">
          <path
            data-name="Ret\xE2ngulo 6726"
            d="M1.474.737a.738.738 0 0 0-.737.737v11.789h12.526V1.474a.738.738 0 0 0-.737-.737H1.474m0-.737h11.052A1.474 1.474 0 0 1 14 1.474V14H0V1.474A1.474 1.474 0 0 1 1.474 0Z"
          />
          <path
            data-name="Ret\xE2ngulo 6727"
            d="M2.948 2.948a.738.738 0 0 0-.737.737v1.473a.738.738 0 0 0 .737.737h2.21a.738.738 0 0 0 .737-.737V3.685a.738.738 0 0 0-.737-.737h-2.21m0-.737h2.21a1.474 1.474 0 0 1 1.474 1.474v1.473a1.474 1.474 0 0 1-1.474 1.474h-2.21a1.474 1.474 0 0 1-1.474-1.474V3.685a1.474 1.474 0 0 1 1.474-1.474Z"
          />
          <path
            data-name="Ret\xE2ngulo 6731"
            d="M8.842 2.948a.738.738 0 0 0-.737.737v1.473a.738.738 0 0 0 .737.737h2.21a.738.738 0 0 0 .737-.737V3.685a.738.738 0 0 0-.737-.737h-2.21m0-.737h2.21a1.474 1.474 0 0 1 1.474 1.474v1.473a1.474 1.474 0 0 1-1.474 1.474h-2.21a1.474 1.474 0 0 1-1.474-1.474V3.685a1.474 1.474 0 0 1 1.474-1.474Z"
          />
          <path
            data-name="Ret\xE2ngulo 6728"
            d="M4.421 8.842a.738.738 0 0 0-.737.737v3.684h6.631V9.579a.738.738 0 0 0-.736-.737H4.421m0-.737h5.158a1.474 1.474 0 0 1 1.473 1.474V14H2.947V9.579a1.474 1.474 0 0 1 1.474-1.474Z"
          />
          <path
            data-name="Ret\xE2ngulo 6732"
            d="M4.421 8.842a.738.738 0 0 0-.737.737v3.684h2.947V8.842h-2.21m0-.737h2.947V14H2.947V9.579a1.474 1.474 0 0 1 1.474-1.474Z"
          />
        </g>
      </g>
    </svg>
  );
}
