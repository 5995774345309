import { Button, Card, Form, FormInstance } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateFreightContext } from '../../../../contexts/Freight/CreateFreightContext/useCreateFreightContext';
import { QuotationGroupTableFragment } from '../../../../graphql/generated/graphql';
import { QuotationGroupFormModal } from '../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroupFormModal/QuotationGroupFormModal';
import { QuotationGroupTable } from '../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroupTable/QuotationGroupTable';
import { useQuotationGroups } from '../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroups/QuotationGroups.hooks';
import { Access } from '../../../Access/Access';
import { DefaultTable } from '../../../AntdCustom/DefaultTable/DefaultTable';
import { QuestionMarkTooltip } from '../../../AntdCustom/QuestionMarkTooltip/QuestionMarkTooltip';
import { ICreateFreightFormValues } from '../CreateFreightForm/CreateFreightForm';

interface IQuotationGroupsFormProps {
  form: FormInstance<ICreateFreightFormValues>;
  useRowSelectionProps: ReturnType<
    typeof DefaultTable.useRowSelection<QuotationGroupTableFragment>
  >;
}

export const QuotationGroupsForm = ({
  form,
  useRowSelectionProps: {
    handlers: { set: setSelectedRowKeys },
    rowSelection,
    selectedRowKeys,
    hasSelectedRowKeysSet,
  },
}: IQuotationGroupsFormProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.shipper.quotationGroupsForm',
  });

  const { createFreightValues, setCreateFreightValues } =
    useCreateFreightContext();

  const {
    actionsColumn,
    createButtonProps,
    data,
    executeQuery,
    groupId,
    handlers: { afterClose, close, openCreate },
    isCreateOpen,
    isEditOpen,
    isViewOpen,
    loading,
    pagination,
  } = useQuotationGroups({ actions: ['view', 'edit'] });

  useEffect(() => {
    form.validateFields(['related_groups']);
  }, [form, selectedRowKeys]);

  useEffect(() => {
    if (createFreightValues && !hasSelectedRowKeysSet) {
      const { related_groups } = createFreightValues;

      if (related_groups?.length) {
        setSelectedRowKeys(related_groups);
      }
    }
  }, [createFreightValues, hasSelectedRowKeysSet, setSelectedRowKeys]);

  useEffect(() => {
    if (
      hasSelectedRowKeysSet &&
      createFreightValues?.related_groups &&
      createFreightValues.related_groups.length !== selectedRowKeys.length
    ) {
      setCreateFreightValues(createFreightValues =>
        createFreightValues
          ? {
              ...createFreightValues,
              related_groups: selectedRowKeys,
            }
          : createFreightValues
      );
    }
  }, [
    createFreightValues,
    hasSelectedRowKeysSet,
    selectedRowKeys,
    setCreateFreightValues,
  ]);

  return (
    <>
      <Card
        title={
          <>
            {t('title')}
            <QuestionMarkTooltip title={t('tooltip')} />
          </>
        }
        extra={
          <CreateQuotationGroupButtonAccess
            onClick={() => openCreate()}
            type="primary"
            {...createButtonProps}
          >
            {t('actions.create')}
          </CreateQuotationGroupButtonAccess>
        }
      >
        <Form form={form}>
          <Form.Item
            name="related_groups"
            rules={[
              {
                validator() {
                  if (selectedRowKeys.length > 10) {
                    return Promise.reject(t('validations.maxQuotationGroups'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <QuotationGroupTable
              dataSource={data}
              loading={loading}
              pagination={pagination}
              actionColumn={actionsColumn}
              rowSelection={rowSelection}
            />
          </Form.Item>
        </Form>
      </Card>
      <QuotationGroupFormModal
        action="create"
        afterClose={afterClose}
        onCancel={close}
        onMutationSuccess={executeQuery}
        onOpen={openCreate}
        open={isCreateOpen}
      />
      <QuotationGroupFormModal
        key={`update_${groupId}`}
        afterClose={afterClose}
        action="update"
        groupId={groupId}
        onCancel={close}
        onMutationSuccess={executeQuery}
        open={isEditOpen}
      />
      <QuotationGroupFormModal
        key={`read_${groupId}`}
        afterClose={afterClose}
        action="read"
        groupId={groupId}
        onCancel={close}
        open={isViewOpen}
      />
    </>
  );
};

const CreateQuotationGroupButtonAccess = Access(Button, {
  acceptedPermissions: [{ module: 'GROUP', actions: ['CREATE'] }],
  fallback: <></>,
});

const QuotationGroupsFormAccess = Access(QuotationGroupsForm, {
  acceptedPermissions: [{ module: 'GROUP', actions: ['READ'] }],
  fallback: <></>,
});

export default QuotationGroupsFormAccess;
