import { Card, Checkbox, Form, Row, notification } from 'antd';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import InputText from '../../../components/inputs/inputText';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import { useAuthContext } from '../../../contexts/AuthContext/useAuthContext';

import './index.scss';

interface ILogin {
  email: string;
  password: string;
  keepConnected: boolean;
}

interface ILoginProps {
  bordered?: boolean;
}

export const LoginForm = ({
  bordered = true,
}: ILoginProps): ReactElement<unknown> => {
  const { t } = useTranslation();
  const [isLoginRequesting, setIsLoginRequesting] = useState(false);

  const { login } = useAuthContext();

  const onFinish = ({ email, password, keepConnected }: ILogin) => {
    const credential = email.toLowerCase();

    setIsLoginRequesting(true);
    login({ credential, password, keepConnected })
      .then(() => {
        return;
      })
      .catch(({ message, description }) => {
        notification.error({
          message: t(message),
          description: t(description),
        });
      })
      .finally(() => {
        setIsLoginRequesting(false);
      });
  };

  const LoginForm = (
    <Form
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      initialValues={{ keepConnected: true }}
    >
      <div>
        <InputText
          formItem
          validation
          size="large"
          name="email"
          disabled={isLoginRequesting}
          label={t('pages.login.fields.email.label')}
          placeholder={t('pages.login.fields.email.placeholder')}
          otherRules={[
            { type: 'email', message: t('input.rules.invalidEmail') },
          ]}
        />
        <InputText
          formItem
          validation
          size="large"
          type="password"
          name="password"
          disabled={isLoginRequesting}
          label={t('pages.login.fields.password.label')}
          placeholder={t('pages.login.fields.password.placeholder')}
          otherRules={[{ min: 6, message: t('input.rules.minPassword') }]}
        />
      </div>
      <Row align="middle" justify="space-between" style={{ rowGap: '12px' }}>
        <div className="keep-connected">
          <Form.Item name={'keepConnected'} valuePropName="checked">
            <Checkbox disabled={isLoginRequesting}>
              {t('pages.login.fields.keepConnected')}
            </Checkbox>
          </Form.Item>
        </div>
        <Link to="/recoveryPassword" className="custom-link">
          {t('pages.login.fields.passwordRecovery')}
        </Link>
      </Row>
      <Row justify="end">
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isLoginRequesting}
          style={{
            width: '100%',
            fontSize: '1rem',
            borderRadius: '4px',
            fontWeight: 'lighter',
          }}
        >
          {t('pages.login.fields.loginButton')}
        </Button>
      </Row>
      <Row style={{ columnGap: '12px' }}>
        <span>{t('pages.login.fields.createAccount.text')}</span>
        <Link to="/signUp" className="custom-link">
          {t('pages.login.fields.createAccount.link')}
        </Link>
      </Row>
    </Form>
  );

  return (
    <HeroCardContainer className="login-card_container">
      <SupportHelpContact />
      <Card className="login-card" bordered={bordered}>
        <TitleHeader
          decorated
          titleText={t('pages.login.title')}
          titleStyle={{ marginBottom: '0.75rem' }}
          subtitleText={t('pages.login.subtitle')}
        />
        {LoginForm}
      </Card>
    </HeroCardContainer>
  );
};

export default LoginForm;
