import { Button, Card, Col, Row, Space } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Access } from '../../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import { CompaniesPageTable } from '../../../../../components/Support/Account/Company/CompaniesPageTable/CompaniesPageTable';
import {
  FunctionEnum,
  TruckerRolesEnum,
  UserDetailsPage_UpdateManyUserHasPermissionsMutationVariables,
  useUnlinkUserFromCompanyMutation,
  useUserDetailsPage_FindUserAndPermissionsQuery,
  useUserDetailsPage_FindUserPermissionTemplateQuery,
  useUserDetailsPage_UpdateManyUserHasPermissionsMutation,
} from '../../../../../graphql/generated/graphql';
import { useMenuItem } from '../../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../../hooks/useTitle/useTitle';

import {
  IMutationSuccess,
  LinkUserToCompanyModal,
} from '../../Components/LinkUserToCompanyModal/LinkUserToCompanyModal';
import { UserDataDescriptions } from './Components/UserDataDescriptions/UserDataDescriptions';
import {
  IUserPermissionsTableFormValues,
  UserPermissionsTable,
} from './Components/UserPermissionsTable/UserPermissionsTable';

const functionEnumToTruckerRoleEnum = {
  [FunctionEnum.Carrier]: TruckerRolesEnum.Carrier,
  [FunctionEnum.Shipper]: TruckerRolesEnum.Shipper,
};

export const UserDetailsPage = (): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.user.userDetailsPage',
  });

  const { t: t0 } = useTranslation();

  const { userId } = useParams();

  const [isLinkUserToCompanyModalOpen, setIsLinkUserToCompanyModalOpen] =
    useState(false);

  const [isPermissionsTableInEditMode, setIsPermissionsTableInEditMode] =
    useState(false);

  const [permissionsTableData, setPermissionsTableData] =
    useState<IUserPermissionsTableFormValues>({});

  const [initialPermissionsTableData, setInitialPermissionsTableData] =
    useState<IUserPermissionsTableFormValues>({});

  const [
    findUserAndPermissionsQueryResult,
    executeFindUserAndPermissionsQuery,
  ] = useUserDetailsPage_FindUserAndPermissionsQuery({
    variables: { userId: userId || '' },
  });

  const companyFunction =
    findUserAndPermissionsQueryResult.data?.FindUser.companies?.at(0)?.company
      .company_function;

  const [findUserPermissionTemplateQueryResult] =
    useUserDetailsPage_FindUserPermissionTemplateQuery({
      variables: companyFunction
        ? { roleName: functionEnumToTruckerRoleEnum[companyFunction] }
        : undefined,
      pause: !companyFunction,
    });

  const [
    unlinkUserFromCompanyMutationResult,
    executeUnlinkUserFromCompanyMutation,
  ] = useUnlinkUserFromCompanyMutation();

  const [
    updateManyUserHasPermissionsMutationResult,
    executeUpdateManyUserHasPermissionsMutation,
  ] = useUserDetailsPage_UpdateManyUserHasPermissionsMutation();

  const handleClick = () => {
    setIsLinkUserToCompanyModalOpen(true);
  };

  const handleMutationSuccess: IMutationSuccess = ({ error, data }) => {
    if (!error && data) {
      executeFindUserAndPermissionsQuery();
      setIsLinkUserToCompanyModalOpen(false);
    }
  };

  const handleEditPermissionsTable = () => {
    setIsPermissionsTableInEditMode(true);
  };

  const handleCancelEditPermissionsTable = () => {
    setIsPermissionsTableInEditMode(false);
  };

  const handleFinishEditPermissionsTable = () => {
    const permissionsDiff: UserDetailsPage_UpdateManyUserHasPermissionsMutationVariables['input']['permissions'] =
      Object.keys(initialPermissionsTableData).reduce(
        (acc, initialPermissionKey) => {
          const permissionValue = permissionsTableData[initialPermissionKey];
          const initialPermissionValue =
            initialPermissionsTableData[initialPermissionKey];

          if (permissionValue !== initialPermissionValue) {
            return [
              ...acc,
              { permission_id: initialPermissionKey, enable: permissionValue },
            ];
          }

          return acc;
        },
        [] as UserDetailsPage_UpdateManyUserHasPermissionsMutationVariables['input']['permissions']
      );

    if (permissionsDiff.length > 0) {
      if (userId) {
        executeUpdateManyUserHasPermissionsMutation({
          input: {
            user_id: userId,
            permissions: permissionsDiff,
          },
        }).then(({ data, error }) => {
          if (!error && data) {
            executeFindUserAndPermissionsQuery();
          }

          setIsPermissionsTableInEditMode(false);
        });
      }
    } else {
      setIsPermissionsTableInEditMode(false);
    }
  };

  const handleUnlinkUserFromCompany = ({
    companyId,
  }: {
    companyId: string;
  }) => {
    if (userId) {
      executeUnlinkUserFromCompanyMutation({
        input: {
          company_id: companyId,
          user_company_id: companyId,
          user_id: userId,
        },
      }).then(({ data, error }) => {
        if (!error && data) {
          executeFindUserAndPermissionsQuery();
        }
      });
    }
  };

  const { data, fetching, error } = findUserAndPermissionsQueryResult;

  const companiesPageTableData =
    data?.FindUser.companies?.map(({ company }) => company) || [];

  const userHasCompanies =
    data?.FindUser.companies && data.FindUser.companies.length > 0;

  const companiesPageTableTitle = (
    <Row gutter={[24, 12]} justify="space-between">
      <Col>{t('companiesTable.title')}</Col>
      {!fetching && !userHasCompanies && (
        <Col>
          <Button onClick={handleClick} type="primary">
            {t('companiesTable.actions.create')}
          </Button>
        </Col>
      )}
    </Row>
  );

  const PermissionsTableTitleButtons = () => (
    <>
      {data?.FindManyUserHasPermissions?.length ? (
        <Col>
          {isPermissionsTableInEditMode ? (
            <Space size="middle">
              <Button
                disabled={updateManyUserHasPermissionsMutationResult.fetching}
                onClick={handleCancelEditPermissionsTable}
              >
                {t0('general.cancel')}
              </Button>
              <Button
                loading={updateManyUserHasPermissionsMutationResult.fetching}
                onClick={handleFinishEditPermissionsTable}
                type="primary"
              >
                {t0('general.save')}
              </Button>
            </Space>
          ) : (
            <Button onClick={handleEditPermissionsTable} type="primary">
              {t0('general.edit')}
            </Button>
          )}
        </Col>
      ) : (
        <></>
      )}
    </>
  );

  const PermissionsTableTitleButtonsAccess = Access(
    PermissionsTableTitleButtons,
    {
      acceptedPermissions: [
        { module: 'USER_HAS_PERMISSIONS', actions: ['UPDATE'] },
      ],
    }
  );

  const permissionsTableTitle = (
    <Row gutter={[24, 12]} justify="space-between">
      <Col>{t('components.userPermissionsTable.title')}</Col>
      <PermissionsTableTitleButtonsAccess />
    </Row>
  );

  useTitle({
    title: data?.FindUser.email || 'tab.support.accounts.users.userDetailsPage',
  });

  useMenuItem({
    selectedMenu: ['/support/accounts/users'],
    openMenu: ['/support/accounts'],
  });

  useEffect(() => {
    const permissionsTableData: IUserPermissionsTableFormValues | undefined =
      data?.FindManyUserHasPermissions.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.user_permission.id]: permission.enable,
        }),
        {}
      );

    if (permissionsTableData) {
      setPermissionsTableData(permissionsTableData);
      setInitialPermissionsTableData({ ...permissionsTableData });
    }
  }, [data?.FindManyUserHasPermissions]);

  return (
    <>
      <DefaultPageHeader
        subTitle={data?.FindUser.email || userId}
        title={t('title')}
      />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={error} />
        <ErrorAlert error={unlinkUserFromCompanyMutationResult.error} />
        <ErrorAlert error={updateManyUserHasPermissionsMutationResult.error} />
        <Col span={24}>
          <Card>
            <UserDataDescriptions data={data?.FindUser} loading={fetching} />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={companiesPageTableTitle}>
            <CompaniesPageTable
              dataSource={companiesPageTableData}
              handleUnlinkCompany={handleUnlinkUserFromCompany}
              loading={fetching || unlinkUserFromCompanyMutationResult.fetching}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={permissionsTableTitle}>
            <UserPermissionsTable
              key={data?.FindUser.id}
              loading={fetching}
              editMode={{
                isEditing: isPermissionsTableInEditMode,
                permissionsTemplate:
                  findUserPermissionTemplateQueryResult.data
                    ?.FindPermissionTemplate,
                permissions: isPermissionsTableInEditMode
                  ? permissionsTableData
                  : initialPermissionsTableData,
                setPermissions: setPermissionsTableData,
              }}
              rawDataSource={data?.FindManyUserHasPermissions}
            />
          </Card>
        </Col>
      </Row>
      <LinkUserToCompanyModal
        open={isLinkUserToCompanyModalOpen}
        onCancel={() => setIsLinkUserToCompanyModalOpen(false)}
        user={data?.FindUser}
        onMutationSuccess={handleMutationSuccess}
      />
    </>
  );
};

const UserDetailsPageAccess = Access(UserDetailsPage, {
  acceptedPermissions: [{ module: 'TRUCKER_USERS', actions: ['CREATE'] }],
  fallback: <Navigate to="/home" replace />,
});

export default UserDetailsPageAccess;
