/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import Formatter from '../../../../classes/Formatter';
import {
  CreateProductTypesMutation,
  SortOrder,
  useDeleteProductTypesMutation,
  useFindProductTypesQuery,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { CreateProductTypesModal } from '../CreateProductTypesModal/CreateProductTypesModal';
import { ProductTypeDetailsModal } from '../ProductTypeDetailsModal/ProductTypeDetailsModal';
import {
  IProductTypesFilterFormValues,
  ProductTypesFilter,
} from '../ProductTypesFilter/ProductTypesFilter';
import {
  IProductTypeTableItem,
  ProductTypesTable,
} from '../ProductTypesTable/ProductTypesTable';

/**
 * Component to show and manage Product Types
 */
export const ListProductTypes = () => {
  const [productTypesFilterValues, setProductTypesFilterValues] =
    useState<IProductTypesFilterFormValues>();
  const [productTypes, setProductTypes] = useState<IProductTypeTableItem[]>();
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<
    string | null
  >(null);
  const [isCreateProductTypeModalOpen, setIsCreateProductTypeModalOpen] =
    useState(false);

  const [findProductTypesResult] = useFindProductTypesQuery({
    variables: {
      order_by: {
        updated_at: SortOrder.Desc,
      },
      pagination: {
        take: 1000,
      },
    },
  });

  const [findProductTypeDetailedResult, executeFindProductTypeDetailedQuery] =
    useFindProductTypesQuery({
      variables: {
        detailed: true,
        where: selectedProductTypeId
          ? {
              id: {
                equals: selectedProductTypeId,
              },
            }
          : undefined,
        pagination: {
          take: 1000,
        },
      },
      pause: true,
    });

  const [deleteProductTypesMutationResult, executeDeleteProductTypesMutation] =
    useDeleteProductTypesMutation();

  const handleCloseDetailsModal = () => {
    setSelectedProductTypeId(null);
  };

  const handleCloseCreateModal = () => {
    setIsCreateProductTypeModalOpen(false);
  };

  const handleCreate = () => {
    setIsCreateProductTypeModalOpen(true);
  };

  const handleCreateSuccess = (
    createdProductTypes: CreateProductTypesMutation['CreateManyProductTypes']
  ) => {
    setProductTypes(productTypes => {
      if (!productTypes) {
        return createdProductTypes;
      }

      const uniqueCreatedProductTypes = createdProductTypes.filter(
        createdProductType =>
          !productTypes.some(
            productType => createdProductType.id === productType.id
          )
      );

      return [...uniqueCreatedProductTypes, ...productTypes];
    });

    handleCloseCreateModal();
  };

  const handleView = (productTypeId: string) => {
    setSelectedProductTypeId(productTypeId);
  };

  const handleDelete = (productTypeId: string) => {
    executeDeleteProductTypesMutation({
      input: { ids: [{ id: productTypeId }] },
    }).then(({ error, data }) => {
      const deletedProductTypesId = data?.DeleteManyProductTypes.map(
        productType => productType.id
      );

      if (!error && deletedProductTypesId) {
        setProductTypes(productTypes =>
          productTypes?.filter(({ id }) => !deletedProductTypesId.includes(id))
        );
      }
    });
  };

  const filterProductTypes = (
    values: IProductTypeTableItem[] = [],
    filter: IProductTypesFilterFormValues = {}
  ) => {
    const { search } = filter;
    if (search) {
      return values.filter(({ name }) =>
        Formatter.removeAccents(name)
          .toLowerCase()
          .includes(Formatter.removeAccents(search).toLowerCase())
      );
    }

    return values;
  };

  useEffect(() => {
    const { fetching, data } = findProductTypesResult;

    if (!fetching && data) setProductTypes(data.FindManyProductTypes);

    return () => {
      setProductTypes(undefined);
    };
  }, [findProductTypesResult]);

  useEffect(() => {
    executeFindProductTypeDetailedQuery();
  }, [selectedProductTypeId]);

  const { fetching, error } = findProductTypesResult;

  const filteredProductTypes = filterProductTypes(
    productTypes,
    productTypesFilterValues
  );

  const productTypeDetails = !findProductTypeDetailedResult.fetching
    ? findProductTypeDetailedResult.data?.FindManyProductTypes[0]
    : undefined;

  return (
    <>
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={error} />
        <ErrorAlert error={deleteProductTypesMutationResult.error} />
        <Col span={24}>
          <ProductTypesFilter onFinish={setProductTypesFilterValues} />
        </Col>
        <Col span={24}>
          <ProductTypesTable
            loading={fetching || deleteProductTypesMutationResult.fetching}
            onCreate={handleCreate}
            onDelete={handleDelete}
            onView={handleView}
            productTypes={filteredProductTypes}
          />
        </Col>
      </Row>
      <ProductTypeDetailsModal
        loading={findProductTypeDetailedResult.fetching}
        onClose={handleCloseDetailsModal}
        open={!!selectedProductTypeId}
        productTypeDetails={productTypeDetails}
      />
      <CreateProductTypesModal
        onClose={handleCloseCreateModal}
        onSuccess={handleCreateSuccess}
        open={isCreateProductTypeModalOpen}
      />
    </>
  );
};
