import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { ListProducts } from '../../../../components/Product/Product/ListProducts/ListProducts';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';

export const ProductsPage = (): ReactElement => {
  const { t } = useTranslation();

  useTitle({ title: 'tab.product.products' });
  useMenuItem({
    openMenu: ['/support'],
    selectedMenu: ['/support/products'],
  });

  return (
    <>
      <DefaultPageHeader
        subTitle={t('pages.product.productsPage.subTitle')}
        title={t('pages.product.productsPage.title')}
      />
      <ListProducts />
    </>
  );
};

const ProductsPageAccess = Access(ProductsPage, {
  acceptedPermissions: [{ module: 'PRODUCTS', actions: ['CREATE'] }],
  fallback: <Navigate to="/home" replace />,
});

export default ProductsPageAccess;
