import {
  Button,
  Card,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CarrierHiredFreightFragment } from '../../../../graphql/generated/graphql';
import { FormattedDate } from '../../../Formatters/FormattedDate/FormattedDate';
import { FormattedRoute } from '../../../Formatters/Freight/FormattedRoute/FormattedRoute';
import { Volume } from '../../../../core/Freight/Volume';
import { Price } from '../../../../core/Price';
import { ProductWithVariety } from '../../../Product/Product/ProductWithVariety';

interface ICarrierHiredFreightTableProps {
  freights?: CarrierHiredFreightFragment[];
  loading?: boolean;
  onView: (quotationId: string, freightId: string) => void;
}

export const CarrierHiredFreightTable = ({
  freights,
  loading,
  onView,
}: ICarrierHiredFreightTableProps) => {
  const CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH =
    'components.freight.carrier.carrierHiredFreightTable';

  const { t } = useTranslation();

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.title`)}
      </Typography.Text>
    </Row>
  );

  const columns: TableColumnsType<CarrierHiredFreightFragment> = [
    {
      title: t(`${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.route`),
      dataIndex: ['freight', 'route'],
      render: (route: CarrierHiredFreightFragment['freight']['route']) => (
        <div style={{ minWidth: 200 }}>
          <FormattedRoute route={route} />
        </div>
      ),
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.shipment_date`
      ),
      dataIndex: ['freight', 'shipment_date'],
      render: shipmentDate => <FormattedDate dateTime={shipmentDate} />,
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.landing_date`
      ),
      dataIndex: ['freight', 'landing_date'],
      render: landingDate => <FormattedDate dateTime={landingDate} />,
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product_type`
      ),
      dataIndex: ['freight', 'product_type', 'name'],
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product`
      ),
      dataIndex: ['freight', 'product', 'name'],
      render: (_, quotation) => (
        <>
          {ProductWithVariety.getProductWithVariety({
            product: quotation.freight.product,
            product_variety: quotation.freight.product_variety,
          })}
        </>
      ),
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.packing`
      ),
      dataIndex: ['freight', 'packing', 'name'],
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.requested_volume`
      ),
      dataIndex: 'requested_volume',
      render: (requested_volume, quotation) => (
        <div style={{ minWidth: 90 }}>
          <Volume.Display
            volume={requested_volume}
            unitType={quotation.freight.unit_type}
          />
        </div>
      ),
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.value_per_unit`
      ),
      dataIndex: 'value_per_unit',
      render: (price, quotation) => (
        <div style={{ minWidth: 90 }}>
          <Price.Display price={price} unitType={quotation.value_unit_type} />
        </div>
      ),
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.status`
      ),
      dataIndex: ['status', 'name'],
      render: status => <>{t(`enums.quotation.quotationStatus.${status}`)}</>,
    },
    {
      title: t(
        `${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.columns.actions`
      ),
      dataIndex: 'actions',
      render: (_, { id, freight }) => (
        <Space>
          <Button type="link" onClick={() => onView(id, freight.id)}>
            {t(`${CARRIER_HIRED_FREIGHT_TABLE_TRANSLATION_PATH}.actions.view`)}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card title={title}>
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={freights}
        loading={loading}
        rowKey={quotation => quotation.id}
      />
    </Card>
  );
};
