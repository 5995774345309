import { Card, CardProps, Descriptions, Row, Skeleton, Typography } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationShortDescriptionFragment } from '../../../../graphql/generated/graphql';
import { ResponsiveDescriptions } from '../../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { Price } from '../../../../core/Price';
import { Volume } from '../../../../core/Freight/Volume';

interface IQuotationShortDescriptionProps extends Partial<CardProps> {
  data?: QuotationShortDescriptionFragment;
  loading?: boolean;
}

const TRANSLATION_PATH =
  'components.freight.quotation.quotationShortDescription';

export const QuotationShortDescription = ({
  data,
  loading,
  ...cardProps
}: IQuotationShortDescriptionProps): ReactElement => {
  const { t } = useTranslation();

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>{t(`${TRANSLATION_PATH}.title`)}</Typography.Text>
    </Row>
  );

  const column = {
    xxl: 4,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <Card title={title} bordered={false} {...cardProps}>
      <ResponsiveDescriptions column={column} layout={layout}>
        <Descriptions.Item
          label={t(`${TRANSLATION_PATH}.fields.value_per_unit`)}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {data?.value_per_unit && (
              <Price.Display
                price={data.value_per_unit}
                unitType={data.value_unit_type}
              />
            )}
          </Skeleton>
        </Descriptions.Item>
        {data?.requested_volume ? (
          <Descriptions.Item
            label={t(`${TRANSLATION_PATH}.fields.requested_volume`)}
          >
            <Volume.Display
              volume={data.requested_volume}
              unitType={data.freight.unit_type}
            />
          </Descriptions.Item>
        ) : (
          <Descriptions.Item
            label={t(`${TRANSLATION_PATH}.fields.total_volume`)}
          >
            <Skeleton active loading={loading} paragraph={false}>
              {data?.total_volume && (
                <Volume.Display
                  volume={data.total_volume}
                  unitType={data.freight.unit_type}
                />
              )}
            </Skeleton>
          </Descriptions.Item>
        )}
      </ResponsiveDescriptions>
    </Card>
  );
};
