import { Modal, Typography, message } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../../../../contexts/UserContext/useUserContext';
import {
  CompanyStatusEnum,
  useApproveCompanyMutation,
} from '../../../../../../../graphql/generated/graphql';

export interface IUseApproveCompanyModal {
  id?: string;
  mother_company?: {
    companyStatus?: { status: CompanyStatusEnum }[] | null;
  } | null;
}

export const useApproveCompanyModal = (
  { id: companyId, mother_company: motherCompany }: IUseApproveCompanyModal,
  onConfirmSuccess: () => void = () => null
) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.components.approveCompanyModal.useApproveCompanyModal',
  });

  const { userData } = useUserContext();

  const [isOpen, setIsOpen] = useState(false);

  const [result, executeMutation] = useApproveCompanyMutation();

  const handlers = useMemo(
    () => ({
      open() {
        if (companyId) {
          if (
            !motherCompany ||
            motherCompany.companyStatus?.some(
              ({ status }) => status === CompanyStatusEnum.Approved
            )
          ) {
            setIsOpen(true);
          } else {
            Modal.warning({
              title: t('title'),
              content: (
                <Typography.Paragraph>{t('content')}</Typography.Paragraph>
              ),
            });
          }
        }
      },
      close() {
        setIsOpen(false);
      },
      confirm() {
        if (companyId && userData) {
          executeMutation({
            input: { company_id: companyId, manager_id: userData.id },
          }).then(({ data }) => {
            if (data) {
              message.success({
                key: data.ApproveCompany.id,
                content: t('success'),
              });

              onConfirmSuccess();
            }
          });

          setIsOpen(false);
        }
      },
    }),
    [companyId, motherCompany, t, userData, executeMutation, onConfirmSuccess]
  );

  return [isOpen, handlers, result] as const;
};
