import { ReactElement } from 'react';
import { DefaultTable } from '../../../../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import { CompanyDetailsPageQuery } from '../../../../../../../../graphql/generated/graphql';
import { Button, TableColumnsType, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormattedDateTime } from '../../../../../../../../components/Formatters/FormattedDateTime/FormattedDateTime';
import { FormattedDate } from '../../../../../../../../components/Formatters/FormattedDate/FormattedDate';
import { SelectOutlined } from '@ant-design/icons';

export type ICarrierCompanyPoliciesTableItem = NonNullable<
  CompanyDetailsPageQuery['FindCompany']['policies']
>[0];

export type ICarrierCompanyPoliciesTableProps =
  TableProps<ICarrierCompanyPoliciesTableItem>;

export const CarrierCompanyPoliciesTable = (
  props: ICarrierCompanyPoliciesTableProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.carrier.carrierCompanyPoliciesTable',
  });

  const columns: TableColumnsType<ICarrierCompanyPoliciesTableItem> = [
    { dataIndex: 'name', title: t('columns.name') },
    {
      dataIndex: 'expires_in',
      render: expiresIn => <FormattedDate dateTime={expiresIn} />,
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.expiresIn'),
    },
    {
      dataIndex: 'created_at',
      render: createdAt => <FormattedDateTime dateTime={createdAt} />,
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.createdAt'),
    },
    {
      dataIndex: 'updated_at',
      render: updatedAt => <FormattedDateTime dateTime={updatedAt} />,
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.updatedAt'),
    },
    {
      dataIndex: 'actions',
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.actions'),
      render: (_: unknown, record: ICarrierCompanyPoliciesTableItem) => (
        <Button
          type="link"
          onClick={() =>
            window.open(record.bucket_url, '_blank', 'noopener noreferrer')
          }
        >
          {t('actions.view')}
          <SelectOutlined style={{ transform: 'scaleX(-1)' }} />
        </Button>
      ),
    },
  ];

  return <DefaultTable columns={columns} rowKey={({ id }) => id} {...props} />;
};
