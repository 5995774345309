import { Card, Col, Descriptions, Row, Skeleton } from 'antd';
import { CSSProperties, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import {
  FindRouteForViewRouteQuery,
  MoveTypeEnum,
  useFindRouteForViewRouteQuery,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { ResponsiveDescriptions } from '../../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { ViewPlace } from '../../Place/ViewPlace/ViewPlace';
import { RouteMap } from '../RouteMap/RouteMap';

interface IViewRouteProps {
  /**
   * Id of the route that should be showed
   */
  routeId?: string;
  /**
   * Style for the route map
   */
  mapStyle?: CSSProperties;
  /**
   * Show the route name
   */
  showRouteName?: boolean;
  /**
   * Route data to be showed instead of using routeId to find route
   */
  route?: FindRouteForViewRouteQuery['FindRoute'];
  /**
   * Show loading
   */
  loading?: boolean;
  /**
   * Style for the place cards
   */
  cardStyle?: CSSProperties;
}

/**
 * Description component to view detailed Route data
 */
export const ViewRoute = ({
  routeId,
  mapStyle,
  showRouteName = true,
  route,
  loading,
  cardStyle,
}: IViewRouteProps): ReactElement => {
  const VIEW_ROUTE_TRANSLATION_PATH = 'components.freight.route.viewRoute';

  const { t } = useTranslation();

  const findRouteVariables = routeId
    ? {
        input: {
          id: routeId,
        },
      }
    : undefined;

  const [findRouteResult] = useFindRouteForViewRouteQuery({
    variables: findRouteVariables,
    pause: !findRouteVariables,
  });

  const { data, error } = findRouteResult;

  const routeData = route || data?.FindRoute;
  const fetching = loading || findRouteResult.fetching;

  const withdrawalPlace = routeData?.places?.find(
    place => place.move_type === MoveTypeEnum.Withdrawal
  );

  const withdrawalCoordinates = Formatter.formatCoordinates(withdrawalPlace);

  const deliveryPlace = routeData?.places?.find(
    place => place.move_type === MoveTypeEnum.Delivery
  );

  const deliveryCoordinates = Formatter.formatCoordinates(deliveryPlace);

  const colSpan = {
    xxl: 8,
    xl: 12,
    lg: 12,
    md: 24,
    sm: 24,
    xs: 24,
  };

  return (
    <Row gutter={[24, 0]}>
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />
      <Col span={24}>
        <Row gutter={[24, 24]}>
          {showRouteName && (
            <Col span={24}>
              <Card>
                <ResponsiveDescriptions layout={{ xs: 'vertical' }}>
                  <Descriptions.Item
                    style={{ padding: 0 }}
                    label={t(
                      `${VIEW_ROUTE_TRANSLATION_PATH}.routeCard.descriptions.name`
                    )}
                  >
                    <Skeleton active loading={fetching} paragraph={false}>
                      {routeData?.name}
                    </Skeleton>
                  </Descriptions.Item>
                </ResponsiveDescriptions>
              </Card>
            </Col>
          )}
          <Col {...colSpan}>
            <Card
              style={cardStyle}
              title={t(
                `${VIEW_ROUTE_TRANSLATION_PATH}.withdrawalPlaceCard.title`
              )}
            >
              <ViewPlace place={withdrawalPlace} loading={fetching} />
            </Card>
          </Col>
          <Col {...colSpan}>
            <Card
              style={cardStyle}
              title={t(
                `${VIEW_ROUTE_TRANSLATION_PATH}.deliveryPlaceCard.title`
              )}
            >
              <ViewPlace place={deliveryPlace} loading={fetching} />
            </Card>
          </Col>
          <Col {...colSpan} lg={24} xl={24}>
            <Card>
              <RouteMap
                style={mapStyle}
                showDescriptions={
                  !!withdrawalCoordinates && !!deliveryCoordinates
                }
                pickupPlaceCoordinates={withdrawalCoordinates}
                dropoffPlaceCoordinates={deliveryCoordinates}
                interactionOptions={{
                  scrollZoom: true,
                  interactive: true,
                }}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
