import {
  Button,
  Popconfirm,
  TableColumnsType,
  TableProps,
  Typography,
} from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CompaniesPageQuery,
  CompanyTypeEnum,
  FunctionEnum,
  UserDetailsPage_FindUserAndPermissionsQuery,
} from '../../../../../graphql/generated/graphql';
import { DefaultTable } from '../../../../AntdCustom/DefaultTable/DefaultTable';
import { CompanyFunction } from '../../../../Company/CompanyFunction/CompanyFunction';
import { CompanyName } from '../../../../Company/CompanyName/CompanyName';
import { CompanyType } from '../../../../Company/CompanyType/CompanyType';
import { LastCompanyStatusTag } from '../../../../Company/LastCompanyStatusTag/LastCompanyStatusTag';
import { FormattedDateTime } from '../../../../Formatters/FormattedDateTime/FormattedDateTime';
import { FormattedDocumentNumber } from '../../../../Formatters/FormattedDocumentNumber/FormattedDocumentNumber';

export type ICompaniesPageTableItem =
  | CompaniesPageQuery['FindCompanies'][number]
  | NonNullable<
      UserDetailsPage_FindUserAndPermissionsQuery['FindUser']['companies']
    >[number]['company'];

export interface ICompaniesPageTableProps
  extends TableProps<ICompaniesPageTableItem> {
  handleUnlinkCompany?: ({ companyId }: { companyId: string }) => void;
}

export const CompaniesPageTable = ({
  handleUnlinkCompany,
  ...props
}: ICompaniesPageTableProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.support.account.company.companiesPageTable',
  });

  const navigate = useNavigate();

  const handleViewCompany = (companyId: string) => {
    navigate(`/support/accounts/company/${companyId}`);
  };

  const columns: TableColumnsType<ICompaniesPageTableItem> = [
    {
      dataIndex: 'document_number',
      title: t('columns.documentNumber'),
      render: (documentNumber: string) => (
        <div style={{ minWidth: 150 }}>
          <Typography.Text copyable={{ text: documentNumber }}>
            <FormattedDocumentNumber documentNumber={documentNumber} />
          </Typography.Text>
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: 'trade_name',
      title: t('columns.companyName'),
      render: (_, company) => (
        <div style={{ minWidth: 300 }}>
          <CompanyName company={company} />
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: 'company_type',
      title: t('columns.companyType'),
      render: (companyType: CompanyTypeEnum) => (
        <CompanyType companyType={companyType} />
      ),
      sorter: true,
    },
    {
      dataIndex: 'company_function',
      title: t('columns.companyFunction'),
      render: (companyFunction: FunctionEnum) => (
        <CompanyFunction companyFunction={companyFunction} />
      ),
      sorter: true,
    },
    {
      dataIndex: 'companyStatus',
      title: t('columns.companyStatus'),
      render: (_, company) => (
        <LastCompanyStatusTag companyStatus={company.companyStatus} />
      ),
    },
    {
      dataIndex: 'created_at',
      title: t('columns.createdAt'),
      defaultSortOrder: 'descend',
      render: (createdAt: string) => (
        <div style={{ minWidth: 100 }}>
          <FormattedDateTime dateTime={createdAt} />
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: 'updated_at',
      title: t('columns.updatedAt'),
      render: (updatedAt: string) => (
        <div style={{ minWidth: 100 }}>
          <FormattedDateTime dateTime={updatedAt} />
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: 'actions',
      title: t('columns.actions'),
      render: (_, record) => (
        <>
          <Button onClick={() => handleViewCompany(record.id)} type="link">
            {t('actions.view')}
          </Button>
          {handleUnlinkCompany && (
            <Popconfirm
              title={t('actions.delete.popconfirm.title')}
              okText={t('actions.delete.popconfirm.actions.confirm')}
              okButtonProps={{ danger: true, type: 'default' }}
              cancelButtonProps={{ type: 'primary' }}
              onConfirm={() => handleUnlinkCompany({ companyId: record.id })}
            >
              <Button danger type="link">
                {t('actions.delete.title')}
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return <DefaultTable columns={columns} rowKey={({ id }) => id} {...props} />;
};
