/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  TimePicker,
  Typography,
} from 'antd';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, {
  MapLayerMouseEvent,
  MapRef,
  Marker,
  MarkerDragEvent,
  ViewStateChangeEvent,
} from 'react-map-gl';
import Formatter from '../../../../classes/Formatter';
import {
  CreatePlaceInput,
  PlaceTableItemFragment,
  useCreatePlaceModalMutation,
} from '../../../../graphql/generated/graphql';
import useCityStateSelects from '../../../../hooks/useCityStateSelects/useCityStateSelects';
import MapStyle from '../../../../structures/interfaces/Mapbox/MapStyles';
import { parseLocaleNumber } from '../../../../utils';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import dayjs from 'dayjs';

import './CreatePlaceModal.scss';

interface ICreatePlaceModalForm {
  city_id: number;
  state_id: string;
  opening_hour: [dayjs.Dayjs, dayjs.Dayjs];
  cadence: number;
  name: string;
  note: string;
  latitude: string;
  longitude: string;
  has_balance?: boolean;
  balance_capacity?: string;
  has_dirt_road?: boolean;
  dirt_road_distance?: string;
}

interface ICoordinates {
  longitude: number;
  latitude: number;
  zoom: number;
}

interface ICreatePlaceModalProps {
  /**
   * Is modal visible?
   */
  isVisible?: boolean;
  /**
   * Function to execute when a place is successfully created
   */
  onCreateSuccess?: (place: PlaceTableItemFragment) => void;
  /**
   * Function to close modal
   */
  onClose?: () => void;
  /**
   * User's company id
   */
  companyId?: string;
}

/**
 * Modal to create a new place
 */
export const CreatePlaceModal = ({
  isVisible = false,
  onCreateSuccess,
  onClose,
  companyId = '',
}: ICreatePlaceModalProps): ReactElement => {
  const PLACE_FILTER_TRANSLATION_PATH =
    'components.freight.place.createPlaceModal';

  const defaultCoordinates: ICoordinates = {
    longitude: -54,
    latitude: -12,
    zoom: 3,
  };

  const { t } = useTranslation();

  const [form] = Form.useForm<ICreatePlaceModalForm>();

  const [result, executeMutation] = useCreatePlaceModalMutation();

  const { fetching, error } = result;

  const mapRef = useRef<MapRef | null>(null);

  const [marker, setMarker] = useState<Omit<ICoordinates, 'zoom'>>();
  const [coordinates, setCoordinates] = useState(defaultCoordinates);

  const [hasDirtRoad, setHasDirtRoad] = useState(false);
  const [hasBalance, setHasBalance] = useState(false);

  const handleCitySelect = () => {
    const formValues = form.getFieldsValue(['latitude', 'longitude']);

    const latitude = formValues.latitude
      ? Number(formValues.latitude)
      : undefined;

    const longitude = formValues.longitude
      ? Number(formValues.longitude)
      : undefined;

    if (latitude && longitude) {
      setMarker({ latitude, longitude });
      mapRef.current?.flyTo({
        animate: true,
        zoom: 12,
        center: {
          lat: latitude,
          lon: longitude,
        },
      });
    }
  };

  const [stateFormItem, cityFormItem] = useCityStateSelects({
    form,
    state: {
      name: 'state_id',
      required: true,
      rules: [{ required: true }],
      label: t(`${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.state_id`),
      selectProps: {
        allowClear: true,
        showSearch: true,
        placeholder: t(
          `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.state_id`
        ),
        size: 'small',
      },
    },
    city: {
      name: 'city_id',
      required: true,
      rules: [{ required: true }],
      label: t(`${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.city_id`),
      selectProps: {
        allowClear: true,
        showSearch: true,
        placeholder: t(
          `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.city_id`
        ),
        size: 'small',
        onSelect: handleCitySelect,
      },
      withCoordinates: { latitude: 'latitude', longitude: 'longitude' },
    },
  });

  const Title = () =>
    useMemo(() => <>{t(`${PLACE_FILTER_TRANSLATION_PATH}.title`)}</>, []);

  const Footer = () =>
    useMemo(
      () => (
        <>
          <Button danger size="small" onClick={onClose} disabled={fetching}>
            {t(`${PLACE_FILTER_TRANSLATION_PATH}.actions.cancel`)}
          </Button>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            loading={fetching}
            onClick={() => {
              form.submit();
            }}
          >
            {t(`${PLACE_FILTER_TRANSLATION_PATH}.actions.register`)}
          </Button>
        </>
      ),
      [fetching]
    );

  const createPlaceModalMutationSerializer = (
    formValues: ICreatePlaceModalForm
  ): CreatePlaceInput => {
    const [opening_hour, closing_hour] = formValues.opening_hour;

    const balance_capacity =
      formValues.balance_capacity && formValues.has_balance
        ? Math.round(
            (parseLocaleNumber(formValues.balance_capacity) || 0) * 1000
          )
        : undefined;

    const dirt_road_distance =
      formValues.dirt_road_distance && formValues.has_dirt_road
        ? Math.round(
            (parseLocaleNumber(formValues.dirt_road_distance) || 0) * 1000
          )
        : undefined;

    return {
      ...formValues,
      company_id: companyId,
      opening_hour: opening_hour.toISOString(),
      closing_hour: closing_hour.toISOString(),
      latitude: marker?.latitude ? String(marker.latitude) : '',
      longitude: marker?.longitude ? String(marker.longitude) : '',
      balance_capacity,
      dirt_road_distance,
    };
  };

  const handleFormSubmit = (formValues: ICreatePlaceModalForm) => {
    executeMutation({
      input: createPlaceModalMutationSerializer(formValues),
    }).then(({ data }) => {
      if (data) {
        form.resetFields();
        setCoordinates(defaultCoordinates);
        setMarker(undefined);
        setHasBalance(false);
        setHasDirtRoad(false);
        if (onCreateSuccess) onCreateSuccess(data.CreatePlace);
      }
    });
  };

  const handleMapMove = (event: ViewStateChangeEvent) => {
    setCoordinates({
      latitude: event.viewState.latitude,
      longitude: event.viewState.longitude,
      zoom: event.viewState.zoom,
    });
  };

  const handleMarkerMove = (event: MapLayerMouseEvent | MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  };

  const handleHasDirtRoad: NonNullable<CheckboxProps['onChange']> = event => {
    if (!event.target.checked) {
      form.setFieldValue('dirt_road_distance', undefined);
    }

    setHasDirtRoad(event.target.checked);
  };

  const handleHasBalance: NonNullable<CheckboxProps['onChange']> = event => {
    if (!event.target.checked) {
      form.setFieldValue('balance_capacity', undefined);
    }

    setHasBalance(event.target.checked);
  };

  useEffect(() => {
    if (marker) {
      form.setFieldsValue({
        latitude: String(marker?.latitude),
        longitude: String(marker?.longitude),
      });
    }
  }, [marker]);

  return (
    <Modal
      destroyOnClose
      footer={<Footer />}
      onCancel={onClose}
      open={isVisible}
      title={<Title />}
      width={'992px'}
    >
      <>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <Row gutter={24}>
          <Col lg={12}>
            <Form
              form={form}
              layout="vertical"
              scrollToFirstError
              disabled={fetching}
              onFinish={handleFormSubmit}
            >
              <Row gutter={24}>
                <Form.Item name="latitude" noStyle hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="longitude" noStyle hidden>
                  <Input />
                </Form.Item>
                <Col xs={24} sm={16}>
                  <Form.Item
                    required
                    name="name"
                    rules={[{ required: true }]}
                    label={t(
                      `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.name`
                    )}
                  >
                    <Input
                      allowClear
                      size="small"
                      placeholder={t(
                        `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.name`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  {stateFormItem}
                </Col>
                <Col xs={24} sm={12}>
                  {cityFormItem}
                </Col>
                <Col xs={24} sm={16}>
                  <Form.Item
                    required
                    name="opening_hour"
                    rules={[{ required: true }]}
                    label={t(
                      `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.opening_hour`
                    )}
                  >
                    <TimePicker.RangePicker
                      format="HH:mm"
                      size="small"
                      style={{ width: '100%' }}
                      placeholder={[
                        t(
                          `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.opening_hour`
                        ),
                        t(
                          `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.opening_hour`
                        ),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16}>
                  <Form.Item
                    required
                    validateFirst
                    name="cadence"
                    rules={[
                      { required: true },
                      { min: 1, type: 'number' },
                      { max: 2000000000, type: 'number' },
                    ]}
                    label={t(
                      `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.cadence`
                    )}
                  >
                    <InputNumber
                      size="small"
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16}>
                  <Form.Item name="has_dirt_road" valuePropName="checked">
                    <Checkbox onChange={handleHasDirtRoad}>
                      {t(
                        `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.has_dirt_road`
                      )}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16}>
                  <Form.Item
                    validateFirst
                    name="dirt_road_distance"
                    normalize={Formatter.formatMoney}
                    dependencies={['has_dirt_road']}
                    rules={[
                      { required: hasDirtRoad },
                      {
                        min: 0.001,
                        type: 'number',
                        transform: parseLocaleNumber,
                      },
                      {
                        max: 2000000,
                        type: 'number',
                        transform: parseLocaleNumber,
                      },
                    ]}
                    label={t(
                      `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.dirt_road_distance`
                    )}
                  >
                    <Input
                      size="small"
                      disabled={!hasDirtRoad}
                      addonAfter={
                        <>{t('enums.measureUnits.length.kilometer.initials')}</>
                      }
                      placeholder={t(
                        `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.dirt_road_distance`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16}>
                  <Form.Item name="has_balance" valuePropName="checked">
                    <Checkbox onChange={handleHasBalance}>
                      {t(
                        `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.has_balance`
                      )}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16}>
                  <Form.Item
                    validateFirst
                    name="balance_capacity"
                    normalize={Formatter.formatVolume}
                    dependencies={['has_balance']}
                    rules={[
                      { required: hasBalance },
                      {
                        min: 0.001,
                        type: 'number',
                        transform: parseLocaleNumber,
                      },
                      {
                        max: 1000000,
                        type: 'number',
                        transform: parseLocaleNumber,
                      },
                    ]}
                    label={t(
                      `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.balance_capacity`
                    )}
                  >
                    <Input
                      size="small"
                      disabled={!hasBalance}
                      addonAfter={
                        <>{t('enums.measureUnits.mass.tonne.initials')}</>
                      }
                      placeholder={t(
                        `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.balance_capacity`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                  <Form.Item
                    name="note"
                    label={t(
                      `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.note`
                    )}
                  >
                    <Input.TextArea
                      size="small"
                      placeholder={t(
                        `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.note`
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={12}>
            <Typography.Paragraph type="secondary">
              {t(`${PLACE_FILTER_TRANSLATION_PATH}.inputs.help.map`)}
            </Typography.Paragraph>
            <div className="create-place-modal__map-container">
              <Map
                {...coordinates}
                reuseMaps
                ref={mapRef}
                mapStyle={MapStyle.streets}
                style={{ height: '100%', width: '100%' }}
                onMove={handleMapMove}
                onClick={handleMarkerMove}
              >
                {marker && (
                  <Marker {...marker} onDrag={handleMarkerMove} draggable />
                )}
              </Map>
            </div>
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default CreatePlaceModal;
