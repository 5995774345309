import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryParams from '../../../../hooks/useQueryParams/useQueryParams';

export interface IRoutesFilterProps {
  /**
   * Function to execute when "Filter" button is clicked
   */
  onFilter?: (formValue: IRouteFilterForm) => void;
  /**
   * Function to execute when "Reset" button is clicked
   */
  onReset?: () => void;
  /**
   * Places cities to be showed as pick-up select options (null is used as loading state)
   */
  pickupPlaces?: IPlaceCity[] | null;
  /**
   * Places cities to be showed as drop-off select options (null is used as loading state)
   */
  dropoffPlaces?: IPlaceCity[] | null;
}

interface IPlaceCity {
  city_id: number;
  city_name: string;
  state_id: string;
}

export interface IRouteFilterForm {
  search?: string;
  pickupCityId?: number;
  dropoffCityId?: number;
}

export const RoutesFilter = ({
  onFilter,
  onReset,
  pickupPlaces,
  dropoffPlaces,
}: IRoutesFilterProps): ReactElement => {
  const ROUTES_FILTER_TRANSLATION_PATH =
    'components.freight.route.routesFilter';

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [getQueryParams, setQueryParams] = useQueryParams();

  const onFinish = (formValues: IRouteFilterForm) => {
    setQueryParams({ ...formValues });

    onFilter && onFilter(formValues);
  };

  const getCityOptions = (
    places?: IPlaceCity[] | null
  ): SelectProps['options'] | undefined => {
    return places?.map(place => ({
      label: `${place.city_name} - ${place.state_id}`,
      value: place.city_id,
    }));
  };

  const colSpan = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
    xl: 4,
    xxl: 4,
  };

  const pickupCityOptions = getCityOptions(pickupPlaces);
  const dropoffCityOptions = getCityOptions(dropoffPlaces);

  useEffect(() => {
    const queryParams = getQueryParams();

    const formValues = {
      search: queryParams.search,
      pickupCityId: Number(queryParams.pickupCityId) || undefined,
      dropoffCityId: Number(queryParams.dropoffCityId) || undefined,
    };

    if (Object.values(formValues).filter(formValue => formValue).length > 0) {
      form.setFieldsValue(formValues);
      onFilter && onFilter(formValues);
    }
  }, [form, getQueryParams, onFilter]);

  /**
   * Form card to filter routes
   */
  return (
    <Card bordered={false}>
      <Form form={form} layout="vertical" onFinish={onFinish} onReset={onReset}>
        <Row gutter={24} align="middle">
          <Col {...colSpan} xl={5} xxl={5}>
            <Form.Item
              name="search"
              label={t(
                `${ROUTES_FILTER_TRANSLATION_PATH}.inputs.labels.search`
              )}
            >
              <Input.Search
                allowClear
                size="small"
                onSearch={() => form.submit()}
                placeholder={t(
                  `${ROUTES_FILTER_TRANSLATION_PATH}.inputs.placeholders.search`
                )}
              />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item
              name="pickupCityId"
              label={t(
                `${ROUTES_FILTER_TRANSLATION_PATH}.inputs.labels.pickupCityId`
              )}
            >
              <Select
                allowClear
                size="small"
                loading={pickupPlaces === null}
                disabled={pickupPlaces === null}
                options={pickupCityOptions}
                placeholder={t(
                  `${ROUTES_FILTER_TRANSLATION_PATH}.inputs.placeholders.pickupCityId`
                )}
              />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item
              name="dropoffCityId"
              label={t(
                `${ROUTES_FILTER_TRANSLATION_PATH}.inputs.labels.dropoffCityId`
              )}
            >
              <Select
                allowClear
                size="small"
                options={dropoffCityOptions}
                loading={dropoffPlaces === null}
                disabled={dropoffPlaces === null}
                placeholder={t(
                  `${ROUTES_FILTER_TRANSLATION_PATH}.inputs.placeholders.dropoffCityId`
                )}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={24}
            lg={6}
            xl={{ span: 5, offset: 6 }}
            xxl={{ span: 5, offset: 6 }}
          >
            <Row gutter={24} justify="end">
              <Col>
                <Button htmlType="reset" size="small">
                  {t(`${ROUTES_FILTER_TRANSLATION_PATH}.actions.reset`)}
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" size="small">
                  {t(`${ROUTES_FILTER_TRANSLATION_PATH}.actions.filter`)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
