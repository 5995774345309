import { ReactElement } from 'react';
import {
  FormattedRouteFragment,
  MoveTypeEnum,
} from '../../../../graphql/generated/graphql';

interface IFormattedRouteProps {
  route: FormattedRouteFragment;
  type?: 'name' | 'places';
}

export const FormattedRoute = ({
  route,
  type = 'places',
}: IFormattedRouteProps): ReactElement => {
  const withdrawalPlace = route.places?.find(
    place => place.move_type === MoveTypeEnum.Withdrawal
  );

  const deliveryPlace = route.places?.find(
    place => place.move_type === MoveTypeEnum.Delivery
  );

  if (withdrawalPlace && deliveryPlace && type === 'places')
    return (
      <>
        {withdrawalPlace.city_name} {withdrawalPlace.state_id}
        {' - '}
        {deliveryPlace.city_name} {deliveryPlace.state_id}
      </>
    );

  return <>{route.name}</>;
};
