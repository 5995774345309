import { useTranslation } from 'react-i18next';
import { i18nUtils } from './I18n.utils';
import { useMemo } from 'react';

interface IUseI18nProps<T extends Record<string, string>> {
  keys: T;
  keyPrefix?: string;
}

export const useI18n = <T extends Record<string, string>>({
  keys,
  keyPrefix,
}: IUseI18nProps<T>) => {
  const { t } = useTranslation('translations', {
    keyPrefix,
  });

  const i18n = useMemo(() => {
    const tKeys = i18nUtils.translateAllKeys(t, keys);

    return { keys, tKeys, t };
  }, [keys, t]);

  return i18n;
};
