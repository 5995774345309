import { FormInstance, SelectProps } from 'antd';
import { TFunction } from 'i18next';
import Normalizer from '../classes/Normalizer';
import { SortOrder } from '../graphql/generated/graphql';
import { getFileValueFromEvent } from './getFileValueFromEvent/getFileValueFromEvent';
import { getPopupContainer } from './getPopupContainer/getPopupContainer';

/**
 * General utils functions
 */
export const isUUID = (value: string): boolean => {
  const uuiPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuiPattern.test(value);
};

export const translateOptions = (
  t: TFunction,
  options: NonNullable<SelectProps['options']>
): NonNullable<SelectProps['options']> => {
  return options.map(({ label, value }) => ({
    label: typeof label === 'string' ? t(label) : label,
    value,
  }));
};

export const selectFilterOptionByLabel = (
  input: string,
  option?: NonNullable<SelectProps['options']>[number]
) => {
  if (option?.label) {
    return Normalizer.removeAccent(`${option.label}`, true).includes(
      Normalizer.removeAccent(input, true)
    );
  }

  return false;
};

export const roundWithDecimals = (value: number, decimals = 2): number => {
  const multiplier = Math.pow(10, decimals);
  const roundedValue = Math.round(value * multiplier);

  return roundedValue / multiplier;
};

export const scrollToFirstError = (
  form: FormInstance,
  { validateFields } = { validateFields: false }
) => {
  const formValidateFields = validateFields
    ? form.validateFields()
    : new Promise<void>((_, reject) => reject());

  formValidateFields.catch(() => {
    const errors = form
      .getFieldsError()
      .filter(({ errors }) => errors.length > 0);

    const firstError = errors[0];

    if (firstError) form.scrollToField(firstError.name);
  });
};

type ICalculateQuotationCommission = { centavos: number } | { real: number };
export const calculateQuotationCommissionInCentavos = (
  data: ICalculateQuotationCommission
) => {
  const commissionPercent = 0;
  const centavos = 'centavos' in data ? data.centavos : data.real * 100;

  return Math.ceil(centavos * (1 + commissionPercent));
};

export const filterOptionByLabel = (
  input: string,
  option?: NonNullable<SelectProps['options']>[number]
) => {
  if (option?.label) {
    return Normalizer.removeAccent(`${option.label}`, true).includes(
      Normalizer.removeAccent(input, true)
    );
  }

  return false;
};

/**
 * Parses a string number to a float, using the given locale's formatting rules.
 *
 * @param {string|null|undefined} stringNumber The number to be parsed.
 * @param {string} [locale='pt-BR'] The locale to be used for formatting.
 * @returns {number|undefined} The parsed float number.
 */

export const parseLocaleNumber = (
  stringNumber?: string | number | null,
  locale = 'pt-BR'
) => {
  if (!stringNumber) return undefined;

  if (typeof stringNumber === 'number') return stringNumber;

  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
};

export const SortOrderEnum = (
  sortOrder?: 'descend' | 'ascend' | null
): [SortOrder | undefined, number] => {
  switch (sortOrder) {
    case 'descend':
      return [SortOrder.Desc, -1];
    case 'ascend':
      return [SortOrder.Asc, 1];
    default:
      return [undefined, 0];
  }
};

type ICharsetOptions = 'lowerCase' | 'upperCase' | 'numbers' | 'symbols';

/**
 * Generates a random password with the given length and character set options.
 *
 * @param {number} length - The length of the password.
 * @param {('all'|ICharsetOptions[])} [options='all'] - The character set options to use.
 * @returns {string} A randomly generated password.
 */
export const generatePassword = (
  length: number,
  options: 'all' | ICharsetOptions[] = 'all'
): string => {
  const lowerCaseCharset = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberCharset = '0123456789';
  const symbolCharset = '!@#$%^&*';

  const charset = {
    lowerCase: lowerCaseCharset,
    upperCase: upperCaseCharset,
    numbers: numberCharset,
    symbols: symbolCharset,
  };

  const currentCharset =
    options === 'all'
      ? Object.values(charset).join('')
      : options.map(option => charset[option]).join('');

  const crypto = window.crypto;
  const randomValues = new Uint32Array(length);
  crypto.getRandomValues(randomValues);

  return Array.from(
    randomValues,
    value => currentCharset[value % currentCharset.length]
  ).join('');
};

export const Utils = {
  getPopupContainer,
  getFileValueFromEvent,
};
