import { ButtonProps, TableColumnType, message } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationState } from 'urql';
import { useUserContext } from '../../../../../contexts/UserContext/useUserContext';
import {
  QuotationGroupTableFragment,
  QuotationGroups_DeleteQuotationGroupMutation,
  SortOrder,
  useQuotationGroups_DeleteQuotationGroupMutation,
  useQuotationGroups_ListQuotationGroupsQuery,
} from '../../../../../graphql/generated/graphql';
import { usePagination } from '../../../../../hooks/usePagination';
import { useQuotationGroupFormModal } from '../QuotationGroupFormModal/QuotationGroupFormModal.hooks';
import {
  DeleteButtonAccess,
  EditButtonAccess,
  ViewButtonAccess,
} from './QuotationGroups.components';

export const useQuotationGroupsQuery = () => {
  const { userData } = useUserContext();

  const [pagination, paginationRequestParams, setDataLength] = usePagination();

  const [result, executeQuery] = useQuotationGroups_ListQuotationGroupsQuery({
    variables: {
      orderBy: { created_at: SortOrder.Desc },
      ownerId: userData?.company?.id || '',
      pagination: paginationRequestParams,
    },
  });

  useEffect(() => {
    const total = result.data?.FindManyGroups.length;

    if (total !== undefined && pagination.total !== total) {
      setDataLength(total);
    }
  }, [pagination.total, result.data, setDataLength]);

  return { result, pagination, executeQuery };
};

export const useDeleteQuotationGroup = () => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.shipper.quotationGroupsPage.components.quotationGroups.hooks.useDeleteQuotationGroup',
  });
  const { userData } = useUserContext();

  const [result, executeMutation] =
    useQuotationGroups_DeleteQuotationGroupMutation();

  const handlers = useMemo(
    () => ({
      delete: (
        { groupId }: { groupId?: string },
        {
          onDeleteSuccess,
        }: { onDeleteSuccess?: (groupId: string) => void } = {}
      ) => {
        if (groupId && userData?.company?.id) {
          executeMutation({
            input: { group_id: groupId, owner_id: userData.company.id },
          }).then(({ data }) => {
            if (data) {
              message.success({
                key: data.DeleteGroup.id,
                content: t('deleteGroupSuccess'),
              });

              onDeleteSuccess && onDeleteSuccess(data.DeleteGroup.id);
            }
          });
        }
      },
    }),
    [executeMutation, t, userData]
  );

  return { result, handlers };
};

interface IActionButtonProps {
  onClick: (groupId: string) => void;
  isVisible: boolean;
}

interface IUseActionsColumnProps {
  viewButtonProps?: IActionButtonProps;
  editButtonProps?: IActionButtonProps;
  deleteButtonProps?: Omit<IActionButtonProps, 'onClick'> & {
    onDeleteSuccess: () => void;
  };
}

interface IUseActionsColumn {
  result: UseMutationState<QuotationGroups_DeleteQuotationGroupMutation>;
  actionsColumn: TableColumnType<QuotationGroupTableFragment>;
}

export const useActionsColumn = ({
  viewButtonProps,
  editButtonProps,
  deleteButtonProps,
}: IUseActionsColumnProps = {}): IUseActionsColumn => {
  const {
    result,
    handlers: { delete: handleDelete },
  } = useDeleteQuotationGroup();

  return {
    result,
    actionsColumn: {
      dataIndex: 'actions',
      title: 'Ações',
      width: 250,
      render(_, { id: groupId }) {
        return (
          <div style={{ minWidth: 250 }}>
            {viewButtonProps?.isVisible && (
              <ViewButtonAccess
                onClick={() => viewButtonProps.onClick(groupId)}
              />
            )}
            {editButtonProps?.isVisible && (
              <EditButtonAccess
                onClick={() => editButtonProps.onClick(groupId)}
              />
            )}
            {deleteButtonProps?.isVisible && (
              <DeleteButtonAccess
                onClick={() => {
                  const { onDeleteSuccess } = deleteButtonProps;
                  handleDelete({ groupId }, { onDeleteSuccess });
                }}
              />
            )}
          </div>
        );
      },
    },
  };
};

export interface IUseQuotationGroups {
  actions?: ('view' | 'edit' | 'delete')[];
}

export const useQuotationGroups = ({
  actions = ['view', 'edit', 'delete'],
}: IUseQuotationGroups = {}) => {
  const {
    executeQuery,
    pagination,
    result: { data, error, fetching },
  } = useQuotationGroupsQuery();

  const {
    handlers: { afterClose, close, openCreate, openEdit, openView },
    groupId,
    isCreateOpen,
    isEditOpen,
    isViewOpen,
  } = useQuotationGroupFormModal();

  const { actionsColumn, result: deleteMutationResult } = useActionsColumn({
    viewButtonProps: {
      isVisible: actions.includes('view'),
      onClick(groupId) {
        return openView(groupId);
      },
    },
    editButtonProps: {
      isVisible: actions.includes('edit'),
      onClick(groupId) {
        return openEdit(groupId);
      },
    },
    deleteButtonProps: {
      isVisible: actions.includes('delete'),
      onDeleteSuccess: executeQuery,
    },
  });

  const quotationGroups = useMemo(() => {
    const createButtonProps: ButtonProps = {
      disabled: data && data.FindManyGroups.length >= 10,
    };

    return {
      actionsColumn,
      createButtonProps,
      data: data?.FindManyGroups.data,
      error: error || deleteMutationResult.error,
      executeQuery,
      groupId,
      handlers: { afterClose, close, openCreate },
      isCreateOpen,
      isEditOpen,
      isViewOpen,
      loading: fetching || deleteMutationResult.fetching,
      pagination: { ...pagination, hideOnSinglePage: true },
    };
  }, [
    actionsColumn,
    afterClose,
    close,
    data,
    deleteMutationResult.error,
    deleteMutationResult.fetching,
    error,
    executeQuery,
    fetching,
    groupId,
    isCreateOpen,
    isEditOpen,
    isViewOpen,
    openCreate,
    pagination,
  ]);

  return quotationGroups;
};
