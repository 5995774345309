import { BreadcrumbProps, Col, Row } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { ViewRoute } from '../../../../components/Freight/Route/ViewRoute/ViewRoute';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';
import { breadcrumbItemRender } from '../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

/**
 * Page to view detailed Route data
 */
export const ViewRoutePage = (): ReactElement => {
  const VIEW_ROUTE_PAGE_TRANSLATION_PATH = 'pages.freight.route.viewRoutePage';

  const { t } = useTranslation();
  const { routeId } = useParams();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/shipper/routes',
        breadcrumbName: t('pages.freight.route.title'),
      },
      {
        path: '/shipper/routes/:routeId',
        breadcrumbName: t(`${VIEW_ROUTE_PAGE_TRANSLATION_PATH}.title`),
      },
    ],
  };

  useTitle({ title: `${VIEW_ROUTE_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/routes'],
  });

  return (
    <Col span={24} style={{ overflow: 'auto' }}>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        style={{ minWidth: 400 }}
        subTitle={t(`${VIEW_ROUTE_PAGE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${VIEW_ROUTE_PAGE_TRANSLATION_PATH}.title`)}
      />
      <Row gutter={[0, 24]} style={{ padding: 24, minWidth: 400 }}>
        <Col span={24}>
          <ViewRoute
            cardStyle={{ minHeight: '100%' }}
            mapStyle={{ maxHeight: 350 }}
            routeId={routeId}
          />
        </Col>
      </Row>
    </Col>
  );
};

const ViewRoutePageAccess = Access(ViewRoutePage, {
  acceptedPermissions: [
    {
      module: 'ROUTES',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default ViewRoutePageAccess;
