import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Formatter from '../../../../classes/Formatter';
import Normalizer from '../../../../classes/Normalizer';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  CreatePlaceRouteInput,
  FindPlacesNamesQueryVariables,
  MoveTypeEnum,
  useCreateRouteMutation,
  useFindPlacesNamesQuery,
} from '../../../../graphql/generated/graphql';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { UF, UFNameEnum } from '../../../../structures/interfaces/UF';
import { parseLocaleNumber } from '../../../../utils';
import { DefaultPageFooter } from '../../../AntdCustom/DefaultPageFooter/DefaultPageFooter';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { IUpdatePlaceForm } from '../../Place/UpdatePlaceForm/UpdatePlaceForm';
import { IRouteData, RouteMap } from '../RouteMap/RouteMap';
import { RoutePlaceForm } from '../RoutePlaceForm/RoutePlaceForm';

interface ICoordinates {
  placeId: string;
  latitude: number;
  longitude: number;
}

/**
 * Form to select two Places to create a Route
 */
export const CreateRoute = (): ReactElement => {
  const CREATE_ROUTE_TRANSLATION_PATH = 'components.freight.route.createRoute';

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [routeNameForm] = Form.useForm<{ name: string }>();
  const [pickupPlaceForm] = Form.useForm<IUpdatePlaceForm>();
  const [dropoffPlaceForm] = Form.useForm<IUpdatePlaceForm>();

  const placeValidationErrorAlertRef = useRef<HTMLDivElement>(null);

  const [isSamePlaceSelected, setIsSamePlaceSelected] = useState(false);
  const [isSameCoordinatesSelected, setIsSameCoordinatesSelected] =
    useState(false);
  const [routeData, setRouteData] = useState<IRouteData>();
  const [pickupPlaceCoordinates, setPickupPlaceCoordinates] =
    useState<ICoordinates>();
  const [dropoffPlaceCoordinates, setDropoffPlaceCoordinates] =
    useState<ICoordinates>();

  const { userData } = useUserContext();

  const findPlacesNameVariables: FindPlacesNamesQueryVariables | undefined =
    userData
      ? {
          where: {
            company: {
              reference_external_company_id: {
                equals: userData.company?.id,
              },
            },
          },
        }
      : undefined;

  const [findPlacesNamesResult] = useFindPlacesNamesQuery({
    variables: findPlacesNameVariables,
  });

  const [createRouteMutationResult, executeCreateRouteMutation] =
    useCreateRouteMutation();

  const placeNamesOptions = findPlacesNamesResult.data?.FindPlacesNames.map(
    ({ id, name }) => ({
      label: name,
      value: id,
    })
  );

  const formatUpdatePlaceFormToPlaceInput = (
    { id, ...formValues }: IUpdatePlaceForm,
    move_type: MoveTypeEnum
  ): CreatePlaceRouteInput => {
    const [opening_hour, closing_hour] = formValues.opening_hour;

    const balance_capacity = Formatter.convertTonBalanceCapacityToKilograms(
      parseLocaleNumber(formValues.balance_capacity),
      formValues.has_balance
    );

    const dirt_road_distance =
      Formatter.convertKilometersDirtRoadDistanceToMeters(
        parseLocaleNumber(formValues.dirt_road_distance),
        formValues.has_dirt_road
      );

    return {
      ...formValues,
      opening_hour: opening_hour.toISOString(),
      closing_hour: closing_hour.toISOString(),
      latitude: formValues.latitude,
      longitude: formValues.longitude,
      reference_place_id: id,
      state_name: UFNameEnum[formValues.state_id as UF],
      balance_capacity,
      dirt_road_distance,
      move_type,
    };
  };

  const handleSubmit = () => {
    if (isSamePlaceSelected || isSameCoordinatesSelected) {
      return placeValidationErrorAlertRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }

    const routeNameFormValidation = routeNameForm.validateFields();
    const pickupPlaceFormValidation = pickupPlaceForm.validateFields();
    const dropoffPlaceFormValidation = dropoffPlaceForm.validateFields();

    const company_id = userData?.company?.id;

    const onFinishFailed: FormProps['onFinishFailed'] = err => {
      pickupPlaceForm.scrollToField(err.errorFields[0].name);
    };

    Promise.all([
      routeNameFormValidation,
      pickupPlaceFormValidation,
      dropoffPlaceFormValidation,
    ])
      .then(() => {
        if (routeData && company_id) {
          const pickupPlace = formatUpdatePlaceFormToPlaceInput(
            pickupPlaceForm.getFieldsValue(),
            MoveTypeEnum.Withdrawal
          );

          const dropoffPlace = formatUpdatePlaceFormToPlaceInput(
            dropoffPlaceForm.getFieldsValue(),
            MoveTypeEnum.Delivery
          );

          executeCreateRouteMutation({
            input: {
              estimate_time: Math.round(routeData.estimate_time),
              total_distance: Math.round(routeData.total_distance),
              name: routeNameForm.getFieldValue('name').trim(),
              places: [pickupPlace, dropoffPlace],
              company_id,
            },
          }).then(({ data }) => {
            if (data?.CreateRoute) {
              navigate(-1);
            }
          });
        }
      })
      .catch(onFinishFailed);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleCreatePlace = () => {
    navigate('/shipper/places');
  };

  const CancelButton = ({ onClick }: { onClick?: () => void }) => (
    <Button
      danger
      disabled={createRouteMutationResult.fetching}
      size="small"
      onClick={onClick}
    >
      {t(`${CREATE_ROUTE_TRANSLATION_PATH}.actions.cancel`)}
    </Button>
  );

  const CreatePlaceButton = ({ onClick }: { onClick?: () => void }) => (
    <Button size="small" type="primary" onClick={onClick}>
      {t(`${CREATE_ROUTE_TRANSLATION_PATH}.actions.createPlace`)}
    </Button>
  );

  const isFormTouched = pickupPlaceCoordinates || dropoffPlaceCoordinates;

  const popConfirmCancelButton = isFormTouched ? (
    <Popconfirm
      title={t(`${CREATE_ROUTE_TRANSLATION_PATH}.confirm.cancel`)}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={handleCancel}
    >
      <CancelButton />
    </Popconfirm>
  ) : (
    <CancelButton onClick={handleCancel} />
  );

  const popConfirmCreatePlaceButton = isFormTouched ? (
    <Popconfirm
      title={t(`${CREATE_ROUTE_TRANSLATION_PATH}.confirm.cancel`)}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={handleCreatePlace}
      placement="bottomRight"
    >
      <CreatePlaceButton />
    </Popconfirm>
  ) : (
    <CreatePlaceButton onClick={handleCreatePlace} />
  );

  const colSpan = {
    xxl: 8,
    xl: 8,
    lg: 12,
    md: 24,
    sm: 24,
    xs: 24,
  };

  useEffect(() => {
    setIsSamePlaceSelected(false);
    setIsSameCoordinatesSelected(false);

    if (pickupPlaceCoordinates && dropoffPlaceCoordinates) {
      if (pickupPlaceCoordinates.placeId === dropoffPlaceCoordinates?.placeId) {
        setIsSamePlaceSelected(true);
      } else if (
        pickupPlaceCoordinates.latitude === dropoffPlaceCoordinates.latitude &&
        pickupPlaceCoordinates.longitude === dropoffPlaceCoordinates.longitude
      ) {
        setIsSameCoordinatesSelected(true);
      }
    }
  }, [pickupPlaceCoordinates, dropoffPlaceCoordinates]);

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 24]}>
        <Col span={24}>
          <Alert
            type="warning"
            message={t(`${CREATE_ROUTE_TRANSLATION_PATH}.alerts.createPlace`)}
            action={popConfirmCreatePlaceButton}
          />
        </Col>
        <ErrorAlert error={createRouteMutationResult.error} />
        {(isSamePlaceSelected || isSameCoordinatesSelected) && (
          <Col span={24} ref={placeValidationErrorAlertRef}>
            <Alert
              type="error"
              message={t(
                `${CREATE_ROUTE_TRANSLATION_PATH}.alerts.${
                  isSamePlaceSelected
                    ? 'samePlaceSelected'
                    : 'sameCoordinatesSelected'
                }`
              )}
            />
          </Col>
        )}
        <Col span={24}>
          <Card>
            <Form form={routeNameForm} layout="vertical" scrollToFirstError>
              <Form.Item
                label={t(
                  `${CREATE_ROUTE_TRANSLATION_PATH}.form.fields.labels.name`
                )}
                name="name"
                required
                rules={[{ required: true }]}
                normalize={Normalizer.removesMultiplesSpacesForOne}
                tooltip={t(
                  `${CREATE_ROUTE_TRANSLATION_PATH}.form.fields.tooltips.name`
                )}
              >
                <Input />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col {...colSpan}>
          <Card
            style={{ minHeight: '100%' }}
            title={
              <Typography.Text>
                {t(`${CREATE_ROUTE_TRANSLATION_PATH}.cards.pickupPlaceTitle`)}
              </Typography.Text>
            }
          >
            <RoutePlaceForm
              form={pickupPlaceForm}
              placesOptions={placeNamesOptions}
              loading={
                findPlacesNamesResult.fetching ||
                createRouteMutationResult.fetching
              }
              setPlaceCoordinates={setPickupPlaceCoordinates}
            />
          </Card>
        </Col>
        <Col {...colSpan}>
          <Card
            style={{ minHeight: '100%' }}
            title={
              <Typography.Text>
                {t(`${CREATE_ROUTE_TRANSLATION_PATH}.cards.dropoffPlaceTitle`)}
              </Typography.Text>
            }
          >
            <RoutePlaceForm
              form={dropoffPlaceForm}
              placesOptions={placeNamesOptions}
              loading={
                findPlacesNamesResult.fetching ||
                createRouteMutationResult.fetching
              }
              setPlaceCoordinates={setDropoffPlaceCoordinates}
            />
          </Card>
        </Col>
        <Col {...colSpan} lg={24}>
          <Card>
            <RouteMap
              pickupPlaceCoordinates={pickupPlaceCoordinates}
              dropoffPlaceCoordinates={dropoffPlaceCoordinates}
              setRouteData={setRouteData}
              style={{
                height: 800,
              }}
              showDescriptions={
                !!pickupPlaceCoordinates && !!dropoffPlaceCoordinates
              }
              interactionOptions={{ scrollZoom: true, interactive: true }}
            />
          </Card>
        </Col>
      </FixedFooterContainer.Inner>

      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            {popConfirmCancelButton}
            <Button
              size="small"
              type="primary"
              onClick={handleSubmit}
              disabled={!routeData}
              loading={createRouteMutationResult.fetching}
            >
              {t(`${CREATE_ROUTE_TRANSLATION_PATH}.actions.submit`)}
            </Button>
          </Space>
        }
      />
    </>
  );
};
