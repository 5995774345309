import { Select, SelectProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleTruckerUserEnum } from '../../../../../../../graphql/generated/graphql';
import { useAuthContext } from '../../../../../../../contexts/AuthContext/useAuthContext';

export type ITruckerUserRoleSelectProps = Omit<
  SelectProps,
  'options' | 'children'
>;

export const TruckerUserRoleSelect = (
  props: ITruckerUserRoleSelectProps
): ReactElement => {
  const { t } = useTranslation();

  const { roles } = useAuthContext();

  const isAdm = roles?.find(role => role === 'ADMINISTRATOR');

  const options: SelectProps['options'] = Object.values(
    RoleTruckerUserEnum
  ).map(key => ({
    label: t(`enums.truckerUserRoleEnum.${key}`),
    value: key,
    disabled: key === RoleTruckerUserEnum.Adm && !isAdm,
  }));

  return (
    <Select options={options} placeholder={t('general.select')} {...props} />
  );
};
