import { Form, FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Key, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Formatter from '../../../../classes/Formatter';
import { useCreateFreightContext } from '../../../../contexts/Freight/CreateFreightContext/useCreateFreightContext';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  ListRoutesQueryVariables,
  MoveTypeEnum,
  UnitTypeEnum,
  useListRoutesQuery,
} from '../../../../graphql/generated/graphql';
import { scrollToFirstError } from '../../../../utils';
import { ISetState } from '../../../../utils/interfaces';
import { usePackageSelect } from '../../../Product/Package/PackageSelect/FindPackages.hooks';
import { IFindRoute } from '../../Route/ListRoutes/ListRoutes';
import {
  getUniquePlaces,
  slugifyRoutes,
} from '../../Route/ListRoutes/ListRoutesUtils';
import { IRouteFilterForm } from '../../Route/RoutesFilter/RoutesFilter';
import { ICreateFreightFormValues } from './CreateFreightForm';

export const useFreightRouteForm = () => {
  const { userData } = useUserContext();

  const navigate = useNavigate();

  const [selectedRouteId, setSelectedRouteId] = useState<string | null>();
  const [timeSpentInRoute, setTimeSpentInRoute] = useState<number>(0);

  const [routeFilterValues, setRouteFilterValues] =
    useState<IRouteFilterForm>();
  const [routesWithSlug, setRoutesWithSlug] = useState<IFindRoute[]>();

  const findRoutesVariables: ListRoutesQueryVariables | undefined =
    userData?.company
      ? {
          where: {
            company: {
              reference_external_company_id: {
                equals: userData.company.id,
              },
            },
          },
        }
      : undefined;

  const [findRoutesResult] = useListRoutesQuery({
    variables: findRoutesVariables,
    pause: !findRoutesVariables,
  });

  const pickupPlaces = getUniquePlaces({
    moveType: MoveTypeEnum.Withdrawal,
    routes: routesWithSlug,
  });

  const dropoffPlaces = getUniquePlaces({
    moveType: MoveTypeEnum.Delivery,
    routes: routesWithSlug,
  });

  const filteredData = useMemo(() => {
    if (routeFilterValues && routesWithSlug) {
      const { pickupCityId, dropoffCityId } = routeFilterValues;

      const search = Formatter.removeAccents(
        routeFilterValues.search || ''
      ).toLowerCase();

      return routesWithSlug.filter(route => {
        const withdrawalCity = route.places?.find(
          place => place.move_type === MoveTypeEnum.Withdrawal
        );

        const dropoffCity = route.places?.find(
          place => place.move_type === MoveTypeEnum.Delivery
        );

        if (!pickupCityId || withdrawalCity?.city_id === pickupCityId) {
          if (!dropoffCityId || dropoffCity?.city_id === dropoffCityId) {
            if (!search || route.slug?.includes(search)) {
              return true;
            }
          }
        }
      });
    }

    return routesWithSlug;
  }, [routesWithSlug, routeFilterValues]);

  useEffect(() => {
    const { data, fetching, error } = findRoutesResult;

    if (error) {
      setRoutesWithSlug([]);
    } else if (data && !fetching) {
      setRoutesWithSlug(slugifyRoutes(data.FindRoutes));
    }
  }, [findRoutesResult]);

  const handleRouteSelect = (routeId: string) => {
    return setSelectedRouteId(routeId);
  };

  const handleViewRoute = (routeId: string) => {
    navigate(`/shipper/routes/${routeId}`);
  };

  useEffect(() => {
    setTimeSpentInRoute(() => {
      const routes = findRoutesResult.data?.FindRoutes || [];
      const route = routes?.find(route => route.id === selectedRouteId);
      return route ? route.estimate_time : 0;
    });
  }, [findRoutesResult.data?.FindRoutes, selectedRouteId]);

  return {
    selectedRouteId,
    setSelectedRouteId,
    timeSpentInRoute,
    pickupPlaces,
    dropoffPlaces,
    filteredData,
    handlers: {
      handleRouteSelect,
      handleViewRoute,
      handleFilterRoutes: setRouteFilterValues,
    },
    findRoutesResult,
  };
};

export interface IUseCreateFreightFormProps {
  form: FormInstance<ICreateFreightFormValues>;
  setSelectedRouteId: ISetState<string | null | undefined>;
  selectedRouteId: string | null | undefined;
  relatedGroupKeys: Key[];
  freightId?: string;
}

export const useCreateFreightForm = ({
  form,
  setSelectedRouteId,
  selectedRouteId,
  relatedGroupKeys,
  freightId,
}: IUseCreateFreightFormProps) => {
  const { createFreightValues, setCreateFreightValues } =
    useCreateFreightContext();

  const navigate = useNavigate();

  const selectedProductType = Form.useWatch<DefaultOptionType | undefined>(
    'product_type_id',
    form
  );

  const selectedProduct = Form.useWatch<DefaultOptionType | undefined>(
    'product_id',
    form
  );

  const selectedPackage = Form.useWatch<DefaultOptionType | undefined>(
    'packing_id',
    form
  );

  const selectedProductTypeId = selectedProductType?.value
    ? String(selectedProductType.value)
    : undefined;

  const {
    options: packageOptions,
    findPackagesResult: { fetching, data },
  } = usePackageSelect({
    productTypeId: selectedProductTypeId,
  });

  const handleCreateRoute = () => {
    setCreateFreightValues({
      ...form.getFieldsValue(),
      related_groups: relatedGroupKeys,
    });

    navigate('/shipper/routes/create');
  };

  const handleCancel = () => {
    setSelectedRouteId(null);
    setCreateFreightValues(undefined);
    return navigate(-1);
  };

  const handleSubmit = () => {
    if (!selectedRouteId) {
      setSelectedRouteId(null);
      return form.validateFields();
    }

    form
      .validateFields()
      .then(formValues => {
        setCreateFreightValues({
          ...formValues,
          route_id: selectedRouteId,
          related_groups: relatedGroupKeys,
          isValid: true,
        });

        if (freightId) {
          return navigate(`/shipper/freights/confirm/${freightId}`);
        }

        return navigate('/shipper/freights/confirm');
      })
      .catch(() => {
        scrollToFirstError(form);
      });
  };

  const isFormTouched =
    form.isFieldsTouched() || !!selectedRouteId || !!relatedGroupKeys.length;

  useEffect(() => {
    if (form.isFieldTouched('product_type_id')) {
      form.resetFields(['product_id', 'packing_id', 'product_variety_id']);
      form.setFieldsValue({
        product_id: undefined,
        packing_id: undefined,
        product_variety_id: undefined,
      });
    }
  }, [form, selectedProductType]);

  useEffect(() => {
    if (form.isFieldTouched('product_id')) {
      form.resetFields(['product_variety_id']);
      form.setFieldsValue({ product_variety_id: undefined });
    }
  }, [form, selectedProduct]);

  useEffect(() => {
    const selectedUnitType = data?.FindManyPackings.data.find(
      ({ id }) => id === selectedPackage?.value
    );

    const unitType =
      selectedUnitType?.measuring_unity === UnitTypeEnum.Kg
        ? UnitTypeEnum.Ton
        : selectedUnitType?.measuring_unity;

    const inputUnitType: string | undefined = form.getFieldValue('unit_type');

    form.setFieldValue('unit_type', unitType);

    if (unitType !== inputUnitType) {
      form.resetFields(['total_volume']);
    }
  }, [data, form, selectedPackage]);

  useEffect(() => {
    if (createFreightValues?.isValid && createFreightValues.route_id) {
      setSelectedRouteId(createFreightValues.route_id);
    }
  }, [createFreightValues, setSelectedRouteId]);

  return {
    packages: {
      options: packageOptions,
      fetching,
    },
    handlers: {
      handleCreateRoute,
      handleCancel,
      handleSubmit,
    },
    isFormTouched,
  };
};
