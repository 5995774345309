/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import { PaginationProps } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';

interface IUsePaginationInput {
  currentPageQueryParam?: string;
  pageSizeQueryParam?: string;

  currentPageDefaultValue?: number;
  pageSizeDefaultValue?: number;

  maxPageSize?: number;
  initialDataLength?: number;
}

export interface IPaginationRequestParams {
  skip: number;
  take: number;
}

export type IPagination = Required<
  Pick<PaginationProps, 'current' | 'pageSize'>
> &
  Omit<PaginationProps, 'current' | 'pageSize'> & { total: number };

type IUsePagination = [
  pagination: IPagination,
  paginationRequestParams: IPaginationRequestParams | undefined,
  setDataLength: React.Dispatch<React.SetStateAction<number>>
];

/**
 * @description Hook to control pagination, set URL query params and get request params
 * @property {string | undefined} [currentPageQueryParam = 'page'] URL query param to get and set current page
 * @property {string | undefined} [pageSizeQueryParam = 'size'] URL query param to get and set page size
 * @property {number | undefined} [currentPageDefaultValue = 1] Default value for current page
 * @property {number | undefined} [pageSizeDefaultValue = 20] Default value for page size
 * @property {number | undefined} [maxPageSize = 100] Max page size
 * @example
 * const [tableData, setTableData] = useState<IData[] | undefined>();
 *
 * const [pagination, paginationRequestParams, setDataLength] = usePagination();
 *
 * const [findAllRequest, isFindAllRequesting] = useRequest(
 *   controller.FindAll
 * );
 *
 * const [countAllRequest, isCountAllRequesting] = useRequest(
 *   controller.CountAll
 * );
 *
 * useEffect(() => {
 *   if (paginationRequestParams) {
 *     findAllRequest(paginationRequestParams)
 *       .then(data => setTableData(data))
 *       .catch(({ message }) => notification.error({ message }));
 *   }
 * }, [paginationRequestParams]);
 *
 * useEffect(() => {
 *   countAllRequest({}).then(length => setDataLength(length));
 * }, []);
 *
 * const isDataLoading =
 *   tableData === undefined || isFindAllRequesting || isCountAllRequesting;
 *
 * return (
 *   <Table
 *     columns={columns}
 *     dataSource={tableData}
 *     loading={isDataLoading}
 *     pagination={pagination}
 *   />
 * );
 */

export const usePagination = ({
  currentPageQueryParam = 'page',
  pageSizeQueryParam = 'size',
  currentPageDefaultValue = 1,
  pageSizeDefaultValue = 20,
  maxPageSize = 100,
  initialDataLength = 0,
}: IUsePaginationInput = {}): IUsePagination => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const hasMounted = useRef(false);

  const getIntQueryParam = (key: string, fallbackValue: number) => {
    const value = searchParams.get(key);
    const onlyNumbers = /^[\d]*$/;

    if (value && onlyNumbers.test(value)) {
      return parseInt(value);
    }

    return fallbackValue;
  };

  const validateSearchParams = ({
    currentPage,
    pageSize,
  }: {
    currentPage: number;
    pageSize: number;
  }) => {
    const validSearchParams = { currentPage, pageSize };

    if (pageSize <= 0) {
      validSearchParams.pageSize = pageSizeDefaultValue;
    } else if (pageSize > maxPageSize) {
      validSearchParams.pageSize = maxPageSize;
    }
    if (currentPage <= 0) {
      validSearchParams.currentPage = currentPageDefaultValue;
    }

    return validSearchParams;
  };

  const [validSearchParams, setValidSearchParams] = useState<{
    currentPage: number;
    pageSize: number;
  }>(() =>
    validateSearchParams({
      currentPage: getIntQueryParam(
        currentPageQueryParam,
        currentPageDefaultValue
      ),
      pageSize: getIntQueryParam(pageSizeQueryParam, pageSizeDefaultValue),
    })
  );

  const [paginationRequestParams, setPaginationRequestParams] =
    useState<IPaginationRequestParams>();
  const [dataLength, setDataLength] = useState(initialDataLength);

  useEffect(() => {
    const { currentPage, pageSize } = validSearchParams;

    const replaceOnFirstMount = !hasMounted.current;

    setSearchParams(
      () => ({
        [pageSizeQueryParam]: pageSize.toString(),
        [currentPageQueryParam]: currentPage.toString(),
      }),
      { replace: replaceOnFirstMount, state: location.state }
    );

    setPaginationRequestParams({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
    });

    if (!hasMounted.current) hasMounted.current = true;

    return () => {
      hasMounted.current = false;
    };
  }, [validSearchParams]);

  const handleChange = (currentPage: number, pageSize: number) => {
    setValidSearchParams(validateSearchParams({ currentPage, pageSize }));
  };

  useEffect(() => {
    if (dataLength > 0) {
      const lastPage = Math.ceil(dataLength / validSearchParams.pageSize);

      if (validSearchParams.currentPage > lastPage) {
        handleChange(lastPage, validSearchParams.pageSize);
      }
    }
  }, [dataLength]);

  return [
    {
      current: validSearchParams.currentPage,
      pageSize: validSearchParams.pageSize,
      onChange: handleChange,
      total: dataLength,
      hideOnSinglePage: true,
    },
    paginationRequestParams,
    setDataLength,
  ];
};
