import { RuleObject } from 'antd/es/form';
import { useTranslation } from 'react-i18next';

export interface IUseUploadFileValidatorProps {
  accept?: string | null;
  maxFileSizeMB?: number | null;
}

const defaultAccept =
  'image/png,image/jpeg,image/jpg,image/webp,application/pdf';

const defaultMaxFileSizeMB = 5;

export const useUploadFileValidator = (
  {
    accept = defaultAccept,
    maxFileSizeMB = defaultMaxFileSizeMB,
  }: IUseUploadFileValidatorProps = {
    accept: defaultAccept,
    maxFileSizeMB: defaultMaxFileSizeMB,
  }
) => {
  const { t } = useTranslation('translations', { keyPrefix: 'upload.rules' });

  const validator = (_: RuleObject, value: File[]) => {
    const file = value.pop();

    const acceptedTypes = accept?.split(',');

    if (!file) {
      return Promise.reject(t('fileNotFound'));
    }

    if (acceptedTypes && !acceptedTypes.includes(file.type)) {
      return Promise.reject(
        t('acceptedFileTypes', {
          accept: acceptedTypes.join(', ').replace(/[\w]*\//g, '.'),
        })
      );
    }

    if (maxFileSizeMB !== null) {
      const maxFileSize = maxFileSizeMB * 1000 * 1000;

      if (maxFileSize && file.size > maxFileSize) {
        return Promise.reject(t('maximumFileSize', { maxFileSizeMB }));
      }
    }

    value.push(file);
    return Promise.resolve(file);
  };

  return {
    validator,
    defaultValues: {
      accept: defaultAccept,
      maxFileSizeMB: defaultMaxFileSizeMB,
    },
  };
};
