interface ILanguage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const br: ILanguage = {
  translations: {
    tab: {
      authentication: 'Autenticação',
      signUp: 'Cadastro',
      resumeSignUp: 'Cadastro',
      home: 'Home',
      graphs: 'Gráficos',
      icons: 'Ícones',
      validateEmail: 'Verificando Email...',
      registerCompany: 'Cadastrar Empresa',
      createPassword: 'Criar Senha',
      termsOfUse: 'Termos de Uso',
      companyCreated: 'Empresa Registrada',
      accountInApproval: 'Conta em aprovação',
      accountReproved: 'Conta reprovada',
      accountBlocked: 'Conta reprovada permanentemente',
      regularizeCompany: 'Regularizar empresa',
      accountCreated: 'Conta criada',
      companies: {
        companies: 'Empresas',
        companyValidation: 'Validação de empresas',
      },
      carrier: {
        freight: {
          index: 'Cotações',
        },
      },
      shipper: {
        freight: {
          index: 'Fretes',
        },
        quotationGroups: {
          index: 'Grupos de cotação',
        },
      },
      freight: {
        index: 'Meus fretes',
        places: 'Locais',
        routes: 'Rotas',
      },
      users: {
        userDetails: 'Meus dados',
      },
      product: {
        index: 'Produtos',
        products: 'Produtos',
        productTypes: 'Tipos de produto',
      },
      support: {
        accounts: {
          index: 'Cadastros',
          companies: {
            index: 'Empresas',
            companyValidation: 'Validação de empresas',
            companyDetailsPage: 'Detalhes da empresa',
          },
          users: {
            index: 'Usuários',
            userDetailsPage: 'Detalhes do usuário',
          },
        },
      },
    },
    languages: {
      ptBR: 'Português',
      enUS: 'English',
      es: 'Español',
    },
    headerMenu: {
      termsOfUse: 'Termos de uso e privacidade',
      logOut: 'Sair',
    },
    layout: {
      main: {
        header: {
          supportData: {
            seeingCompanyData: 'Visualizando dados de {{companyName}}',
          },
        },
      },
    },
    general: {
      logoAlt: 'Logo da Trucker do AgrO',
      yes: 'Sim',
      no: 'Não',
      close: 'Fechar',
      back: 'Voltar',
      save: 'Salvar',
      none: 'Não possui',
      unknown: 'Desconhecido',
      select: 'Selecione',
      remove: 'Remover',
      removePopconfirm: 'Deseja realmente remover esse item?',
      dataNotFound: 'Dados não encontrados',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      edit: 'Editar',
      actions: 'Ações',
      view: 'Visualizar',
      delete: 'Excluir',
      deletePopconfirm: 'Deseja realmente excluir esse item?',
      warning: 'Atenção!',
      notFoundMessage: 'Não foi possível obter os dados',
      finish: 'Finalizar',
      clear: 'Limpar campos',
      filter: 'Filtrar',
      clearFilter: 'Limpar filtros',
    },
    input: {
      rules: {
        required: 'Campo obrigatório',
        invalidEmail: 'Email inválido',
        minPassword: 'Mínimo 6 caracteres',
        invalidDocument: 'CPF/CNPJ inválido',
        invalidDocumentCPF: 'CPF inválido',
        invalidDocumentCNPJ: 'CNPJ inválido',
        invalidPhoneNumber: 'Telefone inválido',
        invalidZipCode: 'CEP inválido',
      },
    },
    upload: {
      rules: {
        fileNotFound: 'Arquivo não encontrado',
        maximumFileSize: 'O arquivo deve ter no máximo {{maxFileSizeMB}} MB',
        acceptedFileTypes:
          'Somente arquivos com formato {{accept}} são aceitos',
      },
    },
    pages: {
      loading: {
        text: 'Carregando',
      },
      login: {
        title: 'Login',
        subtitle: 'Informe seus dados para entrar na sua conta.',
        fields: {
          email: {
            label: 'E-mail',
            placeholder: 'Seu E-mail',
          },
          password: {
            label: 'Senha',
            placeholder: 'Sua senha',
          },
          createAccount: {
            text: 'Não tem conta?',
            link: 'criar conta agora',
          },
          keepConnected: 'Mantenha-me conectado',
          passwordRecovery: 'Recuperar senha',
          loginButton: 'Entrar',
        },
        footer: {
          text: 'Não possui uma conta? ',
          signup: 'Cadastre-se!',
        },
        success: {
          message: 'Login efetuado!',
        },
      },
      recoveryPassword: {
        title: 'Recuperar senha',
        subtitle: 'Informe seu e-mail para recuperar a senha.',
        recoveryButton: 'Enviar recuperação',
        fields: {
          email: {
            label: 'E-mail',
            placeholder: 'Seu E-mail',
          },
        },
        success: {
          message: 'Recuperação de senha iniciada',
          description:
            'Os passos para a recuperação de senha foram enviados ao seu e-mail, se estiver cadastrado!',
        },
        createPassword: {
          title: 'Criar nova senha',
          subtitle: 'Insira sua nova senha nos campos abaixo',
          confirmButton: 'Confirmar',
          fields: {
            labels: {
              password: 'Senha',
              confirmPassword: 'Confirmar senha',
            },
            placeholders: {
              password: 'Informe uma senha válida',
              confirmPassword: 'Confirme sua senha',
            },
            rules: {
              passwordNotMatch: 'As senhas não são iguais',
              passwordMinLength: 'A senha deve ter no mínimo 6 caracteres',
              passwordMaxLength: 'A senha deve ter no máximo 128 caracteres',
            },
          },
          success: {
            message: 'Senha alterada com sucesso!',
            description: 'Você já pode acessar sua conta usando a nova senha',
          },
        },
      },
      activateUser: {
        notifications: {
          success: {
            message: 'Usuário ativado com sucesso!',
            description:
              'Você pode acessar sua conta utilizando a senha cadastrada',
          },
        },
      },
      signUp: {
        title: 'Criando sua conta',
        subtitle: 'Informe os dados abaixo para se cadastrar no sistema',
        fields: {
          labels: {
            name: 'Nome',
            email: 'E-mail',
            phone: 'Telefone',
            companyFunction: 'Segmento da sua empresa',
          },
          placeholders: {
            name: 'Seu nome',
            email: 'Seu e-mail',
            phone: 'Seu número de telefone',
            companyFunction: 'Segmento da sua empresa',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Criar conta',
          return: 'Voltar',
        },
        terms:
          'Ao clicar em <0>criar conta</0> você concorda com os <1>termos de uso e privacidade</1>',
        confirm: {
          cancel: 'Você realmente deseja cancelar o cadastro?',
        },
        messages: {
          accountCreated: {
            title: 'Conta criada!',
            description:
              'Foi enviado para o e‑mail informando um link para validação da conta. Complete seu cadastro a partir do link do email.',
            buttonText: 'Voltar para tela de login',
          },
          shipperCreated: {
            title: 'Embarcador cadastrado!',
            description:
              'Estamos validando seus dados. Aguarde o retorno no seu email.',
            buttonText: 'Voltar para tela de login',
          },
          carrierCreated: {
            title: 'Transportador cadastrado!',
            description:
              'Estamos validando seus dados. Aguarde o retorno no seu email.',
            buttonText: 'Voltar para tela de login',
          },
          accountInApproval: {
            title: 'Conta em aprovação',
            description: [
              'Sua conta está aguardando aprovação.',
              'Em breve você recebera um email com novidades.',
            ],
            buttonText: 'Voltar para tela de login',
          },
          accountBlocked: {
            title: 'Conta reprovada permanentemente',
            explanation: {
              reasons: 'Motivos',
              description: 'Descrição',
            },
            buttonText: 'Voltar para tela de login',
          },
          accountReproved: {
            title: 'Conta reprovada',
            explanation: {
              reasons: 'Motivos',
              description: 'Descrição',
            },
            buttonText: 'Regularizar cadastro',
          },
          completeRegistration: {
            title: 'Completar cadastro',
            description:
              'Precisamos de mais algumas informações da sua empresa para ela estar ativa.',
            buttonText: 'Completar cadastro',
          },
        },
      },
      resumeSignUpCard: {
        title: 'Continue seu cadastro',
        subtitle:
          'Informe o segmento da sua empresa para continuar com seu cadastro',
        fields: {
          labels: {
            name: 'Nome',
            email: 'E-mail',
            companyFunction: 'Segmento da sua empresa',
          },
          placeholders: {
            companyFunction: 'Segmento da sua empresa',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Continuar',
          return: 'Voltar',
        },
        confirm: {
          cancel: 'Você realmente deseja cancelar o cadastro?',
        },
      },
      home: {
        title: 'Bem vindo a Home!',
      },
      graphs: {
        title: 'Página dos Gráficos',
      },
      icons: {
        title: 'Página dos Ícones',
      },
      registerCompany: {
        register: {
          shipperTitle: 'Cadastrar Embarcador',
          carrierTitle: 'Cadastrar Transportadora',
          subtitle: 'Escolha qual o seguimento da sua empresa',
          action: {
            cancel: 'Cancelar',
            submit: 'Criar conta',
          },
          confirm: {
            cancel: 'Você realmente deseja cancelar o cadastro?',
          },
        },
        regularize: {
          shipperTitle: 'Regularizar Embarcador',
          carrierTitle: 'Regularizar Transportadora',
          subtitle: 'Atualize os dados para regularizar sua empresa',
          action: {
            cancel: 'Cancelar',
            submit: 'Atualizar dados',
          },
          confirm: {
            cancel: 'Você realmente deseja cancelar a regularização?',
          },
        },
        sections: {
          companyData: {
            title: 'Dados da empresa',
            fields: {
              labels: {
                companyType: 'Tipo de empresa',
                hasFleet: 'Possui frota própria?',
                fleetQuantity: 'Tamanho da frota',
                logoTitle: 'Logo da empresa',
                logo: 'Clique ou arraste o arquivo para fazer upload',
                logoDescription:
                  'O arquivo deve possuir no máximo 5 MB e deve ser do tipo .png, .jpg ou .jpeg',
              },
              placeholders: {
                companyType: 'Tipo da sua empresa',
                fleetQuantity: 'Informe a quantidade de caminhões',
              },
              rules: {
                companyFunctionNotMatch:
                  'Empresa cadastrada para outro segmento',
                companyTypeNotMatch:
                  'O seu tipo de empresa é incompatível com esta',
                companyStatusBlocked:
                  'Empresa indisponível para vincular novos dependentes',
                tradeNameLength:
                  'O nome fantasia deve ter menos de 100 caracteres',
                companyNameLength:
                  'A razão social deve ter menos de 100 caracteres',
                minimumFleetQuantity:
                  'O tamanho mínimo da frota é de 1 caminhão',
                maximumFileSize: 'O arquivo deve ter no máximo 5 MB',
                acceptedFileTypes:
                  'Somente arquivos .jpg, .jpeg e .png são aceitos',
              },
            },
            Parent: {
              fields: {
                labels: {
                  companyDocument: 'CNPJ ou CPF',
                  companyTradeName: 'Nome fantasia',
                  companyName: 'Razão social',
                },
                placeholders: {
                  companyDocument: 'Seu CNPJ ou CPF',
                  companyTradeName: 'Nome fantasia da sua empresa',
                  companyName: 'Razão social da sua empresa',
                },
                rules: {
                  invalidCompanyDocument: 'CNPJ ou CPF em uso',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Empresa pré-cadastrada',
                  description:
                    'Alguns dados já foram preenchidos por alguma filial. Por favor, preencha os dados restantes para concluir o cadastro',
                },
              },
            },
            Branch: {
              fields: {
                labels: {
                  motherCompanyDocument: 'CNPJ da matriz',
                  motherCompanyName: 'Nome da matriz:',
                  companyDocument: 'CNPJ da filial',
                  companyTradeName: 'Nome fantasia',
                  companyName: 'Razão social',
                },
                placeholders: {
                  motherCompanyDocument: 'Informe o CNPJ da matriz',
                  motherCompanyName: 'Informe o nome da matriz',
                  companyDocument: 'Seu CNPJ',
                  companyTradeName: 'Nome fantasia da sua empresa',
                  companyName: 'Razão social da sua empresa',
                },
                rules: {
                  invalidCompanyDocument: 'CNPJ em uso',
                  sameDocumentNumber:
                    'O seu CNPJ não pode ser igual ao da Matriz',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Empresa pré-cadastrada',
                  description:
                    'Alguns dados já foram preenchidos por alguma unidade. Por favor, preencha os dados restantes para concluir o cadastro',
                },
              },
            },
            Unit: {
              fields: {
                labels: {
                  motherCompanyDocument: 'CNPJ da filial ou da matriz',
                  motherCompanyName: 'Nome da filial ou matriz:',
                  companyDocument: 'CNPJ da unidade',
                  companyTradeName: 'Nome fantasia',
                  companyName: 'Razão social',
                },
                placeholders: {
                  motherCompanyDocument:
                    'Informe o CNPJ da filial ou da matriz',
                  motherCompanyName: 'Informe o nome da filial ou da matriz',
                  companyDocument: 'Seu CNPJ',
                  companyTradeName: 'Nome fantasia da sua empresa',
                  companyName: 'Razão social da sua empresa',
                },
                rules: {
                  invalidCompanyDocument: 'CNPJ em uso',
                },
              },
            },
            modal: {
              title: {
                details: 'Dados da Matriz',
                createParent: 'Cadastrar Matriz',
                createBranchOrUnit: 'Cadastrar Matriz ou Filial',
              },
              fields: {
                labels: {
                  motherCompanyType: 'Tipo de empresa',
                  motherCompanyDocument: 'CNPJ',
                  motherCompanyTradeName: 'Nome fantasia',
                  motherCompanyName: 'Razão social',
                },
                placeholders: {
                  motherCompanyTradeName: 'Nome fantasia da empresa',
                  motherCompanyName: 'Razão social da empresa',
                },
                rules: {
                  tradeNameLength:
                    'O nome fantasia deve ter menos de 100 caracteres',
                  companyNameLength:
                    'A razão social deve ter menos de 100 caracteres',
                },
              },
            },
          },
          companyAddress: {
            title: 'Endereço da empresa',
            fields: {
              labels: {
                zipCode: 'CEP',
                state: 'UF',
                city: 'Cidade',
                address: 'Logradouro',
                district: 'Bairro',
                addressNumber: 'Número',
                complement: 'Complemento/Observação',
              },
              placeholders: {
                zipCode: 'Seu CEP',
                state: 'UF',
                city: 'Cidade onde fica a empresa',
                address: 'Logradouro onde fica a empresa',
                district: 'Bairro onde fica a empresa',
              },
              rules: {
                addressMaxLength:
                  'O logradouro deve ter menos de 100 caracteres',
                districtMaxLength: 'O bairro deve ter menos de 100 caracteres',
                numberMaxLength: 'O número deve ter menos de 10 caracteres',
                complementMaxLength:
                  'O complemento deve ter menos de 100 caracteres',
              },
            },
          },
          companyContact: {
            title: 'Contato da empresa',
            fields: {
              labels: {
                email: 'E-mail',
                phoneNumber: 'Telefone',
              },
              placeholders: {
                email: 'E-mail da empresa',
                phoneNumber: 'Telefone da empresa',
              },
            },
          },
          carrierPolicies: {
            title: 'Apólices',
            fields: {
              labels: {
                expiresIn: 'Validade',
                name: 'Nome',
                logo: 'Clique ou arraste o arquivo para fazer upload',
                logoDescription:
                  'O arquivo deve possuir no máximo 5 MB e deve ser do tipo .png, .jpg, .jpeg ou .pdf',
              },
              placeholders: {
                name: 'Ex. apólice de seguro',
                expiresIn: 'dd/mm/aa',
              },
              rules: {
                maximumFileSize: 'O arquivo deve ter no máximo 5 MB',
                acceptedFileTypes:
                  'Somente arquivos .jpg, .jpeg, .png e .pdf são aceitos',
                nameLength:
                  'O nome do arquivo deve ter menos de 100 caracteres',
                maximumFileAmount: {
                  message: 'Limite de arquivos atingido',
                  description: 'O número máximo de arquivos é de 10',
                },
              },
            },
            fileList: {
              title: 'Arquivos das apólices',
              tooltips: {
                remove: 'Remover',
                preview: 'Visualizar',
                retry: 'Reenviar',
              },
            },
            action: {
              addPolicy: 'Adicionar apólice',
            },
          },
          carrierCompanyOperationAreas: {
            title: 'Área de atuação',
          },
        },
      },
      createPassword: {
        title: 'Segurança',
        description: 'Cadastre e confirme uma senha segura para sua conta',
        fields: {
          labels: {
            password: 'Senha',
            confirmPassword: 'Confirmar senha',
          },
          placeholders: {
            password: 'Informe uma senha válida',
            confirmPassword: 'Confirme sua senha',
          },
          rules: {
            passwordNotMatch: 'As senhas não são iguais',
            passwordMinLength: 'A senha deve ter no mínimo 6 caracteres',
            passwordMaxLength: 'A senha deve ter no máximo 128 caracteres',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Confirmar',
        },
        confirm: {
          cancel: 'Você realmente deseja cancelar o cadastro?',
        },
        notification: {
          invalidToken: {
            message: 'Erro ao verificar email...',
            description: 'O link que você acessou está expirado ou é inválido',
          },
        },
      },
      termsOfUse: {
        subtitle: 'Leia e analise os termos de uso',
        title: 'Termos de uso e privacidade',
        fields: {
          labels: {
            acceptTerms: 'Eu li e concordo com os termos de uso',
          },
        },
        action: {
          cancel: 'Não aceito',
          submit: 'Concordar e continuar',
          return: 'Voltar',
        },
        confirm: {
          cancel: 'Você realmente deseja recusar os termos?',
        },
        pdfNotSupported:
          'Não foi possível carregar o PDF. <0>Clique aqui</0> para abrir os Termos de uso e privacidade em uma nova aba.',
      },
      freight: {
        route: {
          title: 'Rotas',
          createRoutePage: {
            title: 'Nova rota',
            subTitle: 'Crie uma nova rota para os seus fretes',
          },
          viewRoutePage: {
            title: 'Detalhes da rota',
            subTitle: 'Visualize os detalhes da rota',
          },
          routesPage: {
            title: 'Rotas',
            subTitle: 'Crie e gerencie suas rotas',
          },
        },
        freightDetailsPage: {
          title: 'Visualizar detalhes do frete',
          breadcrumb: 'Visualizar',
        },
        freights: {
          createFreightPage: {
            title: 'Nova cotação',
            subTitle: 'Cria uma nova cotação para um frete',
          },
          confirmFreightPage: {
            title: 'Confirmar dados da cotação',
          },
        },
        quotations: {
          createQuotationPage: {
            title: 'Visualizar detalhes do frete',
            remainingTime: 'Tempo restante',
          },
        },
      },
      quotations: {
        quotationDetailsPage: {
          title: 'Visualizar detalhes do frete',
        },
      },
      users: {
        userDetailsPage: {
          title: 'Meus dados',
          subTitle: 'Visualize os dados de usuário e permissões',
        },
      },
      product: {
        productsPage: {
          title: 'Produtos',
          subTitle: 'Crie e gerencie os seus produtos',
        },
        productTypesPage: {
          title: 'Tipos de produto',
          subTitle: 'Crie e gerencie os tipos de produtos',
        },
      },
      shipper: {
        quotationGroupsPage: {
          components: {
            quotationGroups: {
              title: 'Grupos cadastrados',
              actions: {
                create: 'Novo grupo',
              },
              hooks: {
                useDeleteQuotationGroup: {
                  deleteGroupSuccess: 'Grupo excluído com sucesso!',
                },
              },
              tooltip: 'É possível ter até 10 grupos de cotações cadastrados',
            },
            quotationGroupHeader: {
              title: 'Grupos de cotação',
              subTitle: 'Gerencie os grupos de cotação',
            },
            quotationGroupFormModal: {
              modal: {
                create: {
                  title: 'Criar grupo de cotação',
                  okText: 'Criar',
                  onSuccessMessage: 'Grupo criado com sucesso',
                },
                update: {
                  title: 'Editar grupo de cotação',
                  okText: 'Salvar',
                  onSuccessMessage: 'Grupo editado com sucesso',
                  popConfirm:
                    'As alterações não foram salvas, deseja fechar mesmo assim?',
                },
                read: {
                  title: 'Detalhes do grupo de cotação',
                },
              },
              form: {
                placeholders: {
                  name: 'Ex: Grupo de favoritos',
                },
                tooltips: {
                  members:
                    'É possível procurar transportadoras por nome ou CNPJ. Um grupo de cotação deve possuir no mínimo duas e no máximo 20 transportadoras.',
                },
                rules: {
                  minGroupMembers:
                    'Um grupo deve possuir no mínimo 2 transportadoras',
                  maxGroupMembers:
                    'Um grupo deve possuir no máximo 20 transportadoras',
                },
              },
            },
            quotationGroupMembersTable: {
              actions: {
                addMember: 'Adicionar ao grupo',
                removeSelected: {
                  popconfirm:
                    'Deseja remover desse grupo as transportadoras selecionadas?',
                  button: 'Remover selecionados',
                },
              },
            },
          },
        },
      },
      support: {
        account: {
          company: {
            components: {
              companiesPageFilter: {
                labels: {
                  documentNumber: 'Número do documento',
                  companyName: 'Empresa',
                  companyType: 'Tipo',
                  companyFunction: 'Função',
                  createdAt: 'Criado entre',
                  updatedAt: 'Atualizado entre',
                },
              },
            },
            companiesPage: {
              title: 'Empresas',
              subTitle: 'Visualize e gerencie as empresas',
            },
            companyDetailsPage: {
              title: 'Detalhes da empresa',
              approveCompanyModalTitle: 'Aprovar {{name}}?',
              disapproveCompanyModalTitle: 'Reprovar {{name}}?',
              validationButtons: {
                approve: 'Aprovar',
                disapprove: 'Reprovar',
              },
              companyDataDescriptions: {
                descriptions: {
                  id: 'Id',
                  documentNumber: 'Número do documento',
                  companyName: 'Razão social',
                  tradeName: 'Nome fantasia',
                  companyType: 'Tipo',
                  companyFunction: 'Função',
                  companyStatus: 'Status',
                  createdAt: 'Criado em',
                  updatedAt: 'Atualizado em',
                  deletedAt: 'Excluído em',
                  urlLogo: 'Logo da empresa',
                  moreDetails: 'Mais detalhes',
                },
              },
              motherCompanyDescription: {
                title: 'Empresa mãe',
              },
              addressDescriptions: {
                title: 'Endereço',
              },
              contactDescriptions: {
                title: 'Contato',
              },
              companyStatusTable: {
                title: 'Status',
                columns: {
                  status: 'Status',
                  manager: 'Alterado por',
                  updatedAt: 'Alterado em',
                },
              },
              usersTable: {
                title: 'Usuários',
                actions: {
                  create: 'Vincular usuário',
                },
              },
              childrenCompaniesTable: {
                title: 'Empresas filhas',
              },
              carrier: {
                title: 'Dados da transportadora',
                carrierCompanyDataDescriptions: {
                  descriptions: {
                    hasFleet: 'Possui frota própria?',
                    fleetQuantity: 'Quantidade de veículos',
                  },
                },
                carrierCompanyPoliciesTable: {
                  title: 'Apólices',
                  columns: {
                    name: 'Apólice',
                    expiresIn: 'Expira em',
                    createdAt: 'Criado em',
                    updatedAt: 'Atualizado em',
                    actions: 'Ações',
                  },
                  actions: {
                    view: 'Visualizar',
                  },
                },
                carrierCompanyOperationAreasTable: {
                  title: 'Áreas de operação',
                  columns: {
                    stateInitials: 'Sigla',
                    stateName: 'UF',
                    createdAt: 'Criado em',
                    updatedAt: 'Atualizado em',
                  },
                },
              },
              components: {
                approveCompanyModal: {
                  okText: 'Sim, aprovar',
                  content: 'Você realmente deseja aprovar esta empresa?',
                  useApproveCompanyModal: {
                    title: 'Empresa mãe aguardando aprovação',
                    content:
                      'Para aprovar esta empresa é necessário que a empresa mãe seja aprovada antes. Por favor, analise o cadastro da empresa mãe primeiro.',
                    success: 'Empresa aprovada com sucesso!',
                  },
                },
                disapproveCompanyModal: {
                  form: {
                    labels: {
                      reasons: 'Selecione as seções com pendência',
                      isBlocked: 'Reprovar permanentemente',
                      description: 'Informe o motivo da reprovação da empresa',
                    },
                    extra: {
                      description:
                        'O texto será enviado para o email do usuário',
                    },
                  },
                  footer: {
                    actions: {
                      submit: {
                        confirm: 'Sim, reprovar',
                        popconfirm:
                          'Você deseja realmente reprovar esta empresa?',
                      },
                      cancel: 'Cancelar',
                    },
                  },
                  useDisapproveCompanyModal: {
                    success: 'Empresa reprovada com sucesso!',
                  },
                },
              },
            },
            companyValidationPage: {
              title: 'Validação de empresas',
              subTitle: 'Verifique os dados das empresas',
              table: {
                title: 'Empresas aguardando validação',
              },
            },
          },
          user: {
            userDetailsPage: {
              title: 'Detalhes do usuário',
              components: {
                userDataDescriptions: {
                  labels: {
                    id: 'Id',
                    name: 'Nome',
                    email: 'E-mail',
                    active: 'Ativo?',
                    createdAt: 'Criado em',
                    updatedAt: 'Atualizado em',
                    deletedAt: 'Excluído em',
                  },
                },
                userPermissionsTable: {
                  title: 'Permissões',
                  columns: {
                    module: 'Módulo',
                  },
                },
              },
              companiesTable: {
                title: 'Empresas vinculadas',
                actions: {
                  create: 'Vincular empresa',
                },
              },
            },
            usersPage: {
              title: 'Usuários',
              subTitle: 'Visualize e gerencie os usuários',
              filter: {
                labels: {
                  active: 'Ativo?',
                  email: 'E-mail',
                  name: 'Nome',
                  companyName: 'Empresa',
                  createdAt: 'Criado entre',
                  updatedAt: 'Atualizado entre',
                },
              },
              actions: {
                createTruckerUser: 'Criar suporte',
                createUser: 'Criar usuário',
              },
              createUserInternallyModal: {
                title: 'Criar usuário',
                notifications: {
                  userCreated: {
                    message: 'Usuário criado com sucesso',
                    description:
                      'O usuário receberá um email com um link para criar uma senha e ativar a conta',
                  },
                },
              },
              createTruckerUserModal: {
                title: 'Criar suporte',
                notifications: {
                  userCreated: {
                    message: 'Suporte criado com sucesso',
                    description:
                      'O novo usuário de suporte já pode acessar a plataforma com o email e senha criados. <0>Clique aqui</0> para copiar os dados de acesso do usuário',
                    clipboard: {
                      tooltips: {
                        copy: 'Copiar',
                        copied: 'Copiado',
                      },
                      text: 'Email: {{email}}\nSenha: {{password}}',
                    },
                  },
                },
              },
              components: {
                createUserInternallyForm: {
                  fields: {
                    company: 'Empresa',
                    email: 'E-mail',
                    name: 'Nome',
                    phone: 'Telefone',
                    role: 'Papel',
                  },
                  rules: {
                    invalidPhoneNumber:
                      'Por favor, digite um número de telefone válido',
                  },
                },
                createTruckerUserForm: {
                  fields: {
                    email: 'E-mail',
                    name: 'Nome',
                    password: 'Senha',
                    role: 'Papel',
                  },
                  tooltips: {
                    passwordGenerator: 'Clique aqui para gerar uma nova senha',
                  },
                },
              },
            },
          },
          components: {
            linkUserToCompany: {
              form: {
                labels: {
                  user: 'Usuário',
                  company: 'Empresa',
                  role: 'Papel',
                },
              },
            },
            linkUserToCompanyModal: {
              title: 'Vincular empresa',
              actions: {
                create: 'Salvar',
              },
            },
          },
        },
      },
    },
    components: {
      company: {
        companyDescriptions: {
          companyPoliciesDescriptions: {
            descriptions: {
              view: 'Visualizar',
            },
          },
          companyDataDescriptions: {
            descriptions: {
              documentNumber: 'Documento',
              companyType: 'Tipo',
              companyFunction: 'Função',
              tradeName: 'Nome fantasia',
              companyName: 'Razão social',
            },
          },
          companyAddressDescriptions: {
            descriptions: {
              zipCode: 'CEP',
              cityName: 'Cidade',
              stateInitials: 'UF',
              districtName: 'Bairro',
              street: 'Logradouro',
              number: 'Número',
            },
          },
          companyContactDescriptions: {
            descriptions: {
              phone: 'Telefone',
              email: 'E-mail',
            },
          },
        },
      },
      freight: {
        attachments: {
          attachmentForm: {
            labels: {
              file: 'Anexo',
              short_description: 'Descrição',
            },
            placeholders: {
              short_description: 'Máximo de 100 caracteres',
            },
            upload: {
              text: 'Clique aqui ou arraste um arquivo para enviá-lo',
              hint: 'O arquivo deve possuir no máximo 5 Mb e deve ser um PDF ou uma imagem (.jpg, .jpeg, .png)',
            },
          },
          attachmentsTable: {
            columns: {
              file: 'Anexo',
              short_description: 'Descrição',
              created_at: 'Criado em',
            },
          },
        },
        place: {
          placeFilter: {
            inputs: {
              labels: {
                cadence: 'Cadência',
                city: 'Cidade',
                hasBalance: 'Possui balança?',
                hasDirtRoad: 'Estrada de chão?',
                search: ' ',
                state: 'UF',
              },
              placeholders: {
                city: 'Selecione',
                hasBalance: 'Selecione',
                hasDirtRoad: 'Selecione',
                search: 'Pesquisar item da tabela',
                state: 'Selecione',
              },
            },
            actions: {
              filter: 'Filtrar',
              reset: 'Limpar filtro',
            },
          },
          createPlaceModal: {
            title: 'Cadastrar local',
            inputs: {
              labels: {
                name: 'Nome',
                city_id: 'Cidade',
                state_id: 'UF',
                cadence: 'Cadência',
                has_balance: 'Possui balança?',
                balance_capacity: 'Capacidade da balança',
                has_dirt_road: 'Estrada de chão?',
                dirt_road_distance: 'Distância em km de chão',
                note: 'Observação',
                opening_hour: 'Horário de funcionamento',
              },
              placeholders: {
                name: 'Informe um nome',
                city_id: 'Selecione',
                state_id: 'Selecione',
                balance_capacity: '',
                dirt_road_distance: '',
                note: 'Digite sua observação',
                opening_hour: 'HH:mm',
              },
              help: {
                map: 'Clique no mapa ou arraste o marcador para selecionar o local desejado.',
              },
            },
            actions: {
              cancel: 'Cancelar',
              register: 'Cadastrar',
            },
          },
          placeDetailsModal: {
            title: 'Detalhes do local',
            descriptions: {
              name: 'Nome',
              city_name: 'Cidade',
              state_id: 'UF',
              cadence: 'Cadência',
              balance_capacity: 'Balança',
              dirt_road_distance: 'Estrada de chão',
              note: 'Observação',
              opening_hour: 'Horário de funcionamento',
            },
          },
          updatePlaceForm: {
            inputs: {
              labels: {
                name: 'Nome',
                city_id: 'Cidade',
                state_id: 'UF',
                cadence: 'Cadência',
                has_balance: 'Possui balança?',
                balance_capacity: 'Capacidade da balança',
                has_dirt_road: 'Estrada de chão?',
                dirt_road_distance: 'Distância em km de chão',
                note: 'Observação',
                opening_hour: 'Horário de funcionamento',
              },
              placeholders: {
                name: 'Informe um nome',
                city_id: 'Selecione',
                state_id: 'Selecione',
                balance_capacity: '',
                dirt_road_distance: '',
                note: 'Digite sua observação',
                opening_hour: 'HH:mm',
              },
            },
          },
          updatePlaceModal: {
            title: 'Atualizar local',
            help: {
              map: 'Clique no mapa ou arraste o marcador para selecionar o local desejado.',
            },
            actions: {
              cancel: 'Cancelar',
              register: 'Atualizar',
            },
          },
          placeTable: {
            title: 'Locais cadastrados',
            columns: {
              name: 'Nome',
              state_id: 'UF',
              city_name: 'Cidade',
              cadence: 'Cadência',
              dirt_road_distance: 'Estrada de chão',
              has_balance: 'Possui balança',
              note: 'Observação',
              actions: 'Ações',
            },
            actions: {
              view: 'Visualizar',
              update: 'Editar',
              create: 'Novo local',
              delete: {
                button: 'Excluir',
                popconfirm: 'Deseja realmente apagar este local?',
              },
            },
          },
          managePlaces: {
            title: 'Locais',
            subTitle: 'Crie e gerencie seus locais de retirada e entrega',
          },
          viewPlace: {
            name: 'Nome',
            city_name: 'Município',
            cadence: 'Cadência',
            dirt_road_distance: 'Estrada de Chão',
            balance_capacity: 'Capacidade da balança',
            note: 'Observação',
            business_hours: 'Horário de funcionamento',
          },
        },
        route: {
          routeMap: {
            descriptions: {
              distance: 'Distância',
              estimate_time: 'Tempo estimado',
            },
          },
          routesFilter: {
            inputs: {
              labels: {
                pickupCityId: 'Município de retirada',
                dropoffCityId: 'Município de entrega',
                search: ' ',
              },
              placeholders: {
                pickupCityId: 'Selecione',
                dropoffCityId: 'Selecione',
                search: 'Pesquisar item da tabela',
              },
            },
            actions: {
              filter: 'Filtrar',
              reset: 'Limpar filtro',
            },
          },
          createRoute: {
            alerts: {
              createPlace: 'Não possui locais cadastrados? Cadastre agora',
              samePlaceSelected:
                'Não é possível selecionar o mesmo local para a retirada e para a entrega',
              sameCoordinatesSelected:
                'Ambos os locais selecionados possuem as mesmas coordenadas, por favor, selecione um local com coordenadas diferente',
            },
            cards: {
              pickupPlaceTitle: 'Local de retirada',
              dropoffPlaceTitle: 'Local de entrega',
            },
            form: {
              fields: {
                labels: {
                  name: 'Nome da rota',
                },
                tooltips: {
                  name: 'Esse campo será utilizado como identificador da rota',
                },
              },
            },
            actions: {
              createPlace: 'Novo local',
              submit: 'Confirmar',
              cancel: 'Cancelar',
            },
            confirm: {
              cancel: 'Você realmente deseja cancelar o cadastro de rotas?',
            },
          },
          routesTable: {
            tableTitle: 'Rotas cadastradas',
            title: {
              rowSelection: 'Selecione',
              routeName: 'Nome da rota',
              withdrawalPlace: 'Nome do local de retirada',
              withdrawalCity: 'Município de retirada',
              deliveryPlace: 'Nome do local de entrega',
              deliveryCity: 'Município entrega',
              totalDistance: 'Total em Km',
              estimatedTime: 'Tempo estimado',
              actions: 'Ações',
            },
            buttonText: 'Nova rota',
            viewButton: 'Visualizar',
          },
          viewRoute: {
            routeCard: {
              descriptions: {
                name: 'Nome da rota',
              },
            },
            withdrawalPlaceCard: {
              title: 'Local de embarque',
            },
            deliveryPlaceCard: {
              title: 'Local de desembarque',
            },
          },
        },
        carrier: {
          carrierListFreights: {
            title: 'Cotações',
            subTitle: 'Lance e gerencie suas cotações',
          },
          carrierQuotingFreightTable: {
            title: 'Fretes em cotação',
            columns: {
              route: 'Rota',
              shipment_date: 'Data embarque',
              landing_date: 'Data desembarque',
              product_type: 'Tipo',
              product: 'Produto',
              packing: 'Embalagem',
              total_volume: 'Volume',
              quote_limit: 'Tempo cotação',
              actions: 'Ações',
            },
            actions: {
              view: 'Visualizar',
            },
            tooltips: {
              quote_limit:
                'Após o tempo acabar, o frete ficará fechado para novas cotações',
            },
          },
          carrierQuotedFreightTable: {
            title: 'Fretes cotados',
            columns: {
              route: 'Rota',
              shipment_date: 'Data embarque',
              landing_date: 'Data desembarque',
              product_type: 'Tipo',
              product: 'Produto',
              packing: 'Embalagem',
              total_volume: 'Volume',
              quote_limit: 'Tempo cotação',
              value_per_unit: 'Preço',
              actions: 'Ações',
            },
            actions: {
              view: 'Visualizar',
            },
            tooltips: {
              quote_limit:
                'Após o tempo acabar, o Embarcador poderá fazer a contratação do frete',
            },
          },
          carrierHiredFreightTable: {
            title: 'Fretes contratados',
            columns: {
              route: 'Rota',
              shipment_date: 'Data embarque',
              landing_date: 'Data desembarque',
              product_type: 'Tipo',
              product: 'Produto',
              packing: 'Embalagem',
              requested_volume: 'Volume',
              value_per_unit: 'Preço',
              status: 'Status',
              actions: 'Ações',
            },
            actions: {
              view: 'Visualizar',
            },
          },
        },
        shipper: {
          listFreights: {
            title: 'Fretes',
            subTitle: 'Crie e gerencie seus fretes',
          },
          quotingFreightTable: {
            title: 'Fretes em cotação',
            columns: {
              route: 'Rota',
              shipment_date: 'Data embarque',
              landing_date: 'Data desembarque',
              product_type: 'Tipo',
              product: 'Produto',
              packing: 'Embalagem',
              total_volume: 'Volume',
              quote_limit: 'Tempo cotação',
              quotes_received: 'Cotações recebidas',
              actions: 'Ações',
            },
            actions: {
              create: 'Nova cotação',
              view: 'Visualizar',
              delete: {
                button: 'Excluir',
                popconfirm: 'Deseja realmente excluir este frete?',
              },
            },
            tooltips: {
              quote_limit:
                'Após o tempo acabar, o frete ficará fechado para novas cotações e será possível fazer a sua contratação',
            },
          },
          quotedFreightTable: {
            title: 'Fretes cotados',
            columns: {
              route: 'Rota',
              shipment_date: 'Data embarque',
              landing_date: 'Data desembarque',
              product_type: 'Tipo',
              product: 'Produto',
              packing: 'Embalagem',
              requested_volume: 'Volume',
              total_volume: 'Volume',
              expires_in: 'Expira em',
              quotes_received: 'Cotações recebidas',
              actions: 'Ações',
            },
            actions: {
              view: 'Visualizar',
              delete: {
                button: 'Excluir',
                popconfirm: 'Deseja realmente excluir este frete?',
              },
            },
            tooltips: {
              expires_in:
                'Após o tempo acabar, o frete ficará expirado e não será possível fazer a sua contratação',
            },
          },
          hiredFreightTable: {
            title: 'Fretes contratados',
            columns: {
              route: 'Rota',
              shipment_date: 'Data embarque',
              landing_date: 'Data desembarque',
              product_type: 'Tipo',
              product: 'Produto',
              packing: 'Embalagem',
              requested_volume: 'Volume',
              total_volume: 'Volume',
              hired_quotation_price: 'Valor contratado',
              status: 'Status',
              actions: 'Ações',
            },
            actions: {
              view: 'Visualizar',
            },
          },
          createFreightForm: {
            routesTable: {
              title: 'Selecione uma rota',
              actions: {
                create: 'Cadastrar nova rota',
              },
              validation: {
                required: 'Por favor, selecione uma rota',
              },
            },
            dateSubFormTitle: 'Datas para cotação',
            loadSubFormTitle: 'Configurando a carga',
            fields: {
              labels: {
                quote_limit_date: 'Data limite de cotação',
                shipment_date: 'Data de embarque',
                landing_date: 'Data de desembarque',
                product_type_id: 'Tipo de produto',
                product_id: 'Produto',
                product_variety_id: 'Variedade do produto',
                packing_id: 'Embalagem',
                total_volume: 'Tamanho da carga',
                note_value: 'Valor total da NF',
                suggested_value: 'Preço sugerido',
                observation: 'Observação',
              },
              placeholders: {
                product_type_id: 'Selecione',
                product_id: 'Selecione',
                product_variety_id: 'Selecione',
                packing_id: 'Selecione',
                total_volume: '',
                note_value: '',
                suggested_value: '',
              },
              tooltips: {
                timeBetweenShipmentAndLanding:
                  'Atenção com o intervalo entre as datas de embarque e desembarque e o tempo estimado a ser gasto com a rota selecionada',
                shipment_date:
                  'A data de embarque pode ser no mesmo dia da data limite de cotação, desde que a cotação se encerre antes das 15:00 (horário de Brasília). É necessário um tempo após o fim da cotação para a contratação e confirmação do frete.',
                suggested_value:
                  'Este preço será mostrado para as transportadoras como preço sugerido para esse frete',
              },
              extra: {
                quote_limit_date: {
                  footer: {
                    minimumDateButton: 'Duas horas',
                  },
                },
              },
            },
            actions: {
              submit: 'Confirmar',
              cancel: {
                popconfirm: {
                  title:
                    'Os dados não foram salvos. Deseja cancelar a criação do frete?',
                },
                button: 'Cancelar',
              },
            },
            validations: {
              shipmentDateAfterQuoteLimitDate:
                'A data de embarque deve ser posterior a data de limite de cotação.',
              landingDateAfterShipmentDate:
                'A data de desembarque deve ser posterior que a data de embarque',
              validQuoteLimitDate: {
                businessTime:
                  'A data limite de cotação deve ser entre as 9 e as 17 horas (horário de brasília)',
                max: 'O tempo máximo de cotação é de sete dias',
                min: 'O tempo mínimo de cotação é de duas horas',
                weekday:
                  'A data limite de cotação não pode ser em um fim de semana',
              },
              timeBetweenShipmentAndLanding:
                'O intervalo entre as datas de embarque e desembarque é menor que o tempo estimado da rota selecionada',
            },
          },
          confirmCreateFreight: {
            confirmFreightDates: {
              title: 'Datas para cotação',
              fields: {
                quote_limit_time: 'Data limite de cotação',
                shipment_date: 'Data de embarque',
                landing_date: 'Data de desembarque',
              },
            },
            confirmFreightLoad: {
              title: 'Carga',
              fields: {
                product_type_id: 'Tipo de produto',
                product_id: 'Produto',
                product_variety_id: 'Variedade do produto',
                packing_id: 'Embalagem',
                total_volume: 'Tamanho da carga',
                note_value: 'Valor total da NF',
                suggested_value: 'Preço sugerido',
                observation: 'Observação',
              },
            },
            quoteLimitDateForm: {
              fields: {
                labels: {
                  timeAmount: 'Tempo de cotação',
                  quoteInterval: 'Intervalo de cotação',
                  shipmentDate: 'Data de embarque',
                  landingDate: 'Data de desembarque',
                },
                validations: {
                  timeAmount: {
                    hours: {
                      min: 'O tempo mínimo de cotação é de duas horas',
                      max: 'O tempo máximo de cotação é de 168 horas (7 dias)',
                    },
                    days: {
                      min: 'O tempo mínimo de cotação é de 1 dia',
                      max: 'O tempo máximo de cotação é de 7 dias',
                    },
                  },
                  newFreightDates:
                    'As datas de embarque e desembarque foram alteradas devido ao tempo de cotação escolhido',
                },
                tooltips: {
                  quote_limit_date:
                    'A data limite de cotação deve ser um dia da semana, entre as 09 e as 17 horas (horário de Brasília). O tempo mínimo de cotação é de duas horas e o tempo máximo é de 7 dias.',
                },
              },
            },
            actions: {
              submit: 'Cotar',
              cancel: 'Voltar',
            },
          },
          quotationGroupsForm: {
            title: 'Selecione um grupo de cotação',
            actions: {
              create: 'Novo grupo',
            },
            tooltip:
              'É possível selecionar até 10 grupos para o frete. Caso não seja selecionado nenhum grupo, o frete será enviado para todas as transportadoras',
            validations: {
              maxQuotationGroups:
                'Um frete pode ser cotado com no máximo 10 grupos de cotação',
            },
          },
        },
        quotation: {
          hireQuotationTable: {
            title: 'Cotações',
            titleHiredCarrier: 'Cotações contratadas',
            table: {
              columns: {
                select: 'Selecionar',
                carrier: 'Transportadora',
                rating: 'Avaliação',
                finishedFreights: 'Fretes finalizados',
                pricePerUnit: 'Preço',
                hasVehicle: 'Veículo disponível',
                totalVolume: 'Volume proposto',
                loadedVolume: 'Volume carregado',
                status: 'Status',
                createdBy: 'Lançado por',
                actions: 'Ações',
              },
              rows: {
                status: {
                  HIRED: 'Contratada',
                  WAITING: 'Aguardando',
                  CARRYING: 'Carregando',
                  LOADED: 'Carregado',
                  FINISHED: 'Finalizada',
                  DROPPED: 'Revogada',
                  CANCELED: 'Cancelada',
                  REFUSED: 'Recusada',
                  ABANDONED: 'Abandonada',
                },
              },
            },
            tooltips: {
              select:
                'Só é possível contratar várias cotações quando elas possuírem o mesmo valor por tonelada e a soma dos volumes propostos não ultrapassarem o volume do frete.',
            },
            actions: {
              view: 'Detalhes',
              finish: 'Finalizar',
            },
          },
          quotationShortDescription: {
            title: 'Dados da cotação',
            fields: {
              value_per_unit: 'Valor da tonelada',
              total_volume: 'Volume proposto',
              requested_volume: 'Volume contratado',
            },
          },
          canceledQuotationModal: {
            title: 'Detalhes da cotação',
            fields: {
              labels: {
                carrier: 'Transportadora',
                rating: 'Avaliação',
                valuePerTon: 'Valor/ton',
                hasVehicle: 'Veículo disponível',
                requestedVolume: 'Volume proposto',
                loadedVolume: 'Volume carregado',
                cancellationReason: 'Motivo da desistência',
                description: 'Descrição',
              },
            },
            actions: {
              close: 'Fechar',
            },
          },
        },
        freightLoadDescriptions: {
          title: 'Carga',
          descriptions: {
            productTypeName: 'Tipo de produto',
            productName: 'Produto',
            productVarietyName: 'Variedade do produto',
            packingName: 'Embalagem',
            totalVolume: 'Quantidade',
            noteValue: 'Valor da NF',
            suggestedValue: 'Valor sugerido',
            observation: 'Observação',
          },
        },
        freightVolumeBar: {
          descriptions: {
            unallocatedVolume: 'Volume não alocado',
            allocatedVolume: 'Volume alocado',
            loadedVolume: 'Volume carregado',
          },
        },
        quotationVolumeBar: {
          descriptions: {
            unallocatedVolume: 'Volume não confirmado',
            allocatedVolume: 'Volume confirmado',
            loadedVolume: 'Volume carregado',
          },
        },
        freightPlaceDescriptions: {
          title: 'Dados do frete',
          descriptions: {
            landingDate: 'Desembarque',
            shipmentDate: 'Embarque',
            quoteLimit: 'Data limite de cotação',
            city: 'Município',
            place: {
              WITHDRAWAL: 'Local de embarque',
              DELIVERY: 'Local de desembarque',
            },
          },
        },
        freightDetails: {
          quoting: {
            freightStatusWarning: {
              description:
                'Este frete ainda está encerrando o recebimento de novas cotações. A contratação de cotações será disponibilizada apenas em alguns instantes. Aguarde, por favor.',
            },
            actions: {
              cancel: 'Voltar',
            },
            companyDetailsModalTitle: 'Detalhes da transportadora',
          },
          quoted: {
            actions: {
              cancel: {
                button: 'Voltar',
                popconfirm: {
                  title:
                    'Você ainda não confirmou a contratação da cotações selecionadas, deseja realmente voltar?',
                },
              },
              submit: 'Confirmar',
            },
            modal: {
              title: 'Contratação',
              text: {
                common:
                  'Será encaminhado para a(s) transportadora(s) selecionada(s) uma solicitação de carregamento.',
                before24Hours:
                  'A trasportadora terá até 24 horas antes da data de embarque para confirmar o motorista e garantir o frete.',
                after24Hours:
                  'A trasportadora terá até a data de embarque para confirmar o motorista e garantir o frete.',
              },
              footer: {
                okText: 'Confirmar',
              },
            },
            companyDetailsModalTitle: 'Detalhes da transportadora',
          },
          hired: {
            actions: {
              view: 'Visualizar',
              finish: 'Finalizar',
            },
            table: {
              columns: {
                carrier: 'Transportadora',
                valuePerTon: 'Valor/Ton',
                hasVehicle: 'Veículo disponível',
                proposedVolume: 'Volume proposto',
                loadedVolume: 'Volume carregado',
                status: 'Status',
                actions: 'Ações',
              },
            },
            warning: {
              message:
                'Esse frete possui <0 /> não alocadas. Deseja criar um novo frete para cotação com o total não alocado?',
              newQuotation: 'Criar novo frete',
            },
          },
          components: {
            attachmentFormModal: {
              title: 'Anexar arquivo',
              components: {
                openButton: {
                  label: 'Anexar arquivos',
                },
              },
              useAttachmentFormModal: {
                submitSuccess: {
                  message: 'Arquivo anexado ao frete com sucesso!',
                },
              },
            },
            attachmentTableModal: {
              title: 'Arquivos anexados',
              components: {
                openButton: {
                  label: 'Visualizar anexos',
                },
              },
            },
            companyDetails: {
              dataTitle: 'Dados',
              addressTitle: 'Endereço',
              contactTitle: 'Contato',
              policiesTitle: 'Apólices',
            },
            quotationGroupsTable: {
              title: 'Grupos de cotação',
              noQuotationGroupAlert: {
                message: 'Frete enviado para todas as transportadoras',
              },
            },
          },
        },
        cancelFreight: {
          title: 'Cancelar frete',
          inputs: {
            labels: {
              reason: 'Motivo',
              reasonDescription: 'Descrição do motivo',
            },
            placeholders: {
              reasonDescription: 'Descreva o motivo do cancelamento do frete',
            },
          },
        },
      },
      user: {
        userDetails: {
          userData: {
            title: 'Detalhes do usuário',
            fields: {
              name: 'Nome',
              email: 'E-mail',
              roles: 'Papéis',
            },
          },
          companyData: {
            title: 'Empresa',
            fields: {
              company_name: 'Nome',
              company_function: 'Função',
              company_type: 'Tipo',
              company_theme: 'Tema',
            },
            tooltips: {
              company_theme:
                'O tema selecionado será aplicado para todos os usuários pertencentes à sua empresa',
              isAdmOrSup: {
                company_theme:
                  'Usuários do suporte não podem alterar o tema de outras empresas',
              },
            },
          },
          permissionTable: {
            title: 'Permissões',
            columns: {
              pages: 'Páginas',
            },
          },
          useEditCompanyForm: {
            submitSuccess: {
              message: 'Tema da empresa atualizado com sucesso!',
            },
          },
        },
      },
      product: {
        product: {
          createProductsModal: {
            title: 'Cadastrar novos produtos',
            fields: {
              labels: { name: 'Nome', product_type: 'Tipo de produto' },
              placeholders: {
                name: 'Nome do produto',
                product_type: 'Selecione',
              },
            },
            actions: {
              ok: 'Cadastrar',
              add: 'Adicionar campos',
            },
          },
          listProducts: {
            title: 'Lista de produtos',
            actions: {
              create: 'Novo produto',
              edit: 'Editar',
              delete: {
                popconfirm: 'Você realmente deseja excluir este produto?',
                button: 'Deletar',
              },
            },
            productTable: {
              columns: {
                name: 'Nome',
                product_type: 'Tipo de produto',
                actions: 'Ações',
              },
            },
            editableTableCell: {
              fields: {
                labels: { name: 'Nome', type_id: 'Tipo de produto' },
                placeholders: {
                  name: 'Nome do produto',
                  type_id: 'Selecione',
                },
              },
              actions: {
                save: {
                  popconfirm: 'Você realmente deseja salvar as alterações?',
                  button: 'Salvar',
                },
                cancel: 'Cancelar',
              },
            },
            productsFilter: {
              fields: {
                labels: {
                  search: '',
                  productTypeId: 'Tipo de produto',
                },
                placeholders: {
                  search: 'Pesquise por um produto',
                  productTypeId: 'Selecione',
                },
              },
              actions: {
                reset: 'Limpar filtro',
                submit: 'Filtrar',
              },
            },
          },
          productSelect: {
            notFoundMessage: 'Não foi possível obter os dados',
          },
        },
        productType: {
          createProductTypesModal: {
            title: 'Cadastrar novo tipo de produto',
            fields: {
              labels: {
                name: 'Nome',
              },
              rules: {
                required: 'Por favor, insira um nome ou apague esse campo.',
              },
              placeholders: {
                name: 'Nome do tipo de produto',
              },
            },
            actions: {
              ok: 'Cadastrar',
              add: 'Adicionar campo',
            },
          },
          productTypeDetailsModal: {
            actions: {
              close: 'Fechar',
            },
            tables: {
              products: {
                title: 'Produtos',
                columns: {
                  name: 'Nome',
                },
              },
              packings: {
                title: 'Embalagens',
                columns: {
                  name: 'Nome',
                  capacity: 'Capacidade',
                },
              },
            },
          },
          productTypesTable: {
            title: 'Tipos de produtos cadastrados',
            actions: {
              create: 'Novo tipo de produto',
              view: 'Visualizar',
              delete: {
                button: 'Excluir',
                confirm: 'Deseja realmente apagar esse tipo de produto?',
              },
            },
            columns: {
              name: 'Nome',
              actions: 'Ações',
            },
          },
          productTypesFilter: {
            actions: {
              filter: 'Filtrar',
              reset: 'Limpar filtro',
            },
          },
          productTypeSelect: {
            notFoundMessage: 'Não foi possível obter os dados',
          },
        },
        package: {
          packageSelect: {
            notFoundMessage: 'Não foi possível obter os dados',
          },
        },
      },
      refusalReason: {
        refusalReasonSelect: {
          notFoundMessage: 'Não foi possível obter os dados',
        },
      },
      quotation: {
        confirmQuotation: {
          confirmQuotation: {
            cardTitle: 'Confirmar intenção de frete',
            error:
              'O seu contrato para esse frete foi revogado porque não houve confirmação da intenção de frete até <0 />',
            warning:
              'Você tem até <0 /> para confirmar ao menos um motorista e não ter o frete cancelado',
            actions: {
              waive: 'Informar desistência',
            },
          },
          confirmQuotationForm: {
            alert:
              'Preencha o formulário e clique em "{{addButton}}" para adicionar um motorista à tabela. Para confirmar as alterações clique em "{{confirmButton}}"',
            fields: {
              driver_name: 'Nome do motorista',
              cnh: 'Nº CNH do motorista',
              vehicle_type: 'Veículo',
              assigned_volume: 'Volume estimado',
              vehicle_plate: 'Placa do veículo',
            },
            actions: {
              add: 'Adicionar motorista',
            },
            validations: {
              assignedVolume:
                'O volume estimado deve ser menor ou igual ao volume contratado',
              hasNoUnallocatedVolume: 'Todo o volume contratado já foi alocado',
              repeatedNumbers: 'CNH inválida',
              alreadyConfirmedDriverValidator:
                'O motorista informado já foi inserido',
              placesBalance: {
                message:
                  'O volume estimado é maior que a capacidade da balança no {{place}}',
                WITHDRAWAL: 'local de embarque',
                DELIVERY: 'local de desembarque',
              },
            },
          },
          quotationConfirmationTable: {
            columns: {
              isConfirmed: 'Confirmado?',
              driver_name: 'Nome do motorista',
              cnh: 'Nº CNH do motorista',
              vehicle_type: 'Veículo',
              assigned_volume: 'Volume estimado',
              vehicle_plate: 'Placa do veículo',
              loaded_volume: 'Volume carregado',
              actions: 'Ações',
            },
            tooltips: {
              isConfirmed:
                'Para confirmar a intenção de frete é necessário inserir ao menos um motorista. Após confirmado, você não poderá cancelar a intenção de frete.',
              actions:
                'Só é possível editar ou remover confirmações que não possuem CTEs vinculadas.',
            },
          },
        },
        quotationDescriptions: {
          carrier: 'Transportadora',
          rating: 'Avaliação',
          pricePerUnit: 'Valor',
          hasVehicle: 'Veículo disponível',
          requestedVolume: 'Volume proposto',
          loadedVolume: 'Volume carregado',
          caution: 'Atenção',
          cautionDescription:
            'Esse contrato possui um volume de <0 /> que não foi movimentado.',
          notRated: 'Sem avaliação',
        },
        shipper: {
          canceledQuotationModal: {
            title: 'Detalhes da cotação',
            fields: {
              labels: {
                carrier: 'Transportadora',
                rating: 'Avaliação',
                valuePerTon: 'Valor/ton',
                hasVehicle: 'Veículo disponível',
                requestedVolume: 'Volume proposto',
                loadedVolume: 'Volume carregado',
                cancellationReason: 'Motivo da desistência',
                description: 'Descrição',
              },
            },
          },
          carrierHiredDetailsModal: {
            title: 'Visualizar transportadora',
            fields: {
              labels: {
                carrier: 'Transportadora',
              },
            },
          },
        },
        abandonOrCancelQuotationModal: {
          title: 'Finalizar contrato',
          inputs: {
            labels: {
              reason: 'Motivo da finalização',
              reasonDescription: 'Descreva o motivo',
            },
            placeholders: {
              select: 'Selecione',
              reasonDescription: 'Descreva o motivo da finalização do contrato',
            },
          },
        },
        createQuotation: {
          notification: {
            quoteLimitHasEnded: {
              message: 'Frete fechado para novas cotações',
              description:
                'O frete que você tentou acessar está fechado para novas cotações.',
            },
            createQuotationSuccess: {
              message: 'Cotação enviada com sucesso!',
            },
            refuseQuotationSuccess: {
              message: 'Frete recusado com sucesso!',
            },
          },
          actions: {
            submit: 'Confirmar',
            cancel: 'Cancelar',
            refuse: 'Recusar',
          },
        },
        createQuotationForm: {
          cardTitle: 'Lançar cotação',
          fields: {
            labels: {
              price_per_unit: 'Preço por {{unitType}}',
              total_price: 'Preço final',
              total_volume: 'Quantidade que pretende carregar',
              has_vehicle: 'Possuo veículo disponível',
            },
            extra: {
              average_value: 'Valor médio',
              final_value: 'Valor final',
              final_value_per_ton: 'Valor final por ton',
            },
            tooltips: {
              price_per_unit:
                'Informe o preço por {{unitType}}. O preço total será calculado automaticamente e será mostrado no campo ao lado.',
              total_price:
                'Informe o preço final. O preço por {{unitType}} será calculado automaticamente e será mostrado no campo ao lado. O valor médio é referente ao volume total do frete.',
              total_volume:
                'Informe a quantidade que pretende carregar. Este será a quantidade pela qual o Embarcador poderá contratar a sua cotação.',
            },
            rules: {
              max_total_volume:
                'O valor máximo de $t(components.quotation.createQuotationForm.fields.labels.total_volume) é {{max_total_volume}}',
            },
          },
        },
        refuseQuotationModal: {
          title: 'Recusar o frete',
          alert: {
            description:
              'Quando um frete é recusado, ele deixa de aparecer na sua lista de fretes em cotação',
          },
          fields: {
            labels: {
              reason: 'Motivo',
              reason_description: 'Descrição do motivo',
            },
          },
          actions: {
            submit: 'Confirmar',
          },
        },
        waiveQuotationForm: {
          modalTitle: 'Informar o motivo da desistência',
          inputs: {
            labels: {
              reason: 'Motivo',
              reasonDescription: 'Descrição do motivo',
            },
            placeholders: {
              select: 'Selecione',
              reasonDescription: 'Descreva o motivo da desistência',
            },
          },
        },
        cteTable: {
          card: {
            title: 'Arquivos',
            uploadFile: 'Selecionar arquivo',
          },
          table: {
            columns: {
              fileName: 'Nome do arquivo',
              loadedVolume: 'Volume transportado',
              driver: 'Motorista',
              date: 'Data',
              actions: {
                delete: {
                  popconfirm: {
                    title: 'Tem certeza que deseja excluir este arquivo?',
                  },
                },
              },
            },
          },
        },
        uploadCteModal: {
          title: 'Carregar arquivo',
          form: {
            confirmation: 'Confirmação',
            cte: 'CTE',
          },
          upload: {
            text: 'Clique ou arreste um arquivo para fazer o envio',
            hint: 'Apenas arquivos .xml são permitidos',
            rules: {
              acceptedFileTypes: 'Apenas arquivos .xml são permitidos',
              maximumFileSize: 'O arquivo deve ter no máximo 1 MB',
            },
          },
        },
        finishQuotationModal: {
          title: 'Finalizar frete',
          text: 'Após finalizar o frete não será possível adicionar, remover ou alterar as confirmações ou CTEs. Deseja continuar?',
        },
        quotationDetails: {
          finishedQuotationWarning: {
            finishedQuotation: 'Esta cotação está finalizada',
            cancellationReason: 'Motivo',
            cancellationDescription: 'Descrição',
            cancellationReasonText:
              'A cotação não foi confirmada antes da data de expiração',
            cancellationDescriptionText:
              'A transportadora não confirmou sua participação no frete antes do prazo limite',
          },
        },
      },
      help: {
        supportHelpContact: {
          title: 'Dúvidas?',
          content: {
            text: 'Entre em contato com o nosso suporte:',
            phone: '(44) 9 9148-4518',
          },
        },
      },
      support: {
        account: {
          company: {
            companiesPageTable: {
              columns: {
                documentNumber: 'Número do documento',
                companyName: 'Empresa',
                companyType: 'Tipo',
                companyFunction: 'Função',
                companyStatus: 'Status',
                createdAt: 'Criado em',
                updatedAt: 'Atualizado em',
                actions: 'Ações',
              },
              actions: {
                view: 'Visualizar',
                delete: {
                  title: 'Desvincular',
                  popconfirm: {
                    title:
                      'Tem certeza que deseja desvincular este usuário da empresa?',
                    actions: {
                      confirm: 'Sim',
                    },
                  },
                },
              },
            },
          },
          user: {
            usersPageTable: {
              columns: {
                active: 'Ativo?',
                email: 'E-mail',
                name: 'Nome',
                companies: 'Empresas',
                createdAt: 'Criado em',
                updatedAt: 'Atualizado em',
                actions: 'Ações',
              },
              actions: {
                view: 'Visualizar',
                delete: {
                  title: 'Desvincular',
                  popconfirm: {
                    title:
                      'Tem certeza que deseja desvincular este usuário da empresa?',
                    actions: {
                      confirm: 'Sim',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    hooks: {
      useJwtDecode: {
        errors: {
          expired_token: {
            message: 'Link expirado',
            description:
              'O link que você acessou está expirado. Requisite um novo link e tente novamente',
          },
          invalid_token: {
            message: 'Link inválido',
            description: 'O link que você acessou não existe ou é inválido.',
          },
          missing_token: {
            message: 'Link inválido',
            description: 'O link que você acessou não existe ou é inválido.',
          },
        },
      },
      useDatePickerDefaultPresets: {
        presets: {
          today: 'Hoje',
          thisWeek: 'Esta semana',
          thisMonth: 'Este mês',
          lastWeek: 'Semana passada',
          lastMonth: 'Mês passado',
        },
      },
    },
    core: {
      common: {
        hooks: {
          id: 'Id',
          createdAt: 'Data de criação',
          updatedAt: 'Data de modificação',

          no: 'Não',
          yes: 'Sim',

          actions: 'Ações',
          back: 'Voltar',
          cancel: 'Cancelar',
          clearFields: 'Limpar campos',
          clearFilter: 'Limpar filtros',
          close: 'Fechar',
          confirm: 'Confirmar',
          delete: 'Excluir',
          deletePopconfirm: 'Deseja realmente excluir esse item?',
          edit: 'Editar',
          filter: 'Filtrar',
          finish: 'Finalizar',
          remove: 'Remover',
          removePopconfirm: 'Deseja realmente remover esse item?',
          save: 'Salvar',
          select: 'Selecione',
          view: 'Visualizar',
          download: 'Baixar',

          dataNotFound: 'Dados não encontrados',
          none: 'Não possui',
          notFoundMessage: 'Não foi possível obter os dados',
          unknown: 'Desconhecido',
          warning: 'Atenção!',
        },
      },
      company: {
        hooks: { CNPJ: 'CNPJ', company: 'Empresa' },
      },
      companyInGroup: {
        hooks: { createdAt: 'Data de inclusão' },
      },
      price: {
        priceDisplay: {
          KG: '{{price}} por quilograma',
          TON: '{{price}} por tonelada',
          UNIT: '{{price}} por unidade',
        },
      },
      quotationGroup: {
        hooks: {
          members: 'Transportadoras',
          name: 'Nome do grupo',
        },
      },
    },
    enums: {
      unitType: {
        KG: 'Kg',
        TON: 'Ton',
        UNIT: 'Un',
      },
      unitTypeLong: {
        KG_zero: 'Quilogramas',
        KG_one: 'Quilograma',
        KG_other: 'Quilogramas',
        TON_zero: 'Toneladas',
        TON_one: 'Tonelada',
        TON_other: 'Toneladas',
        UNIT_zero: 'Unidades',
        UNIT_one: 'Unidade',
        UNIT_other: 'Unidades',
      },
      company: {
        reproof: {
          reproofReasonType: {
            companyData: 'Dados da empresa',
            companyAddress: 'Endereço da empresa',
            companyContact: 'Contato da empresa',
            companyPolicies: 'Apólices',
            companyOperationArea: 'Áreas de atuação',
            other: 'Outro',
          },
        },
        companyStatus: {
          approved: 'Aprovado',
          inAnalysis: 'Em análise',
          pending: 'Pendente',
          reproved: 'Reprovado',
          blocked: 'Bloqueado',
          waitingData: 'Aguardando dados',
          rectifiedData: 'Dados retificados',
        },
      },
      reasonType: {
        companyData: 'Dados da empresa',
        companyAddress: 'Endereço da empresa',
        companyContact: 'Contato da empresa',
        companyPolicies: 'Apólices',
        companyOperationArea: 'Áreas de atuação',
        other: 'Outro',
      },
      reasonTypeEnum: {
        COMPANY_ADDRESS: 'Endereço da empresa',
        COMPANY_CONTACT: 'Contato da empresa',
        COMPANY_DATA: 'Dados da empresa',
        COMPANY_OPERATION_AREA: 'Áreas de atuação',
        COMPANY_POLICIES: 'Apólices',
        OTHER: 'Outro',
      },
      companyType: {
        Parent: 'Matriz',
        Branch: 'Filial',
        Unit: 'Unidade',
        parent: 'Matriz',
        branch: 'Filial',
        unit: 'Unidade',
      },
      companyFunction: {
        carrier: 'Transportadora',
        shipper: 'Embarcador',
      },
      measureUnits: {
        mass: {
          tonne: {
            name: 'Tonelada',
            initials: 'ton',
          },
        },
        length: {
          kilometer: {
            name: 'Quilômetro',
            initials: 'km',
          },
        },
        time: {
          hours: 'Horas',
          days: 'Dias',
        },
      },
      modules: {
        APPROVE_COMPANIES: 'Aprovação de empresas',
        CANCELLATION_REASONS: 'Razões de cancelamento',
        CANCELLATIONS: 'Cancelamentos',
        COMISSION_PAYMENTS: 'Pagamento de comissão',
        COMPANIES: 'Empresas',
        COMPANY_SETTINGS: 'Configurações da empresa',
        COMPANY_STATUS: 'Status da empresa',
        CTES: 'CTEs',
        DELEGATE_QUOTATIONS: 'Delegar cotação',
        DRIVERS: 'Motoristas',
        FREIGHT_ATTACHMENTS: 'Anexos de frete',
        FREIGHT_RATINGS: 'Avaliações de frete',
        FREIGHTS: 'Fretes',
        GROUP: 'Grupos de cotação',
        HIRE_QUOTATIONS: 'Contratar cotações',
        HOME: 'Página inicial',
        LINK_USER_TO_COMPANY: 'Vincular usuário à empresa',
        LOADINGS: 'Carregamentos',
        MISSING_FREIGHTS: 'Recriação de fretes',
        MODULES: 'Módulos',
        PACKINGS: 'Embalagens',
        PERMISSION_TEMPLATES: 'Modelos de permissão',
        PLACES: 'Locais',
        POLICY: 'Apólices',
        PRODUCT_TYPE_HAS_PACKINGS: 'Vincular embalagens',
        PRODUCT_TYPES: 'Tipos de produto',
        PRODUCTS: 'Produtos',
        QUOTATION_CONFIRMATIONS: 'Confirmações de cotação',
        QUOTATION_RATINGS: 'Avaliações de cotação',
        QUOTATIONS: 'Cotações',
        QUOTING_FREIGHTS: 'Fretes em cotação',
        RATING_REASONS: 'Razões de avaliação',
        RATINGS: 'Avaliações',
        REFUSAL_REASONS: 'Razões de recusa',
        REFUSALS: 'Recusas',
        REPROACH_COMPANIES: 'Reprovação de empresas',
        ROLES: 'Papéis',
        ROUTES: 'Rotas',
        THEMES: 'Temas',
        TRUCKER_USERS: 'Usuários da Trucker',
        UNLINK_USER_INTERNALLY: 'Desvincular usuário da empresa',
        USER_HAS_PERMISSIONS: 'Permissões do usuário',
        USER_HAS_ROLES: 'Papéis de usuários',
        USER_INTERNALLY: 'Usuários internos',
        USER_PERMISSIONS: 'Permissões',
        USERS: 'Usuários',
        VARIETIES: 'Variedades de produto',
        VEHICLE_TYPES: 'Tipos de veículo',
      },
      permissionActions: {
        CREATE: 'Criação',
        READ: 'Leitura',
        UPDATE: 'Edição',
        DELETE: 'Exclusão',
      },
      userRoles: {
        ADMINISTRATOR: 'Administrador',
        SHIPPER: 'Embarcador',
        QUOTATOR: 'Cotador',
        CARRIER: 'Transportador',
        SUPPORT: 'Suporte',
      },
      truckerUserRoleEnum: {
        ADM: 'Administrador',
        SUP: 'Suporte',
      },
      FunctionEnum: {
        CARRIER: 'Transportadora',
        SHIPPER: 'Embarcador',
      },
      CompanyTypeEnum: {
        PARENT: 'Matriz',
        BRANCH: 'Filial',
        UNIT: 'Unidade',
      },
      freight: {
        freightStatus: {
          QUOTING: 'Em cotação',
          QUOTED: 'Cotado',
          HIRING: 'Em contratação',
          HIRED: 'Contratado',
          CARRYING: 'Carregando',
          FINISHED: 'Finalizado',
          CANCELED: 'Cancelado',
          EXPIRED: 'Expirado',
          UNCOMPLETED: 'Incompleto',
        },
      },
      quotation: {
        quotationStatus: {
          HIRED: 'Contratada',
          WAITING: 'Aguardando',
          CARRYING: 'Carregando',
          LOADED: 'Carregado',
          FINISHED: 'Finalizada',
          DROPPED: 'Revogada',
          CANCELED: 'Cancelada',
          REFUSED: 'Recusada',
          ABANDONED: 'Abandonada',
        },
      },
    },
    errors: {
      default: {
        message: 'Um erro não esperado aconteceu',
        description:
          'Recarregue a página e tente realizar a ação novamente, caso o erro ocorra novamente entre em contato com nosso suporte: contato@truckerdoagro.agr.br',
      },
      network: {
        message: 'Falha de conexão',
        description:
          'Não foi possível se conectar com o servidor. Verifique a sua conexão com a internet e tente novamente.',
      },
      invalidToken: {
        message: 'Sessão expirada',
        description:
          'Realize um novo login em nossa plataforma para continuar utilizando o sistema',
      },
      userWithoutCompany: {
        message: 'Usuário sem empresa',
        description:
          'O usuário não possui uma empresa cadastrada, entre em contato com nosso suporte: contato@truckerdoagro.agr.br',
      },
      mapbox: {
        directions: {
          message: 'Falha ao buscar rota',
          description:
            'Um erro inesperado ocorreu e não foi possível buscar a rota',
        },
      },
      101: {
        message: 'Acesso não autorizado (101)',
        description: 'O usuário não tem acesso à este recurso',
      },
      102: {
        message: 'Acesso não autorizado (102)',
        description: 'Nenhum token foi informado',
      },
      103: {
        message: 'Acesso não autorizado (103)',
        description:
          'O usuário não tem o tipo necessário para acessar o recurso',
      },
      104: {
        message: 'Campos duplicados (104)',
        description: 'Foram informados campos duplicados no arquivo',
      },
      105: {
        message: 'Usuário inexistente (105)',
        description: 'Não existe um usuário correspondente ao token',
      },
      106: {
        message: 'Acesso não autorizado (106)',
        description: 'Este usuário não tem nenhum papel',
      },
      107: {
        message: 'Acesso não autorizado (107)',
        description:
          'O papel do usuário é insuficiente para acessar este recurso',
      },
      108: {
        message: 'Dados inexistentes (108)',
        description: 'Nenhum dado foi encontrado para este usuário',
      },
      109: {
        message: 'Permissão inexistente (109)',
        description: 'Nenhuma permissão foi encontrada',
      },
      110: {
        message: 'Acesso não autorizado (110)',
        description:
          'O usuário não tem a permissão necessária para acessar este recurso',
      },
      111: {
        message: 'Ação bloqueada (111)',
        description: 'O usuário não pode realizar esta ação para este recurso',
      },
      112: {
        message: 'Falha ao atualizar o token de acesso (112)',
        description:
          'Para atualizar o seu token de acesso é necessário informar o token de renovação no cabeçalho da requisição',
      },
      113: {
        message: 'Dados de cabeçalho inválidos (113)',
        description:
          'Os tokens de acesso e renovação devem ser informados no cabeçalho',
      },
      114: {
        message: 'Dados de cabeçalho inválidos (114)',
        description: 'O token de renovação é inválido',
      },
      115: {
        message: 'Dados de cabeçalho inválidos (115)',
        description: 'Estes tokens não pertencem ao usuário logado',
      },
      116: {
        message: 'Dados de cabeçalho inválidos (116)',
        description: 'Este token de renovação é antigo',
      },
      117: {
        message: 'Acesso não autorizado (117)',
        description:
          'Não foi possível autenticar o seu usuário. Faça o login novamente para continuar',
      },
      118: {
        message: 'Acesso não autorizado (118)',
        description:
          'O seu usuário não possui permissão para realizar esta ação',
      },

      201: {
        message: 'Usuário não encontrado (201)',
        description: 'Não foi possível encontrar o usuário informado',
      },
      202: {
        message: 'Usuário já registrado (202)',
        description:
          'Já existe um usuário registrado com este email ou documento',
      },
      203: {
        message: 'Usuário não encontrado (203)',
        description:
          'Não foi possível encontrar um usuário com as credenciais informadas',
      },
      204: {
        message: 'Usuário não encontrado (204)',
        description: 'O email ou o número do documento devem ser informados',
      },
      205: {
        message: 'Falha ao atualizar senha (205)',
        description: 'A nova senha não pode ser igual à antiga',
      },
      206: {
        message: 'Falha ao atualizar senha (206)',
        description: 'A nova senha e sua confirmação devem ser iguais',
      },
      207: {
        message: 'Senha incorreta (207)',
        description: 'A senha informada está incorreta',
      },
      208: {
        message: 'Usuário já registrado (208)',
        description: 'Já existe um usuário registrado com este email',
      },
      209: {
        message: 'Permissões não encontradas (209)',
        description: 'Nenhuma permissão foi encontrada para este usuário',
      },
      210: {
        message: 'Usuário já registrado (210)',
        description: 'Já existe um usuário registrado com este email',
      },
      211: {
        message: 'E-mail inválido (211)',
        description: 'Este não é um e-mail válido',
      },
      212: {
        message: 'Erro interno (212)',
        description:
          'Banco de dados fora de sincronização. Entre em contato com o suporte imediatamente',
      },
      213: {
        message: 'Falha ao criar usuário (213)',
        description: 'Ocorreu um erro e não foi possível criar o seu usuário',
      },
      214: {
        message: 'Usuário inexistente  (214)',
        description: 'Não existe um usuário com este e-mail',
      },
      215: {
        message: 'Papel de usuário inválido(215)',
        description: 'O papel selecionado para esse usuário é inválido',
      },
      216: {
        message: 'Falha ao criar usuário (216)',
        description:
          'Esta empresa não pode não pode criar um usuário com esse papel',
      },
      217: {
        message: 'Falha ao criar usuário (217)',
        description: 'Só é possível criar um usuário para empresas aprovadas',
      },
      218: {
        message: 'Falha ao desvincular usuário da empresa (218)',
        description:
          'Não foi possível encontrar o usuário ou ele não faz parte da sua hierarquia',
      },
      219: {
        message: 'Falha ao buscar usuários (219)',
        description: 'Não foi possível buscar os dados dos usuários',
      },

      301: {
        message: 'Email em uso (301)',
        description: 'Já existe um usuário cadastrado com este email',
      },
      302: {
        message: 'Falha ao verificar email (302)',
        description: 'Não foi possível encontrar e verificar o seu email',
      },
      303: {
        message: 'Email em uso (303)',
        description: 'Já existe um usuário cadastrado com este email',
      },
      304: {
        message: 'Email inválido (304)',
        description: 'Este é um email inválido',
      },
      305: {
        message: 'Email em uso (305)',
        description: 'Já existe um usuário logado com este email',
      },

      401: {
        message: 'Endereço não encontrado (401)',
        description: 'Não foi possível encontrar o Estado informado',
      },
      402: {
        message: 'Endereço não encontrado (402)',
        description: 'Não foi possível encontrar o CEP informado',
      },

      501: {
        message: 'Empresa não encontrada (501)',
        description:
          'Não foi possível encontrar uma empresa com o número de documento informado',
      },
      502: {
        message: 'Dados incompletos (502)',
        description:
          'Os campos de email e telefone são obrigatórios, por favor preencha-os e tente novamente',
      },
      503: {
        message: 'Empresa com dados inválidos (503)',
        description:
          'Não foi possível encontrar nenhum usuário vinculado a esta empresa',
      },
      504: {
        message: 'Empresa não encontrada (504)',
        description:
          'Não foi possível encontrar uma empresa com o código informado',
      },
      505: {
        message: 'Falha no envio do arquivo (505)',
        description:
          'É necessário informar uma empresa ou usuário para enviar um arquivo',
      },
      506: {
        message: 'Falha no envio do arquivo (506)',
        description:
          'Não é possível enviar uma apólice para uma empresa e um usuário ao mesmo tempo',
      },
      507: {
        message: 'Endereço não encontrado (507)',
        description: 'Não foi possível encontrar um endereço para esta empresa',
      },
      508: {
        message: 'Contato não encontrado (508)',
        description: 'Não foi possível encontrar um contato para esta empresa',
      },
      509: {
        message: 'Dados inválidos (509)',
        description:
          'Não é possível inserir áreas de operação para empresas com função Embarcador',
      },
      510: {
        message: 'Dados inválidos (510)',
        description:
          'Não é possível cadastrar uma empresa do tipo Matriz vinculada à outra empresa do mesmo tipo',
      },
      511: {
        message: 'Empresa não encontrada (511)',
        description:
          'Não foi possível encontrar uma empresa para vincular o seu cadastro',
      },
      512: {
        message: 'Dados inválidos (512)',
        description:
          'Não é possível vincular uma empresa do tipo Unidade como superior à qualquer outra',
      },
      513: {
        message: 'Dados inválidos (513)',
        description:
          'Só é possível vincular uma empresa do tipo Filial como superior a uma empresa do tipo Unidade',
      },
      514: {
        message: 'Empresa já cadastrada (514)',
        description:
          'Já existe uma empresa cadastrada com o número de documento informado',
      },
      516: {
        message: 'Dados inválidos (516)',
        description:
          'Uma empresa do tipo Unidade só pode utilizar um número de documento já cadastrado, caso ele seja igual ao da sua empresa superior',
      },
      517: {
        message: 'Dados inválidos (517)',
        description:
          'Uma empresa do tipo Unidade não pode retificar seus dados',
      },
      518: {
        message: 'Dados inválidos (518)',
        description:
          'Uma empresa não pode ter o mesmo documento que sua empresa superior',
      },
      519: {
        message: 'Dados inválidos (519)',
        description:
          'Uma empresa não pode ter o mesmo documento que sua empresa superior',
      },
      520: {
        message: 'Dados inválidos (520)',
        description:
          'Apenas empresas do tipo Unidade podem ter a mesma identificação que sua empresa superior',
      },
      521: {
        message: 'Dados inválidos (521)',
        description: 'Apenas empresas reprovadas podem corrigir seus dados',
      },
      522: {
        message: 'Falha ao delegar cotação (522)',
        description:
          'Não foi possível encontrar a empresa ou ela está fora da sua hierarquia',
      },
      523: {
        message: 'Falha ao desvincular usuário da empresa (523)',
        description:
          'Não foi possível encontrar a empresa ou ela está fora da sua hierarquia',
      },
      524: {
        message: 'Empresa não encontrada (524)',
        description:
          'Não foi possível encontrar uma empresa com o número de documento informado',
      },

      601: {
        message: 'Dados inválidos (601)',
        description: 'O remetente não existe',
      },
      602: {
        message: 'Dados inválidos (602)',
        description: 'O destinatário não existe',
      },

      701: {
        message: 'Local não encontrado (701)',
        description:
          'Não foi possível encontrar um local com o identificador informado',
      },
      702: {
        message: 'Município inválido (702)',
        description: 'O município não pertence à UF informada',
      },
      703: {
        message: 'UF inválida (703)',
        description: 'Não foi possível encontrar a UF informada',
      },
      704: {
        message: 'Município inválido (704)',
        description: 'Não foi possível encontrar o município informado',
      },
      705: {
        message: 'Local não encontrado (705)',
        description:
          'Não foi possível encontrar um local com o as especificações informadas',
      },

      801: {
        message: 'Falha no envio (801)',
        description: 'O arquivo enviado possui uma extensão não suportada',
      },
      802: {
        message: 'Falha no envio (802)',
        description:
          'O arquivo enviado não pode possuir um tamanho superior a 5 Mb',
      },

      901: {
        message: 'Não foi possível encontrar o status da empresa (901)',
        description: '',
      },
      902: {
        message: 'Não foi possível encontrar o status da empresa (902)',
        description: '',
      },
      903: {
        message: 'Usuário não encontrado (903)',
        description: 'Não foi possível encontrar os dados deste usuário',
      },
      904: {
        message: 'Esta empresa não pode ser aprovada (904)',
        description: 'O status atual desta empresa não permite a aprovação',
      },
      905: {
        message: 'Esta empresa não pode ser reprovada (905)',
        description: 'O status atual desta empresa não permite a reprovação',
      },
      906: {
        message: 'Falha ao tentar aprovar a empresa (906)',
        description:
          'Durante o processo de aprovação, ocorreu um erro e a empresa não foi aprovada',
      },
      907: {
        message: 'Falha ao tentar reprovar a empresa (907)',
        description:
          'Durante o processo de reprovação, ocorreu um erro e a empresa não foi reprovada',
      },
      908: {
        message: 'Acesso não autorizado (908)',
        description: 'Você não possui permissão para realizar esta ação',
      },
      909: {
        message: 'Falha ao tentar aprovar a empresa (909)',
        description:
          'Para aprovar essa empresa é necessário que a empresa mãe seja aprovada antes',
      },

      1001: {
        message: 'Papel não encontrado (1001)',
        description: 'O papel informado não existe',
      },
      1002: {
        message: 'Papel não encontrado (1002)',
        description: 'Alguns dos papeis informados não existem',
      },
      1003: {
        message: 'Papel não encontrado (1003)',
        description: 'O usuário não tem nenhum dos papéis pesquisados',
      },
      1004: {
        message: 'Falha ao vincular papel ao usuário (1004)',
        description: 'O papel selecionado já está vinculado à este usuário',
      },
      1005: {
        message: 'Papel e usuário incompatíveis (1005)',
        description: 'Este papel e usuário não estão ligados',
      },
      1006: {
        message: 'Campo obrigatório (1006)',
        description: 'É obrigatório preencher ao menos um campo',
      },
      1007: {
        message: 'Papel inexistente (1007)',
        description: 'Um papel com as especificações informadas não existe',
      },

      1101: {
        message: 'Apólice inexistente (1101)',
        description: 'A apólice informada não existe',
      },

      1201: {
        message: 'Reprovação inexistente (1201)',
        description: 'A reprovação não foi encontrada',
      },
      1202: {
        message: 'Reprovação inexistente (1202)',
        description: 'Esta empresa não possui nenhuma reprovação',
      },

      1301: {
        message: 'Rota inexistente (1301)',
        description: 'A rota não foi encontrada',
      },
      1302: {
        message: 'Rota não encontrada (1302)',
        description: 'A rota não pertence à esta empresa',
      },

      1401: {
        message: 'Usuário inexistente (1401)',
        description: 'Não foi possível encontrar o usuário',
      },
      1402: {
        message: 'Código inválido (1402)',
        description: 'O código de recuperação informado é inválido',
      },
      1403: {
        message: 'Código inválido (1403)',
        description: 'O código de ativação informado é inválido',
      },
      1404: {
        message: 'Token inválido (1404)',
        description: 'Você não possui um token de recuperação ativo',
      },
      1405: {
        message: 'Token inválido (1405)',
        description: 'Você não possui um token de ativação ativo',
      },

      1501: {
        message: 'Erro ao criar módulo (1501)',
        description: 'Não foi possível criar um dos módulos passados',
      },
      1502: {
        message: 'Módulo não encontrado (1502)',
        description: 'Não foi possível encontrar os módulos',
      },
      1503: {
        message: 'Módulo não encontrado (1503)',
        description: 'Não foi possível encontrar o módulo informado',
      },
      1504: {
        message: 'Módulo já existente (1504)',
        description: 'Um módulo com este nome já existe',
      },
      1505: {
        message: 'Módulo não encontrado (1505)',
        description: 'Não foi possível encontrar o módulo informado',
      },

      1601: {
        message: 'Erro ao criar permissão (1601)',
        description: 'Não foi possível criar uma das permissões passadas',
      },
      1602: {
        message: 'Permissão não encontrada (1602)',
        description: 'Não foi possível encontrar as permissões',
      },

      1701: {
        message: 'Erro ao criar modelo de permissões (1701)',
        description: 'O papel não foi encontrado',
      },
      1702: {
        message: 'Erro ao criar modelo de permissões (1702)',
        description:
          'Não foi possível criar o modelo de permissões, verifique os dados e tente novamente',
      },
      1703: {
        message: 'Erro ao criar modelo de permissões (1703)',
        description:
          'Não foi possível encontrar a permissão com o papel informado',
      },
      1704: {
        message: 'Erro ao excluir o modelo de permissões (1704)',
        description:
          'Não foi possível excluir o modelo de permissões informado',
      },
      1705: {
        message: 'Dados inválidos (1705)',
        description: 'É obrigatório informar ao menos um dos dados requeridos',
      },
      1706: {
        message: 'Falha ao adicionar permissões (1706)',
        description:
          'Uma ou mais permissões não podem ser adicionadas a um usuário com esse papel',
      },

      1801: {
        message: 'Erro ao relacionar usuário e permissão (1801)',
        description: 'Não foi possível criar relação de usuário com permissão',
      },
      1802: {
        message: 'Permissões de usuários não encontradas (1802)',
        description: 'Não foi possível encontrar as permissões do usuário',
      },
      1803: {
        message: 'Permissões não informadas (1803)',
        description: 'É obrigatório informar as permissões',
      },
      1804: {
        message: 'Modelo de permissões incorreto (1804)',
        description: 'O usuário não possui este modelo de permissões',
      },
      1805: {
        message: 'Falha ao adicionar permissões de usuário (1805)',
        description:
          'Não foi possível encontrar um papel para o usuário informado',
      },
      1806: {
        message: 'Falha ao atualizar as permissões de usuário (1806)',
        description:
          'Ocorreu um erro e não foi possível atualizar as permissões do usuário',
      },

      1901: {
        message: 'Nenhum tipo de produto foi informado (1901)',
        description: 'É necessário informar ao menos um tipo de produto',
      },
      1902: {
        message: 'Falha ao criar novos tipos de produto (1902)',
        description:
          'Ocorreu um erro e não foi possível criar os tipos de produto',
      },
      1903: {
        message: 'Falha ao buscar por tipos de produto (1903)',
        description:
          'Ocorreu um erro e não foi possível buscar por tipos de produto',
      },
      1904: {
        message: 'Falha ao excluir um tipo de produto (1904)',
        description:
          'Ocorreu um erro e não foi possível excluir o tipo de produto',
      },
      1905: {
        message: 'Tipo de produto inexistente (1905)',
        description: 'Não foi possível encontrar o tipo de produto informado',
      },

      2001: {
        message: 'Dados inválidos (2001)',
        description: 'É necessário informar ao menos um nome de embalagem',
      },
      2002: {
        message: 'Falha ao criar embalagem (2002)',
        description: 'Não foi possível criar uma das embalagens',
      },
      2003: {
        message: 'Embalagem inexistente (2003)',
        description: 'Não foi possível encontrar a embalagem especificada',
      },
      2004: {
        message: 'Falha ao excluir uma embalagem (2004)',
        description: 'Ocorreu um erro e não foi possível excluir a embalagem',
      },

      2101: {
        message: 'Dados inválidos (2101)',
        description:
          'É necessário informar ao menos uma relação entre tipo de produto e embalagem',
      },
      2102: {
        message: 'Falha ao relacionar tipo de produto à embalagem (2102)',
        description:
          'Não foi possível criar a relação entre o tipo de produto e a embalagem',
      },
      2103: {
        message: 'Erro ao pesquisar (2103)',
        description:
          'Não foi possível realizar a pesquisa, revise os dados e tente novamente',
      },
      2104: {
        message: 'Erro ao excluir (2104)',
        description:
          'Não foi possível excluir relação entre tipo de produto e embalagem',
      },
      2105: {
        message: 'Embalagem não encontrada (2105)',
        description:
          'Não foi possível encontrar a embalagem para o tipo de produto selecionado',
      },

      2201: {
        message: 'Erro ao criar produtos (2201)',
        description: 'Não foi possível criar nenhum dos produtos',
      },
      2202: {
        message: 'Produtos duplicados (2202)',
        description: 'Todos os produtos estão duplicados',
      },
      2203: {
        message: 'Produto não encontrado (2203)',
        description: 'Não foi possível encontrar o produto especificado',
      },
      2204: {
        message: 'Falha ao excluir um produto (2204)',
        description: 'Ocorreu um erro e não foi possível excluir o produto',
      },
      2205: {
        message: 'Tipo de produto incompatível (2205)',
        description:
          'Estes produtos não possuem o tipo de produto especificado',
      },

      2301: {
        message: 'Frete não encontrado (2301)',
        description: 'Não foi possível encontrar um frete com o id informado',
      },
      2302: {
        message: 'Frete não encontrado (2302)',
        description:
          'Não foi possível encontrar um frete com o número informado',
      },
      2303: {
        message: 'Data de embarque inválida (2303)',
        description: 'Verifique o valor inserido e tente novamente',
      },
      2304: {
        message: 'Data de embarque inválida (2304)',
        description:
          'A data de embarque deve ser anterior à data de desembarque',
      },
      2305: {
        message: 'Empresa não encontrada (2305)',
        description:
          'Não foi possível encontrar a empresa informada para vincular o frete',
      },
      2306: {
        message: 'Usuário não encontrado (2306)',
        description:
          'Não foi possível encontrar o usuário informado para vincular o frete',
      },
      2307: {
        message: 'Falha ao criar o frete (2307)',
        description: 'Ocorre um erro e não foi possível criar o frete',
      },
      2308: {
        message: 'Falha ao excluir frete (2308)',
        description:
          'O frete não pode ser excluído se estiver com status "Finalizado" ou "Carregando"',
      },
      2309: {
        message: 'Frete não encontrado (2309)',
        description: 'O frete em cotação especificado não existe',
      },
      2310: {
        message: 'Dados inválidos (2310)',
        description:
          'O tempo limite de cotação não pode ser menor que 2 horas ou maior que 1 semana',
      },
      2311: {
        message: 'Falha ao criar um frete (2311)',
        description:
          'Não é possível criar um novo frete vinculado ao frete selecionado',
      },
      2312: {
        message: 'Falha ao criar um frete (2312)',
        description:
          'O número máximo de grupos de transportadoras para um frete é 10',
      },
      2313: {
        message: 'Falha ao criar um frete (2313)',
        description:
          'Um ou mais grupos de transportadoras selecionados não pertencem à sua empresa',
      },

      2401: {
        message: 'Dados inválidos (2401)',
        description: 'É obrigatório preencher ao menos um dos campos',
      },
      2402: {
        message: 'Veículo inexistente (2402)',
        description:
          'Não foi possível encontrar o veículo com o nome e/ou id informado',
      },
      2403: {
        message: 'Veículo inexistente (2403)',
        description: 'Não foi possível encontrar o veículo com o id informado',
      },

      2501: {
        message: 'Arquivo inválido (2501)',
        description: 'Apenas arquivos com a extensão .xml são aceitos',
      },
      2502: {
        message: 'CTE inexistente (2502)',
        description: 'Não foi possível encontrar o CTE',
      },
      2503: {
        message: 'Erro ao ler CTE (2503)',
        description: 'Ocorreu um erro ao tentar ler o CTE',
      },
      2504: {
        message: 'Erro ao fazer upload de CTE (2504)',
        description: 'Ocorreu um erro ao tentar fazer o upload do CTE',
      },

      2601: {
        message: 'Cotação inexistente (2601)',
        description: 'Não foi possível encontrar a cotação',
      },
      2602: {
        message: 'Transportadora inexistente (2602)',
        description: 'Não existe a transportadora especificada',
      },
      2603: {
        message: 'Dados inválidos (2603)',
        description:
          'O volume da cotação não pode ser maior que o volume do frete',
      },
      2604: {
        message: 'Dados inválidos (2604)',
        description:
          'O valor total não pode ser menor que o valor por tonelada',
      },
      2605: {
        message: 'Usuário inexistente (2605)',
        description:
          'Não existe o usuário especificado para ser o criador da cotação',
      },
      2606: {
        message: 'Empresa inexistente (2606)',
        description: 'Não existe a empresa especificada para ser o embarcador',
      },
      2607: {
        message: 'Frete inexistente (2607)',
        description: 'Não existe nenhum frete como o especificado',
      },
      2608: {
        message: 'Cotação inexistente (2608)',
        description:
          'Não existe nenhuma cotação com este id ou relacionada com este frete',
      },
      2609: {
        message: 'Status inválido (2609)',
        description: "O status da cotação deve ser 'Esperando'",
      },
      2610: {
        message: 'Volumes inválidos (2610)',
        description:
          'O volume proposto não pode ser maior que o volume não alocado',
      },
      2611: {
        message: 'Volumes inválidos (2611)',
        description:
          'O volume proposto não pode ser maior que o volume total do frete',
      },
      2612: {
        message: 'Erro ao contratar cotação (2612)',
        description: 'Ocorreu um erro ao contratar a cotação, tente novamente',
      },
      2613: {
        message: 'Cotação inexistente (2613)',
        description:
          'Não existe nenhuma cotação que pode ser confirmada com este id',
      },
      2614: {
        message: 'Erro ao confirmar cotação (2614)',
        description: 'Ocorreu um erro ao confirmar a cotação, tente novamente',
      },
      2615: {
        message: 'Cotações inexistentes (2615)',
        description:
          'Não existe nenhuma cotação para este usuário que pode ser carregada',
      },
      2616: {
        message: 'Cotações inexistentes (2616)',
        description:
          'Não existe nenhuma cotação para este usuário que pode ser finalizada',
      },
      2617: {
        message: 'Erro ao finalizar cotação (2617)',
        description: 'Ocorreu um erro ao finalizar a cotação, tente novamente',
      },
      2618: {
        message: 'Erro ao abandonar cotação (2618)',
        description: 'Ocorreu um erro ao abandonar a cotação, tente novamente',
      },
      2619: {
        message: 'Dados inválidos (2619)',
        description: 'O usuário informado não é o dono desta cotação',
      },
      2620: {
        message: 'Erro ao renunciar a cotação (2620)',
        description: 'Ocorreu um erro ao renunciar a cotação, tente novamente',
      },
      2621: {
        message: 'Cotações inexistentes (2621)',
        description: 'Não existe nenhuma cotação para ser cancelada',
      },
      2622: {
        message: 'Erro ao cancelar cotação (2622)',
        description: 'Ocorreu um erro ao cancelar a cotação, tente novamente',
      },
      2623: {
        message: 'Falha ao delegar a cotação (2623)',
        description:
          'Só é possível delegar uma cotação que está contratada e ainda não foi confirmada',
      },

      2701: {
        message: 'Erro ao ler CTE (2701)',
        description: 'Ocorreu um erro ao tentar ler o CTE, tente novamente',
      },
      2702: {
        message: 'Dados inválidos (2702)',
        description:
          'O volume carregado só pode exceder o volume não alocado em 10%',
      },
      2703: {
        message: 'Erro ao carregar (2703)',
        description: 'Ocorreu um erro ao tentar carregar, tente novamente',
      },
      2704: {
        message: 'Erro ao excluir CTE (2704)',
        description: 'Não foi possível excluir o CTE, tente novamente',
      },
      2705: {
        message: 'Erro ao descarregar (2705)',
        description:
          'Ocorreu um erro ao fazer o descarregamento, tente novamente',
      },

      2801: {
        message: 'Erro ao criar razão de cancelamento (2801)',
        description:
          'Ocorreu um erro ao criar a razão de cancelamento, tente novamente',
      },
      2802: {
        message: 'Dados inválidos (2802)',
        description:
          'Não foi possível encontrar uma razão de cancelamento com os dados especificados',
      },
      2803: {
        message: 'Erro ao editar razão de cancelamento (2803)',
        description:
          'Ocorreu um erro ao tentar editar a razão de cancelamento, tente novamente',
      },
      2804: {
        message: 'Erro ao excluir razão de cancelamento (2804)',
        description:
          'Ocorreu um erro ao tentar excluir o razão de cancelamento, tente novamente',
      },
      2805: {
        message: 'Razão de cancelamento inexistente (2805)',
        description: 'Não existe uma razão de cancelamento como a especificada',
      },

      2901: {
        message: 'Motivo de recusa inexistente (2901)',
        description: 'Não existe um motivo de recusa como o especificado',
      },
      2902: {
        message: 'Erro ao criar motivo de recusa (2902)',
        description:
          'Ocorreu um erro ao tentar criar o motivo de recusa, tente novamente',
      },

      3001: {
        message: 'Cancelamento inexistente (3001)',
        description: 'Não existe um cancelamento como o especificado',
      },

      3101: {
        message: 'Recusa inexistente (3101)',
        description: 'Não existe uma recusa como a especificada',
      },

      3201: {
        message: 'Razão de avaliação inexistente (3201)',
        description: 'Não existe uma razão de avaliação como a especificada',
      },
      3202: {
        message: 'Erro ao criar razão de avaliação (3202)',
        description:
          'Ocorreu um erro ao tentar criar a razão de avaliação, tente novamente',
      },
      3203: {
        message: 'Erro ao editar razão de avaliação (3203)',
        description:
          'Ocorreu um erro ao tentar editar a razão de avaliação, tente novamente',
      },
      3204: {
        message: 'Erro ao excluir razão de avaliação (3204)',
        description:
          'Ocorreu um erro ao tentar excluir a razão de avaliação, tente novamente',
      },

      3301: {
        message: 'Empresa inexistente (3301)',
        description:
          'Não existe uma empresa como a especificada para ser um avaliador',
      },
      3302: {
        message: 'Empresa inexistente (3302)',
        description:
          'Não existe uma empresa como a especificada para ser avaliada',
      },
      3303: {
        message: 'Razão de avaliação inexistente (3303)',
        description:
          'Não foi possível encontrar uma razão de avaliação como a especificada',
      },
      3304: {
        message: 'Erro ao criar de avaliação de frete (3304)',
        description:
          'Ocorreu um erro ao tentar criar uma nova avaliação de frete, tente novamente',
      },
      3305: {
        message: 'Avaliação de frete inexistente (3305)',
        description: 'Não existe uma avaliação de frete como a especificada',
      },

      3401: {
        message: 'Avaliação de empresa inexistente (3401)',
        description: 'Não existe uma avaliação de empresa como a especificada',
      },
      3402: {
        message: 'Sistema de avaliação inexistente (3402)',
        description: 'Não foi possível encontrar o sistema de avaliação',
      },

      3501: {
        message: 'Avaliação de cotação inexistente (3501)',
        description: 'Não existe uma avaliação de cotação como a especificada',
      },
      3502: {
        message: 'Embarcador inexistente (3502)',
        description:
          'Não existe um embarcador como o especificado para ser um avaliador',
      },
      3503: {
        message: 'Transportadora inexistente (3503)',
        description:
          'Não existe uma transportadora como a especificada para ser avaliada',
      },
      3504: {
        message: 'Cotação inexistente (3504)',
        description:
          'Não existe uma cotação como a especificada para ser avaliada',
      },
      3505: {
        message: 'Razão de avaliação inexistente (3505)',
        description:
          'Não foi possível encontrar uma razão de avaliação como a especificada',
      },
      3506: {
        message: 'Dados inválidos (3506)',
        description: 'A razão e sua descrição devem ser inseridas juntas',
      },
      3507: {
        message: 'Erro ao criar avaliação de cotação (3507)',
        description:
          'Ocorreu um erro ao tentar criar uma avaliação de cotação e editar os dados da avaliação',
      },

      3601: {
        message: 'Confirmação inexistente (3601)',
        description:
          'Não foi possível encontrar uma confirmação de intenção de frete com o id informado',
      },
      3602: {
        message: 'Falha ao confirmar cotação (3602)',
        description: 'Não foi possível encontrar a cotação para confirmar',
      },
      3603: {
        message: 'Falha ao criar novas confirmações (3603)',
        description:
          'Ocorreu um erro e não foi possível criar novas confirmações de intenção de frete',
      },
      3604: {
        message: 'Falha ao atualizar confirmações (3604)',
        description:
          'Ocorreu um erro e não foi possível atualizar as confirmações de intenção de frete',
      },
      3605: {
        message: 'Falha ao excluir confirmações (3605)',
        description:
          'Não é possível excluir todas as confirmações de uma vez só. Após confirmado, a cotação sempre deve possuir ao menos uma confirmação',
      },
      3606: {
        message: 'Falha ao excluir a confirmação (3606)',
        description:
          'Não é possível excluir uma confirmação que já possui uma CTE vinculada',
      },

      3701: {
        message: 'Falha ao buscar motorista (3701)',
        description:
          'Não foi possível encontrar um motorista com a CNH informada',
      },

      3801: {
        message: 'Falha ao buscar usuários (3801)',
        description: 'Nenhum usuário está vinculado a esta empresa',
      },
      3802: {
        message: 'Falha ao vincular um usuário à empresa (3802)',
        description:
          'Só é possível vincular um usuário a uma empresa que esteja aprovada',
      },
      3803: {
        message: 'Falha ao vincular um usuário à empresa (3803)',
        description:
          'Só é possível vincular um usuário com papel "Transportadora" a uma empresa que possua o mesmo papel',
      },
      3804: {
        message: 'Falha ao vincular um usuário à empresa (3804)',
        description:
          'Só é possível vincular um usuário com papel "Embarcador" a uma empresa que possua o mesmo papel',
      },
      3805: {
        message: 'Falha ao vincular um usuário à empresa (3805)',
        description:
          'Ocorreu um erro e não foi possível vincular um usuário à empresa selecionada',
      },

      3901: {
        message: 'Falha ao buscar um grupo de transportadoras (3901)',
        description:
          'Não foi possível encontrar um grupo de transportadoras com o id informado',
      },
      3902: {
        message: 'Falha ao criar um grupo de transportadoras (3902)',
        description:
          'Um grupo de transportadoras deve possuir apenas empresas do tipo "transportadora"',
      },
      3903: {
        message: 'Falha ao criar/editar um grupo de transportadoras (3903)',
        description:
          'Um grupo de transportadoras deve possuir entre 2 e 20 membros',
      },
      3904: {
        message: 'Falha ao criar um grupo de transportadoras (3904)',
        description:
          'Ocorreu um erro e não foi possível criar um grupo de transportadoras',
      },
      3905: {
        message: 'Falha ao excluir/editar um grupo de transportadoras (3905)',
        description:
          'O grupo que você está tentando excluir/editar não pertence à sua empresa',
      },
      3906: {
        message: 'Falha ao atualizar um grupo de transportadoras (3906)',
        description:
          'Ocorreu um erro e não foi possível atualizar um grupo de transportadoras',
      },
      3907: {
        message: 'Falha ao excluir um grupo de transportadoras (3907)',
        description:
          'Ocorreu um erro e não foi possível excluir um grupo de transportadoras',
      },
      3908: {
        message: 'Falha ao listar os grupos de transportadoras (3908)',
        description:
          'Ocorreu um erro e não foi possível listar os grupos de transportadoras',
      },

      4001: {
        message: 'Falha ao buscar um anexo de frete (4001)',
        description:
          'Não foi possível encontrar um anexo de frete com o id informado',
      },
      4002: {
        message: 'Falha ao anexar arquivo ao frete (4002)',
        description: 'Esse frete não está disponível para o anexo de arquivos',
      },
      4003: {
        message: 'Falha ao anexar arquivo ao frete (4003)',
        description:
          'O seu usuário não possui permissão para anexar um arquivo nesse frete',
      },
      4004: {
        message: 'Falha ao anexar arquivo ao frete (4004)',
        description:
          'Ocorreu um erro e não foi possível anexar o arquivo ao frete',
      },
      4005: {
        message: 'Falha ao anexar arquivo ao frete (4005)',
        description:
          'Somente arquivos em PDF (.pdf) e imagens (.jpg, .png) podem ser anexados ao frete',
      },
      4006: {
        message: 'Falha ao listar anexos de frete (4006)',
        description:
          'Ocorreu um erro e não foi possível listar os anexos do frete',
      },
      4007: {
        message: 'Falha ao remover anexo de frete (4007)',
        description:
          'Ocorreu um erro e não foi possível remover o anexo do frete',
      },

      4101: {
        message: 'Falha ao buscar um arquivo (4101)',
        description: 'Não foi possível encontrar um arquivo com o id informado',
      },
      4102: {
        message: 'Falha ao enviar arquivo (4102)',
        description:
          'Ocorreu um erro e não foi possível fazer o envio do arquivo',
      },

      4201: {
        message: 'Falha ao buscar um tema (4201)',
        description: 'Não foi possível encontrar um tema com o id informado',
      },
      4202: {
        message: 'Tema inválido (4202)',
        description: 'A configuração do tema é invalido',
      },
      4203: {
        message: 'Falha ao criar um tema (4203)',
        description: 'Ocorreu um erro e não foi possível criar um tema',
      },
      4204: {
        message: 'Falha ao atualizar um tema (4204)',
        description: 'Ocorreu um erro e não foi possível atualizar um tema',
      },
      4205: {
        message: 'Falha ao excluir um tema (4205)',
        description: 'Ocorreu um erro e não foi possível excluir um tema',
      },
      4206: {
        message: 'Falha ao listar os temas (4206)',
        description: 'Ocorreu um erro e não foi possível listar os temas',
      },

      4301: {
        message: 'Falha ao buscar as configurações de empresa (4301)',
        description:
          'Não foi possível encontrar uma configuração de empresa com o id informado',
      },
      4302: {
        message: 'Falha ao atualizar configurações de empresa (4302)',
        description:
          'Ocorreu um erro e não foi possível atualizar as configurações da sua empresa',
      },

      4401: {
        message: 'Falha ao buscar uma variedade de produto (4401)',
        description:
          'Não foi possível encontrar uma variedade de produto com o id informado',
      },
      4402: {
        message: 'Falha ao criar uma variedade de produto (4402)',
        description:
          'Ocorreu um erro e não foi possível criar uma variedade de produto',
      },
      4403: {
        message: 'Falha ao buscar variedades de produto (4403)',
        description:
          'Ocorreu um erro e não foi possível buscar as variedades de um produto',
      },
      4404: {
        message: 'Falha ao excluir uma variedade de produto (4404)',
        description:
          'Ocorreu um erro e não foi possível excluir a variedade de produto',
      },
    },
  },
};
export default br;
