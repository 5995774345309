import { Key } from 'react';
import { IdDto } from '../../../../graphql/generated/graphql';

export const toRelatedGroups = (selectedRowKeys: Key[]) => {
  return selectedRowKeys.map(key => ({ id: String(key) }));
};

export const toSelectedRowKeys = (related_groups: IdDto[]) => {
  return related_groups.map(({ id }) => id);
};
