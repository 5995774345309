import { Col, Row } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { ListRoutes } from '../../../../components/Freight/Route/ListRoutes/ListRoutes';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';

export const RoutesPage = (): ReactElement => {
  const ROUTES_PAGE_TRANSLATION_PATH = 'pages.freight.route.routesPage';

  const { t } = useTranslation();

  useTitle({ title: `${ROUTES_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/routes'],
  });

  return (
    <>
      <DefaultPageHeader
        subTitle={t(`${ROUTES_PAGE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${ROUTES_PAGE_TRANSLATION_PATH}.title`)}
      />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <Col span={24}>
          <ListRoutes />
        </Col>
      </Row>
    </>
  );
};

const RoutesPageAccess = Access(RoutesPage, {
  acceptedPermissions: [
    {
      module: 'ROUTES',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default RoutesPageAccess;
