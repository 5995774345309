import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  AttachmentsTableModal_DetachFileMutation,
  useAttachmentsTableModal_DetachFileMutation,
  useAttachmentsTableModal_FindAllAttachmentsQuery,
} from '../../../../../graphql/generated/graphql';
import { useAttachmentModal } from '../../../attachments/AttachmentModal/AttachmentModal.hooks';

export const useAttachmentsTableModal = () => {
  const { freightId } = useParams();

  const variables = freightId ? { freightId } : undefined;

  const [queryResult, executeQuery] =
    useAttachmentsTableModal_FindAllAttachmentsQuery({
      variables,
      pause: !variables,
    });

  const [mutationResult, executeMutation] =
    useAttachmentsTableModal_DetachFileMutation();

  const { isOpen, handlers } = useAttachmentModal();

  const onDelete = useCallback(
    (fileId: string) => {
      return new Promise<AttachmentsTableModal_DetachFileMutation>(
        (resolve, reject) => {
          if (freightId) {
            executeMutation({
              input: {
                freight_id: freightId,
                attachment_id: fileId,
              },
            }).then(({ data, error }) => {
              if (error) {
                return reject(error);
              }

              if (data) {
                executeQuery();
                return resolve(data);
              }

              return reject();
            });
          } else {
            return reject();
          }
        }
      );
    },
    [executeMutation, executeQuery, freightId]
  );

  const fetching = queryResult.fetching || mutationResult.fetching;
  const error = queryResult.error || mutationResult.error;

  return {
    error,
    fetching,
    isOpen,
    result: queryResult,
    executeQuery,
    handlers: {
      ...handlers,
      onDelete,
    },
  };
};
