import { Form, Modal, ModalProps } from 'antd';
import { ReactElement } from 'react';
import {
  DisapproveCompanyForm,
  DisapproveCompanyModalFooter,
  IDisapproveCompanyFormValues,
} from './DisapproveCompanyModal.components';

export interface IDisapproveCompanyModalProps
  extends Omit<
    ModalProps,
    | 'cancelButtonProps'
    | 'children'
    | 'okButtonProps'
    | 'okText'
    | 'onCancel'
    | 'onOk'
  > {
  onOk: (formValues: IDisapproveCompanyFormValues) => void;
  onCancel: () => void;
}

export const DisapproveCompanyModal = ({
  onOk,
  onCancel,
  ...props
}: IDisapproveCompanyModalProps): ReactElement => {
  const [form] = Form.useForm<IDisapproveCompanyFormValues>();

  return (
    <Modal
      {...props}
      onCancel={onCancel}
      footer={
        <DisapproveCompanyModalFooter
          onCancel={onCancel}
          onConfirm={form.submit}
        />
      }
    >
      <DisapproveCompanyForm form={form} onFinish={onOk} />
    </Modal>
  );
};
