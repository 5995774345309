import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { createClient, debugExchange, dedupExchange } from 'urql';

export const getDefaultRequestHeaders = (accessToken?: string) => {
  const headers: Record<string, string> = {
    'Access-Control-Request-Header': 'Authorization',
    'x-origin-request': 'Manager-panel',
    'request-source': 'panel',
  };

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return headers;
};

const getDefaultExchanges = () => {
  const defaultExchanges = [dedupExchange, multipartFetchExchange];

  if (process.env.NODE_ENV === 'development') {
    defaultExchanges.push(debugExchange);
  }

  return defaultExchanges;
};

interface IClientOptions {
  accessToken?: string;
}

export const getApiUrl = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  if (!API_URL) {
    throw new Error('Environment variable `API_URL` is not defined');
  }

  return API_URL;
};

export const getFetchOptions = (accessToken?: string) => ({
  headers: getDefaultRequestHeaders(accessToken),
});

export const createUrqlClient = (clientOptions?: IClientOptions) => {
  return createClient({
    url: getApiUrl(),
    fetchOptions: getFetchOptions(clientOptions?.accessToken),
    maskTypename: true,
    exchanges: [...getDefaultExchanges()],
  });
};

const client = createClient({
  url: getApiUrl(),
  fetchOptions: getFetchOptions(),
  maskTypename: true,
  exchanges: [...getDefaultExchanges()],
});

export default client;
