import { Button as AntButton, ButtonProps, ConfigProvider } from 'antd';
import mainTheme from '../../../layouts/MainLayout/mainTheme';

export type IButtonPropsType =
  | 'default'
  | 'primary'
  | 'ghost'
  | 'dashed'
  | 'link'
  | 'text'
  | 'success';

interface IButtonProps extends Omit<ButtonProps, 'type'> {
  /**
   * Which is the main function of button?
   */
  type?: IButtonPropsType;
  /**
   * Button contents
   */
  children?: string;
}

/**
 * Ant design customized button with "Success" type
 */
export const Button = ({
  type = 'default',
  children = '',
  ...props
}: IButtonProps): JSX.Element => {
  if (type === 'success') {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: mainTheme.style.successColor,
          },
        }}
      >
        <AntButton {...props} type="primary">
          {children}
        </AntButton>
      </ConfigProvider>
    );
  }

  return (
    <AntButton {...props} type={type}>
      {children}
    </AntButton>
  );
};

export default Button;
