import { ReactElement } from 'react';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, FormItemProps, Input, InputProps } from 'antd';

import Normalizer from '../../classes/Normalizer';
import { minRule, requiredField } from '../../utils/inputRules';

import { useTranslation } from 'react-i18next';
import { IDefaultInputInterface, IFieldRules } from '../../utils/interfaces';

interface IInputText {
  max?: number;
  applyMinimumRule?: boolean;
  otherPropsFormItem?: Record<string, unknown>;
  formStyle?: Record<string, never>;
}

/**
 * @description Text input for forms that use AntDesign.
 * @property {number} [max=100] Field max length value.
 * @property {string} name Name of the key that will be returned in the object.
 * @property {string} [type="password"] If type equals password, a input with eyes icon is rendered.
 * @property {string} [extra=''] Extra props for Form.Item.
 * @property {string} [label=null] Label over the input.
 * @property {object} [field={ name: "name", fieldKey: "key" }] Necessary if use dynamics Form.
 * @property {Node} tooltip Config tolltip info.
 * @property {boolean} formItem If true return a <Form.Item /> component, otherwise return a <Input />,
 * @property {object} formStyle <Form.Item /> style object.
 * @property {bool} [validation=false] If the form will be validated.
 * @property {string} initialValue Field initialValue.
 * @property {array} [myOwnRules=[]] Specific rules in the input.
 * @property {array} [otherRules=[]] More rules to add to the rules.
 * @property {boolean} applyMinimumRule If true apply min rule, minlegnth === 2,
 * @property {boolean} [required=true] If field is required.
 * @property {object} otherPropsFormItem <Form.Item /> otherProps.
 * @property {func} normalize Function called to normalize the field value.
 *
 * @example
 * <InputText
 *   formItem
 *   validation
 *   name="example"
 *   label="Example"
 *   placeholder="Example"
 * />
 */

const InputText = (
  props: IDefaultInputInterface & IInputText & FormItemProps & InputProps
): ReactElement => {
  const { t } = useTranslation();

  const {
    max,
    name,
    type,
    extra,
    label,
    field,
    tooltip,
    namePath,
    formItem,
    formStyle,
    validation,
    initialValue,
    otherRules = [],
    myOwnRules = [],
    applyMinimumRule,
    otherPropsFormItem,
    normalize = value => value,
    required = true,
    ...otherProps
  } = props;

  let rules: IFieldRules = [];

  if (validation) {
    rules = [];

    if (required) rules.push(requiredField);

    if (applyMinimumRule) rules.push(minRule);

    if (otherRules.length > 0) {
      rules.push(...otherRules);
    }

    if (myOwnRules.length > 0) {
      rules = myOwnRules;
    }

    rules = rules.map(r => {
      return {
        ...r,
        message: r.message && t(r.message),
      };
    });
  }

  const normalizer = (v: string) => {
    const formattedValue = Normalizer.removesMultiplesSpacesForOne(v);
    return normalize(formattedValue);
  };

  let formName: string | [string, string] | string[] = name;
  if (namePath != null) formName = namePath;
  let fieldKey = props.fieldKey;
  if (field) {
    formName = [field.name, name];
    fieldKey = [field.fieldKey, name];
  }

  if (formItem) {
    return (
      <Form.Item
        name={formName}
        label={label}
        extra={extra}
        rules={rules}
        validateFirst
        tooltip={tooltip}
        fieldKey={fieldKey}
        normalize={normalizer}
        style={{ ...formStyle }}
        initialValue={initialValue}
        {...otherPropsFormItem}
      >
        {type === 'password' ? (
          <Input.Password
            iconRender={visible =>
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
            {...otherProps}
          />
        ) : (
          <Input name={name} {...otherProps} maxLength={max} />
        )}
      </Form.Item>
    );
  }

  return <Input name={name} {...otherProps} maxLength={max} />;
};

export default InputText;
