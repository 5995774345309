/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormProps, Row, Select } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import { CompanyFunctionSelect } from '../../../../../components/Company/CompanyFunction/CompanyFunctionSelect/CompanyFunctionSelect';
import { CompanySelect } from '../../../../../components/Company/CompanySelect/CompanySelect';
import {
  DefaultRolesEnum,
  FunctionEnum,
  LinkUserToCompanyForm_FindCompaniesQuery,
  LinkUserToCompanyForm_FindManyUsersQuery,
  useLinkUserToCompanyForm_FindCompaniesQuery,
  useLinkUserToCompanyForm_FindManyUsersQuery,
} from '../../../../../graphql/generated/graphql';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';

export interface ILinkUserToCompanyFormValues {
  userId: string;
  companyId: string;
  roleName: DefaultRolesEnum;
}

export interface ILinkUserToCompanyProps
  extends FormProps<ILinkUserToCompanyFormValues> {
  user?: LinkUserToCompanyForm_FindManyUsersQuery['FindManyUsers'][number];
  company?: LinkUserToCompanyForm_FindCompaniesQuery['FindCompanies'][number];
}

export const LinkUserToCompanyForm = ({
  user,
  company,
  ...props
}: ILinkUserToCompanyProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.components.linkUserToCompany',
  });

  const defaultForm = Form.useForm<ILinkUserToCompanyFormValues>()[0];
  const form = props.form || defaultForm;

  const companyId = Form.useWatch('companyId', form);

  const [userSearch, setUserSearch] = useState('');
  const [companySearch, setCompanySearch] = useState('');

  const userSearchDebounced = useDebounce(userSearch, 300);
  const companySearchDebounced = useDebounce(companySearch, 300);

  const [findUsersResult] = useLinkUserToCompanyForm_FindManyUsersQuery({
    variables: { search: userSearchDebounced },
    pause: !!user,
  });

  const [findCompaniesResult] = useLinkUserToCompanyForm_FindCompaniesQuery({
    variables: { search: companySearchDebounced },
    pause: !!company,
  });

  const handleCompanyClear = () => {
    form.setFieldValue('roleName', undefined);
  };

  const usersDataSource = user ? [user] : findUsersResult.data?.FindManyUsers;

  const usersOptions = usersDataSource?.map(user => ({
    label: `${user.email} - ${user.name}`,
    value: user.id,
  }));

  const companiesDataSource = company
    ? [company]
    : findCompaniesResult.data?.FindCompanies;

  useEffect(() => {
    const functionEnumToRole = {
      [FunctionEnum.Carrier]: DefaultRolesEnum.Carrier,
      [FunctionEnum.Shipper]: DefaultRolesEnum.Shipper,
    };

    const selectedCompanyRole = findCompaniesResult.data?.FindCompanies.find(
      company => company.id === companyId
    )?.company_function;

    if (selectedCompanyRole) {
      form.setFieldValue('roleName', functionEnumToRole[selectedCompanyRole]);
    }
  }, [findCompaniesResult.data, companyId]);

  useEffect(() => {
    if (user) form.setFieldValue('userId', user.id);
  }, [user]);

  useEffect(() => {
    if (company) {
      form.setFieldValue('companyId', company.id);
      form.setFieldValue('roleName', company.company_function);
    }
  }, [company]);

  return (
    <Row gutter={[0, 24]}>
      <ErrorAlert error={findUsersResult.error} />
      <ErrorAlert error={findCompaniesResult.error} />
      <Col span={24}>
        <Form layout="vertical" {...props} form={form}>
          <Form.Item
            name="userId"
            required
            rules={[{ required: true }]}
            label={t('form.labels.user')}
          >
            <Select
              allowClear
              disabled={!!user}
              loading={findUsersResult.fetching}
              onSearch={setUserSearch}
              optionFilterProp="label"
              options={usersOptions}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="companyId"
            required
            rules={[{ required: true }]}
            label={t('form.labels.company')}
          >
            <CompanySelect
              allowClear
              disabled={!!company}
              loading={findCompaniesResult.fetching}
              onClear={handleCompanyClear}
              onSearch={setCompanySearch}
              options={companiesDataSource}
              showSearch
            />
          </Form.Item>
          <Form.Item name="roleName" label={t('form.labels.role')}>
            <CompanyFunctionSelect disabled />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
