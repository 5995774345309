import { SelectProps } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import {
  FindThemeQueryVariables,
  useFindManyThemesQuery,
  useFindThemeQuery,
} from '../../../graphql/generated/graphql';

export const useThemeSelect = () => {
  const {
    companySettings,
    initialCompanySettings,
    themeHandlers: {
      update: updateTheme,
      rollback: rollbackTheme,
      commit: commitTheme,
    },
  } = useUserContext();

  const [selectedThemeId, setSelectedThemeId] = useState<
    string | null | undefined
  >(() => companySettings?.theme?.id);

  const hasThemeChange = useRef<boolean>(false);

  const findThemeQueryVariables: FindThemeQueryVariables | undefined =
    selectedThemeId ? { themeId: selectedThemeId } : undefined;

  const [findManyThemesQueryResult] = useFindManyThemesQuery();
  const [findThemeQueryResult] = useFindThemeQuery({
    variables: findThemeQueryVariables,
    pause:
      !findThemeQueryVariables ||
      selectedThemeId === initialCompanySettings?.theme?.id,
  });

  const themeSelect = useMemo(() => {
    const options: SelectProps['options'] =
      findManyThemesQueryResult.data?.FindManyThemes.data.map(
        ({ id, name }) => ({
          label: name,
          value: id,
        })
      );

    const handlers = {
      onChange(value: string) {
        setSelectedThemeId(value);
        if (value === initialCompanySettings?.theme?.id) {
          rollbackTheme();
          hasThemeChange.current = false;
        }
      },
      onReset() {
        setSelectedThemeId(initialCompanySettings?.theme?.id);
        rollbackTheme();
        hasThemeChange.current = false;
      },
      onSuccess() {
        commitTheme();
        hasThemeChange.current = false;
      },
    };

    return { handlers, options };
  }, [
    commitTheme,
    findManyThemesQueryResult.data?.FindManyThemes.data,
    initialCompanySettings?.theme?.id,
    rollbackTheme,
  ]);

  useEffect(() => {
    const { data, error, fetching } = findThemeQueryResult;

    if (data && !error && !fetching) {
      updateTheme(data.FindTheme);
      hasThemeChange.current = true;
    }
  }, [findThemeQueryResult, updateTheme]);

  useEffect(() => {
    return () => {
      if (hasThemeChange.current) {
        hasThemeChange.current = false;
        rollbackTheme();
      }
    };
  }, [rollbackTheme]);

  return {
    ...themeSelect,
    selectedThemeId: selectedThemeId,
    isLoading:
      findThemeQueryResult.fetching || findManyThemesQueryResult.fetching,
  };
};
