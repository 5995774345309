import { message } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../../../../contexts/UserContext/useUserContext';
import { useDisapproveCompanyModalMutation } from '../../../../../../../graphql/generated/graphql';
import { IDisapproveCompanyFormValues } from './DisapproveCompanyModal.components';

export interface IUseDisapproveCompanyModal {
  id?: string;
}

export const useDisapproveCompanyModal = (
  { id: companyId }: IUseDisapproveCompanyModal,
  onConfirmSuccess: () => void = () => null
) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.components.disapproveCompanyModal.useDisapproveCompanyModal',
  });

  const { userData } = useUserContext();

  const [isOpen, setIsOpen] = useState(false);

  const [result, executeMutation] = useDisapproveCompanyModalMutation();

  const handlers = useMemo(
    () => ({
      open() {
        setIsOpen(true);
      },
      close() {
        setIsOpen(false);
      },
      confirm(formValues: IDisapproveCompanyFormValues) {
        if (companyId && userData) {
          executeMutation({
            input: {
              company_id: companyId,
              manager_id: userData.id,
              is_block: formValues.isBlocked,
              reasons: formValues.reasons.map(reason => ({
                description: reason.label,
                reason_type: reason.value,
              })),
              reproach_description: formValues.description.trim(),
            },
          }).then(({ data }) => {
            if (data) {
              message.success({
                key: data.ReproachCompany.reference_company_id,
                content: t('success'),
              });

              onConfirmSuccess();
            }
          });

          setIsOpen(false);
        }
      },
    }),
    [companyId, executeMutation, onConfirmSuccess, t, userData]
  );

  return [isOpen, handlers, result] as const;
};
