import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type IUseQueryParams = [
  () => Record<string, string>,
  (values: Record<string, string | number | undefined>) => void
];

const useQueryParams = (): IUseQueryParams => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams = useCallback(
    (values: Record<string, string | number | undefined>) => {
      const queryParams = new URLSearchParams(searchParams);

      Object.keys(values).forEach(key => {
        if (values[key] !== undefined && values[key] !== '') {
          if (!['string', 'number'].includes(typeof values[key])) {
            throw new Error(
              'Invalid object property value on "setQueryParams" function parameter. Object property value ' +
                `"${key}"` +
                ' of type ' +
                `"${typeof values[key]}"` +
                ' is not assignable to parameter of type "string | number | undefined".'
            );
          }

          queryParams.set(key, String(values[key]));
        } else {
          queryParams.delete(key);
        }
      });

      setSearchParams(queryParams);
    },
    [searchParams, setSearchParams]
  );

  const getQueryParams = useCallback(
    (params?: string[]): Record<string, string> => {
      const queryParams = {};

      searchParams.forEach((param, key) => {
        if (!params || params.includes(key)) {
          Object.assign(queryParams, { [key]: param });
        }
      });

      return queryParams;
    },
    [searchParams]
  );

  return [getQueryParams, setQueryParams];
};

export default useQueryParams;
