import { SVGProps } from 'react';

export default function TruckIconComponent(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
      <g data-name="Grupo 16974">
        <g data-name="Grupo 16037" fill="currentColor">
          <path
            data-name="Ret\xE2ngulo 6726"
            d="M1.474.737a.738.738 0 0 0-.737.737v11.052a.738.738 0 0 0 .737.737h11.052a.738.738 0 0 0 .737-.737V1.474a.738.738 0 0 0-.737-.737H1.474m0-.737h11.052A1.474 1.474 0 0 1 14 1.474v11.052A1.474 1.474 0 0 1 12.526 14H1.474A1.474 1.474 0 0 1 0 12.526V1.474A1.474 1.474 0 0 1 1.474 0Z"
          />
          <path
            data-name="Ret\xE2ngulo 6727"
            d="M2.948 2.211a.738.738 0 0 0-.737.737v1.473a.738.738 0 0 0 .737.737h1.473a.738.738 0 0 0 .737-.737V2.948a.738.738 0 0 0-.737-.737H2.948m0-.737h1.473a1.474 1.474 0 0 1 1.474 1.474v1.473a1.474 1.474 0 0 1-1.474 1.474H2.948a1.474 1.474 0 0 1-1.474-1.474V2.948a1.474 1.474 0 0 1 1.474-1.474Z"
          />
          <path
            data-name="Ret\xE2ngulo 6730"
            d="M8.106 8.842a.738.738 0 0 0-.737.737v1.473a.738.738 0 0 0 .737.737h2.947a.738.738 0 0 0 .737-.737V9.579a.738.738 0 0 0-.737-.737H8.106m0-.737h2.947a1.474 1.474 0 0 1 1.474 1.474v1.473a1.474 1.474 0 0 1-1.474 1.474H8.106a1.474 1.474 0 0 1-1.474-1.474V9.579a1.474 1.474 0 0 1 1.474-1.474Z"
          />
          <path
            data-name="Ret\xE2ngulo 6728"
            d="M2.948 7.369a.738.738 0 0 0-.737.737v2.947a.738.738 0 0 0 .737.737h1.473a.738.738 0 0 0 .737-.737V8.106a.738.738 0 0 0-.737-.737H2.948m0-.737h1.473a1.474 1.474 0 0 1 1.474 1.474v2.947a1.474 1.474 0 0 1-1.474 1.474H2.948a1.474 1.474 0 0 1-1.474-1.474V8.106a1.474 1.474 0 0 1 1.474-1.474Z"
          />
          <path
            data-name="Ret\xE2ngulo 6729"
            d="M8.106 2.211a.738.738 0 0 0-.737.737v2.947a.738.738 0 0 0 .737.737h2.947a.738.738 0 0 0 .737-.737V2.948a.738.738 0 0 0-.737-.737H8.106m0-.737h2.947a1.474 1.474 0 0 1 1.474 1.474v2.947a1.474 1.474 0 0 1-1.474 1.474H8.106a1.474 1.474 0 0 1-1.474-1.474V2.948a1.474 1.474 0 0 1 1.474-1.474Z"
          />
        </g>
      </g>
    </svg>
  );
}
