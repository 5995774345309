import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Popover, PopoverProps } from 'antd';
import { HTMLProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import './SupportHelpContact.scss';
import { Utils } from '../../../utils';

interface ISupportHelpContactProps extends HTMLProps<HTMLDivElement> {
  buttonProps?: ButtonProps;
  popoverProps?: PopoverProps;
}

const TRANSLATION_PATH = 'components.help.supportHelpContact';

/**
 * Renders a popover component that displays help and contact information for support.
 *
 * @param {Object} buttonProps - button component props
 * @param {Object} popoverProps - popover component props
 * @param {Object} divProps - div container component props
 * @return {ReactElement} Popover component with help and contact information
 */

export const SupportHelpContact = ({
  buttonProps,
  popoverProps,
  ...divProps
}: ISupportHelpContactProps): ReactElement => {
  const { t } = useTranslation();

  const content = (
    <>
      {t(`${TRANSLATION_PATH}.content.text`)}
      <br />
      {t(`${TRANSLATION_PATH}.content.phone`)}
    </>
  );

  return (
    <div className="support-help-contact__container" {...divProps}>
      <Popover
        content={content}
        placement="rightTop"
        title={t(`${TRANSLATION_PATH}.title`)}
        trigger="hover"
        getPopupContainer={Utils.getPopupContainer}
        {...popoverProps}
      >
        <Button type="text" {...buttonProps}>
          <QuestionCircleOutlined />
        </Button>
      </Popover>
    </div>
  );
};
