import { TableProps } from 'antd';
import { Dispatch, Key, SetStateAction, useMemo, useState } from 'react';

export const useRowSelection = <T extends object>() => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [hasSelectedRowKeysSet, setHasSelectedRowKeysSet] = useState(false);

  const rowSelection = useMemo(() => {
    const newSetSelectedRowKeys: Dispatch<SetStateAction<Key[]>> = (
      selectedRowKeys: Key[] | ((selectedRowKeys: Key[]) => Key[])
    ) => {
      setSelectedRowKeys(selectedRowKeys);
      setHasSelectedRowKeysSet(true);
    };

    const handlers = {
      clear: () => {
        newSetSelectedRowKeys([]);
      },
      remove: (key: Key) => {
        newSetSelectedRowKeys(selectedRowKeys =>
          selectedRowKeys.filter(k => k !== key)
        );
      },
      append: (key: Key) => {
        newSetSelectedRowKeys(selectedRowKeys => [...selectedRowKeys, key]);
      },
      set: (selectedRowKeys: Key[]) => {
        newSetSelectedRowKeys(selectedRowKeys);
      },
    };

    const rowSelection: TableProps<T>['rowSelection'] = {
      selectedRowKeys,
      onChange(selectedRowKeys) {
        newSetSelectedRowKeys(selectedRowKeys);
      },
    };

    return { handlers, rowSelection, selectedRowKeys, hasSelectedRowKeysSet };
  }, [hasSelectedRowKeysSet, selectedRowKeys]);

  return rowSelection;
};
