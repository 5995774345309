import { useEffect, useState } from 'react';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  CarrierListFreightsQueryVariables,
  CarrierQuotingFreightFragment,
  useCarrierListFreightsQuery,
} from '../../../../graphql/generated/graphql';
import { useInterval } from '../../../../hooks/useInterval/useInterval';
import dayjs from 'dayjs';
import { usePagination } from '../../../../hooks/usePagination';

export const useQuotingFreightsQuery = () => {
  const { userData } = useUserContext();

  const [quotingFreights, setQuotingFreights] =
    useState<CarrierQuotingFreightFragment[]>();

  const [pagination, paginationRequestParams, setDataLength] = usePagination();

  const listQuotingFreightVariables:
    | CarrierListFreightsQueryVariables
    | undefined = userData?.company
    ? {
        carrierId: userData.company.id,
        pagination: paginationRequestParams,
        quotingInput: {
          refusals: {
            none: {
              owner: {
                is: {
                  reference_external_company_id: {
                    equals: userData.company.id,
                  },
                },
              },
            },
          },
          quotations: {
            none: {
              owner: {
                is: {
                  reference_external_company_id: {
                    equals: userData.company.id,
                  },
                },
              },
            },
          },
        },
      }
    : undefined;

  const [listQuotingFreightsResult] = useCarrierListFreightsQuery({
    variables: listQuotingFreightVariables,
    pause: !listQuotingFreightVariables,
  });

  useEffect(() => {
    if (listQuotingFreightsResult.data) {
      setQuotingFreights(
        listQuotingFreightsResult.data.FindManyCarrierFreightsQuoting.data
      );
    }

    return () => {
      setQuotingFreights(undefined);
    };
  }, [listQuotingFreightsResult.data]);

  useEffect(() => {
    if (
      listQuotingFreightsResult.data &&
      listQuotingFreightsResult.data.FindManyCarrierFreightsQuoting.length !==
        pagination.total
    ) {
      setDataLength(
        listQuotingFreightsResult.data.FindManyCarrierFreightsQuoting.length
      );
    }
  }, [listQuotingFreightsResult.data, pagination.total, setDataLength]);

  useInterval(() => {
    if (quotingFreights) {
      const now = dayjs();

      const newQuotingFreights = quotingFreights.filter(quotingFreight =>
        dayjs(quotingFreight.quote_limit).isAfter(now, 'seconds')
      );

      if (quotingFreights.length !== newQuotingFreights.length) {
        setQuotingFreights(newQuotingFreights);

        const diff = quotingFreights.length - newQuotingFreights.length;
        setDataLength(dataLength =>
          dataLength > diff ? dataLength - diff : dataLength
        );
      }
    }
  }, 1000);

  return { ...listQuotingFreightsResult, data: quotingFreights, pagination };
};
