import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useCancellationReasonsSelectQuery,
  CancellationTriggerEnum,
  CancellationReasonsSelectQueryVariables,
} from '../../../../graphql/generated/graphql';

type ICancellationReasonSelect = Omit<SelectProps, 'options'> & {
  trigger?: CancellationTriggerEnum;
};

/**
 * Ant design select with CancellationReasons options
 */
export const CancellationReasonsSelect = ({
  trigger,
  ...props
}: ICancellationReasonSelect) => {
  const { t } = useTranslation();

  const variables = trigger
    ? ({
        where: {
          trigger: {
            equals: trigger,
          },
        },
        pagination: {
          take: 1000,
        },
      } as CancellationReasonsSelectQueryVariables)
    : undefined;

  const [findManyCancellationReasonsResult] = useCancellationReasonsSelectQuery(
    {
      variables,
      pause: !variables,
    }
  );

  const { data, fetching, error } = findManyCancellationReasonsResult;

  return (
    <Select
      loading={fetching}
      disabled={fetching || !!error}
      options={data?.FindManyCancellationReasons}
      notFoundContent={t('general.dataNotFound')}
      placeholder={t('general.select')}
      {...props}
    />
  );
};
