/**
 * Unique items array
 *
 * Constructs a new array with unique items
 * Constructor needs an equality comparator function to evaluate which item is unique
 */
export class UniqueArray<T> extends Array<T> {
  constructor(
    array: T | T[],
    equalityComparer = (value1: T, value2: T) => value1 === value2
  ) {
    if (Array.isArray(array)) {
      const uniqueArray = array.reduce((acc, item) => {
        if (!acc.some(uniqueItem => equalityComparer(item, uniqueItem))) {
          acc.push(item);
        }

        return acc;
      }, [] as T[]);

      super(...uniqueArray);
    } else {
      super(array);
    }
  }
}
