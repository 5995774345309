import { ReactElement, useMemo, useState } from 'react';
import { Provider } from 'urql';
import { createUrqlClient, getFetchOptions } from '../../api/urql/client';
import { useAuthContext } from '../AuthContext/useAuthContext';

interface IUrqlContextProviderProps {
  children: JSX.Element;
}

export const UrqlContextProvider = ({
  children,
}: IUrqlContextProviderProps): ReactElement => {
  const { accessToken } = useAuthContext();

  const [urqlClient] = useState(
    createUrqlClient({
      accessToken,
    })
  );

  const fetchOptions = useMemo(() => {
    return getFetchOptions(accessToken);
  }, [accessToken]);

  urqlClient.fetchOptions = fetchOptions;

  return <Provider value={urqlClient}>{children}</Provider>;
};
