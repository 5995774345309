import { useEffect, useState } from 'react';

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(() => window.screen.width);

  useEffect(() => {
    const handleWindowResize = (e: UIEvent) => {
      const { innerWidth } = e.target as Window;
      setScreenWidth(innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { screenWidth };
};
