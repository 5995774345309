import { useMemo, useState } from 'react';
import { IQuotationGroupMembersTableData } from './QuotationGroupMembersTable';

export const useGroupMembers = () => {
  const [groupMembers, setGroupMembers] = useState<
    IQuotationGroupMembersTableData[]
  >([]);

  const quotationGroupForm = useMemo(() => {
    const handlers = {
      remove(companyId: string) {
        setGroupMembers(groupMembers => {
          return groupMembers.filter(company => company.id !== companyId);
        });
      },
      removeMany(companyIds: string[]) {
        setGroupMembers(groupMembers => {
          return groupMembers.filter(
            company => !companyIds.includes(company.id)
          );
        });
      },
      addMany(companies: IQuotationGroupMembersTableData[]) {
        setGroupMembers(groupMembers => [...companies, ...groupMembers]);
      },
    };

    return { groupMembers, handlers };
  }, [groupMembers]);

  return quotationGroupForm;
};
