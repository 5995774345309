import { TableProps } from 'antd';
import { ReactElement } from 'react';
import {
  AttachmentsTableFragment,
  AttachmentsTableModal_DetachFileMutation,
} from '../../../../graphql/generated/graphql';
import { DefaultTable } from '../../../AntdCustom/DefaultTable/DefaultTable';
import { useColumns } from './AttachmentsTable.hooks';

export interface IAttachmentsTableProps
  extends Partial<TableProps<AttachmentsTableFragment>> {
  onDelete?: (
    fileId: string
  ) => Promise<AttachmentsTableModal_DetachFileMutation>;
}

export const AttachmentsTable = ({
  onDelete,
  ...props
}: IAttachmentsTableProps): ReactElement => {
  const columns = useColumns(onDelete);

  return (
    <DefaultTable
      columns={columns}
      rowKey={({ file_id }) => file_id}
      {...props}
    />
  );
};
