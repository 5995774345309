import dayjs from 'dayjs';

import { Button, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

export const COMPANY_POLICY_DESCRIPTIONS_FRAGMENT = `
  id
  name
  expiresIn: expires_in
  url: bucket_url
`;

interface ICompanyPolicy {
  id: string;
  name: string;
  expires_in: string;
  bucket_url: string;
}

export const CompanyPoliciesDescriptions = ({
  companyPolicies,
}: {
  companyPolicies: ICompanyPolicy[];
}) => {
  const { t } = useTranslation();

  return (
    <Descriptions column={1}>
      {companyPolicies.map(({ id, expires_in, name, bucket_url }) => (
        <Descriptions.Item
          key={id}
          label={`${name} ${dayjs(expires_in).format('DD/MM/YY')}`}
        >
          <Button
            disabled={false}
            href={bucket_url}
            size="small"
            target="_blank"
            type="link"
          >
            {t(
              'components.company.companyDescriptions.companyPoliciesDescriptions.descriptions.view'
            )}
          </Button>
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default CompanyPoliciesDescriptions;
