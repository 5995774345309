import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { ListFreights } from '../../../../components/Freight/Shipper/ListFreights/ListFreights';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';

export const ShipperFreightPage = (): ReactElement => {
  useTitle({ title: 'tab.shipper.freight.index' });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/freights'],
  });

  return <ListFreights />;
};

const ShipperFreightPageAccess = Access(ShipperFreightPage, {
  acceptedPermissions: [
    {
      module: 'FREIGHTS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default ShipperFreightPageAccess;
