import { Breakpoint, Descriptions, DescriptionsProps } from 'antd';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';
import { ReactElement } from 'react';
import { useBreakpoints } from '../../../hooks/useBreakpoints/useBreakpoints';

export interface IResponsiveDescriptionsProps
  extends Omit<DescriptionsProps, 'layout'> {
  children: React.ReactNode;
  layout?:
    | 'horizontal'
    | 'vertical'
    | Partial<Record<Breakpoint, 'horizontal' | 'vertical'>>;
}

export const ResponsiveDescriptions = ({
  children,
  layout,
  ...descriptionsProps
}: IResponsiveDescriptionsProps): ReactElement => {
  const responsiveLayout = typeof layout === 'object' ? layout : undefined;
  const fallbackLayout = typeof layout === 'string' ? layout : undefined;

  const breakpointRule = useBreakpoints(responsiveLayout);

  return (
    <Descriptions
      {...descriptionsProps}
      layout={breakpointRule || fallbackLayout}
    >
      {children}
    </Descriptions>
  );
};

interface IDescriptionsItemProps extends Omit<DescriptionsItemProps, 'span'> {
  children: React.ReactNode;
  span?: number | Partial<Record<Breakpoint, number>>;
}

const ResponsiveDescriptionsItem = ({
  children,
  span,
  ...descriptionsItemProps
}: IDescriptionsItemProps): ReactElement => {
  const responsiveSpan = typeof span === 'object' ? span : undefined;
  const fallbackSpan = typeof span === 'number' ? span : undefined;

  const breakpointRule = useBreakpoints(responsiveSpan);

  return (
    <Descriptions.Item
      {...descriptionsItemProps}
      span={breakpointRule || fallbackSpan}
    >
      {children}
    </Descriptions.Item>
  );
};

ResponsiveDescriptions.Item = ResponsiveDescriptionsItem;
