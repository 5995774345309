/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FindFreightForCreateQuotationQuery } from '../../../graphql/generated/graphql';
import { FixedFooterContainer } from '../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { DefaultPageFooter } from '../../AntdCustom/DefaultPageFooter/DefaultPageFooter';
import ErrorAlert from '../../AntdCustom/ErrorAlert/ErrorAlert';
import { ConfirmFreightDates } from '../../Freight/Freights/ConfirmCreateFreight/ConfirmFreightDates/ConfirmFreightDates';
import { ConfirmFreightLoad } from '../../Freight/Freights/ConfirmCreateFreight/ConfirmFreightLoad/ConfirmFreightLoad';
import { ViewRoute } from '../../Freight/Route/ViewRoute/ViewRoute';
import { CreateQuotationForm } from '../CreateQuotationForm/CreateQuotationForm';
import { QuotationDetailsUtils } from '../QuotationDetails/QuotationDetails.utils';
import { RefuseQuotationModal } from '../RefuseQuotationModal/RefuseQuotationModal';
import {
  useCreateQuotation,
  useQuotationAveragePricePerUnit,
} from './CreateQuotation.hooks';

export type IFreight = FindFreightForCreateQuotationQuery['FindFreight'];

interface ICreateQuotationProps {
  setQuoteLimit?: (quoteLimit: dayjs.Dayjs | null) => void;
}

export const CreateQuotation = ({
  setQuoteLimit,
}: ICreateQuotationProps): ReactElement => {
  const CREATE_QUOTATION_TRANSLATION_PATH =
    'components.quotation.createQuotation';

  const { t } = useTranslation();

  const {
    form: refuseQuotationModalForm,
    isModalOpen: isRefuseQuotationModalOpen,
    refuseQuotationModalResult,
    handlers: {
      onClose: handleRefuseQuotationClose,
      onOpen: handleRefuseQuotationOpen,
      onSubmit: handleRefuseQuotationFinish,
    },
  } = RefuseQuotationModal.useRefuseQuotation();

  const {
    createQuotationMutationResult,
    findFreightQueryResult,
    form,
    handlers: {
      onRefuseQuotationSubmit: handleRefuseQuotationSubmit,
      onCancel: handleCancel,
      onConfirm: handleConfirm,
      onSubmit: handleSubmit,
    },
  } = useCreateQuotation({
    handleRefuseQuotationFinish,
    setQuoteLimit,
  });

  const quotationAveragePrices = useQuotationAveragePricePerUnit({
    quotations: findFreightQueryResult.data?.FindFreight.quotations,
  });

  const freight = findFreightQueryResult.data?.FindFreight;

  const isLoading =
    findFreightQueryResult.fetching ||
    createQuotationMutationResult.fetching ||
    refuseQuotationModalResult.fetching;

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 24]}>
        <ErrorAlert error={findFreightQueryResult.error} />
        <ErrorAlert error={createQuotationMutationResult.error} />
        <ErrorAlert error={refuseQuotationModalResult.error} />
        <Col span={24}>
          <ViewRoute
            loading={isLoading}
            mapStyle={{ maxHeight: 350 }}
            route={freight?.route}
            showRouteName={false}
            cardStyle={{ minHeight: '100%' }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <ConfirmFreightDates
                freightDates={freight}
                loading={isLoading}
                title={null}
              />
            </Col>
            <Col span={24}>
              <ConfirmFreightLoad
                freightLoad={QuotationDetailsUtils.toConfirmFreightLoad(
                  freight
                )}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <CreateQuotationForm.Card>
            <CreateQuotationForm
              form={form}
              onFinish={handleSubmit}
              disabled={isLoading}
              freight={freight}
              averagePricePerUnit={quotationAveragePrices}
            />
          </CreateQuotationForm.Card>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button onClick={handleCancel} size="small">
              {t(`${CREATE_QUOTATION_TRANSLATION_PATH}.actions.cancel`)}
            </Button>
            <Button
              danger
              disabled={isLoading}
              onClick={handleRefuseQuotationOpen}
              size="small"
            >
              {t(`${CREATE_QUOTATION_TRANSLATION_PATH}.actions.refuse`)}
            </Button>
            <Button
              htmlType="submit"
              disabled={
                findFreightQueryResult.fetching ||
                refuseQuotationModalResult.fetching
              }
              loading={createQuotationMutationResult.fetching}
              onClick={handleConfirm}
              size="small"
              type="primary"
            >
              {t(`${CREATE_QUOTATION_TRANSLATION_PATH}.actions.submit`)}
            </Button>
          </Space>
        }
      />
      <RefuseQuotationModal
        form={refuseQuotationModalForm}
        isOpen={isRefuseQuotationModalOpen}
        loading={refuseQuotationModalResult.fetching}
        onClose={handleRefuseQuotationClose}
        onFinish={handleRefuseQuotationSubmit}
      />
    </>
  );
};
