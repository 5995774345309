import { Form, FormProps, Input, Modal, ModalProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CancellationTriggerEnum } from '../../../graphql/generated/graphql';
import { CancellationReasonsSelect } from '../AbandonOrCancelQuotation/CancellationReasonsSelect/CancellationReasonsSelect';

export interface IWaiveQuotationFormValues {
  reason: {
    label: string;
    value: string;
  };
  reasonDescription?: string;
}

interface IWaiveQuotationFormProps
  extends FormProps<IWaiveQuotationFormValues> {
  cancellationTrigger?: CancellationTriggerEnum;
}

const TRANSLATION_PATH = 'components.quotation.waiveQuotationForm';

export const WaiveQuotationForm = ({
  ...formProps
}: IWaiveQuotationFormProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical" scrollToFirstError {...formProps}>
      <Form.Item
        name="reason"
        label={t(`${TRANSLATION_PATH}.inputs.labels.reason`)}
        rules={[{ required: true }]}
      >
        <CancellationReasonsSelect
          style={{ width: 250 }}
          trigger={CancellationTriggerEnum.Waiver}
          labelInValue
        />
      </Form.Item>
      <Form.Item
        name="reasonDescription"
        label={t(`${TRANSLATION_PATH}.inputs.labels.reasonDescription`)}
      >
        <Input.TextArea
          rows={6}
          size="small"
          placeholder={t(
            `${TRANSLATION_PATH}.inputs.placeholders.reasonDescription`
          )}
        />
      </Form.Item>
    </Form>
  );
};

interface IWaiveQuotationFormModalProps extends ModalProps {
  children: JSX.Element;
}

export const WaiveQuotationFormModal = ({
  children,
  ...modalProps
}: IWaiveQuotationFormModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      title={t(`${TRANSLATION_PATH}.modalTitle`)}
      width={992}
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

WaiveQuotationForm.Modal = WaiveQuotationFormModal;
