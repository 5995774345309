import { ReactElement } from 'react';
import { Layer, Source } from 'react-map-gl';

interface IMarkerLayerProps {
  id?: string;
  markers?: IMarker[];
  layout?: Record<string, unknown>;
}

interface IMarker {
  coordinates: [number, number];
  properties?: Record<string, unknown>;
}

export const MarkerLayer = ({
  id,
  markers,
  layout,
}: IMarkerLayerProps): ReactElement => {
  if (!markers) {
    return <></>;
  }

  const defaultProperties = {
    size: 1,
  };

  const layerData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: markers.map(({ coordinates, properties = {} }) => ({
      type: 'Feature',
      properties: { ...defaultProperties, ...properties },
      geometry: {
        type: 'Point',
        coordinates: coordinates,
      },
    })),
  };

  return (
    <Source id={id} type="geojson" data={layerData}>
      <Layer
        id={id}
        type="symbol"
        layout={{
          'icon-image': '{icon}',
          'icon-size': ['get', 'size'],
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          ...layout,
        }}
      />
    </Source>
  );
};
