/* eslint-disable react-hooks/exhaustive-deps */
import { LeftOutlined } from '@ant-design/icons';
import {
  ConfigProvider as AntDesignConfigProvider,
  Card,
  Form,
  notification,
} from 'antd';
import { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CombinedError } from 'urql';
import errorHandler from '../../api/errorHandler';
import Button from '../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../components/Help/SupportHelpContact/SupportHelpContact';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import HeroContainer from '../../components/container/heroContainer';
import HeroCardContainer from '../../components/container/heroContainer/heroCardContainer';
import InputText from '../../components/inputs/inputText';
import {
  useRecoverPassword_NewPasswordMutation,
  useRecoverPassword_SendRecoveryCodeMutation,
} from '../../graphql/generated/graphql';
import { useJwtDecode } from '../../hooks/useJwtDecode/useJwtDecode';
import { IAPIError } from '../../utils/interfaces';
import LoadingPage from '../LoadingPage/LoadingPage';

import mainTheme from '../../layouts/MainLayout/mainTheme';
import './RecoverPasswordPage.scss';

const RecoverPasswordPage = (): ReactElement => {
  const { t } = useTranslation();

  const [sendEmailForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const decodedJwt = useJwtDecode({ encodedJwt: token });

  const [
    recoveryPasswordCodeMutationResult,
    executeRecoveryPasswordCodeMutation,
  ] = useRecoverPassword_SendRecoveryCodeMutation();

  const [createPasswordFormMutationResult, executeCreatePasswordFormMutation] =
    useRecoverPassword_NewPasswordMutation();

  const getDetailedError = (error: CombinedError) => {
    const { message, description } = errorHandler(
      error.graphQLErrors as unknown as IAPIError[]
    );
    return { message, description };
  };

  const sendEmailFormOnFinish = (values: { email: string }) => {
    const formValues = { email: values.email.toLowerCase() };

    executeRecoveryPasswordCodeMutation({
      input: formValues,
    })
      .then(({ error }) => {
        if (!error) {
          notification.success({
            message: t('pages.recoveryPassword.success.message'),
            description: t('pages.recoveryPassword.success.description'),
            duration: 7,
          });
          navigate('/');
        } else if (error) {
          const { message, description } = getDetailedError(error);

          notification.error({
            message: t(message),
            description: t(description),
          });
        }
      })
      .catch(({ message, description }) => {
        notification.error({
          message: t(message),
          description: t(description),
        });
      });
  };

  const passwordFormOnFinish = (values: { password: string }) => {
    if (token) {
      executeCreatePasswordFormMutation({
        input: {
          new_password: values.password,
          token: token,
        },
      })
        .then(({ data, error }) => {
          const success = data?.RecoveryPassword.has_success;
          if (success && !error) {
            notification.success({
              message: t(
                'pages.recoveryPassword.createPassword.success.message'
              ),
              description: t(
                'pages.recoveryPassword.createPassword.success.description'
              ),
            });
            navigate('/');
          } else if (error) {
            const { message, description } = getDetailedError(error);

            notification.error({
              message: t(message),
              description: t(description),
            });
          }
        })
        .catch(({ message, description }) => {
          notification.error({
            message: t(message),
            description: t(description),
          });
        });
    }
  };

  const RecoveryPasswordForm = (
    <Form
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={sendEmailFormOnFinish}
      form={sendEmailForm}
    >
      {useCallback(
        () => (
          <>
            <InputText
              formItem
              validation
              size="large"
              name="email"
              disabled={recoveryPasswordCodeMutationResult.fetching}
              label={t('pages.recoveryPassword.fields.email.label')}
              placeholder={t('pages.recoveryPassword.fields.email.placeholder')}
              otherRules={[
                { type: 'email', message: t('input.rules.invalidEmail') },
              ]}
            />
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={recoveryPasswordCodeMutationResult.fetching}
              style={{
                width: '100%',
                fontSize: '1rem',
                borderRadius: '4px',
                fontWeight: 'lighter',
              }}
            >
              {t('pages.recoveryPassword.recoveryButton')}
            </Button>
          </>
        ),
        [recoveryPasswordCodeMutationResult.fetching]
      )()}
    </Form>
  );

  const passwordRules = [
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}`.length < 6) {
          return Promise.reject();
        }

        return Promise.resolve();
      },
      message:
        'pages.recoveryPassword.createPassword.fields.rules.passwordMinLength',
      validateTrigger: 'onBlur',
    },
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (`${value}`.length > 128) {
          return Promise.reject();
        }

        return Promise.resolve();
      },
      message:
        'pages.recoveryPassword.createPassword.fields.rules.passwordMaxLength',
    },
  ];

  const confirmPasswordRules = [
    {
      validator: (_: unknown, value: string): Promise<void> => {
        if (
          `${value}` === passwordForm.getFieldsValue(['password'])['password']
        ) {
          return Promise.resolve();
        }

        return Promise.reject();
      },
      message: 'pages.createPassword.fields.rules.passwordNotMatch',
    },
  ];

  const CreatePasswordForm = (
    <Form
      form={passwordForm}
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={passwordFormOnFinish}
    >
      <InputText
        formItem
        validation
        size="large"
        name="password"
        type={'password'}
        label={t(
          'pages.recoveryPassword.createPassword.fields.labels.password'
        )}
        placeholder={t(
          'pages.recoveryPassword.createPassword.fields.placeholders.password'
        )}
        otherRules={passwordRules}
        otherPropsFormItem={{
          validateTrigger: ['onChange', 'onBlur'],
        }}
      />
      <InputText
        formItem
        validation
        size="large"
        name="confirmPassword"
        type={'password'}
        label={t(
          'pages.recoveryPassword.createPassword.fields.labels.confirmPassword'
        )}
        placeholder={t(
          'pages.recoveryPassword.createPassword.fields.placeholders.confirmPassword'
        )}
        otherRules={confirmPasswordRules}
        otherPropsFormItem={{
          hasFeedback: true,
          dependencies: ['password'],
        }}
      />
      <Button
        size="large"
        type="primary"
        htmlType="submit"
        loading={createPasswordFormMutationResult.fetching}
        style={{
          width: '100%',
          fontSize: '1rem',
          borderRadius: '4px',
          fontWeight: 'lighter',
        }}
      >
        {t('pages.recoveryPassword.createPassword.confirmButton')}
      </Button>
    </Form>
  );

  useEffect(() => {
    if (token && decodedJwt.error) {
      notification.error({
        message: t(decodedJwt.error.message),
        description: t(decodedJwt.error.description),
      });

      navigate('/', { replace: true });
    }
  }, [decodedJwt]);

  if (token && !decodedJwt.data) return <LoadingPage asOverlay opaque />;

  return (
    <AntDesignConfigProvider theme={mainTheme.config}>
      <HeroContainer>
        <HeroCardContainer className="recover-password-card_container">
          <div
            style={{
              position: 'absolute',
              left: '24px',
              top: '24px',
              zIndex: '10',
            }}
          >
            <Link to={'/'} style={{ color: 'black' }}>
              <LeftOutlined />
              {'  '}
              {t('general.back')}
            </Link>
          </div>
          <SupportHelpContact />
          <Card
            className="recover-password-card"
            bordered={false}
            style={{ boxShadow: 'none' }}
          >
            <TitleHeader
              decorated
              titleText={
                token
                  ? t('pages.recoveryPassword.createPassword.title')
                  : t('pages.recoveryPassword.title')
              }
              titleStyle={{ marginBottom: '0.75rem' }}
              subtitleText={
                token
                  ? t('pages.recoveryPassword.createPassword.subtitle')
                  : t('pages.recoveryPassword.subtitle')
              }
            />
            {token ? CreatePasswordForm : RecoveryPasswordForm}
          </Card>
        </HeroCardContainer>
      </HeroContainer>
    </AntDesignConfigProvider>
  );
};

export default RecoverPasswordPage;
