import {
  Button,
  Card,
  Row,
  Space,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import {
  ListRoutesQuery,
  MoveTypeEnum,
} from '../../../../graphql/generated/graphql';
import { Access } from '../../../Access/Access';

type IFindRoutes = ListRoutesQuery['FindRoutes'];

export interface IRouteTableProps {
  routes?: IFindRoutes;
  handleView?: (routeId: string) => void;
  handleCreate?: () => void;
  loading?: boolean;
  title?: React.ReactElement;
  selectableRows?: boolean;
  onRouteSelect?: (routeId: string) => void;
  bordered?: boolean;
  disabled?: boolean;
  size?: TableProps<IFindRoutes[0]>['size'];
  selectedRowKeys?: string[];
}

export const RoutesTable = ({
  routes,
  handleView,
  handleCreate,
  loading,
  title,
  selectableRows,
  onRouteSelect,
  bordered,
  disabled = false,
  size,
  selectedRowKeys,
}: IRouteTableProps): ReactElement => {
  const ROUTES_TABLE_TRANSLATION_PATH = 'components.freight.route.routesTable';
  const { t } = useTranslation();

  const RouteCreateButton = () => (
    <Button type="primary" onClick={handleCreate} disabled={disabled}>
      {t(`${ROUTES_TABLE_TRANSLATION_PATH}.buttonText`)}
    </Button>
  );

  const RouteCreateButtonAccess = Access(RouteCreateButton, {
    acceptedPermissions: [{ module: 'ROUTES', actions: ['CREATE'] }],
  });

  const RouteViewButton = ({ routeId }: { routeId: string }) => (
    <Button
      disabled={disabled}
      onClick={() => handleView && handleView(routeId)}
      type="link"
    >
      {t(`${ROUTES_TABLE_TRANSLATION_PATH}.viewButton`)}
    </Button>
  );

  const RouteViewButtonAccess = Access(RouteViewButton, {
    acceptedPermissions: [{ module: 'ROUTES', actions: ['READ'] }],
  });

  const cardTitle = title || (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${ROUTES_TABLE_TRANSLATION_PATH}.tableTitle`)}
      </Typography.Text>
      <RouteCreateButtonAccess />
    </Row>
  );

  const columns: TableColumnsType<IFindRoutes[0]> = [
    {
      dataIndex: 'name',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.routeName`),
    },
    {
      dataIndex: 'places',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.withdrawalPlace`),
      render: (places: IFindRoutes[0]['places']) => {
        const withdrawalPlace = places?.find(
          place => place.move_type === MoveTypeEnum.Withdrawal
        );

        return <>{withdrawalPlace?.name}</>;
      },
    },
    {
      dataIndex: 'places',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.withdrawalCity`),
      render: (places: IFindRoutes[0]['places']) => {
        const withdrawalPlace = places?.find(
          place => place.move_type === MoveTypeEnum.Withdrawal
        );

        return (
          <>{`${withdrawalPlace?.city_name} - ${withdrawalPlace?.state_id}`}</>
        );
      },
    },
    {
      dataIndex: 'places',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.deliveryPlace`),
      render: (places: IFindRoutes[0]['places']) => {
        const deliveryPlace = places?.find(
          place => place.move_type === MoveTypeEnum.Delivery
        );

        return <>{deliveryPlace?.name}</>;
      },
    },
    {
      dataIndex: 'places',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.deliveryCity`),
      render: (places: IFindRoutes[0]['places']) => {
        const deliveryPlace = places?.find(
          place => place.move_type === MoveTypeEnum.Delivery
        );

        return (
          <>{`${deliveryPlace?.city_name} - ${deliveryPlace?.state_id}`}</>
        );
      },
    },
    {
      dataIndex: 'total_distance',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.totalDistance`),
      render: total_distance => (
        <>{Formatter.formatDistance(total_distance, 'km')}</>
      ),
    },
    {
      dataIndex: 'estimate_time',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.estimatedTime`),
      render: estimate_time => (
        <>{Formatter.formatMsToHoursMinutes(estimate_time * 1000)}</>
      ),
    },
    {
      dataIndex: 'actions',
      title: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.actions`),
      render: (_, { id }) => (
        <Space>
          <RouteViewButtonAccess routeId={id} />
        </Space>
      ),
    },
  ];

  const rowSelection: TableProps<IFindRoutes[0]>['rowSelection'] =
    selectableRows
      ? {
          type: 'radio',
          onChange: selectedRows =>
            onRouteSelect && onRouteSelect(String(selectedRows[0])),
          columnTitle: t(`${ROUTES_TABLE_TRANSLATION_PATH}.title.rowSelection`),
          getCheckboxProps: () => ({
            disabled,
          }),
          selectedRowKeys: selectedRowKeys,
        }
      : undefined;

  return (
    <Card title={cardTitle} bordered={bordered}>
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={routes}
        loading={loading}
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
        rowKey={route => route.id}
        rowSelection={rowSelection}
        size={size}
      />
    </Card>
  );
};
