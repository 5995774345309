import { InboxOutlined } from '@ant-design/icons';
import {
  Alert,
  Form,
  FormProps,
  Modal,
  ModalProps,
  Upload,
  UploadFile,
} from 'antd';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationConfirmationSelectFragment } from '../../../graphql/generated/graphql';
import { QuotationConfirmationSelect } from '../ConfirmQuotation/QuotationConfirmationSelect/QuotationConfirmationSelect';

export interface IUploadCteFormValues {
  confirmation_id: string;
  file: { file: NonNullable<UploadFile['originFileObj']> };
}

interface IUploadCteModalProps extends ModalProps {
  formProps?: FormProps<IUploadCteFormValues>;
  options?: QuotationConfirmationSelectFragment[];
}

const TRANSLATION_PATH = 'components.quotation.uploadCteModal';

export const UploadCteModal = ({
  formProps,
  options,
  ...modalProps
}: IUploadCteModalProps): ReactElement => {
  const { t } = useTranslation();

  const [uploadError, setUploadError] = useState<string>();

  const beforeUpload = (file: NonNullable<UploadFile['originFileObj']>) => {
    const acceptedTypes = ['application/xml', 'text/xml'];

    if (!acceptedTypes.includes(file.type)) {
      setUploadError(t(`${TRANSLATION_PATH}.upload.rules.acceptedFileTypes`));

      return Upload.LIST_IGNORE;
    }

    const maxFileSize1MB = 1000000;
    if (file.size > maxFileSize1MB) {
      setUploadError(t(`${TRANSLATION_PATH}.upload.rules.maximumFileSize`));

      return Upload.LIST_IGNORE;
    }

    return false;
  };

  return (
    <Modal title={t(`${TRANSLATION_PATH}.title`)} {...modalProps}>
      <Form layout="vertical" {...formProps}>
        <Form.Item
          label={t(`${TRANSLATION_PATH}.form.confirmation`)}
          name="confirmation_id"
          required
          rules={[{ required: true }]}
        >
          <QuotationConfirmationSelect options={options || []} allowClear />
        </Form.Item>
        <Form.Item
          label={t(`${TRANSLATION_PATH}.form.cte`)}
          name="file"
          required
          rules={[{ required: true }]}
        >
          <Upload.Dragger
            accept=".xml"
            beforeUpload={beforeUpload}
            maxCount={1}
            name="cte"
            style={{ display: 'block' }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t(`${TRANSLATION_PATH}.upload.text`)}
            </p>
            <p className="ant-upload-hint">
              {t(`${TRANSLATION_PATH}.upload.hint`)}
            </p>
          </Upload.Dragger>
        </Form.Item>
        {uploadError && (
          <Alert
            type="error"
            message={uploadError}
            style={{ marginBottom: 24 }}
          />
        )}
      </Form>
    </Modal>
  );
};
