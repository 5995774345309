import {
  ProductVarietyQueryVariables,
  useProductVarietyQuery,
} from '../../../graphql/generated/graphql';

export interface IUseProductVarietySelectOptionsProps {
  productId?: string;
  search?: string;
}

export const useProductVarietySelectOptions = ({
  productId,
  search,
}: IUseProductVarietySelectOptionsProps = {}) => {
  const variables: ProductVarietyQueryVariables | undefined = productId
    ? {
        productId,
        where: search ? { name: { contains: search } } : undefined,
      }
    : undefined;

  const [result] = useProductVarietyQuery({ variables, pause: !variables });

  const options = result?.data?.FindManyVarieties.data.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return { result, options };
};
