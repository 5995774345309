/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import SignUpMessage from '..';
import ClockCircleOutlined from '../../../../assets/IconComponents/ClockCircleOutlined';

export const AccountInApproval = (): ReactElement<unknown> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as { companyId: string } | undefined;

  useEffect(() => {
    if (!locationState?.companyId) {
      navigate('/', { replace: true });
    }
  }, []);

  const handleConfirm = () => {
    navigate('/');
  };

  return (
    <SignUpMessage
      buttonAction={handleConfirm}
      image={<ClockCircleOutlined />}
      className="sign-up_account-in-approval"
      title={t('pages.signUp.messages.accountInApproval.title')}
      buttonText={t('pages.signUp.messages.accountInApproval.buttonText')}
    >
      <div className="description-text">
        <span>
          {t('pages.signUp.messages.accountInApproval.description.0')}
        </span>
        <br />
        <span>
          {t('pages.signUp.messages.accountInApproval.description.1')}
        </span>
      </div>
    </SignUpMessage>
  );
};

export default AccountInApproval;
