import { gql, TypedDocumentNode } from 'urql';

import { UuidFilter } from '../../graphql/generated/graphql';
import {
  ICompanyAPI,
  IDeleteCarrierPolicy,
  IDeleteCarrierPolicyResponse,
  IDeleteCompanyLogo,
  IDeleteCompanyLogoResponse,
  IFindCompanyByIdAPI,
  IUploadCarrierPolicy,
  IUploadCarrierPolicyResponse,
  IUploadCompanyLogo,
  IUploadCompanyLogoResponse,
} from '../interfaces/Company/Company';
import {
  IComplementCompanyDto,
  ICreateCompanyDto,
  IRectifyCompanyDto,
} from '../interfaces/Company/CompanyDto';
import { IReproachReasonAPI } from '../interfaces/Company/Reproach';
import { addressFragment } from './Address';

const contactFragment = `
  id
  name
  email
  phone
`;

const companyFragment = `
  id
  documentNumber: document_number
  documentType: document_type
  tradeName: trade_name
  companyName: company_name
  urlLogo: url_logo
  companyFunction: company_function
  companyType: company_type
  companyStatus {
    id
    status
  }
`;

const companyDataFragment = `
  contacts {
    ${contactFragment}
  }
  address {
    ${addressFragment}
  }
`;

const carrierCompanyDataFragment = `
  hasFleet: has_fleet
  fleetQuantity: fleet_quantity

  operationAreas: operationArea {
    stateId: state_id
  }

  policies {
    id
    name
    expiresIn: expires_in
    fileUrl: bucket_url
  }
`;

const queries = {
  registerCompany: (): TypedDocumentNode<
    { createdCompany: ICompanyAPI },
    { createCompanyDto: ICreateCompanyDto }
  > => gql`
    mutation RegisterCompany($createCompanyDto: CreateCompanyDto!) {
      createdCompany: CreateCompany(input: $createCompanyDto) {
        ${companyFragment}
        ${companyDataFragment}
        motherCompany: mother_company {
          ${companyFragment}
          ${companyDataFragment}
        }
        referenceCompany: reference_company {
          id
          documentNumber: document_number
          documentType: document_type
        }
      }
    }
  `,
  rectifyCompany: (): TypedDocumentNode<
    { rectifyCompany: ICompanyAPI },
    { rectifyCompanyDto: IRectifyCompanyDto }
  > => gql`
  mutation RectifyCompany($rectifyCompanyDto: RectifyCompanyInput!) {
    rectifyCompany: RectifyCompany(input: $rectifyCompanyDto) {
      ${companyFragment}
      ${companyDataFragment}
      motherCompany: mother_company {
        ${companyFragment}
        ${companyDataFragment}
      }
      referenceCompany: reference_company {
        id
        documentNumber: document_number
        documentType: document_type
      }
    }
  }
`,
  complementCompany: (): TypedDocumentNode<
    { complementedCompany: ICompanyAPI },
    { complementCompanyDto: IComplementCompanyDto }
  > => gql`
    mutation ComplementCompany($complementCompanyDto: ComplementCompanyDto!) {
      complementedCompany: ComplementCompany(input: $complementCompanyDto) {
        ${companyFragment}
        ${companyDataFragment}
        motherCompany: mother_company {
          ${companyFragment}
          ${companyDataFragment}
        }
        referenceCompany: reference_company {
          id
          documentNumber: document_number
          documentType: document_type
        }
      }
    }
  `,
  findCompanyByDocument: (): TypedDocumentNode<
    { foundCompanies: ICompanyAPI[] },
    { documentNumber: string; idFilter?: UuidFilter }
  > => gql`
    query findCompany($documentNumber: String!, $idFilter: UuidFilter) {
      foundCompanies: FindCompanies(
        where: { document_number: { equals: $documentNumber }, id: $idFilter }
      ) {
        ${companyFragment}
        motherCompany: mother_company {
          ${companyFragment}
        }
      }
    }
  `,
  findCompanyById: (): TypedDocumentNode<
    { foundCompany: IFindCompanyByIdAPI },
    { companyId: string }
  > => gql`
    query FindCompanyById($companyId: String!) {
      foundCompany: FindCompany(company_id: $companyId) {
        ${companyFragment}
        ${companyDataFragment}
        ${carrierCompanyDataFragment}

        motherCompany: mother_company {
          ${companyFragment}
        }
      }
    }
  `,
  uploadCompanyLogo: (): TypedDocumentNode<
    { uploadedFile: IUploadCompanyLogoResponse },
    IUploadCompanyLogo
  > => gql`
    mutation UploadImage($file: Upload!, $input: UploadLogoInput!) {
      uploadedFile: UploadLogo(file: $file, input: $input) {
        url
      }
    }
  `,
  deleteCompanyLogo: (): TypedDocumentNode<
    { deletedLogo: IDeleteCompanyLogoResponse },
    IDeleteCompanyLogo
  > => gql`
    mutation DeleteLogo($input: DeleteLogoDto!) {
      deletedLogo: DeleteLogo(input: $input) {
        deleted
      }
    }
  `,
  uploadCarrierPolicy: (): TypedDocumentNode<
    { uploadedPolicy: IUploadCarrierPolicyResponse },
    IUploadCarrierPolicy
  > => gql`
    mutation UploadPolicy($file: Upload!, $input: PolicyInput!) {
      uploadedPolicy: UploadPolicy(file: $file, input: $input) {
        id
        name
        companyId: company_id
        url: bucket_url
        expiresIn: expires_in
      }
    }
  `,
  deleteCarrierPolicy: (): TypedDocumentNode<
    { deletedPolicy: IDeleteCarrierPolicyResponse },
    IDeleteCarrierPolicy
  > => gql`
    mutation DeletePolicy($input: DeletePolicyDto!) {
      deletedPolicy: DeletePolicySignUp(input: $input) {
        deleted
      }
    }
  `,
  findReproachReason: (): TypedDocumentNode<
    { reproachReason: IReproachReasonAPI },
    { companyId: string }
  > => gql`
    query FindReproach($companyId: String!) {
      reproachReason: FindReproach(input: { company_id: $companyId }) {
        reproach {
          id
          description
          isBlocked: is_blocked
          blockedAt: blocked_at
        }
        reasons {
          id
          description
          reasonType: reason_type
        }
      }
    }
  `,
};

export default queries;
