import {
  Button,
  Checkbox,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Select,
  SelectProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ReasonTypeEnum } from '../../../../../../../graphql/generated/graphql';

export interface IDisapproveCompanyFormValues {
  reasons: { label: string; value: ReasonTypeEnum }[];
  isBlocked: boolean;
  description: string;
}

export const DisapproveCompanyForm = (
  props: FormProps<IDisapproveCompanyFormValues>
) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.components.disapproveCompanyModal',
  });

  const { t: t0 } = useTranslation();

  const options: SelectProps['options'] = Object.values(ReasonTypeEnum).map(
    reasonType => ({
      label: t0(`enums.reasonTypeEnum.${reasonType}`),
      value: reasonType,
    })
  );

  return (
    <Form initialValues={{ isBlocked: false }} layout="vertical" {...props}>
      <Form.Item label=" " name="isBlocked" valuePropName="checked">
        <Checkbox>{t('form.labels.isBlocked')}</Checkbox>
      </Form.Item>
      <Form.Item
        label={t('form.labels.reasons')}
        name="reasons"
        required
        rules={[{ required: true }]}
      >
        <Select allowClear mode="multiple" labelInValue options={options} />
      </Form.Item>
      <Form.Item
        extra={t('form.extra.description')}
        label={t('form.labels.description')}
        name="description"
        required
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

export interface IDisapproveCompanyModalFooterProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const DisapproveCompanyModalFooter = ({
  onConfirm,
  onCancel,
}: IDisapproveCompanyModalFooterProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.components.disapproveCompanyModal.footer',
  });

  return (
    <>
      <Button onClick={onCancel} type="primary">
        {t('actions.cancel')}
      </Button>
      <Popconfirm
        cancelButtonProps={{ type: 'primary' }}
        okButtonProps={{ danger: true, type: 'default' }}
        okText={t('actions.submit.confirm')}
        onConfirm={onConfirm}
        title={t('actions.submit.popconfirm')}
      >
        <Button danger htmlType="submit">
          {t('actions.submit.confirm')}
        </Button>
      </Popconfirm>
    </>
  );
};
