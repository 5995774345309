import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IRangePickerPreset {
  label: string;
  value: [dayjs.Dayjs, dayjs.Dayjs];
}

export const useDatePickerDefaultPresets = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'hooks.useDatePickerDefaultPresets.presets',
  });

  const datePickerDefaultPresets: IRangePickerPreset[] = [
    { label: t('today'), value: [dayjs(), dayjs()] },
    {
      label: t('thisWeek'),
      value: [dayjs().startOf('week'), dayjs().endOf('week')],
    },
    {
      label: t('thisMonth'),
      value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
      label: t('lastWeek'),
      value: [
        dayjs().startOf('week').subtract(1, 'week'),
        dayjs().startOf('week').subtract(1, 'week').endOf('week'),
      ],
    },
    {
      label: t('lastMonth'),
      value: [
        dayjs().startOf('month').subtract(1, 'month'),
        dayjs().startOf('month').subtract(1, 'month').endOf('month'),
      ],
    },
  ];

  return datePickerDefaultPresets;
};
