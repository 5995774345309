import { BreadcrumbProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { ConfirmCreateFreight } from '../../../../components/Freight/Freights/ConfirmCreateFreight/ConfirmCreateFreight';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { breadcrumbItemRender } from '../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

export const ConfirmFreightPage = (): ReactElement => {
  const CONFIRM_FREIGHT_PAGE_TRANSLATION_PATH =
    'pages.freight.freights.confirmFreightPage';

  const { t } = useTranslation();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/shipper/freights',
        breadcrumbName: t('tab.shipper.freight.index'),
      },
      {
        path: '/shipper/freights/create',
        breadcrumbName: t('pages.freight.freights.createFreightPage.title'),
      },
      {
        path: '/shipper/freights/confirm',
        breadcrumbName: t(`${CONFIRM_FREIGHT_PAGE_TRANSLATION_PATH}.title`),
      },
    ],
  };

  useTitle({ title: `${CONFIRM_FREIGHT_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/freights'],
  });

  return (
    <FixedFooterContainer>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        title={t(`${CONFIRM_FREIGHT_PAGE_TRANSLATION_PATH}.title`)}
      />
      <ConfirmCreateFreight />
    </FixedFooterContainer>
  );
};

const ConfirmFreightPageAccess = Access(ConfirmFreightPage, {
  acceptedPermissions: [
    {
      module: 'FREIGHTS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default ConfirmFreightPageAccess;
