import { Col, Row } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultPageHeader } from '../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { UserDetails } from '../../../components/User/UserDetails/UserDetails';
import { useMenuItem } from '../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../hooks/useTitle/useTitle';

export const UserDetailsPage = (): ReactElement => {
  const USER_DETAILS_PAGE_TRANSLATION_PATH = 'pages.users.userDetailsPage';

  const { t } = useTranslation();

  useTitle({ title: `${USER_DETAILS_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({ selectedMenu: ['/user'] });

  return (
    <>
      <DefaultPageHeader
        subTitle={t(`${USER_DETAILS_PAGE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${USER_DETAILS_PAGE_TRANSLATION_PATH}.title`)}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <UserDetails />
        </Col>
      </Row>
    </>
  );
};
