import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu, Avatar, Dropdown } from 'antd';

import EuaFlag from '../../assets/eua-flag.svg';
import SpainFlag from '../../assets/spain-flag.svg';
import BrazilFlag from '../../assets/brazil-flag.svg';

import './index.scss';

/**
 * @description Floating component to change panel language.
 * @example
 * <I18n />
 */

export const I18n = (): ReactElement<unknown> => {
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
  };

  const languages = [
    {
      key: 'pt_BR',
      text: t('languages.ptBR'),
      image: BrazilFlag,
    },
    {
      key: 'en_US',
      text: t('languages.enUS'),
      image: EuaFlag,
    },
    {
      key: 'es',
      text: t('languages.es'),
      image: SpainFlag,
    },
  ];

  const getCurrentLanguage = () => {
    const lg = i18n.language;
    const langs = languages.map(e => e.key);

    switch (lg) {
      case 'en_US':
      case 'pt_BR':
      case 'es':
        return languages[langs.indexOf(lg)];

      default:
        return languages[langs.indexOf('pt_BR')];
    }
  };

  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={
          <Menu>
            {languages.map(e => (
              <Menu.Item
                key={e.key}
                onClick={() => handleChangeLanguage(e.key)}
                style={{ minWidth: 120 }}
              >
                <Avatar size={18} src={e.image} style={{ marginRight: 6 }} />
                <span>{e.text}</span>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Avatar size={40} className="fixed-widgets">
          <Avatar size={22} src={getCurrentLanguage().image} />
        </Avatar>
      </Dropdown>
    </>
  );
};

export default I18n;
