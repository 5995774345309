import React, { ReactElement, useEffect, useState } from 'react';
import { ICreateFreightFormValues } from '../../../components/Freight/Freights/CreateFreightForm/CreateFreightForm';
import { ISetState } from '../../../utils/interfaces';
import { useUserContext } from '../../UserContext/useUserContext';

export interface ICreateFreightValidValues extends ICreateFreightFormValues {
  isValid: true;
}

type ICreateFreightUnknownValues = Partial<ICreateFreightFormValues> & {
  isValid?: false;
};

export type ICreateFreightValues =
  | ICreateFreightValidValues
  | ICreateFreightUnknownValues;

export interface ICreateFreightProvider {
  createFreightValues?: ICreateFreightValues;
  setCreateFreightValues: ISetState<ICreateFreightValues | undefined>;
}

interface ICreateFreightProviderProps {
  children: React.ReactNode;
}

export const CreateFreightProvider = ({
  children,
}: ICreateFreightProviderProps): ReactElement => {
  const [createFreightValues, setCreateFreightValues] =
    useState<ICreateFreightValues>();

  const { userData } = useUserContext();

  useEffect(() => {
    return () => {
      if (!userData) {
        setCreateFreightValues(undefined);
      }
    };
  }, [userData]);

  return (
    <CreateFreightContext.Provider
      value={{
        createFreightValues,
        setCreateFreightValues,
      }}
    >
      {children}
    </CreateFreightContext.Provider>
  );
};

export const CreateFreightContext = React.createContext<ICreateFreightProvider>(
  {
    setCreateFreightValues: () => null,
  }
);
