/* eslint-disable react-hooks/exhaustive-deps */
import { Form, FormInstance, FormItemProps, Row, SelectProps } from 'antd';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import FormSection from '.';
import Formatter from '../../../../classes/Formatter';
import InputText from '../../../../components/inputs/inputText';
import Select from '../../../../components/inputs/select';
import { useRequest } from '../../../../hooks/useRequest';
import AddressController from '../../../../structures/controllers/Address';
import {
  ICityName,
  IZipCodeAddress,
} from '../../../../structures/interfaces/Address';
import { UF, UFOptions } from '../../../../structures/interfaces/UF';
import { validateZipCode } from '../../../../utils/inputRules';
import { useSignUpContext } from '../../SignUpPage/SignUpContext/useSignUpContext/useSignUpContext';

interface ICompanyAddressInitialValues {
  validZipCode?: string;
  state?: UF;
  city?: ICityName;
}

interface ICompanyAddressProps {
  form: FormInstance;
  initialValues?: ICompanyAddressInitialValues;
}

const CompanyAddress = ({
  form,
  initialValues,
}: ICompanyAddressProps): ReactElement => {
  const companyAddressTextPath =
    'pages.registerCompany.sections.companyAddress';

  const { t } = useTranslation();

  const hasInitialized = useRef(false);

  const { accessToken = '' } = useSignUpContext();

  const addressController = useMemo(
    () =>
      new AddressController({
        accessToken: accessToken || '',
      }),
    [accessToken]
  );

  const initCitiesOptionsState = () => {
    if (initialValues?.city)
      return [{ label: initialValues.city.name, value: initialValues.city.id }];
    return [];
  };

  const [validZipCode, setValidZipCode] = useState(initialValues?.validZipCode);
  const [zipCodeAddress, setZipCodeAddress] = useState<IZipCodeAddress>();

  const [zipCodeAddressRequest, isZipCodeAddressRequesting] = useRequest(
    addressController.findZipCodeAddress
  );

  const [selectedState, setSelectedState] = useState(initialValues?.state);
  const [citiesOptions, setCitiesOptions] = useState<SelectProps['options']>(
    initCitiesOptionsState
  );

  const [citiesOptionsRequest, isCitiesOptionsRequesting] = useRequest(
    addressController.findCitiesByState
  );

  const zipCodeValidator = {
    validator: (_: unknown, zipCode: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (zipCode) {
          validateZipCode
            .validator(_, zipCode)
            .then(() => {
              const cleanZipCode = `${zipCode}`.replace(/\D/g, '');
              setValidZipCode(cleanZipCode);
              resolve();
            })
            .catch(() => {
              setValidZipCode(undefined);
              reject();
            });
        } else {
          setValidZipCode(undefined);
          resolve();
        }
      });
    },
    message: validateZipCode.message,
  };

  useEffect(() => {
    if (initialValues && !hasInitialized.current) {
      hasInitialized.current = true;

      if (initialValues?.city) {
        form.setFieldsValue({
          city: initialValues.city.id,
        });
      }

      if (initialValues?.state) {
        setSelectedState(initialValues.state);
      }

      if (initialValues?.validZipCode) {
        setValidZipCode(initialValues.validZipCode);
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (validZipCode) {
      zipCodeAddressRequest(validZipCode)
        .then(zipCodeAddress => {
          setZipCodeAddress(zipCodeAddress);

          const { address, district, city, state, zipCodeFormatted } =
            zipCodeAddress;

          flushSync(() => {
            setSelectedState(state.initials as UF);
          });

          form.setFieldsValue({
            address,
            city: city.id,
            state: state.initials,
            zipCode: zipCodeFormatted,
            district: district?.name,
            districtId: district?.id,
          });
        })
        .catch(() => {
          setZipCodeAddress(undefined);

          form.setFieldsValue({
            districtId: undefined,
          });

          return;
        });
    } else {
      setZipCodeAddress(undefined);

      form.setFieldsValue({
        districtId: undefined,
      });
    }
  }, [validZipCode]);

  useEffect(() => {
    setCitiesOptions([]);

    form.setFieldsValue({ city: undefined });

    if (selectedState) {
      citiesOptionsRequest(selectedState)
        .then(cities => {
          setCitiesOptions(
            cities
              .map(({ id, name }) => ({ label: name, value: id }))
              .sort((a, b) => a.label.localeCompare(b.label))
          );
        })
        .catch(() => {
          return;
        });
    }
  }, [selectedState]);

  const handleZipCodeBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ): void => {
    if (event.target.value.length === 0) {
      form.resetFields(['zipCode']);
    }
  };

  const zipCodeFormItemProps = isZipCodeAddressRequesting
    ? { hasFeedback: true, validateStatus: 'validating' }
    : { hasFeedback: true };

  const cityFormItemProps: FormItemProps = isCitiesOptionsRequesting
    ? { hasFeedback: true, validateStatus: 'validating' }
    : {};

  const isCityInputDisabled =
    isZipCodeAddressRequesting ||
    isCitiesOptionsRequesting ||
    !!zipCodeAddress?.city ||
    !selectedState;

  const supplementaryData = <Form.Item name={'districtId'} />;

  return (
    <FormSection sectionTitle={t(`${companyAddressTextPath}.title`)}>
      <>
        <Row>
          <InputText
            formItem
            validation
            size="large"
            name="zipCode"
            required={false}
            disabled={isZipCodeAddressRequesting}
            label={t(`${companyAddressTextPath}.fields.labels.zipCode`)}
            placeholder={t(
              `${companyAddressTextPath}.fields.placeholders.zipCode`
            )}
            normalize={Formatter.formatZipCode}
            otherRules={[zipCodeValidator]}
            onBlur={handleZipCodeBlur}
            otherPropsFormItem={zipCodeFormItemProps}
          />
          <Select
            formItem
            showSearch
            validation
            size="large"
            name="state"
            options={UFOptions}
            disabled={isZipCodeAddressRequesting || !!zipCodeAddress?.state}
            label={t(`${companyAddressTextPath}.fields.labels.state`)}
            placeholder={t(
              `${companyAddressTextPath}.fields.placeholders.state`
            )}
            onChange={selectedValue => {
              setSelectedState(selectedValue as UF);
            }}
          />
          <Select
            formItem
            validation
            showSearch
            size="large"
            options={citiesOptions}
            disabled={isCityInputDisabled}
            name="city"
            label={t(`${companyAddressTextPath}.fields.labels.city`)}
            placeholder={t(
              `${companyAddressTextPath}.fields.placeholders.city`
            )}
            otherPropsFormItem={cityFormItemProps}
          />
          <InputText
            formItem
            validation
            size="large"
            name="address"
            disabled={isZipCodeAddressRequesting || !!zipCodeAddress?.address}
            label={t(`${companyAddressTextPath}.fields.labels.address`)}
            placeholder={t(
              `${companyAddressTextPath}.fields.placeholders.address`
            )}
            otherRules={[
              {
                max: 100,
                message: `${companyAddressTextPath}.fields.rules.addressMaxLength`,
              },
            ]}
          />
          <InputText
            formItem
            validation
            size="large"
            name="district"
            disabled={isZipCodeAddressRequesting || !!zipCodeAddress?.district}
            required={false}
            label={t(`${companyAddressTextPath}.fields.labels.district`)}
            placeholder={t(
              `${companyAddressTextPath}.fields.placeholders.district`
            )}
            otherRules={[
              {
                max: 100,
                message: `${companyAddressTextPath}.fields.rules.districtMaxLength`,
              },
            ]}
          />
          <InputText
            formItem
            validation
            required={false}
            size="large"
            name="addressNumber"
            label={t(`${companyAddressTextPath}.fields.labels.addressNumber`)}
            otherRules={[
              {
                max: 10,
                message: `${companyAddressTextPath}.fields.rules.numberMaxLength`,
              },
            ]}
          />
        </Row>
        <Row>
          <InputText
            formItem
            validation
            required={false}
            size="large"
            name="complement"
            label={t(`${companyAddressTextPath}.fields.labels.complement`)}
            otherPropsFormItem={{ style: { maxWidth: '800px' } }}
            otherRules={[
              {
                max: 100,
                message: `${companyAddressTextPath}.fields.rules.complementMaxLength`,
              },
            ]}
          />
        </Row>
        <Row style={{ display: 'none' }}>{supplementaryData}</Row>
      </>
    </FormSection>
  );
};

export default CompanyAddress;
