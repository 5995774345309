/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Normalizer from '../../../../classes/Normalizer';
import useCityStateSelects from '../../../../hooks/useCityStateSelects/useCityStateSelects';
import useQueryParams from '../../../../hooks/useQueryParams/useQueryParams';
import { UF } from '../../../../structures/interfaces/UF';

export interface IPlaceFilterForm {
  cadence?: string;
  city?: number;
  hasBalance?: string;
  hasDirtRoad?: string;
  search?: string;
  state?: UF;
}

interface IPlaceFilterProps {
  /**
   * Function to execute when "Filter" button is clicked
   */
  onFilter?: (formValue: IPlaceFilterForm) => void;
  /**
   * Function to execute when "Reset" button is clicked
   */
  onReset?: () => void;
}

/**
 * Form to filter places
 */
export const PlaceFilter = ({
  onFilter,
  onReset,
}: IPlaceFilterProps): ReactElement => {
  const PLACE_FILTER_TRANSLATION_PATH = 'components.freight.place.placeFilter';

  const { t } = useTranslation();

  const [form] = Form.useForm<IPlaceFilterForm>();

  const [getQueryParams, setQueryParams] = useQueryParams();

  const [stateFormItem, cityFormItem] = useCityStateSelects({
    form,
    city: {
      name: 'city',
      label: t(`${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.city`),
      selectProps: {
        allowClear: true,
        showSearch: true,
        placeholder: t(
          `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.city`
        ),
        size: 'small',
      },
    },
    state: {
      name: 'state',
      label: t(`${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.state`),
      selectProps: {
        allowClear: true,
        showSearch: true,
        placeholder: t(
          `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.state`
        ),
        size: 'small',
      },
    },
  });

  const colSpan = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
    xl: 3,
    xxl: 3,
  };

  const onFinish = (formValues: IPlaceFilterForm) => {
    const { hasBalance, hasDirtRoad, ...otherValues } = formValues;

    setQueryParams({
      ...otherValues,
      hasBalance: hasBalance !== undefined ? String(hasBalance) : hasBalance,
      hasDirtRoad:
        hasDirtRoad !== undefined ? String(hasDirtRoad) : hasDirtRoad,
    });

    onFilter && onFilter(formValues);
  };

  useEffect(() => {
    form.setFieldsValue(getQueryParams());
    form.submit();
  }, []);

  return (
    <Card bordered={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onReset={() => {
          onReset && onReset();
        }}
      >
        <Row gutter={24} align="middle">
          <Col {...colSpan} xl={4} xxl={4}>
            <Form.Item
              name="search"
              label={t(`${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.search`)}
            >
              <Input.Search
                allowClear
                size="small"
                onSearch={() => form.submit()}
                placeholder={t(
                  `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.search`
                )}
              />
            </Form.Item>
          </Col>
          <Col {...colSpan}>{stateFormItem}</Col>
          <Col {...colSpan}>{cityFormItem}</Col>
          <Col {...colSpan}>
            <Form.Item
              name="cadence"
              normalize={Normalizer.onlyNumbers}
              label={t(
                `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.cadence`
              )}
            >
              <Input size="small" allowClear />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item
              name="hasDirtRoad"
              label={t(
                `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.hasDirtRoad`
              )}
            >
              <Select
                size="small"
                allowClear
                placeholder={t(
                  `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.hasDirtRoad`
                )}
              >
                <Select.Option value="true">{t(`general.yes`)}</Select.Option>
                <Select.Option value="false">{t(`general.no`)}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col {...colSpan} span={3}>
            <Form.Item
              name="hasBalance"
              label={t(
                `${PLACE_FILTER_TRANSLATION_PATH}.inputs.labels.hasBalance`
              )}
            >
              <Select
                size="small"
                allowClear
                placeholder={t(
                  `${PLACE_FILTER_TRANSLATION_PATH}.inputs.placeholders.hasBalance`
                )}
              >
                <Select.Option value="true">{t(`general.yes`)}</Select.Option>
                <Select.Option value="false">{t(`general.no`)}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={5} xxl={5}>
            <Row gutter={24} justify="end">
              <Col>
                <Button htmlType="reset" size="small">
                  {t(`${PLACE_FILTER_TRANSLATION_PATH}.actions.reset`)}
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" size="small">
                  {t(`${PLACE_FILTER_TRANSLATION_PATH}.actions.filter`)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default PlaceFilter;
