import { Modal, ModalProps, Typography } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export type IApproveCompanyModalProps = Omit<ModalProps, 'children' | 'okText'>;

export const ApproveCompanyModal = (
  props: IApproveCompanyModalProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.components.approveCompanyModal',
  });

  return (
    <Modal {...props} okText={t('okText')}>
      <Typography.Paragraph>{t('content')}</Typography.Paragraph>
    </Modal>
  );
};
