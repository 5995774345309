/* eslint-disable react-hooks/exhaustive-deps */
import { LeftOutlined } from '@ant-design/icons';
import { Card, Form, Popconfirm, Row, Typography, notification } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Formatter from '../../../classes/Formatter';
import Button from '../../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import Footer from '../../../components/container/heroContainer/heroCardContainer/footer';
import InputText from '../../../components/inputs/inputText';
import Select from '../../../components/inputs/select';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import { useAuthContext } from '../../../contexts/AuthContext/useAuthContext';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import { useRequest } from '../../../hooks/useRequest';
import UserController from '../../../structures/controllers/User';
import { CompanyFunctionOptions } from '../../../structures/interfaces/Company/CompanyFunction';
import { ICreateAccount } from '../../../structures/interfaces/User';
import { translateOptions } from '../../../utils';
import { validatePhoneNumber } from '../../../utils/inputRules';

import './index.scss';

export const SignUpCard = (): ReactElement<unknown> => {
  const { isUserLoggedIn, logout } = useAuthContext();
  const { termsOfUseUrl } = useUserContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isFormTouched, setIsFormTouched] = useState(false);

  const [form] = Form.useForm();

  const companyFunctionOptions = translateOptions(t, CompanyFunctionOptions);

  const [createAccountRequest, isCreateAccountRequesting] = useRequest(
    UserController.createAccount
  );

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleFormFinish = (values: ICreateAccount) => {
    const formValues: ICreateAccount = {
      ...values,
      email: values.email.toLowerCase(),
    };

    createAccountRequest(formValues)
      .then(success => {
        if (success) {
          navigate('/accountCreated', { replace: true, state: { success } });
        }
      })
      .catch(err =>
        notification.error({
          message: t(err.message),
          description: t(err.description),
        })
      );
  };

  const CancelButton = ({ onClick }: { onClick?: () => void }) => (
    <Button danger size="large" type="primary" onClick={onClick}>
      {t('pages.signUp.action.cancel')}
    </Button>
  );

  const loginForm = (
    <Form
      form={form}
      className="form"
      layout="vertical"
      requiredMark={false}
      onChange={() => setIsFormTouched(true)}
      initialValues={{ keepConnected: true }}
      onFinish={handleFormFinish}
    >
      <div className="form-group">
        <InputText
          formItem
          validation
          size="large"
          name="name"
          disabled={isCreateAccountRequesting}
          label={t('pages.signUp.fields.labels.name')}
          placeholder={t('pages.signUp.fields.placeholders.name')}
        />
        <InputText
          formItem
          validation
          size="large"
          name="email"
          disabled={isCreateAccountRequesting}
          label={t('pages.signUp.fields.labels.email')}
          placeholder={t('pages.signUp.fields.placeholders.email')}
          otherRules={[
            { type: 'email', message: t('input.rules.invalidEmail') },
          ]}
        />
        <InputText
          formItem
          validation
          size="large"
          name="phone"
          disabled={isCreateAccountRequesting}
          label={t('pages.signUp.fields.labels.phone')}
          placeholder={t('pages.signUp.fields.placeholders.phone')}
          normalize={Formatter.formatPhoneWithoutDDI}
          otherRules={[validatePhoneNumber]}
        />
        <Select
          formItem
          validation
          size="large"
          name="companyFunction"
          options={companyFunctionOptions}
          disabled={isCreateAccountRequesting}
          label={t('pages.signUp.fields.labels.companyFunction')}
          placeholder={t('pages.signUp.fields.placeholders.companyFunction')}
        />
      </div>
    </Form>
  );

  const popConfirmCancelButton = isFormTouched ? (
    <Popconfirm
      title={t('pages.signUp.confirm.cancel')}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={handleCancel}
    >
      <CancelButton />
    </Popconfirm>
  ) : (
    <CancelButton onClick={handleCancel} />
  );

  const submitButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      onClick={handleSubmit}
      loading={isCreateAccountRequesting}
    >
      {t('pages.signUp.action.submit')}
    </Button>
  );

  const backButton = (
    <div className="suspended-button">
      <Button
        size="large"
        type="text"
        icon={<LeftOutlined />}
        onClick={handleCancel}
        loading={isCreateAccountRequesting}
      >
        {t('pages.signUp.action.return')}
      </Button>
    </div>
  );

  const formHeader = (
    <TitleHeader
      centered
      titleText={t('pages.signUp.title')}
      subtitleText={t('pages.signUp.subtitle')}
    />
  );

  const formBody = (
    <>
      {loginForm}
      <Row className="terms-text">
        <Typography.Text>
          <Trans
            components={[
              <b key="b" />,
              <a
                href={termsOfUseUrl}
                key={termsOfUseUrl}
                rel="noopener noreferrer"
                target="_blank"
              />,
            ]}
            i18nKey={'pages.signUp.terms'}
            t={t}
          />
        </Typography.Text>
      </Row>
    </>
  );

  const formFooter = (
    <Footer>
      {popConfirmCancelButton}
      {submitButton}
    </Footer>
  );

  useEffect(() => {
    if (isUserLoggedIn) {
      logout();
    }
  }, []);

  return (
    <HeroCardContainer
      logoColumnSize="small"
      className="sign-up-card_container"
    >
      {backButton}
      <Card className="card_container" bordered={false}>
        <SupportHelpContact />
        {formHeader}
        {formBody}
      </Card>
      {formFooter}
    </HeroCardContainer>
  );
};

export default SignUpCard;
