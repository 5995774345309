import { ReactElement } from 'react';

import { Row } from 'antd';

import I18n from '../../i18n';

import './index.scss';

interface IHeroContainerProps {
  children: JSX.Element;
  i18nTab?: boolean;
}

const HeroContainer = ({
  children,
  i18nTab = false,
}: IHeroContainerProps): ReactElement => {
  return (
    <Row justify="center" align="middle" className="hero_container">
      {i18nTab && <I18n />}
      {children}
    </Row>
  );
};

export default HeroContainer;
