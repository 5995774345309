import { AttachmentForm } from './AttachmentForm/AttachmentForm';
import { useAttachmentFormSerializer } from './AttachmentForm/AttachmentForm.hooks';
import { AttachmentModal } from './AttachmentModal/AttachmentModal';
import { useAttachmentModal } from './AttachmentModal/AttachmentModal.hooks';
import { AttachmentsTable } from './AttachmentsTable/AttachmentsTable';

export const Attachments = {
  Modal: AttachmentModal,
  Form: AttachmentForm,
  Table: AttachmentsTable,

  useAttachmentFormSerializer,
  useAttachmentModal,
};
