import { BreadcrumbProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { CreateRoute } from '../../../../components/Freight/Route/CreateRoute/CreateRoute';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { breadcrumbItemRender } from '../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

export const CreateRoutePage = (): ReactElement => {
  const CREATE_ROUTE_PAGE_TRANSLATION_PATH =
    'pages.freight.route.createRoutePage';

  const { t } = useTranslation();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/shipper/routes',
        breadcrumbName: t('pages.freight.route.title'),
      },
      {
        path: '/shipper/routes/create',
        breadcrumbName: t(`${CREATE_ROUTE_PAGE_TRANSLATION_PATH}.title`),
      },
    ],
  };

  useTitle({ title: `${CREATE_ROUTE_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/routes'],
  });

  return (
    <FixedFooterContainer>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        subTitle={t(`${CREATE_ROUTE_PAGE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${CREATE_ROUTE_PAGE_TRANSLATION_PATH}.title`)}
      />
      <CreateRoute />
    </FixedFooterContainer>
  );
};

const CreateRoutePageAccess = Access(CreateRoutePage, {
  acceptedPermissions: [
    {
      module: 'ROUTES',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default CreateRoutePageAccess;
