import { SVGProps } from 'react';

const CheckCircleOutlined = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 198 198"
    {...props}
  >
    <g data-name="Grupo 16730" fill="none">
      <g data-name="Subtra\xE7\xE3o 1">
        <path d="M99 198a98.537 98.537 0 0 1-55.352-16.908A99.29 99.29 0 0 1 7.78 137.535a98.869 98.869 0 0 1 9.128-93.887A99.29 99.29 0 0 1 60.465 7.78a98.869 98.869 0 0 1 93.887 9.128 99.29 99.29 0 0 1 35.868 43.557 98.869 98.869 0 0 1-9.128 93.887 99.29 99.29 0 0 1-43.557 35.868A98.379 98.379 0 0 1 99 198Zm0-179a80 80 0 1 0 80 80 80.091 80.091 0 0 0-80-80Z" />
        <path
          d="M99 193c12.692 0 25.002-2.485 36.587-7.385a94 94 0 0 0 15.966-8.666 94.696 94.696 0 0 0 13.915-11.481 94.694 94.694 0 0 0 11.481-13.915 93.998 93.998 0 0 0 8.666-15.966C190.515 124.002 193 111.692 193 99c0-12.692-2.485-25.002-7.385-36.587a94 94 0 0 0-8.666-15.966 94.698 94.698 0 0 0-11.481-13.915 94.696 94.696 0 0 0-13.915-11.481 93.994 93.994 0 0 0-15.966-8.666C124.002 7.485 111.692 5 99 5c-12.692 0-25.002 2.485-36.587 7.385a93.995 93.995 0 0 0-15.966 8.666 94.697 94.697 0 0 0-13.915 11.481 94.695 94.695 0 0 0-11.481 13.915 93.994 93.994 0 0 0-8.666 15.966C7.485 73.998 5 86.308 5 99c0 12.692 2.485 25.002 7.385 36.587a93.995 93.995 0 0 0 8.666 15.966 94.698 94.698 0 0 0 11.481 13.915 94.696 94.696 0 0 0 13.915 11.481 93.998 93.998 0 0 0 15.966 8.666C73.998 190.515 86.308 193 99 193m0-179c46.87 0 85 38.13 85 85s-38.13 85-85 85-85-38.13-85-85 38.13-85 85-85m0 184c-13.365 0-26.33-2.618-38.535-7.78a99.012 99.012 0 0 1-16.817-9.128 99.71 99.71 0 0 1-14.651-12.089 99.71 99.71 0 0 1-12.09-14.651 99.008 99.008 0 0 1-9.127-16.817C2.618 125.33 0 112.365 0 99c0-13.365 2.618-26.33 7.78-38.535a99.008 99.008 0 0 1 9.128-16.817 99.71 99.71 0 0 1 12.089-14.651 99.71 99.71 0 0 1 14.651-12.09A99.008 99.008 0 0 1 60.465 7.78C72.67 2.618 85.635 0 99 0c13.365 0 26.33 2.618 38.535 7.78a99.008 99.008 0 0 1 16.817 9.128 99.71 99.71 0 0 1 14.651 12.089 99.71 99.71 0 0 1 12.09 14.651 99.012 99.012 0 0 1 9.127 16.817C195.382 72.67 198 85.635 198 99c0 13.365-2.618 26.33-7.78 38.535a99.011 99.011 0 0 1-9.128 16.817 99.709 99.709 0 0 1-12.089 14.651 99.709 99.709 0 0 1-14.651 12.09 99.011 99.011 0 0 1-16.817 9.127C125.33 195.382 112.365 198 99 198Zm0-179c-44.112 0-80 35.888-80 80s35.888 80 80 80 80-35.888 80-80-35.888-80-80-80Z"
          fill="#108e53"
        />
      </g>
      <g data-name="Uni\xE3o 24" opacity={0.228}>
        <path d="m85.758 138.713-28.991-28.992a9.5 9.5 0 0 1 13.435-13.435l22.777 22.778 46.82-46.82a9.5 9.5 0 0 1 13.435 13.435l-53.033 53.033a9.475 9.475 0 0 1-7.212 2.769q-.256.014-.513.014a9.47 9.47 0 0 1-6.718-2.782Z" />
        <path
          d="M92.476 141.496c.17 0 .342-.005.512-.014a9.476 9.476 0 0 0 7.213-2.77l53.033-53.033a9.5 9.5 0 0 0-13.435-13.435l-46.82 46.82-22.777-22.778a9.5 9.5 0 0 0-13.435 13.435l28.991 28.992a9.47 9.47 0 0 0 6.718 2.783m0 5c-1.881 0-3.716-.358-5.454-1.062a14.413 14.413 0 0 1-4.8-3.185l-28.99-28.992a14.413 14.413 0 0 1-3.186-4.8 14.446 14.446 0 0 1-1.062-5.453c0-1.881.358-3.716 1.062-5.454a14.413 14.413 0 0 1 3.185-4.8 14.417 14.417 0 0 1 4.8-3.184 14.446 14.446 0 0 1 5.454-1.062c1.88 0 3.715.357 5.453 1.062a14.413 14.413 0 0 1 4.8 3.185l19.241 19.241 43.284-43.284a14.413 14.413 0 0 1 4.8-3.185 14.446 14.446 0 0 1 5.453-1.062c1.881 0 3.716.358 5.454 1.062a14.413 14.413 0 0 1 4.8 3.185 14.417 14.417 0 0 1 3.184 4.8 14.446 14.446 0 0 1 1.062 5.454c0 1.88-.357 3.715-1.062 5.453a14.413 14.413 0 0 1-3.184 4.8l-53.034 53.033a14.413 14.413 0 0 1-4.799 3.185 14.434 14.434 0 0 1-5.944 1.053c-.172.006-.345.01-.517.01Z"
          fill="#108e53"
        />
      </g>
      <g data-name="Uni\xE3o 25">
        <path d="m73.758 135.713-28.991-28.992a9.5 9.5 0 0 1 13.435-13.435l22.777 22.778 46.82-46.82a9.5 9.5 0 0 1 13.435 13.435l-53.033 53.033a9.475 9.475 0 0 1-7.212 2.769q-.256.014-.513.014a9.47 9.47 0 0 1-6.718-2.782Z" />
        <path
          d="M80.476 138.496c.17 0 .342-.005.512-.014a9.476 9.476 0 0 0 7.213-2.77l53.033-53.033a9.5 9.5 0 0 0-13.435-13.435l-46.82 46.82-22.777-22.778a9.5 9.5 0 0 0-13.435 13.435l28.991 28.992a9.47 9.47 0 0 0 6.718 2.783m0 5c-1.881 0-3.716-.358-5.454-1.062a14.413 14.413 0 0 1-4.8-3.185l-28.99-28.992a14.413 14.413 0 0 1-3.186-4.8 14.446 14.446 0 0 1-1.062-5.453c0-1.881.358-3.716 1.062-5.454a14.413 14.413 0 0 1 3.185-4.8 14.417 14.417 0 0 1 4.8-3.184 14.446 14.446 0 0 1 5.454-1.062c1.88 0 3.715.357 5.453 1.062a14.413 14.413 0 0 1 4.8 3.185l19.241 19.241 43.284-43.284a14.413 14.413 0 0 1 4.8-3.185 14.446 14.446 0 0 1 5.453-1.062c1.881 0 3.716.358 5.454 1.062a14.413 14.413 0 0 1 4.8 3.185 14.417 14.417 0 0 1 3.184 4.8 14.446 14.446 0 0 1 1.062 5.454c0 1.88-.357 3.715-1.062 5.453a14.413 14.413 0 0 1-3.184 4.8l-53.034 53.033a14.413 14.413 0 0 1-4.799 3.185 14.434 14.434 0 0 1-5.944 1.053c-.172.006-.345.01-.517.01Z"
          fill="#108e53"
        />
      </g>
    </g>
  </svg>
);

export default CheckCircleOutlined;
