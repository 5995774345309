/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  ButtonProps,
  Card,
  Col,
  Descriptions,
  Form,
  Row,
  Space,
} from 'antd';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { CombinedError } from 'urql';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import {
  CteTableFragment,
  QuotationDetailsQuery,
  UnitTypeEnum,
  useCreateQuotationConfirmationsMutation,
  useDeleteQuotationConfirmationsMutation,
  useQuotationDetailsQuery,
  useUpdateQuotationConfirmationsMutation,
  useUploadCteModalMutation,
} from '../../../graphql/generated/graphql';
import { FixedFooterContainer } from '../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { DefaultPageFooter } from '../../AntdCustom/DefaultPageFooter/DefaultPageFooter';
import ErrorAlert from '../../AntdCustom/ErrorAlert/ErrorAlert';
import {
  AttachmentTableModal,
  AttachmentTableModalComponents,
} from '../../Freight/FreightDetails/Components/AttachmentsTableModal';
import { useAttachmentsTableModal } from '../../Freight/FreightDetails/Components/AttachmentsTableModal/AttachmentsTableModal.hooks';
import { FreightVolumeBar } from '../../Freight/FreightVolumeBar/FreightVolumeBar';
import { ConfirmFreightDates } from '../../Freight/Freights/ConfirmCreateFreight/ConfirmFreightDates/ConfirmFreightDates';
import { ConfirmFreightLoad } from '../../Freight/Freights/ConfirmCreateFreight/ConfirmFreightLoad/ConfirmFreightLoad';
import { QuotationShortDescription } from '../../Freight/Quotations/QuotationShortDescription/QuotationShortDescription';
import { ViewRoute } from '../../Freight/Route/ViewRoute/ViewRoute';
import { AbandonOrCancelQuotationModal } from '../AbandonOrCancelQuotation/AbandonOrCancelQuotationModal/AbandonOrCancelQuotationModal';
import { ConfirmQuotation } from '../ConfirmQuotation/ConfirmQuotation/ConfirmQuotation';
import { IQuotationConfirmationTableItem } from '../ConfirmQuotation/QuotationConfirmationTable/QuotationConfirmationTable';
import { CteTable } from '../CteTable/CteTable';
import { FinishQuotationModal } from '../FinishQuotationModal/FinishQuotationModal';
import {
  IUploadCteFormValues,
  UploadCteModal,
} from '../UploadCteModal/UploadCteModal';
import { toConfirmFreightLoad } from './QuotationDetails.utils';
import { useConfirmQuotationStatus } from './useConfirmQuotationStatus';

interface IQuotationDetailsProps {
  quotationId?: string;
  setFinishButtonProps?: Dispatch<SetStateAction<ButtonProps>>;
}

const QUOTATION_DETAILS_TRANSLATION_PATH =
  'components.quotation.quotationDetails';

export const QuotationDetails = ({
  quotationId,
  setFinishButtonProps,
}: IQuotationDetailsProps): ReactElement => {
  const variables = quotationId ? { quotationId } : undefined;

  const [findFreightResult] = useQuotationDetailsQuery({
    variables,
    pause: !variables,
  });

  const { data, fetching, error } = findFreightResult;

  if (fetching || error || !data) {
    return <QuotationDetailsLoading {...findFreightResult} />;
  }

  const freightStatus = data.FindQuotation.freight.status.name;
  const quotationStatus = data.FindQuotation.status.name;

  const hiredFreightStatus = ['HIRED', 'CARRYING'];
  const hiredQuotationStatus = ['HIRED', 'CARRYING', 'LOADED'];

  const finishedFreightStatus = ['FINISHED', 'CANCELED', 'UNCOMPLETED'];
  const finishedQuotationStatus = [
    'FINISHED',
    'DROPPED',
    'CANCELED',
    'ABANDONED',
  ];

  if (quotationStatus === 'WAITING') {
    return <QuotationWaitingDetails {...findFreightResult} />;
  } else if (
    hiredFreightStatus.includes(freightStatus) &&
    hiredQuotationStatus.includes(quotationStatus)
  ) {
    return (
      <QuotationHiredDetails
        {...findFreightResult}
        setFinishButtonProps={setFinishButtonProps}
      />
    );
  } else if (
    finishedFreightStatus.includes(freightStatus) ||
    finishedQuotationStatus.includes(quotationStatus)
  ) {
    return <QuotationFinishedDetails {...findFreightResult} />;
  } else {
    return <Navigate to={'/carrier/freights'} replace />;
  }
};

interface IQuotationWaitingDetailsProps {
  data?: QuotationDetailsQuery;
  fetching?: boolean;
}

export const QuotationWaitingDetails = ({
  data,
  fetching,
}: IQuotationWaitingDetailsProps): ReactElement => {
  const freight =
    !fetching && data?.FindQuotation.freight
      ? data.FindQuotation.freight
      : undefined;

  return (
    <Row gutter={[0, 24]} style={{ padding: 24 }}>
      <Col span={24}>
        <ViewRoute
          cardStyle={{ minHeight: '100%' }}
          loading={fetching}
          mapStyle={{ maxHeight: 350 }}
          route={data?.FindQuotation.freight.route}
          showRouteName={false}
        />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <ConfirmFreightDates
              freightDates={data?.FindQuotation.freight}
              loading={fetching}
              title={null}
            />
          </Col>
          <Col span={24}>
            <ConfirmFreightLoad
              freightLoad={toConfirmFreightLoad(freight)}
              loading={fetching}
            />
          </Col>
          <Col span={24}>
            <QuotationShortDescription
              loading={fetching}
              data={data?.FindQuotation}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

interface IQuotationHiredDetailsProps {
  data?: QuotationDetailsQuery;
  fetching?: boolean;
  setFinishButtonProps?: Dispatch<SetStateAction<ButtonProps>>;
}

export const QuotationHiredDetails = ({
  data,
  fetching,
  setFinishButtonProps,
}: IQuotationHiredDetailsProps): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { userData } = useUserContext();

  const [uploadCteModalForm] = Form.useForm<IUploadCteFormValues>();

  const [initialConfirmQuotations, setInitialConfirmQuotations] = useState<
    IQuotationConfirmationTableItem[]
  >([]);
  const [confirmQuotations, setConfirmQuotations] = useState<
    IQuotationConfirmationTableItem[]
  >([]);

  const [isUploadCteModalOpen, setIsUploadCteModalOpen] = useState(false);
  const [isAbandonQuotationModalOpen, setIsAbandonQuotationModalOpen] =
    useState(false);
  const [isFinishQuotationModalOpen, setIsFinishQuotationModalOpen] =
    useState(false);

  const {
    handlers: {
      onClose: handleAttachmentsTableModalClose,
      onOpen: handleAttachmentsTableModalOpen,
    },
    isOpen: isAttachmentsTableModalOpen,
    result: findAttachmentsResponse,
    error: attachmentsTableError,
    fetching: attachmentsTableFetching,
  } = useAttachmentsTableModal();

  const quotation = data
    ? { ...data.FindQuotation, confirmations: initialConfirmQuotations }
    : undefined;

  const [confirmQuotationStatus] = useConfirmQuotationStatus(quotation);

  const [ctes, setCtes] = useState<CteTableFragment[]>([]);

  const [
    createQuotationConfirmationsResult,
    executeCreateQuotationConfirmationsMutation,
  ] = useCreateQuotationConfirmationsMutation();

  const [
    deleteQuotationConfirmationsResult,
    executeDeleteQuotationConfirmationsMutation,
  ] = useDeleteQuotationConfirmationsMutation();

  const [
    updateQuotationConfirmationsResult,
    executeUpdateQuotationConfirmationsMutation,
  ] = useUpdateQuotationConfirmationsMutation();

  const [uploadCteModalResult, executeUploadCteModalMutation] =
    useUploadCteModalMutation();

  const confirmQuotationsToCreate = confirmQuotations.filter(
    confirmQuotation =>
      !initialConfirmQuotations.some(
        confirmation => confirmation.id === confirmQuotation.id
      )
  );

  const confirmQuotationsToDelete = initialConfirmQuotations.filter(
    confirmation =>
      !confirmQuotations.some(
        confirmQuotation => confirmation.id === confirmQuotation.id
      )
  );

  const confirmQuotationsToUpdate = confirmQuotations.filter(
    confirmQuotation => {
      const confirmation = initialConfirmQuotations.find(
        confirmation => confirmation.id === confirmQuotation.id
      );

      if (!confirmation) return false;

      return (
        confirmation.assigned_volume !== confirmQuotation.assigned_volume ||
        confirmation.vehicle_plate !== confirmQuotation.vehicle_plate ||
        confirmation.vehicle_type.id !== confirmQuotation.vehicle_type.id
      );
    }
  );

  const updateCtes = (ctes: CteTableFragment[], confirmationId: string) => {
    const confirmationCtes = ctes.filter(
      ({ confirmation }) => confirmation.id === confirmationId
    );

    const selectedInitialConfirmation = initialConfirmQuotations.find(
      ({ id }) => id === confirmationId
    );

    if (selectedInitialConfirmation) {
      selectedInitialConfirmation.ctes = confirmationCtes;
      setInitialConfirmQuotations(initialConfirmQuotations => [
        ...initialConfirmQuotations,
      ]);
    }

    const selectedConfirmation = confirmQuotations.find(
      ({ id }) => id === confirmationId
    );

    if (selectedConfirmation) {
      selectedConfirmation.ctes = confirmationCtes;
      setConfirmQuotations(confirmQuotations => [...confirmQuotations]);
    }

    setCtes(ctes);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (confirmQuotations.length && data?.FindQuotation.id) {
      let confirmQuotationsState = confirmQuotations;
      let initialConfirmQuotationsState = initialConfirmQuotations;

      if (confirmQuotationsToDelete.length) {
        await executeDeleteQuotationConfirmationsMutation({
          input: {
            quotation_id: data.FindQuotation.id,
            confirmations: confirmQuotationsToDelete.map(confirmQuotation => ({
              id: confirmQuotation.id,
            })),
          },
        }).then(({ data, error }) => {
          if (data) {
            initialConfirmQuotationsState =
              initialConfirmQuotationsState.filter(
                confirmQuotation =>
                  !data.DeleteQuotationConfirmations.some(
                    deletedConfirmQuotation =>
                      deletedConfirmQuotation.id === confirmQuotation.id
                  )
              );
          } else if (error) {
            confirmQuotationsState = [
              ...confirmQuotationsState,
              ...confirmQuotationsToDelete,
            ];
          }
        });
      }

      if (confirmQuotationsToUpdate.length) {
        await executeUpdateQuotationConfirmationsMutation({
          input: {
            quotation_id: data.FindQuotation.id,
            data: confirmQuotationsToUpdate.map(confirmQuotation => ({
              confirmation_id: confirmQuotation.id,
              assigned_volume: confirmQuotation.assigned_volume,
              vehicle_plate: confirmQuotation.vehicle_plate,
              vehicle_type_id: confirmQuotation.vehicle_type.id,
            })),
          },
        }).then(({ data, error }) => {
          if (data) {
            initialConfirmQuotationsState = initialConfirmQuotationsState.map(
              confirmQuotation => {
                const editedConfirmQuotation =
                  data.UpdateQuotationConfirmations.find(
                    ({ id }) => confirmQuotation.id === id
                  );

                if (editedConfirmQuotation) {
                  return {
                    ...confirmQuotation,
                    ...editedConfirmQuotation,
                    hasPersisted: true,
                  };
                }

                return confirmQuotation;
              }
            );
          } else if (error) {
            confirmQuotationsState = confirmQuotationsState.map(
              confirmQuotation => {
                const originalConfirmation = initialConfirmQuotations.find(
                  confirmation => confirmation.id === confirmQuotation.id
                );

                if (originalConfirmation) {
                  return { ...originalConfirmation, hasPersisted: true };
                }

                return confirmQuotation;
              }
            );
          }
        });
      }

      if (confirmQuotationsToCreate.length) {
        await executeCreateQuotationConfirmationsMutation({
          input: {
            quotation_id: data.FindQuotation.id,
            data: confirmQuotationsToCreate.map(confirmQuotation => ({
              assigned_volume: confirmQuotation.assigned_volume,
              vehicle_plate: confirmQuotation.vehicle_plate,
              vehicle_type_id: confirmQuotation.vehicle_type.id,
              cnh: confirmQuotation.driver.cnh,
              driver_name: confirmQuotation.driver.name,
            })),
          },
        }).then(({ data, error }) => {
          if (data && !error) {
            const notCreatedConfirmQuotations = confirmQuotationsState.filter(
              ({ id }) =>
                !confirmQuotationsToCreate.some(
                  createdConfirmQuotation => createdConfirmQuotation.id === id
                )
            );

            const createdConfirmQuotations =
              data.CreateQuotationConfirmations.map(confirmation => ({
                ...confirmation,
                hasPersisted: true,
              }));

            confirmQuotationsState = [
              ...createdConfirmQuotations,
              ...notCreatedConfirmQuotations,
            ];

            initialConfirmQuotationsState = [
              ...createdConfirmQuotations,
              ...initialConfirmQuotationsState,
            ];
          }
        });
      }

      setConfirmQuotations([...confirmQuotationsState]);
      setInitialConfirmQuotations([...initialConfirmQuotationsState]);
    }
  };

  const handleAddConfirmation = (
    confirmQuotation: IQuotationConfirmationTableItem
  ) => {
    setConfirmQuotations(confirmQuotations => [
      confirmQuotation,
      ...confirmQuotations,
    ]);
  };

  const handleEditConfirmation = (
    confirmQuotation: IQuotationConfirmationTableItem
  ) => {
    setConfirmQuotations(confirmQuotations => {
      const newConfirmQuotation = confirmQuotations.find(
        ({ id }) => id === confirmQuotation.id
      );

      if (newConfirmQuotation) {
        Object.assign(newConfirmQuotation, confirmQuotation);
      }

      return [...confirmQuotations];
    });
  };

  const handleRemoveConfirmation = (confirmQuotationId: string) => {
    setConfirmQuotations(confirmQuotations =>
      confirmQuotations.filter(
        confirmQuotation => confirmQuotation.id !== confirmQuotationId
      )
    );
  };

  const handleOpenCteModal = () => setIsUploadCteModalOpen(true);

  const handleCloseCteModal = () => setIsUploadCteModalOpen(false);

  const handleOpenAbandonQuotationModal = () => {
    setIsAbandonQuotationModalOpen(true);
  };

  const handleCloseAbandonQuotationModal = () => {
    setIsAbandonQuotationModalOpen(false);
  };

  const handleAbandonQuotationSuccess = () => {
    navigate('/carrier/freights');
  };

  const handleOpenFinishQuotationModal = () => {
    setIsFinishQuotationModalOpen(true);
  };

  const handleCloseFinishQuotationModal = () => {
    setIsFinishQuotationModalOpen(false);
  };

  const handleFinishQuotationSuccess = () => {
    navigate('/carrier/freights');
  };

  const handleUploadCte = (formValues: IUploadCteFormValues) => {
    const selectedInitialConfirmation = initialConfirmQuotations.find(
      ({ id }) => id === formValues.confirmation_id
    );

    if (
      userData?.company &&
      data &&
      selectedInitialConfirmation?.hasPersisted
    ) {
      executeUploadCteModalMutation({
        file: formValues.file.file,
        input: {
          confirmation_id: formValues.confirmation_id,
          driver_id: selectedInitialConfirmation.driver.id,
          owner_id: userData.company.id,
          quotation_id: data.FindQuotation.id,
        },
      }).then(({ data, error }) => {
        if (data?.Load.ctes && !error) {
          updateCtes(data.Load.ctes, selectedInitialConfirmation.id);

          uploadCteModalForm.resetFields();
        }

        handleCloseCteModal();
      });
    }
  };

  const handleDeleteCte = (cteId: string) => {
    const deletedCte = ctes.find(cte => cte.id === cteId);

    if (deletedCte) {
      updateCtes(
        ctes.filter(cte => cte.id !== cteId),
        deletedCte.confirmation.id
      );
    }
  };

  const initialFinishButtonProps: ButtonProps = {
    hidden: true,
  };

  const defaultFinishButtonProps: ButtonProps = {
    danger: true,
    hidden: false,
    onClick: handleOpenAbandonQuotationModal,
    type: 'default',
  };

  const loadedFinishButtonProps: ButtonProps = {
    type: 'primary',
    hidden: false,
    onClick: handleOpenFinishQuotationModal,
  };

  useEffect(() => {
    if (data?.FindQuotation.confirmations) {
      const confirmQuotations = data.FindQuotation.confirmations.map(
        confirmation => ({
          ...confirmation,
          hasPersisted: true,
        })
      );

      setConfirmQuotations(confirmQuotations);
      setInitialConfirmQuotations(
        Array.from(confirmQuotations, cq => ({ ...cq }))
      );

      const ctes = data.FindQuotation.confirmations
        .flatMap(confirmation => confirmation.ctes)
        .filter(cte => cte) as CteTableFragment[];

      setCtes(ctes);
    }
  }, [data]);

  useEffect(() => {
    if (
      data &&
      setFinishButtonProps &&
      confirmQuotationStatus &&
      confirmQuotationStatus !== 'EXPIRED'
    ) {
      if (confirmQuotationStatus === 'CONFIRMED') {
        const unloadedVolume = ctes.reduce(
          (sum, cte) => sum - cte.loaded_volume,
          data.FindQuotation.requested_volume
        );

        if (unloadedVolume <= 0) {
          setFinishButtonProps(loadedFinishButtonProps);
        } else {
          setFinishButtonProps(defaultFinishButtonProps);
        }
      } else {
        setFinishButtonProps(initialFinishButtonProps);
      }
    }
  }, [ctes, confirmQuotationStatus, data]);

  const isRequesting =
    createQuotationConfirmationsResult.fetching ||
    deleteQuotationConfirmationsResult.fetching ||
    updateQuotationConfirmationsResult.fetching;

  const freight = data?.FindQuotation.freight;

  const unitType = freight?.unit_type || UnitTypeEnum.Ton;

  const hasQuotationConfirmationsChanged =
    confirmQuotationsToCreate.length ||
    confirmQuotationsToDelete.length ||
    confirmQuotationsToUpdate.length;

  const requestedVolume = data?.FindQuotation.requested_volume || 0;
  const allocatedVolume = confirmQuotations.reduce(
    (sum, confirmQuotation) => sum + confirmQuotation.assigned_volume,
    0
  );

  const loadedVolume =
    ctes.reduce((sum, cte) => (cte ? sum + cte?.loaded_volume : sum), 0) || 0;

  const higherVolume =
    allocatedVolume > loadedVolume ? allocatedVolume : loadedVolume;

  const remainingVolume = requestedVolume - higherVolume;

  const unallocatedVolume = remainingVolume < 0 ? 0 : remainingVolume;
  const isAllVolumeLoaded = requestedVolume - loadedVolume <= 0;

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 24]}>
        <ErrorAlert error={createQuotationConfirmationsResult.error} />
        <ErrorAlert error={deleteQuotationConfirmationsResult.error} />
        <ErrorAlert error={updateQuotationConfirmationsResult.error} />
        <ErrorAlert error={uploadCteModalResult.error} />
        <Col span={24}>
          <ViewRoute
            loading={fetching}
            mapStyle={{ maxHeight: 350 }}
            route={data?.FindQuotation.freight.route}
            showRouteName={false}
            cardStyle={{ height: '100%' }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <ConfirmFreightDates
                freightDates={data?.FindQuotation.freight}
                loading={fetching}
                title={null}
              />
            </Col>
            <Col span={24}>
              <ConfirmFreightLoad
                extra={
                  <AttachmentTableModalComponents.OpenButton
                    onClick={handleAttachmentsTableModalOpen}
                  />
                }
                freightLoad={toConfirmFreightLoad(freight)}
                loading={fetching}
              />
            </Col>
            <Col span={24}>
              <QuotationShortDescription
                loading={fetching}
                data={data?.FindQuotation}
              />
            </Col>
            <Col span={24}>
              <ConfirmQuotation
                status={confirmQuotationStatus}
                onAdd={handleAddConfirmation}
                onEdit={handleEditConfirmation}
                onRemove={handleRemoveConfirmation}
                quotation={quotation}
                quotationConfirmations={confirmQuotations}
                disabled={isRequesting}
              />
            </Col>
            {confirmQuotationStatus === 'CONFIRMED' &&
              data?.FindQuotation.id && (
                <Col span={24}>
                  <Card>
                    <FreightVolumeBar
                      type="quotation"
                      data={{
                        total_volume: requestedVolume,
                        allocated_volume: allocatedVolume,
                        loaded_volume: loadedVolume,
                        unallocated_volume: unallocatedVolume,
                        unit_type: unitType,
                      }}
                    />
                  </Card>
                  <CteTable
                    onDeleteSuccess={handleDeleteCte}
                    quotationId={data.FindQuotation.id}
                    onUpload={handleOpenCteModal}
                    ctes={ctes}
                    hideUploadButton={isAllVolumeLoaded}
                    tableProps={{
                      size: 'small',
                      pagination: { pageSize: 5, hideOnSinglePage: true },
                    }}
                  />
                </Col>
              )}
          </Row>
        </Col>
      </FixedFooterContainer.Inner>
      <UploadCteModal
        open={isUploadCteModalOpen}
        onCancel={handleCloseCteModal}
        onOk={uploadCteModalForm.submit}
        okText={t('general.confirm')}
        cancelText={t('general.close')}
        options={initialConfirmQuotations}
        confirmLoading={uploadCteModalResult.fetching}
        formProps={{
          form: uploadCteModalForm,
          onFinish: handleUploadCte,
        }}
      />
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button onClick={handleBack} size="small">
              {t('general.back')}
            </Button>
            {confirmQuotationStatus !== 'EXPIRED' && (
              <Button
                htmlType="submit"
                disabled={fetching || !hasQuotationConfirmationsChanged}
                loading={isRequesting}
                onClick={handleSubmit}
                size="small"
                type="primary"
              >
                {t('general.confirm')}
              </Button>
            )}
          </Space>
        }
      />
      <AbandonOrCancelQuotationModal
        onCancel={handleCloseAbandonQuotationModal}
        open={isAbandonQuotationModalOpen}
        quotationId={data?.FindQuotation.id}
        type="Abandon"
        onAbandonSuccess={handleAbandonQuotationSuccess}
      />
      <FinishQuotationModal
        onCancel={handleCloseFinishQuotationModal}
        open={isFinishQuotationModalOpen}
        quotationId={data?.FindQuotation.id}
        onFinishSuccess={handleFinishQuotationSuccess}
      />
      <AttachmentTableModal
        handleClose={handleAttachmentsTableModalClose}
        isOpen={isAttachmentsTableModalOpen}
        queryResponse={findAttachmentsResponse}
        error={attachmentsTableError}
        loading={attachmentsTableFetching}
      />
    </>
  );
};

interface IQuotationFinishedDetailsProps {
  data?: QuotationDetailsQuery;
  fetching?: boolean;
}

export const QuotationFinishedDetails = ({
  data,
  fetching,
}: IQuotationFinishedDetailsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [confirmQuotationStatus] = useConfirmQuotationStatus(
    data?.FindQuotation
  );

  const [ctes, setCtes] = useState<CteTableFragment[]>([]);

  const {
    handlers: {
      onClose: handleAttachmentsTableModalClose,
      onOpen: handleAttachmentsTableModalOpen,
    },
    isOpen: isAttachmentsTableModalOpen,
    result: findAttachmentsResponse,
    error: attachmentsTableError,
    fetching: attachmentsTableFetching,
  } = useAttachmentsTableModal();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data?.FindQuotation.confirmations) {
      const ctes = data.FindQuotation.confirmations
        .flatMap(confirmation => confirmation.ctes)
        .filter(cte => cte) as CteTableFragment[];

      setCtes(ctes);
    }
  }, [data]);

  const cancellationAlert = useMemo(() => {
    type ICancellation = {
      description?: string | null | undefined;
      cancellationReason: {
        description: string;
      };
    };

    const defaultCancellation: ICancellation = {
      description: t(
        `${QUOTATION_DETAILS_TRANSLATION_PATH}.finishedQuotationWarning.cancellationDescriptionText`
      ),
      cancellationReason: {
        description: t(
          `${QUOTATION_DETAILS_TRANSLATION_PATH}.finishedQuotationWarning.cancellationReasonText`
        ),
      },
    };

    const cancellation: ICancellation | undefined =
      confirmQuotationStatus === 'EXPIRED'
        ? data?.FindQuotation.cancellation
          ? data.FindQuotation.cancellation[0]
          : defaultCancellation
        : undefined;

    if (!cancellation) return <></>;

    return (
      <Col span={24}>
        <Alert
          type="warning"
          message={t(
            `${QUOTATION_DETAILS_TRANSLATION_PATH}.finishedQuotationWarning.finishedQuotation`
          )}
          description={
            <Descriptions column={1} layout="vertical">
              <br />
              <Descriptions.Item
                label={t(
                  `${QUOTATION_DETAILS_TRANSLATION_PATH}.finishedQuotationWarning.cancellationReason`
                )}
              >
                {cancellation.cancellationReason.description}
              </Descriptions.Item>
              <Descriptions.Item
                label={t(
                  `${QUOTATION_DETAILS_TRANSLATION_PATH}.finishedQuotationWarning.cancellationDescription`
                )}
              >
                {cancellation.description}
              </Descriptions.Item>
            </Descriptions>
          }
        />
      </Col>
    );
  }, [data, confirmQuotationStatus]);

  const freight = data?.FindQuotation.freight;

  const requestedVolume = data?.FindQuotation.requested_volume || 0;
  const allocatedVolume =
    data?.FindQuotation.confirmations?.reduce(
      (sum, confirmQuotation) => sum + confirmQuotation.assigned_volume,
      0
    ) || 0;
  const unallocatedVolume = requestedVolume - allocatedVolume;
  const loadedVolume =
    ctes.reduce((sum, cte) => (cte ? sum + cte?.loaded_volume : sum), 0) || 0;
  const unitType = data?.FindQuotation.freight.unit_type || UnitTypeEnum.Ton;

  const confirmQuotations = data?.FindQuotation.confirmations?.map(
    confirmation => ({
      ...confirmation,
      hasPersisted: true,
    })
  );

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 24]}>
        {cancellationAlert}
        <Col span={24}>
          <ViewRoute
            loading={fetching}
            mapStyle={{ maxHeight: 350 }}
            route={data?.FindQuotation.freight.route}
            showRouteName={false}
            cardStyle={{ height: '100%' }}
          />
        </Col>
        {data?.FindQuotation.id && (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ConfirmFreightDates
                  freightDates={data.FindQuotation.freight}
                  loading={fetching}
                  title={null}
                />
              </Col>
              <Col span={24}>
                <ConfirmFreightLoad
                  extra={
                    <AttachmentTableModalComponents.OpenButton
                      onClick={handleAttachmentsTableModalOpen}
                    />
                  }
                  freightLoad={toConfirmFreightLoad(freight)}
                  loading={fetching}
                />
              </Col>
              <Col span={24}>
                <QuotationShortDescription
                  loading={fetching}
                  data={data.FindQuotation}
                />
              </Col>
              <Col span={24}>
                <ConfirmQuotation
                  quotation={data.FindQuotation}
                  quotationConfirmations={confirmQuotations}
                  disabled={true}
                />
              </Col>
              <Col span={24}>
                <Card>
                  <FreightVolumeBar
                    type="quotation"
                    data={{
                      total_volume: requestedVolume,
                      allocated_volume: allocatedVolume,
                      loaded_volume: loadedVolume,
                      unallocated_volume: unallocatedVolume,
                      unit_type: unitType,
                    }}
                  />
                </Card>
                <CteTable
                  quotationId={data.FindQuotation.id}
                  ctes={ctes}
                  hideActions
                  tableProps={{
                    size: 'small',
                    pagination: { pageSize: 5, hideOnSinglePage: true },
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button onClick={handleBack} size="small">
              {t('general.back')}
            </Button>
          </Space>
        }
      />
      <AttachmentTableModal
        handleClose={handleAttachmentsTableModalClose}
        isOpen={isAttachmentsTableModalOpen}
        queryResponse={findAttachmentsResponse}
        error={attachmentsTableError}
        loading={attachmentsTableFetching}
      />
    </>
  );
};

interface IQuotationDetailsLoadingProps {
  error?: CombinedError;
  fetching?: boolean;
}

export const QuotationDetailsLoading = ({
  error,
  fetching,
}: IQuotationDetailsLoadingProps) => {
  return (
    <Row gutter={[0, 24]} style={{ padding: 24 }}>
      <ErrorAlert error={error} />
      <Col span={24}>
        <ViewRoute
          loading={fetching}
          mapStyle={{ maxHeight: 350 }}
          showRouteName={false}
        />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <ConfirmFreightDates loading={fetching} title={null} />
          </Col>
          <Col span={24}>
            <ConfirmFreightLoad loading={fetching} />
          </Col>
          <Col span={24}>
            <QuotationShortDescription loading={fetching} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
