import { Button, ButtonProps, Popconfirm, PopconfirmProps } from 'antd';
import { useTranslation } from 'react-i18next';

export interface IDeleteButtonProps extends Partial<ButtonProps> {
  onClick?: () => void;
  popconfirmProps?: Partial<PopconfirmProps>;
}

const DeleteButton = ({
  popconfirmProps,
  children,
  onClick,
  ...props
}: IDeleteButtonProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'general' });

  return (
    <Popconfirm
      okButtonProps={{ danger: true }}
      okText={t(`delete`)}
      onConfirm={onClick}
      title={t(`deletePopconfirm`)}
      {...popconfirmProps}
    >
      <Button danger type="link" {...props}>
        {children || t('delete')}
      </Button>
    </Popconfirm>
  );
};

const EditButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'general' });

  return (
    <Button type="link" {...props}>
      {children || t('edit')}
    </Button>
  );
};

const ViewButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'general' });

  return (
    <Button type="link" {...props}>
      {children || t('view')}
    </Button>
  );
};

export const DefaultActionColumn = {
  DeleteButton,
  EditButton,
  ViewButton,
};
