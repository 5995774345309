import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { CarrierListFreights } from '../../../../components/Freight/Carrier/CarrierListFreights/CarrierListFreights';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';

export const CarrierFreightPage = (): ReactElement => {
  useTitle({ title: 'tab.carrier.freight.index' });
  useMenuItem({ selectedMenu: ['/carrier/freights'] });

  return <CarrierListFreights />;
};

const CarrierFreightPageAccess = Access(CarrierFreightPage, {
  acceptedPermissions: [
    {
      module: 'QUOTATION_CONFIRMATIONS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default CarrierFreightPageAccess;
