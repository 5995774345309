import { ReactElement, ReactNode } from 'react';

import { Row } from 'antd';

/**
 * @description Container subtitle header.
 * @property {string | Node} [title='primary'] Container header title text
 * @example
 * <SubHeader title="Subtitle" />
 */

interface ISubHeader {
  title: string | ReactNode;
}

const SubHeader = ({ title }: ISubHeader): ReactElement => {
  return <Row className="subheader">{title}</Row>;
};

export default SubHeader;
