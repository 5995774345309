/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useMemo } from 'react';
import HeroContainer from '../../../components/container/heroContainer';
import { useTitle } from '../../../hooks/useTitle/useTitle';
import CreatePasswordPage from '../CreatePasswordPage/CreatePasswordPage';
import ResumeSignUpCard from '../ResumeSignUpCard/ResumeSignUpCard';
import TermsOfUsePage from '../TermsOfUsePage/TermsOfUsePage';
import VerifyEmailPage from '../VerifyEmailPage/VerifyEmailPage';
import RegisterCompany from '../registerCompany';
import SignUpCard from '../signUpCard';
import AccountBlocked from '../signUpMessage/accountBlocked';
import SignUpMessageAccountCreated from '../signUpMessage/accountCreated';
import AccountInApproval from '../signUpMessage/accountInApproval';
import AccountReproved from '../signUpMessage/accountReproved';
import CompanyCreated from '../signUpMessage/companyCreated';
import { SignUpContextProvider } from './SignUpContext/SignUpContext';
import { ConfigProvider as AntDesignConfigProvider } from 'antd';
import mainTheme from '../../../layouts/MainLayout/mainTheme';

type ISignUpSteps =
  | 'signUp'
  | 'resumeSignUp'
  | 'accountCreated'
  | 'validateEmail'
  | 'createPassword'
  | 'termsOfUse'
  | 'registerCompany'
  | 'companyCreated'
  | 'accountBlocked'
  | 'accountReproved'
  | 'accountInApproval'
  | 'regularizeCompany';

interface ISignUpProps {
  currentStep: ISignUpSteps;
}

const SignUp = ({ currentStep }: ISignUpProps): ReactElement<unknown> => {
  useTitle({ title: `tab.${currentStep}` });

  const signUpStepsComponent: Record<ISignUpSteps, React.ReactElement> = {
    signUp: <SignUpCard />,
    resumeSignUp: <ResumeSignUpCard />,
    accountCreated: <SignUpMessageAccountCreated />,
    validateEmail: <VerifyEmailPage />,
    createPassword: <CreatePasswordPage />,
    termsOfUse: <TermsOfUsePage />,
    registerCompany: <RegisterCompany action="create" />,
    companyCreated: <CompanyCreated />,
    accountBlocked: <AccountBlocked />,
    accountReproved: <AccountReproved />,
    accountInApproval: <AccountInApproval />,
    regularizeCompany: <RegisterCompany action="update" />,
  };

  return (
    <HeroContainer>
      <AntDesignConfigProvider theme={mainTheme.config}>
        <SignUpContextProvider>
          {useMemo(() => signUpStepsComponent[currentStep], [currentStep])}
        </SignUpContextProvider>
      </AntDesignConfigProvider>
    </HeroContainer>
  );
};

export default SignUp;
