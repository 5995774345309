import { Button, ButtonProps } from 'antd';
import { Access } from '../../../../Access/Access';
import { useTranslation } from 'react-i18next';

const OpenButton = (props: ButtonProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.freight.freightDetails.components.attachmentTableModal.components.openButton',
  });

  return (
    <Button type="primary" {...props}>
      {t('label')}
    </Button>
  );
};

export const OpenButtonAccess = Access(OpenButton, {
  acceptedPermissions: [{ module: 'FREIGHT_ATTACHMENTS', actions: ['READ'] }],
});
