import {
  Card,
  CardProps,
  Checkbox,
  Col,
  Descriptions,
  Form,
  FormInstance,
  FormProps,
  Row,
  Typography,
} from 'antd';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import { Volume } from '../../../core/Freight/Volume';
import { Price } from '../../../core/Price';
import { UnitTypeEnum } from '../../../graphql/generated/graphql';
import { parseLocaleNumber } from '../../../utils';
import { IFreight } from '../CreateQuotation/CreateQuotation';

const TRANSLATION_PATH = 'components.quotation.createQuotationForm';

export interface ICreateQuotationFormValues {
  price_per_unit: string;
  total_price: string;
  total_volume: string | number;
  has_vehicle?: boolean;
}

interface ICreateQuotationFormProps
  extends FormProps<ICreateQuotationFormValues> {
  averagePricePerUnit?: number;
  freight?: Pick<IFreight, 'id' | 'total_volume' | 'unit_type'>;
}

export const CreateQuotationForm = ({
  form,
  freight,
  averagePricePerUnit,
  ...props
}: ICreateQuotationFormProps): ReactElement => {
  const { t } = useTranslation();

  const totalVolumeValue = Form.useWatch('total_volume', form);
  const pricePerUnitValue = Form.useWatch('price_per_unit', form);
  const totalPriceValue = Form.useWatch('total_price', form);

  const unitType = freight?.unit_type || UnitTypeEnum.Ton;
  const unitTypeI18n = t(`enums.unitTypeLong.${unitType}`, { count: 1 });

  const focusedFieldRef = useRef<
    'price_per_unit' | 'total_price' | 'total_volume'
  >();

  const colSpan = {
    xxl: 6,
    xl: 8,
    lg: 12,
    md: 24,
    sm: 24,
    xs: 24,
  };

  const totalVolume = Volume.Utils.formatVolumeToUnitType(
    freight?.total_volume,
    freight?.unit_type
  );

  const averageTotalPrice =
    totalVolume && averagePricePerUnit
      ? Math.round(totalVolume * averagePricePerUnit)
      : 0;

  const calculatePricePerUnit = useCallback(
    (form: FormInstance<ICreateQuotationFormValues>) => {
      const totalPrice = Price.Utils.formatPriceInputToCents(totalPriceValue);
      const totalVolume = parseLocaleNumber(totalVolumeValue);

      if (totalPrice && totalVolume) {
        const newPricePerUnit = Math.round(totalPrice / totalVolume);

        if (newPricePerUnit > 0) {
          form.resetFields(['price_per_unit']);
          form.setFieldValue(
            'price_per_unit',
            Formatter.formatMoney(String(newPricePerUnit))
          );
          form.validateFields(['price_per_unit']);
        }
      }
    },
    [totalPriceValue, totalVolumeValue]
  );

  const calculateTotalPrice = useCallback(
    (form: FormInstance<ICreateQuotationFormValues>) => {
      const pricePerUnit =
        Price.Utils.formatPriceInputToCents(pricePerUnitValue);
      const totalVolume = parseLocaleNumber(totalVolumeValue);

      if (totalVolume === 0) {
        form.resetFields(['total_price']);
      }

      if (pricePerUnit && totalVolume && form) {
        const newTotalPrice = Math.round(pricePerUnit * totalVolume);

        form.resetFields(['total_price']);
        form.setFieldValue(
          'total_price',
          Formatter.formatMoney(String(newTotalPrice))
        );
        form.validateFields(['total_price']);
      }
    },
    [pricePerUnitValue, totalVolumeValue]
  );

  useEffect(() => {
    if (focusedFieldRef.current === 'total_price' && form) {
      calculatePricePerUnit(form);
    }
  }, [calculatePricePerUnit, form]);

  useEffect(() => {
    if (focusedFieldRef.current !== 'total_price' && form) {
      calculateTotalPrice(form);
    }
  }, [calculateTotalPrice, form]);

  useEffect(() => {
    if (totalVolumeValue === null || totalVolumeValue === undefined) {
      form?.setFieldValue(
        'total_volume',
        Volume.Utils.formatVolumeDisplay(
          freight?.total_volume,
          freight?.unit_type
        )
      );
    }
  }, [form, freight, totalVolumeValue]);

  const handleBlur = () => {
    focusedFieldRef.current = undefined;
  };

  const handleFinalPriceBlur = () => {
    handleBlur();

    if (form) {
      calculateTotalPrice(form);
    }
  };

  return (
    <Form form={form} layout="vertical" scrollToFirstError {...props}>
      <Row gutter={[24, 24]}>
        <Col {...colSpan}>
          <Price.FormItem
            name="price_per_unit"
            label={t(`${TRANSLATION_PATH}.fields.labels.price_per_unit`, {
              unitType: unitTypeI18n,
            })}
            tooltip={t(`${TRANSLATION_PATH}.fields.tooltips.price_per_unit`, {
              unitType: unitTypeI18n,
            })}
            required
            extra={
              <Descriptions>
                <Descriptions.Item
                  label={t(`${TRANSLATION_PATH}.fields.extra.average_value`)}
                >
                  <Price.Display price={averagePricePerUnit} />
                </Descriptions.Item>
              </Descriptions>
            }
          >
            <Price.Input
              onFocus={() => {
                focusedFieldRef.current = 'price_per_unit';
              }}
              onBlur={handleBlur}
            />
          </Price.FormItem>
        </Col>
        <Col {...colSpan}>
          <Price.FormItem
            name="total_price"
            label={t(`${TRANSLATION_PATH}.fields.labels.total_price`, {
              unitType: unitTypeI18n,
            })}
            tooltip={t(`${TRANSLATION_PATH}.fields.tooltips.total_price`, {
              unitType: unitTypeI18n,
            })}
            required
            extra={
              <Descriptions>
                <Descriptions.Item
                  label={t(`${TRANSLATION_PATH}.fields.extra.average_value`)}
                >
                  <Price.Display price={averageTotalPrice} />
                </Descriptions.Item>
              </Descriptions>
            }
          >
            <Price.Input
              onFocus={() => {
                focusedFieldRef.current = 'total_price';
              }}
              onBlur={handleFinalPriceBlur}
            />
          </Price.FormItem>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col {...colSpan}>
          <Volume.FormItem
            label={t(`${TRANSLATION_PATH}.fields.labels.total_volume`)}
            name="total_volume"
            required
            tooltip={t(`${TRANSLATION_PATH}.fields.tooltips.total_volume`)}
            unitType={unitType}
            rules={[
              { required: true },
              {
                type: 'number',
                min: 1,
                transform: parseLocaleNumber,
              },
              {
                type: 'number',
                max:
                  parseLocaleNumber(
                    Volume.Utils.formatVolumeDisplay(
                      freight?.total_volume,
                      freight?.unit_type
                    )
                  ) || 1000000,
                transform: parseLocaleNumber,
                message: t(
                  t(`${TRANSLATION_PATH}.fields.rules.max_total_volume`, {
                    max_total_volume:
                      Volume.Utils.formatVolumeDisplay(
                        freight?.total_volume,
                        freight?.unit_type
                      ) || 1000000,
                  })
                ),
              },
            ]}
          >
            <Volume.Input
              max={freight?.total_volume || 1000000}
              min={1}
              style={{ width: '100%' }}
              unitType={unitType}
              onFocus={() => {
                focusedFieldRef.current = 'total_volume';
              }}
              onBlur={handleBlur}
            />
          </Volume.FormItem>
        </Col>
        <Col {...colSpan}>
          <Form.Item name="has_vehicle" valuePropName="checked" label={' '}>
            <Checkbox>
              {t(`${TRANSLATION_PATH}.fields.labels.has_vehicle`)}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const CreateQuotationFormCard = ({ children, ...props }: CardProps) => {
  const { t } = useTranslation();

  return (
    <Card
      title={
        <Typography.Text>{t(`${TRANSLATION_PATH}.cardTitle`)}</Typography.Text>
      }
      bordered={false}
      {...props}
    >
      {children}
    </Card>
  );
};

CreateQuotationForm.Card = CreateQuotationFormCard;
