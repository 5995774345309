import { ComponentProps, ComponentType, ReactElement } from 'react';
import { IPermission } from '../../contexts/AuthContext/AuthContext';
import { useAuthContext } from '../../contexts/AuthContext/useAuthContext';
import { getAllowedAccesses } from './AccessUtils';

interface IAccessProps {
  acceptedPermissions: IPermission[];
  fallback?: ReactElement | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Access = <T extends ComponentType<any>>(
  Component: T,
  { acceptedPermissions, fallback = null }: IAccessProps
) => {
  type Props = ComponentProps<T>;

  const AccessComponent = (props: Props) => {
    const { permissions } = useAuthContext();

    if (!permissions) return fallback;

    const allowedAccesses = getAllowedAccesses({
      acceptedPermissions: acceptedPermissions,
      claimedPermissions: permissions,
    });

    if (allowedAccesses.length > 0) {
      return <Component {...props} />;
    }

    return fallback;
  };

  return AccessComponent;
};
