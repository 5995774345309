import { SVGProps } from 'react';

const ClockCircleOutlined = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 198 198"
    {...props}
  >
    <g data-name="Grupo 16732" fill="none">
      <g data-name="Subtra\xE7\xE3o 6">
        <path d="M99 198a98.537 98.537 0 0 1-55.352-16.908A99.29 99.29 0 0 1 7.78 137.535a98.869 98.869 0 0 1 9.128-93.887A99.29 99.29 0 0 1 60.465 7.78a98.869 98.869 0 0 1 93.887 9.128 99.29 99.29 0 0 1 35.868 43.557 98.869 98.869 0 0 1-9.128 93.887 99.29 99.29 0 0 1-43.557 35.868A98.379 98.379 0 0 1 99 198Zm0-179a80 80 0 1 0 80 80 80.091 80.091 0 0 0-80-80Z" />
        <path
          d="M99 193c12.692 0 25.002-2.485 36.587-7.385a94 94 0 0 0 15.966-8.666 94.696 94.696 0 0 0 13.915-11.481 94.694 94.694 0 0 0 11.481-13.915 93.998 93.998 0 0 0 8.666-15.966C190.515 124.002 193 111.692 193 99c0-12.692-2.485-25.002-7.385-36.587a94 94 0 0 0-8.666-15.966 94.698 94.698 0 0 0-11.481-13.915 94.696 94.696 0 0 0-13.915-11.481 93.994 93.994 0 0 0-15.966-8.666C124.002 7.485 111.692 5 99 5c-12.692 0-25.002 2.485-36.587 7.385a93.995 93.995 0 0 0-15.966 8.666 94.697 94.697 0 0 0-13.915 11.481 94.695 94.695 0 0 0-11.481 13.915 93.994 93.994 0 0 0-8.666 15.966C7.485 73.998 5 86.308 5 99c0 12.692 2.485 25.002 7.385 36.587a93.995 93.995 0 0 0 8.666 15.966 94.698 94.698 0 0 0 11.481 13.915 94.696 94.696 0 0 0 13.915 11.481 93.998 93.998 0 0 0 15.966 8.666C73.998 190.515 86.308 193 99 193m0-179c46.87 0 85 38.13 85 85s-38.13 85-85 85-85-38.13-85-85 38.13-85 85-85m0 184c-13.365 0-26.33-2.618-38.535-7.78a99.012 99.012 0 0 1-16.817-9.128 99.71 99.71 0 0 1-14.651-12.089 99.71 99.71 0 0 1-12.09-14.651 99.008 99.008 0 0 1-9.127-16.817C2.618 125.33 0 112.365 0 99c0-13.365 2.618-26.33 7.78-38.535a99.008 99.008 0 0 1 9.128-16.817 99.71 99.71 0 0 1 12.089-14.651 99.71 99.71 0 0 1 14.651-12.09A99.008 99.008 0 0 1 60.465 7.78C72.67 2.618 85.635 0 99 0c13.365 0 26.33 2.618 38.535 7.78a99.008 99.008 0 0 1 16.817 9.128 99.71 99.71 0 0 1 14.651 12.089 99.71 99.71 0 0 1 12.09 14.651 99.012 99.012 0 0 1 9.127 16.817C195.382 72.67 198 85.635 198 99c0 13.365-2.618 26.33-7.78 38.535a99.011 99.011 0 0 1-9.128 16.817 99.709 99.709 0 0 1-12.089 14.651 99.709 99.709 0 0 1-14.651 12.09 99.011 99.011 0 0 1-16.817 9.127C125.33 195.382 112.365 198 99 198Zm0-179c-44.112 0-80 35.888-80 80s35.888 80 80 80 80-35.888 80-80-35.888-80-80-80Z"
          fill="#ec9500"
        />
      </g>
      <g data-name="Uni\xE3o 39">
        <path d="M88.033 116.468a9.47 9.47 0 0 1-2.793-6.727q0-.327.022-.647V56.768a9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.499 9.5v43.472h40.478a9.5 9.5 0 1 1 0 19H95.409q-.321.02-.647.021a9.47 9.47 0 0 1-6.717-2.782Z" />
        <path
          d="M88.044 116.479a9.47 9.47 0 0 0 7.364 2.76h49.332a9.5 9.5 0 0 0 9.5-9.499c0-5.247-4.253-9.5-9.5-9.5H104.26V56.761c0-5.247-4.253-9.5-9.5-9.499a9.498 9.498 0 0 0-9.5 9.5v52.331c-.014.214-.02.43-.02.647a9.464 9.464 0 0 0 2.792 6.728l.011.01m-3.535 3.536-.012-.01-.01-.011a14.444 14.444 0 0 1-3.105-4.607 14.414 14.414 0 0 1-1.12-6.443V56.761c0-1.954.383-3.854 1.14-5.645a14.443 14.443 0 0 1 3.106-4.608 14.443 14.443 0 0 1 4.608-3.105 14.413 14.413 0 0 1 5.646-1.141c1.954 0 3.854.384 5.645 1.14a14.442 14.442 0 0 1 4.607 3.106 14.443 14.443 0 0 1 3.106 4.608 14.413 14.413 0 0 1 1.14 5.646l.001 38.478h35.478c1.956 0 3.855.384 5.646 1.141a14.444 14.444 0 0 1 4.608 3.106 14.443 14.443 0 0 1 3.106 4.608 14.413 14.413 0 0 1 1.14 5.646c0 1.954-.384 3.854-1.141 5.645a14.445 14.445 0 0 1-3.105 4.607 14.445 14.445 0 0 1-4.608 3.105 14.414 14.414 0 0 1-5.645 1.142H95.558a14.413 14.413 0 0 1-6.442-1.12 14.445 14.445 0 0 1-4.607-3.106Z"
          fill="#ec9500"
        />
      </g>
      <g data-name="Uni\xE3o 42" opacity={0.38}>
        <path d="M97.033 126.468a9.47 9.47 0 0 1-2.793-6.727q0-.327.022-.647V66.768a9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.499 9.5v43.472h40.478a9.5 9.5 0 1 1 0 19h-49.331q-.321.02-.647.021a9.47 9.47 0 0 1-6.717-2.782Z" />
        <path
          d="M97.044 126.479a9.47 9.47 0 0 0 7.364 2.76h49.332a9.5 9.5 0 0 0 9.5-9.499c0-5.247-4.253-9.5-9.5-9.5H113.26V66.761c0-5.247-4.253-9.5-9.5-9.499a9.498 9.498 0 0 0-9.5 9.5v52.331c-.014.214-.02.43-.02.647a9.464 9.464 0 0 0 2.792 6.728l.011.01m-3.535 3.536-.012-.01-.01-.011a14.444 14.444 0 0 1-3.105-4.607 14.414 14.414 0 0 1-1.12-6.443V66.761c0-1.954.383-3.854 1.14-5.645a14.443 14.443 0 0 1 3.106-4.608 14.443 14.443 0 0 1 4.608-3.105 14.413 14.413 0 0 1 5.646-1.141c1.954 0 3.854.384 5.645 1.14a14.442 14.442 0 0 1 4.607 3.106 14.443 14.443 0 0 1 3.106 4.608 14.413 14.413 0 0 1 1.14 5.646l.001 38.478h35.478c1.956 0 3.855.384 5.646 1.141a14.444 14.444 0 0 1 4.608 3.106 14.443 14.443 0 0 1 3.106 4.608 14.413 14.413 0 0 1 1.14 5.646c0 1.954-.384 3.854-1.141 5.645a14.445 14.445 0 0 1-3.105 4.607 14.445 14.445 0 0 1-4.608 3.105 14.414 14.414 0 0 1-5.645 1.142h-49.182a14.413 14.413 0 0 1-6.442-1.12 14.445 14.445 0 0 1-4.607-3.106Z"
          fill="#ec9500"
        />
      </g>
    </g>
  </svg>
);

export default ClockCircleOutlined;
