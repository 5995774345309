import { DocumentNode } from 'graphql';

import {
  OperationResult,
  OperationContext,
  PromisifiedSource,
  TypedDocumentNode,
} from 'urql';

import client from './client';
import errorHandler from '../errorHandler';
import { IAPIError } from '../../utils/interfaces';

type ICustomOperation = <
  Data = unknown,
  Variables extends Record<string, unknown> = Record<string, never>
>(
  operation: PromisifiedSource<OperationResult<Data, Variables>>
) => Promise<Data>;

type ICustomClientOperation = <
  Data = unknown,
  Variables extends Record<string, unknown> = Record<string, never>
>(
  query: DocumentNode | TypedDocumentNode<Data, Variables> | string,
  variables?: Variables,
  context?: Partial<OperationContext>
) => Promise<Data>;

interface ICustomClient {
  query: ICustomClientOperation;
  mutation: ICustomClientOperation;
}

const customOperation: ICustomOperation = operation => {
  return new Promise((resolve, reject) => {
    operation
      .toPromise()
      .then(({ data, error }) => {
        if (error)
          reject(errorHandler(error.graphQLErrors as unknown as IAPIError[]));

        data && resolve(data);
      })
      .catch(err => reject(err));
  });
};

const customClient: ICustomClient = {
  query: (query, variables, context) =>
    customOperation(client.query(query, variables, context)),

  mutation: (query, variables, context) =>
    customOperation(client.mutation(query, variables, context)),
};

export default customClient;
