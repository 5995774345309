/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryParams from '../../../../hooks/useQueryParams/useQueryParams';

export interface IProductTypesFilterFormValues {
  search?: string;
}

interface IProductTypesFilterProps {
  /**
   * Function to execute when form is successfully submitted
   */
  onFinish?: (values: IProductTypesFilterFormValues) => void;
}

/**
 * Filter to search for Product Types
 */
export const ProductTypesFilter = ({ onFinish }: IProductTypesFilterProps) => {
  const PRODUCT_TYPES_FILTER =
    'components.product.productType.productTypesFilter';

  const { t } = useTranslation();

  const [form] = Form.useForm<IProductTypesFilterFormValues>();

  const [getQueryParams, setQueryParams] = useQueryParams();

  const colSpan = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 6,
  };

  const handleFinish = (values: IProductTypesFilterFormValues) => {
    setQueryParams({ ...values });

    onFinish && onFinish(values);
  };

  useEffect(() => {
    form.setFieldsValue(getQueryParams());
    form.submit();
  }, []);

  return (
    <Card style={{ overflow: 'auto' }}>
      <Form onFinish={handleFinish} form={form}>
        <Row gutter={[24, 24]}>
          <Col {...colSpan}>
            <Form.Item
              name="search"
              hasFeedback={false}
              style={{ marginBottom: 0 }}
            >
              <Input.Search size="small" onSearch={() => form.submit()} />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space size="large">
                <Button size="small" htmlType="reset">
                  {t(`${PRODUCT_TYPES_FILTER}.actions.reset`)}
                </Button>
                <Button size="small" type="primary" htmlType="submit">
                  {t(`${PRODUCT_TYPES_FILTER}.actions.filter`)}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
