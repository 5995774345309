import { Descriptions, Skeleton } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { QuotationDescriptionsFragment } from '../../../graphql/generated/graphql';
import { ResponsiveDescriptions } from '../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { Price } from '../../../core/Price';
import { Volume } from '../../../core/Freight/Volume';
import { CompanyName } from '../../Company/CompanyName/CompanyName';

interface IQuotationDescriptions
  extends Omit<QuotationDescriptionsFragment, 'unallocated_volume'> {
  unallocated_volume?: number;
}

interface IQuotationDescriptionsProps {
  data?: IQuotationDescriptions;
  fetching?: boolean;
}

const TRANSLATION_PATH = 'components.quotation.quotationDescriptions';

export const QuotationDescriptions = ({
  data,
  fetching,
}: IQuotationDescriptionsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ResponsiveDescriptions
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout={{
          xs: 'vertical',
        }}
      >
        <Descriptions.Item label={t(`${TRANSLATION_PATH}.carrier`)}>
          <Skeleton active loading={fetching} paragraph={false}>
            {data && <CompanyName company={data.owner} />}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t(`${TRANSLATION_PATH}.pricePerUnit`)}>
          <Skeleton active loading={fetching} paragraph={false}>
            <Price.Display
              price={data?.value_per_unit}
              unitType={data?.value_unit_type}
            />
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
      <ResponsiveDescriptions
        column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label={t(`${TRANSLATION_PATH}.hasVehicle`)}>
          <Skeleton active loading={fetching} paragraph={false}>
            {data?.has_vehicle ? (
              <>{t('general.yes')}</>
            ) : data ? (
              <>{t('general.no')}</>
            ) : (
              ''
            )}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t(`${TRANSLATION_PATH}.requestedVolume`)}>
          <Skeleton active loading={fetching} paragraph={false}>
            <Volume.Display
              volume={data?.requested_volume}
              unitType={data?.value_unit_type}
            />
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t(`${TRANSLATION_PATH}.loadedVolume`)}>
          <Skeleton active loading={fetching} paragraph={false}>
            <Volume.Display
              volume={data?.loaded_volume}
              unitType={data?.value_unit_type}
            />
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
      <ResponsiveDescriptions column={1} layout="vertical">
        <Descriptions.Item label={t(`${TRANSLATION_PATH}.rating`)}>
          <Skeleton active loading={fetching} paragraph={false}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {/* {data?.owner.rating ? (
                <>
                  <Rate
                    disabled
                    defaultValue={data.owner.rating.average}
                    allowHalf
                  />
                  <b>{data.owner.rating?.average}</b>
                </>
              ) : ( */}
              {t(`${TRANSLATION_PATH}.notRated`)}
              {/* )} */}
            </div>
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
      {data && data.unallocated_volume && data.unallocated_volume > 0 ? (
        <ResponsiveDescriptions
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
          layout={{
            xs: 'vertical',
          }}
        >
          <Descriptions.Item label={<>{t(`${TRANSLATION_PATH}.caution`)}</>}>
            <Skeleton active loading={fetching} paragraph={false}>
              <Trans
                i18nKey={`${TRANSLATION_PATH}.cautionDescription`}
                t={t}
                components={[
                  <Volume.Display
                    key={data.unallocated_volume}
                    volume={data.unallocated_volume}
                    unitType={data.value_unit_type}
                  />,
                ]}
              />
            </Skeleton>
          </Descriptions.Item>
        </ResponsiveDescriptions>
      ) : null}
    </>
  );
};
