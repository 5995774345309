import { useMemo, useState } from 'react';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import {
  RefuseQuotationModalMutation,
  useRefuseQuotationModalMutation,
} from '../../../graphql/generated/graphql';
import { IRefuseQuotationFormValues } from './RefuseQuotationModal';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';

export const useRefuseQuotation = () => {
  const { userData } = useUserContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { freightId } = useParams();

  const [form] = Form.useForm<IRefuseQuotationFormValues>();

  const [refuseQuotationModalResult, executeRefuseQuotationModalMutation] =
    useRefuseQuotationModalMutation();

  const handlers = useMemo(() => {
    return {
      onOpen() {
        setIsModalOpen(true);
      },
      onClose() {
        setIsModalOpen(false);
      },
      onSubmit(
        formValues: IRefuseQuotationFormValues
      ): Promise<RefuseQuotationModalMutation> {
        return new Promise((resolve, reject) => {
          setIsModalOpen(false);

          if (freightId && userData?.company) {
            executeRefuseQuotationModalMutation({
              input: {
                description: `${formValues.reason_description ?? ''}`.trim(),
                reason_id: formValues.reason,
                carrier_id: userData.company.id,
                freight_id: freightId,
              },
            }).then(({ data, error }) => {
              if (error) {
                return reject(error);
              } else if (data) {
                return resolve(data);
              }

              return reject();
            });
          } else {
            reject();
          }
        });
      },
    };
  }, [executeRefuseQuotationModalMutation, freightId, userData]);

  return { isModalOpen, refuseQuotationModalResult, handlers, form };
};
