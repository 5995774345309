import { BreadcrumbProps } from 'antd';
import { Link } from 'react-router-dom';

export const breadcrumbItemRender: BreadcrumbProps['itemRender'] = (
  route,
  params,
  routes
) => {
  const isLastRoute = routes.indexOf(route) === routes.length - 1;

  if (isLastRoute) return <span>{route.breadcrumbName}</span>;

  if (route.path) return <Link to={route.path}>{route.breadcrumbName}</Link>;
};
