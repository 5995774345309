import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import { QuotationConfirmationSelectFragment } from '../../../../graphql/generated/graphql';

type IQuotationConfirmationSelect = Omit<SelectProps, 'options'> & {
  options?: QuotationConfirmationSelectFragment[];
};

/**
 * Ant design select with Product types options
 */
export const QuotationConfirmationSelect = ({
  loading,
  disabled,
  options,
  ...props
}: IQuotationConfirmationSelect) => {
  const { t } = useTranslation();

  const productTypesOptions: SelectProps['options'] = options?.map(
    ({ id, driver, vehicle_plate }) => ({
      label: `[${Formatter.formatMercosulPlate(vehicle_plate)}] ${driver.name}`,
      value: id,
    })
  );

  return (
    <>
      <Select
        {...props}
        loading={loading}
        disabled={disabled}
        options={productTypesOptions}
        notFoundContent={t('general.notFoundMessage')}
      />
    </>
  );
};
