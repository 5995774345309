import React, { ReactElement } from 'react';

import { LayoutContextProvider } from './contexts/LayoutContext/LayoutContext';
import { UserContextProvider } from './contexts/UserContext/UserContext';

import { AuthContextProvider } from './contexts/AuthContext/AuthContext';
import { UrqlContextProvider } from './contexts/UrqlContext/UrqlContext';
import FreightProviders from './providers/FreightProviders';

const Providers = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  return (
    <AuthContextProvider>
      <UrqlContextProvider>
        <LayoutContextProvider>
          <UserContextProvider>
            <FreightProviders>{children}</FreightProviders>
          </UserContextProvider>
        </LayoutContextProvider>
      </UrqlContextProvider>
    </AuthContextProvider>
  );
};

export default Providers;
