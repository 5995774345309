/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  TableColumnsType,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPermission } from '../../../contexts/AuthContext/AuthContext';
import { useAuthContext } from '../../../contexts/AuthContext/useAuthContext';
import { IUserData } from '../../../contexts/UserContext/UserContext';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import {
  QueryFindCompaniesArgs,
  useUserDetailsQuery,
} from '../../../graphql/generated/graphql';
import ErrorAlert from '../../AntdCustom/ErrorAlert/ErrorAlert';
import { useThemeSelect } from '../../theme/ThemeSelect/ThemeSelect.hooks';
import {
  EditCompanyDataButtonsAccess,
  EditingCompanyDataButtons,
} from './UserDetails.components';
import { useEditCompanyForm } from './UserDetails.hooks';

export const UserDetails = (): ReactElement => {
  const USER_DETAILS_TRANSLATION_PATH = 'components.user.userDetails';

  const { t, i18n } = useTranslation();

  const { permissions, roles, isAdmOrSup } = useAuthContext();
  const { userData, setUserData, companySettings, initialCompanySettings } =
    useUserContext();

  const [companyId, setCompanyId] = useState<string>();

  const {
    handlers: { onChange, onReset, onSuccess },
    isLoading,
    options,
    selectedThemeId,
  } = useThemeSelect();

  const {
    isEditing,
    handlers: { onEdit, onCancel, onSubmit },
    result: { fetching, error },
  } = useEditCompanyForm();

  const [form] = Form.useForm();

  const findCompaniesVariables = isAdmOrSup
    ? ({
        where: {
          companyStatus: {
            some: {
              status: {
                equals: 'APPROVED',
              },
            },
          },
        },
      } as QueryFindCompaniesArgs)
    : undefined;

  const [userDetailsResult] = useUserDetailsQuery({
    variables: findCompaniesVariables,
    pause: !findCompaniesVariables,
  });

  const companiesOptions = userDetailsResult.data?.FindCompanies.map(
    ({ id, company_name }) => ({
      label: company_name,
      value: id,
    })
  );

  const renderActionCheckbox = (
    actions: IPermission['actions'],
    targetAction: IPermission['actions'][0]
  ) => {
    const action = actions.find(action => action === targetAction);

    return action ? (
      <Checkbox defaultChecked checked disabled />
    ) : (
      <Checkbox disabled />
    );
  };

  const columns: TableColumnsType<IPermission> = [
    {
      title: t(
        `${USER_DETAILS_TRANSLATION_PATH}.permissionTable.columns.pages`
      ),
      dataIndex: 'module',
      render(module) {
        return t(`enums.modules.${module}`);
      },
    },
    {
      title: t('enums.permissionActions.READ'),
      dataIndex: 'actions',
      render(actions: IPermission['actions']) {
        return renderActionCheckbox(actions, 'READ');
      },
    },
    {
      title: t('enums.permissionActions.CREATE'),
      dataIndex: 'actions',
      render(actions: IPermission['actions']) {
        return renderActionCheckbox(actions, 'CREATE');
      },
    },
    {
      title: t('enums.permissionActions.UPDATE'),
      dataIndex: 'actions',
      render(actions: IPermission['actions']) {
        return renderActionCheckbox(actions, 'UPDATE');
      },
    },
    {
      title: t('enums.permissionActions.DELETE'),
      dataIndex: 'actions',
      render(actions: IPermission['actions']) {
        return renderActionCheckbox(actions, 'DELETE');
      },
    },
  ];

  const colSpan = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 6,
    xxl: 6,
  };

  const removeCompany = () => {
    localStorage.removeItem('company');
    userData && setUserData && setUserData({ ...userData, company: undefined });
  };

  useEffect(() => {
    const companyData = userData?.company;

    const formattedCompanyData = {
      ...companyData,
      company_function: companyData?.company_function
        ? t(`enums.FunctionEnum.${companyData.company_function}`)
        : '',
      company_type: companyData?.company_type
        ? t(`enums.CompanyTypeEnum.${companyData.company_type}`)
        : '',
    };

    const translatedUserRoles = roles?.map(role =>
      t(`enums.userRoles.${role}`)
    );

    form.setFieldsValue({
      ...userData,
      company: formattedCompanyData,
      roles: translatedUserRoles,
    });

    const theme = companySettings?.theme;

    if (theme) {
      onChange(theme.id);
    }
  }, [userData, i18n.resolvedLanguage]);

  useEffect(() => {
    if (isAdmOrSup && companyId) {
      const company = userDetailsResult.data?.FindCompanies.find(
        company => company.id === companyId
      );

      const formattedCompany: IUserData['company'] = company?.companyStatus
        ?.length
        ? {
            id: company.id,
            trade_name: company.company_name,
            company_function: company.company_function,
            company_type: company.company_type,
            company_status:
              company.companyStatus[company.companyStatus.length - 1].status,
            document_number: company.document_number,
          }
        : undefined;

      if (userData && setUserData && formattedCompany) {
        setUserData({
          ...userData,
          company: formattedCompany,
        });
      }

      localStorage.setItem('company', JSON.stringify(formattedCompany));

      form.setFieldsValue({
        ...userData,
        company: formattedCompany,
        roles,
      });
    }
  }, [companyId]);

  const isFormDisabled = !isEditing || fetching;

  return (
    <>
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />
      <Form form={form} layout="vertical" disabled={isFormDisabled}>
        <Card title={t(`${USER_DETAILS_TRANSLATION_PATH}.userData.title`)}>
          <Row gutter={[24, 24]}>
            <Col {...colSpan}>
              <Form.Item
                name="name"
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.userData.fields.name`
                )}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...colSpan}>
              <Form.Item
                name={'email'}
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.userData.fields.email`
                )}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...colSpan}>
              <Form.Item
                name={'roles'}
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.userData.fields.roles`
                )}
              >
                <Select mode="tags" disabled />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          title={t(`${USER_DETAILS_TRANSLATION_PATH}.companyData.title`)}
          extra={
            !isAdmOrSup ? (
              isEditing ? (
                <EditingCompanyDataButtons
                  cancelButtonProps={{
                    onClick: () => {
                      onCancel();
                      onReset();
                    },
                    disabled: fetching,
                  }}
                  submitButtonProps={{
                    onClick: () =>
                      selectedThemeId &&
                      onSubmit(selectedThemeId)
                        .then(onSuccess)
                        .catch(() => null),
                    disabled:
                      !selectedThemeId ||
                      selectedThemeId === initialCompanySettings?.theme?.id,
                    loading: fetching,
                  }}
                />
              ) : (
                <EditCompanyDataButtonsAccess
                  onClick={onEdit}
                  disabled={false}
                />
              )
            ) : (
              <></>
            )
          }
        >
          <Row gutter={[24, 24]}>
            <Col {...colSpan}>
              <Form.Item
                name={['company', 'trade_name']}
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.companyData.fields.company_name`
                )}
              >
                {isAdmOrSup ? (
                  <Select
                    showSearch
                    options={companiesOptions}
                    optionFilterProp="label"
                    disabled={userDetailsResult.fetching}
                    loading={userDetailsResult.fetching}
                    onChange={(value: string) => setCompanyId(value)}
                    allowClear
                    onClear={removeCompany}
                  />
                ) : (
                  <Input disabled />
                )}
              </Form.Item>
            </Col>
            <Col {...colSpan}>
              <Form.Item
                name={['company', 'company_function']}
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.companyData.fields.company_function`
                )}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...colSpan}>
              <Form.Item
                name={['company', 'company_type']}
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.companyData.fields.company_type`
                )}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...colSpan}>
              <Form.Item
                label={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.companyData.fields.company_theme`
                )}
                required={!isFormDisabled}
                tooltip={t(
                  `${USER_DETAILS_TRANSLATION_PATH}.companyData.tooltips.${
                    isAdmOrSup ? 'isAdmOrSup.' : ''
                  }company_theme`
                )}
              >
                <Select
                  onChange={onChange}
                  loading={isLoading}
                  options={options}
                  placeholder={t('general.select')}
                  value={selectedThemeId}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          title={t(`${USER_DETAILS_TRANSLATION_PATH}.permissionTable.title`)}
        >
          <Table
            columns={columns}
            dataSource={permissions || []}
            rowKey={({ module }) => module}
            className="ant-table-wrapper-responsive"
          />
        </Card>
      </Form>
    </>
  );
};
