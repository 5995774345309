/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { ICompanyFunction } from '../../../../structures/interfaces/Company/CompanyFunction';

import SignUpMessage from '..';
import CheckCircleOutlined from '../../../../assets/IconComponents/CheckCircleOutlined';

import './index.scss';

export const CompanyCreated = (): ReactElement<unknown> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as
    | { companyFunction: ICompanyFunction }
    | undefined;

  const [companyFunction] = useState(locationState?.companyFunction);

  useEffect(() => {
    if (!companyFunction) {
      navigate('/', { replace: true });
    }
  }, []);

  const handleConfirm = () => {
    navigate('/');
  };

  return (
    <SignUpMessage
      buttonAction={handleConfirm}
      image={<CheckCircleOutlined />}
      className="sign-up_company-created"
      title={t(`pages.signUp.messages.${companyFunction}Created.title`)}
      buttonText={t(
        `pages.signUp.messages.${companyFunction}Created.buttonText`
      )}
    >
      <span className="description-text">
        {t(`pages.signUp.messages.${companyFunction}Created.description`)}
      </span>
    </SignUpMessage>
  );
};

export default CompanyCreated;
