import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  CarrierHiredFreightFragment,
  CarrierQuotedFreightFragment,
  ListFreightsByQuotationQueryVariables,
  useListFreightsByQuotationQuery,
} from '../../../../graphql/generated/graphql';
import { useInterval } from '../../../../hooks/useInterval/useInterval';
import { DefaultPageHeader } from '../../../AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { CarrierHiredFreightTable } from '../CarrierHiredFreightTable/CarrierHiredFreightTable';
import { CarrierQuotedFreightTable } from '../CarrierQuotedFreightTable/CarrierQuotedFreightTable';
import { CarrierQuotingFreightTable } from '../CarrierQuotingFreightTable/CarrierQuotingFreightTable';
import { useQuotingFreightsQuery } from './CarrierListFreights.hooks';

export const CarrierListFreights = (): ReactElement => {
  const CARRIER_LIST_FREIGHTS_TRANSLATION_PATH =
    'components.freight.carrier.carrierListFreights';

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userData } = useUserContext();

  const [quotedFreights, setQuotedFreights] =
    useState<CarrierQuotedFreightFragment[]>();
  const [hiredFreights, setHiredFreights] =
    useState<CarrierHiredFreightFragment[]>();

  const [now] = useState(dayjs());

  const listQuotingFreightsResult = useQuotingFreightsQuery();

  const hiredQuotationStatus = [
    'HIRED',
    'CARRYING',
    'LOADED',
    'FINISHED',
    'DROPPED',
    'CANCELED',
    'ABANDONED',
  ];

  const listFreightsByQuotationVariables:
    | ListFreightsByQuotationQueryVariables
    | undefined = userData?.company?.id
    ? {
        quotedInput: {
          owner: {
            is: {
              reference_external_company_id: {
                equals: userData.company.id,
              },
            },
          },
          freight: {
            is: {
              status: {
                is: {
                  name: {
                    equals: 'QUOTING',
                  },
                },
              },
              quote_limit: {
                gt: now.toISOString(),
              },
            },
          },
        },
        hiredInput: {
          owner: {
            is: {
              reference_external_company_id: {
                equals: userData.company.id,
              },
            },
          },
          OR: hiredQuotationStatus.map(status => ({
            status: {
              is: {
                name: {
                  equals: status,
                },
              },
            },
          })),
        },
        pagination: {
          take: 1000,
        },
      }
    : undefined;

  const [listFreightsByQuotationResult] = useListFreightsByQuotationQuery({
    variables: listFreightsByQuotationVariables,
    pause: !listFreightsByQuotationVariables,
  });

  const handleViewQuotedOrHiredFreight = (
    quotationId: string,
    freightId: string
  ) => {
    return navigate(`/carrier/freights/${freightId}/quotations/${quotationId}`);
  };

  const handleViewQuotingFreight = (freightId: string) => {
    return navigate(`/carrier/freights/${freightId}/quotations/create`);
  };

  useEffect(() => {
    if (
      listFreightsByQuotationResult.data &&
      !listFreightsByQuotationResult.fetching
    ) {
      setQuotedFreights(
        listFreightsByQuotationResult.data.FindManyQuotationQuoted
      );
      setHiredFreights(
        listFreightsByQuotationResult.data.FindManyQuotationHired
      );
    }

    return () => {
      setQuotedFreights(undefined);
      setHiredFreights(undefined);
    };
  }, [listFreightsByQuotationResult]);

  useInterval(() => {
    if (quotedFreights) {
      const now = dayjs();
      const newQuotedFreights = quotedFreights.filter(
        quotingFreight =>
          quotingFreight.freight.status.name === 'QUOTING' &&
          dayjs(quotingFreight.freight.quote_limit).isAfter(now, 'seconds')
      );

      setQuotedFreights(newQuotedFreights);
    }
  }, 1000);

  const isLoading =
    listQuotingFreightsResult.fetching ||
    listFreightsByQuotationResult.fetching ||
    !userData;

  return (
    <>
      <DefaultPageHeader
        subTitle={t(`${CARRIER_LIST_FREIGHTS_TRANSLATION_PATH}.subTitle`)}
        title={t(`${CARRIER_LIST_FREIGHTS_TRANSLATION_PATH}.title`)}
      />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={listQuotingFreightsResult.error} />
        <ErrorAlert error={listFreightsByQuotationResult.error} />
        <Col span={24}>
          <CarrierQuotingFreightTable
            freights={listQuotingFreightsResult.data}
            loading={isLoading}
            pagination={listQuotingFreightsResult.pagination}
            onView={handleViewQuotingFreight}
          />
        </Col>
        <Col span={24}>
          <CarrierQuotedFreightTable
            freights={quotedFreights}
            loading={isLoading}
            onView={handleViewQuotedOrHiredFreight}
          />
        </Col>
        <Col span={24}>
          <CarrierHiredFreightTable
            freights={hiredFreights}
            loading={isLoading}
            onView={handleViewQuotedOrHiredFreight}
          />
        </Col>
      </Row>
    </>
  );
};
