/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext/useAuthContext';
import { useJwtDecode } from '../../../hooks/useJwtDecode/useJwtDecode';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../../LoadingPage/LoadingPage';

const VerifyEmailPage = (): ReactElement => {
  const { t } = useTranslation();
  const { isUserLoggedIn, logout } = useAuthContext();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const decodedJwt = useJwtDecode({ encodedJwt: token });

  useEffect(() => {
    if (isUserLoggedIn) {
      logout();
    }
  }, []);

  useEffect(() => {
    if (decodedJwt.error) {
      notification.error({
        message: t(decodedJwt.error.message),
        description: t(decodedJwt.error.description),
      });

      navigate('/', { replace: true });
    } else if (decodedJwt.data) {
      navigate('/createPassword', {
        replace: true,
        state: { token },
      });
    }
  }, [decodedJwt]);

  return <LoadingPage asOverlay opaque />;
};

export default VerifyEmailPage;
