import { Alert, Button, Col, Modal, Popconfirm, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { CombinedError } from 'urql';
import Formatter from '../../../classes/Formatter';
import { useCreateFreightContext } from '../../../contexts/Freight/CreateFreightContext/useCreateFreightContext';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import { Volume } from '../../../core/Freight/Volume';
import {
  CancelQuotationMutation,
  FreightDetailsQuery,
  HireQuotationTableFragment,
  useFreightDetailsQuery,
  useFreightDetails_CompanyDetailsQuery,
  useHireQuotationsMutation,
} from '../../../graphql/generated/graphql';
import { FixedFooterContainer } from '../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { Access } from '../../Access/Access';
import { DefaultPageFooter } from '../../AntdCustom/DefaultPageFooter/DefaultPageFooter';
import ErrorAlert from '../../AntdCustom/ErrorAlert/ErrorAlert';
import { AbandonOrCancelQuotationModal } from '../../Quotation/AbandonOrCancelQuotation/AbandonOrCancelQuotationModal/AbandonOrCancelQuotationModal';
import { HireQuotationTable } from '../../Quotation/HireQuotationTable/HireQuotationTable';
import { CanceledQuotationModal } from '../../Quotation/Shipper/CanceledQuotationModal/CanceledQuotationModal';
import { CarrierHiredDetailsModal } from '../../Quotation/Shipper/CarrierHiredDetailsModal/CarrierHiredDetailsModal';
import { FreightLoadDescriptions } from '../FreightLoadDescriptions/FreightLoadDescriptions';
import { FreightPlaceDescriptions } from '../FreightPlaceDescriptions/FreightPlaceDescriptions';
import { FreightVolumeBar } from '../FreightVolumeBar/FreightVolumeBar';
import { AttachmentsModal } from './Components/AttachmentsModal/AttachmentsModal';
import { useAttachmentsModal } from './Components/AttachmentsModal/AttachmentsModal.hooks';
import {
  AttachmentTableModal,
  AttachmentTableModalComponents,
} from './Components/AttachmentsTableModal';
import { useAttachmentsTableModal } from './Components/AttachmentsTableModal/AttachmentsTableModal.hooks';
import { CompanyDetails } from './Components/CompanyDetails/CompanyDetails';
import { QuotationGroupsTable } from './Components/QuotationGroupsTable/QuotationGroupsTable';

const TRANSLATION_PATH = 'components.freight.freightDetails';

interface IFreightDetailsProps {
  freightId?: string;
}

export const FreightDetails = ({
  freightId,
}: IFreightDetailsProps): ReactElement => {
  const variables = freightId ? { freightId } : undefined;

  const [result] = useFreightDetailsQuery({
    variables: variables,
    pause: !variables,
  });

  const { data, fetching, error } = result;

  if (error) {
    return (
      <>
        <FreightDetailsEmpty error={error} />
      </>
    );
  }

  if (fetching) {
    return <FreightDetailsLoading />;
  }

  if (data?.FindFreight.status) {
    switch (data.FindFreight.status.name) {
      case 'QUOTING':
        return <FreightDetailsQuoting data={data.FindFreight} />;
      case 'QUOTED':
        return <FreightDetailsQuoted data={data.FindFreight} />;
      case 'HIRED':
      case 'HIRING':
      case 'CARRYING':
        return <FreightDetailsHired data={data.FindFreight} />;
      case 'UNCOMPLETED':
      case 'FINISHED':
        return <FreightDetailsFinished data={data.FindFreight} />;
      default:
        return <FreightDetailsQuoting data={data.FindFreight} />;
    }
  }

  return <FreightDetailsEmpty />;
};

const FreightDetailsQuoting = ({
  data,
}: {
  data?: FreightDetailsQuery['FindFreight'];
}): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [companyId, setCompanyId] = useState('');

  const [result] = useFreightDetails_CompanyDetailsQuery({
    variables: { companyId },
    pause: !companyId,
  });

  const attachmentsModalProps = useAttachmentsModal();

  const {
    handlers: { handleAttachmentsTableModalOpen },
  } = attachmentsModalProps;

  const handleBack = () => {
    return navigate(-1);
  };

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 0]}>
        <Col span={24}>
          {data?.status.name === 'QUOTING' &&
            dayjs(data.quote_limit).isBefore(dayjs(), 'second') && (
              <Alert
                message={<b>{t('general.warning')}</b>}
                description={t(
                  `${TRANSLATION_PATH}.quoting.freightStatusWarning.description`
                )}
                type="warning"
                style={{ marginBottom: 24 }}
              />
            )}
        </Col>
        <Col span={24}>
          {data?.related_groups?.length ? (
            <QuotationGroupsTable
              data={data.related_groups.map(({ group }) => group)}
            />
          ) : (
            <QuotationGroupsTable.NoQuotationGroupAlert />
          )}
        </Col>
        <Col span={24}>
          <FreightPlaceDescriptions.Card
            extra={
              <AttachmentTableModalComponents.OpenButton
                onClick={handleAttachmentsTableModalOpen}
              />
            }
          >
            <FreightPlaceDescriptions data={data} />
          </FreightPlaceDescriptions.Card>
        </Col>
        <Col span={24}>
          <FreightLoadDescriptions.Card>
            <FreightLoadDescriptions data={data} />
          </FreightLoadDescriptions.Card>
        </Col>
        <Col span={24}>
          <HireQuotationTable.Card>
            <HireQuotationTable
              quotations={data?.quotations || []}
              showSelection={false}
              showTableActions={true}
              onViewCarrierDetails={companyId => setCompanyId(companyId)}
            />
          </HireQuotationTable.Card>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button size="small" onClick={handleBack}>
              {t(`${TRANSLATION_PATH}.quoting.actions.cancel`)}
            </Button>
          </Space>
        }
      />
      <Modal
        cancelText={t(`general.close`)}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setCompanyId('')}
        open={!!companyId}
        title={t(`${TRANSLATION_PATH}.quoting.companyDetailsModalTitle`)}
        width={700}
      >
        <ErrorAlert error={result.error} />
        <CompanyDetails
          company={result.data?.FindCompany}
          loading={result.fetching}
        />
      </Modal>
      <AttachmentsModal {...attachmentsModalProps} />
    </>
  );
};

const FreightDetailsQuoted = ({
  data,
}: {
  data: FreightDetailsQuery['FindFreight'];
}): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userData } = useUserContext();

  const [companyId, setCompanyId] = useState('');

  const [result] = useFreightDetails_CompanyDetailsQuery({
    variables: { companyId },
    pause: !companyId,
  });

  const attachmentsModalProps = useAttachmentsModal();

  const {
    handlers: { handleAttachmentsTableModalOpen },
  } = attachmentsModalProps;

  const [selectedQuotations, setSelectedQuotations] = useState<
    HireQuotationTableFragment[]
  >([]);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [hireQuotationResult, executeHireQuotationMutation] =
    useHireQuotationsMutation();

  const handleCancel = () => {
    return navigate(-1);
  };

  const handleOpenModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleSubmit = () => {
    if (userData?.company) {
      executeHireQuotationMutation({
        input: {
          freight_id: data.id,
          shipper_id: userData?.company.id,
          quotations: selectedQuotations.map(({ id, total_volume }) => ({
            quotation_id: id,
            proposed_volume: total_volume,
          })),
        },
      }).then(({ data, error }) => {
        handleCloseModal();
        if (data && !error) {
          navigate(-1);
        }
      });
    }
  };

  const CancelButton = ({ onClick }: { onClick?: () => void }) => (
    <Button size="small" onClick={onClick}>
      {t(`${TRANSLATION_PATH}.quoted.actions.cancel.button`)}
    </Button>
  );

  const popConfirmCancelButton =
    selectedQuotations.length > 0 ? (
      <Popconfirm
        title={t(`${TRANSLATION_PATH}.quoted.actions.cancel.popconfirm.title`)}
        okText={t(`general.no`)}
        okType="default"
        cancelText={t('general.yes')}
        cancelButtonProps={{
          danger: true,
          type: 'primary',
        }}
        onCancel={handleCancel}
      >
        <CancelButton />
      </Popconfirm>
    ) : (
      <CancelButton onClick={handleCancel} />
    );

  const { total_volume, loaded_volume } = data;

  const allocatedVolume = selectedQuotations.reduce(
    (acc, { total_volume }) => acc + total_volume,
    0
  );

  const unallocatedVolume = total_volume - allocatedVolume;

  const hoursToShipment = dayjs(data.shipment_date).diff(dayjs(), 'hours');

  const { error, fetching } = hireQuotationResult;

  const ConfirmButton = () => (
    <Button
      size="small"
      type="primary"
      htmlType="submit"
      disabled={selectedQuotations.length === 0}
      onClick={handleOpenModal}
      loading={fetching}
    >
      {t(`${TRANSLATION_PATH}.quoted.actions.submit`)}
    </Button>
  );

  const ConfirmButtonAccess = Access(ConfirmButton, {
    acceptedPermissions: [{ module: 'HIRE_QUOTATIONS', actions: ['CREATE'] }],
  });

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 0]}>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <Col span={24}>
          {data.related_groups?.length ? (
            <QuotationGroupsTable
              data={data.related_groups.map(({ group }) => group)}
            />
          ) : (
            <QuotationGroupsTable.NoQuotationGroupAlert />
          )}
        </Col>
        <Col span={24}>
          <FreightPlaceDescriptions.Card
            extra={
              <AttachmentTableModalComponents.OpenButton
                onClick={handleAttachmentsTableModalOpen}
              />
            }
          >
            <FreightPlaceDescriptions data={data} />
          </FreightPlaceDescriptions.Card>
        </Col>
        <Col span={24}>
          <FreightLoadDescriptions.Card>
            <FreightLoadDescriptions data={data} />
            <FreightVolumeBar
              data={{
                allocated_volume: allocatedVolume,
                unallocated_volume: unallocatedVolume,
                total_volume,
                loaded_volume,
                unit_type: data.unit_type,
              }}
            />
          </FreightLoadDescriptions.Card>
        </Col>
        <Col span={24}>
          <HireQuotationTable.Card>
            <HireQuotationTable
              quotations={data?.quotations || []}
              showSelection={true}
              unallocatedVolume={unallocatedVolume}
              setSelectedQuotations={setSelectedQuotations}
              showTableActions={true}
              onViewCarrierDetails={companyId => setCompanyId(companyId)}
            />
          </HireQuotationTable.Card>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            {popConfirmCancelButton}
            <ConfirmButtonAccess />
          </Space>
        }
      />
      <Modal
        centered
        okButtonProps={{ loading: fetching }}
        okText={t(`${TRANSLATION_PATH}.quoted.modal.footer.okText`)}
        cancelText={t('general.close')}
        onCancel={handleCloseModal}
        onOk={handleSubmit}
        open={isConfirmationModalOpen}
        title={t(`${TRANSLATION_PATH}.quoted.modal.title`)}
      >
        <Typography.Paragraph>
          {t(`${TRANSLATION_PATH}.quoted.modal.text.common`)}
        </Typography.Paragraph>
        {hoursToShipment > 24 ? (
          <Typography.Paragraph>
            {t(`${TRANSLATION_PATH}.quoted.modal.text.before24Hours`)}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph>
            {t(`${TRANSLATION_PATH}.quoted.modal.text.after24Hours`)}
          </Typography.Paragraph>
        )}
      </Modal>
      <Modal
        cancelText={t(`general.close`)}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setCompanyId('')}
        open={!!companyId}
        title={t(`${TRANSLATION_PATH}.quoted.companyDetailsModalTitle`)}
        width={700}
      >
        <ErrorAlert error={result.error} />
        <CompanyDetails
          company={result.data?.FindCompany}
          loading={result.fetching}
        />
      </Modal>
      <AttachmentsModal {...attachmentsModalProps} />
    </>
  );
};

const FreightDetailsHired = ({
  data,
}: {
  data: FreightDetailsQuery['FindFreight'];
}): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setCreateFreightValues } = useCreateFreightContext();

  const attachmentsModalProps = useAttachmentsModal();

  const {
    handlers: { handleAttachmentsTableModalOpen },
  } = attachmentsModalProps;

  const [
    seeCarrierDetailsModalQuotationId,
    setSeeCarrierDetailsModalQuotationId,
  ] = useState<string>();
  const [
    finalizationReasonModalQuotationId,
    setFinalizationReasonModalQuotationId,
  ] = useState<string>();
  const [finalizationModalQuotationId, setFinalizationModalQuotationId] =
    useState<string>();
  const [updateFinishQuotationData, setUpdateFinishQuotationData] =
    useState<CancelQuotationMutation>();

  const handleSetFreightValues = () => {
    setCreateFreightValues({
      shipment_date: dayjs(),
      landing_date: newLandingDate,
      route_id: data.route.id,
      observation: data.observation,
      note_value: data.note_value
        ? Formatter.formatMoney(String(data.note_value))
        : undefined,
      suggested_value: data.suggested_value
        ? Formatter.formatMoney(String(data.suggested_value))
        : undefined,
      total_volume: data.unallocated_volume
        ? Volume.Utils.formatVolumeDisplay(
            data.unallocated_volume,
            data.unit_type
          ) ?? 0
        : 0,

      product_variety_id: data.product_variety
        ? {
            value: data.product_variety.id,
            label: data.product_variety.name,
          }
        : undefined,
      packing_id: {
        value: data.packing.id,
        label: data.packing.name,
      },
      product_id: {
        value: data.product.id,
        label: data.product.name,
      },
      product_type_id: {
        value: data.product_type.id,
        label: data.product_type.name,
      },

      isValid: false,
    });
    navigate(`/shipper/freights/create/${data.id}`);
  };

  const handleFinishQuotationRequestSuccess = (
    quotation: CancelQuotationMutation
  ) => {
    setUpdateFinishQuotationData(quotation);
  };

  const timeBetweenShipmentAndLandingInDays = dayjs(data.landing_date).diff(
    dayjs(data.shipment_date),
    'days'
  );
  const today = dayjs();
  const newLandingDate = today.add(timeBetweenShipmentAndLandingInDays, 'days');

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 0]}>
        <Col span={24}>
          {data.related_groups?.length ? (
            <QuotationGroupsTable
              data={data.related_groups.map(({ group }) => group)}
            />
          ) : (
            <QuotationGroupsTable.NoQuotationGroupAlert />
          )}
        </Col>
        <Col span={24}>
          <FreightPlaceDescriptions.Card
            extra={
              <AttachmentTableModalComponents.OpenButton
                onClick={handleAttachmentsTableModalOpen}
              />
            }
          >
            <FreightPlaceDescriptions data={data} />
          </FreightPlaceDescriptions.Card>
        </Col>
        <Col span={24}>
          <FreightLoadDescriptions.Card>
            <FreightLoadDescriptions data={data} />
            <FreightVolumeBar
              data={{
                allocated_volume: data.allocated_volume,
                unallocated_volume: data.unallocated_volume,
                total_volume: data.total_volume,
                loaded_volume: data.loaded_volume,
                unit_type: data.unit_type,
              }}
            />
          </FreightLoadDescriptions.Card>
        </Col>
        {data.unallocated_volume > 0 && !data.child && (
          <Col span={24}>
            <Alert
              style={{ margin: '1rem 0rem' }}
              type="warning"
              message={
                <Trans
                  i18nKey={`${TRANSLATION_PATH}.hired.warning.message`}
                  t={t}
                  components={[
                    <Volume.Display
                      key={data.unallocated_volume}
                      volume={data.unallocated_volume}
                    />,
                  ]}
                />
              }
              action={
                <Button
                  type="primary"
                  size="small"
                  onClick={handleSetFreightValues}
                >
                  {t(`${TRANSLATION_PATH}.hired.warning.newQuotation`)}
                </Button>
              }
            />
          </Col>
        )}
        <Col span={24}>
          <HireQuotationTable.Card isHiredFreight>
            <HireQuotationTable
              quotations={
                data?.quotations?.filter(
                  quotation =>
                    quotation.status.name !== 'WAITING' &&
                    quotation.status.name !== 'REFUSED'
                ) || []
              }
              showSelection={true}
              unallocatedVolume={data.unallocated_volume}
              isHiredFreight
              onViewFinishedQuotationReason={quotationId =>
                setFinalizationReasonModalQuotationId(quotationId)
              }
              onViewFinishQuotation={quotationId =>
                setFinalizationModalQuotationId(quotationId)
              }
              onViewCarrierDetailsQuotation={quotationId =>
                setSeeCarrierDetailsModalQuotationId(quotationId)
              }
              updateFinishQuotationData={updateFinishQuotationData}
            />
          </HireQuotationTable.Card>
        </Col>
      </FixedFooterContainer.Inner>

      <CanceledQuotationModal
        open={!!finalizationReasonModalQuotationId}
        quotationId={finalizationReasonModalQuotationId}
        onCancel={() => setFinalizationReasonModalQuotationId(undefined)}
      />

      <CarrierHiredDetailsModal
        open={!!seeCarrierDetailsModalQuotationId}
        quotationId={seeCarrierDetailsModalQuotationId}
        onCancel={() => setSeeCarrierDetailsModalQuotationId(undefined)}
      />

      <AbandonOrCancelQuotationModal
        open={!!finalizationModalQuotationId}
        quotationId={finalizationModalQuotationId}
        onCancel={() => setFinalizationModalQuotationId(undefined)}
        onUpdateSuccess={handleFinishQuotationRequestSuccess}
        type="Cancel"
        freightId={data.id}
      />

      <AttachmentsModal {...attachmentsModalProps} />
    </>
  );
};

const FreightDetailsFinished = ({
  data,
}: {
  data: FreightDetailsQuery['FindFreight'];
}): ReactElement => {
  const {
    handlers: {
      onClose: handleAttachmentsTableModalClose,
      onOpen: handleAttachmentsTableModalOpen,
    },
    isOpen: isAttachmentsTableModalOpen,
    result: findAttachmentsResponse,
    error: attachmentsTableError,
    fetching: attachmentsTableFetching,
  } = useAttachmentsTableModal();

  const [
    seeCarrierDetailsModalQuotationId,
    setSeeCarrierDetailsModalQuotationId,
  ] = useState<string>();

  const [
    finalizationReasonModalQuotationId,
    setFinalizationReasonModalQuotationId,
  ] = useState<string>();

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 0]}>
        <Col span={24}>
          {data.related_groups?.length ? (
            <QuotationGroupsTable
              data={data.related_groups.map(({ group }) => group)}
            />
          ) : (
            <QuotationGroupsTable.NoQuotationGroupAlert />
          )}
        </Col>
        <Col span={24}>
          <FreightPlaceDescriptions.Card
            extra={
              <AttachmentTableModalComponents.OpenButton
                onClick={handleAttachmentsTableModalOpen}
              />
            }
          >
            <FreightPlaceDescriptions data={data} />
          </FreightPlaceDescriptions.Card>
        </Col>
        <Col span={24}>
          <FreightLoadDescriptions.Card>
            <FreightLoadDescriptions data={data} />
            <FreightVolumeBar
              data={{
                allocated_volume: data.allocated_volume,
                unallocated_volume: data.unallocated_volume,
                total_volume: data.total_volume,
                loaded_volume: data.loaded_volume,
                unit_type: data.unit_type,
              }}
            />
          </FreightLoadDescriptions.Card>
        </Col>
        <Col span={24}>
          <HireQuotationTable.Card isHiredFreight>
            <HireQuotationTable
              quotations={
                data?.quotations?.filter(
                  quotation =>
                    quotation.status.name !== 'WAITING' &&
                    quotation.status.name !== 'REFUSED'
                ) || []
              }
              showSelection={true}
              unallocatedVolume={data.unallocated_volume}
              isHiredFreight
              onViewFinishedQuotationReason={quotationId =>
                setFinalizationReasonModalQuotationId(quotationId)
              }
              onViewCarrierDetailsQuotation={quotationId =>
                setSeeCarrierDetailsModalQuotationId(quotationId)
              }
            />
          </HireQuotationTable.Card>
        </Col>
      </FixedFooterContainer.Inner>

      <CanceledQuotationModal
        open={!!finalizationReasonModalQuotationId}
        quotationId={finalizationReasonModalQuotationId}
        onCancel={() => setFinalizationReasonModalQuotationId(undefined)}
      />

      <CarrierHiredDetailsModal
        open={!!seeCarrierDetailsModalQuotationId}
        quotationId={seeCarrierDetailsModalQuotationId}
        onCancel={() => setSeeCarrierDetailsModalQuotationId(undefined)}
      />

      <AttachmentTableModal
        handleClose={handleAttachmentsTableModalClose}
        isOpen={isAttachmentsTableModalOpen}
        queryResponse={findAttachmentsResponse}
        error={attachmentsTableError}
        loading={attachmentsTableFetching}
      />
    </>
  );
};

const FreightDetailsLoading = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    return navigate(-1);
  };

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 0]}>
        <Col span={24}>
          <FreightPlaceDescriptions.Card>
            <FreightPlaceDescriptions loading={true} />
          </FreightPlaceDescriptions.Card>
        </Col>
        <Col span={24}>
          <FreightLoadDescriptions.Card>
            <FreightLoadDescriptions loading={true} />
          </FreightLoadDescriptions.Card>
        </Col>
        <Col span={24}>
          <HireQuotationTable.Card>
            <HireQuotationTable loading={true} />
          </HireQuotationTable.Card>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button size="small" onClick={handleBack}>
              {t(`${TRANSLATION_PATH}.quoting.actions.cancel`)}
            </Button>
          </Space>
        }
      />
    </>
  );
};

const FreightDetailsEmpty = ({
  error,
}: {
  error?: CombinedError | undefined;
}): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    return navigate(-1);
  };

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 0]}>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <Col span={24}>
          <FreightPlaceDescriptions.Card>
            <FreightPlaceDescriptions />
          </FreightPlaceDescriptions.Card>
        </Col>
        <Col span={24}>
          <FreightLoadDescriptions.Card>
            <FreightLoadDescriptions />
          </FreightLoadDescriptions.Card>
        </Col>
        <Col span={24}>
          <HireQuotationTable.Card>
            <HireQuotationTable />
          </HireQuotationTable.Card>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button size="small" onClick={handleBack}>
              {t(`${TRANSLATION_PATH}.quoting.actions.cancel`)}
            </Button>
          </Space>
        }
      />
    </>
  );
};

const FreightDetailsAccess = Access(FreightDetails, {
  acceptedPermissions: [{ module: 'FREIGHTS', actions: ['READ'] }],
  fallback: <Navigate to="/" replace />,
});

export default FreightDetailsAccess;
