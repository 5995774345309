import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QueryMode,
  UsersPageQueryVariables,
} from '../../../../../../../graphql/generated/graphql';
import { useDatePickerDefaultPresets } from '../../../../../../../hooks/useDatePickerDefaultPresets/useDatePickerDefaultPresets';

interface IUsersPageFilterProps {
  setVariables: Dispatch<SetStateAction<UsersPageQueryVariables>>;
}

export interface IFilterFormValues {
  active?: boolean;
  email?: string;
  name?: string;
  companyName?: string;
  createdAt?: [dayjs.Dayjs, dayjs.Dayjs];
  updatedAt?: [dayjs.Dayjs, dayjs.Dayjs];
}

export const UsersPageFilter = ({
  setVariables,
}: IUsersPageFilterProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.user.usersPage.filter',
  });

  const { t: tg } = useTranslation('translations', {
    keyPrefix: 'general',
  });

  const datePickerDefaultPresets = useDatePickerDefaultPresets();

  const handleFilter = (formValues: IFilterFormValues) => {
    const active =
      formValues.active !== undefined
        ? { equals: formValues.active }
        : undefined;

    const name = formValues.name?.trim()
      ? { contains: formValues.name, mode: QueryMode.Insensitive }
      : undefined;

    const email = formValues.email?.trim()
      ? { contains: formValues.email.trim(), mode: QueryMode.Insensitive }
      : undefined;

    const created_at = formValues.createdAt
      ? {
          gte: formValues.createdAt[0].startOf('day').toISOString(),
          lte: formValues.createdAt[1].endOf('day').toISOString(),
        }
      : undefined;

    const updated_at = formValues.updatedAt
      ? {
          gte: formValues.updatedAt[0].startOf('day').toISOString(),
          lte: formValues.updatedAt[1].endOf('day').toISOString(),
        }
      : undefined;

    const companyName = formValues.companyName?.trim();
    const companies = companyName
      ? {
          some: {
            company: {
              is: {
                OR: [
                  {
                    company_name: {
                      contains: companyName,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    trade_name: {
                      contains: companyName,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    address: {
                      is: {
                        city: {
                          is: {
                            city_name_without_accent: {
                              contains: companyName,
                              mode: QueryMode.Insensitive,
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    address: {
                      is: {
                        city: {
                          is: {
                            city_name: {
                              contains: companyName,
                              mode: QueryMode.Insensitive,
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    address: {
                      is: {
                        city: {
                          is: {
                            state_id: { contains: companyName },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        }
      : undefined;

    const where: UsersPageQueryVariables['where'] = {
      ...{ active },
      ...{ name },
      ...{ email },
      ...{ created_at },
      ...{ updated_at },
      ...{ companies },
    };

    setVariables(variables => ({ ...variables, where }));
  };

  return (
    <Card>
      <Form layout="vertical" onFinish={handleFilter}>
        <Row gutter={[24, 24]}>
          <Col>
            <Form.Item label={t('labels.active')} name="active">
              <Select
                options={[
                  { label: tg('yes'), value: true },
                  { label: tg('no'), value: false },
                ]}
                placeholder={tg('select')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.email')} name="email">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.name')} name="name">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.companyName')} name="companyName">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.createdAt')} name="createdAt">
              <DatePicker.RangePicker
                allowClear
                format="DD/MM/YYYY"
                presets={[{ label: '', value: [dayjs(), dayjs()] }]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.updatedAt')} name="updatedAt">
              <DatePicker.RangePicker
                allowClear
                format="DD/MM/YYYY"
                presets={datePickerDefaultPresets}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Space size="large">
            <Button htmlType="reset">{tg('clearFilter')}</Button>
            <Button htmlType="submit" type="primary">
              {tg('filter')}
            </Button>
          </Space>
        </Row>
      </Form>
    </Card>
  );
};
