import { BreadcrumbProps, Button, ButtonProps } from 'antd';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Access } from '../../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { QuotationDetails } from '../../../../../components/Quotation/QuotationDetails/QuotationDetails';
import { useMenuItem } from '../../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../../hooks/useTitle/useTitle';
import { FixedFooterContainer } from '../../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { breadcrumbItemRender } from '../../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

const TRANSLATION_PATH = 'pages.quotations.quotationDetailsPage';

export const QuotationDetailsPage = (): ReactElement => {
  const { quotationId } = useParams();

  const [finishButtonProps, setFinishButtonProps] = useState<ButtonProps>({
    hidden: true,
  });

  const { t } = useTranslation();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/carrier/freights',
        breadcrumbName: t('tab.carrier.freight.index'),
      },
      {
        path: '/carrier/quotations/:quotationId',
        breadcrumbName: t(`${TRANSLATION_PATH}.title`),
      },
    ],
  };

  useTitle({ title: `${TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/carrier'],
    selectedMenu: ['/carrier/freights'],
  });

  if (!quotationId) return <Navigate to="/" replace />;

  const headerButtonStyle = finishButtonProps.hidden
    ? {
        ...finishButtonProps,
        style: { ...finishButtonProps.style, display: 'none' },
      }
    : { ...finishButtonProps };

  const headerButton = (
    <Button {...headerButtonStyle}>{t('general.finish')}</Button>
  );

  return (
    <FixedFooterContainer>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        title={t(`${TRANSLATION_PATH}.title`)}
        extra={headerButton}
      />
      <QuotationDetails
        quotationId={quotationId}
        setFinishButtonProps={setFinishButtonProps}
      />
    </FixedFooterContainer>
  );
};

const QuotationDetailsPageAccess = Access(QuotationDetailsPage, {
  acceptedPermissions: [
    {
      module: 'QUOTATION_CONFIRMATIONS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default QuotationDetailsPageAccess;
