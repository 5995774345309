import client from '../../api/urql/customClient';
import {
  ICreateAccount,
  ICreateUser,
  ICreateUserResponse,
} from '../interfaces/User';
import { CreateAccountModel } from '../models/User';
import queries from '../queries/User';

class UserController {
  static createUser = (createUser: ICreateUser): Promise<ICreateUserResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.createUser(), {
          createUserInput: createUser,
        })
        .then(({ createdUser }) => resolve(createdUser))
        .catch(err => reject(err))
    );

  static createAccount = (createAccount: ICreateAccount): Promise<boolean> =>
    new Promise((resolve, reject) =>
      client
        .mutation(queries.createAccount(), {
          createAccount: new CreateAccountModel(createAccount),
        })
        .then(({ createdAccount }) => resolve(createdAccount?.hasSuccess))
        .catch(err => reject(err))
    );
}

export default UserController;
