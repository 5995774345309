import { useMemo, useState } from 'react';

export const useAttachmentModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handlers = useMemo(() => {
    return {
      onOpen() {
        setIsOpen(true);
      },
      onClose() {
        setIsOpen(false);
      },
    };
  }, []);

  return { isOpen, handlers };
};
