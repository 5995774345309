import { IconBaseProps } from '@ant-design/icons/lib/components/Icon';
import { cloneElement, ReactElement } from 'react';

interface IFormItemIcon extends IconBaseProps {
  /**
   * Icon to be rendered
   */
  icon: ReactElement;
}
/**
 * Icon component for dynamic forms
 */
export const FormItemIcon = ({ icon, ...props }: IFormItemIcon) => {
  const Icon = cloneElement(icon, {
    ...props,
    style: {
      color: '#999',
      cursor: 'pointer',
      fontSize: 24,
      marginLeft: 12,
      position: 'relative',
      top: 3,
      transition: 'all 0.3s',
      ...props.style,
    },
  });
  return <>{Icon}</>;
};
