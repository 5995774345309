import { Button, Modal, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCarrierHiredDetailsModalQuery } from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { QuotationDescriptions } from '../../QuotationDescriptions/QuotationDescriptions';

interface ICarrierHiredDetailsModalProps extends Omit<ModalProps, 'onCancel'> {
  quotationId?: string;
  onCancel?: () => void;
}

const TRANSLATION_PATH =
  'components.quotation.shipper.carrierHiredDetailsModal';

export const CarrierHiredDetailsModal = ({
  quotationId,
  ...modalProps
}: ICarrierHiredDetailsModalProps) => {
  const { t } = useTranslation();

  const findCarrierHiredDetailsVariables = quotationId
    ? { quotation_id: quotationId }
    : undefined;

  const [carrierHiredDetailsModalResult] = useCarrierHiredDetailsModalQuery({
    variables: findCarrierHiredDetailsVariables,
    pause: !findCarrierHiredDetailsVariables,
  });

  const { data, error, fetching } = carrierHiredDetailsModalResult;

  const footer = (
    <Button size="small" onClick={modalProps.onCancel}>
      {t('general.close')}
    </Button>
  );

  return (
    <Modal
      width={'992px'}
      title={t(`${TRANSLATION_PATH}.title`)}
      footer={footer}
      centered
      {...modalProps}
    >
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />

      <QuotationDescriptions data={data?.FindQuotation} fetching={fetching} />
    </Modal>
  );
};
