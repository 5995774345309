import { SelectProps } from 'antd';

export const CompanyFunctions = ['carrier', 'shipper'] as const;

export type ICompanyFunction = (typeof CompanyFunctions)[number];

export enum CompanyFunctionEnum {
  carrier = 'CARRIER',
  shipper = 'SHIPPER',
}

export enum CompanyFunctionReverseEnum {
  CARRIER = 'carrier',
  SHIPPER = 'shipper',
}

export enum CompanyFunctionTranslationEnum {
  CARRIER = 'enums.companyFunction.carrier',
  SHIPPER = 'enums.companyFunction.shipper',
}

export const CompanyFunctionOptions: NonNullable<SelectProps['options']> =
  CompanyFunctions.map(companyFunction => ({
    label: `enums.companyFunction.${companyFunction}`,
    value: companyFunction,
  }));
