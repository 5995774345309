import { theme } from 'antd';
import { SVGProps } from 'react';

export const UserAvatarIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  const {
    token: { colorPrimary, colorBgLayout },
  } = theme.useToken();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={38}
      color={colorBgLayout}
      {...props}
    >
      <g data-name="Group 16400" transform="translate(1 1)">
        <g fill={colorPrimary} stroke={colorBgLayout} data-name="Ellipse 183">
          <circle cx={18} cy={18} r={18} stroke="none" />
          <circle cx={18} cy={18} r={18.5} fill="none" />
        </g>
        <path
          fill="currentColor"
          d="M3.378 28.5a18 18 0 0 1 29.244 0A17.976 17.976 0 0 1 18 36a17.976 17.976 0 0 1-14.622-7.5Z"
          data-name="Intersection 5"
        />
        <circle
          cx={8}
          cy={8}
          r={8}
          fill="currentColor"
          data-name="Ellipse 181"
          transform="translate(10 3)"
        />
      </g>
    </svg>
  );
};

export default UserAvatarIcon;
