import {
  Button,
  Col,
  Modal,
  Row,
  Skeleton,
  Table,
  TableColumnsType,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import { FindProductTypesQuery } from '../../../../graphql/generated/graphql';

export type IProductTypeDetails =
  FindProductTypesQuery['FindManyProductTypes'][0];

type IProductTypeDetailsProduct = NonNullable<
  IProductTypeDetails['product']
>[0];

type IProductTypeDetailsPacking = NonNullable<
  IProductTypeDetails['product_type_has_packing']
>[0]['packing'];

interface IProductTypesDetailsModalProps {
  /**
   * Control whether the loading spinners must be showed
   */
  loading?: boolean;
  /**
   * Function to close the modal
   */
  onClose?: () => void;
  /**
   * Control whether the modal is open or closed
   */
  open?: boolean;
  /**
   * Data to be showed inside the component
   */
  productTypeDetails?: IProductTypeDetails;
}

/**
 * Modal to show Products and Packings linked to a specific Product Type
 */
export const ProductTypeDetailsModal = ({
  loading,
  onClose,
  open,
  productTypeDetails,
}: IProductTypesDetailsModalProps) => {
  const PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH =
    'components.product.productType.productTypeDetailsModal';

  const { t } = useTranslation();

  const products = productTypeDetails?.product || [];
  const packings =
    productTypeDetails?.product_type_has_packing?.flatMap(
      ({ packing }) => packing
    ) || [];

  const productsColumns: TableColumnsType<IProductTypeDetailsProduct> = [
    {
      title: t(
        `${PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH}.tables.products.columns.name`
      ),
      dataIndex: 'name',
    },
  ];

  const packingsColumns: TableColumnsType<IProductTypeDetailsPacking> = [
    {
      title: t(
        `${PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH}.tables.packings.columns.name`
      ),
      dataIndex: 'name',
    },
    {
      title: t(
        `${PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH}.tables.packings.columns.capacity`
      ),
      dataIndex: 'capacity',
      render(capacity, packing) {
        return (
          <>
            {/* {Formatter.formatMeasuringUnit(capacity, packing.measuring_unity)} */}
          </>
        );
      },
    },
  ];

  return (
    <Modal
      open={open}
      destroyOnClose
      onCancel={onClose}
      footer={
        <Button onClick={onClose}>
          {t(`${PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH}.actions.close`)}
        </Button>
      }
      title={
        <Skeleton
          active
          loading={loading}
          paragraph={false}
          style={{ width: '90%' }}
        >
          {productTypeDetails?.name || ''}
        </Skeleton>
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Table
            columns={productsColumns}
            dataSource={products}
            rowKey={({ id }) => id}
            loading={loading}
            pagination={{ defaultPageSize: 5 }}
            size="small"
            title={() => (
              <>
                {t(
                  `${PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH}.tables.products.title`
                )}
              </>
            )}
          />
        </Col>
        <Col span={24}>
          <Table
            columns={packingsColumns}
            dataSource={packings}
            rowKey={({ id }) => id}
            loading={loading}
            pagination={{ defaultPageSize: 5 }}
            size="small"
            title={() => (
              <>
                {t(
                  `${PRODUCT_TYPE_DETAILS_MODAL_TRANSLATION_PATH}.tables.packings.title`
                )}
              </>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};
