import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  CancelFreightMutation,
  HiredFreightFragment,
  ListFreightsQueryVariables,
  QuotedFreightFragment,
  QuotingFreightFragment,
  useCancelFreightMutation,
  useListFreightsQuery,
} from '../../../../graphql/generated/graphql';
import { useInterval } from '../../../../hooks/useInterval/useInterval';
import { DefaultPageHeader } from '../../../AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { HiredFreightTable } from '../HiredFreightTable/HiredFreightTable';
import { QuotedFreightTable } from '../QuotedFreightTable/QuotedFreightTable';
import { QuotingFreightTable } from '../QuotingFreightTable/QuotingFreightTable';

export const ListFreights = (): ReactElement => {
  const LIST_FREIGHTS_TRANSLATION_PATH =
    'components.freight.shipper.listFreights';

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userData } = useUserContext();

  const [quotingFreights, setQuotingFreights] = useState<
    QuotingFreightFragment[]
  >([]);
  const [quotedFreights, setQuotedFreights] = useState<QuotedFreightFragment[]>(
    []
  );
  const [hiredFreights, setHiredFreights] = useState<HiredFreightFragment[]>();

  const [now] = useState(dayjs());

  const variablesFreights: ListFreightsQueryVariables | undefined = userData
    ?.company?.id
    ? {
        quotingInput: {
          owner: {
            is: {
              reference_external_company_id: {
                equals: userData?.company.id,
              },
            },
          },
          status: {
            is: {
              name: {
                equals: 'QUOTING',
              },
            },
          },
          quote_limit: {
            gt: now.toISOString(),
          },
        },
        quotedInput: {
          owner: {
            is: {
              reference_external_company_id: {
                equals: userData?.company.id,
              },
            },
          },
          OR: [
            {
              status: {
                is: {
                  name: {
                    equals: 'QUOTED',
                  },
                },
              },
              expires_in: {
                gt: now.toISOString(),
              },
            },
            {
              status: {
                is: {
                  name: {
                    equals: 'QUOTING',
                  },
                },
              },
              quote_limit: {
                lt: now.toISOString(),
              },
            },
          ],
        },
        hiredInput: {
          owner: {
            is: {
              reference_external_company_id: {
                equals: userData?.company.id,
              },
            },
          },
          OR: [
            {
              status: {
                is: {
                  name: {
                    equals: 'HIRED',
                  },
                },
              },
            },
            {
              status: {
                is: {
                  name: {
                    equals: 'HIRING',
                  },
                },
              },
            },
            {
              status: {
                is: {
                  name: {
                    equals: 'CARRYING',
                  },
                },
              },
            },
            {
              status: {
                is: {
                  name: {
                    equals: 'UNCOMPLETED',
                  },
                },
              },
            },
            {
              status: {
                is: {
                  name: {
                    equals: 'FINISHED',
                  },
                },
              },
            },
          ],
        },
        pagination: {
          take: 1000,
        },
      }
    : undefined;

  const [listFreightsResult] = useListFreightsQuery({
    variables: variablesFreights,
    pause: !variablesFreights,
  });

  const [cancelFreightResult] = useCancelFreightMutation();

  const handleCancelFreightRequestSuccess = (
    freight: CancelFreightMutation,
    freightStatus: 'QUOTED' | 'QUOTING'
  ) => {
    if (freightStatus === 'QUOTED') {
      const freightsAfterDelete = quotedFreights.filter(
        quotedFreight => freight.CancelFreight.id !== quotedFreight.id
      );

      setQuotedFreights(freightsAfterDelete);
    } else {
      const freightsAfterDelete = quotingFreights?.filter(
        quotingFreight => freight.CancelFreight.id !== quotingFreight.id
      );

      setQuotingFreights(freightsAfterDelete);
    }
  };

  const handleCreate = () => {
    navigate('/shipper/freights/create');
  };

  const handleView = (freightId: string) => {
    return navigate(`/shipper/freights/${freightId}`);
  };

  useEffect(() => {
    if (listFreightsResult.data && !listFreightsResult.fetching) {
      setQuotingFreights(listFreightsResult.data.FindManyFreightsQuoting);
      setQuotedFreights(listFreightsResult.data.FindManyFreightsQuoted);
      setHiredFreights(listFreightsResult.data.FindManyFreightsHired);
    }

    return () => {
      setQuotingFreights([]);
      setQuotedFreights([]);
      setHiredFreights(undefined);
    };
  }, [listFreightsResult]);

  useInterval(() => {
    const now = dayjs();

    const freights = quotingFreights.map(quotingFreight => {
      if (
        quotingFreight.status.name === 'QUOTING' &&
        dayjs(quotingFreight.quote_limit).isBefore(now, 'seconds')
      ) {
        quotingFreight.status.name = 'QUOTED';
      }

      return quotingFreight;
    });

    const filteredQuotingFreights = freights.filter(
      freight => freight.status.name === 'QUOTING'
    );

    setQuotingFreights(filteredQuotingFreights);

    const newQuotedFreights = [
      ...quotedFreights,
      ...freights.filter(freight => freight.status.name === 'QUOTED'),
    ];

    const filteredQuotedFreights = newQuotedFreights.filter(
      quotedFreight =>
        (quotedFreight.status.name === 'QUOTED' &&
          dayjs(quotedFreight.expires_in).isAfter(now, 'seconds')) ||
        (quotedFreight.status.name === 'QUOTING' &&
          dayjs(quotedFreight.quote_limit).isBefore(now, 'seconds'))
    );

    setQuotedFreights(filteredQuotedFreights);
  }, 1000);

  const isLoading =
    listFreightsResult.fetching || cancelFreightResult.fetching || !userData;

  return (
    <>
      <DefaultPageHeader
        subTitle={t(`${LIST_FREIGHTS_TRANSLATION_PATH}.subTitle`)}
        title={t(`${LIST_FREIGHTS_TRANSLATION_PATH}.title`)}
      />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={listFreightsResult.error} />
        <Col span={24}>
          <QuotingFreightTable
            freights={quotingFreights}
            loading={isLoading}
            onCreate={handleCreate}
            onView={handleView}
            onDeleteSuccess={freight =>
              handleCancelFreightRequestSuccess(freight, 'QUOTING')
            }
          />
        </Col>
        <Col span={24}>
          <QuotedFreightTable
            freights={quotedFreights}
            loading={isLoading}
            onDeleteSuccess={freight =>
              handleCancelFreightRequestSuccess(freight, 'QUOTED')
            }
            onView={handleView}
          />
        </Col>
        <Col span={24}>
          <HiredFreightTable
            freights={hiredFreights}
            loading={isLoading}
            onView={handleView}
          />
        </Col>
      </Row>
    </>
  );
};
