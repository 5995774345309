import {
  Button,
  Card,
  Form,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CancelFreightMutation,
  QuotingFreightFragment,
} from '../../../../graphql/generated/graphql';
import { Access } from '../../../Access/Access';
import { FormattedCountdown } from '../../../Formatters/FormattedCountdown/FormattedCountdown';
import { FormattedDate } from '../../../Formatters/FormattedDate/FormattedDate';
import { Volume } from '../../../../core/Freight/Volume';
import { CancelFreightModal } from '../CancelFreight/CancelFreight';
import { ProductWithVariety } from '../../../Product/Product/ProductWithVariety';

interface IQuotingFreightTableProps {
  freights?: QuotingFreightFragment[];
  loading?: boolean;
  onCreate: () => void;
  onDeleteSuccess?: (freight: CancelFreightMutation) => void;
  onView: (id: string) => void;
}

export const QuotingFreightTable = ({
  freights,
  loading,
  onCreate,
  onDeleteSuccess,
  onView,
}: IQuotingFreightTableProps) => {
  const QUOTING_FREIGHT_TABLE_TRANSLATION_PATH =
    'components.freight.shipper.quotingFreightTable';

  const { t } = useTranslation();

  const [cancelFreightModalFreightId, setCancelFreightModalFreightId] =
    useState<string>();

  const DeleteButton = ({ id }: { id: string }) => (
    <Button
      type="link"
      onClick={() => setCancelFreightModalFreightId(id)}
      danger
    >
      {t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.actions.delete.button`)}
    </Button>
  );

  const DeleteButtonAccess = Access(DeleteButton, {
    acceptedPermissions: [{ module: 'FREIGHTS', actions: ['DELETE'] }],
  });

  const CreateButton = () => (
    <Button type="primary" onClick={onCreate}>
      {t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.actions.create`)}
    </Button>
  );

  const CreateButtonAccess = Access(CreateButton, {
    acceptedPermissions: [{ module: 'FREIGHTS', actions: ['CREATE'] }],
  });

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.title`)}
      </Typography.Text>
      <CreateButtonAccess />
    </Row>
  );

  const columns: TableColumnsType<QuotingFreightFragment> = [
    {
      title: t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.route`),
      dataIndex: ['route', 'name'],
    },
    {
      title: t(
        `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.shipment_date`
      ),
      dataIndex: 'shipment_date',
      render: shipmentDate => <FormattedDate dateTime={shipmentDate} />,
    },
    {
      title: t(
        `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.landing_date`
      ),
      dataIndex: 'landing_date',
      render: landingDate => <FormattedDate dateTime={landingDate} />,
    },
    {
      title: t(
        `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product_type`
      ),
      dataIndex: ['product_type', 'name'],
    },
    {
      title: t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product`),
      dataIndex: ['product', 'name'],
      render: (_, freight) => (
        <>
          {ProductWithVariety.getProductWithVariety({
            product: freight.product,
            product_variety: freight.product_variety,
          })}
        </>
      ),
    },
    {
      title: t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.packing`),
      dataIndex: ['packing', 'name'],
    },
    {
      title: t(
        `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.total_volume`
      ),
      dataIndex: 'total_volume',
      render: (totalVolume, freight) => (
        <div style={{ minWidth: 90 }}>
          <Volume.Display volume={totalVolume} unitType={freight.unit_type} />
        </div>
      ),
    },
    {
      title: (
        <Form.Item
          label={t(
            `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.quote_limit`
          )}
          tooltip={t(
            `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.tooltips.quote_limit`
          )}
          style={{ margin: 0 }}
          colon={false}
        />
      ),
      dataIndex: 'quote_limit',
      render: quoteLimitDate => (
        <div style={{ minWidth: 100 }}>
          <FormattedCountdown dateTime={quoteLimitDate} />
        </div>
      ),
    },
    {
      title: t(
        `${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.quotes_received`
      ),
      dataIndex: 'quotations',
      render: (quotations: unknown[] | undefined) => (
        <>{quotations?.length || 0}</>
      ),
    },
    {
      title: t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.actions`),
      dataIndex: 'actions',
      render: (_, { id }) => (
        <Space>
          <Button type="link" onClick={() => onView(id)}>
            {t(`${QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.actions.view`)}
          </Button>
          <DeleteButtonAccess id={id} />
        </Space>
      ),
    },
  ];

  return (
    <Card title={title}>
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={freights}
        loading={loading}
        rowKey={freight => freight.id}
      />

      <CancelFreightModal
        open={!!cancelFreightModalFreightId}
        onCancel={() => setCancelFreightModalFreightId(undefined)}
        onDeleteSuccess={onDeleteSuccess}
        freightId={cancelFreightModalFreightId}
      />
    </Card>
  );
};
