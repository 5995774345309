/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import errorHandler from '../../api/errorHandler';
import {
  CompanyStatusEnum,
  CompanyTypeEnum,
  FindUserQuery,
  FunctionEnum,
  useFindUserQuery,
} from '../../graphql/generated/graphql';
import { IAPIError, IJSONObject, ISetState } from '../../utils/interfaces';
import { useAuthContext } from '../AuthContext/useAuthContext';
import { IUseCompanySettings, useCompanySettings } from './UserContext.hooks';

export interface ICompanySettings {
  theme?: {
    id: string;
    config: IJSONObject;
  } | null;
}

export interface IUserCompanyData {
  id: string;
  trade_name?: string | null;
  company_function: FunctionEnum;
  company_type: CompanyTypeEnum;
  company_status: CompanyStatusEnum;
  document_number: string;
}

export interface IUserData {
  id: string;
  name: string;
  email: string;
  company?: IUserCompanyData;
}

export interface IUserDataWithCompanySettings extends IUserData {
  companySettings?: ICompanySettings | null;
}

type IUserProviderCompanySettings = Omit<IUseCompanySettings, 'handlers'> & {
  themeHandlers: IUseCompanySettings['handlers']['theme'];
};

export interface IUserProvider extends IUserProviderCompanySettings {
  userData?: IUserData | null;
  setUserData?: ISetState<IUserData | null | undefined>;
  termsOfUseUrl?: string;
}

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    companySettings,
    initialCompanySettings,
    handlers: { initialize: initializeCompanySettings, theme: themeHandlers },
  } = useCompanySettings();

  const [userData, setUserData] = useState<IUserData | null | undefined>();

  const { refreshToken, isUserLoggedIn, userId, logout, isAdmOrSup } =
    useAuthContext();

  const findUserQueryVariables = isUserLoggedIn ? { userId } : undefined;

  const [findUserQueryResult] = useFindUserQuery({
    variables: findUserQueryVariables,
    pause: !findUserQueryVariables,
  });

  const redirectUser = ({ company }: Pick<IUserData, 'company'>) => {
    const companyStatusRedirect: Record<
      CompanyStatusEnum,
      (() => void) | undefined
    > = {
      BLOCKED: () => {
        if (refreshToken) {
          localStorage.setItem('SignUp_refreshToken', refreshToken);
        }

        return navigate('/accountBlocked', {
          replace: true,
          state: { user: userData },
        });
      },
      REPROVED: () => {
        if (refreshToken) {
          localStorage.setItem('SignUp_refreshToken', refreshToken);
        }

        return navigate('/accountReproved', {
          replace: true,
          state: { user: userData },
        });
      },
      PENDING: () =>
        navigate('/accountInApproval', {
          replace: true,
          state: {
            companyId: company?.id,
          },
        }),
      IN_ANALYSIS: () =>
        navigate('/accountInApproval', {
          replace: true,
          state: {
            companyId: company?.id,
          },
        }),
      RECTIFIED_DATA: () =>
        navigate('/accountInApproval', {
          replace: true,
          state: {
            companyId: company?.id,
          },
        }),
      APPROVED: undefined,
      WAITING_DATA: undefined,
    };

    const redirect = company
      ? companyStatusRedirect[company.company_status]
      : undefined;
    if (redirect) {
      logout();
      redirect();
    }
  };

  const formatFindUserToUserData = ({
    id,
    name,
    email,
    companies,
  }: FindUserQuery['FindUser']): IUserDataWithCompanySettings | null => {
    if (companies) {
      const mainCompany = companies[0].company;

      if (mainCompany && mainCompany.companyStatus) {
        const mainCompanyStatus = mainCompany.companyStatus
          .map(({ status, updated_at }) => ({
            status,
            updated_at: new Date(updated_at),
          }))
          .reduce(
            (
              mostRecentStatus: {
                status: CompanyStatusEnum;
                updated_at: Date;
              } | null,
              status
            ) => {
              if (!mostRecentStatus) return status;

              if (status.updated_at >= mostRecentStatus.updated_at)
                return status;

              return mostRecentStatus;
            },
            null
          )?.status;

        if (mainCompanyStatus) {
          return {
            id,
            name,
            email,
            company: {
              id: mainCompany.id,
              trade_name: mainCompany.trade_name,
              company_type: mainCompany.company_type,
              company_function: mainCompany.company_function,
              company_status: mainCompanyStatus,
              document_number: mainCompany.document_number,
            },
            companySettings: mainCompany.settings,
          };
        }
      }
    } else if (isAdmOrSup) {
      try {
        const companyInLocalStorage = localStorage.getItem('company');
        const companyInLocalStorageAsObject = companyInLocalStorage
          ? JSON.parse(companyInLocalStorage)
          : undefined;

        return {
          id,
          name,
          email,
          company: companyInLocalStorageAsObject,
        };
      } catch {
        localStorage.removeItem('company');

        return {
          id,
          name,
          email,
        };
      }
    }

    return null;
  };

  useEffect(() => {
    return () => {
      setUserData(undefined);
      initializeCompanySettings(undefined);
    };
  }, []);

  useEffect(() => {
    const { data, error, fetching } = findUserQueryResult;

    if (error) {
      const { message, description } = errorHandler(
        error.graphQLErrors as unknown as IAPIError[]
      );

      notification.error({
        message: t(message),
        description: t(description),
      });

      setUserData(null);
      initializeCompanySettings(null);
      logout();
    }

    if (data && !fetching) {
      const userData = formatFindUserToUserData(data.FindUser);

      if (userData) {
        setUserData(userData);
        initializeCompanySettings(userData?.companySettings || null);
      } else if (data.FindUser.id) {
        if (refreshToken) {
          localStorage.setItem('SignUp_refreshToken', refreshToken);
        }

        logout();
        navigate('/resumeSignUp', {
          replace: true,
          state: {
            userId: data.FindUser.id,
            name: data.FindUser.name,
            email: data.FindUser.email,
          },
        });
      } else {
        notification.error({
          message: t('errors.userWithoutCompany.message'),
          description: t('errors.userWithoutCompany.description'),
        });

        setUserData(null);
        initializeCompanySettings(null);
        logout();
      }
    }
  }, [findUserQueryResult]);

  useEffect(() => {
    if (userData) {
      redirectUser(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (isUserLoggedIn === false) {
      setUserData(null);
      initializeCompanySettings(null);
    }
  }, [isUserLoggedIn]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        termsOfUseUrl: process.env.REACT_APP_TERMS_OF_USE_URL,
        companySettings,
        initialCompanySettings,
        themeHandlers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserContextDefaultValues: IUserProvider = {
  setUserData: () => null,
  companySettings: null,
  initialCompanySettings: null,
  themeHandlers: {
    commit: () => null,
    rollback: () => null,
    update: () => null,
  },
};

export const UserContext = React.createContext<IUserProvider>(
  UserContextDefaultValues
);
