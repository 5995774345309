import React from 'react';
import Button from '../../../components/AntdCustom/Button/Button';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';

import './index.scss';

interface ISignUpMessageProps {
  children: React.ReactElement;
  title: string;
  buttonText: string;
  buttonAction: () => void;
  image: React.ReactElement;
  className?: string;
}

const SignUpMessage = ({
  children,
  image,
  title,
  className,
  buttonText,
  buttonAction,
}: ISignUpMessageProps): React.ReactElement => {
  const confirmButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      onClick={buttonAction}
    >
      {buttonText}
    </Button>
  );

  return (
    <div
      className={`sign-up-message_container${className ? ' ' + className : ''}`}
    >
      <SupportHelpContact />
      <div className="content_container">
        <div className="image_container">
          <span className="svg_container">{image}</span>
        </div>
        <h1>{title}</h1>
        <div className="children_container">{children}</div>
      </div>
      <footer>{confirmButton}</footer>
    </div>
  );
};

export default SignUpMessage;
