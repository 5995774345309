import { gql, TypedDocumentNode } from 'urql';
import { ICityName, IZipCodeAddressResponse } from '../interfaces/Address';

const countryNameFragment = `
  initials
  name
`;

const stateNameFragment = `
  initials
  name: state_name
`;

const cityNameFragment = `
  id
  name: city_name
`;

const districtNameFragment = `
  id
  name: district_name
`;

export const addressFragment = `
  id
  address: street
  number
  latitude
  longitude
  complement
  zipCode: zip_code_address
  districtId: district_id
  districtName: district_name
  city {
    ${cityNameFragment}
  }
  state {
    ${stateNameFragment}
  }
  country {
    ${countryNameFragment}
  }
`;

const queries = {
  findZipCodeAddress: (): TypedDocumentNode<
    IZipCodeAddressResponse,
    { zipCode: string }
  > => gql`
    query ($zipCode: String!) {
      zipCodeAddress: FindZipCode(input: { zip_code: $zipCode }) {
        zipCode: zip_code
        streetType: type
        streetName: street_name
        latitude
        longitude
        state {
          ${stateNameFragment}
        }
        city {
          ${cityNameFragment}
        }
        district {
          ${districtNameFragment}
        }
      }
    }
  `,

  findCitiesByState: (): TypedDocumentNode<
    { cities: ICityName[] },
    { stateId: string }
  > => gql`
    query ($stateId: String!) {
      cities: FindCities(state_id: $stateId) {
        ${cityNameFragment}
      }
    }
  `,
};

export default queries;
