import { ExportOutlined } from '@ant-design/icons';
import { Button, Space, TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonI18n } from '../../../../core/Common/Common.hooks';
import {
  AttachmentsTableFragment,
  AttachmentsTableModal_DetachFileMutation,
} from '../../../../graphql/generated/graphql';
import { FormattedDateTime } from '../../../Formatters/FormattedDateTime/FormattedDateTime';
import { DeleteButtonAccess } from './AttachmentsTable.components';

export const useColumns = (
  onDelete?: (
    fileId: string
  ) => Promise<AttachmentsTableModal_DetachFileMutation>
) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.attachments.attachmentsTable.columns',
  });

  const {
    tKeys: { actions, view },
  } = useCommonI18n();

  const columns = useMemo(() => {
    const columns: TableColumnsType<AttachmentsTableFragment> = [
      {
        dataIndex: 'file',
        title: t('file'),
        render: (file: AttachmentsTableFragment['file']) => (
          <>{file?.filename}</>
        ),
      },
      {
        dataIndex: 'short_description',
        title: t('short_description'),
      },
      {
        dataIndex: 'created_at',
        title: t('created_at'),
        width: 150,
        render: (created_at: AttachmentsTableFragment['created_at']) => (
          <FormattedDateTime dateTime={created_at} />
        ),
      },
      {
        dataIndex: 'actions',
        title: actions,
        render: (_, { file_id, file }) => (
          <Space size="small">
            {file && (
              <Button
                type="link"
                onClick={() => window.open(file.url, '_blank')}
              >
                <ExportOutlined /> {view}
              </Button>
            )}
            {onDelete && (
              <DeleteButtonAccess onDelete={() => onDelete(file_id)} />
            )}
          </Space>
        ),
      },
    ];

    return columns;
  }, [actions, onDelete, t, view]);

  return columns;
};
