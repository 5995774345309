import { PageHeaderProps } from '@ant-design/pro-layout';
import { ReactElement } from 'react';
import { DefaultPageHeader } from '../DefaultPageHeader/DefaultPageHeader';

type IDefaultPageFooterProps = PageHeaderProps;

export const DefaultPageFooter = (
  props: IDefaultPageFooterProps
): ReactElement => {
  return <DefaultPageHeader onBack={undefined} {...props} />;
};
