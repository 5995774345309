import { Button, Card, Popconfirm } from 'antd';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import { CompanyDetails } from '../../../../../components/Freight/FreightDetails/Components/CompanyDetails/CompanyDetails';
import { useQuotationGroupMembersTable_FindCompanyQuery } from '../../../../../graphql/generated/graphql';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonI18n } from '../../../../../core/Common/Common.hooks';

export const MemberExpandable = ({ companyId }: { companyId: string }) => {
  const [result] = useQuotationGroupMembersTable_FindCompanyQuery({
    variables: { companyId },
  });
  const { data, error, fetching } = result;

  return (
    <>
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />
      <CompanyDetails company={data?.FindCompany} loading={fetching} />
    </>
  );
};

export interface IActionsCardProps {
  children?: ReactNode;
  createButtonProps?: {
    disabled?: boolean;
    onClick: () => void;
  };
  removeSelectedButtonProps?: {
    disabled?: boolean;
    onConfirm: () => void;
  };
}

export const ActionsCard = ({
  children,
  createButtonProps,
  removeSelectedButtonProps,
}: IActionsCardProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.shipper.quotationGroupsPage.components.quotationGroupMembersTable',
  });

  const {
    tKeys: { yes },
  } = useCommonI18n();

  const title = createButtonProps && (
    <Button type="primary" {...createButtonProps}>
      {t('actions.addMember')}
    </Button>
  );

  const extra = removeSelectedButtonProps && (
    <Popconfirm
      okButtonProps={{ danger: true }}
      okText={yes}
      title={t('actions.removeSelected.popconfirm')}
      {...removeSelectedButtonProps}
    >
      <Button danger disabled={removeSelectedButtonProps.disabled} type="link">
        {t('actions.removeSelected.button')}
      </Button>
    </Popconfirm>
  );

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      bordered={false}
      extra={extra}
      headStyle={{ padding: 0 }}
      style={{ boxShadow: 'none' }}
      title={title}
    >
      {children}
    </Card>
  );
};
