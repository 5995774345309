import { ReactElement } from 'react';
import { Layer, Source } from 'react-map-gl';

interface ILineLayerProps {
  id?: string;
  line?: ILine;
  lineWidth?: number;
  lineOpacity?: number;
}

interface ILine {
  coordinates: [number, number][];
  properties?: Record<string, unknown>;
}

export const LineLayer = ({
  id,
  line,
  lineWidth = 2,
  lineOpacity = 1,
}: ILineLayerProps): ReactElement => {
  if (!line) {
    return <></>;
  }

  const layerData: GeoJSON.Feature<GeoJSON.Geometry> = {
    type: 'Feature',
    properties: line.properties || null,
    geometry: {
      type: 'LineString',
      coordinates: line.coordinates,
    },
  };

  return (
    <Source id={id} type="geojson" data={layerData}>
      <Layer
        id={id}
        type="line"
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        paint={{
          'line-color': 'red',
          'line-width': lineWidth,
          'line-opacity': lineOpacity,
        }}
      />
    </Source>
  );
};
