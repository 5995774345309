import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CreateManyProductsInput,
  CreateManyProductsMutation,
  useCreateManyProductsMutation,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { FormItemIcon } from '../../../AntdCustom/FormItemIcon/FormItemIcon';
import { ProductTypeSelect } from '../../ProductType/ProductTypeSelect/ProductTypeSelect';

interface ICreateProductsModalProps {
  /**
   * Function to be executed to close the modal
   */
  onClose?: () => void;
  /**
   * Function to be executed when CreateProductsMutation was succeeded
   */
  onSuccess?: (
    formValues: CreateManyProductsMutation['CreateManyProducts']['data']
  ) => void;
  /**
   * Control whether modal is open or closed
   */
  open?: boolean;
}

/**
 * Modal with form to create many products at same time
 */
export const CreateProductsModal = ({
  onClose,
  onSuccess,
  open,
}: ICreateProductsModalProps) => {
  const CREATE_PRODUCTS_MODAL_TRANSLATION_PATH =
    'components.product.product.createProductsModal';

  const { t } = useTranslation();

  const [form] = Form.useForm<CreateManyProductsInput>();

  const [createProductsMutationResult, executeCreateProductsMutation] =
    useCreateManyProductsMutation();

  const handleFinish = (formValues: CreateManyProductsInput) => {
    const cleanValues = {
      data: formValues.data.map(({ name, type_id }) => ({
        name: name.trim(),
        type_id,
      })),
    };

    executeCreateProductsMutation({ input: cleanValues }).then(({ data }) => {
      if (data) {
        onSuccess && onSuccess(data.CreateManyProducts.data);

        form.resetFields();
      }
    });
  };

  const colSpan = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };

  const { error, fetching } = createProductsMutationResult;

  const footer = (
    <Space size="large" wrap style={{ justifyContent: 'end' }}>
      <Button
        htmlType="reset"
        onClick={() => form.resetFields()}
        disabled={fetching}
      >
        {t('general.clear')}
      </Button>
      <Button onClick={onClose} disabled={fetching}>
        {t('general.cancel')}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => form.submit()}
        loading={fetching}
      >
        {t('general.confirm')}
      </Button>
    </Space>
  );

  return (
    <Modal
      onCancel={onClose}
      open={open}
      title={<>{t(`${CREATE_PRODUCTS_MODAL_TRANSLATION_PATH}.title`)}</>}
      width={720}
      footer={footer}
    >
      <>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          disabled={fetching}
        >
          <Form.List name="data" initialValue={['']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Row
                    align="middle"
                    justify="space-between"
                    key={field.key}
                    wrap={false}
                  >
                    <Row gutter={[24, 0]} style={{ padding: 12, flex: 1 }}>
                      <Col {...colSpan}>
                        <Form.Item
                          {...field}
                          label={t(
                            `${CREATE_PRODUCTS_MODAL_TRANSLATION_PATH}.fields.labels.name`
                          )}
                          name={[field.name, 'name']}
                          required
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                            },
                          ]}
                          style={{ flex: 1 }}
                        >
                          <Input
                            placeholder={t(
                              `${CREATE_PRODUCTS_MODAL_TRANSLATION_PATH}.fields.placeholders.name`
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...colSpan}>
                        <Form.Item
                          {...field}
                          label={t(
                            `${CREATE_PRODUCTS_MODAL_TRANSLATION_PATH}.fields.labels.product_type`
                          )}
                          name={[field.name, 'type_id']}
                          required
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                            },
                          ]}
                          style={{ flex: 1 }}
                        >
                          <ProductTypeSelect
                            disabled={fetching}
                            placeholder={t(
                              `${CREATE_PRODUCTS_MODAL_TRANSLATION_PATH}.fields.placeholders.product_type`
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {fields.length > 1 && (
                      <FormItemIcon
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Row>
                ))}
                <Button block onClick={() => add()}>
                  {t(`${CREATE_PRODUCTS_MODAL_TRANSLATION_PATH}.actions.add`)}
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </>
    </Modal>
  );
};
