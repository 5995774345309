import { ReactElement } from 'react';

const FormSection = ({
  sectionTitle,
  children,
}: {
  sectionTitle: string;
  children: JSX.Element;
}): ReactElement => {
  return (
    <div className="form-section_container">
      <h2>{sectionTitle}</h2>
      <div className="form-section_inputs">{children}</div>
    </div>
  );
};

export default FormSection;
