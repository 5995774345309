import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import { AttachmentForm_AttachFileMutationVariables } from '../../../../graphql/generated/graphql';
import { IAttachmentFormValues } from '../AttachmentForm/AttachmentForm';

export type IAttachmentFormSerializer = (
  formValues: IAttachmentFormValues
) => AttachmentForm_AttachFileMutationVariables | undefined;

export const useAttachmentFormSerializer = (): IAttachmentFormSerializer => {
  const { userData } = useUserContext();

  const { freightId } = useParams();

  const serializer: IAttachmentFormSerializer = useCallback(
    formValues => {
      const [file] = formValues.file;

      if (freightId && userData?.id && file.originFileObj) {
        return {
          file: file.originFileObj,
          input: {
            short_description: formValues.short_description?.trim(),
            creator_id: userData.id,
            freight_id: freightId,
          },
        };
      }
    },
    [freightId, userData]
  );

  return serializer;
};
