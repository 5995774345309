import { BreadcrumbProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import FreightDetails from '../../../../components/Freight/FreightDetails/FreightDetails';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { breadcrumbItemRender } from '../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

const TRANSLATION_PATH = 'pages.freight.freightDetailsPage';

export const FreightDetailsPage = (): ReactElement => {
  const { t } = useTranslation();

  const { freightId } = useParams();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/shipper/freights',
        breadcrumbName: t('tab.shipper.freight.index'),
      },
      {
        path: '/shipper/freights/:freightId',
        breadcrumbName: t(`${TRANSLATION_PATH}.breadcrumb`),
      },
    ],
  };

  useTitle({ title: `${TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/freights'],
  });

  return (
    <FixedFooterContainer>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        style={{ minWidth: 400 }}
        title={t(`${TRANSLATION_PATH}.title`)}
      />
      <FreightDetails freightId={freightId} />
    </FixedFooterContainer>
  );
};

const FreightDetailsPageAccess = Access(FreightDetailsPage, {
  acceptedPermissions: [
    {
      module: 'FREIGHTS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default FreightDetailsPageAccess;
