import { TableColumnType, TableColumnsType, TableProps } from 'antd';
import { DefaultTable } from '../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import { CompanyName } from '../../../../../components/Company/CompanyName/CompanyName';
import { FormattedDocumentNumber } from '../../../../../components/Formatters/FormattedDocumentNumber/FormattedDocumentNumber';
import { useCompanyI18n } from '../../../../../core/Company/Company.hooks';
import { useCompanyInGroupI18n } from '../../../../../core/CompanyInGroup/CompanyInGroup.hooks';
import {
  FreightQuotationGroupMembersTableFragment,
  QuotationGroupMembersTableFragment,
} from '../../../../../graphql/generated/graphql';
import {
  ActionsCard,
  MemberExpandable,
} from './QuotationGroupMembersTable.components';
import { useGroupMembers } from './QuotationGroupMembersTable.hooks';

export type IQuotationGroupMembersTableData = (
  | QuotationGroupMembersTableFragment
  | FreightQuotationGroupMembersTableFragment
) & {
  created_at: string;
};

export interface IQuotationGroupMembersTableProps
  extends Partial<TableProps<IQuotationGroupMembersTableData>> {
  actionsColumn?: TableColumnType<IQuotationGroupMembersTableData>;
}

export const QuotationGroupMembersTable = ({
  actionsColumn,
  ...tableProps
}: IQuotationGroupMembersTableProps) => {
  const {
    tKeys: { CNPJ: documentNumberTitle, company: companyTitle },
  } = useCompanyI18n();

  const {
    tKeys: { createdAt: createdAtTitle },
  } = useCompanyInGroupI18n();

  const columns: TableColumnsType<IQuotationGroupMembersTableData> = [
    {
      dataIndex: 'document_number',
      title: documentNumberTitle,
      width: 200,
      render(
        documentNumber: IQuotationGroupMembersTableData['document_number']
      ) {
        return <FormattedDocumentNumber documentNumber={documentNumber} />;
      },
    },
    {
      dataIndex: 'company',
      title: companyTitle,
      render(_, company) {
        return <CompanyName company={company} />;
      },
    },
    {
      dataIndex: 'created_at',
      title: createdAtTitle,
      sorter: (a, b) => (a.created_at <= b.created_at ? -1 : 1),
      defaultSortOrder: 'descend',
      render(createdAt: IQuotationGroupMembersTableData['created_at']) {
        return <DefaultTable.Columns.CreatedAt date={createdAt} />;
      },
    },
  ];

  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return (
    <DefaultTable
      {...tableProps}
      columns={columns}
      expandable={{
        expandedRowRender: record => <MemberExpandable companyId={record.id} />,
      }}
      pagination={{ hideOnSinglePage: true, pageSize: 5 }}
      rowKey={({ id }) => id}
    />
  );
};

QuotationGroupMembersTable.useGroupMembers = useGroupMembers;
QuotationGroupMembersTable.ActionsCard = ActionsCard;
