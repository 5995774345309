import { useEffect, useState } from 'react';
import { useLayoutContext } from '../../contexts/LayoutContext/useLayoutContext';

interface IUseMenuItem {
  openMenu?: string[];
  selectedMenu?: string[];
}

export const useMenuItem = (props: IUseMenuItem) => {
  const { setOpenMenu, setSelectedMenu } = useLayoutContext();

  const [openMenu] = useState(props?.openMenu || []);
  const [selectedMenu] = useState(props?.selectedMenu || []);

  useEffect(() => {
    setOpenMenu(openMenu);
  }, [openMenu, setOpenMenu]);

  useEffect(() => {
    setSelectedMenu(selectedMenu);
  }, [selectedMenu, setSelectedMenu]);

  return {
    openMenus: openMenu,
    selectedMenus: selectedMenu,
  };
};
