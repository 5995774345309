import { useTranslation } from 'react-i18next';
import { CombinedError } from 'urql';

import { Alert, AlertProps, Col } from 'antd';

import { useEffect, useRef } from 'react';
import errorHandler from '../../../api/errorHandler';
import { IAPIError } from '../../../utils/interfaces';

interface IErrorAlertProps
  extends Omit<AlertProps, 'type' | 'message' | 'description'> {
  /**
   * [URQL request error object](https://formidable.com/open-source/urql/docs/basics/errors/)
   */
  error?: CombinedError;
}

/**
 * Ant design customized alert with an ErrorHandler to display supported error messages from back-end API
 */
export const ErrorAlert = ({ error, ...alertProps }: IErrorAlertProps) => {
  const { t } = useTranslation();

  const internalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    internalRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [error]);

  if (!error) return <></>;

  const { graphQLErrors, networkError } = error;

  if (networkError) {
    return (
      <Col span={24} ref={internalRef}>
        <Alert
          {...alertProps}
          type="error"
          message={t('errors.network.message')}
          description={t('errors.network.description')}
        />
      </Col>
    );
  }

  if (graphQLErrors) {
    const { message, description } = errorHandler(
      graphQLErrors as unknown as IAPIError[]
    );

    return (
      <Col span={24} ref={internalRef}>
        <Alert
          {...alertProps}
          type="error"
          message={t(message)}
          description={t(description)}
        />
      </Col>
    );
  }

  return (
    <Col span={24} ref={internalRef}>
      <Alert
        {...alertProps}
        type="error"
        message={t('errors.default.message')}
        description={t('errors.default.description')}
      />
    </Col>
  );
};

export default ErrorAlert;
