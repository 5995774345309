/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, ModalProps, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  CancelFreightMutation,
  RefusalTriggerEnum,
  useCancelFreightMutation,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { RefusalReasonSelect } from '../../RefusalReason/RefusalReasonSelect/RefusalReasonSelect';

interface CancelFreightModalProps extends ModalProps {
  freightId?: string;
  onCancel?: () => void;
  /**
   * Function to execute when a freight is successfully deleted
   */
  onDeleteSuccess?: (freight: CancelFreightMutation) => void;
}

interface ICancelFreightModalForm {
  reason: string;
  reasonDescription?: string;
}

const TRANSLATION_PATH = 'components.freight.cancelFreight';

export const CancelFreightModal = ({
  freightId,
  onCancel,
  onDeleteSuccess,
  ...modalProps
}: CancelFreightModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ICancelFreightModalForm>();
  const { userData } = useUserContext();

  const [cancelFreightResult, executeCancelFreightMutation] =
    useCancelFreightMutation();

  useEffect(() => {
    if (!modalProps.open) {
      delete cancelFreightResult.error;
    }
  }, [modalProps.open]);

  const handleCancelFreight = (formValues: ICancelFreightModalForm) => {
    if (freightId && userData?.company) {
      executeCancelFreightMutation({
        input: {
          freight_id: freightId,
          shipper_id: userData?.company.id,
          reason_id: formValues.reason,
          description: formValues.reasonDescription,
        },
      }).then(({ data, error }) => {
        if (data && !error) {
          onDeleteSuccess && onDeleteSuccess(data);
          handleOnClose();
        }
      });
    }
  };

  const handleOnClose = () => {
    if (!cancelFreightResult.fetching) {
      onCancel && onCancel();
      form.resetFields();
    }
  };

  const footer = (
    <Space size="large">
      <Button
        size="small"
        onClick={handleOnClose}
        disabled={cancelFreightResult.fetching}
      >
        {t('general.close')}
      </Button>
      <Button
        size="small"
        type="primary"
        htmlType="submit"
        loading={cancelFreightResult.fetching}
        disabled={!!cancelFreightResult.error}
        onClick={form.submit}
      >
        {t('general.confirm')}
      </Button>
    </Space>
  );

  return (
    <Modal
      width={'992px'}
      title={t(`${TRANSLATION_PATH}.title`)}
      footer={footer}
      centered
      onCancel={handleOnClose}
      {...modalProps}
    >
      <ErrorAlert
        error={cancelFreightResult.error}
        style={{ marginBottom: 24 }}
      />

      <Form
        form={form}
        disabled={cancelFreightResult.fetching || !!cancelFreightResult.error}
        layout="vertical"
        onFinish={handleCancelFreight}
      >
        <Form.Item
          name="reason"
          label={t(`${TRANSLATION_PATH}.inputs.labels.reason`)}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <RefusalReasonSelect
            placeholder={t('general.select')}
            style={{ width: 250 }}
            refusalReasonTrigger={RefusalTriggerEnum.Cancel}
            disabled={
              cancelFreightResult.fetching || !!cancelFreightResult.error
            }
          />
        </Form.Item>
        <Form.Item
          name="reasonDescription"
          label={t(`${TRANSLATION_PATH}.inputs.labels.reasonDescription`)}
        >
          <Input.TextArea
            size="small"
            placeholder={t(
              `${TRANSLATION_PATH}.inputs.placeholders.reasonDescription`
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
