import { Alert, Col, Form, FormInstance, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { RefusalTriggerEnum } from '../../../graphql/generated/graphql';
import { RefusalReasonSelect } from '../../Freight/RefusalReason/RefusalReasonSelect/RefusalReasonSelect';
import { useRefuseQuotation } from './RefuseQuotationModal.hooks';

export interface IRefuseQuotationFormValues {
  reason: string;
  reason_description?: string;
}

interface IRefuseQuotationModalProps {
  form: FormInstance<IRefuseQuotationFormValues>;
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  onFinish: (formValues: IRefuseQuotationFormValues) => void;
}

export const RefuseQuotationModal = ({
  form,
  isOpen,
  loading,
  onClose,
  onFinish,
}: IRefuseQuotationModalProps) => {
  const REFUSE_QUOTATION_MODAL_TRANSLATION_PATH =
    'components.quotation.refuseQuotationModal';

  const { t } = useTranslation();

  return (
    <Modal
      cancelButtonProps={{ size: 'small' }}
      cancelText={t('general.close')}
      centered
      okButtonProps={{ size: 'small', loading }}
      okText={t(`${REFUSE_QUOTATION_MODAL_TRANSLATION_PATH}.actions.submit`)}
      onCancel={onClose}
      onOk={form.submit}
      open={isOpen}
      title={t(`${REFUSE_QUOTATION_MODAL_TRANSLATION_PATH}.title`)}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
      >
        <Alert
          closable
          description={t(
            `${REFUSE_QUOTATION_MODAL_TRANSLATION_PATH}.alert.description`
          )}
          style={{ marginBottom: 24 }}
          type="warning"
        />
        <Row>
          <Col xs={24} sm={12}>
            <Form.Item
              name="reason"
              label={t(
                `${REFUSE_QUOTATION_MODAL_TRANSLATION_PATH}.fields.labels.reason`
              )}
              required
              rules={[{ required: true }]}
              validateFirst
            >
              <RefusalReasonSelect
                disabled={loading}
                refusalReasonTrigger={RefusalTriggerEnum.Hide}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="reason_description"
              label={t(
                `${REFUSE_QUOTATION_MODAL_TRANSLATION_PATH}.fields.labels.reason_description`
              )}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

RefuseQuotationModal.useRefuseQuotation = useRefuseQuotation;
