/* eslint-disable react-hooks/exhaustive-deps */
import { CloudUploadOutlined } from '@ant-design/icons';
import {
  Form,
  FormInstance,
  Upload,
  UploadFile,
  UploadProps,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseRequestAction } from '../../../../hooks/useRequest';
import {
  IDeleteCompanyLogo,
  IDeleteCompanyLogoResponse,
  IUploadCompanyLogo,
  IUploadCompanyLogoResponse,
} from '../../../../structures/interfaces/Company/Company';

interface ICompanyDataLogoUploadProps {
  userId: string;
  companyId?: string;
  form: FormInstance;
  defaultFileUrl?: string;
  companyDataTextPath: string;
  uploadLogoImageRequest: IUseRequestAction<
    IUploadCompanyLogo,
    IUploadCompanyLogoResponse
  >;
  deleteLogoImageRequest: IUseRequestAction<
    IDeleteCompanyLogo,
    IDeleteCompanyLogoResponse
  >;
}

const CompanyDataLogoUpload = ({
  form,
  userId,
  companyId,
  defaultFileUrl,
  companyDataTextPath,
  uploadLogoImageRequest,
  deleteLogoImageRequest,
}: ICompanyDataLogoUploadProps) => {
  const { t } = useTranslation();

  const { Dragger } = Upload;

  const [previewUrl, setPreviewUrl] = useState<string>();

  const [fileList, setFileList] = useState<UploadFile[]>();

  useEffect(() => {
    form.setFieldsValue({ urlLogo: previewUrl });
  }, [previewUrl]);

  useEffect(() => {
    if (defaultFileUrl && !previewUrl) {
      try {
        const url = defaultFileUrl.split('/');

        const fileName = url.pop();
        const fileId = fileName?.split('.').shift();

        if (fileName && fileId) {
          setFileList([{ uid: fileId, name: fileName, url: defaultFileUrl }]);
          setPreviewUrl(defaultFileUrl);
        }
      } catch {
        setFileList(undefined);
      }
    } else {
      setFileList(undefined);
    }
  }, [defaultFileUrl]);

  const beforeUpload = (file: NonNullable<UploadFile['originFileObj']>) => {
    setFileList(undefined);

    const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!acceptedTypes.includes(file.type)) {
      notification.error({
        message: t(`${companyDataTextPath}.fields.rules.acceptedFileTypes`),
      });

      return Upload.LIST_IGNORE;
    }

    //max file size = 5 MB = 5 * 10^6
    if (file.size > 5000000) {
      notification.error({
        message: t(`${companyDataTextPath}.fields.rules.maximumFileSize`),
      });

      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const customRequest: UploadProps['customRequest'] = options => {
    const { file, onSuccess, onError } = options;

    let input;

    if (companyId) {
      input = { company_id: companyId };
    } else {
      input = { user_id: userId };
    }

    uploadLogoImageRequest({ file, input })
      .then(({ url }) => {
        setPreviewUrl(url);
        onSuccess && onSuccess({ previewUrl: url });
      })
      .catch(({ message }) => {
        onError && onError({ name: '', message: t(message) });
      });
  };

  const handlePreview = () => {
    previewUrl && window.open(previewUrl, '_blank');
  };

  const handleRemove = () => {
    if (previewUrl) {
      deleteLogoImageRequest({ input: { url: previewUrl } });
      setPreviewUrl(undefined);
      setFileList(undefined);
    }
  };

  return (
    <Form.Item label={t(`${companyDataTextPath}.fields.labels.logoTitle`)}>
      <Dragger
        accept=".png, .jpg, .jpeg"
        beforeUpload={beforeUpload}
        fileList={fileList}
        listType="picture"
        maxCount={1}
        name="logo"
        style={{ display: 'block' }}
        customRequest={customRequest}
        progress={{
          style: { display: 'none' },
        }}
        onPreview={handlePreview}
        onRemove={handleRemove}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          {t(`${companyDataTextPath}.fields.labels.logo`)}
        </p>
        <p className="ant-upload-hint">
          {t(`${companyDataTextPath}.fields.labels.logoDescription`)}
        </p>
      </Dragger>
    </Form.Item>
  );
};

export default CompanyDataLogoUpload;
