import { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../../components/Access/Access';

import { Card, Col, Row, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import {
  CompaniesPageTable,
  ICompaniesPageTableItem,
} from '../../../../../components/Support/Account/Company/CompaniesPageTable/CompaniesPageTable';
import {
  CompaniesPageQueryVariables,
  CompanyStatusEnum,
  SortOrder,
  useCompaniesPageQuery,
} from '../../../../../graphql/generated/graphql';
import { useMenuItem } from '../../../../../hooks/useMenuItem/useMenuItem';
import { usePagination } from '../../../../../hooks/usePagination';
import { useTitle } from '../../../../../hooks/useTitle/useTitle';

import { SortOrderEnum } from '../../../../../utils';
import { CompaniesPageFilter } from '../Components/CompaniesPageFilter/CompaniesPageFilter';

export const CompanyValidationPage = (): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.company.companyValidationPage',
  });

  const [pagination, paginationRequestParams, setDataLength] = usePagination();

  const [variables, setVariables] = useState<CompaniesPageQueryVariables>({
    orderBy: { created_at: SortOrder.Desc },
    where: {
      companyStatus: {
        none: {
          status: {
            in: [CompanyStatusEnum.Approved, CompanyStatusEnum.Blocked],
          },
        },
        every: { status: { notIn: [CompanyStatusEnum.WaitingData] } },
      },
    },
  });

  const [result] = useCompaniesPageQuery({
    variables: {
      ...variables,
      pagination: paginationRequestParams,
    },
    pause: !paginationRequestParams,
  });

  const { data, error, fetching } = result;

  const handleTableChange: TableProps<ICompaniesPageTableItem>['onChange'] = (
    _,
    __,
    sorter
  ) => {
    const { order, field } = Array.isArray(sorter) ? sorter[0] : sorter;

    const orderByFields = [
      'document_number',
      'trade_name',
      'company_type',
      'company_function',
      'created_at',
      'updated_at',
    ];

    const orderByField = orderByFields.find(
      orderByField => orderByField === field
    );

    if (orderByField) {
      const [sortOrderEnum] = SortOrderEnum(order);

      if (order) {
        setVariables(variables => ({
          ...variables,
          orderBy: { [orderByField]: sortOrderEnum },
        }));
      } else {
        setVariables(variables => ({
          ...variables,
          orderBy: undefined,
        }));
      }
    }
  };

  useTitle({ title: 'tab.support.accounts.companies.companyValidation' });
  useMenuItem({
    selectedMenu: ['/support/accounts/companies/companyValidation'],
    openMenu: ['/support/accounts'],
  });

  useEffect(() => {
    const total = result.data?.Count.length;

    if (total !== undefined && pagination.total !== total) {
      setDataLength(total);
    }
  }, [pagination.total, result.data, setDataLength]);

  return (
    <>
      <DefaultPageHeader subTitle={t('subTitle')} title={t('title')} />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <Col span={24}>
          <CompaniesPageFilter setVariables={setVariables} />
        </Col>
        <ErrorAlert error={error} />
        <Col span={24}>
          <Card title={t('table.title')}>
            <CompaniesPageTable
              dataSource={data?.FindCompanies}
              loading={fetching}
              onChange={handleTableChange}
              pagination={{ ...pagination, hideOnSinglePage: true }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

const CompanyValidationPageAccess = Access(CompanyValidationPage, {
  acceptedPermissions: [
    {
      module: 'APPROVE_COMPANIES',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default CompanyValidationPageAccess;
