import { Dropdown, Menu, Space, Typography } from 'antd';
import { Key, ReactElement } from 'react';
import UserAvatarIcon from '../../../../assets/Icons/UserAvatarIcon/UserAvatarIcon';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import { Utils } from '../../../../utils';
import { useAvatarMenu } from './AvatarMenu.hooks';

export const DesktopAvatarMenu = (): ReactElement => {
  const { userData } = useUserContext();

  const {
    handlers: { onClick },
    items,
  } = useAvatarMenu();

  return (
    <Dropdown
      getPopupContainer={Utils.getPopupContainer}
      menu={{ onClick, items }}
      trigger={['click']}
    >
      <Space direction="horizontal" size="middle" style={{ cursor: 'pointer' }}>
        <UserAvatarIcon
          style={{ width: '100%', height: 'auto', verticalAlign: 'middle' }}
        />
        <Typography.Paragraph
          ellipsis
          style={{
            color: 'white',
            fontWeight: 'bold',
            margin: 0,
            maxWidth: '200px',
          }}
        >
          {userData?.name}
        </Typography.Paragraph>
      </Space>
    </Dropdown>
  );
};

const MobileAvatarMenu = (): ReactElement => {
  const {
    handlers: { onClick },
    items,
  } = useAvatarMenu();

  const menuKeys = ['logout', 'termsOfUse'] as Key[];

  const menuItems = items.filter(
    item => item?.key && menuKeys.includes(item.key)
  );

  return (
    <Menu
      defaultOpenKeys={[]}
      items={menuItems}
      onClick={onClick}
      selectable={false}
    />
  );
};

export const AvatarMenu = {
  Desktop: DesktopAvatarMenu,
  Mobile: MobileAvatarMenu,
};

export default AvatarMenu;
