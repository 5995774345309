import { InboxOutlined } from '@ant-design/icons';
import { Form, FormProps, Input, Upload, UploadFile } from 'antd';
import { ReactElement } from 'react';
import { getFileValueFromEvent } from '../../../../utils/getFileValueFromEvent/getFileValueFromEvent';
import { useUploadFileValidator } from '../../../AntdCustom/Upload/Upload.hooks';
import { useTranslation } from 'react-i18next';

export interface IAttachmentFormValues {
  file: [UploadFile];
  short_description?: string;
}

export type IAttachmentFormProps = Partial<FormProps<IAttachmentFormValues>>;

export const AttachmentForm = ({
  ...props
}: IAttachmentFormProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.attachments.attachmentForm',
  });

  const {
    validator,
    defaultValues: { accept },
  } = useUploadFileValidator();

  return (
    <Form layout="vertical" scrollToFirstError {...props}>
      <Form.Item
        getValueFromEvent={getFileValueFromEvent}
        label={t('labels.file')}
        name="file"
        required
        rules={[{ required: true }, { validator }]}
        validateFirst
        valuePropName="fileList"
      >
        <Upload.Dragger
          accept={accept}
          beforeUpload={() => false}
          maxCount={1}
          style={{ padding: 24 }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('upload.text')}</p>
          <p className="ant-upload-hint">{t('upload.hint')}</p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item name="short_description" label={t('labels.short_description')}>
        <Input.TextArea
          maxLength={100}
          placeholder={t('placeholders.short_description')}
          rows={2}
        />
      </Form.Item>
    </Form>
  );
};
