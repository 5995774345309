import queries from '../queries/Address';
import client from '../../api/urql/customClient';
import ZipCodeAddressModel from '../models/Address';
import { ICityName, IZipCodeAddress } from '../interfaces/Address';

class AddressController {
  accessToken: string;

  constructor({ accessToken }: { accessToken: string }) {
    this.accessToken = accessToken;
  }

  findZipCodeAddress = (zipCode: string): Promise<IZipCodeAddress> =>
    new Promise((resolve, reject) =>
      client
        .query(
          queries.findZipCodeAddress(),
          { zipCode },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ zipCodeAddress }) => {
          resolve(new ZipCodeAddressModel(zipCodeAddress));
        })
        .catch(err => {
          reject(err);
        })
    );

  findCitiesByState = (stateId: string): Promise<ICityName[]> =>
    new Promise((resolve, reject) =>
      client
        .query(
          queries.findCitiesByState(),
          { stateId },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ cities }) => {
          resolve(cities);
        })
        .catch(err => {
          reject(err);
        })
    );
}

export default AddressController;
