import { Typography } from 'antd';
import { CSSProperties, ReactElement } from 'react';
import { CompanyName } from '../../../../../components/Company/CompanyName/CompanyName';
import { QuotationGroupTableFragment } from '../../../../../graphql/generated/graphql';

interface IMembersColumnProps {
  members: QuotationGroupTableFragment['members'];
  style?: CSSProperties;
}

export const MembersColumn = ({
  members,
  style,
}: IMembersColumnProps): ReactElement => {
  if (!members) return <>-</>;

  return (
    <Typography.Paragraph
      ellipsis={{ expandable: true, rows: 1 }}
      style={{ margin: 0, maxWidth: 700, ...style }}
    >
      {members
        .map(({ company }) => CompanyName.getCompanyName(company))
        .join(' | ')}
    </Typography.Paragraph>
  );
};
