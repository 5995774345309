/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Form,
  FormRule,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CreateFreightContextUtils } from '../../../../contexts/Freight/CreateFreightContext/CreateFreightContext.utils';
import { useCreateFreightContext } from '../../../../contexts/Freight/CreateFreightContext/useCreateFreightContext';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import { Volume } from '../../../../core/Freight/Volume';
import { Price } from '../../../../core/Price';
import {
  QuotationGroupTableFragment,
  useCreateFreightMutation,
  useCreateMissingFreightMutation,
} from '../../../../graphql/generated/graphql';
import { useInterval } from '../../../../hooks/useInterval/useInterval';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { DefaultPageFooter } from '../../../AntdCustom/DefaultPageFooter/DefaultPageFooter';
import { DefaultTable } from '../../../AntdCustom/DefaultTable/DefaultTable';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { FormattedDate } from '../../../Formatters/FormattedDate/FormattedDate';
import { FormattedDateTime } from '../../../Formatters/FormattedDateTime/FormattedDateTime';
import { ViewRoute } from '../../Route/ViewRoute/ViewRoute';
import { ICreateFreightFormValues } from '../CreateFreightForm/CreateFreightForm';
import QuotationGroupsForm from '../QuotationGroupsForm/QuotationGroupsForm';
import { toRelatedGroups } from '../QuotationGroupsForm/QuotationGroupsForm.utils';
import { getValidDates } from './ConfirmCreateFreightUtils';
import { ConfirmFreightDates } from './ConfirmFreightDates/ConfirmFreightDates';
import { ConfirmFreightLoad } from './ConfirmFreightLoad/ConfirmFreightLoad';

interface IFreightDates {
  landing_date: dayjs.Dayjs;
  shipment_date: dayjs.Dayjs;
  limit: dayjs.Dayjs;
}

export const CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH =
  'components.freight.shipper.confirmCreateFreight';

export const ConfirmCreateFreight = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { freightId } = useParams();

  const [form] = Form.useForm<ICreateFreightFormValues>();
  const { selectedRowKeys, ...useRowSelectionProps } =
    DefaultTable.useRowSelection<QuotationGroupTableFragment>();

  const [isQuoteLimitDateModalOpen, setIsQuoteLimitDateModalOpen] =
    useState(false);

  const { userData } = useUserContext();
  const { createFreightValues, setCreateFreightValues } =
    useCreateFreightContext();

  const [freightDates, setFreightDates] = useState<IFreightDates>();

  const [createFreightMutationResult, executeCreateFreightMutation] =
    useCreateFreightMutation();

  const [
    createMissingFreightMutationResult,
    executeCreateMissingFreightMutation,
  ] = useCreateMissingFreightMutation();

  const handleCancel = () => {
    return navigate(-1);
  };

  const handleModalOpen = () => {
    setIsQuoteLimitDateModalOpen(true);
  };

  const handleModalClose = () => {
    setIsQuoteLimitDateModalOpen(false);
  };

  const handleSubmit = () => {
    if (userData?.company && createFreightValues && freightDates) {
      if (createFreightValues.isValid) {
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          isValid,
          note_value,
          packing_id,
          product_id,
          product_variety_id,
          product_type_id,
          suggested_value,
          total_volume,
          unit_type,
          ...restCreateFreightValues
        } = createFreightValues;

        const totalVolume =
          Volume.Utils.formatVolumeInputToBaseUnitType(
            total_volume,
            unit_type
          ) || 0;

        const createFreightInput = {
          ...restCreateFreightValues,
          packing_id: packing_id.value,
          product_id: product_id.value,
          variety_id: product_variety_id?.value,
          product_type_id: product_type_id.value,
          total_volume: totalVolume,
          unit_type: unit_type,
          note_value: Price.Utils.formatPriceInputToCents(note_value),
          suggested_value: Price.Utils.formatPriceInputToCents(suggested_value),
          landing_date: freightDates.landing_date.toISOString(),
          shipment_date: freightDates.shipment_date.toISOString(),
          limit: freightDates.limit.toISOString(),
          related_groups: toRelatedGroups(selectedRowKeys),
          creator_id: userData.id,
        };

        const handleMutationResult = ({ data }: { data?: unknown }) => {
          handleModalClose();

          if (data) {
            setCreateFreightValues(undefined);
            return navigate('/shipper/freights', { replace: true });
          }
        };

        if (!freightId) {
          executeCreateFreightMutation({
            input: {
              ...createFreightInput,
              owner_id: userData.company.id,
            },
          }).then(handleMutationResult);
        } else {
          executeCreateMissingFreightMutation({
            input: {
              ...createFreightInput,
              parent_id: freightId,
            },
          }).then(handleMutationResult);
        }
      }
    }
  };

  if (!createFreightValues?.isValid) {
    return <Navigate to={'/shipper/freights/create'} replace />;
  }

  return (
    <>
      <FixedFooterContainer.Inner gutter={[24, 24]}>
        <ErrorAlert error={createFreightMutationResult.error} />
        <ErrorAlert error={createMissingFreightMutationResult.error} />

        <Col span={24}>
          <ViewRoute
            routeId={createFreightValues.route_id}
            mapStyle={{ maxHeight: 350 }}
            cardStyle={{ minHeight: '100%' }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <QuotationGroupsForm
                form={form}
                useRowSelectionProps={{
                  selectedRowKeys,
                  ...useRowSelectionProps,
                }}
              />
            </Col>
            <Col span={24}>
              <ConfirmFreightDates
                freightDates={CreateFreightContextUtils.toConfirmFreightDates(
                  createFreightValues
                )}
              />
            </Col>
            <Col span={24}>
              <ConfirmFreightLoad
                freightLoad={CreateFreightContextUtils.toConfirmFreightLoad(
                  createFreightValues
                )}
              />
            </Col>
          </Row>
        </Col>
      </FixedFooterContainer.Inner>
      <DefaultPageFooter
        extra={
          <Space size={'large'}>
            <Button
              disabled={
                createFreightMutationResult.fetching ||
                createMissingFreightMutationResult.fetching
              }
              size="small"
              onClick={handleCancel}
            >
              {t(`${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.actions.cancel`)}
            </Button>
            <Button
              size="small"
              type="primary"
              htmlType="submit"
              onClick={handleModalOpen}
              loading={
                createFreightMutationResult.fetching ||
                createMissingFreightMutationResult.fetching
              }
            >
              {t(`${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.actions.submit`)}
            </Button>
          </Space>
        }
      />
      <Modal
        centered
        width={600}
        onCancel={handleModalClose}
        cancelText={t('general.close')}
        okText={t(`${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.actions.submit`)}
        onOk={handleSubmit}
        open={isQuoteLimitDateModalOpen}
        okButtonProps={{
          loading:
            createFreightMutationResult.fetching ||
            createMissingFreightMutationResult.fetching,
        }}
      >
        <QuoteLimitDateForm setFreightDates={setFreightDates} />
      </Modal>
    </>
  );
};

interface IQuoteLimitDateFormValues {
  timeUnit: 'hours' | 'days';
  timeAmount: number;
}

interface IQuoteLimitDateFormProps {
  setFreightDates: (freightDates: IFreightDates) => void;
}

const QuoteLimitDateForm = (props: IQuoteLimitDateFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<IQuoteLimitDateFormValues>();

  const timeUnit = Form.useWatch('timeUnit', form);
  const timeAmount = Form.useWatch('timeAmount', form);

  const [now, setNow] = useState(dayjs().add(1, 'minute'));

  const { createFreightValues } = useCreateFreightContext();

  const [freightDates, setFreightDates] = useState<IFreightDates>({
    landing_date: dayjs(createFreightValues?.landing_date),
    shipment_date: dayjs(createFreightValues?.shipment_date),
    limit: now.clone().add(2, 'hours'),
  });

  const timeUnitOptions = [
    {
      label: t('enums.measureUnits.time.hours'),
      value: 'hours',
    },
    {
      label: t('enums.measureUnits.time.days'),
      value: 'days',
    },
  ];

  const timeAmountRules: Record<'hours' | 'days', FormRule[]> = {
    days: [
      { required: true },
      {
        type: 'number',
        min: 1,
        message: t(
          `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.validations.timeAmount.days.min`
        ),
      },
      {
        type: 'number',
        max: 7,
        message: t(
          `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.validations.timeAmount.days.max`
        ),
      },
    ],
    hours: [
      { required: true },
      {
        type: 'number',
        min: 2,
        message: t(
          `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.validations.timeAmount.hours.min`
        ),
      },
      {
        type: 'number',
        max: 24 * 7,
        message: t(
          `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.validations.timeAmount.hours.max`
        ),
      },
    ],
  };

  useInterval(() => {
    setNow(dayjs().add(1, 'minute'));
  }, (60 - now.second()) * 1000);

  useEffect(() => {
    form.setFieldsValue({
      timeUnit: 'hours',
      timeAmount: 2,
    });
  }, []);

  useEffect(() => {
    const { quoteLimitDate, shipmentDate, landingDate } = getValidDates(
      {
        timeAmount: timeAmount || 2,
        timeUnit: timeUnit || 'hours',
        shipmentDate: dayjs(createFreightValues?.shipment_date),
        landingDate: dayjs(createFreightValues?.landing_date),
      },
      now
    );

    setFreightDates({
      landing_date: landingDate,
      shipment_date: shipmentDate,
      limit: quoteLimitDate,
    });
  }, [timeAmount, timeUnit, createFreightValues, now]);

  useEffect(() => {
    props.setFreightDates(freightDates);
  }, [freightDates]);

  const timeUnitField = (
    <Form.Item name={'timeUnit'} noStyle>
      <Select options={timeUnitOptions} />
    </Form.Item>
  );

  const quoteStartDate = now;

  const shipmentDateHasChanged = !freightDates.shipment_date.isSame(
    createFreightValues?.shipment_date,
    'days'
  );

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label={t(
          `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.labels.timeAmount`
        )}
        tooltip={t(
          `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.tooltips.quote_limit_date`
        )}
        dependencies={['timeUnit']}
        name={'timeAmount'}
        rules={timeAmountRules[timeUnit]}
        validateFirst
      >
        <InputNumber type="number" addonAfter={timeUnitField} precision={0} />
      </Form.Item>
      <Descriptions column={1}>
        <Descriptions.Item
          label={t(
            `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.labels.quoteInterval`
          )}
        >
          <FormattedDateTime dateTime={quoteStartDate} /> -{' '}
          <FormattedDateTime dateTime={freightDates.limit} />
        </Descriptions.Item>
      </Descriptions>
      {shipmentDateHasChanged && (
        <>
          <Alert
            type="warning"
            style={{ marginBottom: 12 }}
            description={t(
              `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.validations.newFreightDates`
            )}
          />
          <Descriptions column={2}>
            <Descriptions.Item
              label={t(
                `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.labels.shipmentDate`
              )}
            >
              <FormattedDate dateTime={freightDates.shipment_date} />
            </Descriptions.Item>
            <Descriptions.Item
              label={t(
                `${CONFIRM_CREATE_FREIGHT_TRANSLATION_PATH}.quoteLimitDateForm.fields.labels.landingDate`
              )}
            >
              <FormattedDate dateTime={freightDates.landing_date} />
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Form>
  );
};
