import { Layout } from 'antd';
import { ReactElement, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { Sidebar } from './components/Sidebar/Sidebar';
import LayoutContext from '../../contexts/LayoutContext/LayoutContext';

const { Content } = Layout;

export const MainLayout = (): ReactElement => {
  const { useMobileLayout } = useContext(LayoutContext);

  return useMobileLayout ? <MobileLayout /> : <DesktopLayout />;
};

const DesktopLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar.Desktop />
      <Layout style={{ marginLeft: 200 }}>
        <Header.Desktop />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

const MobileLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar.Mobile />
      <Layout>
        <Header.Mobile />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

MainLayout.Desktop = DesktopLayout;
MainLayout.Mobile = MobileLayout;
