import { Form, FormItemProps, Select as SelectAnt, SelectProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Normalizer from '../../classes/Normalizer';
import { requiredField } from '../../utils/inputRules';
import { IDefaultInputInterface, IFieldRules } from '../../utils/interfaces';

/**
 * @description Text input for forms that use AntDesign.
 * @property {string} name Name of the key that will be returned in the object.
 * @property {string} [extra=''] Extra props for Form.Item.
 * @property {string} [label=null] Label over the input.
 * @property {object} [field={ name: "name", fieldKey: "key" }] Necessary if use dynamics Form.
 * @property {object} [options=SelectProps['options']] Select options
 * @property {Node} tooltip Config tooltip info.
 * @property {object} formItem This component is used inside a <Form /> ?.
 * @property {object} formStyle <Form.Item /> style object.
 * @property {bool} [validation=false] If the form will be validated.
 * @property {string} initialValue field initialValue.
 * @property {array} [myOwnRules=[]] Specific rules in the input.
 * @property {array} [otherRules=[]] More rules to add to the rules.
 * @property {object} otherPropsFormItem <Form.Item /> otherProps.
 *
 * @example
 * <Select
 *      formItem
 *      validation
 *      form={form}
 *      name="example"
 *      label="Example"
 *      placeholder="Example"
 * />
 */

const { Option } = SelectAnt;

interface ISelect {
  otherPropsFormItem?: FormItemProps;
  formStyle?: Record<string, never>;
  options: SelectProps['options'];
  disabled?: boolean;
  required?: boolean;
  onChange?: SelectProps['onChange'];
}

const Select = (
  props: IDefaultInputInterface &
    ISelect &
    FormItemProps &
    SelectProps<SelectProps['onChange']>
): ReactElement => {
  const { t } = useTranslation();

  const {
    name,
    extra,
    label,
    field,
    options,
    tooltip,
    namePath,
    formItem,
    onChange,
    formStyle,
    validation,
    placeholder,
    initialValue,
    otherRules = [],
    myOwnRules = [],
    required = true,
    otherPropsFormItem,
    ...otherProps
  } = props;

  let rules: IFieldRules = [];

  if (validation) {
    if (required) {
      rules.push(requiredField);
    }

    if (otherRules.length > 0) {
      rules.push(...otherRules);
    }

    if (myOwnRules.length > 0) {
      rules = myOwnRules;
    }

    rules = rules.map(r => {
      return {
        ...r,
        message: r.message && t(r.message),
      };
    });
  }

  const getSelectOptions = (
    mountedOption: JSX.Element[],
    option: NonNullable<SelectProps['options']>[number]
  ) => {
    const { value, label, disabled = false, ...otherPropsOption } = option;

    return [
      ...mountedOption,
      <Option
        {...otherPropsOption}
        value={value}
        disabled={disabled}
        key={`${value}_${Math.random()}`}
      >
        {label}
      </Option>,
    ];
  };

  const getOptions = (values: SelectProps['options']) =>
    values?.reduce(getSelectOptions, []);

  let formName: string | [string, string] | string[] = name;
  if (namePath != null) formName = namePath;
  let fieldKey = undefined;
  if (field) {
    formName = [field.name, name];
    fieldKey = [field.fieldKey, name];
  }

  const defaultFilterOption = (
    input: string,
    option?: NonNullable<SelectProps['options']>[number]
  ) => {
    if (option?.props?.value) {
      return Normalizer.removeAccent(option.props.children, true).includes(
        Normalizer.removeAccent(input, true)
      );
    }

    return false;
  };

  if (formItem) {
    return (
      <Form.Item
        name={formName}
        label={label}
        extra={extra}
        rules={rules}
        validateFirst
        tooltip={tooltip}
        fieldKey={fieldKey}
        style={{ ...formStyle }}
        initialValue={initialValue}
        {...otherPropsFormItem}
      >
        <SelectAnt
          onChange={onChange}
          optionFilterProp="children"
          filterOption={defaultFilterOption}
          placeholder={placeholder}
          {...otherProps}
        >
          {getOptions(options)}
        </SelectAnt>
      </Form.Item>
    );
  }

  return (
    <SelectAnt
      optionFilterProp="children"
      filterOption={defaultFilterOption}
      placeholder={placeholder}
      {...otherProps}
    >
      {getOptions(options)}
    </SelectAnt>
  );
};

export default Select;
