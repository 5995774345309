import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface IUseTitleProps {
  title: string;
}

export const useTitle = ({ title }: IUseTitleProps): string => {
  const { t } = useTranslation();

  const defaultTitle = 'Trucker do AgrO';
  const locatedTitle = t(title);

  const documentTitle = `${locatedTitle} • ${defaultTitle}`;

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return documentTitle;
};
