import companyQueries from '../queries/Company';

import client from '../../api/urql/customClient';

import { UuidFilter } from '../../graphql/generated/graphql';
import {
  ICarrierCompany,
  ICompany,
  IDeleteCarrierPolicy,
  IDeleteCarrierPolicyResponse,
  IDeleteCompanyLogo,
  IDeleteCompanyLogoResponse,
  IFoundCompany,
  IUploadCarrierPolicy,
  IUploadCarrierPolicyResponse,
  IUploadCompanyLogo,
  IUploadCompanyLogoResponse,
} from '../interfaces/Company/Company';
import {
  IComplementCompanyForm,
  ICreateCompanyForm,
  IRectifyCompanyForm,
} from '../interfaces/Company/CompanyDto';
import { CompanyTypeEnum } from '../interfaces/Company/CompanyType';
import { IReproachReason } from '../interfaces/Company/Reproach';
import { ReproofReasonTypeReverseEnum } from '../interfaces/Company/Reproof/ReproofReasonType';
import {
  CarrierCompanyModel,
  CompanyFragmentModel,
  CompanyModel,
} from '../models/Company';
import {
  ComplementCompanyModel,
  CreateCompanyModel,
  RectifyCompanyModel,
} from '../models/Company/CreateUpdateCompany';

export class CompanyController {
  accessToken: string;

  constructor({ accessToken }: { accessToken: string }) {
    this.accessToken = accessToken;
  }

  findCompanyByDocument = ({
    documentNumber,
    companyId,
  }: {
    documentNumber: string;
    companyId?: string;
  }): Promise<IFoundCompany> => {
    const idFilter: UuidFilter | undefined = companyId
      ? { not: { equals: companyId } }
      : undefined;

    return new Promise((resolve, reject) =>
      client
        .query(
          companyQueries.findCompanyByDocument(),
          {
            documentNumber,
            idFilter,
          },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ foundCompanies }) => {
          let foundCompany = foundCompanies.find(
            ({ companyType }) => companyType !== CompanyTypeEnum.Unit
          );

          if (!foundCompany) {
            foundCompany = foundCompanies.find(
              ({ companyType }) => companyType === CompanyTypeEnum.Unit
            );
          }

          if (foundCompany) {
            resolve(new CompanyModel(foundCompany));
          } else {
            reject();
          }
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  findCompanyById = (companyId: string): Promise<ICarrierCompany> =>
    new Promise((resolve, reject) =>
      client
        .query(
          companyQueries.findCompanyById(),
          {
            companyId,
          },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ foundCompany }) => {
          if (foundCompany) {
            resolve(new CarrierCompanyModel(foundCompany));
          } else {
            reject();
          }
        })
        .catch(err => {
          reject(err);
        })
    );

  createCompany = (
    createCompanyFormValues: ICreateCompanyForm
  ): Promise<ICompany> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.registerCompany(),
          {
            createCompanyDto: new CreateCompanyModel(createCompanyFormValues),
          },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(
          ({
            createdCompany: { motherCompany, referenceCompany, ...company },
          }) => {
            resolve({
              ...new CompanyFragmentModel(company),
              motherCompany: motherCompany
                ? new CompanyFragmentModel(motherCompany)
                : undefined,
              referenceCompany: referenceCompany
                ? new CompanyFragmentModel(referenceCompany)
                : undefined,
            });
          }
        )
        .catch(err => {
          reject(err);
        })
    );

  rectifyCompany = (
    updateCompanyFormValues: IRectifyCompanyForm
  ): Promise<ICompany> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.rectifyCompany(),
          {
            rectifyCompanyDto: new RectifyCompanyModel(
              updateCompanyFormValues
            ).getRectifyMutationVariables(),
          },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(
          ({
            rectifyCompany: { motherCompany, referenceCompany, ...company },
          }) => {
            resolve({
              ...new CompanyFragmentModel(company),
              motherCompany: motherCompany
                ? new CompanyFragmentModel(motherCompany)
                : undefined,
              referenceCompany: referenceCompany
                ? new CompanyFragmentModel(referenceCompany)
                : undefined,
            });
          }
        )
        .catch(err => {
          reject(err);
        })
    );

  complementCompany = (
    complementCompanyFormValues: IComplementCompanyForm
  ): Promise<ICompany> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.complementCompany(),
          {
            complementCompanyDto: new ComplementCompanyModel(
              complementCompanyFormValues
            ),
          },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(
          ({
            complementedCompany: {
              motherCompany,
              referenceCompany,
              ...company
            },
          }) => {
            resolve({
              ...new CompanyFragmentModel(company),
              motherCompany: motherCompany
                ? new CompanyFragmentModel(motherCompany)
                : undefined,
              referenceCompany: referenceCompany
                ? new CompanyFragmentModel(referenceCompany)
                : undefined,
            });
          }
        )
        .catch(err => {
          reject(err);
        })
    );

  uploadCompanyLogo = (
    uploadLogoImage: IUploadCompanyLogo
  ): Promise<IUploadCompanyLogoResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.uploadCompanyLogo(),
          { ...uploadLogoImage },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ uploadedFile }) => resolve(uploadedFile))
        .catch(err => reject(err))
    );

  deleteCompanyLogo = (
    deleteLogoImage: IDeleteCompanyLogo
  ): Promise<IDeleteCompanyLogoResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.deleteCompanyLogo(),
          { ...deleteLogoImage },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ deletedLogo }) => resolve(deletedLogo))
        .catch(err => reject(err))
    );

  uploadCarrierPolicy = (
    uploadPolicy: IUploadCarrierPolicy
  ): Promise<IUploadCarrierPolicyResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.uploadCarrierPolicy(),
          { ...uploadPolicy },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ uploadedPolicy }) => resolve(uploadedPolicy))
        .catch(err => reject(err))
    );

  deleteCarrierPolicy = (
    uploadPolicy: IDeleteCarrierPolicy
  ): Promise<IDeleteCarrierPolicyResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(
          companyQueries.deleteCarrierPolicy(),
          { ...uploadPolicy },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ deletedPolicy }) => resolve(deletedPolicy))
        .catch(err => reject(err))
    );

  findReproachReason = ({
    companyId,
  }: {
    companyId: string;
  }): Promise<IReproachReason> =>
    new Promise((resolve, reject) =>
      client
        .query(
          companyQueries.findReproachReason(),
          { companyId },
          {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
              },
            },
          }
        )
        .then(({ reproachReason: { reasons, reproach } }) =>
          resolve({
            reproach,
            reasons: reasons.map(({ reasonType, ...reason }) => ({
              ...reason,
              reasonType: ReproofReasonTypeReverseEnum[reasonType],
            })),
          })
        )
        .catch(err => reject(err))
    );
}

export default CompanyController;
