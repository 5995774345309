import { SelectProps } from 'antd';

const REPROOF_REASON_TYPE = [
  'companyData',
  'companyAddress',
  'companyContact',
  'companyPolicies',
  'companyOperationArea',
  'other',
] as const;

export type IReproofReasonType = (typeof REPROOF_REASON_TYPE)[number];

export enum ReproofReasonTypeEnum {
  companyData = 'COMPANY_DATA',
  companyAddress = 'COMPANY_ADDRESS',
  companyContact = 'COMPANY_CONTACT',
  companyPolicies = 'COMPANY_POLICIES',
  companyOperationArea = 'COMPANY_OPERATION_AREA',
  other = 'OTHER',
}

export enum ReproofReasonTypeReverseEnum {
  COMPANY_DATA = 'companyData',
  COMPANY_ADDRESS = 'companyAddress',
  COMPANY_CONTACT = 'companyContact',
  COMPANY_POLICIES = 'companyPolicies',
  COMPANY_OPERATION_AREA = 'companyOperationArea',
  OTHER = 'other',
}

export const ReproofReasonTypeOptions: NonNullable<SelectProps['options']> =
  REPROOF_REASON_TYPE.map(reproofReasonType => ({
    label: `enums.company.reproof.reproofReasonType.${reproofReasonType}`,
    value: reproofReasonType,
  }));
