import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyTypeEnum } from '../../../graphql/generated/graphql';

interface ICompanyTypeProps {
  companyType?: CompanyTypeEnum;
}

export const CompanyType = ({
  companyType,
}: ICompanyTypeProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'enums.companyType',
  });

  if (!companyType) return <></>;

  return <>{t(companyType.toLowerCase())}</>;
};
