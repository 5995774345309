/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Space,
  TableProps,
  Typography,
  notification,
} from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import {
  IUsersPageTableItem,
  UsersPageTable,
} from '../../../../../components/Support/Account/User/UsersPageTable/UsersPageTable';
import {
  SortOrder,
  UsersPageQueryVariables,
  useUsersPageQuery,
  useUsersPage_CreateTruckerUserMutation,
  useUsersPage_CreateUserInternallyMutation,
  useUsersPage_FindCompaniesQuery,
} from '../../../../../graphql/generated/graphql';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { useMenuItem } from '../../../../../hooks/useMenuItem/useMenuItem';
import { usePagination } from '../../../../../hooks/usePagination';
import { useTitle } from '../../../../../hooks/useTitle/useTitle';

import { SortOrderEnum } from '../../../../../utils';
import {
  CreateTruckerUserForm,
  ICreateTruckerUserFormValues,
} from './Components/CreateTruckerUserForm/CreateTruckerUserForm';
import {
  CreateUserInternallyForm,
  ICreateUserInternallyFormValues,
} from './Components/CreateUserInternallyForm/CreateUserInternallyForm';
import { UsersPageFilter } from './Components/UsersPageFilter/UsersPageFilter';

export const UsersPage = (): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.user.usersPage',
  });

  const { t: t0 } = useTranslation();

  const [createUserInternallyForm] =
    Form.useForm<ICreateUserInternallyFormValues>();

  const [createTruckerUserForm] = Form.useForm<ICreateTruckerUserFormValues>();

  const [pagination, paginationRequestParams, setDataLength] = usePagination();

  const [
    isCreateUserInternallyFormModalOpen,
    setIsCreateUserInternallyFormModalOpen,
  ] = useState(false);

  const [
    isCreateTruckerUserFormModalOpen,
    setIsCreateTruckerUserFormModalOpen,
  ] = useState(false);

  const [variables, setVariables] = useState<UsersPageQueryVariables>({
    orderBy: { created_at: SortOrder.Desc },
  } as UsersPageQueryVariables);

  const [companySearch, setCompanySearch] = useState('');

  const companySearchDebounced = useDebounce(companySearch, 300);

  const [findCompaniesQueryResult] = useUsersPage_FindCompaniesQuery({
    variables: {
      search: companySearchDebounced,
    },
    pause: !isCreateUserInternallyFormModalOpen,
  });

  const [
    createUserInternallyMutationResult,
    executeCreateUserInternallyMutation,
  ] = useUsersPage_CreateUserInternallyMutation();

  const [createTruckerUserMutationResult, executeCreateTruckerUserMutation] =
    useUsersPage_CreateTruckerUserMutation();

  const [result, executeUsersPageQuery] = useUsersPageQuery({
    variables: {
      ...variables,
      pagination: paginationRequestParams,
    },
    pause: !paginationRequestParams,
  });

  const { fetching, data, error } = result;

  const handleTableChange: TableProps<IUsersPageTableItem>['onChange'] = (
    _,
    __,
    sorter
  ) => {
    const { order, field } = Array.isArray(sorter) ? sorter[0] : sorter;

    const orderByFields = ['email', 'name', 'created_at', 'updated_at'];

    const orderByField = orderByFields.find(
      orderByField => orderByField === field
    );

    if (orderByField) {
      const [sortOrderEnum] = SortOrderEnum(order);

      if (order) {
        setVariables(variables => ({
          ...variables,
          orderBy: { [orderByField]: sortOrderEnum },
        }));
      } else {
        setVariables(variables => ({
          ...variables,
          orderBy: undefined,
        }));
      }
    }
  };

  const handleOpenCreateUserInternallyFormModal = () => {
    setIsCreateUserInternallyFormModalOpen(true);
  };

  const handleCloseCreateUserInternallyFormModal = () => {
    setIsCreateUserInternallyFormModalOpen(false);
  };

  const handleCreateUserInternallyFormFinish = (
    values: ICreateUserInternallyFormValues
  ) => {
    executeCreateUserInternallyMutation({
      input: CreateUserInternallyForm.toCreateUserInternallyInput(values),
    }).then(({ data, error }) => {
      if (!error && data) {
        notification.success({
          duration: 10,
          key: data.CreateUserInternally.id,
          description: t(
            'createUserInternallyModal.notifications.userCreated.description'
          ),
          message: t(
            'createUserInternallyModal.notifications.userCreated.message'
          ),
        });
        executeUsersPageQuery();
        handleCloseCreateUserInternallyFormModal();
        createUserInternallyForm.resetFields();
      }
    });
  };

  const handleOpenCreateTruckerUserFormModal = () => {
    setIsCreateTruckerUserFormModalOpen(true);
  };

  const handleCloseCreateTruckerUserFormModal = () => {
    setIsCreateTruckerUserFormModalOpen(false);
  };

  const Copy = ({
    children,
    copyableContent,
  }: {
    children?: JSX.Element;
    copyableContent: { email: string; password: string };
  }) => {
    const { t } = useTranslation('translations', {
      keyPrefix:
        'pages.support.account.user.usersPage.createTruckerUserModal.notifications.userCreated.clipboard',
    });

    return (
      <Typography.Link
        style={{ marginLeft: -4 }}
        copyable={{
          text: t('text', copyableContent),
          icon: [
            <React.Fragment key={'copy'}>{children}</React.Fragment>,
            <React.Fragment key={'copied'}>{children}</React.Fragment>,
          ],
          tooltips: [t('tooltips.copy'), t('tooltips.copied')],
        }}
      />
    );
  };

  const handleCreateTruckerUserFormFinish = (
    values: ICreateTruckerUserFormValues
  ) => {
    executeCreateTruckerUserMutation({
      input: CreateTruckerUserForm.toCreateTruckerUserInput(values),
    }).then(({ data, error }) => {
      if (!error && data) {
        notification.success({
          duration: 0,
          key: data.CreateTruckerUser.id,
          description: (
            <Trans
              i18nKey={
                'createTruckerUserModal.notifications.userCreated.description'
              }
              t={t}
              components={[
                <Copy
                  key={data.CreateTruckerUser.id}
                  copyableContent={{
                    email: values.email,
                    password: values.password,
                  }}
                />,
              ]}
            />
          ),
          message: t(
            'createTruckerUserModal.notifications.userCreated.message'
          ),
        });
        executeUsersPageQuery();
        handleCloseCreateTruckerUserFormModal();
        createTruckerUserForm.resetFields();
      }
    });
  };

  const CreateUserInternallyButton = () => (
    <Button onClick={handleOpenCreateUserInternallyFormModal} type="primary">
      {t('actions.createUser')}
    </Button>
  );

  const CreateUserButtonAccess = Access(CreateUserInternallyButton, {
    acceptedPermissions: [{ module: 'USER_INTERNALLY', actions: ['CREATE'] }],
  });

  const CreateTruckerUserButton = () => (
    <Button onClick={handleOpenCreateTruckerUserFormModal}>
      {t('actions.createTruckerUser')}
    </Button>
  );

  const CreateTruckerUserButtonAccess = Access(CreateTruckerUserButton, {
    acceptedPermissions: [{ module: 'TRUCKER_USERS', actions: ['CREATE'] }],
  });

  const usersTableTitle = (
    <Row justify="space-between">
      <Col></Col>
      <Col>
        <Space>
          <CreateTruckerUserButtonAccess />
          <CreateUserButtonAccess />
        </Space>
      </Col>
    </Row>
  );

  useTitle({ title: 'tab.support.accounts.users.index' });

  useMenuItem({
    selectedMenu: ['/support/accounts/users'],
    openMenu: ['/support/accounts'],
  });

  useEffect(() => {
    const total = result.data?.Count.length;

    if (total !== undefined && pagination.total !== total) {
      setDataLength(total);
    }
  }, [result.data]);

  return (
    <>
      <DefaultPageHeader subTitle={t('subTitle')} title={t('title')} />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={error} />
        <Col span={24}>
          <UsersPageFilter setVariables={setVariables} />
        </Col>
        <Col span={24}>
          <Card title={usersTableTitle}>
            <UsersPageTable
              dataSource={data?.FindManyUsers}
              loading={fetching}
              onChange={handleTableChange}
              pagination={{ ...pagination, hideOnSinglePage: true }}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        centered
        okButtonProps={{ loading: createUserInternallyMutationResult.fetching }}
        okText={t0('general.save')}
        onCancel={handleCloseCreateUserInternallyFormModal}
        onOk={() => createUserInternallyForm.submit()}
        open={isCreateUserInternallyFormModalOpen}
        title={t('createUserInternallyModal.title')}
      >
        <ErrorAlert error={createUserInternallyMutationResult.error} />
        <CreateUserInternallyForm
          companySelectProps={{
            loading: findCompaniesQueryResult.fetching,
            onSearch: setCompanySearch,
            options: findCompaniesQueryResult.data?.FindCompanies,
          }}
          form={createUserInternallyForm}
          name="CreateUserInternallyForm"
          onFinish={handleCreateUserInternallyFormFinish}
        />
      </Modal>
      <Modal
        centered
        okButtonProps={{ loading: createTruckerUserMutationResult.fetching }}
        okText={t0('general.save')}
        onCancel={handleCloseCreateTruckerUserFormModal}
        onOk={() => createTruckerUserForm.submit()}
        open={isCreateTruckerUserFormModalOpen}
        title={t('createTruckerUserModal.title')}
      >
        <ErrorAlert error={createTruckerUserMutationResult.error} />
        <CreateTruckerUserForm
          form={createTruckerUserForm}
          name="CreateTruckerUserForm"
          onFinish={handleCreateTruckerUserFormFinish}
        />
      </Modal>
    </>
  );
};

const UsersPageAccess = Access(UsersPage, {
  acceptedPermissions: [{ module: 'TRUCKER_USERS', actions: ['CREATE'] }],
  fallback: <Navigate to="/home" replace />,
});

export default UsersPageAccess;
