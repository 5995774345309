import VMasker from 'vanilla-masker';
import { UnitMeasureEnum } from '../graphql/generated/graphql';
import { roundWithDecimals } from '../utils';
import { ICoordinates, ICoordinatesApi } from './FormatterInterfaces';
import Normalizer from './Normalizer';

/**
 * @description Formats the values passed to the given format.
 */

class Formatter {
  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CNPJ format
   * 00.000.000/0000-00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatCNPJ = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99.999.999/9999-99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CPF format
   * 000.000.000-00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatCPF = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '999.999.999-99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CPF (000.000.000-00)
   * or CNPJ (00.000.000/0000-00) format. If formatting is not possible,
   * the value passed as a parameter without changes is returned.
   */
  formatCPF_CNPJ = (text?: string | null) => {
    if (!text) return '';

    try {
      const cleanValue = `${text}`.replace(/[^0-9]/g, '');
      return cleanValue.length <= 11
        ? this.formatCPF(cleanValue)
        : this.formatCNPJ(cleanValue);
    } catch {
      return '';
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the RG format
   * 00.000.000-0. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatRG = (text: string) => {
    try {
      let cleanValue = `${text}`.replaceAll(/[.]|[-]/g, '');
      if (cleanValue.match(/(^[[0-9]{8,9}X{0,1}$)/i)) {
        return VMasker.toPattern(cleanValue, '99.999.999-S').toUpperCase();
      }
      cleanValue = `${text}`.replace(/[^0-9]/g, '');
      return cleanValue;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the CEP format
   * 00000-00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatZipCode = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99999-999');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the expires at date format
   * 00/00. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatExpiresAt = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99/99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the credit card number format
   * 9999 9999 9999 9999, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatCreditCard = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');
        if (value.length > 16) {
          return VMasker.toPattern(value, '9999 9999 9999 9999999');
        }
        return VMasker.toPattern(value, '9999 9999 9999 9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the money format
   * 9999 9999 9999 9999, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatMoney = (text = '') => {
    try {
      if (!text) return '';

      const value = text.toString().replace(/[^0-9]/g, '');
      return VMasker.toMoney(value);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the money format
   * R$ 999.999.999,99. If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatMoneyWithPrefix = (text = '', prefix = 'R$ ') => {
    try {
      if (!text) return '';

      const value = text.toString().replace(/[^0-9]/g, '');
      return prefix + VMasker.toMoney(value);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats according to the number of characters
   * if it is 11 it returns in CPF format 000.000.000-00,
   * case 13 in CNPJ format 00.000.000/0000-00,
   * and if 10 is returned in RG format: 00.000.000-0.
   */
  formatDocument = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      if (value.length > 11) {
        return this.formatCNPJ(value);
      }
      return this.formatCPF(value);
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * +55 (44) 3698-5555, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatLandline = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');

        return VMasker.toPattern(value, '(99) 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * +55 (44) 9 9746-1823, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatCellphone = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');

        return VMasker.toPattern(value, '(99) 9 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * (44) 9 9746-1823, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatPhoneWithoutDDI = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9]/g, '');
        if (value.length === 11) {
          return VMasker.toPattern(value, '(99) 9 9999-9999');
        }
        if (value.length === 13) {
          return VMasker.toPattern(value, '+99(99) 9 9999-9999');
        }
        return VMasker.toPattern(value, '(99) 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Formats the value passed to the phone format
   * +55 (44) 9 9746-1823, If formatting is not possible, it is
   * returned the value passed as a parameter without changes.
   */
  formatPhoneWithDDI = (text = '') => {
    try {
      if (text) {
        const value = `${text}`.replace(/[^0-9 | *]/g, '');
        if (value.includes('*')) {
          return VMasker.toPattern(value, '+99 (99) 9999-****');
        }
        if (value.length === 13) {
          return VMasker.toPattern(value, '+99 (99) 9 9999-9999');
        }
        return VMasker.toPattern(value, '+99 (99) 9999-9999');
      }
      return text;
    } catch (error) {
      return text;
    }
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the date format
   * 99/99/9999. If formatting is not possible, the value
   * passed as a parameter without changes is returned.
   */
  formatInputDate = (value: string) => {
    try {
      return VMasker.toPattern(value, '99/99/9999');
    } catch (error) {
      return value;
    }
  };

  /**
    //      * @function
    //      * @param {string} value Text aim to be formatted.
    //      * @returns {string}
    //      * @description Format the value passed to the date format
    //      * 99/99/9999. If formatting is not possible, the value
    //      * passed as a parameter without changes is returned.
    //  */
  // formatDate = (value: string) => {
  //     if (!value) return null;

  //     try {
  //         return dayjs(value).format('DD/MM/YYYY');
  //     } catch (error) {
  //         return value;
  //     }
  // };

  // formatUTCDate = (value: string) => {
  //     try {
  //         return dayjs.utc(value).format('DD/MM/YYYY');
  //     } catch (error) {
  //         return value;
  //     }
  // };

  // /**
  //      * @function
  //      * @param {string} text Text aim to be formatted.
  //      * @returns {string}
  //      * @description Format the value passed to the date format Ex: a few seconds ago
  //  */
  // formatDateFromNow = (date: string) => {
  //     try {
  //         return dayjs(date).locale('pt-br').fromNow();
  //     } catch (error) {
  //         return date;
  //     }
  // };

  // /**
  //      * @function formatTimeWithTimezone
  //      * @param {string} time time to be converted.
  //      * @param {string} timeZone time zone to be used.
  //      * @returns {string}
  //      * @description format a time (HH:MM:SS) on HH:mm with timezone
  //  */
  // formatTimeWithTimezone = (time: string, timeZone: string) => {
  //     try {
  //         return dayjs.utc(time, 'HH:mm').tz(timeZone).format('HH:mm');

  //     } catch (error) {
  //         return time;
  //     }
  // }

  /**
   * @function formatTimeWithoutTimezone
   * @param {string} text text to be converted.
   * @returns {string}
   * @description format a text on HH:mm
   */
  formatTimeWithoutTimezone = (text: string) => {
    try {
      const value = `${text}`.replace(/[^0-9]/g, '');
      return VMasker.toPattern(value, '99:99');
    } catch (error) {
      return text;
    }
  };

  /**
   * @function removeKeysWithoutValue
   * @param {object} object object to be formatted.
   * @returns {object}
   * @description Used to remove the keys of an object, that doesn't have value.
   * If it fail you will receive the object without change.
   */
  removeKeysWithoutValue = (object: Record<string, never>) => {
    try {
      const formattedObject: Record<string, never> = {};

      Object.keys(object).forEach(key => {
        if (object[key]) {
          formattedObject[key] = object[key];
        }
      });

      return formattedObject;
    } catch (error) {
      return object;
    }
  };

  /**
   * @function formatToPercent
   * @param {number} text - Text aim to be formatted.
   * @returns {number}
   * @description Return the formatted percentage with % at the end, example
   * comes from backend 0.20 and its returned 20.00%.
   */
  formatToPercent = (text: number) => {
    let numbers = parseInt(Normalizer.onlyNumbers(`${text}` || '0'));
    numbers = numbers > 10000 ? 10000 : numbers;
    const floatValue = parseFloat(`${numbers / 100}`).toFixed(2);
    return text ? floatValue : text;
  };

  /**
   * @function formatPercentToFloatNumber
   * @param {number} text - Text aim to be formatted.
   * @returns {number}
   * @description Return the formatted percentage with % at the end, example
   * comes from backend 20.00% and its returned 0.20.
   */
  formatPercentToFloatNumber = (text: string) => {
    const removePercent = text.replace('%', '');
    const numbers = parseFloat(Normalizer.onlyNumbers(removePercent || '0'));
    const floatValue = parseFloat(`${numbers / 10000}`).toFixed(6);
    return text ? floatValue : text;
  };

  /**
   * @function formatMoneyToFloat
   * @param {number} text - Text aim to be formatted.
   * @returns {number}
   * @description Return the formatted without the currency symbol, example
   * comes from backend R$ 7,89 and its returned 78900.
   */
  formatMoneyToFloat = (text: string, symbol = 'R$ ') => {
    const removeSymbol = text.replace(symbol, '');
    const numbers = parseFloat(Normalizer.onlyNumbers(removeSymbol || '0'));
    const floatValue = parseFloat(`${numbers * 100}`).toFixed(6);
    return text ? floatValue : text;
  };

  /**
   * @function removeAccents
   * @param {string} text - Text aim to be formatted.
   * @returns {string}
   * @description Return a formatted text without accents.
   */
  removeAccents = (text: string) => {
    try {
      return text.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '');
    } catch {
      return text;
    }
  };

  /**
   * @function formatLinkSlug
   * @param {string} text - Text aim to be formatted.
   * @returns {string}
   * @description Return a formatted slug text by be applied in a url link text
   */
  formatLinkSlug = (text: string) => {
    try {
      return text
        .normalize('NFD')
        .replaceAll(/[\u0300-\u036f]/g, '')
        .replaceAll(' ', '-')
        .toLocaleLowerCase();
    } catch {
      return text;
    }
  };

  /**
   * @function formatDimension
   * @param {string} text - Text aim to be formatted.
   * @returns {string}
   * @description Return a formatted dimension text, with 2 ca
   */
  formatDimension = (value: string) => {
    try {
      let text: string | string[] = value.replace(/[^0-9|,.]/g, '');
      text = text.split(/[.|,]/g);
      if (text.length >= 2) return `${text[0]}.${text[1].substring(0, 2)}`;
      return value;
    } catch {
      return value;
    }
  };

  /**
   * @function convertMinutesInHours
   * @param {string} text - Text aim to be formatted in minutes.
   * @returns {string}
   * @description Return a formatted hours with 'HH:MM' format
   */
  convertMinutesInHours = (text: number) => {
    if (!text) return '';
    const time = text / 60;
    let timeInt: string | number = parseInt(`${time}`);
    let timeDec: string | number = parseInt(
      `${Math.round((time - timeInt) * 60)}`
    );

    if (`${timeInt}`.length < 2) timeInt = `0${timeInt}`;
    if (`${timeDec}`.length < 2) timeDec = `0${timeDec}`;

    return `${timeInt}:${timeDec}`;
  };

  /**
   * @function convertDistance
   * @param {number} value - Value (in meters) to be converted.
   * @param {string} toUnit - Measure unit to perform the conversion.
   * @returns {number}
   * @description Return a converted value in the specified measure unit
   */
  convertDistance = (value?: number, toUnit: 'cm' | 'km' = 'km') => {
    if (!value) return NaN;

    const unitConversionFactor = {
      cm: 100,
      km: 1 / 1000,
    };

    return value * unitConversionFactor[toUnit];
  };

  /**
   * @function formatDistance
   * @param {number} value - Value (in meters) to be converted.
   * @param {string} toUnit - Measure unit to perform the conversion.
   * @param {object} options - Parameters to customize the formatting.
   * @returns {string}
   * @description Return a formatted value in the specified measure unit
   */
  formatDistance = (
    value: number,
    toUnit: 'cm' | 'km' = 'km',
    { decimals = 2, withUnit = true } = {}
  ) => {
    return `${roundWithDecimals(
      this.convertDistance(value, toUnit),
      decimals
    ).toLocaleString()}${withUnit ? ' ' + toUnit : ''}`;
  };

  /**
   * @function formatMsToHoursMinutes
   * @param {number} value - Value (in milliseconds) to be formatted.
   * @returns {string}
   * @description Return a formatted time
   */
  formatMsToHoursMinutes = (value?: number) => {
    if (!value) return NaN;

    const msInSecond = 1000;
    const msInMinute = 60 * msInSecond;
    const msInHour = 60 * msInMinute;

    const hours = Math.floor(value / msInHour);
    const rest = value % msInHour;
    const minutes = Math.floor(rest / msInMinute);

    return `${hours ? `${hours}h` : ''} ${minutes}m`;
  };

  /**
   * @function convertTonBalanceCapacityToKilograms
   * @param {number} balanceCapacity - Balance capacity, in tonnes, to be converted to kilograms.
   * @returns {string}
   * @description Return balance capacity in kilograms
   */
  convertTonBalanceCapacityToKilograms = (
    balanceCapacity?: number,
    hasBalance = false
  ) => {
    if (hasBalance && balanceCapacity) {
      return Math.round(balanceCapacity * 1000);
    }

    return undefined;
  };

  /**
   * @function convertKilometersDirtRoadDistanceToMeters
   * @param {number} dirtRoadDistance - Dirt road distance, in kilometers, to be converted to meters.
   * @returns {number}
   * @description Return dirt road distance in meters
   */
  convertKilometersDirtRoadDistanceToMeters = (
    dirtRoadDistance?: number,
    hasDirtRoad = false
  ) => {
    if (hasDirtRoad && dirtRoadDistance) {
      return Math.round(dirtRoadDistance * 1000);
    }
  };

  /**
   * @function formatCoordinates
   * @param {ICoordinatesApi} coordinates - Coordinates (latitude and longitude) from API.
   * @returns {ICoordinates}
   * @description Return coordinates formatted
   */
  formatCoordinates = (
    coordinates?: ICoordinatesApi
  ): ICoordinates | undefined => {
    if (coordinates) {
      return {
        latitude: Number(coordinates.latitude),
        longitude: Number(coordinates.longitude),
      };
    }
  };

  /**
   * @function formatMeasuringUnit
   * @param {UnitMeasureEnum} measuringUnit - Unit of measurement API enum value.
   * @param {number} value - Value to be displayed.
   * @returns {string | undefined}
   * @description Return formatted value with measuring unit
   */
  formatMeasuringUnit = (value?: number, measuringUnit?: UnitMeasureEnum) => {
    enum MeasuringUnitInitialsEnum {
      CENTIMETERS = 'cm',
      GRAMS = 'g',
      KILOGRAMS = 'kg',
      KILOMETERS = 'km',
      METERS = 'm',
      MILIMETERS = 'mm',
      MILLIGRAMS = 'mg',
      TONS = 'ton',
    }

    if (value) {
      return (
        value.toLocaleString() +
        (measuringUnit ? ` ${MeasuringUnitInitialsEnum[measuringUnit]}` : '')
      );
    }
  };

  /**
   * @function formatMercosulPlate
   * @param {string} text Text aim to be formatted.
   * @returns {string}
   * @description Format the value passed to the Mercosul plate format.
   */
  formatMercosulPlate = (text = '') => {
    if (!text) return text;

    try {
      const cleanValue = Normalizer.onlyNumbersLetters(
        Normalizer.toUpperCase(text)
      );

      if (cleanValue.length < 5) {
        return VMasker.toPattern(cleanValue, 'AAA-9S99');
      }

      if (/[A-Z]/.test(cleanValue[4])) {
        return VMasker.toPattern(cleanValue, 'AAA 9A99');
      }

      return VMasker.toPattern(cleanValue, 'AAA-9999');
    } catch (error) {
      return text;
    }
  };

  /**
   * @param {string} [text=''] - The text to be formatted as money.
   * @returns {string} The input text formatted as money, or the original text if
   *                   the input is invalid.
   * @description Formats a given text as a money value with 3 decimal places precision and a
   * thousands separator. If the input is invalid, the original text is returned.
   */

  formatVolume = (text = '') => {
    try {
      if (!text) return '';

      const value = text.toString().replace(/[^0-9]/g, '');
      return VMasker.toMoney(value, { precision: 3 });
    } catch (error) {
      return text;
    }
  };
}

export default new Formatter();
