/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Descriptions, Row, Skeleton } from 'antd';
import {
  CSSProperties,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Map, { MapRef } from 'react-map-gl';
import MarkerIcon from '../../../../assets/marker-icon.png';
import Formatter from '../../../../classes/Formatter';
import MapboxController from '../../../../controllers/MapboxController/MapboxController';
import { IGetDirectionsResponse } from '../../../../controllers/MapboxController/MapboxControllerInterfaces';
import MapStyle from '../../../../structures/interfaces/Mapbox/MapStyles';
import { ResponsiveDescriptions } from '../../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { LineLayer } from '../../../Mapbox/Layer/LineLayer';
import { MarkerLayer } from '../../../Mapbox/Layer/MarkerLayer';

interface ICoordinates {
  latitude: number;
  longitude: number;
}

export interface IRouteData {
  total_distance: number;
  estimate_time: number;
}

interface IMapboxInteractionOptions {
  boxZoom?: boolean;
  doubleClickZoom?: boolean;
  dragRotate?: boolean;
  dragPan?: boolean;
  keyboard?: boolean;
  scrollZoom?: boolean;
  touchPitch?: boolean;
  touchZoomRotate?: boolean;
  interactive?: boolean;
}

interface IRouteMapProps {
  /**
   * Coordinates of the pick-up place
   */
  pickupPlaceCoordinates?: ICoordinates;
  /**
   * Coordinates of the drop-off place
   */
  dropoffPlaceCoordinates?: ICoordinates;
  /**
   * Function to update state with the distance and estimate time of the route
   */
  setRouteData?: React.Dispatch<React.SetStateAction<IRouteData | undefined>>;
  /**
   * Show distance and estimated time above the map
   */
  showDescriptions?: boolean;
  /**
   * Map container style
   */
  style?: CSSProperties;
  /**
   * Mapbox interaction options
   */
  interactionOptions?: IMapboxInteractionOptions;
}

/**
 * Map to show a route between two places and the distance and estimate time
 */
export const RouteMap = ({
  pickupPlaceCoordinates,
  dropoffPlaceCoordinates,
  setRouteData,
  showDescriptions,
  style,
  interactionOptions,
}: IRouteMapProps): ReactElement => {
  const ROUTE_MAP_TRANSLATION_PATH = 'components.freight.route.routeMap';

  const defaultCoordinates = {
    longitude: -54,
    latitude: -12,
    zoom: 3,
  };

  const { t } = useTranslation();

  const mapRef = useRef<MapRef | null>(null);
  const areImagesUnloaded = useRef(true);

  const [requestError, setRequestError] = useState(false);
  const [coordinates, setCoordinates] = useState<ICoordinates[]>();
  const [directionsData, setDirectionsData] =
    useState<IGetDirectionsResponse>();

  const fitToBounds = (coordinates: ICoordinates[]) => {
    const getMinMaxCoordinates = (coordinates: ICoordinates[]) => {
      let minLat = coordinates[0].latitude;
      let minLng = coordinates[0].longitude;
      let maxLat = coordinates[0].latitude;
      let maxLng = coordinates[0].longitude;

      coordinates.forEach(({ latitude, longitude }) => {
        minLng = longitude < minLng ? longitude : minLng;
        minLat = latitude < minLat ? latitude : minLat;
        maxLng = longitude > maxLng ? longitude : maxLng;
        maxLat = latitude > maxLat ? latitude : maxLat;
      });

      return [
        [minLng, minLat],
        [maxLng, maxLat],
      ] as [[number, number], [number, number]];
    };

    if (mapRef.current) {
      mapRef.current.fitBounds(getMinMaxCoordinates(coordinates), {
        padding: 30,
      });
    }
  };

  const loadImages = () => {
    if (mapRef.current && areImagesUnloaded.current) {
      mapRef.current.loadImage(MarkerIcon, (error, image) => {
        if (error) throw error;
        if (mapRef.current && !mapRef.current.hasImage(MarkerIcon) && image) {
          mapRef.current.addImage(MarkerIcon, image);
        }
      });

      areImagesUnloaded.current = false;
    }
  };

  useEffect(() => {
    if (pickupPlaceCoordinates && dropoffPlaceCoordinates) {
      const coordinates = [
        {
          latitude: pickupPlaceCoordinates.latitude,
          longitude: pickupPlaceCoordinates.longitude,
        },
        {
          latitude: dropoffPlaceCoordinates.latitude,
          longitude: dropoffPlaceCoordinates.longitude,
        },
      ];

      setCoordinates(coordinates);
    }
  }, [pickupPlaceCoordinates, dropoffPlaceCoordinates]);

  useEffect(() => {
    if (coordinates) {
      setRequestError(false);

      MapboxController.getDirections({
        profile: 'driving',
        coordinates: coordinates,
      })
        .then(data => {
          setDirectionsData(data);

          if (setRouteData) {
            const route = data.routes[0];
            setRouteData({
              estimate_time: route.duration,
              total_distance: route.distance,
            });
          }
        })
        .catch(() => {
          setRequestError(true);
        });
    }
  }, [coordinates]);

  useEffect(() => {
    if (coordinates) {
      loadImages();
      fitToBounds(coordinates);
    }

    if (directionsData && directionsData.code !== 'Ok') setRequestError(true);
  }, [directionsData]);

  const markers = coordinates?.map(({ latitude, longitude }) => ({
    coordinates: [longitude, latitude] as [number, number],
    properties: {
      icon: MarkerIcon,
      size: 0.4,
    },
  }));

  const route = directionsData?.routes[0];

  const line = route?.geometry;

  const descriptions = {
    estimate_time: route?.duration,
    total_distance: route?.distance,
  };

  const RouteDescription = useMemo(() => {
    return (
      <ResponsiveDescriptions
        column={{ xxl: 2, xl: 1, lg: 2, md: 2, sm: 2, xs: 1 }}
        layout={{
          xxl: 'horizontal',
          xl: 'horizontal',
          lg: 'horizontal',
          md: 'horizontal',
          sm: 'horizontal',
          xs: 'vertical',
        }}
        size="small"
      >
        <Descriptions.Item
          label={t(`${ROUTE_MAP_TRANSLATION_PATH}.descriptions.distance`)}
        >
          <Skeleton active paragraph={false} loading={!directionsData}>
            {descriptions.total_distance
              ? Formatter.formatDistance(descriptions.total_distance, 'km')
              : ''}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`${ROUTE_MAP_TRANSLATION_PATH}.descriptions.estimate_time`)}
        >
          <Skeleton active paragraph={false} loading={!directionsData}>
            {descriptions.estimate_time
              ? Formatter.formatMsToHoursMinutes(
                  descriptions.estimate_time * 1000
                )
              : ''}
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
    );
  }, [descriptions, directionsData]);

  return (
    <Row>
      {requestError && (
        <Col span={24} style={{ marginBottom: 24 }}>
          <Alert
            type="error"
            message={t('errors.mapbox.directions.message')}
            description={t('errors.mapbox.directions.description')}
          />
        </Col>
      )}
      {showDescriptions ? <Col span={24}>{RouteDescription}</Col> : <></>}
      <Col span={24} className="mapbox__map-container" style={style}>
        <Map
          reuseMaps
          ref={mapRef}
          maxZoom={10}
          mapStyle={MapStyle.streets}
          style={{ height: '100%', width: '100%' }}
          boxZoom={false}
          doubleClickZoom={false}
          dragRotate={false}
          dragPan={false}
          keyboard={false}
          scrollZoom={false}
          touchPitch={false}
          touchZoomRotate={false}
          interactive={false}
          onResize={() => {
            if (coordinates) fitToBounds(coordinates);
          }}
          initialViewState={{
            ...defaultCoordinates,
          }}
          {...interactionOptions}
        >
          <LineLayer line={line} />
          <MarkerLayer markers={markers} />
        </Map>
      </Col>
    </Row>
  );
};
