import { BreadcrumbProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { CreateFreightForm } from '../../../../components/Freight/Freights/CreateFreightForm/CreateFreightForm';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';
import { FixedFooterContainer } from '../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { breadcrumbItemRender } from '../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

export const CreateFreightPage = (): ReactElement => {
  const CREATE_FREIGHT_PAGE_TRANSLATION_PATH =
    'pages.freight.freights.createFreightPage';

  const { t } = useTranslation();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/shipper/freights',
        breadcrumbName: t('tab.shipper.freight.index'),
      },
      {
        path: '/shipper/freights/create',
        breadcrumbName: t(`${CREATE_FREIGHT_PAGE_TRANSLATION_PATH}.title`),
      },
    ],
  };

  useTitle({ title: `${CREATE_FREIGHT_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/freights'],
  });

  return (
    <FixedFooterContainer>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        subTitle={t(`${CREATE_FREIGHT_PAGE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${CREATE_FREIGHT_PAGE_TRANSLATION_PATH}.title`)}
      />
      <CreateFreightForm />
    </FixedFooterContainer>
  );
};

const CreateFreightPageAccess = Access(CreateFreightPage, {
  acceptedPermissions: [
    {
      module: 'FREIGHTS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default CreateFreightPageAccess;
