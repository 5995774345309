import { Form, FormItemProps, FormRule } from 'antd';
import Formatter from '../../../../classes/Formatter';
import { UnitTypeEnum } from '../../../../graphql/generated/graphql';
import { parseLocaleNumber } from '../../../../utils';

interface IVolumeFormItemProps extends FormItemProps {
  unitType?: UnitTypeEnum;
}

export const VolumeFormItem = ({
  unitType,
  ...props
}: IVolumeFormItemProps) => {
  const tonVolumeFormItem = <TonVolumeFormItem {...props} />;

  if (unitType === UnitTypeEnum.Ton) {
    return tonVolumeFormItem;
  }

  if (unitType === UnitTypeEnum.Unit) {
    return <UnitVolumeFormItem {...props} />;
  }

  return tonVolumeFormItem;
};

const TonVolumeFormItem = (props: FormItemProps) => {
  const rules: FormRule[] = [
    { type: 'number', min: 0.001, transform: parseLocaleNumber },
    {
      type: 'number',
      max: 100000000,
      transform: parseLocaleNumber,
    },
  ];

  if (props.required) rules.unshift({ required: true });

  return (
    <Form.Item
      normalize={Formatter.formatVolume}
      rules={rules}
      validateFirst
      {...props}
    >
      {props.children}
    </Form.Item>
  );
};

const UnitVolumeFormItem = (props: FormItemProps) => {
  return <Form.Item {...props}>{props.children}</Form.Item>;
};
