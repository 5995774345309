import { Button, ButtonProps, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Access } from '../../Access/Access';

export const EditCompanyDataButtons = (props: ButtonProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'general' });

  return <Button {...props}>{t('edit')}</Button>;
};

export const EditCompanyDataButtonsAccess = Access(EditCompanyDataButtons, {
  acceptedPermissions: [{ module: 'COMPANY_SETTINGS', actions: ['UPDATE'] }],
});

export interface IEditingCompanyDataButtons {
  cancelButtonProps?: ButtonProps;
  submitButtonProps?: ButtonProps;
}

export const EditingCompanyDataButtons = ({
  cancelButtonProps,
  submitButtonProps,
}: IEditingCompanyDataButtons) => {
  const { t } = useTranslation('translations', { keyPrefix: 'general' });

  return (
    <Space>
      <Button {...cancelButtonProps}>{t('cancel')}</Button>
      <Button type="primary" {...submitButtonProps}>
        {t('save')}
      </Button>
    </Space>
  );
};
