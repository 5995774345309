import { useI18n } from '../I18n/I18n.hooks';

const keys = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',

  no: 'no',
  yes: 'yes',

  actions: 'actions',
  back: 'back',
  cancel: 'cancel',
  clearFields: 'clearFields',
  clearFilter: 'clearFilter',
  close: 'close',
  confirm: 'confirm',
  delete: 'delete',
  deletePopconfirm: 'deletePopconfirm',
  edit: 'edit',
  filter: 'filter',
  finish: 'finish',
  remove: 'remove',
  removePopconfirm: 'removePopconfirm',
  save: 'save',
  select: 'select',
  view: 'view',
  download: 'download',

  dataNotFound: 'dataNotFound',
  none: 'none',
  notFoundMessage: 'notFoundMessage',
  unknown: 'unknown',
  warning: 'warning',
};

export const useCommonI18n = () => {
  return useI18n({ keys, keyPrefix: 'core.common.hooks' });
};
