import { Select, SelectProps } from 'antd';

import { useTranslation } from 'react-i18next';
import {
  FindProductsQueryVariables,
  useFindProductsQuery,
} from '../../../../graphql/generated/graphql';

type IProductSelect = Omit<SelectProps, 'options'> & {
  productTypeId?: string;
};

/**
 * Ant design select with Product options
 */
export const ProductSelect = ({
  productTypeId,
  loading,
  disabled,
  ...props
}: IProductSelect) => {
  const { t } = useTranslation();

  const variables: FindProductsQueryVariables | undefined = productTypeId
    ? {
        where: {
          type_id: {
            equals: productTypeId,
          },
        },
        pagination: {
          take: 1000,
        },
      }
    : undefined;

  const [findProductsResult] = useFindProductsQuery({
    variables,
    pause: !variables,
  });

  const { data, fetching } = findProductsResult;

  const productOptions: SelectProps['options'] | undefined =
    data?.FindManyProducts.data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  return (
    <>
      <Select
        {...props}
        loading={loading || fetching}
        disabled={disabled || fetching}
        options={productOptions}
        notFoundContent={t(
          'components.product.product.productSelect.notFoundMessage'
        )}
      />
    </>
  );
};
