import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { ListProductTypes } from '../../../../components/Product/ProductType/ListProductTypes/ListProductTypes';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';

export const ProductTypesPage = (): ReactElement => {
  const PRODUCT_TYPES_PAGE_TRANSLATION_PATH = 'pages.product.productTypesPage';

  const { t } = useTranslation();

  useTitle({ title: 'tab.product.productTypes' });
  useMenuItem({
    openMenu: ['/support'],
    selectedMenu: ['/support/productTypes'],
  });

  return (
    <>
      <DefaultPageHeader
        subTitle={t(`${PRODUCT_TYPES_PAGE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${PRODUCT_TYPES_PAGE_TRANSLATION_PATH}.title`)}
      />
      <ListProductTypes />
    </>
  );
};

const ProductTypesPageAccess = Access(ProductTypesPage, {
  acceptedPermissions: [
    {
      module: 'PRODUCT_TYPES',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default ProductTypesPageAccess;
