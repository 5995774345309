import { ReactElement } from 'react';

import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import Providers from './providers';
import AntDesignConfigProvider from './contexts/AntDesignConfigProvider/AntDesignConfigProvider';
import { DevelopmentBadge } from './components/layout/DevelopmentBadge/DevelopmentBadge';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import pt_BR from 'dayjs/locale/pt-br';

dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore);
dayjs.locale(pt_BR);

function App(): ReactElement<unknown> {
  return (
    <BrowserRouter>
      <Providers>
        <AntDesignConfigProvider>
          <>
            <DevelopmentBadge />
            <Routes />
          </>
        </AntDesignConfigProvider>
      </Providers>
    </BrowserRouter>
  );
}

export default App;
