import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { Row } from 'antd';

import Formatter from '../../../../classes/Formatter';

import FormSection from '.';
import InputText from '../../../../components/inputs/inputText';
import { validatePhoneNumber } from '../../../../utils/inputRules';

const CompanyContact = (): ReactElement => {
  const companyContactTextPath =
    'pages.registerCompany.sections.companyContact';

  const { t } = useTranslation();

  return (
    <FormSection sectionTitle={t(`${companyContactTextPath}.title`)}>
      <Row>
        <InputText
          formItem
          validation
          size="large"
          name="phoneNumber"
          label={t(`${companyContactTextPath}.fields.labels.phoneNumber`)}
          placeholder={t(
            `${companyContactTextPath}.fields.placeholders.phoneNumber`
          )}
          normalize={Formatter.formatPhoneWithoutDDI}
          otherRules={[validatePhoneNumber]}
        />
        <InputText
          formItem
          validation
          size="large"
          name="email"
          label={t(`${companyContactTextPath}.fields.labels.email`)}
          placeholder={t(`${companyContactTextPath}.fields.placeholders.email`)}
          otherRules={[
            { type: 'email', message: t('input.rules.invalidEmail') },
          ]}
        />
      </Row>
    </FormSection>
  );
};

export default CompanyContact;
