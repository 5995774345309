import { VolumeDisplay } from './VolumeDisplay/VolumeDisplay';
import { VolumeInput } from './VolumeInput/VolumeInput';
import { VolumeFormItem } from './VolumeInput/VolumeInput.components';
import { VolumeInputUtils } from './VolumeInput/VolumeInput.utils';

export const Volume = {
  Display: VolumeDisplay,
  Input: VolumeInput,
  FormItem: VolumeFormItem,
  Utils: VolumeInputUtils,
};
