import { App, Form, notification } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import { Volume } from '../../../core/Freight/Volume';
import { Price } from '../../../core/Price';
import {
  Quotation,
  RefuseQuotationModalMutation,
  useCreateQuotationMutation,
  useFindFreightForCreateQuotationQuery,
} from '../../../graphql/generated/graphql';
import { useInterval } from '../../../hooks/useInterval/useInterval';
import { ICreateQuotationFormValues } from '../CreateQuotationForm/CreateQuotationForm';
import { IRefuseQuotationFormValues } from '../RefuseQuotationModal/RefuseQuotationModal';

export interface IUseCreateQuotationProps {
  handleRefuseQuotationFinish?: (
    formValues: IRefuseQuotationFormValues
  ) => Promise<RefuseQuotationModalMutation>;
  setQuoteLimit?: (quoteLimit: dayjs.Dayjs | null) => void;
}

export const useCreateQuotation = ({
  handleRefuseQuotationFinish,
  setQuoteLimit,
}: IUseCreateQuotationProps = {}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.quotation.createQuotation',
  });

  const navigate = useNavigate();

  const { message } = App.useApp();

  const { freightId } = useParams();

  const [form] = Form.useForm<ICreateQuotationFormValues>();

  const { userData } = useUserContext();

  const variables = freightId ? { freightId } : undefined;

  const [findFreightQueryResult] = useFindFreightForCreateQuotationQuery({
    variables,
    pause: !variables,
  });

  const { validateQuoteLimit } = useQuoteLimitDateValidation({
    quoteLimitDate: findFreightQueryResult.data?.FindFreight.quote_limit,
  });

  const [createQuotationMutationResult, executeCreateQuotationMutation] =
    useCreateQuotationMutation();

  const handlers = useMemo(() => {
    return {
      onCancel() {
        return navigate(-1);
      },
      onConfirm() {
        form.submit();
      },
      onSubmit(formValues: ICreateQuotationFormValues) {
        const unitType = findFreightQueryResult.data?.FindFreight.unit_type;

        const totalVolume = Volume.Utils.formatVolumeInputToBaseUnitType(
          formValues.total_volume,
          unitType
        );
        const pricePerUnit = Price.Utils.formatPriceInputToCents(
          formValues.price_per_unit
        );

        const totalPrice = Price.Utils.formatPriceInputToCents(
          formValues.total_price
        );

        if (
          userData?.company &&
          freightId &&
          totalVolume &&
          pricePerUnit &&
          totalPrice
        ) {
          executeCreateQuotationMutation({
            input: {
              creator_id: userData.id,
              owner_id: userData.company.id,
              freight_id: freightId,
              has_vehicle: Boolean(formValues.has_vehicle),
              total_volume: totalVolume,
              value_per_unit: pricePerUnit,
              value: totalPrice,
            },
          }).then(({ data, error }) => {
            if (!error && data) {
              message.success({
                content: t('notification.createQuotationSuccess.message'),
              });

              return navigate('/carrier/freights', { replace: true });
            }
          });
        }
      },
      onRefuseQuotationSubmit(formValues: IRefuseQuotationFormValues) {
        handleRefuseQuotationFinish?.(formValues)
          .then(() => {
            message.success({
              content: t('notification.refuseQuotationSuccess.message'),
            });

            return navigate('/carrier/freights');
          })
          .catch(() => null);
      },
    };
  }, [
    executeCreateQuotationMutation,
    findFreightQueryResult.data?.FindFreight.unit_type,
    form,
    freightId,
    handleRefuseQuotationFinish,
    navigate,
    message,
    t,
    userData,
  ]);

  useEffect(() => {
    const { data, error, fetching } = findFreightQueryResult;

    if (error) {
      setQuoteLimit?.(null);
    }

    if (data && !error && !fetching) {
      validateQuoteLimit?.(data.FindFreight.quote_limit);

      setQuoteLimit?.(dayjs(data.FindFreight.quote_limit));
    }
  }, [findFreightQueryResult, setQuoteLimit, validateQuoteLimit]);

  return {
    createQuotationMutationResult,
    findFreightQueryResult,
    form,
    handlers,
  };
};

export interface IUseQuoteLimitDateValidation {
  quoteLimitDate?: string | Dayjs;
}

export const useQuoteLimitDateValidation = ({
  quoteLimitDate,
}: IUseQuoteLimitDateValidation) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.quotation.createQuotation.notification.quoteLimitHasEnded',
  });

  const navigate = useNavigate();

  const validateQuoteLimit = useCallback(
    (quoteLimit: string | Dayjs) => {
      if (dayjs(quoteLimit).isSameOrBefore(dayjs(), 'seconds')) {
        notification.error({
          message: t('message'),
          description: t('description'),
          duration: 15,
        });

        return navigate(-1);
      }
    },
    [navigate, t]
  );

  useInterval(() => {
    if (quoteLimitDate) {
      validateQuoteLimit(quoteLimitDate);
    }
  }, 1000);

  return { validateQuoteLimit };
};

export interface IUseQuotationAveragePricePerUnitProps {
  quotations?: Pick<Quotation, 'value_per_unit'>[] | null;
}

export const useQuotationAveragePricePerUnit = ({
  quotations,
}: IUseQuotationAveragePricePerUnitProps) => {
  if (!quotations || quotations.length < 3) {
    return 0;
  }

  const averagePricePerUnit =
    quotations.reduce((acc, { value_per_unit }) => acc + value_per_unit, 0) /
    quotations.length;

  return averagePricePerUnit;
};
