import { useEffect, useRef } from 'react';

export const useInterval = (handler: () => void, timeout: number) => {
  const intervalRef = useRef<number | null>(null);
  const handlerRef = useRef<() => void>(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    intervalRef.current = window.setInterval(
      () => handlerRef.current(),
      timeout
    );

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [timeout]);
};
