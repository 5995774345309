import { Col, Form, Input, Modal, ModalProps, Row, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import { CompanySelect } from '../../../../../components/Company/CompanySelect/CompanySelect';
import { useQuotationGroupI18n } from '../../../../../core/QuotationGroup/QuotationGroup.hooks';
import { QuotationGroupMembersTable } from '../QuotationGroupMembersTable/QuotationGroupMembersTable';
import { useQuotationGroupFormModalActions } from './QuotationGroupFormModal.hooks';

export interface IQuotationGroupFormModalValues {
  name: string;
  members?: SelectProps['options'];
}

export interface IQuotationGroupFormModalProps extends Partial<ModalProps> {
  action: 'create' | 'update' | 'read';
  groupId?: string | null;
  afterClose?: () => void;
  onCancel?: () => void;
  onOpen?: () => void;
  onMutationSuccess?: () => void;
}

export const QuotationGroupFormModal = ({
  action,
  groupId,
  afterClose,
  onCancel,
  onOpen,
  onMutationSuccess,
  ...props
}: IQuotationGroupFormModalProps) => {
  const {
    tKeys: { members: membersTitle, name: nameTitle },
  } = useQuotationGroupI18n();

  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.shipper.quotationGroupsPage.components.quotationGroupFormModal',
  });

  const {
    tableProps,
    tableActionsCardProps,
    formProps,
    form: {
      membersTable: { rules },
      members: {
        selectProps: membersSelectProps,
        isVisible: isMembersSelectVisible,
      },
    },
    errors: {
      createGroup: createGroupError,
      findGroup: findGroupError,
      updateGroup: updateGroupError,
    },
    modalProps,
  } = useQuotationGroupFormModalActions({
    action,
    groupId,
    onCancel,
    afterClose,
    onOpen,
    onMutationSuccess,
  });

  return (
    <>
      <Modal {...props} {...modalProps}>
        <Form {...formProps}>
          <Row>
            <ErrorAlert error={createGroupError} style={{ marginBottom: 24 }} />
            <ErrorAlert error={findGroupError} style={{ marginBottom: 24 }} />
            <ErrorAlert error={updateGroupError} style={{ marginBottom: 24 }} />
            <Col span={24}>
              <Form.Item
                name="name"
                label={nameTitle}
                required
                rules={[{ required: true }, { type: 'string', max: 100 }]}
              >
                <Input placeholder={t('form.placeholders.name')} />
              </Form.Item>
            </Col>
            {isMembersSelectVisible && (
              <Col span={24}>
                <Form.Item
                  name="members"
                  label={membersTitle}
                  required
                  tooltip={t('form.tooltips.members')}
                >
                  <CompanySelect {...membersSelectProps} />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="membersTable"
                rules={[rules.minGroupMembers, rules.maxGroupMembers]}
              >
                <QuotationGroupMembersTable.ActionsCard
                  {...tableActionsCardProps}
                >
                  <QuotationGroupMembersTable {...tableProps} />
                </QuotationGroupMembersTable.ActionsCard>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
