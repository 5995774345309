import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { Row } from 'antd';

import { UFNameOptions } from '../../../../structures/interfaces/UF';

import FormSection from '.';
import Select from '../../../../components/inputs/select';

const CarrierCompanyOperationAreas = (): ReactElement => {
  const carrierCompanyOperationAreasTextPath =
    'pages.registerCompany.sections.carrierCompanyOperationAreas';

  const { t } = useTranslation();

  return (
    <FormSection
      sectionTitle={t(`${carrierCompanyOperationAreasTextPath}.title`)}
    >
      <Row>
        <Select
          formItem
          name="operationAreas"
          size="large"
          mode="multiple"
          required={false}
          otherPropsFormItem={{ style: { maxWidth: '800px' } }}
          options={UFNameOptions}
        />
      </Row>
    </FormSection>
  );
};

export default CarrierCompanyOperationAreas;
