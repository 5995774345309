import {
  Alert,
  AlertProps,
  Button,
  Card,
  CardProps,
  Col,
  Form,
  Row,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import Formatter from '../../../../classes/Formatter';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  ConfirmQuotationFragment,
  UnitTypeEnum,
  useWaiveQuotationModalMutation,
} from '../../../../graphql/generated/graphql';
import { parseLocaleNumber } from '../../../../utils';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { FormattedDateTime } from '../../../Formatters/FormattedDateTime/FormattedDateTime';
import {
  IWaiveQuotationFormValues,
  WaiveQuotationForm,
} from '../../WaiveQuotationForm/WaiveQuotationForm';
import {
  ConfirmQuotationForm,
  IConfirmQuotationFormValues,
} from '../ConfirmQuotationForm/ConfirmQuotationForm';
import {
  IQuotationConfirmationTableItem,
  QuotationConfirmationTable,
} from '../QuotationConfirmationTable/QuotationConfirmationTable';
import { Volume } from '../../../../core/Freight/Volume';

const TRANSLATION_PATH =
  'components.quotation.confirmQuotation.confirmQuotation';

export type IConfirmQuotationStatus = 'EXPIRED' | 'CONFIRMED' | 'WAITING';

interface IConfirmQuotationProps {
  onAdd?: (confirmQuotation: IQuotationConfirmationTableItem) => void;
  onEdit?: (confirmQuotation: IQuotationConfirmationTableItem) => void;
  onRemove?: (confirmQuotationId: string) => void;
  status?: IConfirmQuotationStatus;
  quotation?: ConfirmQuotationFragment;
  quotationConfirmations?: IQuotationConfirmationTableItem[];
  disabled?: boolean;
}

export const ConfirmQuotation = ({
  onAdd,
  onEdit,
  onRemove,
  status,
  quotation,
  quotationConfirmations = [],
  disabled,
}: IConfirmQuotationProps): ReactElement => {
  const { t } = useTranslation();

  const [form] = Form.useForm<IConfirmQuotationFormValues>();

  const [editingConfirmation, setEditingConfirmation] =
    useState<IConfirmQuotationFormValues>();

  const parseConfirmQuotationFormValues = ({
    assigned_volume,
    vehicle_plate,
    ...quotationConfirmationsTableItem
  }: IQuotationConfirmationTableItem): IConfirmQuotationFormValues => {
    const assignedVolume = assigned_volume / 1000;
    const vehiclePlate = Formatter.formatMercosulPlate(vehicle_plate);

    return {
      ...quotationConfirmationsTableItem,
      driver_id: quotationConfirmationsTableItem.driver.id,
      cnh: quotationConfirmationsTableItem.driver.cnh,
      driver_name: quotationConfirmationsTableItem.driver.name,
      vehicle_type: {
        label: quotationConfirmationsTableItem.vehicle_type.name,
        value: quotationConfirmationsTableItem.vehicle_type.id,
      },
      assigned_volume: Formatter.formatVolume(
        String(assignedVolume.toFixed(3))
      ),
      vehicle_plate: vehiclePlate,
    };
  };

  const parseQuotationConfirmationTableItem = ({
    cnh,
    driver_name,
    assigned_volume,
    vehicle_plate,
    driver_id,
    unitType,
    ...confirmQuotationFormValues
  }: IConfirmQuotationFormValues & {
    id: string;
    hasPersisted: boolean;
    unitType?: UnitTypeEnum;
  }): IQuotationConfirmationTableItem => {
    const assignedVolume =
      Volume.Utils.formatVolumeInputToBaseUnitType(
        parseLocaleNumber(assigned_volume),
        unitType
      ) || 0;
    const vehiclePlate = vehicle_plate.replaceAll(/[\s-]/g, '');
    const driverName = driver_name.trim();
    const driverId = driver_id || v4();

    return {
      ...confirmQuotationFormValues,
      driver: {
        id: driverId,
        cnh,
        name: driverName,
      },
      vehicle_type: {
        id: confirmQuotationFormValues.vehicle_type.value,
        name: confirmQuotationFormValues.vehicle_type.label,
      },
      assigned_volume: assignedVolume,
      vehicle_plate: vehiclePlate,
    };
  };

  const handleEdit = (confirmationId: string) => {
    const editingQuotationConfirmation = quotationConfirmations.find(
      confirmation => confirmation.id === confirmationId
    );

    if (editingQuotationConfirmation) {
      setEditingConfirmation(
        parseConfirmQuotationFormValues(editingQuotationConfirmation)
      );
    }
  };

  const handleCancelEdit = () => {
    setEditingConfirmation(undefined);
  };

  const handleRemove = (confirmationId: string) => {
    onRemove && onRemove(confirmationId);
  };

  const handleAddOrEdit = (
    confirmQuotationFormValues: IConfirmQuotationFormValues
  ) => {
    if (editingConfirmation?.id) {
      const editedQuotationConfirmation = parseQuotationConfirmationTableItem({
        ...confirmQuotationFormValues,
        id: editingConfirmation.id,
        driver_name: editingConfirmation.driver_name,
        cnh: editingConfirmation.cnh,
        hasPersisted:
          quotation?.confirmations?.some(
            confirmation => confirmation.id === editingConfirmation.id
          ) || false,
        unitType: quotation?.freight.unit_type,
      });

      if (onEdit) {
        onEdit(editedQuotationConfirmation);
      }

      handleCancelEdit();
    } else {
      const createdQuotationConfirmation = parseQuotationConfirmationTableItem({
        ...confirmQuotationFormValues,
        id: v4(),
        hasPersisted: false,
        unitType: quotation?.freight.unit_type,
      });

      if (onAdd) {
        onAdd(createdQuotationConfirmation);
      }
    }

    form.resetFields();
  };

  const alertTypes: Record<IConfirmQuotationStatus, AlertProps['type'] | null> =
    {
      CONFIRMED: null,
      EXPIRED: 'error',
      WAITING: 'warning',
    };

  const alertType = status ? alertTypes[status] : null;

  const requestedVolume = quotation?.requested_volume || 0;
  const unallocatedVolume = quotationConfirmations.reduce(
    (sum, confirmation) =>
      confirmation.id !== editingConfirmation?.id
        ? sum - confirmation.assigned_volume
        : sum,
    requestedVolume
  );

  const isQuotationConfirmed = quotationConfirmations.some(
    confirmation => confirmation.hasPersisted
  );

  return (
    <ConfirmQuotationCard
      quotationId={quotation?.id}
      status={status}
      isQuotationConfirmed={isQuotationConfirmed}
    >
      <Row gutter={[0, 24]}>
        {quotation?.expires_in && alertType && (
          <Col span={24}>
            <Alert
              type={alertType}
              description={
                <Trans
                  components={[
                    <FormattedDateTime
                      key={quotation.expires_in}
                      dateTime={dayjs(quotation.expires_in)}
                    />,
                  ]}
                  i18nKey={`${TRANSLATION_PATH}.${alertType}`}
                  t={t}
                />
              }
            />
          </Col>
        )}
        {status && (
          <Col span={24}>
            <ConfirmQuotationForm
              editingConfirmation={editingConfirmation}
              form={form}
              disabled={status === 'EXPIRED' || disabled}
              status={status}
              onFinish={handleAddOrEdit}
              onReset={handleCancelEdit}
              quotationConfirmations={quotationConfirmations}
              quotation={
                quotation
                  ? {
                      ...quotation,
                      unallocated_volume: unallocatedVolume,
                    }
                  : undefined
              }
            />
          </Col>
        )}
        <Col span={24}>
          <QuotationConfirmationTable
            dataSource={status === 'EXPIRED' ? [] : quotationConfirmations}
            editingConfirmationId={editingConfirmation?.id}
            onEdit={handleEdit}
            onRemove={handleRemove}
            disableActions={disabled}
            confirmQuotationStatus={status}
            pagination={{ pageSize: 5, hideOnSinglePage: true }}
            unitType={quotation?.freight.unit_type}
          />
        </Col>
      </Row>
    </ConfirmQuotationCard>
  );
};

interface IConfirmQuotationCardProps extends CardProps {
  children: JSX.Element;
  status?: IConfirmQuotationStatus;
  quotationId?: string;
  isQuotationConfirmed?: boolean;
}

const ConfirmQuotationCard = ({
  children,
  quotationId,
  isQuotationConfirmed,
  status,
  ...cardProps
}: IConfirmQuotationCardProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm<IWaiveQuotationFormValues>();

  const { userData } = useUserContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [result, executeWaiveQuotationModalMutation] =
    useWaiveQuotationModalMutation();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFinish = (formValues: IWaiveQuotationFormValues) => {
    if (userData?.company && quotationId) {
      executeWaiveQuotationModalMutation({
        input: {
          description: formValues.reasonDescription,
          reason_id: formValues.reason.value,
          owner_id: userData.company.id,
          quotation_id: quotationId,
        },
      }).then(({ data, error }) => {
        if (data && !error) {
          navigate(-1);
        }
      });
    }
  };

  const title = (
    <Row justify={'space-between'}>
      <Typography.Text>{t(`${TRANSLATION_PATH}.cardTitle`)}</Typography.Text>
      {status && status !== 'EXPIRED' && !isQuotationConfirmed && (
        <Button type="default" danger onClick={handleOpenModal}>
          {t(`${TRANSLATION_PATH}.actions.waive`)}
        </Button>
      )}
    </Row>
  );

  const { fetching, error } = result;

  return (
    <>
      <Card title={title} bordered={false} {...cardProps}>
        {children}
      </Card>
      <WaiveQuotationForm.Modal
        okButtonProps={{
          htmlType: 'submit',
          onClick: form.submit,
          loading: fetching,
        }}
        okText={t('general.confirm')}
        onCancel={handleCloseModal}
        open={isModalOpen}
      >
        <>
          <ErrorAlert error={error} style={{ marginBottom: 24 }} />
          <WaiveQuotationForm
            disabled={fetching}
            form={form}
            onFinish={handleFinish}
          />
        </>
      </WaiveQuotationForm.Modal>
    </>
  );
};
