import { ReactElement, ReactNode } from 'react';

import { Row, Card } from 'antd';

/**
 * @description Default child container card
 * @property {string} [title='title'] Card title
 * @property {Node} [children=null] Container children components
 * @property {string} [subtitle='subtitle'] Card subtitle
 * @example
 * <ContentCard
 *    title="Title"
 *    subtitle="Subtitle"
 * >
 *    <div>example</div>
 * </ContentCard>
 */

interface IContentCard {
  className?: string;
  children?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  [p: string]: unknown;
}

const ContentCard = ({
  title,
  subtitle,
  children,
  className,
  ...otherProps
}: IContentCard): ReactElement => {
  return (
    <Card className={`container_card ${className}`} {...otherProps}>
      <Row className="container_card-title">{title}</Row>
      <Row className="container_card-subtitle">{subtitle}</Row>
      <Row className="container_card-content">{children}</Row>
    </Card>
  );
};

export default ContentCard;
