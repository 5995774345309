import { DeleteOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Popconfirm } from 'antd';
import { useCommonI18n } from '../../../../core/Common/Common.hooks';
import { Access } from '../../../Access/Access';

const DeleteButton = ({
  onDelete,
  ...props
}: ButtonProps & {
  onDelete: () => void;
}) => {
  const { tKeys } = useCommonI18n();

  return (
    <Popconfirm title={tKeys.deletePopconfirm} onConfirm={onDelete}>
      <Button type="link" danger {...props}>
        <DeleteOutlined /> {tKeys.delete}
      </Button>
    </Popconfirm>
  );
};

export const DeleteButtonAccess = Access(DeleteButton, {
  acceptedPermissions: [{ module: 'FREIGHT_ATTACHMENTS', actions: ['DELETE'] }],
});
