import { Button, Form, FormProps, Input, Tooltip } from 'antd';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TruckerUserRoleSelect } from '../TruckerUserRoleSelect/TruckerUserRoleSelect';
import {
  CreateTruckerUserInput,
  RoleTruckerUserEnum,
} from '../../../../../../../graphql/generated/graphql';
import { RetweetOutlined } from '@ant-design/icons';
import { generatePassword } from '../../../../../../../utils';

export interface ICreateTruckerUserFormValues {
  email: string;
  name: string;
  password: string;
  role: RoleTruckerUserEnum;
}

export type ICreateTruckerUserFormProps =
  FormProps<ICreateTruckerUserFormValues>;

export const CreateTruckerUserForm = (
  props: ICreateTruckerUserFormProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.user.usersPage.components.createTruckerUserForm',
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(true);

  const defaultForm = Form.useForm<ICreateTruckerUserFormValues>()[0];
  const form = props.form || defaultForm;

  const onFinish = (values: ICreateTruckerUserFormValues) => {
    setIsPasswordVisible(false);
    props.onFinish && props.onFinish(values);
  };

  const handleGeneratePassword = () => {
    form.setFieldValue('password', generatePassword(8));
    form.validateFields(['password']);
  };

  return (
    <Form
      layout="vertical"
      validateTrigger={['onChange', 'onBlur']}
      {...props}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        label={t('fields.email')}
        name={'email'}
        required
        rules={[
          { required: true },
          { type: 'email', validateTrigger: 'onBlur' },
        ]}
        validateFirst
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label={t('fields.name')}
        name={'name'}
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item required label={t('fields.password')}>
        <Input.Group compact>
          <Form.Item
            label={t('fields.password')}
            name={'password'}
            noStyle
            rules={[
              { required: true },
              { min: 6, validateTrigger: 'onBlur' },
              { max: 128 },
            ]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Input.Password
              style={{ width: 'calc(100% - 32px)' }}
              type="password"
              visibilityToggle={{
                visible: isPasswordVisible,
                onVisibleChange: setIsPasswordVisible,
              }}
            />
          </Form.Item>
          <Tooltip title={t('tooltips.passwordGenerator')}>
            <Button
              onClick={handleGeneratePassword}
              icon={<RetweetOutlined />}
            />
          </Tooltip>
        </Input.Group>
      </Form.Item>
      <Form.Item
        initialValue={RoleTruckerUserEnum.Sup}
        label={t('fields.role')}
        name={'role'}
        required
        rules={[{ required: true }]}
      >
        <TruckerUserRoleSelect />
      </Form.Item>
    </Form>
  );
};

const toCreateTruckerUserInput = (
  formValues: ICreateTruckerUserFormValues
): CreateTruckerUserInput => {
  const email = formValues.email.trim().toLowerCase();
  const name = formValues.name.trim();
  const password = formValues.password;
  const roleName = formValues.role;

  return {
    email,
    name,
    password,
    role_name: roleName,
  };
};

CreateTruckerUserForm.toCreateTruckerUserInput = toCreateTruckerUserInput;
