import { useTranslation } from 'react-i18next';
import { UnitTypeEnum } from '../../../graphql/generated/graphql';
import { roundWithDecimals } from '../../../utils';

export interface IPriceDisplayProps {
  price?: string | number;
  unitType?: UnitTypeEnum;
}

export const PriceDisplay = ({ price = 0, unitType }: IPriceDisplayProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'core.price.priceDisplay',
  });

  const numberPrice = Number(price) || 0;

  const formattedPrice = roundWithDecimals(numberPrice / 100, 2).toLocaleString(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
    }
  );

  if (unitType) {
    return <>{t(unitType, { price: formattedPrice })}</>;
  }

  return <>{formattedPrice}</>;
};
