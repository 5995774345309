/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Col, Form, FormInstance, Row, notification } from 'antd';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormSection from '.';
import Formatter from '../../../../classes/Formatter';
import Normalizer from '../../../../classes/Normalizer';
import InputText from '../../../../components/inputs/inputText';
import Select from '../../../../components/inputs/select';
import { IUseRequestAction } from '../../../../hooks/useRequest';
import {
  IDeleteCompanyLogo,
  IDeleteCompanyLogoResponse,
  IFoundCompany,
  IUploadCompanyLogo,
  IUploadCompanyLogoResponse,
} from '../../../../structures/interfaces/Company/Company';
import { ICompanyFunction } from '../../../../structures/interfaces/Company/CompanyFunction';
import {
  CompanyTypeOptions,
  ICompanyType,
} from '../../../../structures/interfaces/Company/CompanyType';
import { translateOptions } from '../../../../utils';
import CompanyDataLogoUpload from './companyDataLogoUpload';
import CreateMotherCompanyModal, {
  IFieldValues as ICreateMotherCompanyFieldValues,
} from './createMotherCompanyModal';
import { useCompanyDataValidations } from './useCompanyDataValidations';

interface ICompanyDataInitialValues {
  companyDataAction?: ICompanyDataAction;
  companyType?: ICompanyType;
  motherCompanyDocument?: string;
  uploadDefaultFileUrl?: string;
  hasFleet?: boolean;
  companyId?: string;
}

interface ICompanyDataProps {
  userId: string;
  form: FormInstance;
  selectedCompanyFunction: ICompanyFunction;
  initialValues?: ICompanyDataInitialValues;
  uploadLogoImageRequest: IUseRequestAction<
    IUploadCompanyLogo,
    IUploadCompanyLogoResponse
  >;
  deleteLogoImageRequest: IUseRequestAction<
    IDeleteCompanyLogo,
    IDeleteCompanyLogoResponse
  >;
}

export type ICreateMotherCompanyModalAction =
  | 'createParent'
  | 'createBranchOrUnit'
  | 'details'
  | 'none';

export type ICompanyDataAction = 'create' | 'complete' | 'update';

const CompanyData = ({
  form,
  userId,
  initialValues,
  selectedCompanyFunction,
  uploadLogoImageRequest,
  deleteLogoImageRequest,
}: ICompanyDataProps): ReactElement => {
  const hasInitialized = useRef(false);

  const { t } = useTranslation();

  const [foundCompany, setFoundCompany] = useState<IFoundCompany>();
  const [foundMotherCompany, setFoundMotherCompany] = useState<IFoundCompany>();
  const [isFleetCheckboxChecked, setIsFleetCheckboxChecked] = useState(
    initialValues?.hasFleet || false
  );

  const [companyDataAction, setCompanyDataAction] =
    useState<ICompanyDataAction>(initialValues?.companyDataAction || 'create');

  const [
    isCreateMotherCompanyModalVisible,
    setIsCreateMotherCompanyModalVisible,
  ] = useState(false);

  const [createMotherCompanyModalAction, setCreateMotherCompanyModalAction] =
    useState<ICreateMotherCompanyModalAction>('none');

  const [selectedCompanyType, setSelectedCompanyType] = useState<ICompanyType>(
    initialValues?.companyType || 'Parent'
  );

  const companyDataTextPath = 'pages.registerCompany.sections.companyData';
  const companyTypeOptions = translateOptions(t, CompanyTypeOptions);

  useEffect(() => {
    if (initialValues && !hasInitialized.current) {
      hasInitialized.current = true;

      if (initialValues.companyDataAction) {
        setCompanyDataAction(initialValues.companyDataAction);
      }

      if (initialValues.companyType) {
        setSelectedCompanyType(initialValues.companyType);
      }

      if (initialValues.motherCompanyDocument) {
        form.validateFields(['motherCompanyDocument']);
      }

      if (initialValues.hasFleet) {
        setIsFleetCheckboxChecked(initialValues.hasFleet);
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (foundMotherCompany) {
      form.setFieldsValue({
        modalMotherCompanyType: foundMotherCompany.companyType,
        modalMotherCompanyDocument: Formatter.formatCNPJ(
          foundMotherCompany.documentNumber
        ),
        modalMotherCompanyTradeName: foundMotherCompany.tradeName,
        modalMotherCompanyName: foundMotherCompany.companyName,
      });
    } else {
      form.setFieldsValue({
        modalMotherCompanyType: 'Parent',
        modalMotherCompanyDocument: form.getFieldValue('motherCompanyDocument'),
        modalMotherCompanyTradeName: '',
        modalMotherCompanyName: '',
      });
    }
  }, [isCreateMotherCompanyModalVisible, foundMotherCompany]);

  useEffect(() => {
    form.setFieldsValue({ motherCompany: foundMotherCompany });
  }, [foundMotherCompany]);

  useEffect(() => {
    if (companyDataAction === 'complete' && foundCompany) {
      form.setFieldsValue({
        id: foundCompany.id,
        companyName: foundCompany.companyName,
        companyTradeName: foundCompany.tradeName,
      });

      notification.info({
        message: t(
          `${companyDataTextPath}.${selectedCompanyType}.notifications.preRegisteredCompany.message`
        ),
        description: t(
          `${companyDataTextPath}.${selectedCompanyType}.notifications.preRegisteredCompany.description`
        ),
        duration: 15,
      });
    }
  }, [companyDataAction]);

  const companyDataValidations = useCompanyDataValidations({
    form,
    updatingCompanyId: initialValues?.companyId,
    companyDataTextPath,
    foundCompany,
    foundMotherCompany,
    selectedCompanyType,
    selectedCompanyFunction,
    setFoundCompany,
    setFoundMotherCompany,
    setCompanyDataAction,
    setIsCreateMotherCompanyModalVisible,
    setCreateMotherCompanyModalAction,
  });

  const {
    normalizeCompanyDocument,
    companyDocumentDependencies,
    companyDocumentRules,
    motherCompanyDocumentRules,
  } = companyDataValidations[selectedCompanyType];

  const supplementaryData = (
    <>
      <Form.Item name={'id'} />
      <Form.Item name={'motherCompany'} />
      <Form.Item name={'urlLogo'} />
    </>
  );

  const motherCompanyData = (
    <>
      <InputText
        formItem
        validation
        size="large"
        validateFirst
        name="motherCompanyDocument"
        key={'motherCompanyDocument_' + selectedCompanyType}
        label={t(
          `${companyDataTextPath}.${selectedCompanyType}.fields.labels.motherCompanyDocument`
        )}
        placeholder={t(
          `${companyDataTextPath}.${selectedCompanyType}.fields.placeholders.motherCompanyDocument`
        )}
        normalize={Formatter.formatCNPJ}
        otherRules={motherCompanyDocumentRules}
        otherPropsFormItem={{
          dependencies: ['companyType'],
          hasFeedback: true,
        }}
        onChange={() => {
          form.setFieldsValue({
            motherCompanyName: '',
          });

          setCreateMotherCompanyModalAction('none');
        }}
      />
      <InputText
        formItem
        size="large"
        name="motherCompanyName"
        readOnly
        style={
          createMotherCompanyModalAction !== 'none'
            ? { cursor: 'pointer' }
            : { cursor: 'default' }
        }
        onClick={() =>
          createMotherCompanyModalAction !== 'none'
            ? setIsCreateMotherCompanyModalVisible(true)
            : null
        }
        label={t(
          `${companyDataTextPath}.${selectedCompanyType}.fields.labels.motherCompanyName`
        )}
        placeholder={t(
          `${companyDataTextPath}.${selectedCompanyType}.fields.placeholders.motherCompanyName`
        )}
      />
    </>
  );

  const handleCompanyTypeChange = (value: ICompanyType) => {
    setSelectedCompanyType(value);

    form.setFieldsValue({
      motherCompanyName: '',
      motherCompany: '',
    });

    setCreateMotherCompanyModalAction('none');
    setFoundMotherCompany(undefined);
  };

  const handleCompanyDocumentChange = () => {
    if (companyDataAction === 'complete') {
      form.setFieldsValue({
        id: undefined,
        companyName: undefined,
        companyTradeName: undefined,
      });

      setCompanyDataAction('create');
    }
  };

  const handleCreateMotherCompanyModalConfirm = ({
    modalMotherCompanyDocument,
    modalMotherCompanyType,
    modalMotherCompanyTradeName,
    modalMotherCompanyName,
  }: ICreateMotherCompanyFieldValues) => {
    setFoundMotherCompany(foundMotherCompany => {
      if (foundMotherCompany?.id) {
        return foundMotherCompany;
      } else {
        const clearModalMotherCompanyDocument =
          `${modalMotherCompanyDocument}`.replace(/\D/g, '');

        form.setFieldsValue({
          motherCompanyName: modalMotherCompanyTradeName,
        });

        return {
          companyFunction: selectedCompanyFunction,
          companyType: modalMotherCompanyType,
          documentNumber: clearModalMotherCompanyDocument,
          tradeName: modalMotherCompanyTradeName.trim(),
          companyName: modalMotherCompanyName.trim(),
          companyStatus: 'waitingData',
        };
      }
    });

    setIsCreateMotherCompanyModalVisible(false);
  };

  const createMotherCompanyModalProps = {
    form: form,
    action: createMotherCompanyModalAction,
    onClose: () => setIsCreateMotherCompanyModalVisible(false),
    onConfirm: handleCreateMotherCompanyModalConfirm,
    isVisible: isCreateMotherCompanyModalVisible,
    translationTextPath: companyDataTextPath,
  };

  const handleFleetCheckboxChange = () => {
    const { hasFleet } = form.getFieldsValue(['hasFleet']);

    if (!hasFleet) {
      form.resetFields(['fleetQuantity']);
    }

    setIsFleetCheckboxChecked(hasFleet);
  };

  const fleetQuantityValidation = {
    validator: (_: unknown, value: string) => {
      const clearValue = `${value}`.replace(/\D/g, '');

      const { hasFleet } = form.getFieldsValue(['hasFleet']);

      if (!hasFleet || Number(clearValue) > 0) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
    message: `${companyDataTextPath}.fields.rules.minimumFleetQuantity`,
  };

  const carrierCompanyData = (
    <Row>
      <Form.Item
        name="hasFleet"
        valuePropName="checked"
        style={{ justifyContent: 'center' }}
      >
        <Checkbox onChange={handleFleetCheckboxChange}>
          {t(`${companyDataTextPath}.fields.labels.hasFleet`)}
        </Checkbox>
      </Form.Item>
      <InputText
        formItem
        size="large"
        validation
        validateFirst
        required={false}
        name="fleetQuantity"
        label={t(`${companyDataTextPath}.fields.labels.fleetQuantity`)}
        disabled={!isFleetCheckboxChecked}
        placeholder={t(
          `${companyDataTextPath}.fields.placeholders.fleetQuantity`
        )}
        normalize={Normalizer.onlyNumbers}
        otherRules={[fleetQuantityValidation]}
        otherPropsFormItem={{ dependencies: ['hasFleet'] }}
      />
    </Row>
  );

  return (
    <FormSection sectionTitle={t(`${companyDataTextPath}.title`)}>
      <>
        <Row>
          <Select
            formItem
            validation
            size="large"
            name="companyType"
            options={companyTypeOptions}
            disabled={companyDataAction === 'complete'}
            label={t(`${companyDataTextPath}.fields.labels.companyType`)}
            placeholder={t(
              `${companyDataTextPath}.fields.placeholders.companyType`
            )}
            initialValue={selectedCompanyType}
            onChange={value => handleCompanyTypeChange(value as ICompanyType)}
          />
          {selectedCompanyType !== 'Parent' && motherCompanyData}
        </Row>
        {selectedCompanyFunction === 'carrier' && carrierCompanyData}
        <Row>
          <InputText
            formItem
            validation
            size="large"
            validateFirst
            name="companyDocument"
            key={'companyDocument_' + selectedCompanyType}
            onChange={handleCompanyDocumentChange}
            otherPropsFormItem={{
              dependencies: companyDocumentDependencies,
              hasFeedback: true,
            }}
            label={t(
              `${companyDataTextPath}.${selectedCompanyType}.fields.labels.companyDocument`
            )}
            placeholder={t(
              `${companyDataTextPath}.${selectedCompanyType}.fields.placeholders.companyDocument`
            )}
            normalize={normalizeCompanyDocument}
            otherRules={companyDocumentRules}
          />
          <InputText
            formItem
            validation
            size="large"
            name="companyTradeName"
            disabled={companyDataAction === 'complete'}
            label={t(
              `${companyDataTextPath}.${selectedCompanyType}.fields.labels.companyTradeName`
            )}
            placeholder={t(
              `${companyDataTextPath}.${selectedCompanyType}.fields.placeholders.companyTradeName`
            )}
            otherRules={[
              {
                max: 100,
                message: `${companyDataTextPath}.fields.rules.tradeNameLength`,
              },
            ]}
          />
          <InputText
            formItem
            validation
            size="large"
            name="companyName"
            disabled={companyDataAction === 'complete'}
            label={t(
              `${companyDataTextPath}.${selectedCompanyType}.fields.labels.companyName`
            )}
            placeholder={t(
              `${companyDataTextPath}.${selectedCompanyType}.fields.placeholders.companyName`
            )}
            otherRules={[
              {
                max: 100,
                message: `${companyDataTextPath}.fields.rules.companyNameLength`,
              },
            ]}
          />
        </Row>
        <Col
          style={{
            height: '100%',
            marginBottom: 24,
            maxWidth: '800px',
            width: '100%',
          }}
        >
          <CompanyDataLogoUpload
            form={form}
            userId={userId}
            companyId={initialValues?.companyId}
            defaultFileUrl={initialValues?.uploadDefaultFileUrl}
            companyDataTextPath={companyDataTextPath}
            uploadLogoImageRequest={uploadLogoImageRequest}
            deleteLogoImageRequest={deleteLogoImageRequest}
          />
        </Col>
        {isCreateMotherCompanyModalVisible && (
          <CreateMotherCompanyModal {...createMotherCompanyModalProps} />
        )}
        <Row style={{ display: 'none' }}>{supplementaryData}</Row>
      </>
    </FormSection>
  );
};

export default CompanyData;
