import { GroupOutlined, InboxOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import {
  MenuDividerType,
  MenuItemGroupType,
  MenuItemType,
  SubMenuType,
} from 'antd/lib/menu/hooks/useItems';
import { TFunction } from 'i18next';
import { Key } from 'react';
import AccountIcon from '../../../../assets/Icons/AccountIcon/AccountIcon';
import CompanyIcon from '../../../../assets/Icons/CompanyIcon/CompanyIcon';
import HomeIcon from '../../../../assets/Icons/HomeIcon/HomeIcon';
import TruckIconComponent from '../../../../assets/Icons/TruckIcon/TruckIcon';
import { isAccessAllowed } from '../../../../components/Access/AccessUtils';
import { IPermission } from '../../../../contexts/AuthContext/AuthContext';

interface ISubMenu extends Omit<SubMenuType, 'children'> {
  children: IAccessItem[];
}
interface IMenuItemGroup extends Omit<MenuItemGroupType, 'children'> {
  children?: IAccessItem[];
}

type IAccessItem = (
  | MenuItemType
  | ISubMenu
  | IMenuItemGroup
  | MenuDividerType
) & {
  acceptedPermissions?: IPermission[];
};

export const getMenuItems = (t: TFunction): IAccessItem[] => {
  const menuItems: IAccessItem[] = [
    {
      label: t('tab.home'),
      icon: <HomeIcon />,
      key: '/home',
    },
    {
      label: t('tab.support.accounts.index'),
      key: '/support/accounts',
      icon: <CompanyIcon />,
      children: [
        {
          label: t('tab.support.accounts.companies.index'),
          key: '/support/accounts/companies',
          acceptedPermissions: [
            { module: 'APPROVE_COMPANIES', actions: ['CREATE'] },
          ],
        },
        {
          label: (
            <Tooltip
              title={t('tab.support.accounts.companies.companyValidation')}
            >
              {t('tab.support.accounts.companies.companyValidation')}
            </Tooltip>
          ),
          key: '/support/accounts/companies/companyValidation',
          acceptedPermissions: [
            { module: 'APPROVE_COMPANIES', actions: ['CREATE'] },
          ],
        },
        {
          label: t('tab.support.accounts.users.index'),
          key: '/support/accounts/users',
          acceptedPermissions: [
            { module: 'TRUCKER_USERS', actions: ['CREATE'] },
          ],
        },
      ],
    },
    {
      label: t('tab.freight.index'),
      icon: <TruckIconComponent />,
      key: '/shipper',
      children: [
        {
          label: t('tab.shipper.freight.index'),
          key: '/shipper/freights',
          acceptedPermissions: [
            {
              module: 'FREIGHTS',
              actions: ['CREATE'],
            },
          ],
        },
        {
          label: t('tab.freight.places'),
          key: '/shipper/places',
          acceptedPermissions: [
            {
              module: 'PLACES',
              actions: ['CREATE'],
            },
          ],
        },
        {
          label: t('tab.freight.routes'),
          key: '/shipper/routes',
          acceptedPermissions: [
            {
              module: 'ROUTES',
              actions: ['CREATE'],
            },
          ],
        },
      ],
    },
    {
      label: t('tab.shipper.quotationGroups.index'),
      icon: <GroupOutlined />,
      key: '/shipper/quotationGroups',
      acceptedPermissions: [
        {
          module: 'GROUP',
          actions: ['CREATE'],
        },
      ],
    },
    {
      label: t('tab.carrier.freight.index'),
      icon: <TruckIconComponent />,
      key: '/carrier/freights',
      acceptedPermissions: [
        {
          module: 'QUOTATION_CONFIRMATIONS',
          actions: ['CREATE'],
        },
      ],
    },
    {
      label: t('tab.users.userDetails'),
      icon: <AccountIcon />,
      key: '/user',
    },
    {
      label: t('tab.product.index'),
      icon: <InboxOutlined />,
      key: '/support',
      children: [
        {
          label: t('tab.product.products'),
          key: '/support/products',
          acceptedPermissions: [
            {
              module: 'PRODUCTS',
              actions: ['CREATE'],
            },
          ],
        },
        {
          label: t('tab.product.productTypes'),
          key: '/support/productTypes',
          acceptedPermissions: [
            {
              module: 'PRODUCT_TYPES',
              actions: ['CREATE'],
            },
          ],
        },
      ],
    },
  ];

  return menuItems;
};

export const getAllowedMenuItems = (
  menuItems: IAccessItem[],
  permissions: IPermission[] | null | undefined
): IAccessItem[] => {
  if (!permissions) return [];

  const allowedMenuItens = menuItems.reduce(
    (allowedMenuItems, { acceptedPermissions, ...menuItem }) => {
      if ('children' in menuItem) {
        if (menuItem.children?.length) {
          const allowedChildren = getAllowedMenuItems(
            menuItem.children,
            permissions
          );

          if (allowedChildren.length) {
            return [
              ...allowedMenuItems,
              { ...menuItem, children: allowedChildren },
            ];
          }
        }

        return allowedMenuItems;
      } else {
        if (!acceptedPermissions) return [...allowedMenuItems, menuItem];

        const allowedPermissions = acceptedPermissions.reduce(
          (allowedPermissions, acceptedPermission) => {
            const accessAllowed = isAccessAllowed({
              acceptedPermission,
              claimedPermissions: permissions,
            });

            if (!accessAllowed) return allowedPermissions;

            return [...allowedPermissions, accessAllowed];
          },
          [] as IPermission[]
        );

        if (!allowedPermissions.length) return allowedMenuItems;

        return [...allowedMenuItems, menuItem];
      }
    },
    [] as IAccessItem[]
  );

  return allowedMenuItens;
};

export const getMenuItemPath = (
  menuItems: IAccessItem[],
  key: Key
): string[] => {
  const menuItemKeyPath = [] as Key[];

  const findNestedMenuItem = (
    menuItems: IAccessItem[],
    key: Key
  ): Key | null => {
    const menuItem = menuItems.find(menuItem => {
      if ('children' in menuItem && menuItem.children?.length) {
        return findNestedMenuItem(menuItem.children, key);
      } else {
        return menuItem.key === key;
      }
    });

    if (menuItem?.key) {
      menuItemKeyPath.push(menuItem.key);
      return menuItem.key;
    }

    return null;
  };

  findNestedMenuItem(menuItems, key);

  return menuItemKeyPath.map(String);
};
