import {
  Button,
  Card,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FindProductTypesQuery } from '../../../../graphql/generated/graphql';
import { Access } from '../../../Access/Access';

export type IProductTypeTableItem = Pick<
  FindProductTypesQuery['FindManyProductTypes'][0],
  'id' | 'name'
>;

interface IProductTypesTableProps {
  /**
   * Function to be execute when Create button is clicked
   */
  onCreate?: () => void;
  /**
   * Control whether the loading spinners must be showed
   */
  loading?: boolean;
  /**
   * Function to be execute when Delete button is clicked
   */
  onDelete?: (productTypeId: string) => void;
  /**
   * Function to be execute when View button is clicked
   */
  onView?: (productTypeId: string) => void;
  /**
   * Data to be showed in the table
   */
  productTypes?: IProductTypeTableItem[];
}

/**
 * Table to show and manage Product Types
 */
export const ProductTypesTable = ({
  onCreate,
  loading,
  onDelete,
  onView,
  productTypes,
}: IProductTypesTableProps) => {
  const PRODUCT_TYPES_TABLE_TRANSLATION_PATH =
    'components.product.productType.productTypesTable';

  const { t } = useTranslation();

  const CreateProductTypeButton = () => (
    <Button type="primary" onClick={onCreate}>
      {t(`${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.actions.create`)}
    </Button>
  );

  const DeleteProductTypeButton = ({ id }: { id: string }) => (
    <Popconfirm
      onConfirm={() => onDelete && onDelete(id)}
      okText={t('general.yes')}
      okButtonProps={{ danger: true }}
      title={t(
        `${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.actions.delete.confirm`
      )}
    >
      <Button danger type="link">
        {t(`${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.actions.delete.button`)}
      </Button>
    </Popconfirm>
  );

  const CreateProductTypeButtonAccess = Access(CreateProductTypeButton, {
    acceptedPermissions: [{ module: 'PRODUCT_TYPES', actions: ['CREATE'] }],
  });

  const DeleteProductTypeButtonAccess = Access(DeleteProductTypeButton, {
    acceptedPermissions: [{ module: 'PRODUCT_TYPES', actions: ['DELETE'] }],
  });

  const title = (
    <Row align="middle" justify="space-between" style={{ rowGap: 12 }}>
      <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
        {t(`${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.title`)}
      </Typography.Text>
      <CreateProductTypeButtonAccess />
    </Row>
  );

  const columns: TableColumnsType<IProductTypeTableItem> = [
    {
      title: t(`${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.columns.name`),
      dataIndex: 'name',
    },
    {
      title: t(`${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.columns.actions`),
      dataIndex: 'actions',
      render(_, { id }) {
        return (
          <Space>
            <Button type="link" onClick={() => onView && onView(id)}>
              {t(`${PRODUCT_TYPES_TABLE_TRANSLATION_PATH}.actions.view`)}
            </Button>
            <DeleteProductTypeButtonAccess id={id} />
          </Space>
        );
      },
    },
  ];

  return (
    <Card title={title}>
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={productTypes}
        loading={loading}
        size="small"
        rowKey={productType => productType.id}
      />
    </Card>
  );
};
