import { Select, SelectProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInternalAvailableRolesEnum } from '../../../../../../../graphql/generated/graphql';

export type IUserRoleSelectProps = Omit<SelectProps, 'options' | 'children'>;

export const UserRoleSelect = (props: IUserRoleSelectProps): ReactElement => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = Object.values(
    UserInternalAvailableRolesEnum
  ).map(key => ({
    label: t(`enums.userRoles.${key}`),
    value: key,
    disabled: key === UserInternalAvailableRolesEnum.Quotator,
  }));

  return (
    <Select options={options} placeholder={t('general.select')} {...props} />
  );
};
