import { useTranslation } from 'react-i18next';
import { UnitTypeEnum } from '../../../../graphql/generated/graphql';
import { VolumeInputUtils } from '../VolumeInput/VolumeInput.utils';

export interface IVolumeDisplayProps {
  volume?: string | number;
  unitType?: UnitTypeEnum;
}

export const VolumeDisplay = ({
  unitType,
  volume = 0,
}: IVolumeDisplayProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'enums.unitTypeLong',
  });

  const numberVolume = Number(volume) || 0;

  const formattedVolume = VolumeInputUtils.formatVolumeDisplay(
    volume,
    unitType
  );

  if (!formattedVolume || !unitType) return null;

  return (
    <>
      {formattedVolume} {t(unitType, { count: numberVolume === 1 ? 1 : 0 })}
    </>
  );
};
