import { useTranslation } from 'react-i18next';

import { Descriptions, Typography } from 'antd';

import Formatter from '../../../../classes/Formatter';
import { CompanyDataDescriptionsFragment } from '../../../../graphql/generated/graphql';
import { CompanyFunctionReverseEnum } from '../../../../structures/interfaces/Company/CompanyFunction';
import { CompanyTypeReverseEnum } from '../../../../structures/interfaces/Company/CompanyType';

const { Text } = Typography;

export const CompanyDataDescriptions = ({
  companyData,
}: {
  companyData: CompanyDataDescriptionsFragment;
}) => {
  const COMPANY_DATA_DESCRIPTIONS_TRANSLATION_PATH =
    'components.company.companyDescriptions.companyDataDescriptions';

  const { t } = useTranslation();

  return (
    <Descriptions>
      <Descriptions.Item
        span={3}
        label={t(
          `${COMPANY_DATA_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.documentNumber`
        )}
      >
        <Text copyable={{ text: companyData.document_number }}>
          {Formatter.formatCPF_CNPJ(companyData.document_number)}
        </Text>
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        label={t(
          `${COMPANY_DATA_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.companyType`
        )}
      >
        {t(
          `enums.companyType.${
            CompanyTypeReverseEnum[companyData.company_type]
          }`
        )}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={t(
          `${COMPANY_DATA_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.companyFunction`
        )}
      >
        {t(
          `enums.companyFunction.${
            CompanyFunctionReverseEnum[companyData.company_function]
          }`
        )}
      </Descriptions.Item>
      <Descriptions.Item
        span={3}
        label={t(
          `${COMPANY_DATA_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.tradeName`
        )}
      >
        {companyData.trade_name}
      </Descriptions.Item>
      <Descriptions.Item
        span={3}
        label={t(
          `${COMPANY_DATA_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.companyName`
        )}
      >
        {companyData.company_name}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CompanyDataDescriptions;
