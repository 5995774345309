import { Card, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import Select, { DefaultOptionType } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { useCreateFreightContext } from '../../../../../contexts/Freight/CreateFreightContext/useCreateFreightContext';
import { Volume } from '../../../../../core/Freight/Volume';
import { Price } from '../../../../../core/Price';
import { UnitTypeEnum } from '../../../../../graphql/generated/graphql';
import { useDebounceState } from '../../../../../hooks/useDebounce/useDebounce';
import { filterOptionByLabel } from '../../../../../utils';
import { ProductSelect } from '../../../../Product/Product/ProductSelect/ProductSelect';
import { ProductTypeSelect } from '../../../../Product/ProductType/ProductTypeSelect/ProductTypeSelect';
import { useProductVarietySelectOptions } from '../../../../Product/ProductVariety/ProductVariety.hooks';
import { CREATE_FREIGHT_FORM_TRANSLATION_PATH } from '../CreateFreightForm';

export interface IFreightLoadFormValues {
  note_value?: string;
  observation?: string | null;
  packing_id: { value: string; label: string };
  product_id: { value: string; label: string };
  product_type_id: { value: string; label: string };
  product_variety_id?: { value: string; label: string };
  suggested_value?: string | null;
  total_volume: string | number;
  unit_type: UnitTypeEnum;
}

export interface IFreightLoadFormProps {
  disabled?: boolean;
  form: FormInstance<IFreightLoadFormValues>;
  packages: {
    fetching: boolean;
    options: DefaultOptionType[] | undefined;
  };
}

export const FreightLoadForm = ({
  form,
  disabled,
  packages,
}: IFreightLoadFormProps) => {
  const { t } = useTranslation();

  const { createFreightValues } = useCreateFreightContext();

  const selectedProductType = Form.useWatch<DefaultOptionType | undefined>(
    'product_type_id',
    form
  );

  const selectedProduct = Form.useWatch<DefaultOptionType | undefined>(
    'product_id',
    form
  );

  const selectedUnitType = Form.useWatch<UnitTypeEnum | undefined>(
    'unit_type',
    form
  );

  const selectedProductTypeId = selectedProductType?.value
    ? String(selectedProductType.value)
    : undefined;

  const selectedProductId = selectedProduct?.value
    ? String(selectedProduct.value)
    : undefined;

  const [productVarietySearch, setProductVarietySearch] =
    useDebounceState<string>();

  const { options: productVarietyOptions } = useProductVarietySelectOptions({
    productId: selectedProductId,
    search: productVarietySearch,
  });

  const freightLoadFormTitle = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.loadSubFormTitle`)}
      </Typography.Text>
    </Row>
  );

  const colSpan = {
    xxl: 4,
    xl: 6,
    lg: 8,
    md: 12,
    sm: 12,
    xs: 24,
  };

  return (
    <Card title={freightLoadFormTitle} bordered={false}>
      <Form
        disabled={disabled}
        initialValues={createFreightValues}
        form={form}
        layout="vertical"
        scrollToFirstError
        size="small"
      >
        <Row gutter={[24, 0]} align="bottom">
          <Col {...colSpan}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.product_type_id`
              )}
              name="product_type_id"
              required
              rules={[{ required: true }]}
              validateFirst
            >
              <ProductTypeSelect
                disabled={disabled}
                filterOption={filterOptionByLabel}
                getPopupContainer={trigger => trigger.parentElement || trigger}
                labelInValue
                listHeight={128}
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.product_type_id`
                )}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.product_id`
              )}
              name="product_id"
              required
              rules={[{ required: true }]}
              validateFirst
            >
              <ProductSelect
                disabled={!selectedProductType?.value || disabled}
                filterOption={filterOptionByLabel}
                labelInValue
                listHeight={128}
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.product_id`
                )}
                productTypeId={selectedProductTypeId}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.product_variety_id`
              )}
              name="product_variety_id"
            >
              <Select
                allowClear
                defaultValue={undefined}
                disabled={!selectedProduct?.value || disabled}
                filterOption={filterOptionByLabel}
                labelInValue
                listHeight={128}
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.product_variety_id`
                )}
                onSearch={setProductVarietySearch}
                options={productVarietyOptions}
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]} align="bottom">
          <Col {...colSpan}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.packing_id`
              )}
              name="packing_id"
              required
              rules={[{ required: true }]}
              validateFirst
            >
              <Select
                disabled={!selectedProductType || disabled}
                filterOption={filterOptionByLabel}
                labelInValue
                listHeight={128}
                options={packages.options}
                loading={packages.fetching}
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.packing_id`
                )}
                showSearch
              />
            </Form.Item>
            <Form.Item name="unit_type" noStyle>
              <Input hidden />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Volume.FormItem
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.total_volume`
              )}
              dependencies={['packing_id']}
              name="total_volume"
              required
              unitType={selectedUnitType}
              validateFirst
            >
              <Volume.Input
                disabled={!selectedUnitType}
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.total_volume`
                )}
                style={{ width: '100%' }}
                unitType={selectedUnitType}
              />
            </Volume.FormItem>
          </Col>
          <Col xs={0} lg={8} xl={0} />
          <Col {...colSpan}>
            <Price.FormItem
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.note_value`
              )}
              name="note_value"
            >
              <Price.Input
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.note_value`
                )}
              />
            </Price.FormItem>
          </Col>
          <Col {...colSpan}>
            <Price.FormItem
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.suggested_value`
              )}
              tooltip={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.tooltips.suggested_value`
              )}
              name="suggested_value"
            >
              <Price.Input
                placeholder={t(
                  `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.placeholders.suggested_value`
                )}
              />
            </Price.FormItem>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.observation`
              )}
              name="observation"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
