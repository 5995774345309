import { Navigate, Route } from 'react-router-dom';
import SignUpRoutes from './SignUpRoutes/SignUpRoutes';

export const PublicRoutes = (
  <>
    {SignUpRoutes}

    <Route path="*" element={<Navigate to="/" replace />} />
  </>
);
