import {
  Button,
  Card,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaceTableItemFragment } from '../../../../graphql/generated/graphql';
import { roundWithDecimals } from '../../../../utils';
import { Access } from '../../../Access/Access';

interface IPlaceTableProps {
  /**
   * Function to execute when View action is clicked
   */
  handleView: (placeId: string) => void;
  /**
   * Function to execute when Update action is clicked
   */
  handleUpdate: (placeId: string) => void;
  /**
   * Function to execute when Delete place action is clicked
   */
  handleDelete: (placeId: string) => void;
  /**
   * Function to execute when Create action is clicked
   */
  handleCreate: () => void;
  /**
   * Places returned by query in father component
   */
  filteredPlaces?: PlaceTableItemFragment[];
  loading?: boolean;
}

/**
 * Table to view and manage places
 */

export const PlaceTable = ({
  handleView,
  handleUpdate,
  handleDelete,
  handleCreate,
  filteredPlaces,
  loading,
}: IPlaceTableProps): ReactElement => {
  const PLACE_TABLE_TRANSLATION_PATH = 'components.freight.place.placeTable';

  const { t } = useTranslation();

  const formatDirtRoadDistance = (dirtRoadDistance?: number) => {
    if (dirtRoadDistance) {
      const dirtRoadDistanceInKilometers = roundWithDecimals(
        dirtRoadDistance / 1000,
        3
      );

      return `${dirtRoadDistanceInKilometers.toLocaleString()} ${t(
        'enums.measureUnits.length.kilometer.initials'
      )}`;
    }
    return t('general.none');
  };

  const PlaceCreateButton = () => (
    <Button type="primary" onClick={handleCreate}>
      {t(`${PLACE_TABLE_TRANSLATION_PATH}.actions.create`)}
    </Button>
  );

  const PlaceUpdateButton = ({ id }: { id: string }) => (
    <Button type="link" onClick={() => handleUpdate(id)}>
      {t(`${PLACE_TABLE_TRANSLATION_PATH}.actions.update`)}
    </Button>
  );

  const PlaceDeleteButton = ({ id }: { id: string }) => (
    <Popconfirm
      onConfirm={() => {
        handleDelete(id);
      }}
      title={t(`${PLACE_TABLE_TRANSLATION_PATH}.actions.delete.popconfirm`)}
    >
      <Button type="link" danger>
        {t(`${PLACE_TABLE_TRANSLATION_PATH}.actions.delete.button`)}
      </Button>
    </Popconfirm>
  );

  const PlaceCreateButtonAccess = Access(PlaceCreateButton, {
    acceptedPermissions: [{ module: 'PLACES', actions: ['CREATE'] }],
  });

  const PlaceUpdateButtonAccess = Access(PlaceUpdateButton, {
    acceptedPermissions: [{ module: 'PLACES', actions: ['UPDATE'] }],
  });

  const PlaceDeleteButtonAccess = Access(PlaceDeleteButton, {
    acceptedPermissions: [{ module: 'PLACES', actions: ['DELETE'] }],
  });

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${PLACE_TABLE_TRANSLATION_PATH}.title`)}
      </Typography.Text>
      <PlaceCreateButtonAccess />
    </Row>
  );

  const columns: TableColumnsType<PlaceTableItemFragment> = [
    {
      dataIndex: 'name',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.name`),
    },
    {
      dataIndex: 'state_id',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.state_id`),
    },
    {
      dataIndex: 'city_name',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.city_name`),
    },
    {
      dataIndex: 'cadence',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.cadence`),
      render: cadence => <>{cadence || t('general.none')}</>,
    },
    {
      dataIndex: 'dirt_road_distance',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.dirt_road_distance`),
      render: dirtRoadDistance => (
        <>{formatDirtRoadDistance(dirtRoadDistance)}</>
      ),
    },
    {
      dataIndex: 'has_balance',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.has_balance`),
      render: hasBalance => (
        <>{hasBalance ? t('general.yes') : t('general.no')}</>
      ),
    },
    {
      dataIndex: 'note',
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.note`),
      render: note => (
        <Typography.Paragraph
          ellipsis={{ expandable: true }}
          style={{ width: 300 }}
        >
          {note}
        </Typography.Paragraph>
      ),
    },
    {
      title: t(`${PLACE_TABLE_TRANSLATION_PATH}.columns.actions`),
      render: (_, { id }) => (
        <Space>
          <Button type="link" onClick={() => handleView(id)}>
            {t(`${PLACE_TABLE_TRANSLATION_PATH}.actions.view`)}
          </Button>
          <PlaceUpdateButtonAccess id={id} />
          <PlaceDeleteButtonAccess id={id} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card title={title}>
        <Table
          className="ant-table-wrapper-responsive"
          columns={columns}
          dataSource={filteredPlaces}
          loading={loading}
          pagination={{ hideOnSinglePage: true }}
          rowKey={place => place.id}
        />
      </Card>
    </>
  );
};
