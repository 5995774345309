import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SignUpMessage from '..';
import CloseCircleOutlined from '../../../../assets/IconComponents/CloseCircleOutlined';
import { IUser } from '../../../../structures/interfaces/User';
import ReproachDescription from './reproachDescription';

import './index.scss';

export const AccountReproved = (): ReactElement<unknown> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as { user?: IUser } | undefined;

  const handleConfirm = () => {
    navigate('/regularizeCompany', { state: locationState });
  };

  if (!locationState?.user?.company) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <SignUpMessage
      buttonAction={handleConfirm}
      image={<CloseCircleOutlined />}
      className="sign-up_account-reproved"
      title={t('pages.signUp.messages.accountReproved.title')}
      buttonText={t('pages.signUp.messages.accountReproved.buttonText')}
    >
      <ReproachDescription
        action="reproved"
        companyId={locationState.user.company.id}
      />
    </SignUpMessage>
  );
};

export default AccountReproved;
