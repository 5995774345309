import {
  Button,
  Card,
  Form,
  Row,
  Space,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CarrierQuotingFreightFragment } from '../../../../graphql/generated/graphql';
import { Access } from '../../../Access/Access';
import { FormattedCountdown } from '../../../Formatters/FormattedCountdown/FormattedCountdown';
import { FormattedDate } from '../../../Formatters/FormattedDate/FormattedDate';
import { Volume } from '../../../../core/Freight/Volume';
import { FormattedRoute } from '../../../Formatters/Freight/FormattedRoute/FormattedRoute';
import { ProductWithVariety } from '../../../Product/Product/ProductWithVariety';

interface ICarrierQuotingFreightTableProps {
  freights?: CarrierQuotingFreightFragment[];
  loading?: boolean;
  onView: (id: string) => void;
  pagination?: TableProps<CarrierQuotingFreightFragment>['pagination'];
}

export const CarrierQuotingFreightTable = ({
  freights,
  loading,
  onView,
  pagination,
}: ICarrierQuotingFreightTableProps) => {
  const CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH =
    'components.freight.carrier.carrierQuotingFreightTable';

  const { t } = useTranslation();

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.title`)}
      </Typography.Text>
    </Row>
  );

  const ViewButton = ({ id }: { id: string }) => (
    <Button type="link" onClick={() => onView(id)}>
      {t(`${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.actions.view`)}
    </Button>
  );

  const ViewButtonAccess = Access(ViewButton, {
    acceptedPermissions: [{ module: 'QUOTATIONS', actions: ['CREATE'] }],
  });

  const columns: TableColumnsType<CarrierQuotingFreightFragment> = [
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.route`
      ),
      dataIndex: 'route',
      render: (route: CarrierQuotingFreightFragment['route']) => (
        <div style={{ minWidth: 200 }}>
          <FormattedRoute route={route} />
        </div>
      ),
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.shipment_date`
      ),
      dataIndex: 'shipment_date',
      render: shipmentDate => <FormattedDate dateTime={shipmentDate} />,
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.landing_date`
      ),
      dataIndex: 'landing_date',
      render: landingDate => <FormattedDate dateTime={landingDate} />,
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product_type`
      ),
      dataIndex: ['product_type', 'name'],
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.product`
      ),
      dataIndex: ['product', 'name'],
      render: (_, freight) => (
        <>
          {ProductWithVariety.getProductWithVariety({
            product: freight.product,
            product_variety: freight.product_variety,
          })}
        </>
      ),
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.packing`
      ),
      dataIndex: ['packing', 'name'],
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.total_volume`
      ),
      dataIndex: 'total_volume',
      render: (totalVolume, freight) => (
        <div style={{ minWidth: 90 }}>
          <Volume.Display volume={totalVolume} unitType={freight.unit_type} />
        </div>
      ),
    },
    {
      title: (
        <Form.Item
          label={t(
            `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.quote_limit`
          )}
          tooltip={t(
            `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.tooltips.quote_limit`
          )}
          style={{ margin: 0 }}
          colon={false}
        />
      ),
      dataIndex: 'quote_limit',
      render: quoteLimitDate => (
        <div style={{ minWidth: 100 }}>
          <FormattedCountdown dateTime={quoteLimitDate} />
        </div>
      ),
    },
    {
      title: t(
        `${CARRIER_QUOTING_FREIGHT_TABLE_TRANSLATION_PATH}.columns.actions`
      ),
      dataIndex: 'actions',
      render: (_, { id }) => (
        <Space>
          <ViewButtonAccess id={id} />
        </Space>
      ),
    },
  ];

  return (
    <Card title={title}>
      <Table
        className="ant-table-wrapper-responsive"
        columns={columns}
        dataSource={freights}
        loading={loading}
        rowKey={freight => freight.id}
        pagination={pagination}
      />
    </Card>
  );
};
