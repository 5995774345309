import { useTranslation } from 'react-i18next';

import { Descriptions } from 'antd';

import Formatter from '../../../../classes/Formatter';
import { CompanyAddressDescriptionsFragment } from '../../../../graphql/generated/graphql';

export const CompanyAddressDescriptions = ({
  companyAddress,
}: {
  companyAddress: CompanyAddressDescriptionsFragment;
}) => {
  const COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH =
    'components.company.companyDescriptions.companyAddressDescriptions';

  const { t } = useTranslation();

  return (
    <Descriptions>
      <Descriptions.Item
        label={t(
          `${COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.zipCode`
        )}
      >
        {companyAddress.zip_code_address
          ? Formatter.formatZipCode(companyAddress.zip_code_address)
          : '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `${COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.cityName`
        )}
      >
        {companyAddress.city.city_name}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `${COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.stateInitials`
        )}
      >
        {companyAddress.state?.initials}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `${COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.districtName`
        )}
      >
        {companyAddress.district?.district_name}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `${COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.street`
        )}
      >
        {companyAddress.street}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `${COMPANY_ADDRESS_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.number`
        )}
      >
        {companyAddress.number}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CompanyAddressDescriptions;
