import { Button, Descriptions, Modal, ModalProps, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CanceledQuotationModalQueryVariables,
  useCanceledQuotationModalQuery,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { QuotationDescriptions } from '../../QuotationDescriptions/QuotationDescriptions';

interface ICanceledQuotationModalProps extends Omit<ModalProps, 'onCancel'> {
  quotationId?: string;
  onCancel?: () => void;
}

const TRANSLATION_PATH = 'components.quotation.shipper.canceledQuotationModal';

export const CanceledQuotationModal = ({
  quotationId,
  ...modalProps
}: ICanceledQuotationModalProps) => {
  const { t } = useTranslation();

  const findCanceledQuotationVariables = quotationId
    ? ({ quotation_id: quotationId } as CanceledQuotationModalQueryVariables)
    : undefined;

  const [canceledQuotationModalResult] = useCanceledQuotationModalQuery({
    variables: findCanceledQuotationVariables,
    pause: !findCanceledQuotationVariables,
  });

  const { data, error, fetching } = canceledQuotationModalResult;

  const footer = (
    <Button size="small" onClick={modalProps.onCancel}>
      {t('general.close')}
    </Button>
  );

  return (
    <Modal
      width={'992px'}
      title={t(`${TRANSLATION_PATH}.title`)}
      footer={footer}
      centered
      {...modalProps}
    >
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />

      <QuotationDescriptions data={data?.FindQuotation} fetching={fetching} />

      <Descriptions column={1}>
        <Descriptions.Item
          label={t(`${TRANSLATION_PATH}.fields.labels.cancellationReason`)}
        >
          <Skeleton active loading={fetching} paragraph={false}>
            {data?.FindQuotation.cancellation &&
              data.FindQuotation.cancellation[0].cancellationReason.name}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item
          label={t(`${TRANSLATION_PATH}.fields.labels.description`)}
        >
          <Skeleton active loading={fetching} paragraph={false}>
            {data?.FindQuotation.cancellation &&
              data.FindQuotation.cancellation[0].description}
          </Skeleton>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
