import { ReactElement } from 'react';

import enUS from 'antd/locale/en_US';
import esES from 'antd/locale/es_ES';
import ptBR from 'antd/locale/pt_BR';

import { App, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import { ISupportedLocale } from '../../i18n/locales';
import { mainTheme } from '../../layouts/MainLayout/mainTheme';
import { useUserContext } from '../UserContext/useUserContext';

interface IAntDesignConfigProviderProps {
  children: JSX.Element;
}

export const AntDesignConfigProvider = ({
  children,
}: IAntDesignConfigProviderProps): ReactElement => {
  const { i18n } = useTranslation();

  const { companySettings } = useUserContext();

  const themeConfig = companySettings?.theme?.config || mainTheme.config;

  const language = i18n.language as ISupportedLocale;

  const currentLocales = {
    pt_BR: ptBR,
    en_US: enUS,
    es: esES,
  };

  const currentLocale = currentLocales[language] || currentLocales.pt_BR;

  return (
    <ConfigProvider locale={currentLocale} theme={themeConfig}>
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default AntDesignConfigProvider;
