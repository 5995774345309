import { Button, Form, Input, Modal, ModalProps, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  AbandonOrCancelQuotationModalQueryVariables,
  AbandonQuotationMutation,
  CancelQuotationMutation,
  CancellationTriggerEnum,
  useAbandonOrCancelQuotationModalQuery,
  useAbandonQuotationMutation,
  useCancelQuotationMutation,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { QuotationDescriptions } from '../../QuotationDescriptions/QuotationDescriptions';
import { CancellationReasonsSelect } from '../CancellationReasonsSelect/CancellationReasonsSelect';

interface IAbandonOrCancelQuotationFormValues {
  reason: string;
  reasonDescription?: string;
}

interface AbandonOrCancelQuotationModalProps extends ModalProps {
  quotationId?: string;
  type: 'Abandon' | 'Cancel';
  freightId?: string;
  onCancel?: () => void;
  /**
   * Function to execute when a quotation is successfully updated
   */
  onUpdateSuccess?: (quotation: CancelQuotationMutation) => void;
  onAbandonSuccess?: (quotation: AbandonQuotationMutation) => void;
}

const TRANSLATION_PATH = 'components.quotation.abandonOrCancelQuotationModal';

export const AbandonOrCancelQuotationModal = ({
  quotationId,
  type,
  freightId,
  onCancel,
  onUpdateSuccess,
  onAbandonSuccess,
  ...modalProps
}: AbandonOrCancelQuotationModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IAbandonOrCancelQuotationFormValues>();
  const { userData } = useUserContext();

  const abandonOrCancelQuotationModalVariables = quotationId
    ? ({
        quotation_id: quotationId,
      } as AbandonOrCancelQuotationModalQueryVariables)
    : undefined;

  const [abandonOrCancelQuotationModalResult] =
    useAbandonOrCancelQuotationModalQuery({
      variables: abandonOrCancelQuotationModalVariables,
      pause: !abandonOrCancelQuotationModalVariables,
    });

  const { data, fetching, error } = abandonOrCancelQuotationModalResult;

  const [abandonQuotationResult, executeAbandonQuotationMutation] =
    useAbandonQuotationMutation();

  const [cancelQuotationResult, executeCancelQuotationMutation] =
    useCancelQuotationMutation();

  const handleFinishQuotation = (
    formValues: IAbandonOrCancelQuotationFormValues
  ) => {
    if (userData?.company && quotationId) {
      if (type === 'Abandon') {
        executeAbandonQuotationMutation({
          input: {
            quotation_id: quotationId,
            owner_id: userData?.company.id,
            reason_id: formValues.reason,
            description: formValues.reasonDescription,
          },
        }).then(({ data, error }) => {
          if (data && !error) {
            onAbandonSuccess && onAbandonSuccess(data);
            handleOnClose();
          }
        });
      } else if (type === 'Cancel' && freightId) {
        executeCancelQuotationMutation({
          input: {
            quotation_id: quotationId,
            freight_id: freightId,
            shipper_id: userData?.company.id,
            reason_id: formValues.reason,
            description: formValues.reasonDescription,
          },
        }).then(({ data, error }) => {
          if (data && !error) {
            onUpdateSuccess && onUpdateSuccess(data);
            handleOnClose();
          }
        });
      }
    }
  };

  const handleOnClose = () => {
    onCancel && onCancel();
    form.resetFields();
  };

  const footer = (
    <Space size="large">
      <Button danger size="small" onClick={handleOnClose} disabled={fetching}>
        {t('general.cancel')}
      </Button>
      <Button
        size="small"
        type="primary"
        htmlType="submit"
        loading={
          fetching ||
          abandonQuotationResult.fetching ||
          cancelQuotationResult.fetching
        }
        disabled={!!error}
        onClick={form.submit}
      >
        {t('general.confirm')}
      </Button>
    </Space>
  );

  const cancellationReasonTrigger =
    type === 'Abandon'
      ? CancellationTriggerEnum.Abandonment
      : CancellationTriggerEnum.Shipper;

  return (
    <Modal
      width={'992px'}
      title={t(`${TRANSLATION_PATH}.title`)}
      footer={footer}
      centered
      onCancel={handleOnClose}
      {...modalProps}
    >
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />
      <ErrorAlert
        error={abandonQuotationResult.error}
        style={{ marginBottom: 24 }}
      />
      <ErrorAlert
        error={cancelQuotationResult.error}
        style={{ marginBottom: 24 }}
      />

      <QuotationDescriptions data={data?.FindQuotation} fetching={fetching} />

      <Form
        form={form}
        disabled={fetching || !!error}
        layout="vertical"
        onFinish={handleFinishQuotation}
      >
        <Form.Item
          name="reason"
          label={t(`${TRANSLATION_PATH}.inputs.labels.reason`)}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CancellationReasonsSelect
            style={{ width: 250 }}
            trigger={cancellationReasonTrigger}
          />
        </Form.Item>
        <Form.Item
          name="reasonDescription"
          label={t(`${TRANSLATION_PATH}.inputs.labels.reasonDescription`)}
        >
          <Input.TextArea
            size="small"
            placeholder={t(
              `${TRANSLATION_PATH}.inputs.placeholders.reasonDescription`
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
