import { BreadcrumbProps, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import { FormattedCountdown } from '../../../../../components/Formatters/FormattedCountdown/FormattedCountdown';
import { CreateQuotation } from '../../../../../components/Quotation/CreateQuotation/CreateQuotation';
import { useInterval } from '../../../../../hooks/useInterval/useInterval';
import { useMenuItem } from '../../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../../hooks/useTitle/useTitle';
import { FixedFooterContainer } from '../../../../../layouts/FixedFooterContainer/FixedFooterContainer';
import { breadcrumbItemRender } from '../../../../../utils/breadcrumbItemRender/breadcrumbItemRender';

export const CreateQuotationPage = (): ReactElement => {
  const CREATE_QUOTATION_PAGE_TRANSLATION_PATH =
    'pages.freight.quotations.createQuotationPage';

  const { t } = useTranslation();

  const [quoteLimit, setQuoteLimit] = useState<dayjs.Dayjs | null>();

  const [endingRemainingTimeType, setEndingRemainingTimeType] =
    useState<'danger'>();

  const breadcrumb: BreadcrumbProps = {
    itemRender: breadcrumbItemRender,
    items: [
      {
        path: '/carrier/freights',
        breadcrumbName: t('tab.carrier.freight.index'),
      },
      {
        path: '/carrier/quotations/:freightId/create',
        breadcrumbName: t(`${CREATE_QUOTATION_PAGE_TRANSLATION_PATH}.title`),
      },
    ],
  };

  useTitle({ title: `${CREATE_QUOTATION_PAGE_TRANSLATION_PATH}.title` });
  useMenuItem({
    openMenu: ['/carrier'],
    selectedMenu: ['/carrier/freights'],
  });

  useInterval(() => {
    if (quoteLimit && quoteLimit.diff(dayjs(), 'minutes') < 15) {
      setEndingRemainingTimeType('danger');
    }
  }, 1000);

  return (
    <FixedFooterContainer>
      <DefaultPageHeader
        breadcrumb={breadcrumb}
        title={t(`${CREATE_QUOTATION_PAGE_TRANSLATION_PATH}.title`)}
        extra={
          <>
            <Typography.Text strong type={endingRemainingTimeType}>
              {t(`${CREATE_QUOTATION_PAGE_TRANSLATION_PATH}.remainingTime`)}:
            </Typography.Text>
            <Skeleton
              active
              loading={quoteLimit === undefined}
              paragraph={false}
              style={{ width: 100 }}
            >
              {quoteLimit && (
                <Typography.Text type={endingRemainingTimeType}>
                  <FormattedCountdown dateTime={quoteLimit.toDate()} />
                </Typography.Text>
              )}
            </Skeleton>
          </>
        }
      />
      <CreateQuotation setQuoteLimit={setQuoteLimit} />
    </FixedFooterContainer>
  );
};

const CreateQuotationPageAccess = Access(CreateQuotationPage, {
  acceptedPermissions: [
    {
      module: 'QUOTATIONS',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default CreateQuotationPageAccess;
