import { TableColumnsType, TableProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '../../../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import CompanyStatusTag from '../../../../../../../components/Company/CompanyStatusTag/CompanyStatusTag';
import { FormattedDateTime } from '../../../../../../../components/Formatters/FormattedDateTime/FormattedDateTime';
import {
  CompanyDetailsPageQuery,
  CompanyStatusEnum,
} from '../../../../../../../graphql/generated/graphql';

type ICompanyStatusTableItem = NonNullable<
  CompanyDetailsPageQuery['FindCompany']['companyStatus']
>[0];

type ICompanyStatusTableProps = TableProps<ICompanyStatusTableItem>;

export const CompanyStatusTable = (
  props: ICompanyStatusTableProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.companyStatusTable',
  });

  const columns: TableColumnsType<ICompanyStatusTableItem> = [
    {
      dataIndex: 'status',
      title: t('columns.status'),
      render: (status: CompanyStatusEnum) => (
        <CompanyStatusTag companyStatus={status} />
      ),
    },
    {
      dataIndex: ['manager', 'email'],
      title: t('columns.manager'),
      render: (email?: string) => email || '-',
    },
    {
      dataIndex: 'updated_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.updated_at <= b.updated_at ? -1 : 1),
      title: t('columns.updatedAt'),
      render: (updatedAt: string) => <FormattedDateTime dateTime={updatedAt} />,
    },
  ];

  return (
    <DefaultTable columns={columns} rowKey={status => status.id} {...props} />
  );
};
