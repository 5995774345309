import { FormInstance } from 'antd';
import { useMemo } from 'react';
import Formatter from '../../../../classes/Formatter';
import { useRequest } from '../../../../hooks/useRequest';
import CompanyController from '../../../../structures/controllers/Company';
import { IFoundCompany } from '../../../../structures/interfaces/Company/Company';
import { ICompanyFunction } from '../../../../structures/interfaces/Company/CompanyFunction';
import { ICompanyType } from '../../../../structures/interfaces/Company/CompanyType';
import { validateCnpj, validateCpfCnpj } from '../../../../utils/inputRules';
import { ISetState } from '../../../../utils/interfaces';
import { useSignUpContext } from '../../SignUpPage/SignUpContext/useSignUpContext/useSignUpContext';
import {
  ICompanyDataAction,
  ICreateMotherCompanyModalAction,
} from './companyData';

interface ICompanyDataValidationsProps {
  form: FormInstance;
  updatingCompanyId?: string;
  companyDataTextPath: string;
  foundCompany?: IFoundCompany;
  foundMotherCompany?: IFoundCompany;
  selectedCompanyType: ICompanyType;
  selectedCompanyFunction: ICompanyFunction;
  setCompanyDataAction: ISetState<ICompanyDataAction>;
  setFoundCompany: ISetState<IFoundCompany | undefined>;
  setIsCreateMotherCompanyModalVisible: ISetState<boolean>;
  setFoundMotherCompany: ISetState<IFoundCompany | undefined>;
  setCreateMotherCompanyModalAction: ISetState<ICreateMotherCompanyModalAction>;
}

interface ICompanyDataValidations {
  normalizeCompanyDocument: (text: string) => string;
  companyDocumentDependencies: string[];
  companyDocumentRules: Record<string, unknown>[];
  motherCompanyDocumentRules: Record<string, unknown>[];
}

export const useCompanyDataValidations = ({
  form,
  updatingCompanyId,
  companyDataTextPath,
  foundCompany,
  foundMotherCompany,
  selectedCompanyType,
  selectedCompanyFunction,
  setFoundCompany,
  setFoundMotherCompany,
  setCompanyDataAction,
  setIsCreateMotherCompanyModalVisible,
  setCreateMotherCompanyModalAction,
}: ICompanyDataValidationsProps): Record<
  ICompanyType,
  ICompanyDataValidations
> => {
  const { accessToken = '' } = useSignUpContext();

  const companyController = useMemo(
    () =>
      new CompanyController({
        accessToken: accessToken || '',
      }),
    [accessToken]
  );

  const [foundCompanyRequest] = useRequest(
    companyController.findCompanyByDocument
  );

  const validateRepeatedCnpj = {
    validator: (_: unknown, documentNumber: string): Promise<void> => {
      const clearDocumentNumber = `${documentNumber}`.replace(/\D/g, '');
      const clearMotherDocumentNumber = `${form.getFieldValue(
        'motherCompanyDocument'
      )}`.replace(/\D/g, '');

      if (
        clearMotherDocumentNumber &&
        clearDocumentNumber &&
        clearDocumentNumber === clearMotherDocumentNumber
      ) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
    message: `${companyDataTextPath}.Branch.fields.rules.sameDocumentNumber`,
  };

  const findCompanyByDocument = {
    validator: (_: unknown, documentNumber: string): Promise<void> => {
      const clearDocumentNumber = `${documentNumber}`.replace(/\D/g, '');

      return new Promise(resolve => {
        if (foundCompany?.documentNumber !== clearDocumentNumber) {
          foundCompanyRequest({
            documentNumber: clearDocumentNumber,
            companyId: updatingCompanyId,
          })
            .then(foundCompany => {
              setFoundCompany(foundCompany);
            })
            .catch(() => {
              setFoundCompany(undefined);
            })
            .finally(() => resolve());
        } else {
          resolve();
        }
      });
    },
  };

  const findMotherCompanyByDocument = {
    validator: (_: unknown, documentNumber: string): Promise<void> => {
      const clearDocumentNumber = `${documentNumber}`.replace(/\D/g, '');

      return new Promise(resolve => {
        if (foundMotherCompany?.documentNumber !== clearDocumentNumber) {
          foundCompanyRequest({
            documentNumber: clearDocumentNumber,
            companyId: updatingCompanyId,
          })
            .then(foundMotherCompany => {
              setFoundMotherCompany(foundMotherCompany);
            })
            .catch(() => {
              setFoundMotherCompany(undefined);
            })
            .finally(() => resolve());
        } else {
          resolve();
        }
      });
    },
  };

  const validateCompany = {
    validator: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        setFoundCompany(foundCompany => {
          if (!foundCompany) {
            setCompanyDataAction('create');
            resolve();
          } else if (
            foundCompany.companyFunction === selectedCompanyFunction &&
            foundCompany.companyType === selectedCompanyType &&
            foundCompany.companyStatus === 'waitingData'
          ) {
            setCompanyDataAction('complete');
            resolve();
          } else {
            setCompanyDataAction('create');
            reject();
          }

          return foundCompany;
        });
      });
    },
    message: `${companyDataTextPath}.${selectedCompanyType}.fields.rules.invalidCompanyDocument`,
  };

  const triggerCreateMotherCompany = {
    validator: (): Promise<void> => {
      return new Promise(resolve => {
        setFoundMotherCompany(foundMotherCompany => {
          let createMotherCompanyModalAction: ICreateMotherCompanyModalAction =
            selectedCompanyType === 'Branch'
              ? 'createParent'
              : 'createBranchOrUnit';

          if (foundMotherCompany) {
            if (foundMotherCompany.id) {
              createMotherCompanyModalAction = 'details';
            }
          } else {
            setIsCreateMotherCompanyModalVisible(true);
          }

          setCreateMotherCompanyModalAction(createMotherCompanyModalAction);
          resolve();

          return foundMotherCompany;
        });
      });
    },
  };

  const validateMotherCompanyFunction = {
    validator: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        setFoundMotherCompany(foundMotherCompany => {
          if (
            !foundMotherCompany ||
            foundMotherCompany.companyFunction === selectedCompanyFunction
          ) {
            resolve();
          } else {
            reject();
          }

          return foundMotherCompany;
        });
      });
    },
    message: `${companyDataTextPath}.fields.rules.companyFunctionNotMatch`,
  };

  const validateMotherCompanyType = {
    validator: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        setFoundMotherCompany(foundMotherCompany => {
          if (
            !foundMotherCompany ||
            foundMotherCompany.companyType === 'Parent' ||
            (foundMotherCompany.companyType === 'Branch' &&
              selectedCompanyType === 'Unit')
          ) {
            resolve();
          } else {
            reject();
          }

          return foundMotherCompany;
        });
      });
    },
    message: `${companyDataTextPath}.fields.rules.companyTypeNotMatch`,
  };

  const validateMotherCompanyStatus = {
    validator: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        setFoundMotherCompany(foundMotherCompany => {
          if (
            !foundMotherCompany ||
            foundMotherCompany.companyStatus !== 'blocked'
          ) {
            form.setFieldsValue({
              motherCompanyName: foundMotherCompany?.tradeName,
            });
            resolve();
          } else {
            reject();
          }

          return foundMotherCompany;
        });
      });
    },
    message: `${companyDataTextPath}.fields.rules.companyStatusBlocked`,
  };

  const validateUnitCompany = {
    validator: (): Promise<void> => {
      return new Promise((resolve, reject) => {
        setFoundCompany(foundCompany => {
          setFoundMotherCompany(foundMotherCompany => {
            setCompanyDataAction('create');

            if (
              !foundCompany ||
              foundCompany.documentNumber === foundMotherCompany?.documentNumber
            ) {
              resolve();
            } else {
              reject();
            }

            return foundMotherCompany;
          });

          return foundCompany;
        });
      });
    },
    message: `${companyDataTextPath}.${selectedCompanyType}.fields.rules.invalidCompanyDocument`,
  };

  return {
    Parent: {
      normalizeCompanyDocument: Formatter.formatCPF_CNPJ,
      companyDocumentDependencies: ['companyType'],
      companyDocumentRules: [
        validateCpfCnpj,
        findCompanyByDocument,
        validateCompany,
      ],
      motherCompanyDocumentRules: [],
    },
    Branch: {
      normalizeCompanyDocument: Formatter.formatCNPJ,
      companyDocumentDependencies: ['companyType', 'motherCompanyDocument'],
      companyDocumentRules: [
        validateCnpj,
        validateRepeatedCnpj,
        findCompanyByDocument,
        validateCompany,
      ],
      motherCompanyDocumentRules: [
        validateCnpj,
        findMotherCompanyByDocument,
        validateMotherCompanyFunction,
        validateMotherCompanyType,
        validateMotherCompanyStatus,
        triggerCreateMotherCompany,
      ],
    },
    Unit: {
      normalizeCompanyDocument: Formatter.formatCNPJ,
      companyDocumentDependencies: ['companyType', 'motherCompanyDocument'],
      companyDocumentRules: [
        validateCnpj,
        findCompanyByDocument,
        validateUnitCompany,
      ],
      motherCompanyDocumentRules: [
        validateCnpj,
        findMotherCompanyByDocument,
        validateMotherCompanyFunction,
        validateMotherCompanyType,
        validateMotherCompanyStatus,
        triggerCreateMotherCompany,
      ],
    },
  };
};

export default useCompanyDataValidations;
