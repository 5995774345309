import { IPermission } from '../../contexts/AuthContext/AuthContext';

interface IGetAllowedAccesses {
  acceptedPermissions: IPermission[];
  claimedPermissions: IPermission[];
}

export const getAllowedAccesses = ({
  acceptedPermissions: acceptedPermissions,
  claimedPermissions,
}: IGetAllowedAccesses) => {
  return acceptedPermissions.reduce((acc, acceptedPermission) => {
    const accessAllowed = isAccessAllowed({
      acceptedPermission,
      claimedPermissions,
    });

    if (accessAllowed) return [...acc, { ...accessAllowed }];

    return acc;
  }, [] as IPermission[]);
};

interface IIsAccessAllowed<T> {
  acceptedPermission: T & IPermission;
  claimedPermissions: IPermission[];
}

export const isAccessAllowed = <T>({
  acceptedPermission,
  claimedPermissions,
}: IIsAccessAllowed<T>): (T & IPermission) | null => {
  const claimedActions = claimedPermissions.find(
    permission => permission.module === acceptedPermission.module
  )?.actions;

  const allowedActions = claimedActions?.filter(claimedAction =>
    acceptedPermission.actions.includes(claimedAction)
  );

  if (!allowedActions?.length) return null;

  return { ...acceptedPermission, actions: allowedActions };
};
