import { ThemeConfig } from 'antd';

const style = {
  btnPrimaryColor: '#FFF',
  fontFamily:
    "Roboto, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  menuInlineSubmenuBg: '#007dfe',
  primaryColor: '#1890FF',
  successColor: '#108e53',
  textColor: '#363A45',
  colorTextTertiary: 'rgba(0, 0, 0, 0.55)',
  checkboxColorBorder: 'rgba(0, 0, 0, 0.25)',
};

const config: ThemeConfig = {
  token: {
    borderRadius: 2,
    colorPrimary: style.primaryColor,
    fontFamily: style.fontFamily,
  },
  components: {
    Descriptions: {
      colorTextTertiary: style.colorTextTertiary,
    },
    Checkbox: {
      colorBorder: style.checkboxColorBorder,
    },
    Layout: {
      colorBgTrigger: style.primaryColor,
      colorBgHeader: style.primaryColor,
    },
    Menu: {
      colorBgContainer: style.primaryColor,
      colorBgElevated: style.primaryColor,
      colorHighlight: style.textColor,
      itemColor: style.btnPrimaryColor,
      itemSelectedColor: style.textColor,
      subMenuItemBg: style.menuInlineSubmenuBg,
    },
    Radio: {
      colorBorder: style.checkboxColorBorder,
    },
  },
};

interface IMainThemeProps {
  style: typeof style;
  config: ThemeConfig;
}

export const mainTheme: IMainThemeProps = { style, config };

export default mainTheme;
