import { useCallback } from 'react';
import { useAttachmentFormModal } from '../AttachmentFormModal/AttachmentFormModal.hooks';
import { useAttachmentsTableModal } from '../AttachmentsTableModal/AttachmentsTableModal.hooks';
import { IAttachmentFormValues } from '../../../attachments/AttachmentForm/AttachmentForm';

export const useAttachmentsModal = () => {
  const {
    form,
    handlers: {
      onClose: handleAttachmentFormModalClose,
      onFinish: handleAttachmentFormModalFinish,
      onOpen: handleAttachmentFormModalOpen,
    },
    isOpen: isAttachmentFormModalOpen,
    result: createAttachmentResponse,
  } = useAttachmentFormModal();

  const {
    handlers: {
      onClose: handleAttachmentsTableModalClose,
      onOpen: handleAttachmentsTableModalOpen,
      onDelete: handleDeleteAttachment,
    },
    isOpen: isAttachmentsTableModalOpen,
    result: findAttachmentsResponse,
    error: attachmentsTableError,
    fetching: attachmentsTableFetching,
    executeQuery: executeFindAttachmentsQuery,
  } = useAttachmentsTableModal();

  const handleOpen = useCallback(() => {
    handleAttachmentsTableModalClose();
    handleAttachmentFormModalOpen();
  }, [handleAttachmentFormModalOpen, handleAttachmentsTableModalClose]);

  const handleClose = useCallback(() => {
    handleAttachmentFormModalClose();
    handleAttachmentsTableModalOpen();
  }, [handleAttachmentFormModalClose, handleAttachmentsTableModalOpen]);

  const handleFinish: (formValues: IAttachmentFormValues) => void = useCallback(
    formValues =>
      handleAttachmentFormModalFinish(formValues)
        .then(() => {
          executeFindAttachmentsQuery();

          handleClose();
        })
        .catch(() => null),
    [executeFindAttachmentsQuery, handleAttachmentFormModalFinish, handleClose]
  );

  return {
    attachmentsTableError,
    attachmentsTableFetching,
    createAttachmentResponse,
    findAttachmentsResponse,
    form,
    handlers: {
      handleAttachmentsTableModalClose,
      handleAttachmentsTableModalOpen,
      handleClose,
      handleDeleteAttachment,
      handleFinish,
      handleOpen,
    },
    isAttachmentFormModalOpen,
    isAttachmentsTableModalOpen,
  };
};
