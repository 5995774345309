import jwtDecode from 'jwt-decode';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getApiUrl } from '../../../../api/urql/client';
import { useSignUpContext_RefreshTokenMutation } from '../../../../graphql/generated/graphql';
import { useInterval } from '../../../../hooks/useInterval/useInterval';

interface ISignUpContextProviderValues {
  accessToken: string | null;
  refreshToken: string | null;
  setTokens: (args: { accessToken: string; refreshToken: string }) => void;
  clearTokens: () => void;
}

interface ISignUpContextProviderProps {
  children: JSX.Element;
}

export const SignUpContextProvider = ({
  children,
}: ISignUpContextProviderProps): ReactElement => {
  const { accessToken, refreshToken, setTokens, clearTokens } =
    useLocalStorageToken();

  const [, executeRefreshTokenMutation] =
    useSignUpContext_RefreshTokenMutation();

  const decodedRefreshToken = useMemo(() => {
    if (refreshToken) return jwtDecode<{ access_token: string }>(refreshToken);
    return null;
  }, [refreshToken]);

  const contextValues = useMemo<ISignUpContextProviderValues>(
    () => ({
      accessToken,
      refreshToken,
      setTokens,
      clearTokens,
    }),
    [accessToken, clearTokens, refreshToken, setTokens]
  );

  const getRefreshToken = useCallback(() => {
    if (refreshToken && decodedRefreshToken) {
      return executeRefreshTokenMutation(undefined, {
        fetchOptions: {
          headers: {
            authorization: `Bearer ${decodedRefreshToken.access_token}`,
            'refresh-token': `Bearer ${refreshToken}`,
          },
        },
        url: getApiUrl(),
      }).then(({ data, error }) => {
        if (error) {
          clearTokens();
        } else if (data) {
          setTokens({
            accessToken: data.RefreshToken.access_token,
            refreshToken: data.RefreshToken.refresh_token,
          });
        }
      });
    }
  }, [
    clearTokens,
    decodedRefreshToken,
    executeRefreshTokenMutation,
    refreshToken,
    setTokens,
  ]);

  useEffect(() => {
    if (!accessToken) getRefreshToken();
  }, [accessToken, getRefreshToken]);

  useInterval(() => {
    getRefreshToken();
  }, 15 * 60 * 1000);

  return (
    <SignUpContext.Provider value={contextValues}>
      {children}
    </SignUpContext.Provider>
  );
};

const SignUpContextDefaultValues: ISignUpContextProviderValues = {
  accessToken: null,
  refreshToken: null,
  setTokens: () => null,
  clearTokens: () => null,
};

export const SignUpContext = React.createContext<ISignUpContextProviderValues>(
  SignUpContextDefaultValues
);

const useLocalStorageToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(() => null);
  const [refreshToken, setRefreshToken] = useState<string | null>(() =>
    getLocalStorageValidRefreshToken()
  );

  const setTokens = ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const clearTokens = () => {
    setAccessToken(null);
    setRefreshToken(null);
  };

  const contextValues = useMemo<ISignUpContextProviderValues>(
    () => ({
      accessToken,
      refreshToken,
      setTokens,
      clearTokens,
    }),
    [accessToken, refreshToken]
  );

  useEffect(() => {
    if (refreshToken) localStorage.setItem('SignUp_refreshToken', refreshToken);
    else localStorage.removeItem('SignUp_refreshToken');
  }, [refreshToken]);

  return contextValues;
};

const getLocalStorageValidRefreshToken = () => {
  const key = 'SignUp_refreshToken';
  const encodedToken = localStorage.getItem(key);

  if (!encodedToken) {
    return null;
  }

  try {
    const decodedToken = jwtDecode<{
      exp: number;
      iat: number;
    }>(encodedToken);

    const issuedAt = Number(decodedToken.iat);
    const expirationTime = Number(decodedToken.exp);
    const currentTime = Math.floor(Date.now() / 1000);

    if (issuedAt <= currentTime && expirationTime >= currentTime) {
      return encodedToken;
    }

    localStorage.removeItem(key);
    return null;
  } catch {
    localStorage.removeItem(key);
    return null;
  }
};
