import {
  Card,
  Checkbox,
  ConfigProvider,
  Form,
  notification,
  Popconfirm,
  Typography,
} from 'antd';
import jwtDecode from 'jwt-decode';
import { ReactElement, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import errorHandler from '../../../api/errorHandler';
import Button from '../../../components/AntdCustom/Button/Button';
import HeroCardContainer from '../../../components/container/heroContainer/heroCardContainer';
import Footer from '../../../components/container/heroContainer/heroCardContainer/footer';
import { SupportHelpContact } from '../../../components/Help/SupportHelpContact/SupportHelpContact';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import { useTermsOfUsePageMutation } from '../../../graphql/generated/graphql';
import mainTheme from '../../../layouts/MainLayout/mainTheme';
import { IDecodedTokenCreatedUser } from '../../../structures/interfaces/User';
import { IAPIError } from '../../../utils/interfaces';
import { useSignUpContext } from '../SignUpPage/SignUpContext/useSignUpContext/useSignUpContext';

import './TermsOfUsePage.scss';
import { CompanyFunctionReverseEnum } from '../../../structures/interfaces/Company/CompanyFunction';

export const TermsOfUsePage = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { termsOfUseUrl } = useUserContext();
  const { setTokens } = useSignUpContext();

  const locationState = location.state as
    | {
        token?: string;
        email?: string;
        password?: string;
        companyFunction?: CompanyFunctionReverseEnum;
        userId?: string;
      }
    | undefined;

  const [form] = Form.useForm();

  const userId = useMemo(() => {
    if (locationState?.token) {
      try {
        const { id } = jwtDecode<IDecodedTokenCreatedUser>(
          locationState?.token
        );
        return id;
      } catch {
        return null;
      }
    }

    return null;
  }, [locationState]);

  const [isAcceptTermChecked, setIsAcceptTermChecked] = useState(false);

  const [termsOfUseLoginMutationResult, executeTermsOfUseLoginMutation] =
    useTermsOfUsePageMutation();

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onCheckboxChange = () =>
    setIsAcceptTermChecked(isAcceptTermsChecked => !isAcceptTermsChecked);

  const onFormFinish = () => {
    if (userId && locationState?.email && locationState?.password) {
      executeTermsOfUseLoginMutation({
        email: locationState.email.toLowerCase(),
        password: locationState.password,
      }).then(({ data, error }) => {
        if (error) {
          const { message, description } = errorHandler(
            error.graphQLErrors as unknown as IAPIError[]
          );

          notification.error({
            message: t(message),
            description: t(description),
          });
        } else if (data) {
          setTokens({
            accessToken: data.Login.access_token,
            refreshToken: data.Login.refresh_token,
          });

          navigate('/registerCompany', {
            replace: true,
            state: {
              ...locationState,
              userId,
            },
          });
        }
      });
    } else if (locationState?.userId && locationState?.companyFunction) {
      navigate('/registerCompany', {
        replace: true,
        state: {
          ...locationState,
        },
      });
    }
  };

  const termsForm = (
    <Form
      form={form}
      className="form"
      layout="vertical"
      requiredMark={false}
      onFinish={onFormFinish}
    >
      <div className="terms-document">
        <object
          data={termsOfUseUrl}
          height="100%"
          title={termsOfUseUrl}
          type="application/pdf"
          width="100%"
        >
          <Typography.Paragraph>
            <Trans
              components={[
                <a
                  href={termsOfUseUrl}
                  key={termsOfUseUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                />,
              ]}
              i18nKey={'pages.termsOfUse.pdfNotSupported'}
              t={t}
            />
          </Typography.Paragraph>
        </object>
      </div>
      <Form.Item
        name="acceptTerms"
        valuePropName="checked"
        className="accept-terms"
      >
        <ConfigProvider
          theme={{ token: { colorPrimary: mainTheme.style.successColor } }}
        >
          <Checkbox onChange={onCheckboxChange}>
            {t('pages.termsOfUse.fields.labels.acceptTerms')}
          </Checkbox>
        </ConfigProvider>
      </Form.Item>
    </Form>
  );

  const cancelButton = (
    <Popconfirm
      cancelButtonProps={{ type: 'primary' }}
      cancelText={t('general.no')}
      okButtonProps={{ danger: true, type: 'default' }}
      okText={t('general.yes')}
      onConfirm={handleCancel}
      title={t('pages.termsOfUse.confirm.cancel')}
    >
      <Button
        danger
        size="large"
        type="primary"
        disabled={termsOfUseLoginMutationResult.fetching}
      >
        {t('pages.termsOfUse.action.cancel')}
      </Button>
    </Popconfirm>
  );

  const submitButton = (
    <Button
      size="large"
      type="success"
      htmlType="submit"
      onClick={handleSubmit}
      disabled={!isAcceptTermChecked}
      loading={termsOfUseLoginMutationResult.fetching}
    >
      {t('pages.termsOfUse.action.submit')}
    </Button>
  );

  const formHeader = (
    <TitleHeader
      centered
      titleText={t(`pages.termsOfUse.title`)}
      subtitleText={t('pages.termsOfUse.subtitle')}
    />
  );

  const formFooter = (
    <Footer>
      {cancelButton}
      {submitButton}
    </Footer>
  );

  if (!userId && !locationState?.userId) {
    return <Navigate to="/" replace />;
  }

  return (
    <HeroCardContainer
      logoColumnSize="small"
      className="terms-of-use_container"
    >
      <Card className="terms-of-use_card" bordered={false}>
        <SupportHelpContact />
        {formHeader}
        {termsForm}
      </Card>
      {formFooter}
    </HeroCardContainer>
  );
};

export default TermsOfUsePage;
