import {
  ConfirmFreightLoadFragment,
  QuotationDetailsQuery,
} from '../../../graphql/generated/graphql';

export const toConfirmFreightLoad = (
  freight?: Pick<
    QuotationDetailsQuery['FindQuotation']['freight'],
    | 'packing_id'
    | 'product_id'
    | 'product_variety_id'
    | 'product_type_id'
    | 'total_volume'
    | 'unit_type'
    | 'note_value'
    | 'observation'
    | 'suggested_value'
  >
): ConfirmFreightLoadFragment | undefined => {
  if (!freight) return undefined;

  const {
    packing_id: { label: packingName },
    product_id: { label: productName },
    product_type_id: { label: productTypeName },
    product_variety_id,
    total_volume,
    unit_type,
    note_value,
    observation,
    suggested_value,
  } = freight;

  return {
    packing: { name: packingName },
    product: { name: productName },
    product_variety: product_variety_id
      ? { name: product_variety_id.label, id: product_variety_id.value }
      : undefined,
    product_type: { name: productTypeName },
    total_volume: total_volume,
    unit_type: unit_type,
    observation,
    note_value: note_value,
    suggested_value: suggested_value,
  };
};

export const QuotationDetailsUtils = {
  toConfirmFreightLoad,
};
