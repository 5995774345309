import { Select, SelectProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionEnum } from '../../../../graphql/generated/graphql';
import { CompanyFunction } from '../CompanyFunction';

export type ICompanyFunctionSelectProps = Omit<
  SelectProps,
  'options' | 'children'
>;

export const CompanyFunctionSelect = (
  props: ICompanyFunctionSelectProps
): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'general',
  });

  const options: SelectProps['options'] = Object.values(FunctionEnum).map(
    key => ({ label: <CompanyFunction companyFunction={key} />, value: key })
  );

  return <Select options={options} placeholder={t('select')} {...props} />;
};
