import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import logoTrucker1xPng from '../../assets/images/trucker-horizontal-branca-400.png';
import logoTrucker1xWebp from '../../assets/images/trucker-horizontal-branca-400.webp';
import logoTrucker2xPng from '../../assets/images/trucker-horizontal-branca-400@2x.png';
import logoTrucker2xWebp from '../../assets/images/trucker-horizontal-branca-400@2x.webp';

interface ITruckerLogoProps {
  alt?: string;
  className?: string;
  style?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export const TruckerLogo = ({
  alt,
  className,
  style,
}: ITruckerLogoProps): ReactElement => {
  const { t } = useTranslation();

  const defaultStyle: React.ImgHTMLAttributes<HTMLImageElement> = {
    width: '100%',
    height: 'auto',
  };

  return (
    <picture className="trucker-logo_container">
      <source
        srcSet={`${logoTrucker1xWebp} 1x, ${logoTrucker2xWebp} 2x`}
        type="image/webp"
      />
      <source
        srcSet={`${logoTrucker1xPng} 1x, ${logoTrucker2xPng} 2x`}
        type="image/png"
      />
      <img
        src="../../assets/images/trucker-horizontal-branca-400.png"
        alt={alt || t('general.logoAlt')}
        className={className || 'trucker-logo'}
        style={style?.style ?? defaultStyle}
      />
    </picture>
  );
};

export default TruckerLogo;
