import { Form, FormInstance, Modal, Row, SelectProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FormSection from '.';
import InputText from '../../../../components/inputs/inputText';
import Select from '../../../../components/inputs/select';
import {
  CompanyTypeOptions,
  ICompanyType,
} from '../../../../structures/interfaces/Company/CompanyType';
import { translateOptions } from '../../../../utils';
import { ICreateMotherCompanyModalAction } from './companyData';

export interface IFieldValues {
  modalMotherCompanyType: ICompanyType;
  modalMotherCompanyDocument: string;
  modalMotherCompanyTradeName: string;
  modalMotherCompanyName: string;
}

interface IFieldProps {
  disabled?: boolean;
  options?: SelectProps['options'];
}

interface IActionFields {
  modalMotherCompanyTypeProps: IFieldProps;
  modalMotherCompanyDocumentProps: IFieldProps;
  modalMotherCompanyTradeNameProps?: IFieldProps;
  modalMotherCompanyNameProps?: IFieldProps;
}

interface IActionFieldValues {
  details: IActionFields;
  createParent: IActionFields;
  createBranchOrUnit: IActionFields;
  none: Record<string, never>;
}

interface ICreateMotherCompanyModalProps {
  form: FormInstance;
  action: ICreateMotherCompanyModalAction;
  onClose: () => void;
  onConfirm: (fieldValues: IFieldValues) => void;
  isVisible: boolean;
  translationTextPath: string;
}

const CreateMotherCompanyModal = ({
  form,
  action,
  onClose,
  onConfirm,
  isVisible,
  translationTextPath,
}: ICreateMotherCompanyModalProps): ReactElement => {
  const { t } = useTranslation();

  const companyTypeOptions = translateOptions(t, CompanyTypeOptions);

  const actionFieldValues: IActionFieldValues = {
    details: {
      modalMotherCompanyTypeProps: {
        disabled: true,
      },
      modalMotherCompanyDocumentProps: {
        disabled: true,
      },
      modalMotherCompanyTradeNameProps: {
        disabled: true,
      },
      modalMotherCompanyNameProps: {
        disabled: true,
      },
    },
    createParent: {
      modalMotherCompanyTypeProps: {
        disabled: true,
      },
      modalMotherCompanyDocumentProps: {
        disabled: true,
      },
    },
    createBranchOrUnit: {
      modalMotherCompanyTypeProps: {
        options: companyTypeOptions.filter(({ value }) => value !== 'Unit'),
      },
      modalMotherCompanyDocumentProps: {
        disabled: true,
      },
    },
    none: {},
  };

  const {
    modalMotherCompanyTypeProps,
    modalMotherCompanyDocumentProps,
    modalMotherCompanyTradeNameProps,
    modalMotherCompanyNameProps,
  } = actionFieldValues[action];

  const handleModalConfirm = () => {
    const fieldNames = [
      'modalMotherCompanyType',
      'modalMotherCompanyDocument',
      'modalMotherCompanyTradeName',
      'modalMotherCompanyName',
    ];

    form
      .validateFields(fieldNames)
      .then(onConfirm)
      .catch(() => {
        return;
      });
  };

  const modalCancelText =
    action === 'details' ? t('general.back') : t('general.close');

  return (
    <Modal
      centered
      className={'create-mother-company_modal'}
      open={isVisible}
      onOk={handleModalConfirm}
      okText={t('general.save')}
      onCancel={onClose}
      cancelText={modalCancelText}
      width={'600px'}
      okButtonProps={{
        className: 'btn-success',
        hidden: action === 'details',
      }}
    >
      <Form form={form} className="form" layout="vertical" requiredMark={false}>
        <FormSection
          sectionTitle={t(`${translationTextPath}.modal.title.${action}`)}
        >
          <Row>
            <Select
              formItem
              size="large"
              name="modalMotherCompanyType"
              options={companyTypeOptions}
              label={t(
                `${translationTextPath}.modal.fields.labels.motherCompanyType`
              )}
              {...modalMotherCompanyTypeProps}
            />
            <InputText
              formItem
              validation
              disabled
              size="large"
              name="modalMotherCompanyDocument"
              label={t(
                `${translationTextPath}.modal.fields.labels.motherCompanyDocument`
              )}
              {...modalMotherCompanyDocumentProps}
            />
            <InputText
              formItem
              validation
              size="large"
              name="modalMotherCompanyTradeName"
              label={t(
                `${translationTextPath}.modal.fields.labels.motherCompanyTradeName`
              )}
              placeholder={t(
                `${translationTextPath}.modal.fields.placeholders.motherCompanyTradeName`
              )}
              otherRules={[
                {
                  max: 100,
                  message: `${translationTextPath}.modal.fields.rules.tradeNameLength`,
                },
              ]}
              {...modalMotherCompanyTradeNameProps}
            />
            <InputText
              formItem
              validation
              size="large"
              name="modalMotherCompanyName"
              label={t(
                `${translationTextPath}.modal.fields.labels.motherCompanyName`
              )}
              placeholder={t(
                `${translationTextPath}.modal.fields.placeholders.motherCompanyName`
              )}
              otherRules={[
                {
                  max: 100,
                  message: `${translationTextPath}.modal.fields.rules.companyNameLength`,
                },
              ]}
              {...modalMotherCompanyNameProps}
            />
          </Row>
        </FormSection>
      </Form>
    </Modal>
  );
};

export default CreateMotherCompanyModal;
