import { ReactElement } from 'react';
import { FreightDetails_CompanyDetailsFragment } from '../../../../../graphql/generated/graphql';
import CompanyDataDescriptions from '../../../../Company/CompanyDescriptions/CompanyDataDescriptions/CompanyDataDescriptions';
import CompanyAddressDescriptions from '../../../../Company/CompanyDescriptions/CompanyAddressDescriptions/CompanyAddressDescriptions';
import CompanyContactDescriptions from '../../../../Company/CompanyDescriptions/CompanyContactDescriptions/CompanyContactDescriptions';
import CompanyPoliciesDescriptions from '../../../../Company/CompanyDescriptions/CompanyPoliciesDescriptions/CompanyPoliciesDescriptions';
import { Card, Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

interface ICompanyDetailsProps {
  company?: FreightDetails_CompanyDetailsFragment;
  loading?: boolean;
}

export const CompanyDetails = ({
  company,
  loading,
}: ICompanyDetailsProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.freightDetails.components.companyDetails',
  });

  return (
    <>
      <Card title={t('dataTitle')}>
        <Skeleton active loading={loading}>
          {company ? (
            <CompanyDataDescriptions companyData={company} />
          ) : (
            <Empty />
          )}
        </Skeleton>
      </Card>
      <Card title={t('addressTitle')}>
        <Skeleton active loading={loading}>
          {company?.address ? (
            <CompanyAddressDescriptions companyAddress={company.address} />
          ) : (
            <Empty />
          )}
        </Skeleton>
      </Card>
      <Card title={t('contactTitle')}>
        <Skeleton active loading={loading}>
          {company?.contacts && company.contacts[0] ? (
            <CompanyContactDescriptions companyContact={company.contacts[0]} />
          ) : (
            <Empty />
          )}
        </Skeleton>
      </Card>
      <Card title={t('policiesTitle')}>
        <Skeleton active loading={loading}>
          {company?.policies ? (
            <CompanyPoliciesDescriptions companyPolicies={company.policies} />
          ) : (
            <Empty />
          )}
        </Skeleton>
      </Card>
    </>
  );
};
