/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  FindPlacesForPlaceTableQueryVariables,
  PlaceTableItemFragment,
  useDeletePlaceMutation,
  useFindPlacesForPlaceTableQuery,
} from '../../../../graphql/generated/graphql';
import { DefaultPageHeader } from '../../../AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import CreatePlaceModal from '../CreatePlaceModal/CreatePlaceModal';
import PlaceDetailsModal from '../PlaceDetailsModal/PlaceDetailsModal';
import PlaceFilter, { IPlaceFilterForm } from '../PlaceFilter/PlaceFilter';
import { PlaceTable } from '../PlaceTable/PlaceTable';
import UpdatePlaceModal from '../UpdatePlaceModal/UpdatePlaceModal';

type IPlaceAction = 'Create' | 'Update' | 'View';

type IFindPlace = PlaceTableItemFragment & {
  slug: string;
};

export const ManagePlaces = (): ReactElement => {
  const MANAGE_PLACE_TRANSLATION_PATH = 'components.freight.place.managePlaces';

  const { t } = useTranslation();

  const { userData } = useUserContext();

  const [placeFilterValues, setPlaceFilterValues] =
    useState<IPlaceFilterForm>();

  const [selectedPlaceId, setSelectedPlaceId] = useState<string>();
  const [selectedPlaceAction, setSelectedPlaceAction] =
    useState<IPlaceAction>();

  const [findPlaces, setFindPlaces] = useState<IFindPlace[]>();

  const handlePlaceAction = (action: IPlaceAction, placeId?: string) => {
    setSelectedPlaceAction(action);
    if (placeId) setSelectedPlaceId(placeId);
  };

  const handleCloseActionModal = () => {
    setSelectedPlaceId(undefined);
    setSelectedPlaceAction(undefined);
  };

  const handleRequestSuccess = (
    updatePlaceTableData: PlaceTableItemFragment
  ) => {
    if (findPlaces) {
      const place = findPlaces.find(
        place => place.id === updatePlaceTableData.id
      );

      if (place) {
        const places = findPlaces.map(place =>
          place.id === updatePlaceTableData.id ? updatePlaceTableData : place
        );

        setFindPlaces(slugifyPlaces(places));
      } else {
        setFindPlaces(slugifyPlaces([updatePlaceTableData, ...findPlaces]));
      }
    } else {
      setFindPlaces(slugifyPlaces([updatePlaceTableData]));
    }
    handleCloseActionModal();
  };

  const slugifyPlaces = (places: Omit<IFindPlace, 'slug'>[]) => {
    return places.map(place => ({
      ...place,
      slug: Formatter.removeAccents(
        `${place.name} ${place.city_name} ${place.state_id} ${place.cadence} ${place.note}`.toLowerCase()
      ),
    }));
  };

  const findPlaceVariables: FindPlacesForPlaceTableQueryVariables | undefined =
    userData?.company
      ? {
          where: {
            company: {
              reference_external_company_id: { equals: userData.company.id },
            },
          },
        }
      : undefined;

  const [findPlacesForPlaceTableResult, executeFindPlacesForPlaceTableQuery] =
    useFindPlacesForPlaceTableQuery({
      variables: findPlaceVariables,
      pause: true,
    });

  useEffect(() => {
    if (userData) {
      executeFindPlacesForPlaceTableQuery();
    }
  }, [userData]);

  const filteredPlaces = useMemo(() => {
    if (placeFilterValues) {
      const { cadence, city, hasBalance, hasDirtRoad, state } =
        placeFilterValues;

      const search = Formatter.removeAccents(
        placeFilterValues.search || ''
      ).toLowerCase();

      return findPlaces?.filter(place => {
        if (!cadence || String(place.cadence).includes(cadence)) {
          if (!city || place.city_id === city) {
            if (
              hasBalance === undefined ||
              String(place.has_balance) === hasBalance
            ) {
              if (
                hasDirtRoad === undefined ||
                String(place.has_dirt_road) === hasDirtRoad
              ) {
                if (!search || place.slug.includes(search)) {
                  if (!state || place.state_id.includes(state)) {
                    return true;
                  }
                }
              }
            }
          }
        }

        return false;
      });
    }

    return findPlaces;
  }, [placeFilterValues, findPlaces]);

  const [deletePlaceMutationResult, executeDeletePlaceMutation] =
    useDeletePlaceMutation();

  const handleDeletePlace = (placeId: string) => {
    executeDeletePlaceMutation({ input: { place_id: placeId } }).then(
      ({ data }) => {
        if (data) {
          setFindPlaces(findPlaces =>
            findPlaces?.filter(place => place.id !== data.DeletePlace.id)
          );
        }
      }
    );
  };

  useEffect(() => {
    if (findPlacesForPlaceTableResult.error) {
      setFindPlaces(undefined);
    } else if (
      findPlacesForPlaceTableResult.data?.FindPlaces &&
      !findPlacesForPlaceTableResult.fetching
    ) {
      setFindPlaces(
        slugifyPlaces(findPlacesForPlaceTableResult.data?.FindPlaces)
      );
    }
  }, [findPlacesForPlaceTableResult.data]);

  return (
    <>
      <DefaultPageHeader
        subTitle={t(`${MANAGE_PLACE_TRANSLATION_PATH}.subTitle`)}
        title={t(`${MANAGE_PLACE_TRANSLATION_PATH}.title`)}
      />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={findPlacesForPlaceTableResult.error} />
        <ErrorAlert error={deletePlaceMutationResult.error} />
        <Col span={24}>
          <PlaceFilter onFilter={setPlaceFilterValues} />
        </Col>
        <Col span={24}>
          <PlaceTable
            handleView={placeId => handlePlaceAction('View', placeId)}
            handleUpdate={placeId => handlePlaceAction('Update', placeId)}
            handleDelete={placeId => handleDeletePlace(placeId)}
            handleCreate={() => handlePlaceAction('Create')}
            filteredPlaces={filteredPlaces}
            loading={
              findPlacesForPlaceTableResult.fetching ||
              deletePlaceMutationResult.fetching
            }
          />
        </Col>
      </Row>
      <PlaceDetailsModal
        placeId={selectedPlaceId}
        isVisible={selectedPlaceAction === 'View'}
        onClose={handleCloseActionModal}
      />
      <UpdatePlaceModal
        placeId={selectedPlaceId}
        isVisible={selectedPlaceAction === 'Update'}
        onClose={handleCloseActionModal}
        onUpdateSuccess={handleRequestSuccess}
      />
      <CreatePlaceModal
        companyId={userData?.company?.id}
        isVisible={selectedPlaceAction === 'Create'}
        onClose={handleCloseActionModal}
        onCreateSuccess={handleRequestSuccess}
      />
    </>
  );
};
