/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SignUpMessage from '..';
import CloseCircleOutlined from '../../../../assets/IconComponents/CloseCircleOutlined';
import { IUser } from '../../../../structures/interfaces/User';
import ReproachDescription from '../accountReproved/reproachDescription';

import './index.scss';

export const AccountBlocked = (): ReactElement<unknown> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as { user?: IUser } | undefined;

  useEffect(() => {
    if (!locationState?.user) {
      navigate('/', { replace: true });
    }
  }, []);

  const handleConfirm = () => {
    navigate('/');
  };

  if (!locationState?.user?.company) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <SignUpMessage
      buttonAction={handleConfirm}
      image={<CloseCircleOutlined />}
      className="sign-up_account-blocked"
      title={t('pages.signUp.messages.accountBlocked.title')}
      buttonText={t('pages.signUp.messages.accountBlocked.buttonText')}
    >
      <ReproachDescription
        action="blocked"
        companyId={locationState.user.company.id}
      />
    </SignUpMessage>
  );
};

export default AccountBlocked;
