import { MenuOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ReactElement } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext/useAuthContext';
import { useLayoutContext } from '../../../../contexts/LayoutContext/useLayoutContext';
import AvatarMenu from '../AvatarMenu/AvatarMenu';
import {
  HeaderSupportHelpContact,
  StickyHeader,
  SupportData,
} from './Header.components';

const DesktopHeader = (): ReactElement => {
  const { isAdmOrSup } = useAuthContext();

  return (
    <StickyHeader style={{ justifyContent: 'flex-end' }}>
      <Space size="middle">
        {isAdmOrSup && <SupportData.Desktop />}
        <AvatarMenu.Desktop />
        {/* <TranslateMenu /> */}
        <HeaderSupportHelpContact />
      </Space>
    </StickyHeader>
  );
};

const MobileHeader = () => {
  const { isAdmOrSup } = useAuthContext();
  const { setIsMobileMenuOpen } = useLayoutContext();

  return (
    <StickyHeader
      style={{ justifyContent: 'space-between', paddingInline: '24px' }}
    >
      <Space>
        <Button
          ghost
          icon={<MenuOutlined />}
          onClick={() => setIsMobileMenuOpen(true)}
        />
      </Space>
      {isAdmOrSup && <SupportData.Mobile />}
      <Space size="large">
        {/* <TranslateMenu /> */}
        <HeaderSupportHelpContact />
      </Space>
    </StickyHeader>
  );
};

export const Header = {
  Desktop: DesktopHeader,
  Mobile: MobileHeader,
};
