import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

export interface IFormattedCountdown {
  dateTime: Date;
}

export const FormattedCountdown = (props: IFormattedCountdown) => {
  const dateTime = dayjs(props.dateTime);

  const countdownRef = useRef<number | null>(null);

  const [diffSeconds, setDiffSeconds] = useState(
    dateTime.diff(dayjs(), 'seconds')
  );

  useEffect(() => {
    const secondInMs = 1000; // 1 second

    if (diffSeconds > 0 && !countdownRef.current) {
      countdownRef.current = window.setInterval(() => {
        setDiffSeconds(diffSeconds => diffSeconds - 1);
      }, secondInMs);
    }

    return () => {
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
        countdownRef.current = null;
      }
    };
  }, [diffSeconds]);

  const days = Math.floor(diffSeconds / 86400);
  const hours = Math.floor((diffSeconds % 86400) / 3600);
  const minutes = Math.floor((diffSeconds % 3600) / 60);
  const seconds = Math.floor(diffSeconds % 60);

  const formattedDays = days > 0 ? `${days}d` : '';
  const formattedHours = hours > 0 ? `${hours}h` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}m` : '';
  const formattedSeconds = seconds > 0 ? `${seconds}s` : '';

  const formattedCountdown = [
    formattedDays,
    formattedHours,
    formattedMinutes,
    formattedSeconds,
  ]
    .filter(Boolean)
    .join(' ');

  if (diffSeconds > 0) return <>{formattedCountdown}</>;

  return <>-</>;
};
