/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import SignUpMessage from '..';
import CheckCircleOutlined from '../../../../assets/IconComponents/CheckCircleOutlined';

import './index.scss';

export const AccountCreated = (): ReactElement<unknown> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as { success: boolean } | undefined;

  useEffect(() => {
    if (!locationState?.success) {
      navigate('/', { replace: true });
    }
  }, []);

  const handleConfirm = () => {
    navigate('/');
  };

  return (
    <SignUpMessage
      buttonAction={handleConfirm}
      image={<CheckCircleOutlined />}
      className="sign-up_account-created"
      title={t('pages.signUp.messages.accountCreated.title')}
      buttonText={t('pages.signUp.messages.accountCreated.buttonText')}
    >
      <span className="description-text">
        {t('pages.signUp.messages.accountCreated.description')}
      </span>
    </SignUpMessage>
  );
};

export default AccountCreated;
