/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardProps,
  Form,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CancelQuotationMutation,
  HireQuotationTableFragment,
} from '../../../graphql/generated/graphql';
import { CompanyName } from '../../Company/CompanyName/CompanyName';
import { Price } from '../../../core/Price';
import { Volume } from '../../../core/Freight/Volume';

import './HireQuotationTable.scss';

interface IHireQuotationTableProps {
  quotations?: HireQuotationTableFragment[];
  loading?: boolean;
  showSelection?: boolean;
  onViewFinishedQuotationReason?: (quotationId: string) => void;
  onViewCarrierDetailsQuotation?: (quotationId: string) => void;
  onViewCarrierDetails?: (companyId: string) => void;
  onViewFinishQuotation?: (quotationId: string) => void;
  unallocatedVolume?: number;
  setSelectedQuotations?: (quotations: HireQuotationTableFragment[]) => void;
  isHiredFreight?: boolean;
  updateFinishQuotationData?: CancelQuotationMutation;
  showTableActions?: boolean;
}

const TRANSLATION_PATH = 'components.freight.quotation.hireQuotationTable';

export const HireQuotationTable = ({
  quotations,
  loading,
  showSelection = true,
  onViewFinishedQuotationReason,
  onViewCarrierDetailsQuotation,
  onViewCarrierDetails,
  onViewFinishQuotation,
  unallocatedVolume,
  isHiredFreight,
  updateFinishQuotationData,
  showTableActions = true,
  ...props
}: IHireQuotationTableProps): ReactElement => {
  const { t } = useTranslation();

  const [selectedQuotations, setSelectedQuotations] = useState<
    HireQuotationTableFragment[]
  >([]);
  const [quotationsTableData, setQuotationsTableData] = useState<
    HireQuotationTableFragment[]
  >(quotations || []);

  const isQuotationInvalid = (quotation: HireQuotationTableFragment) => {
    const hasSelectedQuotations = selectedQuotations.length > 0;
    const someDistinctValue = selectedQuotations.some(
      ({ value_per_unit }) => value_per_unit !== quotation.value_per_unit
    );
    const hasEnoughUnallocatedVolume =
      unallocatedVolume === undefined ||
      unallocatedVolume >= quotation.total_volume;
    const isSelected = !!selectedQuotations.find(
      ({ id }) => id === quotation.id
    );

    return (
      !isSelected &&
      hasSelectedQuotations &&
      (someDistinctValue || !hasEnoughUnallocatedVolume)
    );
  };

  const handleSelectQuotationChange = (
    selectedRows: HireQuotationTableFragment[]
  ) => {
    if (selectedRows.every(quotation => !isQuotationInvalid(quotation))) {
      setSelectedQuotations(selectedRows);
    }
  };

  const handleViewQuotation = (
    status: string,
    quotationId: string,
    ownerId: string
  ) => {
    status === 'CANCELED' || status === 'DROPPED' || status === 'ABANDONED'
      ? onViewFinishedQuotationReason &&
        onViewFinishedQuotationReason(quotationId)
      : status === 'WAITING'
      ? onViewCarrierDetails && onViewCarrierDetails(ownerId)
      : onViewCarrierDetailsQuotation &&
        onViewCarrierDetailsQuotation(quotationId);
  };

  useEffect(() => {
    if (props.setSelectedQuotations && !isHiredFreight) {
      props.setSelectedQuotations(selectedQuotations);
    }
  }, [selectedQuotations]);

  useEffect(() => {
    if (updateFinishQuotationData) {
      if (quotations) {
        const quotation = quotations.find(
          quotation =>
            quotation.id === updateFinishQuotationData.CancelQuotation.id
        );

        if (quotation) {
          Object.assign(quotation, updateFinishQuotationData.CancelQuotation);
          setQuotationsTableData([...quotations]);
        }
      }
    }
  }, [updateFinishQuotationData]);

  const columns: TableColumnsType<HireQuotationTableFragment> = [
    {
      title: t(`${TRANSLATION_PATH}.table.columns.carrier`),
      dataIndex: 'owner',
      key: 'owner',
      render: owner => <CompanyName company={owner} />,
    },
    // {
    //   title: t(`${TRANSLATION_PATH}.table.columns.rating`),
    //   dataIndex: ['owner', 'rating', 'average'],
    //   key: 'rating',
    // },
    // {
    //   title: t(`${TRANSLATION_PATH}.table.columns.finishedFreights`),
    //   dataIndex: 'finished_freights',
    //   key: 'finished_freights',
    // },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.pricePerUnit`),
      dataIndex: 'value_per_unit',
      key: 'value_per_unit',
      sorter: {
        compare: (a, b) => a.value_per_unit - b.value_per_unit,
        multiple: 1,
      },
      defaultSortOrder: 'ascend',
      render: (pricePerUnit, quotation) => (
        <Price.Display
          price={pricePerUnit}
          unitType={quotation.value_unit_type}
        />
      ),
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.hasVehicle`),
      dataIndex: 'has_vehicle',
      key: 'has_vehicle',
      render: hasVehicle => {
        if (hasVehicle) return <span>{t('general.yes')}</span>;
        return <span className="no-vehicle">{t('general.no')}</span>;
      },
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.totalVolume`),
      dataIndex: 'total_volume',
      key: 'total_volume',
      render: (totalVolume, quotation) => (
        <Volume.Display
          volume={totalVolume}
          unitType={quotation.freight.unit_type}
        />
      ),
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.loadedVolume`),
      dataIndex: 'loaded_volume',
      key: 'loaded_volume',
      render: (loadedVolume, quotation) => (
        <Volume.Display
          volume={loadedVolume}
          unitType={quotation.freight.unit_type}
        />
      ),
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.status`),
      dataIndex: ['status', 'name'],
      key: 'status_name',
      render: data => t(`${TRANSLATION_PATH}.table.rows.status.${data}`),
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.createdBy`),
      dataIndex: ['creator', 'email'],
      key: 'creator_name',
    },
    {
      title: t(`${TRANSLATION_PATH}.table.columns.actions`),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, quotation) => (
        <>
          <Button
            type="link"
            disabled={isQuotationInvalid(quotation)}
            onClick={() =>
              handleViewQuotation(
                quotation.status.name,
                quotation.id,
                quotation.owner.reference_external_company_id
              )
            }
          >
            {t(`${TRANSLATION_PATH}.actions.view`)}
          </Button>

          {isHiredFreight &&
            quotation.status.name !== 'FINISHED' &&
            quotation.status.name !== 'LOADED' &&
            quotation.status.name !== 'CANCELED' &&
            quotation.status.name !== 'DROPPED' &&
            quotation.status.name !== 'ABANDONED' && (
              <Button
                type="link"
                danger
                onClick={() =>
                  onViewFinishQuotation && onViewFinishQuotation(quotation.id)
                }
              >
                {t(`${TRANSLATION_PATH}.actions.finish`)}
              </Button>
            )}
        </>
      ),
    },
  ];

  const filteredColumns = columns.filter(column => {
    if (column.key === 'actions') {
      return showTableActions;
    } else if (isHiredFreight) {
      return column.key != 'rating' && column.key != 'finished_freights';
    } else {
      return (
        column.key != 'loaded_volume' &&
        column.key != 'status_name' &&
        column.key != 'actions'
      );
    }
  });

  const rowSelection: TableProps<HireQuotationTableFragment>['rowSelection'] = {
    columnTitle: (
      <Form.Item
        label={t(`${TRANSLATION_PATH}.table.columns.select`)}
        tooltip={t(`${TRANSLATION_PATH}.tooltips.select`)}
        style={{ margin: 0 }}
        colon={false}
      />
    ),
    onChange: (selectedRowKeys, selectedRows) => {
      handleSelectQuotationChange(selectedRows);
    },
    selectedRowKeys: selectedQuotations.map(({ id }) => id),
  };

  return (
    <Table
      className="ant-table-wrapper-responsive hire-quotation-table"
      columns={filteredColumns}
      dataSource={quotationsTableData}
      loading={loading}
      rowKey={quotation => quotation.id}
      rowClassName={quotation =>
        isQuotationInvalid(quotation) ? 'disabled-row' : ''
      }
      rowSelection={!isHiredFreight && showSelection ? rowSelection : undefined}
    />
  );
};

interface IHireQuotationTableCardProps extends CardProps {
  isHiredFreight?: boolean;
}

const HireQuotationTableCard = ({
  children,
  isHiredFreight,
  ...props
}: IHireQuotationTableCardProps) => {
  const { t } = useTranslation();

  const title = (
    <Typography.Text>{t(`${TRANSLATION_PATH}.title`)}</Typography.Text>
  );

  const titleHiredCarrier = (
    <Typography.Text>
      {t(`${TRANSLATION_PATH}.titleHiredCarrier`)}
    </Typography.Text>
  );

  return (
    <Card
      title={isHiredFreight ? titleHiredCarrier : title}
      bordered={false}
      {...props}
    >
      {children}
    </Card>
  );
};

HireQuotationTable.Card = HireQuotationTableCard;
