import { Input, InputNumber, InputNumberProps, InputProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { UnitTypeEnum } from '../../../../graphql/generated/graphql';

export interface IVolumeInputProps
  extends Partial<InputProps & InputNumberProps> {
  unitType?: UnitTypeEnum;
}

export const VolumeInput = ({ unitType, ...props }: IVolumeInputProps) => {
  if (unitType === UnitTypeEnum.Ton) {
    return <TonVolumeInput {...props} />;
  }

  if (unitType === UnitTypeEnum.Unit) {
    return <UnitVolumeInput {...props} />;
  }

  return <Input {...props} />;
};

const TonVolumeInput = (props: InputProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'enums.unitType' });

  return <Input addonAfter={t('TON')} {...props} />;
};

const UnitVolumeInput = (props: InputNumberProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'enums.unitType' });

  return <InputNumber min={0} addonAfter={t('UNIT')} {...props} />;
};
