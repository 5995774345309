import { useMemo } from 'react';
import { useQuotationGroupFormModal } from '../../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroupFormModal/QuotationGroupFormModal.hooks';
import { useActionsColumn } from '../../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroups/QuotationGroups.hooks';

export const useQuotationGroupsTable = () => {
  const {
    handlers: { afterClose, close, openView },
    groupId,
    isViewOpen,
  } = useQuotationGroupFormModal();

  const { actionsColumn } = useActionsColumn({
    viewButtonProps: {
      isVisible: true,
      onClick(groupId) {
        return openView(groupId);
      },
    },
  });

  const quotationGroups = useMemo(() => {
    return {
      actionsColumn,
      groupId,
      handlers: { afterClose, close },
      isViewOpen,
    };
  }, [actionsColumn, afterClose, close, groupId, isViewOpen]);

  return quotationGroups;
};
