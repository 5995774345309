import { Descriptions, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import {
  PlaceForViewPlaceFragment,
  PlaceRouteForViewPlaceFragment,
} from '../../../../graphql/generated/graphql';
import { roundWithDecimals } from '../../../../utils';

interface IViewPlaceProps {
  place?: PlaceForViewPlaceFragment | PlaceRouteForViewPlaceFragment;
  loading?: boolean;
}

const formattingHour = (datetime: string) => {
  const date = dayjs(datetime).format('HH:mm');
  return date;
};

export const ViewPlace = ({
  place,
  loading,
}: IViewPlaceProps): ReactElement => {
  const VIEW_PLACE_TRANSLATION_PATH = 'components.freight.place.viewPlace';

  const { t } = useTranslation();

  const formatBalanceCapacity = (balanceCapacity?: number | null) => {
    if (balanceCapacity) {
      const balanceCapacityInTons = roundWithDecimals(
        Number(balanceCapacity) / 1000,
        3
      );

      return `${balanceCapacityInTons.toLocaleString()} ${t(
        'enums.measureUnits.mass.tonne.initials'
      )}`;
    }
    return t('general.none');
  };

  return (
    <Descriptions
      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
      contentStyle={{ marginBottom: 8 }}
      layout="vertical"
      size="small"
    >
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.name`)}
        span={1}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.name}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.city_name`)}
        span={1}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.city_name} - {place?.state_id}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.dirt_road_distance`)}
        span={1}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.has_dirt_road
            ? Formatter.formatDistance(place?.dirt_road_distance as number)
            : '-'}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.balance_capacity`)}
        span={1}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.balance_capacity
            ? `${formatBalanceCapacity(place?.balance_capacity)} `
            : '-'}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.cadence`)}
        span={1}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.cadence}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.business_hours`)}
        span={2}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.opening_hour ? formattingHour(place?.opening_hour) : ''} -{' '}
          {place?.closing_hour ? formattingHour(place?.closing_hour) : ''}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${VIEW_PLACE_TRANSLATION_PATH}.note`)}
        span={2}
      >
        <Skeleton active loading={loading} paragraph={false}>
          {place?.note}
        </Skeleton>
      </Descriptions.Item>
    </Descriptions>
  );
};
