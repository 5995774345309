import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type IJwtDecodeError =
  | 'expired_token'
  | 'invalid_token'
  | 'missing_token';

export interface IJwtBase {
  exp: number;
  iat: number;
}

export interface IUseJwtDecodeProps {
  encodedJwt?: string | null;
}

export interface IUseJwtDecode<T> {
  error?: { type: IJwtDecodeError; message: string; description: string };
  data?: T;
}

/**
 * Decodes a JSON Web Token and returns its payload data if the token is valid and not expired.
 * @typeParam T - The type of the JWT payload.
 * @param encodedJwt - The encoded JWT to decode.
 * @returns An object with the decoded token payload data, or an error message if the token is invalid or expired.
 */
export const useJwtDecode = <T extends IJwtBase>({
  encodedJwt,
}: IUseJwtDecodeProps): IUseJwtDecode<T> => {
  const { t } = useTranslation();

  const [response, setResponse] = useState<IUseJwtDecode<T>>({});

  useEffect(() => {
    if (!encodedJwt) {
      const errorType = 'missing_token';

      setResponse({
        error: {
          type: errorType,
          message: t(`hooks.useJwtDecode.errors.${errorType}.message`),
          description: t(`hooks.useJwtDecode.errors.${errorType}.description`),
        },
      });
    } else {
      try {
        const decodedToken = jwtDecode<T>(encodedJwt);

        const issuedAt = Number(decodedToken.iat);
        const expirationTime = Number(decodedToken.exp);
        const currentTime = Math.floor(Date.now() / 1000);

        if (issuedAt <= currentTime && expirationTime >= currentTime) {
          setResponse({
            data: decodedToken,
          });
        } else {
          const errorType = 'expired_token';

          setResponse({
            error: {
              type: errorType,
              message: t(`hooks.useJwtDecode.errors.${errorType}.message`),
              description: t(
                `hooks.useJwtDecode.errors.${errorType}.description`
              ),
            },
          });
        }
      } catch {
        const errorType = 'invalid_token';

        setResponse({
          error: {
            type: errorType,
            message: t(`hooks.useJwtDecode.errors.${errorType}.message`),
            description: t(
              `hooks.useJwtDecode.errors.${errorType}.description`
            ),
          },
        });
      }
    }

    return () => {
      setResponse({});
    };
  }, [encodedJwt, t]);

  return response;
};
