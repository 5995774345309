import { App } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import { useUserDetails_UpdateCompanySettingsMutation } from '../../../graphql/generated/graphql';

export const useEditCompanyForm = () => {
  const { userData } = useUserContext();

  const { t } = useTranslation('translations', {
    keyPrefix: 'components.user.userDetails.useEditCompanyForm',
  });

  const { message } = App.useApp();

  const [isEditing, setIsEditing] = useState(false);

  const [result, executeMutation] =
    useUserDetails_UpdateCompanySettingsMutation();

  const handlers = {
    onEdit() {
      setIsEditing(true);
    },
    onCancel() {
      setIsEditing(false);
    },
    onSubmit(themeId: string) {
      return new Promise((resolve, reject) => {
        if (userData?.company) {
          executeMutation({
            input: {
              theme_id: themeId,
              company_id: userData?.company?.id,
              changer_id: userData?.id,
            },
          }).then(response => {
            const { data, error } = response;

            if (error) {
              setIsEditing(true);
              reject(error);
            } else if (data) {
              setIsEditing(false);
              message.success({ content: t('submitSuccess.message') });
              resolve(response);
            }
          });
        }
      });
    },
  };

  return { isEditing, handlers, result };
};
