import { ReactElement } from 'react';
import { CreateFreightProvider } from '../contexts/Freight/CreateFreightContext/CreateFreightContext';

interface IFreightProvidersProps {
  children: React.ReactNode;
}

export const FreightProviders = ({
  children,
}: IFreightProvidersProps): ReactElement => {
  return <CreateFreightProvider>{children}</CreateFreightProvider>;
};

export default FreightProviders;
