import dayjs from 'dayjs';
import { CSSProperties, ReactElement } from 'react';
import { commonUtils } from '../../../../../core/Common/Common.utils';

interface IDefaultDateTimeColumnProps {
  date?: string | dayjs.Dayjs;
  style?: CSSProperties;
}

export const DefaultDateTimeColumn = ({
  date,
  style,
}: IDefaultDateTimeColumnProps): ReactElement => {
  if (!date) return <>-</>;

  return (
    <div style={{ minWidth: 150, ...style }}>
      {commonUtils.formatDateTime(date)}
    </div>
  );
};
