import { Col, Descriptions, Modal, Row, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { MapRef, Marker, NavigationControl } from 'react-map-gl';
import { usePlaceDetailsModalQuery } from '../../../../graphql/generated/graphql';
import MapStyle from '../../../../structures/interfaces/Mapbox/MapStyles';
import { roundWithDecimals } from '../../../../utils';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import './PlaceDetailsModal.scss';

interface ICoordinates {
  longitude: number;
  latitude: number;
  zoom: number;
}

interface IPlaceDetailsModalProps {
  /**
   * Is modal visible?
   */
  isVisible?: boolean;
  /**
   * Function to close modal
   */
  onClose?: () => void;
  /**
   * Id of the place that must be showed in the modal
   */
  placeId?: string;
}

/**
 * Modal to view details of a place
 */
export const PlaceDetailsModal = ({
  isVisible = false,
  onClose,
  placeId,
}: IPlaceDetailsModalProps): ReactElement => {
  const PLACE_DETAILS_MODAL_TRANSLATION_PATH =
    'components.freight.place.placeDetailsModal';

  const { t } = useTranslation();

  const findPlaceVariables = placeId ? { input: { id: placeId } } : undefined;

  const [result] = usePlaceDetailsModalQuery({
    variables: findPlaceVariables,
    pause: !findPlaceVariables,
  });

  const { fetching, error } = result;

  const mapRef = useRef<MapRef | null>(null);

  const [marker, setMarker] = useState<Omit<ICoordinates, 'zoom'>>();

  const Title = () =>
    useMemo(
      () => <>{t(`${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.title`)}</>,
      []
    );

  const formatOpeningHours = (
    isoOpeningTime?: string,
    isoClosingTime?: string
  ) => {
    if (isoOpeningTime && isoClosingTime) {
      const opening = dayjs(isoOpeningTime).format('HH:mm');
      const closing = dayjs(isoClosingTime).format('HH:mm');

      return `${opening} - ${closing}`;
    }

    return '';
  };

  const formatDirtRoadDistance = (dirtRoadDistance?: number) => {
    if (dirtRoadDistance) {
      const dirtRoadDistanceInKilometers = roundWithDecimals(
        dirtRoadDistance / 1000,
        3
      );

      return `${dirtRoadDistanceInKilometers.toLocaleString()} ${t(
        'enums.measureUnits.length.kilometer.initials'
      )}`;
    }
    return t('general.none');
  };

  const formatBalanceCapacity = (balanceCapacity?: number | null) => {
    if (balanceCapacity) {
      const balanceCapacityInTons = roundWithDecimals(
        balanceCapacity / 1000,
        3
      );

      return `${balanceCapacityInTons.toLocaleString()} ${t(
        'enums.measureUnits.mass.tonne.initials'
      )}`;
    }
    return t('general.none');
  };

  const handleMarkerClick = () => {
    if (marker) {
      mapRef.current?.flyTo({
        animate: true,
        zoom: 14,
        center: {
          lat: marker.latitude,
          lon: marker.longitude,
        },
      });
    }
  };

  useEffect(() => {
    if (result.data) {
      const latitude = Number(result.data.FindPlace.latitude);
      const longitude = Number(result.data.FindPlace.longitude);

      if (latitude && longitude) {
        setMarker({ latitude, longitude });
        mapRef.current?.flyTo({
          animate: true,
          zoom: 14,
          center: {
            lat: latitude,
            lon: longitude,
          },
        });
      }
    }
  }, [result]);

  return (
    <Modal
      width={'992px'}
      title={<Title />}
      open={isVisible}
      footer={false}
      onCancel={onClose}
    >
      <>
        <ErrorAlert error={error} style={{ marginBottom: 24 }} />
        <Row gutter={24}>
          <Col lg={12} xs={24}>
            <Skeleton active loading={fetching}>
              <Descriptions>
                <Descriptions.Item
                  span={3}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.name`
                  )}
                >
                  {result.data?.FindPlace.name}
                </Descriptions.Item>
                <Descriptions.Item
                  span={1}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.state_id`
                  )}
                >
                  {result.data?.FindPlace.state_id}
                </Descriptions.Item>
                <Descriptions.Item
                  span={2}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.city_name`
                  )}
                >
                  {result.data?.FindPlace.city_name}
                </Descriptions.Item>
                <Descriptions.Item
                  span={3}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.opening_hour`
                  )}
                >
                  {formatOpeningHours(
                    result.data?.FindPlace.opening_hour,
                    result.data?.FindPlace.closing_hour
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  span={3}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.cadence`
                  )}
                >
                  {result.data?.FindPlace.cadence || t('general.none')}
                </Descriptions.Item>
                <Descriptions.Item
                  span={3}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.dirt_road_distance`
                  )}
                >
                  {formatDirtRoadDistance(
                    result.data?.FindPlace.dirt_road_distance
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  span={3}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.balance_capacity`
                  )}
                >
                  {formatBalanceCapacity(
                    result.data?.FindPlace.balance_capacity
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  span={3}
                  label={t(
                    `${PLACE_DETAILS_MODAL_TRANSLATION_PATH}.descriptions.note`
                  )}
                >
                  {result.data?.FindPlace.note}
                </Descriptions.Item>
              </Descriptions>
            </Skeleton>
            <Skeleton active loading={fetching} />
          </Col>
          <Col lg={12} xs={24}>
            <div className="place-details-modal__map-container">
              <Map
                reuseMaps
                ref={mapRef}
                mapStyle={MapStyle.streets}
                style={{ height: '100%', width: '100%' }}
                initialViewState={{
                  longitude: -54,
                  latitude: -12,
                  zoom: 2.5,
                }}
              >
                {marker && <Marker {...marker} onClick={handleMarkerClick} />}
                <NavigationControl />
              </Map>
            </div>
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default PlaceDetailsModal;
