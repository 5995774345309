/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton, notification } from 'antd';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext/useAuthContext';
import { useRequest } from '../../../../hooks/useRequest';
import CompanyController from '../../../../structures/controllers/Company';
import { IReproachReason } from '../../../../structures/interfaces/Company/Reproach';
import { useSignUpContext } from '../../SignUpPage/SignUpContext/useSignUpContext/useSignUpContext';

enum ReproachDescriptionActionEnum {
  reproved = 'Reproved',
  blocked = 'Blocked',
}

interface IReproachDescriptionProps {
  action: keyof typeof ReproachDescriptionActionEnum;
  companyId: string;
}

const ReproachDescription = ({
  action,
  companyId,
}: IReproachDescriptionProps): ReactElement => {
  const reproachDescriptionTextPath = `pages.signUp.messages.account${ReproachDescriptionActionEnum[action]}`;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { accessToken } = useSignUpContext();

  const [reproachReason, setReproachReason] = useState<IReproachReason>();

  const companyController = useMemo(
    () => new CompanyController({ accessToken: accessToken || '' }),
    [accessToken]
  );

  const { logout } = useAuthContext();

  const [findReproachReason] = useRequest(companyController.findReproachReason);

  useEffect(() => {
    if (accessToken) {
      findReproachReason({ companyId })
        .then(reproachReason => setReproachReason(reproachReason))
        .catch(({ message, description }) => {
          notification.error({
            key: message,
            message: t(message),
            description: t(description),
          });

          logout();
          navigate('/', { replace: true });
        });
    }
  }, [accessToken]);

  const reasons = (
    <>
      {reproachReason ? (
        <>
          <h2>{t(`${reproachDescriptionTextPath}.explanation.reasons`)}</h2>
          <ul>
            {reproachReason.reasons.map(({ id, reasonType }) => (
              <li key={id}>{t(`enums.reasonType.${reasonType}`)}</li>
            ))}
          </ul>
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );

  const reproach = (
    <>
      {reproachReason ? (
        <>
          <h2>{t(`${reproachDescriptionTextPath}.explanation.description`)}</h2>
          <p>{reproachReason.reproach.description}</p>
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );

  return (
    <div className="reproach-explanation_container">
      <div className="reasons_container">{reasons}</div>
      <div className="description_container">{reproach}</div>
    </div>
  );
};

export default ReproachDescription;
