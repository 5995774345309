import { ReactElement, ReactNode } from 'react';

import { Row, Spin } from 'antd';

import SubHeader from './subHeader';

import './index.scss';

/**
 * @description Default pages structure.
 * @property {bool} [loading=false] if true, a loading component overlay the page
 * @property {Node} [children=null] Container children components
 * @property {string} [subHeaderTitle='primary'] Container header title text
 * @example
 * <Container
 *   loading={false}
 *   subHeaderTitle="Subtitle"
 * >
 *    <div>example</div>
 * </Container>
 */

interface IContainer {
  loading?: boolean;
  children?: ReactNode;
  subHeaderTitle?: string | ReactNode;
  [x: string]: unknown; // otherprops type declaration
}

const Container = ({
  subHeaderTitle,
  loading,
  children,
  ...otherprops
}: IContainer): ReactElement => {
  return (
    <>
      {loading && <div className="loader">{loading && <Spin />}</div>}
      <div
        {...otherprops}
        className={`${otherprops.className} ${loading && 'loader-child'}`}
      >
        <SubHeader title={subHeaderTitle} />
        <Row className="content_card-row">{children}</Row>
      </div>
    </>
  );
};

export default Container;
