import { ConfigProvider as AntDesignConfigProvider } from 'antd';
import { ReactElement } from 'react';
import HeroContainer from '../../components/container/heroContainer';
import { useTitle } from '../../hooks/useTitle/useTitle';
import mainTheme from '../../layouts/MainLayout/mainTheme';
import { LoginForm } from './LoginForm/LoginForm';

export const AuthPage = (): ReactElement<unknown> => {
  useTitle({ title: 'tab.authentication' });

  return (
    <AntDesignConfigProvider theme={mainTheme.config}>
      <HeroContainer>
        <LoginForm bordered={false} />
      </HeroContainer>
    </AntDesignConfigProvider>
  );
};

export default AuthPage;
