import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Access } from '../../../../components/Access/Access';
import { ManagePlaces } from '../../../../components/Freight/Place/ManagePlaces/ManagePlaces';
import { useMenuItem } from '../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../hooks/useTitle/useTitle';

export const PlacesPage = (): ReactElement => {
  useTitle({ title: 'tab.freight.places' });
  useMenuItem({
    openMenu: ['/shipper'],
    selectedMenu: ['/shipper/places'],
  });

  return <ManagePlaces />;
};

const PlacesPageAccess = Access(PlacesPage, {
  acceptedPermissions: [
    {
      module: 'PLACES',
      actions: ['CREATE'],
    },
  ],
  fallback: <Navigate to="/" replace />,
});

export default PlacesPageAccess;
