import { ReactElement, Suspense, useMemo } from 'react';

import { Route, Routes as Switch } from 'react-router-dom';

import LoadingPage from './pages/LoadingPage/LoadingPage';

import { useAuthContext } from './contexts/AuthContext/useAuthContext';
import { AuthRoutes } from './routes/AuthRoutes';
import { PrivateRoutes } from './routes/PrivateRoutes';
import { PublicRoutes } from './routes/PublicRoutes';
import { useUserContext } from './contexts/UserContext/useUserContext';

type IUserAuthStatus = 'loggedIn' | 'loggedOut' | 'loading';

const Routes = (): ReactElement<unknown> => {
  const { isUserLoggedIn } = useAuthContext();
  const { userData } = useUserContext();

  const userAuthStatus: IUserAuthStatus = useMemo(() => {
    if (isUserLoggedIn === false) {
      return 'loggedOut';
    }
    if (isUserLoggedIn === undefined || userData === undefined) {
      return 'loading';
    } else if (userData !== null) {
      return 'loggedIn';
    } else {
      return 'loading';
    }
  }, [isUserLoggedIn, userData]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        {userAuthStatus === 'loggedOut' && AuthRoutes}
        {userAuthStatus === 'loggedIn' && PrivateRoutes}
        {userAuthStatus !== 'loading' && PublicRoutes}
        {userAuthStatus === 'loading' && (
          <Route path="*" element={<LoadingPage />} />
        )}
      </Switch>
    </Suspense>
  );
};

export default Routes;
