import { Card, CardProps, Row, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Volume } from '../../../../../core/Freight/Volume';
import { Price } from '../../../../../core/Price';
import { ConfirmFreightLoadFragment } from '../../../../../graphql/generated/graphql';
import { ResponsiveDescriptions } from '../../../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';

export interface IConfirmFreightLoadProps extends CardProps {
  freightLoad?: ConfirmFreightLoadFragment;
  loading?: boolean;
}

export const ConfirmFreightLoad = ({
  freightLoad,
  loading,
  ...cardProps
}: IConfirmFreightLoadProps) => {
  const CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH =
    'components.freight.shipper.confirmCreateFreight.confirmFreightLoad';

  const { t } = useTranslation();

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.title`)}
      </Typography.Text>
    </Row>
  );

  const column = {
    xxl: 4,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <Card title={title} bordered={false} {...cardProps}>
      <ResponsiveDescriptions column={column} layout={layout}>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.product_type_id`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.product_type.name}
          </Skeleton>
        </ResponsiveDescriptions.Item>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.product_id`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.product.name}
          </Skeleton>
        </ResponsiveDescriptions.Item>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.product_variety_id`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.product_variety?.name || '-'}
          </Skeleton>
        </ResponsiveDescriptions.Item>
      </ResponsiveDescriptions>
      <ResponsiveDescriptions column={column} layout={layout}>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.packing_id`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.packing.name}
          </Skeleton>
        </ResponsiveDescriptions.Item>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.total_volume`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            <Volume.Display
              volume={freightLoad?.total_volume}
              unitType={freightLoad?.unit_type}
            />
          </Skeleton>
        </ResponsiveDescriptions.Item>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.note_value`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.note_value ? (
              <Price.Display price={freightLoad.note_value} />
            ) : (
              '-'
            )}
          </Skeleton>
        </ResponsiveDescriptions.Item>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.suggested_value`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.suggested_value ? (
              <Price.Display price={freightLoad.suggested_value} />
            ) : (
              '-'
            )}
          </Skeleton>
        </ResponsiveDescriptions.Item>
      </ResponsiveDescriptions>
      <ResponsiveDescriptions column={column} layout={layout}>
        <ResponsiveDescriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_LOAD_TRANSLATION_PATH}.fields.observation`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightLoad?.observation}
          </Skeleton>
        </ResponsiveDescriptions.Item>
      </ResponsiveDescriptions>
    </Card>
  );
};
