import dayjs from 'dayjs';

class Validator {
  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {bool}
   * @description Returns true if the CNPJ is valid, false otherwise.
   */
  validateCNPJ = (text: string) => {
    const cnpj = text.replace(/[^0-9]/g, '');

    if (cnpj === '00000000000000') return false;
    const invalids = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].filter(
      number => {
        const regexp = new RegExp(`^${number.repeat(14)}$`);
        return regexp.test(cnpj);
      }
    );

    if (cnpj.length !== 14) {
      return false;
    }

    if (invalids.length > 0) {
      return false;
    }

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    const digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i -= 1) {
      sum += parseInt(numbers.charAt(length - i)) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(0))) {
      return false;
    }

    length += 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i -= 1) {
      sum += parseInt(numbers.charAt(length - i)) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(1))) {
      return false;
    }

    return true;
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {bool}
   * @description Returns true if the CPF is valid, false otherwise.
   */
  validateCPF = (text: string) => {
    const cpf = text.replace(/[^\d]+/g, '');

    if (
      cpf === '' ||
      cpf.length !== 11 ||
      [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ].includes(cpf)
    ) {
      return false;
    }

    let add = 0;
    for (let i = 0; i < 9; i += 1) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(9))) {
      return false;
    }

    add = 0;
    for (let i = 0; i < 10; i += 1) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  };

  /**
   * @function
   * @param {string} text Text aim to be formatted.
   * @returns {bool}
   * @description Returns true if the RG is valid, false otherwise.
   */
  validateRG = (text: string) => {
    const rg = text.replace(/[.]|[-]/g, '');

    if (rg.length > 9 || rg.length < 8) {
      return false;
    }
    return true;
  };

  /**
   * @function validateQuoteLimitDate
   * @param {dayjs.Dayjs} quoteLimitDate Dayjs date to be validated.
   * @returns {object}
   * @description Returns true if the quote limit date is valid. The quote limit date must be a week day and must be between 9am and 5pm (GMT-3).
   */
  validateQuoteLimitDate = (
    quoteLimitDate: dayjs.Dayjs
  ): IValidateQuoteLimitDateResult => {
    const isQuoteLimitDateValid = {
      min: true,
      max: true,
      weekday: true,
      businessTime: true,
    };

    const now = dayjs();
    const quoteLimitDateBRT = quoteLimitDate.clone().utcOffset(-180);

    if (quoteLimitDate.isBefore(now.clone().add(2, 'hours'), 'minutes')) {
      isQuoteLimitDateValid.min = false;
    }

    if (quoteLimitDate.isAfter(now.clone().add(7, 'days'), 'minutes')) {
      isQuoteLimitDateValid.max = false;
    }

    if (
      quoteLimitDateBRT.weekday() === 0 ||
      quoteLimitDateBRT.weekday() === 6
    ) {
      isQuoteLimitDateValid.weekday = false;
    }

    if (quoteLimitDateBRT.hour() < 9 || quoteLimitDateBRT.hour() >= 17) {
      isQuoteLimitDateValid.businessTime = false;
    }

    return isQuoteLimitDateValid;
  };
}

export interface IValidateQuoteLimitDateResult {
  min: boolean;
  max: boolean;
  weekday: boolean;
  businessTime: boolean;
}

export default new Validator();
