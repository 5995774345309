/* eslint-disable react-hooks/exhaustive-deps */
import { FormInstance, SelectProps } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useFindPlaceQuery } from '../../../../graphql/generated/graphql';
import { UF } from '../../../../structures/interfaces/UF';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import {
  IUpdatePlaceForm,
  IUpdatePlaceFormInternalStates,
  UpdatePlaceForm,
} from '../../Place/UpdatePlaceForm/UpdatePlaceForm';
import { formatFindPlaceToPlaceForm } from '../../Place/UpdatePlaceForm/UpdatePlaceFormUtils';

interface ICoordinates {
  placeId: string;
  latitude: number;
  longitude: number;
}

interface IUpdatePlaceModalFormProps {
  /**
   * Form instance for father component be able to retrieve the form data
   */
  form: FormInstance<IUpdatePlaceForm>;
  /**
   * List of places options to be showed in Select field
   */
  placesOptions?: SelectProps['options'];
  /**
   * Whether is loading place options
   */
  loading?: boolean;
  /**
   * State to set the coordinates of the place
   */
  setPlaceCoordinates?: React.Dispatch<
    React.SetStateAction<ICoordinates | undefined>
  >;
}

/**
 * Form to select a place by name and edit it
 */
export const RoutePlaceForm = ({
  form,
  placesOptions,
  loading,
  setPlaceCoordinates,
}: IUpdatePlaceModalFormProps): ReactElement => {
  const [updateInternalStates, setUpdateInternalStates] =
    useState<IUpdatePlaceFormInternalStates>();

  const [selectedPlaceId, setSelectedPlaceId] = useState<string>();

  const findPlaceVariables = selectedPlaceId
    ? { input: { id: selectedPlaceId } }
    : undefined;

  const [findPlaceQueryResult, executeFindPlaceQuery] = useFindPlaceQuery({
    pause: true,
    variables: findPlaceVariables,
  });

  const handleSelectPlace = (placeId?: string) => {
    if (selectedPlaceId !== placeId) {
      form.resetFields();
      form.setFieldsValue({ name: placeId });

      setSelectedPlaceId(placeId);
    }
  };

  useEffect(() => {
    const { data, fetching } = findPlaceQueryResult;

    if (data && !fetching) {
      const formValues = formatFindPlaceToPlaceForm(data.FindPlace);

      form.setFieldsValue(formValues);

      const { latitude, longitude, id } = data.FindPlace;

      if (setPlaceCoordinates && latitude && longitude)
        setPlaceCoordinates({
          placeId: id,
          latitude: Number(formValues.latitude),
          longitude: Number(formValues.longitude),
        });

      setUpdateInternalStates({
        state_id: data.FindPlace.state_id as UF,
        has_balance: data.FindPlace.has_balance,
        has_dirt_road: data.FindPlace.has_dirt_road,
      });
    }
  }, [findPlaceQueryResult]);

  useEffect(() => {
    if (selectedPlaceId) {
      executeFindPlaceQuery();
    }
  }, [selectedPlaceId]);

  return (
    <>
      <ErrorAlert
        error={findPlaceQueryResult.error}
        style={{ marginBottom: 24 }}
      />
      <UpdatePlaceForm
        form={form}
        formProps={{
          disabled:
            loading ||
            findPlaceQueryResult.fetching ||
            !findPlaceQueryResult.data?.FindPlace,
        }}
        usePlaceNameSelect={{
          onSelect: handleSelectPlace,
          options: placesOptions,
          fetching: loading || findPlaceQueryResult.fetching,
        }}
        updateInternalStates={updateInternalStates}
        updateInternalStatesCallback={() => setUpdateInternalStates(undefined)}
      />
    </>
  );
};
