import { parseLocaleNumber } from '../../../utils';

const formatPriceInputToCents = (value?: string | number | null) => {
  if (value === undefined || value === null) return undefined;

  const parsedValue = parseLocaleNumber(String(value));

  if (parsedValue) return Math.trunc(parsedValue * 100);

  return undefined;
};

export const PriceInputUtils = {
  formatPriceInputToCents,
};
