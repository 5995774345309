import { ReactElement } from 'react';
import { useAttachmentsModal } from './AttachmentsModal.hooks';
import { AttachmentFormModal } from '../AttachmentFormModal';
import { AttachmentTableModal } from '../AttachmentsTableModal';

export const AttachmentsModal = ({
  attachmentsTableError,
  attachmentsTableFetching,
  createAttachmentResponse,
  findAttachmentsResponse,
  form,
  handlers: {
    handleAttachmentsTableModalClose,
    handleClose,
    handleDeleteAttachment,
    handleFinish,
    handleOpen,
  },
  isAttachmentFormModalOpen,
  isAttachmentsTableModalOpen,
}: ReturnType<typeof useAttachmentsModal>): ReactElement => {
  return (
    <>
      <AttachmentFormModal
        form={form}
        handleClose={handleClose}
        handleFinish={handleFinish}
        isOpen={isAttachmentFormModalOpen}
        mutationResponse={createAttachmentResponse}
      />
      <AttachmentTableModal
        handleClose={handleAttachmentsTableModalClose}
        handleDelete={handleDeleteAttachment}
        handleOpenFormModal={handleOpen}
        isOpen={isAttachmentsTableModalOpen}
        queryResponse={findAttachmentsResponse}
        error={attachmentsTableError}
        loading={attachmentsTableFetching}
      />
    </>
  );
};
