import {
  Button,
  Form,
  FormItemProps,
  FormProps,
  ModalProps,
  Popconfirm,
  SelectProps,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import { ICompanySelectProps } from '../../../../../components/Company/CompanySelect/CompanySelect';
import { useUserContext } from '../../../../../contexts/UserContext/useUserContext';
import { useCommonI18n } from '../../../../../core/Common/Common.hooks';
import {
  UpdateGroupInput,
  useQuotationGroupFormModal_CreateGroupMutation,
  useQuotationGroupFormModal_FindCarrierCompaniesQuery,
  useQuotationGroupFormModal_FindQuotationGroupQuery,
  useQuotationGroupFormModal_UpdateGroupMutation,
} from '../../../../../graphql/generated/graphql';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import {
  IQuotationGroupMembersTableData,
  IQuotationGroupMembersTableProps,
} from '../QuotationGroupMembersTable/QuotationGroupMembersTable';
import { IActionsCardProps } from '../QuotationGroupMembersTable/QuotationGroupMembersTable.components';
import { IQuotationGroupFormModalValues } from './QuotationGroupFormModal';

export const useQuotationGroupFormModal = () => {
  const [isOpen, setIsOpen] = useState<'create' | 'edit' | 'view' | false>(
    false
  );
  const [groupId, setGroupId] = useState<string | null>();

  const handlers = useMemo(
    () => ({
      openCreate() {
        setIsOpen('create');
        setGroupId(null);
      },
      openView(groupId: string) {
        setIsOpen('view');
        setGroupId(groupId);
      },
      openEdit(groupId: string) {
        setIsOpen('edit');
        setGroupId(groupId);
      },
      close() {
        setIsOpen(false);
      },
      afterClose() {
        setGroupId(null);
      },
    }),
    []
  );

  const isCreateOpen = isOpen === 'create';
  const isEditOpen = isOpen === 'edit';
  const isViewOpen = isOpen === 'view';

  return { handlers, groupId, isCreateOpen, isEditOpen, isViewOpen };
};

export interface IUseQuotationGroupFormModalActions {
  action: 'create' | 'update' | 'read';
  groupId?: string | null;
  afterClose?: () => void;
  onCancel?: () => void;
  onOpen?: () => void;
  onMutationSuccess?: () => void;
}

export const useQuotationGroupFormModalActions = ({
  action,
  groupId,
  afterClose,
  onCancel,
  onOpen,
  onMutationSuccess,
}: IUseQuotationGroupFormModalActions) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.shipper.quotationGroupsPage.components.quotationGroupFormModal',
  });

  const {
    tKeys: {
      remove: removeButton,
      select: selectPlaceholder,
      close: closeText,
      yes: yesText,
    },
  } = useCommonI18n();

  const props = {
    create: {
      isFormDisabled: false,
      showModalConfirm: false,
      showTableActions: true,
      title: t('modal.create.title'),
      okText: t('modal.create.okText'),
      showMembersSelect: true,
    },
    update: {
      isFormDisabled: false,
      showModalConfirm: false,
      showTableActions: true,
      title: t('modal.update.title'),
      okText: t('modal.update.okText'),
      showMembersSelect: true,
    },
    read: {
      isFormDisabled: true,
      showModalConfirm: true,
      showTableActions: false,
      title: t('modal.read.title'),
      okText: undefined,
      showMembersSelect: false,
    },
  };

  const actionProps = props[action];

  const [form] = Form.useForm<IQuotationGroupFormModalValues>();

  const nameInput = Form.useWatch('name', form);
  const membersSelect = Form.useWatch('members', form);

  const [isCancelPopconfirmOpen, setIsCancelPopconfirmOpen] = useState(false);

  const [initialGroupMembers, setInitialGroupMembers] = useState<
    IQuotationGroupMembersTableData[]
  >([]);

  const [groupMembers, setGroupMembers] = useState<
    IQuotationGroupMembersTableData[]
  >([]);

  const [isGroupMemberEdited, setIsGroupMemberEdited] = useState(false);

  const [search, setSearch] = useState<string>();

  const searchDebounced = useDebounce(search, 300);

  const [findCarrierCompaniesResult] =
    useQuotationGroupFormModal_FindCarrierCompaniesQuery({
      variables: {
        search: searchDebounced,
        excludeIds: groupMembers.map(({ id }) => id),
      },
    });

  const [createGroupResult, executeCreateGroupMutation] =
    useQuotationGroupFormModal_CreateGroupMutation();

  const [updateGroupResult, executeUpdateGroupMutation] =
    useQuotationGroupFormModal_UpdateGroupMutation();

  const [findGroupResult] = useQuotationGroupFormModal_FindQuotationGroupQuery({
    variables: { groupId: groupId || '' },
    pause: !groupId,
  });

  const {
    handlers: {
      clear: clearSelectedRowKeys,
      remove: handleRemoveSelectedRowKeys,
    },
    rowSelection,
    selectedRowKeys,
  } = DefaultTable.useRowSelection<IQuotationGroupMembersTableData>();

  const { userData } = useUserContext();

  const clearStates = () => {
    setInitialGroupMembers({ ...groupMembers });
    setGroupMembers([]);
    setIsGroupMemberEdited(false);
    clearSelectedRowKeys();
    setSearch(undefined);
    form.resetFields();
  };

  const handleFinish = (formValues: { name: string }) => {
    if (userData?.company?.id) {
      onCancel && onCancel();

      if (action === 'create') {
        executeCreateGroupMutation({
          input: {
            owner_id: userData.company.id,
            name: formValues.name.trim(),
            carrier_ids: groupMembers.map(({ id }) => ({ id })),
          },
        }).then(({ data, error }) => {
          if (error) {
            onOpen && onOpen();
          } else if (data && !error) {
            clearStates();
            onMutationSuccess && onMutationSuccess();
            message.success({
              key: data.CreateGroup.id,
              content: t('modal.create.onSuccessMessage'),
            });
          }
        });
      } else if (action === 'update' && groupId) {
        const variables: { input: UpdateGroupInput } = {
          input: {
            group_id: groupId,
            owner_id: userData.company.id,
          },
        };

        let hasChanged = false;

        if (findGroupResult.data?.FindGroup.name !== formValues.name.trim()) {
          hasChanged = true;
          variables.input.new_name = formValues.name.trim();
        }

        const addedMembers = groupMembers.filter(
          member => !initialGroupMembers.some(({ id }) => member.id === id)
        );

        if (addedMembers.length > 0) {
          hasChanged = true;
          variables.input.carriers_to_add = addedMembers.map(({ id }) => ({
            id,
          }));
        }

        const removedMembers = initialGroupMembers.filter(
          member => !groupMembers.some(({ id }) => member.id === id)
        );

        if (removedMembers.length > 0) {
          hasChanged = true;
          variables.input.carriers_to_remove = removedMembers.map(({ id }) => ({
            id,
          }));
        }

        if (hasChanged) {
          executeUpdateGroupMutation(variables).then(({ data, error }) => {
            if (error) {
              onOpen && onOpen();
            } else if (data && !error) {
              clearStates();
              onMutationSuccess && onMutationSuccess();
              message.success({
                key: data.UpdateGroup.id,
                content: t('modal.update.onSuccessMessage'),
              });
            }
          });
        }
      }
    }
  };

  const handleSearch = (value?: string) => {
    setSearch(value);
  };

  const handleAdd = () => {
    const members = form.getFieldValue('members') as SelectProps['options'];
    if (members) {
      const addedMembers =
        findCarrierCompaniesResult.data?.FindCompanies.filter(({ id }) =>
          members.some(member => member.value === id)
        ).map(member => ({ ...member, created_at: dayjs().toISOString() })) ||
        [];
      setGroupMembers(c => [...addedMembers, ...c]);
      handleSearch();
      setIsGroupMemberEdited(true);
      form.resetFields(['members']);
      form.validateFields(['membersTable']);
    }
  };

  const handleRemove = (id: string) => {
    setGroupMembers(c =>
      c.filter(c2 => {
        return c2.id !== id;
      })
    );

    handleRemoveSelectedRowKeys(id);
    setIsGroupMemberEdited(true);

    form.validateFields(['membersTable']);
  };

  const handleRemoveSelected = () => {
    setGroupMembers(selectedCompanies =>
      selectedCompanies.filter(
        company => !selectedRowKeys.some(key => company.id === key)
      )
    );

    clearSelectedRowKeys();
    setIsGroupMemberEdited(true);

    form.validateFields(['membersTable']);
  };

  useEffect(() => {
    if (findGroupResult.data) {
      form.setFieldValue('name', findGroupResult.data.FindGroup.name);
      if (findGroupResult.data.FindGroup.members) {
        const members = findGroupResult.data.FindGroup.members.map(
          ({ company, created_at }) => ({ ...company, created_at })
        );

        setGroupMembers(members);
        setInitialGroupMembers(members);
        setIsGroupMemberEdited(false);
      }
    }
  }, [findGroupResult.data, form]);

  const tableProps: IQuotationGroupMembersTableProps = {
    dataSource: groupMembers,
    loading: findGroupResult.fetching,
    rowSelection: actionProps.showMembersSelect ? rowSelection : undefined,
    actionsColumn: actionProps.showTableActions
      ? {
          title: 'Ações',
          render(_, record) {
            return (
              <Button
                danger
                onClick={() => handleRemove(record.id)}
                type="link"
              >
                {removeButton}
              </Button>
            );
          },
        }
      : undefined,
  };

  const tableActionsCardProps: IActionsCardProps = actionProps.showTableActions
    ? {
        createButtonProps: {
          onClick: handleAdd,
          disabled: !membersSelect?.length,
        },
        removeSelectedButtonProps: {
          onConfirm: handleRemoveSelected,
          disabled: selectedRowKeys.length === 0,
        },
      }
    : {};

  const formProps: FormProps = {
    disabled:
      actionProps.isFormDisabled ||
      createGroupResult.fetching ||
      updateGroupResult.fetching ||
      findGroupResult.fetching,
    form: form,
    layout: 'vertical',
    onFinish: handleFinish,
  };

  const isFormEdited =
    nameInput !== findGroupResult.data?.FindGroup.name ||
    (action === 'create' && groupMembers.length > 0) ||
    (action === 'update' && isGroupMemberEdited);

  type IRule = NonNullable<
    FormItemProps<IQuotationGroupFormModalValues>['rules']
  >[number];

  type IRuleNames = 'minGroupMembers' | 'maxGroupMembers';

  const rules: Record<IRuleNames, IRule> = {
    minGroupMembers: {
      validator() {
        if (groupMembers.length < 2) {
          return Promise.reject(t('form.rules.minGroupMembers'));
        }

        return Promise.resolve();
      },
    },
    maxGroupMembers: {
      validator() {
        if (groupMembers.length > 20) {
          return Promise.reject(t('form.rules.maxGroupMembers'));
        }

        return Promise.resolve();
      },
    },
  };

  const membersSelectProps: ICompanySelectProps = {
    allowClear: true,
    labelInValue: true,
    loading: findCarrierCompaniesResult.fetching,
    mode: 'multiple',
    onSearch: handleSearch,
    optionFilterProp: 'label',
    options: findCarrierCompaniesResult.data?.FindCompanies,
    placeholder: selectPlaceholder,
    showSearch: true,
  };

  const onCancelEdited = () => {
    setIsCancelPopconfirmOpen(true);
  };

  const cancelButton = <Button onClick={onCancel}>{closeText}</Button>;

  const popConfirmCancelButton = (
    <Popconfirm
      cancelButtonProps={{ type: 'primary' }}
      defaultOpen={false}
      getPopupContainer={node => node.parentElement || document.body}
      okButtonProps={{ type: 'default', danger: true }}
      okText={yesText}
      onConfirm={() => {
        onCancel?.();
        setIsCancelPopconfirmOpen(false);
      }}
      onOpenChange={open => setIsCancelPopconfirmOpen(open)}
      open={isCancelPopconfirmOpen}
      title={t('modal.update.popConfirm')}
    >
      <Button onClick={() => setIsCancelPopconfirmOpen(true)}>
        {closeText}
      </Button>
    </Popconfirm>
  );

  const modalCancelButton = isFormEdited
    ? popConfirmCancelButton
    : cancelButton;

  const modalConfirmButton = (
    <Button
      disabled={
        !isFormEdited || groupMembers.length < 2 || groupMembers.length > 20
      }
      hidden={actionProps.showModalConfirm}
      htmlType="submit"
      onClick={form.submit}
      type="primary"
    >
      {actionProps.okText}
    </Button>
  );

  const modalFooter = {
    read: <>{cancelButton}</>,
    create: (
      <>
        {cancelButton}
        {modalConfirmButton}
      </>
    ),
    update: (
      <>
        {modalCancelButton}
        {modalConfirmButton}
      </>
    ),
  };

  const modalProps: Partial<ModalProps> = {
    afterClose: () => {
      setIsCancelPopconfirmOpen(false);
      afterClose?.();
    },
    footer: modalFooter[action],
    onCancel: isFormEdited && action === 'update' ? onCancelEdited : onCancel,
    title: actionProps.title,
    width: 992,
  };

  return {
    tableProps,
    tableActionsCardProps,
    formProps,
    form: {
      isFormTouched: isFormEdited,
      membersTable: { rules },
      members: {
        selectProps: membersSelectProps,
        isVisible: actionProps.showMembersSelect,
      },
    },
    errors: {
      createGroup: createGroupResult.error,
      updateGroup: updateGroupResult.error,
      findGroup: findGroupResult.error,
    },
    modalProps,
  };
};
