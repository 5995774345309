import { ReactElement } from 'react';
import {
  IResponsiveDescriptionsProps,
  ResponsiveDescriptions,
} from '../../../../../../../components/AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { Checkbox, Descriptions, Skeleton } from 'antd';
import { FormattedDateTime } from '../../../../../../../components/Formatters/FormattedDateTime/FormattedDateTime';
import { UserDetailsPage_FindUserAndPermissionsQuery } from '../../../../../../../graphql/generated/graphql';
import { useTranslation } from 'react-i18next';

export type IUserDataDescriptionsData = Omit<
  UserDetailsPage_FindUserAndPermissionsQuery['FindUser'],
  'companies'
>;

export interface IUserDataDescriptionsProps
  extends Omit<IResponsiveDescriptionsProps, 'children'> {
  loading?: boolean;
  data?: IUserDataDescriptionsData | null;
}

export const UserDataDescriptions = ({
  loading,
  data,
  ...props
}: IUserDataDescriptionsProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.user.userDetailsPage.components.userDataDescriptions',
  });

  const column = {
    xxl: 3,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <ResponsiveDescriptions column={{ ...column }} layout={layout} {...props}>
      <ResponsiveDescriptions.Item span={{}} label={t('labels.id')}>
        <Skeleton loading={loading} active paragraph={false}>
          {data?.id}
        </Skeleton>
      </ResponsiveDescriptions.Item>
      <Descriptions.Item label={t('labels.name')}>
        <Skeleton loading={loading} active paragraph={false}>
          {data?.name}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('labels.email')}>
        <Skeleton loading={loading} active paragraph={false}>
          {data?.email}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('labels.active')}>
        <Skeleton loading={loading} active paragraph={false}>
          <Checkbox disabled checked={data?.active} />
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('labels.createdAt')}>
        <Skeleton loading={loading} active paragraph={false}>
          {data?.created_at ? (
            <FormattedDateTime dateTime={data?.created_at} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('labels.updatedAt')}>
        <Skeleton loading={loading} active paragraph={false}>
          {data?.updated_at ? (
            <FormattedDateTime dateTime={data?.updated_at} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('labels.deletedAt')}>
        <Skeleton loading={loading} active paragraph={false}>
          {data?.deleted_at ? (
            <FormattedDateTime dateTime={data?.deleted_at} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
    </ResponsiveDescriptions>
  );
};
