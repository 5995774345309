import { Descriptions, Skeleton } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IResponsiveDescriptionsProps,
  ResponsiveDescriptions,
} from '../../../../../../../../components/AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';

export type ICarrierCompanyDataDescriptionsData = {
  has_fleet?: boolean | null;
  fleet_quantity?: number | null;
};

export interface ICompanyDataDescriptionsProps
  extends Omit<IResponsiveDescriptionsProps, 'children'> {
  loading?: boolean;
  data?: ICarrierCompanyDataDescriptionsData | null;
}

export const CarrierCompanyDataDescriptions = ({
  loading,
  data,
  ...props
}: ICompanyDataDescriptionsProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.carrier.carrierCompanyDataDescriptions',
  });

  const { t: t0 } = useTranslation();

  const column = {
    xxl: 3,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <ResponsiveDescriptions column={{ ...column }} layout={layout} {...props}>
      <ResponsiveDescriptions.Item label={t('descriptions.hasFleet')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.has_fleet ? t0('general.yes') : t0('general.no')}
        </Skeleton>
      </ResponsiveDescriptions.Item>
      <Descriptions.Item label={t('descriptions.fleetQuantity')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.fleet_quantity ? data.fleet_quantity : <>-</>}
        </Skeleton>
      </Descriptions.Item>
    </ResponsiveDescriptions>
  );
};
