/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import jwtDecode from 'jwt-decode';
import { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../api/errorHandler';
import { getApiUrl } from '../../api/urql/client';
import {
  LoginInput,
  LoginMutation,
  useLoginMutation,
  useRefreshTokenMutation,
} from '../../graphql/generated/graphql';
import { IAPIError } from '../../utils/interfaces';

interface IUserLoggedIn {
  isUserLoggedIn: true;
  userId: string;
  roles: string[] | null;
  permissions: IPermission[] | null;
  accessToken: string;
  refreshToken: string;
  isAdmOrSup: boolean;
}

interface IUserLoggedOut {
  isUserLoggedIn: false;
  userId: undefined;
  roles: undefined;
  permissions: undefined;
  accessToken: undefined;
  refreshToken: undefined;
  isAdmOrSup: undefined;
}

interface IUserLoggingIn {
  isUserLoggedIn: undefined;
  userId: undefined;
  roles: undefined;
  permissions: undefined;
  accessToken: undefined;
  refreshToken: undefined;
  isAdmOrSup: undefined;
}

type ILoginCredentials = LoginInput & { keepConnected: boolean };

type ILoginData = LoginMutation['Login'];

export type IAuthProvider = {
  login: (credentials: ILoginCredentials) => Promise<ILoginData>;
  logout: () => void;
} & (IUserLoggedIn | IUserLoggedOut | IUserLoggingIn);

interface IJwtToken {
  iat: string;
  exp: string;
}

interface IAccessToken extends IJwtToken {
  id: string;
}

interface IRefreshToken extends IJwtToken {
  access_token: string;
  user_id: string;
}

export type IPermissionAction = 'READ' | 'CREATE' | 'UPDATE' | 'DELETE';

const PermissionModules = [
  'APPROVE_COMPANIES',
  'CANCELLATION_REASONS',
  'CANCELLATIONS',
  'COMISSION_PAYMENTS',
  'COMPANIES',
  'COMPANY_SETTINGS',
  'COMPANY_STATUS',
  'CTES',
  'DRIVERS',
  'FREIGHT_ATTACHMENTS',
  'FREIGHT_RATINGS',
  'FREIGHTS',
  'GROUP',
  'HIRE_QUOTATIONS',
  'HOME',
  'LOADINGS',
  'MISSING_FREIGHTS',
  'MODULES',
  'PACKINGS',
  'PERMISSION_TEMPLATES',
  'PLACES',
  'POLICY',
  'PRODUCT_TYPE_HAS_PACKINGS',
  'PRODUCT_TYPES',
  'PRODUCTS',
  'QUOTATION_CONFIRMATIONS',
  'QUOTATION_RATINGS',
  'QUOTATIONS',
  'QUOTING_FREIGHTS',
  'RATING_REASONS',
  'RATINGS',
  'REFUSAL_REASONS',
  'REFUSALS',
  'REPROACH_COMPANIES',
  'ROLES',
  'ROUTES',
  'THEMES',
  'TRUCKER_USERS',
  'USER_HAS_PERMISSIONS',
  'USER_HAS_ROLES',
  'USER_INTERNALLY',
  'USER_PERMISSIONS',
  'USERS',
  'VEHICLE_TYPES',
] as const;

export type IPermissionModule = (typeof PermissionModules)[number];

export interface IPermission {
  module: IPermissionModule;
  actions: IPermissionAction[];
}

interface IExecuteRefreshTokenParams {
  accessToken?: string | null;
  refreshToken?: string | null;
}

interface IExecuteRefreshTokenOptions {
  firstRequest?: boolean;
}

const decodeJwtTokenIfValid = <T extends IJwtToken>(token?: string | null) => {
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<T>(token);

    const expirationTime = Number(decodedToken.exp);
    const currentTime = Math.floor(Date.now() / 1000);

    if (expirationTime >= currentTime) {
      return decodedToken;
    }
  } catch (err) {
    return null;
  }
  return null;
};

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [authProviderData, setAuthProviderData] = useState<IAuthProvider>(
    AuthContextDefaultValue
  );

  const [keepConnected] = useState(() =>
    localStorage.getItem('keep_connected') === 'true' ? true : false
  );

  const { t } = useTranslation();

  const refreshTokenIntervalRef = useRef<number | null>(null);
  const userIsLoggedInRef = useRef(true);

  const [, executeRefreshTokenMutation] = useRefreshTokenMutation();

  const [, executeLoginMutation] = useLoginMutation();

  const logout = () => {
    userIsLoggedInRef.current = false;

    setAuthProviderData(authProviderData => ({
      ...authProviderData,
      login,
      isUserLoggedIn: false,
      accessToken: undefined,
      refreshToken: undefined,
      permissions: undefined,
      roles: undefined,
      userId: undefined,
      isAdmOrSup: undefined,
    }));

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('keep_connected');
    localStorage.removeItem('company');
  };

  const setLoggedInUserStates = (data: ILoginData, keepConnected = false) => {
    const decodedAccessToken = decodeJwtTokenIfValid<IAccessToken>(
      data.access_token
    );

    if (decodedAccessToken?.id) {
      userIsLoggedInRef.current = true;

      const isAdmOrSup = data.roles?.some(
        role => role === 'ADMINISTRATOR' || role === 'SUPPORT'
      );

      setAuthProviderData({
        login,
        logout,
        isUserLoggedIn: true,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        permissions: data.permissions,
        roles: data.roles,
        userId: decodedAccessToken.id,
        isAdmOrSup: !!isAdmOrSup,
      } as IAuthProvider);

      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('keep_connected', String(keepConnected));
    } else {
      logout();
    }
  };

  const login = ({
    credential,
    password,
    keepConnected,
  }: ILoginCredentials) => {
    setAuthProviderData(
      authProviderData =>
        ({
          ...authProviderData,
          isUserLoggedIn: undefined,
        } as IAuthProvider)
    );

    return new Promise<ILoginData>((resolve, reject) =>
      executeLoginMutation(
        { input: { credential, password } },
        { url: getApiUrl() }
      ).then(({ data, error }) => {
        if (error) {
          logout();
          const { message, description } = errorHandler(
            error.graphQLErrors as unknown as IAPIError[]
          );
          return reject({ message, description });
        }

        if (data) {
          setLoggedInUserStates(data.Login, keepConnected);
          return resolve(data.Login);
        }
      })
    );
  };

  const executeRefreshToken = (
    { accessToken, refreshToken }: IExecuteRefreshTokenParams,
    { firstRequest }: IExecuteRefreshTokenOptions = {
      firstRequest: false,
    }
  ) => {
    if (accessToken && refreshToken) {
      executeRefreshTokenMutation(undefined, {
        fetchOptions: {
          headers: {
            authorization: `Bearer ${accessToken}`,
            'refresh-token': `Bearer ${refreshToken}`,
          },
        },
        url: getApiUrl(),
      }).then(({ data, error }) => {
        if (error) {
          if (!firstRequest)
            notification.error({
              message: t('errors.invalidToken.message'),
              description: t('errors.invalidToken.description'),
            });

          logout();
        }

        if (data) {
          if (firstRequest || userIsLoggedInRef.current) {
            setLoggedInUserStates(data.RefreshToken, keepConnected);
          }
        }
      });
    } else {
      if (!firstRequest)
        notification.error({
          message: t('errors.invalidToken.message'),
          description: t('errors.invalidToken.description'),
        });

      logout();
    }
  };

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');

    const decodedAccessToken = decodeJwtTokenIfValid(access_token);
    const decodedRefreshToken = decodeJwtTokenIfValid(refresh_token);

    if (decodedAccessToken || keepConnected) {
      if (decodedRefreshToken) {
        executeRefreshToken(
          {
            accessToken: access_token,
            refreshToken: refresh_token,
          },
          {
            firstRequest: true,
          }
        );
      } else {
        logout();
      }
    } else {
      logout();
    }

    return () => {
      setAuthProviderData(authProviderData => ({
        ...authProviderData,
        isUserLoggedIn: undefined,
        accessToken: undefined,
        refreshToken: undefined,
        permissions: undefined,
        roles: undefined,
        userId: undefined,
        isAdmOrSup: undefined,
      }));
    };
  }, []);

  useEffect(() => {
    const refreshTokenTimer = 15 * 60 * 1000; // 15 minutes

    if (authProviderData.isUserLoggedIn && !refreshTokenIntervalRef.current) {
      refreshTokenIntervalRef.current = window.setInterval(() => {
        const refresh_token = localStorage.getItem('refresh_token');

        if (refresh_token !== authProviderData.refreshToken) {
          const decodedRefreshToken =
            decodeJwtTokenIfValid<IRefreshToken>(refresh_token);

          if (decodedRefreshToken) {
            executeRefreshToken({
              accessToken: decodedRefreshToken.access_token,
              refreshToken: refresh_token,
            });
          } else {
            executeRefreshToken({
              accessToken: authProviderData.accessToken,
              refreshToken: authProviderData.refreshToken,
            });
          }
        } else {
          executeRefreshToken({
            accessToken: authProviderData.accessToken,
            refreshToken: authProviderData.refreshToken,
          });
        }
      }, refreshTokenTimer);
    }

    return () => {
      if (refreshTokenIntervalRef.current) {
        clearInterval(refreshTokenIntervalRef.current);
        refreshTokenIntervalRef.current = null;
      }
    };
  }, [authProviderData.isUserLoggedIn]);

  return (
    <AuthContext.Provider value={authProviderData}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContextDefaultValue: IAuthProvider = {
  login: () => {
    return new Promise(() => null);
  },
  logout: () => {
    return;
  },
  isUserLoggedIn: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  userId: undefined,
  roles: undefined,
  permissions: undefined,
  isAdmOrSup: undefined,
};

export const AuthContext = createContext<IAuthProvider>(
  AuthContextDefaultValue
);
