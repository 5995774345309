import { ReactElement } from 'react';
import { FunctionEnum } from '../../../graphql/generated/graphql';
import { useTranslation } from 'react-i18next';

interface ICompanyFunctionProps {
  companyFunction?: FunctionEnum;
}

export const CompanyFunction = ({
  companyFunction,
}: ICompanyFunctionProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'enums.companyFunction',
  });

  if (!companyFunction) return <></>;

  return <>{t(companyFunction.toLowerCase())}</>;
};
