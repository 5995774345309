import { useTranslation } from 'react-i18next';

import { Descriptions, Typography } from 'antd';

import Formatter from '../../../../classes/Formatter';

const { Text } = Typography;

export const COMPANY_CONTACT_DESCRIPTIONS_FRAGMENT = `
  phone
  email
`;

interface ICompanyContact {
  phone: string;
  email: string;
}

export const CompanyContactDescriptions = ({
  companyContact,
}: {
  companyContact: ICompanyContact;
}) => {
  const COMPANY_CONTACT_DESCRIPTIONS_TRANSLATION_PATH =
    'components.company.companyDescriptions.companyContactDescriptions';

  const { t } = useTranslation();

  return (
    <Descriptions>
      <Descriptions.Item
        label={t(
          `${COMPANY_CONTACT_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.phone`
        )}
      >
        {Formatter.formatPhoneWithoutDDI(
          companyContact.phone.replace('+55', '')
        )}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={t(
          `${COMPANY_CONTACT_DESCRIPTIONS_TRANSLATION_PATH}.descriptions.email`
        )}
      >
        <Text copyable>{companyContact.email}</Text>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CompanyContactDescriptions;
