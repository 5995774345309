import { Divider, Layout, Space, Tooltip, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { SupportHelpContact } from '../../../../components/Help/SupportHelpContact/SupportHelpContact';
// import TranslateMenu from '../../../../components/layout/main/translationMenu';
import { CSSProperties } from 'react';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import Formatter from '../../../../classes/Formatter';
import { InfoCircleOutlined } from '@ant-design/icons';

const SupportDataContent = ({
  showDivider = true,
  wrapperStyle,
}: {
  showDivider?: boolean;
  wrapperStyle?: CSSProperties;
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'layout.main.header.supportData',
  });

  const {
    token: { colorWarning },
  } = theme.useToken();

  const { userData } = useUserContext();

  if (!userData?.company) return <></>;

  return (
    <Tooltip
      title={t('seeingCompanyData', {
        companyName: userData?.company?.trade_name,
      })}
    >
      <Space size="middle">
        <Space align="center" style={wrapperStyle}>
          <Typography.Text style={{ color: colorWarning, fontSize: '20px' }}>
            <InfoCircleOutlined />
          </Typography.Text>
          <Typography.Paragraph
            ellipsis
            style={{
              color: 'white',
              fontWeight: 'bold',
              margin: 0,
            }}
          >
            {t('seeingCompanyData', {
              companyName: userData.company.trade_name,
            })}
          </Typography.Paragraph>
        </Space>
        {showDivider && (
          <Divider type="vertical" style={{ backgroundColor: 'white' }} />
        )}
      </Space>
    </Tooltip>
  );
};

const DesktopSupportData = () => {
  return (
    <SupportDataContent
      wrapperStyle={{
        maxWidth: 'clamp(120px, calc(100vw - 648px), 300px)',
        overflow: 'hidden',
      }}
    />
  );
};

const MobileSupportData = () => {
  return (
    <SupportDataContent
      showDivider={false}
      wrapperStyle={{
        maxWidth: 'min(300px, calc(100vw - 190px))',
        overflow: 'hidden',
      }}
    />
  );
};

export const SupportData = {
  Desktop: DesktopSupportData,
  Mobile: MobileSupportData,
};

type IHeaderProps = Parameters<typeof Layout.Header>[number];

export const StickyHeader = (props: IHeaderProps) => {
  const { children, style, ...headerProps } = props;

  const stickyStyle = {
    ...style,
    alignItems: 'center',
    display: 'flex',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 6,
  } as const;

  return (
    <Layout.Header style={stickyStyle} {...headerProps}>
      {children}
    </Layout.Header>
  );
};

export const HeaderSupportHelpContact = () => {
  return (
    <SupportHelpContact
      style={{ position: 'static', display: 'flex' }}
      buttonProps={{ style: { fontSize: 24, color: 'white' } }}
      popoverProps={{ placement: 'bottomLeft' }}
    />
  );
};

const UserDataContent = ({ style }: { style?: CSSProperties }) => {
  const { userData } = useUserContext();

  const userName = userData?.name;
  const companyName = userData?.company?.trade_name;
  const companyDocument = Formatter.formatCPF_CNPJ(
    userData?.company?.document_number
  );

  const data = [userName, companyDocument, companyName];

  return (
    <Space size={0} direction="vertical" style={style}>
      {data.map((value, index) => (
        <Typography.Paragraph
          key={index}
          ellipsis
          style={{
            color: 'inherit',
            fontWeight: 'inherit',
            margin: '0 4px',
          }}
        >
          {value}
        </Typography.Paragraph>
      ))}
    </Space>
  );
};

const DesktopUserData = () => {
  return (
    <UserDataContent
      style={{
        fontWeight: '500',
        maxWidth: '300px',
        overflow: 'hidden',
      }}
    />
  );
};

const MobileUserData = () => {
  return (
    <UserDataContent
      style={{
        color: 'white',
        fontWeight: '500',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: '24px 16px 0',
      }}
    />
  );
};

export const UserData = {
  Desktop: DesktopUserData,
  Mobile: MobileUserData,
};
