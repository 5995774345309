/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_FREIGHT_FORM_TRANSLATION_PATH } from '../CreateFreightForm';

export interface IFreightDatesFormValues {
  landing_date: dayjs.Dayjs;
  shipment_date: dayjs.Dayjs;
}

interface IFreightDatesFormProps {
  disabled?: boolean;
  form: FormInstance<IFreightDatesFormValues>;
  timeSpentInRoute: number;
}

export const FreightDatesForm = ({
  form,
  disabled,
  timeSpentInRoute,
}: IFreightDatesFormProps): ReactElement => {
  const { t } = useTranslation();

  const shipmentDate = Form.useWatch('shipment_date', form);

  const disableDateBeforeToday = (current: dayjs.Dayjs) => {
    //disable today after 13:00 (GMT-3)
    if (current.isSame(dayjs(), 'day')) {
      return dayjs().utcOffset(-180).hour() >= 13;
    }

    return current && current.isBefore(dayjs(), 'day');
  };

  const disableDateBeforeShipmentDate = (current: dayjs.Dayjs) => {
    return current && shipmentDate && current.isBefore(shipmentDate, 'day');
  };

  const landingDateAfterShipmentDateValidator = (
    rule: unknown,
    landingDate: dayjs.Dayjs
  ) => {
    if (
      landingDate &&
      shipmentDate &&
      landingDate.isBefore(shipmentDate, 'day')
    ) {
      return Promise.reject(
        t(
          `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.validations.landingDateAfterShipmentDate`
        )
      );
    }

    return Promise.resolve();
  };

  const shipmentDateAfterTodayValidator = (
    rule: unknown,
    shipmentDate: dayjs.Dayjs
  ) => {
    if (shipmentDate && shipmentDate.isBefore(dayjs(), 'day')) {
      return Promise.reject(
        t(
          `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.validations.shipmentDateAfterQuoteLimitDate`
        )
      );
    }
    return Promise.resolve();
  };

  const timeBetweenShipmentAndLandingValidator = () => {
    const { landing_date, shipment_date } = form.getFieldsValue([
      'landing_date',
      'shipment_date',
    ]);

    const timeBetweenShipmentAndLandingInDays = dayjs(landing_date).diff(
      dayjs(shipment_date),
      'days'
    );

    // estimated the driver will spent 10h per day driving
    const timeInsertedToSpentInRouteInSeconds =
      (timeBetweenShipmentAndLandingInDays + 1) * 10 * 60 * 60;

    if (timeInsertedToSpentInRouteInSeconds < timeSpentInRoute) {
      return Promise.reject(
        t(
          `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.validations.timeBetweenShipmentAndLanding`
        )
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (timeSpentInRoute && form.getFieldValue('landing_date'))
      form.validateFields(['landing_date']);
  }, [timeSpentInRoute]);

  const freightDateFormTitle = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.dateSubFormTitle`)}
      </Typography.Text>
    </Row>
  );

  const colSpan = {
    xxl: 4,
    xl: 6,
    lg: 8,
    md: 12,
    sm: 12,
    xs: 24,
  };

  return (
    <Card title={freightDateFormTitle} bordered={false}>
      <Form
        disabled={disabled}
        form={form}
        layout="vertical"
        scrollToFirstError
        size="small"
      >
        <Row gutter={[24, 24]}>
          <Col {...colSpan}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.shipment_date`
              )}
              name="shipment_date"
              required
              tooltip={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.tooltips.shipment_date`
              )}
              rules={[
                { required: true },
                { validator: shipmentDateAfterTodayValidator },
              ]}
              validateFirst
            >
              <DatePicker
                disabledDate={disableDateBeforeToday}
                format={'DD/MM/YY'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col {...colSpan}>
            <Form.Item
              label={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.labels.landing_date`
              )}
              name="landing_date"
              required
              dependencies={['shipment_date']}
              rules={[
                { required: true },
                { validator: landingDateAfterShipmentDateValidator },
                {
                  validator: timeBetweenShipmentAndLandingValidator,
                  warningOnly: true,
                },
              ]}
              validateFirst
              tooltip={t(
                `${CREATE_FREIGHT_FORM_TRANSLATION_PATH}.fields.tooltips.timeBetweenShipmentAndLanding`
              )}
            >
              <DatePicker
                disabled={!shipmentDate}
                disabledDate={disableDateBeforeShipmentDate}
                format={'DD/MM/YY'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
