import './index.scss';

interface IFooterProps {
  children: React.ReactNode;
  className?: string;
}

const Footer = ({ children, className }: IFooterProps): JSX.Element => {
  return <footer className={className || ''}>{children}</footer>;
};

export default Footer;
