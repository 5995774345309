import { theme } from 'antd';
import { ReactElement } from 'react';

const isProductionEnvironment =
  process.env.REACT_APP_ENVIRONMENT === 'production';

export const DevelopmentBadge = (): ReactElement => {
  const {
    token: { colorError, fontFamily },
  } = theme.useToken();

  const badge = (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: colorError,
        bottom: 0,
        color: 'white',
        display: 'flex',
        fontFamily,
        fontSize: 16,
        fontWeight: 800,
        height: 50,
        justifyContent: 'center',
        left: 0,
        pointerEvents: 'none',
        position: 'fixed',
        transform: 'rotate(35deg)',
        transformOrigin: '50% -150%',
        width: 250,
        zIndex: 1000,
      }}
    >
      Development
    </div>
  );

  return <>{!isProductionEnvironment && badge}</>;
};
