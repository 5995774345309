import { ReactElement } from 'react';
import { QuotationGroupFormModal } from '../../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroupFormModal/QuotationGroupFormModal';
import { QuotationGroupTable } from '../../../../../pages/shipper/QuotationGroupsPage/components/QuotationGroupTable/QuotationGroupTable';
import { useQuotationGroupsTable } from './QuotationGroupsTable.hooks';
import { QuotationGroupTableFragment } from '../../../../../graphql/generated/graphql';
import { Alert, Card } from 'antd';
import { useTranslation } from 'react-i18next';

interface IQuotationGroupsTableProps {
  data?: QuotationGroupTableFragment[];
}

export const QuotationGroupsTable = ({
  data,
}: IQuotationGroupsTableProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.freight.freightDetails.components.quotationGroupsTable',
  });

  const {
    groupId,
    actionsColumn,
    handlers: { afterClose, close },
    isViewOpen,
  } = useQuotationGroupsTable();

  return (
    <>
      <Card title={t('title')}>
        <QuotationGroupTable
          dataSource={data}
          pagination={{ hideOnSinglePage: true, pageSize: 5 }}
          actionColumn={actionsColumn}
        />
      </Card>
      <QuotationGroupFormModal
        key={`read_${groupId}`}
        afterClose={afterClose}
        action="read"
        groupId={groupId}
        onCancel={close}
        open={isViewOpen}
      />
    </>
  );
};

const NoQuotationGroupAlert = () => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.freight.freightDetails.components.quotationGroupsTable.noQuotationGroupAlert',
  });

  return (
    <Alert
      closable
      message={t('message')}
      showIcon
      style={{ marginBottom: 24 }}
      type="info"
    />
  );
};

QuotationGroupsTable.NoQuotationGroupAlert = NoQuotationGroupAlert;
