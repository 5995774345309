import { useEffect, useState } from 'react';
import { LastCompanyStatusTag } from '../../../../../components/Company/LastCompanyStatusTag/LastCompanyStatusTag';
import { useUserContext } from '../../../../../contexts/UserContext/useUserContext';
import {
  CompanyDetailsPageQuery,
  CompanyStatusEnum,
  useCompanyDetailsPage_BeginCompanyAnalysisMutation,
} from '../../../../../graphql/generated/graphql';

interface IUseBeginCompanyAnalysisMutationInput {
  companyId?: string;
  companyStatus?: CompanyStatusEnum;
}

const useBeginCompanyAnalysisMutation = ({
  companyId,
  companyStatus,
}: IUseBeginCompanyAnalysisMutationInput) => {
  const { userData } = useUserContext();

  const [result, executeMutation] =
    useCompanyDetailsPage_BeginCompanyAnalysisMutation();

  useEffect(() => {
    const beforeAnalysisStatus = [
      CompanyStatusEnum.Pending,
      CompanyStatusEnum.RectifiedData,
    ];

    if (
      companyId &&
      userData &&
      beforeAnalysisStatus.some(status => status === companyStatus)
    ) {
      executeMutation({
        companyId,
        managerId: userData.id,
      });
    }
  }, [companyId, companyStatus, executeMutation, userData]);

  return result;
};

type ICompanyStatus = NonNullable<
  CompanyDetailsPageQuery['FindCompany']['companyStatus']
>[number];

const useLastCompanyStatus = (status?: ICompanyStatus[] | null) => {
  const [lastStatus, setLastStatus] = useState(() =>
    LastCompanyStatusTag.getLastCompanyStatusTag(status)
  );

  useEffect(() => {
    setLastStatus(LastCompanyStatusTag.getLastCompanyStatusTag(status));
  }, [status]);

  return lastStatus;
};

export interface IUseCompanyStatus {
  data?: {
    FindCompany: { id: string; companyStatus?: ICompanyStatus[] | null };
  };
}

export const useCompanyStatus = ({ data }: IUseCompanyStatus) => {
  const [companyStatus, setCompanyStatus] = useState<ICompanyStatus[]>([]);

  const lastCompanyStatus = useLastCompanyStatus(companyStatus);
  const result = useBeginCompanyAnalysisMutation({
    companyId: data?.FindCompany.id,
    companyStatus: lastCompanyStatus?.status,
  });

  useEffect(() => {
    if (data?.FindCompany.companyStatus) {
      setCompanyStatus(data.FindCompany.companyStatus);
    }
  }, [data]);

  useEffect(() => {
    const { data } = result;

    if (data?.ChangeCompanyStatus) {
      setCompanyStatus(companyStatus => [
        ...companyStatus,
        data.ChangeCompanyStatus,
      ]);
    }
  }, [result]);

  return { companyStatus, lastCompanyStatus };
};
