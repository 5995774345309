import { Form, FormProps, Input } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../../../../classes/Formatter';
import {
  CompanySelect,
  ICompanySelectProps,
} from '../../../../../../../components/Company/CompanySelect/CompanySelect';
import {
  CreateUserInternallyInput,
  UserInternalAvailableRolesEnum,
} from '../../../../../../../graphql/generated/graphql';
import { validatePhoneNumber } from '../../../../../../../utils/inputRules';
import { UserRoleSelect } from '../UserRoleSelect/UserRoleSelect';

export interface ICreateUserInternallyFormValues {
  company: string;
  email: string;
  name: string;
  phone: string;
  role: UserInternalAvailableRolesEnum;
}

export interface ICreateUserInternallyFormProps
  extends FormProps<ICreateUserInternallyFormValues> {
  companySelectProps?: Pick<
    ICompanySelectProps,
    'loading' | 'options' | 'onSearch'
  >;
}

export const CreateUserInternallyForm = ({
  companySelectProps,
  ...props
}: ICreateUserInternallyFormProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.user.usersPage.components.createUserInternallyForm',
  });

  const defaultForm = Form.useForm<ICreateUserInternallyFormValues>()[0];
  const form = props.form || defaultForm;

  const handleCompanyChange = (companyId: string) => {
    const selectedCompany = companySelectProps?.options?.find(
      ({ id }) => id === companyId
    );

    if (selectedCompany) {
      form.setFieldValue('role', selectedCompany.company_function);
      form.validateFields(['role']);
    }
  };

  const handleCompanyClear = () => {
    form.setFieldValue('roleName', undefined);
  };

  return (
    <Form
      layout="vertical"
      validateTrigger={['onChange', 'onBlur']}
      {...props}
      form={form}
    >
      <Form.Item
        label={t('fields.company')}
        name={'company'}
        required
        rules={[{ required: true }]}
      >
        <CompanySelect
          allowClear
          onClear={handleCompanyClear}
          onChange={handleCompanyChange}
          optionFilterProp="label"
          showSearch
          {...companySelectProps}
        />
      </Form.Item>
      <Form.Item
        label={t('fields.email')}
        name={'email'}
        required
        rules={[
          { required: true },
          { type: 'email', validateTrigger: 'onBlur' },
        ]}
        validateFirst
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label={t('fields.name')}
        name={'name'}
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('fields.phone')}
        name={'phone'}
        normalize={Formatter.formatPhoneWithoutDDI}
        required
        rules={[
          { required: true },
          {
            ...validatePhoneNumber,
            message: t('rules.invalidPhoneNumber'),
            validateTrigger: 'onBlur',
          },
        ]}
        validateFirst
      >
        <Input type="tel" />
      </Form.Item>
      <Form.Item
        dependencies={['company']}
        label={t('fields.role')}
        name={'role'}
        required
        rules={[{ required: true }]}
      >
        <UserRoleSelect disabled />
      </Form.Item>
    </Form>
  );
};

const toCreateUserInternallyInput = (
  formValues: ICreateUserInternallyFormValues
): CreateUserInternallyInput => {
  const companyId = formValues.company;
  const email = formValues.email.trim().toLowerCase();
  const name = formValues.name.trim();
  const phone = '+55' + formValues.phone.replace(/[\D]/g, '');
  const role = formValues.role;

  return {
    company_id: companyId,
    email,
    name,
    phone,
    role,
  };
};

CreateUserInternallyForm.toCreateUserInternallyInput =
  toCreateUserInternallyInput;
