import { Row, RowProps } from 'antd';
import { ReactElement } from 'react';

interface IFixedFooterContainerProps {
  children: React.ReactNode;
}

export const FixedFooterContainer = ({
  children,
}: IFixedFooterContainerProps): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: 'calc(100vh - 64px)',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

interface IFixedFooterContainerInnerProps extends Partial<RowProps> {
  children: React.ReactNode;
}

const FixedFooterContainerInner = ({
  children,
  ...rowProps
}: IFixedFooterContainerInnerProps) => {
  return (
    <Row
      style={{
        marginBottom: 24,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 24,
        maxHeight: '100%',
        overflow: 'auto',
        padding: '0px 12px',
      }}
      {...rowProps}
    >
      {children}
    </Row>
  );
};

FixedFooterContainer.Inner = FixedFooterContainerInner;
