import Formatter from '../../../../classes/Formatter';
import { UniqueArray } from '../../../../classes/UniqueArray';
import { MoveTypeEnum } from '../../../../graphql/generated/graphql';
import { IFindRoute, IPlaceCity } from './ListRoutes';

export const slugifyRoutes = (routes: Omit<IFindRoute, 'slug'>[]) => {
  return routes.map(route => {
    const withdrawalCity = route.places?.find(
      place => place.move_type === MoveTypeEnum.Withdrawal
    );
    const dropoffCity = route.places?.find(
      place => place.move_type === MoveTypeEnum.Delivery
    );

    return {
      ...route,
      slug: Formatter.removeAccents(
        `${route.name} ${withdrawalCity?.name} ${withdrawalCity?.city_name} - ${withdrawalCity?.state_id} ${dropoffCity?.name} ${dropoffCity?.city_name} - ${dropoffCity?.state_id}`.toLowerCase()
      ),
    };
  });
};

export interface IGetUniquePlacesProps {
  moveType: MoveTypeEnum;
  routes?: IFindRoute[];
}

export const getUniquePlaces = ({
  moveType,
  routes,
}: IGetUniquePlacesProps) => {
  if (!routes) return [];

  return new UniqueArray(
    routes
      .flatMap(({ places }) =>
        places?.map(place => ({
          city_id: place?.city_id,
          city_name: place?.city_name,
          state_id: place?.state_id,
          move_type: place?.move_type,
        }))
      )
      .filter(place => place && place.move_type === moveType) as IPlaceCity[],
    (place1, place2) => place1.city_id === place2.city_id
  );
};
