import { gql, TypedDocumentNode } from 'urql';

import {
  ICreateAccountAPI,
  ICreateUser,
  ICreateUserResponse,
} from '../interfaces/User';

const queries = {
  createUser: (): TypedDocumentNode<
    { createdUser: ICreateUserResponse },
    { createUserInput: ICreateUser }
  > => gql`
    mutation CreateUser($createUserInput: CreateUserInput!) {
      createdUser: CreateUser(input: $createUserInput) {
        token
      }
    }
  `,

  createAccount: (): TypedDocumentNode<
    { createdAccount: { hasSuccess: boolean } },
    { createAccount: ICreateAccountAPI }
  > => gql`
    mutation CreateAccount($createAccount: SendValidationEmailInput!) {
      createdAccount: SendValidationEmail(input: $createAccount) {
        hasSuccess: has_success
      }
    }
  `,
};

export default queries;
