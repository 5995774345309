import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { QuotationDetailsQuery } from '../../../graphql/generated/graphql';
import { IConfirmQuotationStatus } from '../ConfirmQuotation/ConfirmQuotation/ConfirmQuotation';

type IUseConfirmQuotationStatusProps = Pick<
  QuotationDetailsQuery['FindQuotation'],
  'expires_in'
> & {
  confirmations?: unknown[] | null;
};

export const useConfirmQuotationStatus = (
  quotation?: IUseConfirmQuotationStatusProps
) => {
  const [confirmQuotationStatus, setConfirmQuotationStatus] =
    useState<IConfirmQuotationStatus>();

  useEffect(() => {
    if (quotation?.expires_in) {
      const expiresIn = dayjs(quotation.expires_in);

      if (quotation.confirmations?.length) {
        setConfirmQuotationStatus('CONFIRMED');
      } else if (expiresIn.isBefore(dayjs(), 'seconds')) {
        setConfirmQuotationStatus('EXPIRED');
      } else {
        setConfirmQuotationStatus('WAITING');
      }
    }
  }, [quotation]);

  return [confirmQuotationStatus];
};
