import { SelectProps } from 'antd';
import {
  FindPackagesQuery,
  FindPackagesQueryVariables,
  useFindPackagesQuery,
} from '../../../../graphql/generated/graphql';
import { useState } from 'react';

type IPackage = FindPackagesQuery['FindManyPackings']['data'][number];

export interface IUsePackageSelectProps {
  productTypeId?: string;
}

export const usePackageSelect = ({ productTypeId }: IUsePackageSelectProps) => {
  const [selectedPackage, setSelectedPackage] = useState<IPackage>();

  const variables: FindPackagesQueryVariables | undefined = productTypeId
    ? {
        where: {
          product_type_has_packing: {
            some: {
              product_type_id: {
                equals: productTypeId,
              },
            },
          },
        },
        pagination: {
          take: 1000,
        },
      }
    : undefined;

  const [findPackagesResult] = useFindPackagesQuery({
    variables,
    pause: !variables,
  });

  const packageOptions: SelectProps['options'] | undefined =
    findPackagesResult.data?.FindManyPackings.data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  const handleSelectPackage = (id: string) => {
    const selectedPackage = findPackagesResult.data?.FindManyPackings.data.find(
      ({ id: packageId }) => packageId === id
    );

    setSelectedPackage(selectedPackage);
  };

  return {
    findPackagesResult,
    options: packageOptions,
    selectedPackage,
    handlers: { handleSelectPackage },
  };
};
