import { Alert, AlertProps, Button, Col } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Access } from '../../../Access/Access';

export const RouteErrorAlert = ({
  visible,
  ...props
}: AlertProps & { visible?: boolean }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.shipper.createFreightForm',
  });

  const routeErrorAlertRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    routeErrorAlertRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [visible]);

  return (
    <Col
      span={24}
      ref={routeErrorAlertRef}
      style={{ display: visible ? 'block' : 'none' }}
    >
      <Alert
        type="error"
        message={t('routesTable.validation.required')}
        {...props}
      />
    </Col>
  );
};

const CreateRouteButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.shipper.createFreightForm',
  });

  return (
    <Button type="primary" onClick={onClick}>
      {t('routesTable.actions.create')}
    </Button>
  );
};

export const CreateRouteButtonAccess = Access(CreateRouteButton, {
  acceptedPermissions: [{ module: 'ROUTES', actions: ['CREATE'] }],
});

export const CancelFormButton = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.shipper.createFreightForm',
  });

  return (
    <Button danger size="small" onClick={onClick}>
      {t('actions.cancel.button')}
    </Button>
  );
};
