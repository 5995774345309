import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import {
  CompanyStatusEnum as CustomCompanyStatusEnum,
  CompanyStatusReverseEnum,
} from '../../../structures/interfaces/Company/CompanyStatus';
import { CompanyStatusEnum } from '../../../graphql/generated/graphql';

interface ICompanyStatusTagProps {
  companyStatus: CustomCompanyStatusEnum | CompanyStatusEnum;
}

export const CompanyStatusTag = ({
  companyStatus,
}: ICompanyStatusTagProps): ReactElement => {
  const { t } = useTranslation();

  const status = CompanyStatusReverseEnum[companyStatus];

  const statusTagColor = {
    approved: 'green',
    blocked: 'red',
    inAnalysis: 'yellow',
    pending: 'yellow',
    rectifiedData: 'yellow',
    reproved: 'orange',
    waitingData: undefined,
  };

  return (
    <Tag color={statusTagColor[status]}>
      {t(`enums.company.companyStatus.${status}`)}
    </Tag>
  );
};

export default CompanyStatusTag;
