import { Card, CardProps, Descriptions, Skeleton, Typography } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FreightPlaceDescriptionsFragment,
  MoveTypeEnum,
} from '../../../graphql/generated/graphql';
import { ResponsiveDescriptions } from '../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { FormattedDate } from '../../Formatters/FormattedDate/FormattedDate';
import { FormattedDateTime } from '../../Formatters/FormattedDateTime/FormattedDateTime';

interface IFreightPlaceDescriptionsProps {
  data?: FreightPlaceDescriptionsFragment;
  loading?: boolean;
}

const TRANSLATION_PATH = 'components.freight.freightPlaceDescriptions';

export const FreightPlaceDescriptions = ({
  data,
  loading,
}: IFreightPlaceDescriptionsProps): ReactElement => {
  const { t } = useTranslation();

  const column = {
    xxl: 3,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  const withdrawalPlace = data?.route.places?.find(
    place => place.move_type === MoveTypeEnum.Withdrawal
  );

  const deliveryPlace = data?.route.places?.find(
    place => place.move_type === MoveTypeEnum.Delivery
  );

  return (
    <ResponsiveDescriptions column={column} layout={layout}>
      <Descriptions.Item
        label={t(`${TRANSLATION_PATH}.descriptions.shipmentDate`)}
      >
        <Skeleton active paragraph={false} loading={loading}>
          {data?.shipment_date && (
            <FormattedDate dateTime={data.shipment_date} />
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${TRANSLATION_PATH}.descriptions.place.WITHDRAWAL`)}
      >
        <Skeleton active paragraph={false} loading={loading}>
          {withdrawalPlace && withdrawalPlace.name}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t(`${TRANSLATION_PATH}.descriptions.city`)}>
        <Skeleton active paragraph={false} loading={loading}>
          {withdrawalPlace && (
            <>
              {withdrawalPlace.city.city_name} - {withdrawalPlace.city.state_id}
            </>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${TRANSLATION_PATH}.descriptions.landingDate`)}
      >
        <Skeleton active paragraph={false} loading={loading}>
          {data?.landing_date && <FormattedDate dateTime={data.landing_date} />}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${TRANSLATION_PATH}.descriptions.place.DELIVERY`)}
      >
        <Skeleton active paragraph={false} loading={loading}>
          {deliveryPlace && deliveryPlace.name}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t(`${TRANSLATION_PATH}.descriptions.city`)}>
        <Skeleton active paragraph={false} loading={loading}>
          {deliveryPlace && (
            <>
              {deliveryPlace.city.city_name} - {deliveryPlace.city.state_id}
            </>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`${TRANSLATION_PATH}.descriptions.quoteLimit`)}
      >
        <Skeleton active paragraph={false} loading={loading}>
          {data?.quote_limit && (
            <FormattedDateTime dateTime={data.quote_limit} />
          )}
        </Skeleton>
      </Descriptions.Item>
    </ResponsiveDescriptions>
  );
};

const FreightPlaceDescriptionsCard = ({ children, ...props }: CardProps) => {
  const { t } = useTranslation();

  const title = (
    <Typography.Text>{t(`${TRANSLATION_PATH}.title`)}</Typography.Text>
  );

  return (
    <Card title={title} bordered={false} {...props}>
      {children}
    </Card>
  );
};

FreightPlaceDescriptions.Card = FreightPlaceDescriptionsCard;
