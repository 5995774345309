import { ReactElement } from 'react';
import {
  CompanyGraphQlOutputNameFragment,
  CompanyNameFragment,
  FreightCompanyNameFragment,
} from '../../../graphql/generated/graphql';

interface CompanyNameProps {
  company:
    | CompanyNameFragment
    | FreightCompanyNameFragment
    | CompanyGraphQlOutputNameFragment;
}

/**
 * Renders the company name and address in a formatted string.
 *
 * @param {CompanyNameProps} company - An object containing the company's address, company name, and trade name.
 * @returns {ReactElement} A React element containing the formatted string of the company name and address.
 */
export const CompanyName = ({ company }: CompanyNameProps): ReactElement => {
  return <>{getCompanyName(company)}</>;
};

const getCompanyName = ({
  address,
  company_name,
  trade_name,
}: CompanyNameProps['company']) => {
  const companyAddress = address?.city
    ? `${address.city.city_name} - ${address.city.state_id}`
    : '';

  const companyName = trade_name || company_name;

  if (!companyName) return '';

  if (!companyAddress) return companyName;

  return `${companyName} (${companyAddress})`;
};

CompanyName.getCompanyName = getCompanyName;
