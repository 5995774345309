import { Table, TableProps } from 'antd';
import { ReactElement } from 'react';
import { DefaultDateTimeColumn } from './components/DefaultDateTimeColumn/DefaultDateTimeColumn';
import { useRowSelection } from './DefaultTable.hooks';

type IDefaultTableProps<RecordType> = TableProps<RecordType>;

export const DefaultTable = <RecordType extends object>(
  props: IDefaultTableProps<RecordType>
): ReactElement => {
  return (
    <Table
      className="ant-table-wrapper-responsive"
      pagination={{ hideOnSinglePage: true }}
      {...props}
    />
  );
};

DefaultTable.Columns = {
  CreatedAt: DefaultDateTimeColumn,
  UpdatedAt: DefaultDateTimeColumn,
};

DefaultTable.useRowSelection = useRowSelection;
