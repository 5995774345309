import { Breakpoint } from 'antd';
import { useScreenWidth } from '../useScreenWidth/useScreenWidth';

export const useBreakpoints = <T,>(
  breakpointRules?: Partial<Record<Breakpoint, T>>
) => {
  const { screenWidth } = useScreenWidth();

  const breakpoints: Record<Breakpoint, number> = {
    xxl: 1600,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 576,
  };

  if (!breakpointRules) return;

  if (screenWidth >= breakpoints.xxl) {
    return breakpointRules['xxl'];
  } else if (screenWidth >= breakpoints.xl) {
    return breakpointRules['xl'];
  } else if (screenWidth >= breakpoints.lg) {
    return breakpointRules['lg'];
  } else if (screenWidth >= breakpoints.md) {
    return breakpointRules['md'];
  } else if (screenWidth >= breakpoints.sm) {
    return breakpointRules['sm'];
  } else if (screenWidth < breakpoints.xs) {
    return breakpointRules['xs'];
  }
};
