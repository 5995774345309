import ptBrTranslations from './pt-br';
// import enUsTranslations from './en-us';
// import esTranslations from './es';

// export const SUPPORTED_LOCALES = ['pt_BR', 'en_US', 'es'] as const;
export const SUPPORTED_LOCALES = ['pt_BR'] as const;

export type ISupportedLocale = (typeof SUPPORTED_LOCALES)[number];

const translations = {
  pt_BR: ptBrTranslations,
  // en_US: enUsTranslations,
  // es: esTranslations,
};

export default translations;
