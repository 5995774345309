import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import Container from '../../components/container';
import ContainerCard from '../../components/container/containerCard';
import { useMenuItem } from '../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../hooks/useTitle/useTitle';

export const HomePage = (): ReactElement => {
  const { t } = useTranslation();

  useTitle({ title: 'tab.home' });
  useMenuItem({ selectedMenu: ['/home'] });

  return (
    <Container subHeaderTitle={t('tab.home')}>
      <ContainerCard title={t('pages.home.title')}></ContainerCard>
    </Container>
  );
};
