import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import { theme } from 'antd';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type IDefaultPageHeaderProps = PageHeaderProps;

export const DefaultPageHeader = ({
  style,
  ...props
}: IDefaultPageHeaderProps): ReactElement => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageHeader
      style={{
        backgroundColor: token.colorBgContainer,
        color: token.colorText,
        paddingBlock: 16,
        paddingInline: 24,
        ...style,
      }}
      onBack={handleBack}
      ghost={false}
      {...props}
    />
  );
};
