import axios from 'axios';
import {
  IGetDirectionsParams,
  IGetDirectionsResponse,
  IGetDirectionsUrlParams,
} from './MapboxControllerInterfaces';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export class MapboxController {
  static getDirections({
    profile,
    coordinates,
    ...props
  }: IGetDirectionsParams) {
    return new Promise<IGetDirectionsResponse>((resolve, reject) => {
      const baseUrl = `${process.env.REACT_APP_MAPBOX_API_URL}/directions/v5/mapbox/${profile}`;

      const coordinatesPairs = coordinates
        .map(({ longitude, latitude }) => `${longitude},${latitude}`)
        .join(';');

      const defaultParams = {
        overview: 'simplified',
        geometries: 'geojson',
        access_token: MAPBOX_ACCESS_TOKEN,
      };

      const params = { ...defaultParams, ...props.params };

      const paramsKeys = Object.keys(params) as Array<
        keyof IGetDirectionsUrlParams
      >;

      const urlParams = paramsKeys.reduce((acc, key) => {
        if (params[key]) {
          acc.push(`${key}=${params[key]}`);
        }
        return acc;
      }, [] as string[]);

      const url = `${baseUrl}/${coordinatesPairs}?${urlParams.join('&')}`;

      axios
        .get<IGetDirectionsResponse>(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default MapboxController;
