import { Route } from 'react-router-dom';

import SignUp from '../../pages/SignUp/SignUpPage/SignUpPage';
import ActivateUserPage from '../../pages/SignUp/ActivateUserPage/ActivateUserPage';

const SignUpRoutes = (
  <>
    <Route path="/signUp" element={<SignUp currentStep="signUp" />} />
    <Route
      path="/resumeSignUp"
      element={<SignUp currentStep="resumeSignUp" />}
    />
    <Route
      path="/accountCreated"
      element={<SignUp currentStep="accountCreated" />}
    />
    <Route
      path="/validateEmail"
      element={<SignUp currentStep="validateEmail" />}
    />
    <Route
      path="/createPassword"
      element={<SignUp currentStep="createPassword" />}
    />
    <Route path="/termsOfUse" element={<SignUp currentStep="termsOfUse" />} />
    <Route
      path="/registerCompany"
      element={<SignUp currentStep="registerCompany" />}
    />
    <Route
      path="/regularizeCompany"
      element={<SignUp currentStep="regularizeCompany" />}
    />
    <Route
      path="/companyCreated"
      element={<SignUp currentStep="companyCreated" />}
    />
    <Route
      path="/accountBlocked"
      element={<SignUp currentStep="accountBlocked" />}
    />
    <Route
      path="/accountReproved"
      element={<SignUp currentStep="accountReproved" />}
    />
    <Route
      path="/accountInApproval"
      element={<SignUp currentStep="accountInApproval" />}
    />
    <Route path="/activateUser" element={<ActivateUserPage />} />
  </>
);

export default SignUpRoutes;
