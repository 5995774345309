import { Modal, ModalProps } from 'antd';
import { ReactElement } from 'react';

export type IAttachmentModalProps = Partial<ModalProps>;

export const AttachmentModal = ({
  children,
  ...props
}: IAttachmentModalProps): ReactElement => {
  return <Modal {...props}>{children}</Modal>;
};
