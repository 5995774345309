import './TitleHeader.scss';

interface ITitleHeaderProps {
  decorated?: boolean;
  centered?: boolean;
  className?: string;
  titleText?: string;
  subtitleText?: string;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
}

export const TitleHeader = ({
  decorated,
  centered,
  className,
  titleText,
  subtitleText,
  titleStyle,
  subtitleStyle,
}: ITitleHeaderProps): JSX.Element => {
  const title = titleText ? (
    <h1 className="title" style={titleStyle}>
      {titleText}
    </h1>
  ) : null;

  const subtitle = subtitleText ? (
    <p className="subtitle" style={subtitleStyle}>
      {subtitleText}
    </p>
  ) : null;

  const headerClassName = [
    'title-header_container',
    decorated && 'decorated',
    centered && 'centered',
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <header className={headerClassName}>
      {title}
      {subtitle}
    </header>
  );
};

export default TitleHeader;
