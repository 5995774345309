import { UF } from '../interfaces/UF';
import { IContact } from '../interfaces/Contact';
import { IAddress } from '../interfaces/Address';
import { IDocumentType } from '../interfaces/Company/DocumentType';
import {
  ICompany,
  ICompanyAPI,
  ICarrierCompany,
  ICompanyFragment,
  ICompanyFragmentAPI,
  IFindCompanyByIdAPI,
} from '../interfaces/Company/Company';
import {
  ICompanyType,
  CompanyTypeReverseEnum,
} from '../interfaces/Company/CompanyType';
import {
  ICompanyStatus,
  CompanyStatusReverseEnum,
} from '../interfaces/Company/CompanyStatus';
import {
  ICompanyFunction,
  CompanyFunctionReverseEnum,
} from '../interfaces/Company/CompanyFunction';

export class CompanyFragmentModel implements ICompanyFragment {
  id: string;
  documentNumber: string;
  documentType: IDocumentType;
  tradeName: string;
  companyName: string;
  urlLogo: string;
  companyFunction: ICompanyFunction;
  companyType: ICompanyType;
  companyStatus: ICompanyStatus;
  contact: IContact;
  address: IAddress;

  constructor(data: ICompanyFragmentAPI) {
    this.id = data.id;
    this.documentNumber = data.documentNumber;
    this.documentType = data.documentType;
    this.tradeName = data.tradeName;
    this.companyName = data.companyName;
    this.urlLogo = data.urlLogo;
    this.companyFunction = CompanyFunctionReverseEnum[data.companyFunction];
    this.companyType = CompanyTypeReverseEnum[data.companyType];
    this.companyStatus =
      CompanyStatusReverseEnum[
        data.companyStatus ? data.companyStatus[0].status : 'PENDING'
      ];
    this.contact = data.contacts ? data.contacts[0] : ({} as IContact);
    this.address = data.address;
  }
}

export class CompanyModel extends CompanyFragmentModel implements ICompany {
  motherCompany?: ICompanyFragment;
  referenceCompany?: ICompanyFragment;

  constructor(data: ICompanyAPI) {
    const { motherCompany, referenceCompany, ...company } = data;

    super(company);
    this.motherCompany = motherCompany
      ? new CompanyFragmentModel(motherCompany)
      : undefined;
    this.referenceCompany = referenceCompany
      ? new CompanyFragmentModel(referenceCompany)
      : undefined;
  }
}

export class CarrierCompanyModel
  extends CompanyModel
  implements ICarrierCompany
{
  hasFleet: boolean;
  fleetQuantity?: number;
  operationAreas: UF[];
  policies: {
    id: string;
    name: string;
    expiresIn: string;
    fileUrl: string;
  }[];

  constructor(data: IFindCompanyByIdAPI) {
    const { hasFleet, fleetQuantity, operationAreas, policies, ...company } =
      data;

    super(company);
    this.hasFleet = hasFleet;
    this.fleetQuantity = fleetQuantity;
    this.operationAreas = operationAreas
      ? operationAreas.map(({ stateId }) => stateId)
      : [];
    this.policies = policies || [];
  }
}
