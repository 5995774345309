import dayjs from 'dayjs';
import Formatter from '../../../../classes/Formatter';
import { FindPlaceQuery } from '../../../../graphql/generated/graphql';
import { roundWithDecimals } from '../../../../utils';
import { IUpdatePlaceForm } from './UpdatePlaceForm';

export const formatFindPlaceToPlaceForm = (
  findPlace: FindPlaceQuery['FindPlace']
) => {
  const {
    balance_capacity,
    dirt_road_distance,
    opening_hour,
    closing_hour,
    ...place
  } = findPlace;

  const formValues: IUpdatePlaceForm = {
    ...place,
    balance_capacity: findPlace.has_balance
      ? Formatter.formatVolume(
          String(
            roundWithDecimals(Number(balance_capacity) / 1000, 3).toFixed(3)
          )
        )
      : undefined,
    dirt_road_distance: findPlace.has_dirt_road
      ? Formatter.formatMoney(
          String(
            roundWithDecimals(Number(dirt_road_distance) / 1000, 2).toFixed(2)
          )
        )
      : undefined,
    opening_hour: [dayjs(opening_hour), dayjs(closing_hour)],
  };

  return formValues;
};
