import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  RefusalReasonSelectQueryVariables,
  RefusalTriggerEnum,
  useRefusalReasonSelectQuery,
} from '../../../../graphql/generated/graphql';

type IRefusalReasonSelect = Omit<SelectProps, 'options'> & {
  refusalReasonTrigger?: RefusalTriggerEnum;
};

/**
 * Ant design select with RefusalReason options
 */
export const RefusalReasonSelect = ({
  refusalReasonTrigger,
  loading,
  disabled,
  ...props
}: IRefusalReasonSelect) => {
  const { t } = useTranslation();

  const variables = refusalReasonTrigger
    ? ({
        where: {
          trigger: {
            equals: refusalReasonTrigger,
          },
        },
        pagination: {
          take: 1000,
        },
      } as RefusalReasonSelectQueryVariables)
    : undefined;

  const [result] = useRefusalReasonSelectQuery({
    variables,
    pause: !variables,
  });

  const { data, fetching } = result;

  return (
    <>
      <Select
        {...props}
        loading={loading || fetching}
        disabled={disabled || fetching}
        options={data?.FindManyRefusalReasons}
        notFoundContent={t(
          'components.refusalReason.refusalReasonSelect.notFoundMessage'
        )}
      />
    </>
  );
};
