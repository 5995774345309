import { Col, Row } from 'antd';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Formatter from '../../../../classes/Formatter';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import {
  ListRoutesQuery,
  ListRoutesQueryVariables,
  MoveTypeEnum,
  useListRoutesQuery,
} from '../../../../graphql/generated/graphql';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { IRouteFilterForm, RoutesFilter } from '../RoutesFilter/RoutesFilter';
import { RoutesTable } from '../RoutesTable/RoutesTable';
import { slugifyRoutes, getUniquePlaces } from './ListRoutesUtils';

export interface IPlaceCity {
  city_id: number;
  city_name: string;
  state_id: string;
}

export type IFindRoute = ListRoutesQuery['FindRoutes'][0] & {
  slug?: string;
};

export const ListRoutes = (): ReactElement => {
  const navigate = useNavigate();

  const { userData } = useUserContext();

  const [routeFilterValues, setRouteFilterValues] =
    useState<IRouteFilterForm>();
  const [routesWithSlug, setRoutesWithSlug] = useState<IFindRoute[]>();

  const findRoutesVariables: ListRoutesQueryVariables | undefined =
    userData?.company
      ? {
          where: {
            company: {
              reference_external_company_id: { equals: userData.company.id },
            },
          },
        }
      : undefined;

  const [findRoutesResult] = useListRoutesQuery({
    variables: findRoutesVariables,
    pause: !findRoutesVariables,
  });

  useEffect(() => {
    const { data, fetching, error } = findRoutesResult;

    if (error) {
      setRoutesWithSlug([]);
    } else if (data && !fetching) {
      setRoutesWithSlug(slugifyRoutes(data.FindRoutes));
    }
  }, [findRoutesResult]);

  const handleView = (routeId: string) =>
    navigate(`/shipper/routes/${routeId}`);

  const handleCreate = () => navigate(`/shipper/routes/create`);

  const pickupPlaces = getUniquePlaces({
    moveType: MoveTypeEnum.Withdrawal,
    routes: routesWithSlug,
  });

  const dropoffPlaces = getUniquePlaces({
    moveType: MoveTypeEnum.Delivery,
    routes: routesWithSlug,
  });

  const filteredData = useMemo(() => {
    if (routeFilterValues && routesWithSlug) {
      const { pickupCityId, dropoffCityId } = routeFilterValues;

      const search = Formatter.removeAccents(
        routeFilterValues.search || ''
      ).toLowerCase();

      return routesWithSlug.filter(route => {
        const withdrawalCity = route.places?.find(
          place => place.move_type === MoveTypeEnum.Withdrawal
        );

        const dropoffCity = route.places?.find(
          place => place.move_type === MoveTypeEnum.Delivery
        );

        if (!pickupCityId || withdrawalCity?.city_id === pickupCityId) {
          if (!dropoffCityId || dropoffCity?.city_id === dropoffCityId) {
            if (!search || route.slug?.includes(search)) {
              return true;
            }
          }
        }
      });
    }

    return routesWithSlug;
  }, [routesWithSlug, routeFilterValues]);

  return (
    <Row gutter={[0, 24]}>
      <ErrorAlert error={findRoutesResult.error} />
      <Col span={24}>
        <RoutesFilter
          pickupPlaces={pickupPlaces}
          dropoffPlaces={dropoffPlaces}
          onFilter={setRouteFilterValues}
        />
      </Col>
      <Col span={24}>
        <RoutesTable
          routes={filteredData}
          handleView={handleView}
          handleCreate={handleCreate}
          loading={findRoutesResult.fetching}
        />
      </Col>
    </Row>
  );
};
