import { Modal, ModalProps } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../contexts/UserContext/useUserContext';
import { useFinishQuotationModalMutation } from '../../../graphql/generated/graphql';
import ErrorAlert from '../../AntdCustom/ErrorAlert/ErrorAlert';

interface IFinishQuotationModalProps extends Partial<ModalProps> {
  quotationId?: string;
  onFinishSuccess?: (quotationId: string) => void;
}

const TRANSLATION_PATH = 'components.quotation.finishQuotationModal';

export const FinishQuotationModal = ({
  quotationId,
  onFinishSuccess,
  ...modalProps
}: IFinishQuotationModalProps): ReactElement => {
  const { t } = useTranslation();

  const { userData } = useUserContext();

  const [finishQuotationModalResult, executeFinishQuotationModalMutation] =
    useFinishQuotationModalMutation();

  const handleConfirm = () => {
    if (quotationId && userData?.company) {
      executeFinishQuotationModalMutation({
        input: {
          owner_id: userData.company.id,
          quotation_id: quotationId,
        },
      }).then(({ data, error }) => {
        if (data && !error && onFinishSuccess) {
          onFinishSuccess(data.FinishQuotation.id);
        }
      });
    }
  };

  const { error, fetching } = finishQuotationModalResult;

  return (
    <Modal
      title={t(`${TRANSLATION_PATH}.title`)}
      cancelText={t('general.close')}
      centered
      okButtonProps={{ loading: fetching }}
      okText={t('general.finish')}
      onOk={handleConfirm}
      {...modalProps}
    >
      <ErrorAlert error={error} style={{ marginBottom: 24 }} />
      {t(`${TRANSLATION_PATH}.text`)}
    </Modal>
  );
};
