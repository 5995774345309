export const CompanyStatus = [
  'approved',
  'inAnalysis',
  'pending',
  'reproved',
  'blocked',
  'waitingData',
  'rectifiedData',
] as const;

export type ICompanyStatus = (typeof CompanyStatus)[number];

export enum CompanyStatusEnum {
  approved = 'APPROVED',
  inAnalysis = 'IN_ANALYSIS',
  pending = 'PENDING',
  reproved = 'REPROVED',
  blocked = 'BLOCKED',
  waitingData = 'WAITING_DATA',
  rectifiedData = 'RECTIFIED_DATA',
}

export enum CompanyStatusReverseEnum {
  APPROVED = 'approved',
  IN_ANALYSIS = 'inAnalysis',
  PENDING = 'pending',
  REPROVED = 'reproved',
  BLOCKED = 'blocked',
  WAITING_DATA = 'waitingData',
  RECTIFIED_DATA = 'rectifiedData',
}
