import { Access } from '../../../../../components/Access/Access';
import { DefaultActionColumn } from '../../../../../components/AntdCustom/DefaultTable/components/DefaultActionColumn/DefaultActionColumn';

export const DeleteButtonAccess = Access(DefaultActionColumn.DeleteButton, {
  acceptedPermissions: [{ module: 'GROUP', actions: ['CREATE'] }],
});

export const EditButtonAccess = Access(DefaultActionColumn.EditButton, {
  acceptedPermissions: [{ module: 'GROUP', actions: ['UPDATE'] }],
});

export const ViewButtonAccess = Access(DefaultActionColumn.ViewButton, {
  acceptedPermissions: [{ module: 'GROUP', actions: ['READ'] }],
});
