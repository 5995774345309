import { Select, SelectProps } from 'antd';

import { useTranslation } from 'react-i18next';
import {
  FindProductTypesQueryVariables,
  useFindProductTypesQuery,
} from '../../../../graphql/generated/graphql';

type IProductTypeSelect = Omit<SelectProps, 'options'>;

/**
 * Ant design select with Product types options
 */
export const ProductTypeSelect = ({
  loading,
  disabled,
  ...props
}: IProductTypeSelect) => {
  const { t } = useTranslation();

  const variables: FindProductTypesQueryVariables = {
    pagination: {
      take: 1000,
    },
  };

  const [findProductTypesResult] = useFindProductTypesQuery({
    variables,
  });

  const { data, fetching } = findProductTypesResult;

  const productTypesOptions: SelectProps['options'] | undefined =
    data?.FindManyProductTypes.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  return (
    <>
      <Select
        {...props}
        loading={loading || fetching}
        disabled={disabled || fetching}
        options={productTypesOptions}
        notFoundContent={t(
          'components.product.productType.productTypeSelect.notFoundMessage'
        )}
      />
    </>
  );
};
