import { ReactElement, ReactNode } from 'react';

import { Col, Row } from 'antd';

import TruckerLogo from '../../../TruckerLogo/TruckerLogo';

interface IHeroCardContainerProps {
  className?: string;
  children: ReactNode;
  logoColumnSize?: 'middle' | 'small';
}

import './index.scss';

const HeroCardContainer = ({
  children,
  className,
  logoColumnSize = 'middle',
}: IHeroCardContainerProps): ReactElement => {
  const logoCol = (
    <Col
      className={`logo-col${
        logoColumnSize === 'small' ? ' logo-col-small' : ''
      }`}
    >
      <div className="logo-container">
        <TruckerLogo />
      </div>
    </Col>
  );

  return (
    <Row className={`hero-card_container${className ? ` ${className}` : ''}`}>
      {logoCol}
      <Col className="form-col">{children}</Col>
    </Row>
  );
};

export default HeroCardContainer;
