import { SelectProps } from 'antd';

export const COMPANY_TYPES = ['Parent', 'Branch', 'Unit'] as const;

export type ICompanyType = (typeof COMPANY_TYPES)[number];

export enum CompanyTypeEnum {
  Parent = 'PARENT',
  Branch = 'BRANCH',
  Unit = 'UNIT',
}

export enum CompanyTypeReverseEnum {
  PARENT = 'Parent',
  BRANCH = 'Branch',
  UNIT = 'Unit',
}

export const CompanyTypeOptions: NonNullable<SelectProps['options']> =
  COMPANY_TYPES.map(type => ({
    label: `enums.companyType.${type}`,
    value: type,
  }));
