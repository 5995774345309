import { Route } from 'react-router-dom';
import AuthPage from '../pages/AuthPage/AuthPage';
import RecoverPasswordPage from '../pages/RecoverPasswordPage/RecoverPasswordPage';

export const AuthRoutes = (
  <>
    <Route index element={<AuthPage />} />
    <Route path="/auth" element={<AuthPage />} />
    <Route path="/recoveryPassword" element={<RecoverPasswordPage />} />
  </>
);
