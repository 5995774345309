import { Col, Row } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CombinedError, UseMutationState } from 'urql';
import {
  AttachmentsTableModal_DetachFileMutation,
  AttachmentsTableModal_FindAllAttachmentsQuery,
  AttachmentsTableModal_FindAllAttachmentsQueryVariables,
} from '../../../../../graphql/generated/graphql';
import { Access } from '../../../../Access/Access';
import ErrorAlert from '../../../../AntdCustom/ErrorAlert/ErrorAlert';
import { Attachments } from '../../../attachments';
import { AttachmentFormModalComponents } from '../AttachmentFormModal';

interface IAttachmentsTableModalProps {
  handleClose: () => void;
  handleOpenFormModal?: () => void;
  handleDelete?: (
    fileId: string
  ) => Promise<AttachmentsTableModal_DetachFileMutation>;
  isOpen: boolean;
  queryResponse: UseMutationState<
    AttachmentsTableModal_FindAllAttachmentsQuery,
    AttachmentsTableModal_FindAllAttachmentsQueryVariables
  >;
  loading?: boolean;
  error?: CombinedError;
}

export const AttachmentsTableModal = ({
  handleClose,
  handleDelete,
  handleOpenFormModal,
  isOpen,
  queryResponse,
  loading,
  error,
}: IAttachmentsTableModalProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'components.freight.freightDetails.components.attachmentTableModal',
  });

  return (
    <Attachments.Modal
      footer={null}
      onCancel={queryResponse.fetching ? undefined : handleClose}
      open={isOpen}
      title={
        <Row justify="space-between">
          {t('title')}
          {handleOpenFormModal ? (
            <AttachmentFormModalComponents.OpenButton
              style={{ marginRight: 24 }}
              onClick={handleOpenFormModal}
            />
          ) : null}
        </Row>
      }
      width={992}
    >
      <Row gutter={[24, 24]}>
        <ErrorAlert error={error} />
        <Col span={24}>
          <Attachments.Table
            dataSource={queryResponse.data?.ListAllFreightAttachments.data}
            loading={loading}
            onDelete={handleDelete}
          />
        </Col>
      </Row>
    </Attachments.Modal>
  );
};

export const AttachmentTableModalAccess = Access(AttachmentsTableModal, {
  acceptedPermissions: [{ module: 'FREIGHT_ATTACHMENTS', actions: ['READ'] }],
});
