/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  TableColumnType,
  TableColumnsType,
  Typography,
} from 'antd';
import { FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../classes/Formatter';
import {
  CreateManyProductsMutation,
  FindProductsQuery,
  SortOrder,
  useDeleteProductsMutation,
  useFindProductsQuery,
  useUpdateProductMutation,
} from '../../../../graphql/generated/graphql';
import useQueryParams from '../../../../hooks/useQueryParams/useQueryParams';
import { Access } from '../../../Access/Access';
import ErrorAlert from '../../../AntdCustom/ErrorAlert/ErrorAlert';
import { ProductTypeSelect } from '../../ProductType/ProductTypeSelect/ProductTypeSelect';
import { CreateProductsModal } from '../CreateProductsModal/CreateProductsModal';

type IProductTableItem = FindProductsQuery['FindManyProducts']['data'][0];

interface EditableTableCellProps extends TableColumnType<IProductTableItem> {
  editingId?: string;
  record: IProductTableItem;
  children: React.ReactNode;
  dataIndex: 'name' | 'product_type' | 'actions';
}

interface IProductFilterForm {
  search?: string;
  productTypeId?: string;
}

interface IEditProductForm {
  name: string;
  type_id: string;
}

export const ListProducts = () => {
  const LIST_PRODUCTS_TRANSLATION_PATH =
    'components.product.product.listProducts';

  const { t } = useTranslation();

  const [filterForm] = Form.useForm<IProductFilterForm>();
  const [editProductForm] = Form.useForm<Record<string, IEditProductForm>>();

  const [products, setProducts] = useState<IProductTableItem[]>();
  const [productFilterValues, setProductFilterValues] =
    useState<IProductFilterForm>();
  const [isCreateProductTypeModalOpen, setIsCreateProductModalOpen] =
    useState(false);
  const [editingProductId, setEditingProductId] = useState<string>();

  const [getQueryParams, setQueryParams] = useQueryParams();

  const [findProductsResult] = useFindProductsQuery({
    variables: {
      order_by: {
        created_at: SortOrder.Desc,
      },
      pagination: {
        take: 1000,
      },
    },
  });
  const [deleteProductsMutationResult, executeDeleteProductsMutation] =
    useDeleteProductsMutation();
  const [updateProductMutationResult, executeUpdateProductMutation] =
    useUpdateProductMutation();

  const EditableTableCell = ({
    editingId = '',
    dataIndex,
    record,
    children,
  }: EditableTableCellProps) => {
    const formItemInput = {
      name: (
        <Form.Item
          name={[editingId, 'name']}
          label={t(
            `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.fields.labels.name`
          )}
          labelCol={{ hidden: true }}
          initialValue={record.name}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
          style={{ flex: 1, marginTop: 24 }}
        >
          <Input
            placeholder={t(
              `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.fields.placeholders.name`
            )}
          />
        </Form.Item>
      ),
      product_type: (
        <Form.Item
          name={[editingId, 'type_id']}
          label={t(
            `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.fields.labels.type_id`
          )}
          labelCol={{ hidden: true }}
          initialValue={record.product_type.id}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
          style={{ flex: 1, marginTop: 24 }}
        >
          <ProductTypeSelect disabled={updateProductMutationResult.fetching} />
        </Form.Item>
      ),
      actions: (
        <Space>
          <Popconfirm
            onConfirm={handleSaveEdit}
            okText={t(
              `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.actions.save.button`
            )}
            title={t(
              `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.actions.save.popconfirm`
            )}
          >
            <Button type="link" loading={updateProductMutationResult.fetching}>
              {t(
                `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.actions.save.button`
              )}
            </Button>
          </Popconfirm>
          <Button danger type="link" onClick={handleCancelEdit}>
            {t(
              `${LIST_PRODUCTS_TRANSLATION_PATH}.editableTableCell.actions.cancel`
            )}
          </Button>
        </Space>
      ),
    };

    if (editingId === record.id) {
      return formItemInput[dataIndex];
    } else {
      return <>{children}</>;
    }
  };

  const handleFinish = (formValues: IProductFilterForm) => {
    setQueryParams({ ...formValues });
    setProductFilterValues(formValues);
  };

  const handleReset: FormEventHandler<HTMLFormElement> = event => {
    event.stopPropagation();

    filterForm.setFieldsValue({
      productTypeId: undefined,
      search: undefined,
    });
  };

  const filterProducts = (
    products: IProductTableItem[] = [],
    filterValues: IProductFilterForm = {}
  ) => {
    const { search, productTypeId } = filterValues;

    let filteredProducts = products;

    if (search) {
      filteredProducts = filteredProducts.filter(({ name }) =>
        Formatter.removeAccents(name)
          .toLowerCase()
          .includes(Formatter.removeAccents(search).toLowerCase())
      );
    }

    if (productTypeId) {
      filteredProducts = filteredProducts.filter(
        ({ product_type }) => product_type.id === productTypeId
      );
    }

    return filteredProducts;
  };

  const handleDelete = (productId: string) => {
    executeDeleteProductsMutation({
      input: { data: [{ id: productId }] },
    }).then(({ error, data }) => {
      const deletedProductsId = data?.DeleteManyProducts.data.map(
        ({ id }) => id
      );

      if (!error && deletedProductsId) {
        setProducts(products =>
          products?.filter(({ id }) => !deletedProductsId.includes(id))
        );
      }
    });
  };

  const handleEdit = (productId: string) => {
    setEditingProductId(productId);
  };

  const handleCancelEdit = () => {
    editProductForm.resetFields();
    setEditingProductId(undefined);
  };

  const handleSaveEdit = () => {
    if (editingProductId) {
      editProductForm
        .validateFields([
          [editingProductId, 'name'],
          [editingProductId, 'type_id'],
        ])
        .then(formValues => {
          const { name, type_id } = formValues[editingProductId];

          executeUpdateProductMutation({
            input: { data: [{ product_id: editingProductId, name, type_id }] },
          }).then(({ error, data }) => {
            const updatedProducts = data?.UpdateManyProducts.data;

            if (!error && updatedProducts) {
              if (products) {
                const newProducts = [...products];

                updatedProducts.forEach(({ id, name, product_type }) => {
                  const product = newProducts.find(
                    product => id === product.id
                  );

                  if (product) {
                    product.name = name;
                    product.product_type = product_type;
                  }
                });

                setProducts(newProducts);
              }

              setEditingProductId(undefined);
            }
          });
        })
        .catch(() => null);
    }
  };

  const handleCloseCreateModal = () => {
    setIsCreateProductModalOpen(false);
  };

  const handleCreate = () => {
    setIsCreateProductModalOpen(true);
  };

  const handleCreateSuccess = (
    createdProducts: CreateManyProductsMutation['CreateManyProducts']['data']
  ) => {
    if (!products) {
      setProducts(createdProducts);
    } else {
      const uniqueCreatedProducts = createdProducts.filter(
        createdProduct =>
          !products.some(productType => createdProduct.id === productType.id)
      );

      setProducts([...uniqueCreatedProducts, ...products]);
    }

    handleCloseCreateModal();
  };

  const CreateProductButton = () => (
    <Button type="primary" onClick={handleCreate}>
      {t(`${LIST_PRODUCTS_TRANSLATION_PATH}.actions.create`)}
    </Button>
  );

  const EditProductButton = ({ productId }: { productId: string }) => (
    <Button type="link" onClick={() => handleEdit(productId)}>
      {t(`${LIST_PRODUCTS_TRANSLATION_PATH}.actions.edit`)}
    </Button>
  );

  const DeleteProductButton = ({ productId }: { productId: string }) => (
    <Popconfirm
      onConfirm={() => handleDelete && handleDelete(productId)}
      okButtonProps={{ danger: true }}
      okText={t(`${LIST_PRODUCTS_TRANSLATION_PATH}.actions.delete.button`)}
      title={t(`${LIST_PRODUCTS_TRANSLATION_PATH}.actions.delete.popconfirm`)}
    >
      <Button danger type="link">
        {t(`${LIST_PRODUCTS_TRANSLATION_PATH}.actions.delete.button`)}
      </Button>
    </Popconfirm>
  );

  const CreateProductButtonAccess = Access(CreateProductButton, {
    acceptedPermissions: [{ module: 'PRODUCTS', actions: ['CREATE'] }],
  });

  const EditProductButtonAccess = Access(EditProductButton, {
    acceptedPermissions: [{ module: 'PRODUCT_TYPES', actions: ['UPDATE'] }],
  });

  const DeleteProductButtonAccess = Access(DeleteProductButton, {
    acceptedPermissions: [{ module: 'PRODUCT_TYPES', actions: ['DELETE'] }],
  });

  useEffect(() => {
    filterForm.setFieldsValue(getQueryParams());
    filterForm.submit();
  }, []);

  useEffect(() => {
    const { data, fetching } = findProductsResult;

    if (data && !fetching) setProducts(data.FindManyProducts.data);
  }, [findProductsResult]);

  const columns: TableColumnsType<IProductTableItem> = [
    {
      title: t(`${LIST_PRODUCTS_TRANSLATION_PATH}.productTable.columns.name`),
      dataIndex: 'name',
      render(value, record) {
        return (
          <EditableTableCell
            record={record}
            dataIndex="name"
            editingId={editingProductId}
          >
            {value}
          </EditableTableCell>
        );
      },
    },
    {
      title: t(
        `${LIST_PRODUCTS_TRANSLATION_PATH}.productTable.columns.product_type`
      ),
      dataIndex: ['product_type', 'name'],
      render(value, record) {
        return (
          <EditableTableCell
            record={record}
            dataIndex="product_type"
            editingId={editingProductId}
          >
            {value}
          </EditableTableCell>
        );
      },
    },
    {
      title: t(
        `${LIST_PRODUCTS_TRANSLATION_PATH}.productTable.columns.actions`
      ),
      dataIndex: 'actions',
      render(_, record) {
        return (
          <EditableTableCell
            record={record}
            dataIndex="actions"
            editingId={editingProductId}
          >
            <Space>
              <EditProductButtonAccess productId={record.id} />
              <DeleteProductButtonAccess productId={record.id} />
            </Space>
          </EditableTableCell>
        );
      },
    },
  ];

  const title = (
    <Row align="middle" justify="space-between" style={{ rowGap: 12 }}>
      <Typography.Text>
        {t(`${LIST_PRODUCTS_TRANSLATION_PATH}.title`)}
      </Typography.Text>
      <CreateProductButtonAccess />
    </Row>
  );

  const errorMessages = (
    <>
      <ErrorAlert error={findProductsResult.error} />
      <ErrorAlert error={deleteProductsMutationResult.error} />
      <ErrorAlert error={updateProductMutationResult.error} />
    </>
  );

  const colSpan = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 6,
  };

  const filteredProducts = filterProducts(products, productFilterValues);

  return (
    <>
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        {errorMessages}
        <Col span={24}>
          <Card style={{ overflow: 'auto' }}>
            <Form
              form={filterForm}
              layout="vertical"
              onFinish={handleFinish}
              onResetCapture={handleReset}
            >
              <Row gutter={[24, 24]} align={'bottom'}>
                <Col {...colSpan}>
                  <Form.Item
                    label={t(
                      `${LIST_PRODUCTS_TRANSLATION_PATH}.productsFilter.fields.labels.search`
                    )}
                    name="search"
                    style={{ margin: 0 }}
                  >
                    <Input.Search
                      size="small"
                      placeholder={t(
                        `${LIST_PRODUCTS_TRANSLATION_PATH}.productsFilter.fields.placeholders.search`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col {...colSpan}>
                  <Form.Item
                    label={t(
                      `${LIST_PRODUCTS_TRANSLATION_PATH}.productsFilter.fields.labels.productTypeId`
                    )}
                    name={'productTypeId'}
                    style={{ margin: 0 }}
                  >
                    <ProductTypeSelect
                      size="small"
                      placeholder={t(
                        `${LIST_PRODUCTS_TRANSLATION_PATH}.productsFilter.fields.placeholders.productTypeId`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col {...colSpan}>
                  <Form.Item style={{ margin: 0 }}>
                    <Space size="large">
                      <Button size="small" htmlType="reset">
                        {t(
                          `${LIST_PRODUCTS_TRANSLATION_PATH}.productsFilter.actions.reset`
                        )}
                      </Button>
                      <Button size="small" type="primary" htmlType="submit">
                        {t(
                          `${LIST_PRODUCTS_TRANSLATION_PATH}.productsFilter.actions.submit`
                        )}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card title={title}>
            <Form
              layout="vertical"
              form={editProductForm}
              disabled={updateProductMutationResult.fetching}
            >
              <Table
                className="ant-table-wrapper-responsive"
                columns={columns}
                dataSource={filteredProducts}
                loading={
                  findProductsResult.fetching ||
                  deleteProductsMutationResult.fetching
                }
                rowKey={({ id }) => id}
                size="small"
              />
            </Form>
          </Card>
        </Col>
      </Row>
      <CreateProductsModal
        onClose={handleCloseCreateModal}
        onSuccess={handleCreateSuccess}
        open={isCreateProductTypeModalOpen}
      />
    </>
  );
};
