import { UploadFile } from 'antd';

export const getFileValueFromEvent = (
  value?: { file: UploadFile; fileList: UploadFile[] } | UploadFile[]
) => {
  if (Array.isArray(value)) {
    return value;
  }

  return value?.fileList || [];
};
