import { EyeOutlined } from '@ant-design/icons';
import { Button, Descriptions, Image, Skeleton } from 'antd';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IResponsiveDescriptionsProps,
  ResponsiveDescriptions,
} from '../../../../../../../components/AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { CompanyFunction } from '../../../../../../../components/Company/CompanyFunction/CompanyFunction';
import { CompanyType } from '../../../../../../../components/Company/CompanyType/CompanyType';
import { LastCompanyStatusTag } from '../../../../../../../components/Company/LastCompanyStatusTag/LastCompanyStatusTag';
import { FormattedDateTime } from '../../../../../../../components/Formatters/FormattedDateTime/FormattedDateTime';
import { FormattedDocumentNumber } from '../../../../../../../components/Formatters/FormattedDocumentNumber/FormattedDocumentNumber';
import { CompanyDetailsPageQuery } from '../../../../../../../graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';

export type ICompanyDataDescriptionsData =
  CompanyDetailsPageQuery['FindCompany']['mother_company'];

export interface ICompanyDataDescriptionsProps
  extends Omit<IResponsiveDescriptionsProps, 'children'> {
  loading?: boolean;
  data?: ICompanyDataDescriptionsData | null;
  showMoreDetails?: boolean;
}

export const CompanyDataDescriptions = ({
  loading,
  data,
  showMoreDetails = false,
  ...props
}: ICompanyDataDescriptionsProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'pages.support.account.company.companyDetailsPage.companyDataDescriptions',
  });

  const navigate = useNavigate();

  const { t: t0 } = useTranslation();

  const [isLogoPreviewVisible, setIsLogoPreviewVisible] = useState(false);

  const column = {
    xxl: 3,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <ResponsiveDescriptions column={{ ...column }} layout={layout} {...props}>
      <ResponsiveDescriptions.Item label={t('descriptions.id')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.id}
        </Skeleton>
      </ResponsiveDescriptions.Item>
      <Descriptions.Item label={t('descriptions.documentNumber')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.document_number ? (
            <FormattedDocumentNumber documentNumber={data.document_number} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.companyStatus')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.companyStatus ? (
            <LastCompanyStatusTag companyStatus={data.companyStatus} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.companyName')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.company_name}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.tradeName')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.trade_name}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.companyType')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.company_type ? (
            <CompanyType companyType={data?.company_type} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.companyFunction')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.company_function ? (
            <CompanyFunction companyFunction={data?.company_function} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.createdAt')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.created_at ? (
            <FormattedDateTime dateTime={data?.created_at} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.updatedAt')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.updated_at ? (
            <FormattedDateTime dateTime={data?.updated_at} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      <Descriptions.Item label={t('descriptions.deletedAt')}>
        <Skeleton active paragraph={false} loading={loading}>
          {data?.deleted_at ? (
            <FormattedDateTime dateTime={data?.deleted_at} />
          ) : (
            <>-</>
          )}
        </Skeleton>
      </Descriptions.Item>
      {data?.url_logo && (
        <Descriptions.Item label={t('descriptions.urlLogo')}>
          <Skeleton active paragraph={false} loading={loading}>
            <>
              <Button
                type="link"
                size="small"
                onClick={() => setIsLogoPreviewVisible(true)}
              >
                <EyeOutlined />
                {t0('general.view')}
              </Button>
              <Image
                src={data.url_logo}
                style={{ display: 'none' }}
                preview={{
                  visible: isLogoPreviewVisible,
                  onVisibleChange: setIsLogoPreviewVisible,
                }}
              />
            </>
          </Skeleton>
        </Descriptions.Item>
      )}
      {showMoreDetails && data?.id && (
        <Descriptions.Item label={t('descriptions.moreDetails')}>
          <Skeleton active paragraph={false} loading={loading}>
            <>
              <Button
                type="link"
                size="small"
                onClick={() => navigate(`/support/accounts/company/${data.id}`)}
              >
                {t0('general.view')}
              </Button>
            </>
          </Skeleton>
        </Descriptions.Item>
      )}
    </ResponsiveDescriptions>
  );
};
