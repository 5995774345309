import { Card, CardProps, Descriptions, Row, Skeleton, Typography } from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveDescriptions } from '../../../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { FormattedDate } from '../../../../Formatters/FormattedDate/FormattedDate';
import { ConfirmFreightDatesFragment } from '../../../../../graphql/generated/graphql';

interface IConfirmFreightDatesProps extends Partial<CardProps> {
  freightDates?: ConfirmFreightDatesFragment;
  loading?: boolean;
}

export const ConfirmFreightDates = ({
  freightDates,
  loading,
  ...cardProps
}: IConfirmFreightDatesProps): ReactElement => {
  const CONFIRM_FREIGHT_DATES_TRANSLATION_PATH =
    'components.freight.shipper.confirmCreateFreight.confirmFreightDates';

  const { t } = useTranslation();

  const title = (
    <Row align="middle" justify="space-between">
      <Typography.Text>
        {t(`${CONFIRM_FREIGHT_DATES_TRANSLATION_PATH}.title`)}
      </Typography.Text>
    </Row>
  );

  const column = {
    xxl: 4,
    xl: 3,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <Card title={title} bordered={false} {...cardProps}>
      <ResponsiveDescriptions column={column} layout={layout}>
        <Descriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_DATES_TRANSLATION_PATH}.fields.shipment_date`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightDates?.shipment_date && (
              <FormattedDate dateTime={freightDates.shipment_date} />
            )}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item
          label={t(
            `${CONFIRM_FREIGHT_DATES_TRANSLATION_PATH}.fields.landing_date`
          )}
        >
          <Skeleton active loading={loading} paragraph={false}>
            {freightDates?.landing_date && (
              <FormattedDate dateTime={freightDates.landing_date} />
            )}
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
    </Card>
  );
};
