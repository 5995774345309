import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface IFreightStatusProps {
  status: string;
}

export const FreightStatus = ({
  status,
}: IFreightStatusProps): ReactElement => {
  const { t } = useTranslation();

  const translatedStatus = t(`enums.freight.freightStatus.${status}`, {
    defaultValue: t('general.unknown'),
  });

  return <>{translatedStatus}</>;
};
