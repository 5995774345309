import { Button, Card, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../../../../../classes/Formatter';
import { CompanyFunctionSelect } from '../../../../../../components/Company/CompanyFunction/CompanyFunctionSelect/CompanyFunctionSelect';
import { CompanyTypeSelect } from '../../../../../../components/Company/CompanyType/CompanyTypeSelect/CompanyTypeSelect';
import {
  CompaniesPageQueryVariables,
  CompanyTypeEnum,
  FunctionEnum,
  QueryMode,
} from '../../../../../../graphql/generated/graphql';
import { useDatePickerDefaultPresets } from '../../../../../../hooks/useDatePickerDefaultPresets/useDatePickerDefaultPresets';

interface ICompaniesPageFilterProps {
  setVariables: Dispatch<SetStateAction<CompaniesPageQueryVariables>>;
}

export interface IFilterFormValues {
  documentNumber?: string;
  companyName?: string;
  companyType?: CompanyTypeEnum;
  companyFunction?: FunctionEnum;
  createdAt?: [dayjs.Dayjs, dayjs.Dayjs];
  updatedAt?: [dayjs.Dayjs, dayjs.Dayjs];
}

export const CompaniesPageFilter = ({
  setVariables,
}: ICompaniesPageFilterProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.company.components.companiesPageFilter',
  });

  const { t: tg } = useTranslation('translations', {
    keyPrefix: 'general',
  });

  const datePickerDefaultPresets = useDatePickerDefaultPresets();

  const handleFilter = (formValues: IFilterFormValues) => {
    const documentNumber = formValues.documentNumber?.replaceAll(/[\D]/g, '');
    const document_number = documentNumber
      ? {
          contains: documentNumber,
          mode: QueryMode.Insensitive,
        }
      : undefined;

    const company_type = formValues.companyType
      ? {
          equals: formValues.companyType,
        }
      : undefined;

    const company_function = formValues.companyFunction
      ? {
          equals: formValues.companyFunction,
        }
      : undefined;

    const created_at = formValues.createdAt
      ? {
          gte: formValues.createdAt[0].startOf('day').toISOString(),
          lte: formValues.createdAt[1].endOf('day').toISOString(),
        }
      : undefined;

    const updated_at = formValues.updatedAt
      ? {
          gte: formValues.updatedAt[0].startOf('day').toISOString(),
          lte: formValues.updatedAt[1].endOf('day').toISOString(),
        }
      : undefined;

    const companyName = formValues.companyName?.trim();
    const company = companyName
      ? {
          OR: [
            {
              company_name: {
                contains: companyName,
                mode: QueryMode.Insensitive,
              },
            },
            {
              trade_name: {
                contains: companyName,
                mode: QueryMode.Insensitive,
              },
            },
            {
              address: {
                is: {
                  city: {
                    is: {
                      city_name_without_accent: {
                        contains: companyName,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              address: {
                is: {
                  city: {
                    is: {
                      city_name: {
                        contains: companyName,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
              },
            },
            {
              address: {
                is: {
                  city: {
                    is: {
                      state_id: { contains: companyName },
                    },
                  },
                },
              },
            },
          ],
        }
      : undefined;

    const where: CompaniesPageQueryVariables['where'] = {
      ...{ document_number },
      ...{ company_type },
      ...{ company_function },
      ...{ created_at },
      ...{ updated_at },
      ...company,
    };

    setVariables(variables => ({
      ...variables,
      where: { ...variables.where, ...where },
    }));
  };

  return (
    <Card>
      <Form layout="vertical" onFinish={handleFilter}>
        <Row gutter={[24, 24]}>
          <Col>
            <Form.Item
              label={t('labels.documentNumber')}
              name="documentNumber"
              normalize={Formatter.formatCPF_CNPJ}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.companyName')} name="companyName">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.companyType')} name="companyType">
              <CompanyTypeSelect allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={t('labels.companyFunction')}
              name="companyFunction"
            >
              <CompanyFunctionSelect allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.createdAt')} name="createdAt">
              <DatePicker.RangePicker
                allowClear
                format="DD/MM/YYYY"
                presets={datePickerDefaultPresets}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('labels.updatedAt')} name="updatedAt">
              <DatePicker.RangePicker
                allowClear
                format="DD/MM/YYYY"
                presets={datePickerDefaultPresets}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Space size="large">
            <Button htmlType="reset">{tg('clearFilter')}</Button>
            <Button htmlType="submit" type="primary">
              {tg('filter')}
            </Button>
          </Space>
        </Row>
      </Form>
    </Card>
  );
};
