import { ReactElement } from 'react';
import { LastCompanyStatusTagFragment } from '../../../graphql/generated/graphql';
import CompanyStatusTag from '../CompanyStatusTag/CompanyStatusTag';

type ILastCompanyStatusTagFragment = Omit<
  LastCompanyStatusTagFragment,
  '__typename'
>;

interface ILastCompanyStatusTagProps {
  companyStatus?: ILastCompanyStatusTagFragment[] | null;
}

export const LastCompanyStatusTag = ({
  companyStatus,
}: ILastCompanyStatusTagProps): ReactElement => {
  const lastStatus = getLastCompanyStatusTag(companyStatus);

  if (!lastStatus) return <>-</>;

  return <CompanyStatusTag companyStatus={lastStatus.status} />;
};

const getLastCompanyStatusTag = (
  companyStatus: ILastCompanyStatusTagFragment[] | null | undefined
) => {
  if (!companyStatus?.length) return null;

  const lastStatus = companyStatus.sort((a, b) =>
    a.updated_at >= b.updated_at ? -1 : 1
  )[0];

  return lastStatus;
};

LastCompanyStatusTag.getLastCompanyStatusTag = getLastCompanyStatusTag;
