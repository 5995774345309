import { TableColumnType, TableColumnsType, TableProps } from 'antd';
import { ReactElement } from 'react';
import { DefaultTable } from '../../../../../components/AntdCustom/DefaultTable/DefaultTable';
import { useCommonI18n } from '../../../../../core/Common/Common.hooks';
import { QuotationGroupTableFragment } from '../../../../../graphql/generated/graphql';
import { MembersColumn } from './QuotationGroupTable.components';
import { useQuotationGroupI18n } from '../../../../../core/QuotationGroup/QuotationGroup.hooks';

export interface IQuotationGroupTableProps
  extends Omit<
    TableProps<QuotationGroupTableFragment>,
    'columns' | 'children'
  > {
  actionColumn?: TableColumnType<QuotationGroupTableFragment>;
  showDateColumns?: boolean;
  children?: JSX.Element;
}

export const QuotationGroupTable = ({
  actionColumn,
  showDateColumns,
  children,
  ...props
}: IQuotationGroupTableProps): ReactElement => {
  const {
    tKeys: { createdAt: createdAtTitle, updatedAt: updatedAtTitle },
  } = useCommonI18n();

  const {
    tKeys: { name: nameTitle, members: membersTitle },
  } = useQuotationGroupI18n();

  const columns: TableColumnsType<QuotationGroupTableFragment> = [
    {
      dataIndex: 'name',
      title: nameTitle,
      width: 200,
      render(name: QuotationGroupTableFragment['name']) {
        return <div style={{ minWidth: 100 }}>{name}</div>;
      },
    },
    {
      dataIndex: 'members',
      title: membersTitle,
      render(members: QuotationGroupTableFragment['members']) {
        return (
          <MembersColumn
            members={members}
            style={{ maxWidth: showDateColumns ? 700 : 1000 }}
          />
        );
      },
    },
  ];

  if (showDateColumns) {
    columns.push({
      dataIndex: 'created_at',
      title: createdAtTitle,
      render(createdAt: QuotationGroupTableFragment['created_at']) {
        return <DefaultTable.Columns.CreatedAt date={createdAt} />;
      },
    });

    columns.push({
      dataIndex: 'updated_at',
      title: updatedAtTitle,
      render(updatedAt: QuotationGroupTableFragment['updated_at']) {
        return <DefaultTable.Columns.UpdatedAt date={updatedAt} />;
      },
    });
  }

  if (actionColumn) {
    columns.push(actionColumn);
  }

  return (
    <DefaultTable {...props} columns={columns} rowKey={({ id }) => id}>
      {children}
    </DefaultTable>
  );
};
