import { UF } from '../../interfaces/UF';
import { CompanyTypeEnum } from '../../interfaces/Company/CompanyType';
import { DocumentTypeEnum } from '../../interfaces/Company/DocumentType';
import { CompanyFunctionEnum } from '../../interfaces/Company/CompanyFunction';
import {
  ICompanyDto,
  ICreateCompanyDto,
  IRectifyCompanyDto,
  ICreateCompanyForm,
  IRectifyCompanyForm,
  IComplementCompanyDto,
  IComplementCompanyForm,
  ICreateCompanyParentDto,
  ICreateCompanyContactDto,
  ICreateCompanyAddressDto,
} from '../../interfaces/Company/CompanyDto';

abstract class CompanyModel implements ICompanyDto {
  user_id: string;
  url_logo?: string;
  reference_company_id?: string;

  address_data: ICreateCompanyAddressDto;
  contact_data: ICreateCompanyContactDto;

  has_fleet?: boolean;
  fleet_quantity?: number;

  mother_company_id?: string;
  parent_data?: ICreateCompanyParentDto;

  constructor(data: ICreateCompanyForm) {
    this.user_id = data.userId;
    this.url_logo = data.urlLogo;

    const addressData = {} as ICreateCompanyAddressDto;

    addressData.city_id = data.city;
    addressData.complement = data.complement;
    addressData.country_id = 'BR';
    addressData.number = data.addressNumber?.trim();
    addressData.state_id = data.state;
    addressData.street = data.address.trim();
    addressData.zip_code_address = data.zipCode?.replace(/\D/g, '');

    if (data.districtId) {
      addressData.district_id = data.districtId;
    } else {
      addressData.district_name = data.district;
    }

    this.address_data = addressData;

    const contactData = {} as ICreateCompanyContactDto;

    contactData.email = data.email;
    contactData.phone = '+55' + data.phoneNumber.replace(/\D/g, '');

    this.contact_data = contactData;

    this.has_fleet = data.hasFleet;
    this.fleet_quantity = Number(data.fleetQuantity) || undefined;

    if (data.motherCompany) {
      if ('id' in data.motherCompany) {
        this.mother_company_id = data.motherCompany.id;
      } else {
        const clearMotherDocumentNumber =
          data.motherCompany.documentNumber.replace(/\D/g, '');
        const motherCompanyType =
          CompanyTypeEnum[data.motherCompany.companyType];
        const motherDocumentType =
          DocumentTypeEnum[
            clearMotherDocumentNumber.length === 14 ? 'cnpj' : 'cpf'
          ];

        const parentData = {} as ICreateCompanyParentDto;

        parentData.parent_trade_name = data.motherCompany.tradeName.trim();
        parentData.parent_company_name = data.motherCompany.companyName.trim();
        parentData.parent_document_number = clearMotherDocumentNumber;
        parentData.parent_company_type = motherCompanyType;
        parentData.parent_document_type = motherDocumentType;

        this.parent_data = parentData;
      }
    }
  }
}

export class CreateCompanyModel
  extends CompanyModel
  implements ICreateCompanyDto
{
  trade_name: string;
  company_name: string;
  document_number: string;
  document_type: DocumentTypeEnum;
  company_type: CompanyTypeEnum;
  company_function: CompanyFunctionEnum;

  areas: string[];

  constructor(data: ICreateCompanyForm) {
    super(data);

    const clearDocumentNumber = data.companyDocument.replace(/\D/g, '');
    const documentType =
      DocumentTypeEnum[clearDocumentNumber.length === 14 ? 'cnpj' : 'cpf'];

    this.trade_name = data.companyTradeName.trim();
    this.company_name = data.companyName.trim();
    this.document_number = clearDocumentNumber;
    this.document_type = documentType;
    this.company_type = CompanyTypeEnum[data.companyType];
    this.company_function = CompanyFunctionEnum[data.companyFunction];

    this.areas = data.operationAreas || [];
  }
}

export class ComplementCompanyModel
  extends CompanyModel
  implements IComplementCompanyDto
{
  id: string;
  areas_to_include?: UF[];

  constructor(data: IComplementCompanyForm) {
    super(data);

    this.id = data.id;
    this.reference_company_id = data.id;
    this.areas_to_include = data.operationAreas;
  }
}

export class RectifyCompanyModel
  extends CreateCompanyModel
  implements IRectifyCompanyDto
{
  id: string;
  areas_to_remove?: string[];
  areas_to_include?: string[];
  new_contact: ICreateCompanyContactDto;
  new_address: ICreateCompanyAddressDto;
  new_mother_company_id?: string;
  new_reference_company_id?: string;
  parent_company_data?: ICreateCompanyParentDto;

  getRectifyMutationVariables = (): IRectifyCompanyDto => {
    const newRectifyCompanyModel: IRectifyCompanyDto &
      Partial<
        Pick<
          ICreateCompanyDto,
          | 'areas'
          | 'parent_data'
          | 'address_data'
          | 'contact_data'
          | 'mother_company_id'
        >
      > = {
      ...this,
    };

    delete newRectifyCompanyModel.areas;
    delete newRectifyCompanyModel.parent_data;
    delete newRectifyCompanyModel.address_data;
    delete newRectifyCompanyModel.contact_data;
    delete newRectifyCompanyModel.url_logo;
    delete newRectifyCompanyModel.mother_company_id;

    return newRectifyCompanyModel as IRectifyCompanyDto;
  };

  constructor(data: IRectifyCompanyForm) {
    super(data);

    this.id = data.id;

    this.new_address = this.address_data;
    this.new_contact = this.contact_data;

    this.new_mother_company_id = this.mother_company_id;
    this.new_reference_company_id = this.id;

    if (data.operationAreas) {
      const operationAreas = data.operationAreas;

      this.areas_to_include = operationAreas.filter(
        area => !data.oldOperationAreas.includes(area)
      );
      this.areas_to_remove = data.oldOperationAreas.filter(
        area => !operationAreas.includes(area)
      );
    } else {
      this.areas_to_include = [];
      this.areas_to_remove = [];
    }
  }
}
