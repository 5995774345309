import dayjs from 'dayjs';

const formatDate = (date?: string | dayjs.Dayjs | null) => {
  if (!date) return '-';

  return dayjs(date).format('DD/MM/YYYY');
};

const formatDateTime = (date?: string | dayjs.Dayjs | null) => {
  if (!date) return '-';

  return dayjs(date).format('DD/MM/YY HH:mm');
};

export const commonUtils = {
  formatDate,
  formatDateTime,
};
