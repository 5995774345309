import { LogoutOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext/useAuthContext';
import { useUserContext } from '../../../../contexts/UserContext/useUserContext';
import { UserData } from '../Header/Header.components';

export const useAvatarMenu = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { logout } = useAuthContext();
  const { termsOfUseUrl } = useUserContext();

  const avatarMenu = useMemo(() => {
    const handlers: { onClick: NonNullable<MenuProps['onClick']> } = {
      onClick({ key }) {
        switch (key) {
          case 'logout':
            return logout();
          case 'termsOfUse':
            return window.open(termsOfUseUrl, '_blank');
          case 'userData':
            return navigate('/user');
        }
      },
    };

    const items: NonNullable<MenuProps['items']> = [
      {
        key: 'userData',
        label: <UserData.Desktop />,
      },
      { type: 'divider' },
      {
        key: 'termsOfUse',
        label: t('headerMenu.termsOfUse'),
      },
      { type: 'divider' },
      {
        key: 'logout',
        label: (
          <>
            <LogoutOutlined /> {t('headerMenu.logOut')}
          </>
        ),
      },
    ];

    return { handlers, items };
  }, [logout, navigate, t, termsOfUseUrl]);

  return avatarMenu;
};
