import { TFunction } from 'i18next';

function translateAllKeys<T extends Record<string, string>>(
  t: TFunction,
  keys: T
) {
  type IKeys = keyof typeof keys;
  const tKeys = { ...keys };

  (Object.entries(keys) as [IKeys, string][]).forEach(
    ([key, value]) => (tKeys[key] = t(value))
  );

  return tKeys;
}

export const i18nUtils = {
  translateAllKeys,
};
