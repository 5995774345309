/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import FormSection from '.';
import { IUseRequestAction } from '../../../../hooks/useRequest';
import {
  IDeleteCarrierPolicy,
  IDeleteCarrierPolicyResponse,
  IUploadCarrierPolicy,
  IUploadCarrierPolicyResponse,
} from '../../../../structures/interfaces/Company/Company';

type IPolicyFileState = 'uploading' | 'success' | 'error' | 'undefined';

interface IPolicyFile {
  id: string;
  file?: UploadFile['originFileObj'];
  name: string;
  expiresIn: dayjs.Dayjs;
  state: IPolicyFileState;
  fileUrl?: string;
  errorMessage?: string;
}

interface ICarrierPoliciesUploadInitialValues {
  policyFiles?: IPolicyFile[];
  companyId?: string;
}

interface ICarrierPoliciesUpload {
  userId: string;
  initialValues?: ICarrierPoliciesUploadInitialValues;
  uploadPolicyFileRequest: IUseRequestAction<
    IUploadCarrierPolicy,
    IUploadCarrierPolicyResponse
  >;
  deleteCarrierPolicyRequest: IUseRequestAction<
    IDeleteCarrierPolicy,
    IDeleteCarrierPolicyResponse
  >;
}

const CarrierPoliciesUpload = ({
  userId,
  initialValues,
  uploadPolicyFileRequest,
  deleteCarrierPolicyRequest,
}: ICarrierPoliciesUpload) => {
  const carrierPoliciesTextPath =
    'pages.registerCompany.sections.carrierPolicies';

  const { t } = useTranslation();

  const { Dragger } = Upload;

  const [form] = Form.useForm();

  const [policyFile, setPolicyFile] = useState<UploadFile['originFileObj']>();
  const [policyFiles, setPolicyFiles] = useState<IPolicyFile[]>(
    initialValues?.policyFiles || []
  );

  useEffect(() => {
    policyFiles.forEach(policyFile => {
      if (policyFile.state === 'undefined') {
        customRequest(policyFile);
      }
    });
  }, [policyFiles]);

  const customRequest = ({ id, expiresIn, file, name }: IPolicyFile) => {
    if (file) {
      updatePolicyFile(id, { state: 'uploading' });

      let input;

      if (initialValues?.companyId) {
        input = { company_id: initialValues.companyId };
      } else {
        input = { user_id: userId };
      }

      uploadPolicyFileRequest({
        file,
        input: {
          ...input,
          name: name.trim(),
          expires_in: expiresIn.toISOString(),
        },
      })
        .then(({ id: newId, expiresIn, name, url }) => {
          updatePolicyFile(id, {
            id: newId,
            name: name,
            state: 'success',
            fileUrl: url,
            errorMessage: '',
            expiresIn: dayjs(expiresIn),
          });
        })
        .catch(({ message }) => {
          updatePolicyFile(id, { state: 'error', errorMessage: t(message) });
        });
    }
  };

  const updatePolicyFile = (id: string, values: Partial<IPolicyFile>) => {
    setPolicyFiles(policyFiles =>
      policyFiles.map(policyFile =>
        policyFile.id === id ? { ...policyFile, ...values } : policyFile
      )
    );
  };

  const beforeUpload = (file: NonNullable<UploadFile['originFileObj']>) => {
    const acceptedTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
    ];

    if (!acceptedTypes.includes(file.type)) {
      notification.error({
        message: t(`${carrierPoliciesTextPath}.fields.rules.acceptedFileTypes`),
      });

      return Upload.LIST_IGNORE;
    }

    const maxFileSize5MB = 5000000;
    if (file.size > maxFileSize5MB) {
      notification.error({
        message: t(`${carrierPoliciesTextPath}.fields.rules.maximumFileSize`),
      });

      return Upload.LIST_IGNORE;
    }

    setPolicyFile(file);

    return false;
  };

  const handleDraggerRemove = () => {
    setPolicyFile(undefined);
  };

  const policyFileIsNotEmpty = () =>
    policyFile ? Promise.resolve() : Promise.reject();

  const dragger = (
    <Form.Item
      name="file"
      className="dragger_container"
      rules={[
        {
          validateTrigger: 'onSubmit',
          validator: policyFileIsNotEmpty,
          message: t('input.rules.required'),
        },
      ]}
    >
      <Dragger
        accept=".png, .jpg, .jpeg, .pdf"
        beforeUpload={beforeUpload}
        maxCount={1}
        name="logo"
        style={{ display: 'block' }}
        fileList={policyFile ? [policyFile] : []}
        onRemove={handleDraggerRemove}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          {t(`${carrierPoliciesTextPath}.fields.labels.logo`)}
        </p>
        <p className="ant-upload-hint">
          {t(`${carrierPoliciesTextPath}.fields.labels.logoDescription`)}
        </p>
      </Dragger>
    </Form.Item>
  );

  const disabledDate: DatePickerProps['disabledDate'] = current => {
    return current && current < dayjs().endOf('day').add(-1, 'day');
  };

  const expiresInFormItem = (
    <Form.Item
      name="expiresIn"
      rules={[{ required: true, message: t('input.rules.required') }]}
      label={t(`${carrierPoliciesTextPath}.fields.labels.expiresIn`)}
    >
      <DatePicker
        size="large"
        format="DD/MM/YY"
        disabledDate={disabledDate}
        getPopupContainer={trigger => trigger.parentElement || trigger}
        placeholder={t(
          `${carrierPoliciesTextPath}.fields.placeholders.expiresIn`
        )}
      />
    </Form.Item>
  );

  const nameFormItem = (
    <Form.Item
      name="name"
      style={{ maxWidth: '100%' }}
      label={t(`${carrierPoliciesTextPath}.fields.labels.name`)}
      rules={[
        { required: true, message: t('input.rules.required') },
        {
          max: 100,
          message: t(`${carrierPoliciesTextPath}.fields.rules.nameLength`),
        },
      ]}
    >
      <Input
        size="large"
        placeholder={t(`${carrierPoliciesTextPath}.fields.placeholders.name`)}
      />
    </Form.Item>
  );

  const handleAddPolicy = () => {
    form
      .validateFields(['expiresIn', 'name', 'file'])
      .then(({ expiresIn, name }: Pick<IPolicyFile, 'expiresIn' | 'name'>) => {
        if (policyFile) {
          setPolicyFiles(policiesFiles => {
            if (policiesFiles.length < 9) {
              const newPolicyFile = {
                name,
                expiresIn,
                id: uuidv4(),
                file: policyFile,
                state: 'undefined',
              } as IPolicyFile;

              form.setFieldsValue({ name: '', expiresIn: '' });

              setPolicyFile(undefined);

              return [...policiesFiles, newPolicyFile];
            } else {
              notification.error({
                message: t(
                  `${carrierPoliciesTextPath}.fields.rules.maximumFileAmount.message`
                ),
                description: t(
                  `${carrierPoliciesTextPath}.fields.rules.maximumFileAmount.description`
                ),
              });

              return policiesFiles;
            }
          });
        }
      })
      .catch(() => {
        return;
      });
  };

  const addPolicyButton = (
    <Button type="primary" size="large" block onClick={handleAddPolicy}>
      {t(`${carrierPoliciesTextPath}.action.addPolicy`)}
    </Button>
  );

  const PolicyFileStateIcon = ({
    policyFile: { id, state },
  }: {
    policyFile: IPolicyFile;
  }) => {
    const HandleRetryUpload = ({ id }: { id: string }) => {
      return (
        <Tooltip
          title={t(`${carrierPoliciesTextPath}.fileList.tooltips.retry`)}
        >
          <ReloadOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const foundPolicyFile = policyFiles.find(
                policyFile => policyFile.id === id
              );
              if (foundPolicyFile) customRequest(foundPolicyFile);
            }}
          />
        </Tooltip>
      );
    };

    const icons = {
      success: <PaperClipOutlined />,
      error: <HandleRetryUpload id={id} />,
      uploading: <LoadingOutlined spin />,
      undefined: <PaperClipOutlined />,
    };

    return <div className="ant-upload-text-icon">{icons[state]}</div>;
  };

  const PolicyFileStateActions = ({
    policyFile: { id, fileUrl },
  }: {
    policyFile: IPolicyFile;
  }) => {
    const HandlePreview = ({ fileUrl }: { fileUrl: string }) => {
      return (
        <Tooltip
          title={t(`${carrierPoliciesTextPath}.fileList.tooltips.preview`)}
        >
          <button
            type="button"
            className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
            onClick={() => {
              window.open(fileUrl, '_blank');
            }}
          >
            <EyeOutlined />
          </button>
        </Tooltip>
      );
    };

    const HandleRemove = () => {
      return (
        <Tooltip
          title={t(`${carrierPoliciesTextPath}.fileList.tooltips.remove`)}
        >
          <button
            type="button"
            className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
            onClick={() => {
              setPolicyFiles(policyFiles => {
                deleteCarrierPolicyRequest({ input: { policy_id: id } });

                return policyFiles.filter(p => p.id !== id);
              });
            }}
          >
            <DeleteOutlined />
          </button>
        </Tooltip>
      );
    };

    return (
      <>
        {fileUrl ? <HandlePreview fileUrl={fileUrl} /> : null}
        <HandleRemove />
      </>
    );
  };

  const policyFilesList = (
    <>
      <Typography.Title level={3} style={{ fontSize: '1rem' }}>
        {t(`${carrierPoliciesTextPath}.fileList.title`)}
      </Typography.Title>
      <div className="ant-upload-list ant-upload-list-text">
        {policyFiles.map(policyFile => {
          const { id, name, expiresIn, state, errorMessage } = policyFile;
          const listItemName = (
            <span className="ant-upload-list-item-name">
              <Space size={'middle'}>
                <span>{name}</span>
                <span>{expiresIn.format('DD/MM/YY')}</span>
              </Space>
            </span>
          );
          return (
            <div className="ant-upload-list-text-container" key={id}>
              <div
                className={`ant-upload-list-item ant-upload-list-item-${state} ant-upload-list-item-list-type-text`}
              >
                <div className="ant-upload-list-item-info">
                  <div className="ant-upload-span">
                    <PolicyFileStateIcon policyFile={policyFile} />
                    {errorMessage ? (
                      <Tooltip title={errorMessage}>{listItemName}</Tooltip>
                    ) : (
                      <>{listItemName}</>
                    )}
                    <span className="ant-upload-list-item-card-actions">
                      <PolicyFileStateActions policyFile={policyFile} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );

  const colSpan = {
    xxl: 12,
    xl: 12,
    lg: 24,
    md: 24,
    sm: 24,
    xs: 24,
  };

  return (
    <Form form={form}>
      <FormSection sectionTitle={t(`${carrierPoliciesTextPath}.title`)}>
        <Row className="carrier-policies-upload_container">
          <Col {...colSpan}>
            <Row gutter={[16, 0]}>
              <Col span={24} style={{ marginBottom: 8, height: '100%' }}>
                {dragger}
              </Col>
              <Col lg={8} xs={24}>
                {expiresInFormItem}
              </Col>
              <Col lg={16} xs={24}>
                {nameFormItem}
              </Col>
              <Col span={24}>{addPolicyButton}</Col>
            </Row>
          </Col>
          <Col {...colSpan} style={{ maxHeight: 350 }}>
            {policyFilesList}
          </Col>
        </Row>
      </FormSection>
    </Form>
  );
};

export default CarrierPoliciesUpload;
