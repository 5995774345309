import { ICreateFreightFormValues } from '../../../components/Freight/Freights/CreateFreightForm/CreateFreightForm';
import { Volume } from '../../../core/Freight/Volume';
import { Price } from '../../../core/Price';
import {
  ConfirmFreightDatesFragment,
  ConfirmFreightLoadFragment,
} from '../../../graphql/generated/graphql';

const toConfirmFreightDates = ({
  landing_date,
  shipment_date,
}: Pick<
  ICreateFreightFormValues,
  'landing_date' | 'shipment_date'
>): ConfirmFreightDatesFragment => {
  return {
    landing_date: landing_date.toISOString(),
    shipment_date: shipment_date.toISOString(),
  };
};

const toConfirmFreightLoad = ({
  packing_id: { label: packingName },
  product_id: { label: productName },
  product_variety_id,
  product_type_id: { label: productTypeName },
  total_volume,
  unit_type,
  note_value,
  observation,
  suggested_value,
}: Pick<
  ICreateFreightFormValues,
  | 'packing_id'
  | 'product_id'
  | 'product_variety_id'
  | 'product_type_id'
  | 'total_volume'
  | 'unit_type'
  | 'note_value'
  | 'observation'
  | 'suggested_value'
>): ConfirmFreightLoadFragment => {
  const totalVolume =
    Volume.Utils.formatVolumeInputToBaseUnitType(total_volume, unit_type) || 0;

  return {
    packing: { name: packingName },
    product: { name: productName },
    product_variety: product_variety_id
      ? { name: product_variety_id.label, id: product_variety_id.value }
      : undefined,
    product_type: { name: productTypeName },
    total_volume: totalVolume,
    unit_type: unit_type,
    observation,
    note_value: Price.Utils.formatPriceInputToCents(note_value),
    suggested_value: Price.Utils.formatPriceInputToCents(suggested_value),
  };
};

export const CreateFreightContextUtils = {
  toConfirmFreightDates,
  toConfirmFreightLoad,
};
