import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, TooltipProps } from 'antd';
import { ReactElement } from 'react';

type IQuestionMarkTooltipProps = Omit<TooltipProps, 'children'>;

export const QuestionMarkTooltip = (
  props: IQuestionMarkTooltipProps
): ReactElement => {
  return (
    <Tooltip {...props}>
      <QuestionCircleOutlined
        style={{
          color: 'rgba(0, 0, 0, 0.45)',
          cursor: 'help',
          writingMode: 'horizontal-tb',
          marginInlineStart: '4px',
        }}
      />
    </Tooltip>
  );
};
