import { SVGProps } from 'react';

export default function TruckIconComponent(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.557}
      height={14}
      {...props}
    >
      <g data-name="Grupo 16971">
        <g data-name="Grupo 16039" fill="currentColor">
          <path
            data-name="Subtra\xE7\xE3o 4"
            d="M9.333 11.667H5.444V3.888a1.557 1.557 0 0 1 1.555-1.555h7a1.557 1.557 0 0 1 1.555 1.555v7.777h-1.555a2.327 2.327 0 0 0-.133-.778h.91v-7a.779.779 0 0 0-.777-.776h-7a.779.779 0 0 0-.778.778v7h3.245a2.327 2.327 0 0 0-.133.778Z"
          />
          <path
            data-name="Subtra\xE7\xE3o 3"
            d="M9.333 11.668H5.444V1.555A1.557 1.557 0 0 1 6.999 0h7a1.557 1.557 0 0 1 1.555 1.555v10.111h-1.555a2.327 2.327 0 0 0-.133-.778h.91V1.555a.779.779 0 0 0-.777-.777h-7a.779.779 0 0 0-.778.778v9.334h3.245a2.327 2.327 0 0 0-.133.778Z"
          />
          <path
            data-name="Subtra\xE7\xE3o 2"
            d="M1.555 11.667H0V7a3.114 3.114 0 0 1 3.111-3.111h1.556a1.557 1.557 0 0 1 1.555 1.555v6.138a2.354 2.354 0 0 0-.778-1.654V5.444a.779.779 0 0 0-.777-.777H3.111A2.336 2.336 0 0 0 .778 7v3.889h.911a2.326 2.326 0 0 0-.133.778Z"
          />
          <path
            data-name="Ret\xE2ngulo 6738"
            d="M3.112 6.222A.779.779 0 0 0 2.334 7v.778h1.555V6.222h-.777m0-.778h.778a.778.778 0 0 1 .778.778v2.333H1.556V7a1.556 1.556 0 0 1 1.556-1.556Z"
          />
          <path
            data-name="Elipse 202 - Contorno"
            d="M11.666 10.111a1.556 1.556 0 1 0 1.556 1.555 1.557 1.557 0 0 0-1.556-1.555m0-.778a2.333 2.333 0 1 1-2.333 2.333 2.333 2.333 0 0 1 2.333-2.333Z"
          />
          <path
            data-name="Elipse 201 - Contorno"
            d="M3.889 10.111a1.556 1.556 0 1 0 1.556 1.555 1.557 1.557 0 0 0-1.556-1.555m0-.778a2.333 2.333 0 1 1-2.333 2.333 2.333 2.333 0 0 1 2.333-2.333Z"
          />
        </g>
      </g>
    </svg>
  );
}
