import { Button, Card, Col, Collapse, Row, Skeleton, Space } from 'antd';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Access } from '../../../../../components/Access/Access';
import { DefaultPageHeader } from '../../../../../components/AntdCustom/DefaultPageHeader/DefaultPageHeader';
import ErrorAlert from '../../../../../components/AntdCustom/ErrorAlert/ErrorAlert';
import CompanyAddressDescriptions from '../../../../../components/Company/CompanyDescriptions/CompanyAddressDescriptions/CompanyAddressDescriptions';
import CompanyContactDescriptions from '../../../../../components/Company/CompanyDescriptions/CompanyContactDescriptions/CompanyContactDescriptions';
import { CompanyName } from '../../../../../components/Company/CompanyName/CompanyName';
import { CompaniesPageTable } from '../../../../../components/Support/Account/Company/CompaniesPageTable/CompaniesPageTable';
import { UsersPageTable } from '../../../../../components/Support/Account/User/UsersPageTable/UsersPageTable';
import {
  CompanyStatusEnum,
  FunctionEnum,
  useCompanyDetailsPageQuery,
  useUnlinkUserFromCompanyMutation,
} from '../../../../../graphql/generated/graphql';
import { useMenuItem } from '../../../../../hooks/useMenuItem/useMenuItem';
import { useTitle } from '../../../../../hooks/useTitle/useTitle';
import {
  IMutationSuccess,
  LinkUserToCompanyModal,
} from '../../Components/LinkUserToCompanyModal/LinkUserToCompanyModal';
import { useCompanyStatus } from './CompanyDetailsPage.hooks';
import { ApproveCompanyModal } from './Components/ApproveCompanyModal/ApproveCompanyModal';
import { useApproveCompanyModal } from './Components/ApproveCompanyModal/ApproveCompanyModal.hooks';
import { CarrierCompanyDataDescriptions } from './Components/Carrier/CarrierCompanyDataDescriptions/CarrierCompanyDataDescriptions';
import { CarrierCompanyOperationAreasTable } from './Components/Carrier/CarrierCompanyOperationAreasTable/CarrierCompanyOperationAreasTable';
import { CarrierCompanyPoliciesTable } from './Components/Carrier/CarrierCompanyPoliciesTable/CarrierCompanyPoliciesTable';
import { CompanyDataDescriptions } from './Components/CompanyDataDescriptions/CompanyDataDescriptions';
import { CompanyStatusTable } from './Components/CompanyStatusTable/CompanyStatusTable';
import { DisapproveCompanyModal } from './Components/DisapproveCompanyModal/DisapproveCompanyModal';
import { useDisapproveCompanyModal } from './Components/DisapproveCompanyModal/DisapproveCompanyModal.hooks';

export const CompanyDetailsPage = (): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.support.account.company.companyDetailsPage',
  });

  const { companyId } = useParams();

  const [isLinkUserToCompanyModalOpen, setIsLinkUserToCompanyModalOpen] =
    useState(false);

  const [companyDetailsPageQueryResult, executeCompanyDetailsPageQuery] =
    useCompanyDetailsPageQuery({
      variables: { companyId: companyId || '' },
    });

  const [
    unlinkUserFromCompanyMutationResult,
    executeUnlinkUserFromCompanyMutation,
  ] = useUnlinkUserFromCompanyMutation();

  const { data, error, fetching } = companyDetailsPageQueryResult;

  const { companyStatus, lastCompanyStatus } = useCompanyStatus({ data });
  const [
    isApproveCompanyModalOpen,
    approveCompanyModalHandlers,
    approveCompanyModalResult,
  ] = useApproveCompanyModal(
    { ...data?.FindCompany },
    executeCompanyDetailsPageQuery
  );

  const [
    isDisapproveCompanyModalOpen,
    disapproveCompanyModalHandlers,
    disapproveCompanyModalResult,
  ] = useDisapproveCompanyModal(
    { ...data?.FindCompany },
    executeCompanyDetailsPageQuery
  );

  const handleClick = () => {
    setIsLinkUserToCompanyModalOpen(true);
  };

  const handleMutationSuccess: IMutationSuccess = ({ error, data }) => {
    if (!error && data) {
      executeCompanyDetailsPageQuery();
      setIsLinkUserToCompanyModalOpen(false);
    }
  };

  const handleUnlinkUserFromCompany = ({ userId }: { userId: string }) => {
    if (companyId) {
      executeUnlinkUserFromCompanyMutation({
        input: {
          company_id: companyId,
          user_company_id: companyId,
          user_id: userId,
        },
      }).then(({ data, error }) => {
        if (!error && data) {
          executeCompanyDetailsPageQuery();
        }
      });
    }
  };

  const usersPageTableTitle = lastCompanyStatus?.status ===
    CompanyStatusEnum.Approved && (
    <Row gutter={[24, 12]} justify="space-between">
      <Col />
      <Col>
        <Button onClick={handleClick} type="primary">
          {t('usersTable.actions.create')}
        </Button>
      </Col>
    </Row>
  );

  const isCarrier = data?.FindCompany.company_function === FunctionEnum.Carrier;
  const hasMotherCompany = !!data?.FindCompany.mother_company?.id;
  const hasChildrenCompanies = !!data?.FindCompany.children_companies;

  const company = data ? { ...data.FindCompany, companyStatus } : undefined;

  useTitle({
    title: data?.FindCompany
      ? CompanyName.getCompanyName(data.FindCompany)
      : 'tab.support.accounts.companies.companyDetailsPage',
  });

  useMenuItem({
    selectedMenu: ['/support/accounts/companies'],
    openMenu: ['/support/accounts'],
  });

  const isValidationButtonsVisible =
    !fetching && lastCompanyStatus?.status === CompanyStatusEnum.InAnalysis;

  const validationButtons = (
    <>
      {isValidationButtonsVisible ? (
        <Space>
          <Button
            danger
            disabled={approveCompanyModalResult.fetching}
            loading={disapproveCompanyModalResult.fetching}
            onClick={disapproveCompanyModalHandlers.open}
          >
            {t('validationButtons.disapprove')}
          </Button>
          <Button
            disabled={disapproveCompanyModalResult.fetching}
            loading={approveCompanyModalResult.fetching}
            onClick={approveCompanyModalHandlers.open}
            type="primary"
          >
            {t('validationButtons.approve')}
          </Button>
        </Space>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <DefaultPageHeader
        extra={validationButtons}
        subTitle={
          data?.FindCompany ? (
            <CompanyName company={data.FindCompany} />
          ) : (
            companyId
          )
        }
        title={t('title')}
      />
      <Row gutter={[0, 24]} style={{ padding: 24 }}>
        <ErrorAlert error={error} />
        <ErrorAlert error={unlinkUserFromCompanyMutationResult.error} />
        <ErrorAlert error={approveCompanyModalResult.error} />
        <ErrorAlert error={disapproveCompanyModalResult.error} />
        <Col span={24}>
          <Card>
            <CompanyDataDescriptions data={company} loading={fetching} />
          </Card>
        </Col>
        <Col span={24}>
          <Collapse bordered={false} defaultActiveKey={['usersTable']}>
            {hasMotherCompany && (
              <Collapse.Panel
                header={t('motherCompanyDescription.title')}
                key={'motherCompanyDescription'}
              >
                <Card>
                  <CompanyDataDescriptions
                    data={data?.FindCompany.mother_company}
                    loading={fetching}
                    showMoreDetails
                  />
                </Card>
              </Collapse.Panel>
            )}
            <Collapse.Panel
              header={t('addressDescriptions.title')}
              key={'addressDescriptions'}
            >
              <Card>
                <Skeleton active loading={fetching}>
                  {data?.FindCompany.address && (
                    <CompanyAddressDescriptions
                      companyAddress={data?.FindCompany.address}
                    />
                  )}
                </Skeleton>
              </Card>
            </Collapse.Panel>
            <Collapse.Panel
              header={t('contactDescriptions.title')}
              key={'contactDescriptions'}
            >
              <Card>
                <Skeleton active loading={fetching}>
                  {data?.FindCompany.contacts?.at(0) && (
                    <CompanyContactDescriptions
                      companyContact={data?.FindCompany.contacts[0]}
                    />
                  )}
                </Skeleton>
              </Card>
            </Collapse.Panel>
            <Collapse.Panel
              header={t('companyStatusTable.title')}
              key={'companyStatusTable'}
            >
              <Card>
                <CompanyStatusTable
                  loading={fetching}
                  dataSource={companyStatus}
                />
              </Card>
            </Collapse.Panel>
            <Collapse.Panel header={t('usersTable.title')} key={'usersTable'}>
              <Card title={usersPageTableTitle}>
                <UsersPageTable
                  dataSource={data?.FindCompany.users?.map(({ user }) => user)}
                  hideCompaniesColumn
                  handleUnlinkUser={
                    lastCompanyStatus?.status === CompanyStatusEnum.Approved
                      ? handleUnlinkUserFromCompany
                      : undefined
                  }
                  loading={
                    fetching || unlinkUserFromCompanyMutationResult.fetching
                  }
                />
              </Card>
            </Collapse.Panel>
            {hasChildrenCompanies && (
              <Collapse.Panel
                header={t('childrenCompaniesTable.title')}
                key={'childrenCompaniesTable'}
              >
                <Card>
                  <CompaniesPageTable
                    dataSource={data?.FindCompany.children_companies || []}
                    loading={fetching}
                  />
                </Card>
              </Collapse.Panel>
            )}
            {isCarrier && (
              <Collapse.Panel header={t('carrier.title')} key={'carrier'}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Card>
                      <CarrierCompanyDataDescriptions
                        data={data.FindCompany}
                        loading={fetching}
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      title={t('carrier.carrierCompanyPoliciesTable.title')}
                    >
                      <CarrierCompanyPoliciesTable
                        dataSource={data.FindCompany.policies || []}
                        loading={fetching}
                      />
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      title={t(
                        'carrier.carrierCompanyOperationAreasTable.title'
                      )}
                    >
                      <CarrierCompanyOperationAreasTable
                        dataSource={data.FindCompany.operationArea || []}
                        loading={fetching}
                      />
                    </Card>
                  </Col>
                </Row>
              </Collapse.Panel>
            )}
          </Collapse>
        </Col>
      </Row>
      <LinkUserToCompanyModal
        open={isLinkUserToCompanyModalOpen}
        onCancel={() => setIsLinkUserToCompanyModalOpen(false)}
        company={data?.FindCompany}
        onMutationSuccess={handleMutationSuccess}
      />
      <ApproveCompanyModal
        open={isApproveCompanyModalOpen}
        onCancel={approveCompanyModalHandlers.close}
        onOk={approveCompanyModalHandlers.confirm}
        title={t('approveCompanyModalTitle', {
          name: data?.FindCompany.trade_name,
        })}
      />
      <DisapproveCompanyModal
        open={isDisapproveCompanyModalOpen}
        onCancel={disapproveCompanyModalHandlers.close}
        onOk={disapproveCompanyModalHandlers.confirm}
        title={t('disapproveCompanyModalTitle', {
          name: data?.FindCompany.trade_name,
        })}
      />
    </>
  );
};

const CompanyDetailsPageAccess = Access(CompanyDetailsPage, {
  acceptedPermissions: [{ module: 'APPROVE_COMPANIES', actions: ['CREATE'] }],
  fallback: <Navigate to="/home" replace />,
});

export default CompanyDetailsPageAccess;
