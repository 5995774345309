import {
  Button,
  Form,
  Popconfirm,
  Table,
  TableColumnsType,
  TableProps,
} from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Volume } from '../../../../core/Freight/Volume';
import {
  QuotationConfirmationTableFragment,
  UnitTypeEnum,
} from '../../../../graphql/generated/graphql';
import { FormattedMercosulPlate } from '../../../Formatters/FormattedMercosulPlate/FormattedMercosulPlate';
import { IConfirmQuotationStatus } from '../ConfirmQuotation/ConfirmQuotation';

export interface IQuotationConfirmationTableItem
  extends QuotationConfirmationTableFragment {
  hasPersisted?: boolean;
}

interface IQuotationConfirmationTableProps
  extends Omit<TableProps<IQuotationConfirmationTableItem>, 'columns'> {
  onRemove?: (id: string) => void;
  onEdit?: (id: string) => void;
  editingConfirmationId?: string;
  disableActions?: boolean;
  confirmQuotationStatus?: IConfirmQuotationStatus;
  unitType?: UnitTypeEnum;
}

const TRANSLATION_PATH =
  'components.quotation.confirmQuotation.quotationConfirmationTable';

export const QuotationConfirmationTable = ({
  onRemove,
  onEdit,
  editingConfirmationId,
  disableActions,
  confirmQuotationStatus,
  unitType,
  ...props
}: IQuotationConfirmationTableProps): ReactElement => {
  const { t } = useTranslation();

  const quotationConfirmations = props.dataSource?.filter(
    data => data.id !== editingConfirmationId
  );

  const confirmedQuotations =
    quotationConfirmations?.filter(data => data.hasPersisted) || [];

  const isDeleteButtonDisabled = (record?: IQuotationConfirmationTableItem) => {
    return (
      disableActions ||
      !!editingConfirmationId ||
      (record?.hasPersisted && confirmedQuotations.length === 1) ||
      !!record?.ctes?.length
    );
  };

  const isEditButtonDisabled = (record?: IQuotationConfirmationTableItem) => {
    return !!editingConfirmationId || disableActions || !!record?.ctes?.length;
  };

  const getColumns: () => TableColumnsType<IQuotationConfirmationTableItem> =
    () => {
      const columns: TableColumnsType<IQuotationConfirmationTableItem> = [
        {
          title: t(`${TRANSLATION_PATH}.columns.driver_name`),
          dataIndex: ['driver', 'name'],
        },
        {
          title: t(`${TRANSLATION_PATH}.columns.cnh`),
          dataIndex: ['driver', 'cnh'],
        },
        {
          title: t(`${TRANSLATION_PATH}.columns.vehicle_type`),
          dataIndex: ['vehicle_type', 'name'],
        },
        {
          title: t(`${TRANSLATION_PATH}.columns.assigned_volume`),
          dataIndex: 'assigned_volume',
          render: assignedVolume => (
            <div style={{ minWidth: 150 }}>
              <Volume.Display volume={assignedVolume} unitType={unitType} />
            </div>
          ),
        },
        {
          title: t(`${TRANSLATION_PATH}.columns.vehicle_plate`),
          dataIndex: 'vehicle_plate',
          render: vehiclePlate => (
            <div style={{ minWidth: 120 }}>
              <FormattedMercosulPlate plate={vehiclePlate} />
            </div>
          ),
        },
      ];

      if (confirmQuotationStatus === 'CONFIRMED') {
        columns.push({
          title: t(`${TRANSLATION_PATH}.columns.loaded_volume`),
          dataIndex: 'ctes',
          render: (ctes: QuotationConfirmationTableFragment['ctes']) => {
            const loadedVolume = ctes?.reduce(
              (sum, cte) => sum + cte.loaded_volume,
              0
            );

            return (
              <div style={{ minWidth: 150 }}>
                {loadedVolume ? (
                  <Volume.Display volume={loadedVolume} unitType={unitType} />
                ) : (
                  '-'
                )}
              </div>
            );
          },
        });
      }

      if (confirmQuotationStatus && confirmQuotationStatus !== 'EXPIRED') {
        columns.push({
          title: (
            <Form.Item
              label={t(`${TRANSLATION_PATH}.columns.actions`)}
              tooltip={t(`${TRANSLATION_PATH}.tooltips.actions`)}
              style={{ margin: 0 }}
              colon={false}
            />
          ),
          dataIndex: 'actions',
          render: (_, record) => (
            <>
              <Button
                disabled={isEditButtonDisabled(record)}
                type="link"
                onClick={() => onEdit && onEdit(record.id)}
              >
                {t('general.edit')}
              </Button>
              <Popconfirm
                onConfirm={() => onRemove && onRemove(record.id)}
                disabled={isDeleteButtonDisabled(record)}
                title={t('general.removePopconfirm')}
                okText={t('general.remove')}
                okButtonProps={{
                  danger: true,
                }}
              >
                <Button
                  danger
                  type="link"
                  disabled={isDeleteButtonDisabled(record)}
                >
                  {t('general.remove')}
                </Button>
              </Popconfirm>
            </>
          ),
        });
      }

      return columns;
    };

  return (
    <Table
      columns={getColumns()}
      className="ant-table-wrapper-responsive"
      rowKey={quotationConfirmation => quotationConfirmation.id}
      size="small"
      rowSelection={{
        columnTitle: (
          <Form.Item
            label={t(`${TRANSLATION_PATH}.columns.isConfirmed`)}
            tooltip={t(`${TRANSLATION_PATH}.tooltips.isConfirmed`)}
            style={{ margin: 0 }}
            colon={false}
          />
        ),
        type: 'checkbox',
        selectedRowKeys: confirmedQuotations.map(data => data.id),
        getCheckboxProps: record => ({
          disabled: true,
          key: record.id,
        }),
      }}
      {...props}
      dataSource={quotationConfirmations}
    />
  );
};
