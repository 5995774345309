import { SVGProps } from 'react';

const CloseCircleOutlined = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 198 198"
    {...props}
  >
    <g data-name="Grupo 16731" fill="none">
      <g data-name="Subtra\xE7\xE3o 5">
        <path d="M99 198a98.537 98.537 0 0 1-55.352-16.908A99.29 99.29 0 0 1 7.78 137.535a98.869 98.869 0 0 1 9.128-93.887A99.29 99.29 0 0 1 60.465 7.78a98.869 98.869 0 0 1 93.887 9.128 99.29 99.29 0 0 1 35.868 43.557 98.869 98.869 0 0 1-9.128 93.887 99.29 99.29 0 0 1-43.557 35.868A98.379 98.379 0 0 1 99 198Zm0-179a80 80 0 1 0 80 80 80.091 80.091 0 0 0-80-80Z" />
        <path
          d="M99 193c12.692 0 25.002-2.485 36.587-7.385a94 94 0 0 0 15.966-8.666 94.696 94.696 0 0 0 13.915-11.481 94.694 94.694 0 0 0 11.481-13.915 93.998 93.998 0 0 0 8.666-15.966C190.515 124.002 193 111.692 193 99c0-12.692-2.485-25.002-7.385-36.587a94 94 0 0 0-8.666-15.966 94.698 94.698 0 0 0-11.481-13.915 94.696 94.696 0 0 0-13.915-11.481 93.994 93.994 0 0 0-15.966-8.666C124.002 7.485 111.692 5 99 5c-12.692 0-25.002 2.485-36.587 7.385a93.995 93.995 0 0 0-15.966 8.666 94.697 94.697 0 0 0-13.915 11.481 94.695 94.695 0 0 0-11.481 13.915 93.994 93.994 0 0 0-8.666 15.966C7.485 73.998 5 86.308 5 99c0 12.692 2.485 25.002 7.385 36.587a93.995 93.995 0 0 0 8.666 15.966 94.698 94.698 0 0 0 11.481 13.915 94.696 94.696 0 0 0 13.915 11.481 93.998 93.998 0 0 0 15.966 8.666C73.998 190.515 86.308 193 99 193m0-179c46.87 0 85 38.13 85 85s-38.13 85-85 85-85-38.13-85-85 38.13-85 85-85m0 184c-13.365 0-26.33-2.618-38.535-7.78a99.012 99.012 0 0 1-16.817-9.128 99.71 99.71 0 0 1-14.651-12.089 99.71 99.71 0 0 1-12.09-14.651 99.008 99.008 0 0 1-9.127-16.817C2.618 125.33 0 112.365 0 99c0-13.365 2.618-26.33 7.78-38.535a99.008 99.008 0 0 1 9.128-16.817 99.71 99.71 0 0 1 12.089-14.651 99.71 99.71 0 0 1 14.651-12.09A99.008 99.008 0 0 1 60.465 7.78C72.67 2.618 85.635 0 99 0c13.365 0 26.33 2.618 38.535 7.78a99.008 99.008 0 0 1 16.817 9.128 99.71 99.71 0 0 1 14.651 12.089 99.71 99.71 0 0 1 12.09 14.651 99.012 99.012 0 0 1 9.127 16.817C195.382 72.67 198 85.635 198 99c0 13.365-2.618 26.33-7.78 38.535a99.011 99.011 0 0 1-9.128 16.817 99.709 99.709 0 0 1-12.089 14.651 99.709 99.709 0 0 1-14.651 12.09 99.011 99.011 0 0 1-16.817 9.127C125.33 195.382 112.365 198 99 198Zm0-179c-44.112 0-80 35.888-80 80s35.888 80 80 80 80-35.888 80-80-35.888-80-80-80Z"
          fill="#df4242"
        />
      </g>
      <g data-name="Uni\xE3o 31">
        <path d="m118.797 132.234-19.8-19.8-19.8 19.8a9.5 9.5 0 1 1-13.429-13.436l19.8-19.8-19.8-19.8A9.5 9.5 0 0 1 79.2 65.766l19.8 19.8 19.8-19.8A9.5 9.5 0 1 1 132.235 79.2l-19.8 19.8 19.8 19.8a9.5 9.5 0 0 1-13.438 13.434Z" />
        <path
          d="M125.515 135.016a9.47 9.47 0 0 0 6.718-2.782c3.71-3.71 3.71-9.725 0-13.436l-19.798-19.797 19.8-19.8a9.47 9.47 0 0 0 2.783-6.718 9.47 9.47 0 0 0-2.783-6.717 9.499 9.499 0 0 0-13.435 0L99 85.566l-19.8-19.8c-3.71-3.71-9.725-3.71-13.435 0-3.71 3.71-3.71 9.724 0 13.435l19.8 19.8-19.797 19.797a9.47 9.47 0 0 0-2.784 6.718 9.47 9.47 0 0 0 2.784 6.718 9.5 9.5 0 0 0 13.434 0L99 112.436l19.797 19.798a9.472 9.472 0 0 0 6.718 2.782m0 5c-1.88 0-3.716-.357-5.453-1.062a14.416 14.416 0 0 1-4.8-3.185L99 119.507l-16.263 16.262a14.413 14.413 0 0 1-4.799 3.185 14.445 14.445 0 0 1-5.453 1.062c-1.881 0-3.716-.357-5.454-1.062a14.413 14.413 0 0 1-4.799-3.185 14.412 14.412 0 0 1-3.185-4.799 14.444 14.444 0 0 1-1.063-5.454c0-1.881.358-3.716 1.063-5.454a14.413 14.413 0 0 1 3.185-4.8l16.262-16.261-16.265-16.264a14.416 14.416 0 0 1-3.185-4.8 14.445 14.445 0 0 1-1.062-5.454c0-1.881.357-3.716 1.062-5.454a14.413 14.413 0 0 1 3.186-4.8 14.412 14.412 0 0 1 4.798-3.184 14.443 14.443 0 0 1 5.454-1.062c1.881 0 3.716.357 5.454 1.062a14.416 14.416 0 0 1 4.8 3.185L99 78.495l16.264-16.265a14.412 14.412 0 0 1 4.8-3.185 14.444 14.444 0 0 1 5.454-1.062c1.88 0 3.716.357 5.454 1.062a14.413 14.413 0 0 1 4.8 3.186 14.413 14.413 0 0 1 3.183 4.798 14.444 14.444 0 0 1 1.063 5.454c0 1.88-.358 3.716-1.063 5.454a14.413 14.413 0 0 1-3.184 4.799L119.506 99l16.262 16.262a14.417 14.417 0 0 1 3.185 4.8 14.446 14.446 0 0 1 1.062 5.453c0 1.881-.357 3.716-1.062 5.454a14.413 14.413 0 0 1-3.185 4.799 14.413 14.413 0 0 1-4.799 3.185 14.445 14.445 0 0 1-5.454 1.062Z"
          fill="#df4242"
        />
      </g>
      <g data-name="Uni\xE3o 35" opacity={0.23}>
        <path d="m128.797 138.714-19.8-19.8-19.8 19.8a9.5 9.5 0 1 1-13.429-13.436l19.8-19.8-19.8-19.8A9.5 9.5 0 0 1 89.2 72.246l19.8 19.8 19.8-19.8a9.5 9.5 0 1 1 13.435 13.434l-19.8 19.8 19.8 19.8a9.5 9.5 0 0 1-13.438 13.434Z" />
        <path
          d="M135.515 141.496a9.47 9.47 0 0 0 6.718-2.782c3.71-3.71 3.71-9.725 0-13.436l-19.798-19.797 19.8-19.8a9.47 9.47 0 0 0 2.783-6.718 9.47 9.47 0 0 0-2.783-6.717 9.499 9.499 0 0 0-13.435 0l-19.8 19.8-19.8-19.8c-3.71-3.71-9.725-3.71-13.435 0-3.71 3.71-3.71 9.724 0 13.435l19.8 19.8-19.797 19.797a9.47 9.47 0 0 0-2.784 6.718 9.47 9.47 0 0 0 2.784 6.718 9.5 9.5 0 0 0 13.434 0L109 118.916l19.797 19.798a9.472 9.472 0 0 0 6.718 2.782m0 5c-1.88 0-3.716-.357-5.453-1.062a14.416 14.416 0 0 1-4.8-3.185L109 125.987l-16.263 16.262a14.413 14.413 0 0 1-4.799 3.185 14.445 14.445 0 0 1-5.453 1.062c-1.881 0-3.716-.357-5.454-1.062a14.413 14.413 0 0 1-4.799-3.185 14.412 14.412 0 0 1-3.185-4.799 14.444 14.444 0 0 1-1.063-5.454c0-1.881.358-3.716 1.063-5.454a14.413 14.413 0 0 1 3.185-4.8l16.262-16.261-16.265-16.264a14.416 14.416 0 0 1-3.185-4.8 14.445 14.445 0 0 1-1.062-5.454c0-1.881.357-3.716 1.062-5.454a14.413 14.413 0 0 1 3.186-4.8 14.412 14.412 0 0 1 4.798-3.184 14.443 14.443 0 0 1 5.454-1.062c1.881 0 3.716.357 5.454 1.062a14.416 14.416 0 0 1 4.8 3.185L109 84.975l16.264-16.265a14.412 14.412 0 0 1 4.8-3.185 14.444 14.444 0 0 1 5.454-1.062c1.88 0 3.716.357 5.454 1.062a14.413 14.413 0 0 1 4.8 3.186 14.413 14.413 0 0 1 3.183 4.798 14.444 14.444 0 0 1 1.063 5.454c0 1.88-.358 3.716-1.063 5.454a14.413 14.413 0 0 1-3.184 4.799l-16.265 16.265 16.262 16.262a14.417 14.417 0 0 1 3.185 4.8 14.446 14.446 0 0 1 1.062 5.453c0 1.881-.357 3.716-1.062 5.454a14.413 14.413 0 0 1-3.185 4.799 14.413 14.413 0 0 1-4.799 3.185 14.445 14.445 0 0 1-5.454 1.062Z"
          fill="#df4242"
        />
      </g>
    </g>
  </svg>
);

export default CloseCircleOutlined;
