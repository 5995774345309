import { Card, CardProps, Descriptions, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FreightLoadDescriptionsFragment } from '../../../graphql/generated/graphql';
import { ResponsiveDescriptions } from '../../AntdCustom/ResponsiveDescriptions/ResponsiveDescriptions';
import { Price } from '../../../core/Price';
import { Volume } from '../../../core/Freight/Volume';

interface IFreightLoadDescriptionsProps {
  data?: FreightLoadDescriptionsFragment;
  loading?: boolean;
}

export const FreightLoadDescriptions = ({
  data,
  loading,
}: IFreightLoadDescriptionsProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.freightLoadDescriptions',
  });

  const column = {
    xxl: 4,
    xl: 2,
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const layout = {
    xxl: 'horizontal',
    xl: 'horizontal',
    lg: 'horizontal',
    md: 'horizontal',
    sm: 'horizontal',
    xs: 'vertical',
  } as const;

  return (
    <>
      <ResponsiveDescriptions column={column} layout={layout}>
        <Descriptions.Item label={t('descriptions.productTypeName')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.product_type.name || '-'}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t('descriptions.productName')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.product.name || '-'}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t('descriptions.productVarietyName')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.product_variety?.name || '-'}
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
      <ResponsiveDescriptions column={column} layout={layout}>
        <Descriptions.Item label={t('descriptions.packingName')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.packing.name || '-'}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t('descriptions.totalVolume')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.total_volume ? (
              <Volume.Display
                volume={data.total_volume}
                unitType={data.unit_type}
              />
            ) : (
              '-'
            )}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t('descriptions.noteValue')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.note_value ? <Price.Display price={data.note_value} /> : '-'}
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item label={t('descriptions.suggestedValue')}>
          <Skeleton active paragraph={false} loading={loading}>
            {data?.suggested_value ? (
              <Price.Display price={data.suggested_value} />
            ) : (
              '-'
            )}
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
      <ResponsiveDescriptions column={column} layout={layout}>
        <Descriptions.Item label={t('descriptions.observation')}>
          <Skeleton active loading={loading} paragraph={false}>
            {data?.observation || '-'}
          </Skeleton>
        </Descriptions.Item>
      </ResponsiveDescriptions>
    </>
  );
};

const FreightLoadDescriptionsCard = ({ children, ...props }: CardProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.freight.freightLoadDescriptions',
  });

  const title = <Typography.Text>{t('title')}</Typography.Text>;

  return (
    <Card title={title} bordered={false} {...props}>
      {children}
    </Card>
  );
};

FreightLoadDescriptions.Card = FreightLoadDescriptionsCard;
