import { ReactElement } from 'react';
import Formatter from '../../../classes/Formatter';

interface IFormattedMercosulPlateProps {
  plate?: string;
}

export const FormattedMercosulPlate = ({
  plate,
}: IFormattedMercosulPlateProps): ReactElement => {
  return <>{Formatter.formatMercosulPlate(plate)}</>;
};
