import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useVehicleTypeSelectQuery } from '../../../graphql/generated/graphql';

type IVehicleTypeSelect = Omit<SelectProps, 'options'>;

/**
 * Ant design select with Vehicle Type options
 */
export const VehicleTypeSelect = ({
  loading,
  disabled,
  ...props
}: IVehicleTypeSelect) => {
  const { t } = useTranslation();

  const [result] = useVehicleTypeSelectQuery();

  const { data, fetching } = result;

  return (
    <>
      <Select
        {...props}
        loading={loading || fetching}
        disabled={disabled || fetching}
        options={data?.FindVehicleTypes}
        notFoundContent={t(
          'components.product.product.productSelect.notFoundMessage'
        )}
      />
    </>
  );
};
